import request from "apiClient";
export default class RefundService {
  static create(orgId, data) {
    return request({
      url: `/api/${orgId}/refunds`,
      method: "POST",
      data
    });
  }
  static record(orgId, data) {
    return request({
      url: `/api/${orgId}/refunds/record`,
      method: "POST",
      data
    });
  }
  static index(orgId, bookingCode) {
    return request({
      url: `/api/${orgId}/refunds?booking_code=${bookingCode}`,
      method: "GET"
    });
  }
}
