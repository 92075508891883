import React from 'react';
import { BookingCalendarService } from 'adminApi';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';

import WidgetDatePickerContainer from './WidgetDatePickerContainer';

export default class WidgetDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bookingCalendar: props.bookingCalendar || {}
    };
  }

  componentDidMount() {
    if (this.state.bookingCalendar !== {}) return null;
    BookingCalendarService.get(this.props.organizationID, this.props.unitID)
      .then(response => {
        this.setState({ bookingCalendar: response.booking_calendar });
      })
      .catch(err => console.error(err));
  }

  calendarDayStatus = day => {
    const key = day.format('DD-MM-YYYY');
    const keyDate = this.state.bookingCalendar[key];
    let status;
    if (this.props.defaultAvailability) {
      // check default availability
      const numDayOfWeek = day.day();
      const defaultAvailabilityForDay = this.props.defaultAvailability[
        numDayOfWeek
      ].availability;
      if (!isUndefined(defaultAvailabilityForDay))
        status = defaultAvailabilityForDay;
    }
    // custom availability should override default
    status = get(
      keyDate,
      'status',
      get(keyDate, 'availability', status || 'available')
    );

    if (
      this.props.initialStartDate &&
      this.props.initialEndDate &&
      (day.isSame(this.props.initialStartDate, 'day') ||
        day.isSame(this.props.initialEndDate, 'day'))
    ) {
      status = 'available';
    }

    const availability = get(keyDate, 'availability');
    const changeover = get(keyDate, 'changeover');
    if (availability === 'available' && changeover === 'check_in') {
      status = 'check_in_only';
    }
    if (availability === 'available' && changeover === 'check_out') {
      status = 'check_out_only';
    }

    return status;
  };

  isDayBlocked = day => {
    const status = this.calendarDayStatus(day);
    let blocked =
      status === 'blocked' ||
      status === 'check_in_out' ||
      status === 'unavailable';

    if (
      this.props.initialStartDate &&
      this.props.initialEndDate &&
      day.isBetween(this.props.initialStartDate, this.props.initialEndDate)
    ) {
      blocked = false;
    }

    return blocked;
  };

  render() {
    return (
      <WidgetDatePickerContainer
        {...this.props}
        isDayBlocked={this.isDayBlocked}
        calendarDayStatus={this.calendarDayStatus}
        readOnly={this.props.readOnly}
      />
    );
  }
}
