import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  FormField,
  FormFieldGridRow,
  IconFontAwesome,
  InputButtonGroup,
  InputCheckbox,
  Label,
  Spacer,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import { isEmailValid, isPhoneNoValid } from 'sharedUtils';
import { useDetectMobile } from 'sharedHooks';
import OpportunityService from 'adminApi/OpportunityService';
import displayError from 'sharedComponents/ErrorDisplay';
import BookingDetails from 'adminComponents/CreateReservationModal/BookingDetails';
import { updateNewBooking } from '../../redux/slices/createBooking';
import OwnerSelfCleanCheckbox from '../../pages/DashboardReservation/atoms/owner-self-clean-checkbox';
import PermissionComponent from '../../PermissionComponent';

const BookingStepThree = ({
  disableAdvanceButton,
  checkIn,
  checkOut,
  organization,
  handleContentIsScrollable,
  user
}) => {
  const { isMobile } = useDetectMobile();
  const booking = useSelector(state => state.newBooking);
  const dispatch = useDispatch();

  const checkValidState = fields => {
    const datesCheck = checkIn.isValid() && checkOut.isValid();
    return (
      fields.map(field => booking[field] === '').includes(true) ||
      !isEmailValid(booking.customer_email) ||
      !isPhoneNoValid(booking.customer_telephone) ||
      !datesCheck
    );
  };

  const fieldsBrandListingIncomplete = () => {
    return booking.brand_id === '' || booking.listing_id === '';
  };

  const checkReservationIncomplete = () => {
    const baseFields = ['customer_name'];
    const guestFields = ['total'].concat(baseFields);
    const insuranceFields = [
      'customer_addr_line_one',
      'customer_city',
      'customer_state',
      'customer_country',
      'customer_zip'
    ].concat(baseFields);
    if (booking.travelInsuranceAdded) {
      return checkValidState(insuranceFields);
    } else if (booking.booking_type === 'guest') {
      return checkValidState(guestFields);
    } else {
      return checkValidState(baseFields);
    }
  };

  const onEmailClick = () => {
    OpportunityService.possibleCustomer(
      organization.id,
      encodeURIComponent(booking.customer_email)
    )
      .then(response => {
        const customer = response.customer;
        if (!response.customer) return null;
        const adrLineOne = customer.adr_street
          ? customer.adr_street.split(',')[0]
          : '';
        const adrLineTwo = customer.adr_street
          ? customer.adr_street.split(',')[1]
          : '';
        dispatch(
          updateNewBooking({
            customer_id: customer.id,
            customer_name: customer.name,
            customer_telephone: customer.telephone,
            customer_addr_line_one: adrLineOne,
            customer_addr_line_two: adrLineTwo,
            customer_city: customer.adr_city,
            customer_state: customer.adr_state,
            customer_country: customer.adr_country,
            customer_zip: customer.adr_zip
          })
        );
      })
      .catch(err => {
        displayError({ message: 'Error fetching customer', err });
      });
  };

  const handleOwnerSelfCleanToggle = () => {
    dispatch(
      updateNewBooking({
        owner_will_self_clean: !booking.owner_will_self_clean
      })
    );
  };

  useEffect(
    () => {
      if (checkReservationIncomplete() || fieldsBrandListingIncomplete())
        disableAdvanceButton(true);
      else disableAdvanceButton(false);
    },
    [booking]
  );

  useEffect(() => {
    handleContentIsScrollable(true);
  }, []);

  return (
    <Box
      className="createReservationModal__contentWrapper"
      paddingTop="s"
      paddingBottom="m"
    >
      <BookingDetails
        listingName={booking.listing_name}
        bookingTypeName={booking.booking_type_name}
      />
      <Divider padding="s" />
      <TextH2>Guest Info</TextH2>
      <Spacer />
      <Label htmlFor="customer_email">Email Address</Label>
      <InputButtonGroup
        buttonType="icon-button"
        buttonIcon={<IconFontAwesome name="search" />}
        buttonProps={{
          onClick: () => {
            onEmailClick();
          },
          variation: 'secondary'
        }}
        inputType="text"
        inputProps={{
          type: 'email',
          onChange: e => {
            dispatch(
              updateNewBooking({
                customer_email: e.target.value
              })
            );
          },
          id: 'customer_email',
          value: booking.customer_email
        }}
      />
      <FormField
        labelText="Name"
        labelHtmlFor="customer_name"
        inputType="text"
        inputProps={{
          onChange: e => {
            dispatch(
              updateNewBooking({
                customer_name: e.target.value
              })
            );
          },
          value: booking.customer_name
        }}
      />
      <FormField
        labelText="Phone"
        labelHtmlFor="customer_telephone"
        inputType="phone"
        inputProps={{
          onChange: phone => {
            dispatch(
              updateNewBooking({
                customer_telephone: phone
              })
            );
          },
          value: booking.customer_telephone,
          inputWidth: 'm'
        }}
      />
      {booking.travelInsuranceAdded && (
        <>
          <Divider padding="s" />
          <FormFieldGridRow
            formFields={[
              <FormField
                labelText="Address Line 1"
                labelHtmlFor="customer_addr_line_one"
                inputType="text"
                inputProps={{
                  placeholder: '123 Main St.',
                  onChange: e => {
                    dispatch(
                      updateNewBooking({
                        customer_addr_line_one: e.target.value
                      })
                    );
                  },
                  value: booking.customer_addr_line_one
                }}
              />,
              {
                gridRowCellWidth: 120,
                component: (
                  <FormField
                    labelText="Apt, Suite, etc."
                    labelHtmlFor="customer_addr_line_two"
                    inputType="text"
                    inputProps={{
                      placeholder: 'Apt. 5',
                      onChange: e => {
                        dispatch(
                          updateNewBooking({
                            customer_addr_line_two: e.target.value
                          })
                        );
                      },
                      value: booking.customer_addr_line_two
                    }}
                  />
                )
              }
            ]}
          />
          <FormFieldGridRow
            formFields={[
              <FormField
                labelText="City"
                labelHtmlFor="customer_city"
                inputType="text"
                inputProps={{
                  placeholder: 'Chicago',
                  onChange: e => {
                    dispatch(
                      updateNewBooking({
                        customer_city: e.target.value
                      })
                    );
                  },
                  value: booking.customer_city
                }}
              />,
              {
                gridRowCellWidth: 150,
                component: (
                  <FormField
                    labelText="State/Province"
                    labelHtmlFor="customer_state"
                    inputType="text"
                    inputProps={{
                      placeholder: 'IL',
                      onChange: e => {
                        dispatch(
                          updateNewBooking({
                            customer_state: e.target.value
                          })
                        );
                      },
                      value: booking.customer_state
                    }}
                  />
                )
              },
              {
                gridRowCellWidth: 100,
                component: (
                  <FormField
                    labelText="Postal Code"
                    labelHtmlFor="customer_zip"
                    inputType="text"
                    inputProps={{
                      placeholder: '60612',
                      onChange: e => {
                        dispatch(
                          updateNewBooking({
                            customer_zip: e.target.value
                          })
                        );
                      },
                      value: booking.customer_zip
                    }}
                  />
                )
              }
            ]}
          />
          <FormField
            labelText="Country"
            labelHtmlFor="customer_country"
            inputType="text"
            inputProps={{
              placeholder: 'United States',
              onChange: e => {
                dispatch(
                  updateNewBooking({
                    customer_country: e.target.value
                  })
                );
              },
              value: booking.customer_country,
              inputValue: 'm'
            }}
          />
        </>
      )}
      <Divider padding="s" />
      {booking.booking_type !== 'owner' && (
        <InputCheckbox
          onChange={() => {
            dispatch(
              updateNewBooking({
                sendConfirmation: !booking.sendConfirmation
              })
            );
          }}
          checked={booking.sendConfirmation}
        >
          Send your guest a confirmation email
        </InputCheckbox>
      )}
      <PermissionComponent
        user={user}
        permission="view_reservations"
        restriction="reservations_read_only"
      >
        <OwnerSelfCleanCheckbox
          booking={booking}
          viewer={user}
          onToggle={handleOwnerSelfCleanToggle}
        />
      </PermissionComponent>
      <PermissionComponent user={user} permission="self_clean">
        <OwnerSelfCleanCheckbox
          booking={booking}
          viewer={user}
          onToggle={handleOwnerSelfCleanToggle}
        />
      </PermissionComponent>
    </Box>
  );
};

BookingStepThree.propTypes = {
  disableAdvanceButton: PropTypes.func,
  checkIn: PropTypes.any,
  checkOut: PropTypes.any,
  handleContentIsScrollable: PropTypes.func
};

BookingStepThree.defaultProps = {};

export default BookingStepThree;
