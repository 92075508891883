import request from "apiClient";
export default class DeductionAccountService {
  static index(organizationId, filter) {
    return request({
      url: `/api/${organizationId}/deduction_accounts?filter=${filter}`,
      method: "GET"
    });
  }
  static new(organizationId) {
    return request({
      url: `/api/${organizationId}/deduction_accounts/new`,
      method: "GET"
    });
  }
  static create(organizationId, data) {
    return request({
      url: `/api/${organizationId}/deduction_accounts`,
      method: "POST",
      data
    });
  }
  static fetch(organizationId, deductionAccountId) {
    return request({
      url: `/api/${organizationId}/deduction_accounts/${deductionAccountId}`,
      method: "GET"
    });
  }
  static delete(organizationId, deductionAccountId) {
    return request({
      url: `/api/${organizationId}/deduction_accounts/${deductionAccountId}`,
      method: "DELETE"
    });
  }
  static update(organizationId, deductionAccountId, data) {
    return request({
      url: `/api/${organizationId}/deduction_accounts/${deductionAccountId}`,
      method: "PUT",
      data
    });
  }
}
