import React from 'react';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';
import find from 'lodash/find';

const options = [
  { value: '', label: 'All Types' },
  { value: 'guest', label: 'Guest Reservation' },
  { value: 'owner', label: 'Owner Block' },
  { value: 'maintenance', label: 'Maintenance Block' },
  { value: 'admin', label: 'Admin Block' },
  { value: 'friend', label: 'Friend of Owner' },
  { value: 'comp', label: 'Comped Guest' }
];

const ReservationTypeFilter = props => (
  <figure style={{ flex: `1 1 auto`, marginRight: '5px' }}>
    <label htmlFor="reservation_type">Reservation Type</label>
    {props.multi ? (
      <InputSelect
        name="reservation_type"
        className="magnified"
        options={options}
        isClearable={false}
        isSearchable={false}
        placeholder="All Statuses"
        onChange={option =>
          props.updateFilter('reservation_type', option.value)
        }
        value={find(options, { value: props.reservation_type })}
        isMulti
      />
    ) : (
      <InputSelect
        name="reservation_type"
        className="magnified"
        options={options}
        isClearable={false}
        isSearchable={false}
        placeholder="All Statuses"
        onChange={option =>
          props.updateFilter('reservation_type', option.value)
        }
        value={find(options, { value: props.reservation_type })}
      />
    )}
  </figure>
);

export default ReservationTypeFilter;
