import request from "apiClient";
export default class StripeBankAccountService {
  static index(organizationId, queryString) {
    return request({
      url: `/api/${organizationId}/stripe_bank_accounts${queryString}`,
      method: "GET"
    });
  }
  static create(organizationID, data) {
    return request({
      url: `/api/${organizationID}/stripe_bank_accounts`,
      method: "POST",
      data
    });
  }
  static update(organizationID, directBankAccountID, data) {
    return request({
      url: `/api/${organizationID}/stripe_bank_accounts/${directBankAccountID}`,
      method: "PUT",
      data
    });
  }
  static update_verification(organizationID, directBankAccountID, data) {
    return request({
      url: `/api/${organizationID}/stripe_bank_accounts/${directBankAccountID}/update_verification`,
      method: "POST",
      data
    });
  }
  static get(organizationID, directBankAccountID) {
    return request({
      url: `/api/${organizationID}/stripe_bank_accounts/${directBankAccountID}`,
      method: "GET"
    });
  }
  static destroy(organizationID, directBankAccountID) {
    return request({
      url: `/api/${organizationID}/stripe_bank_accounts/${directBankAccountID}`,
      method: "DELETE"
    });
  }
  static addBankAccount(organizationID, data) {
    return request({
      url: `/api/${organizationID}/stripe_bank_accounts/add_bank_account`,
      method: "POST",
      data
    });
  }
}
