import React from 'react';
import PermissionComponent from 'PermissionComponent';
import {
  ACCOUNTING_NAV_TAGS,
  DASHBOARD_NAV_TAGS,
  DISTRIBUTION_NAV_TAGS,
  INBOX_NAV_TAGS,
  LISTINGS_NAV_TAGS,
  MY_ACCOUNT_NAV_TAGS,
  PEOPLE_NAV_TAGS,
  RESERVATIONS_NAV_TAGS,
  WEBSITE_NAV_TAGS
} from 'sharedNav';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import DashboardHomeLink from 'adminNavbar/dashboard/links/dashboard-home-link';
import InboxLink from 'adminNavbar/inbox/links/inbox-link';
import HelpLink from 'adminNavbar/support/links/help-link';
import LogoutLink from 'adminNavbar/my-account/links/logout-link';
import { RVSHARE_CONTRACTS_NAV_TAGS } from 'src/shared/nav';
import { FeatureTierDisplay } from 'src/admin/components/FeatureTierDisplay';
import MyAccountMobileLinkMenu from './link-menus/my-account/my-account-mobile-link-menu';
import AccountingLinkMenu from './link-menus/accounting/accounting-link-menu';
import PeopleLinkMenu from './link-menus/people/people-link-menu';
import ReservationsLinkMenu from './link-menus/reservations/reservations-link-menu';
import ListingsLinkMenu from './link-menus/listings/listings-link-menu';
import RvShareLink from '../../distribution/links/rv-share-link';
import RentalAgreementLink from '../../site-editor/links/rental-agreement-link';

const RvFleetMobile = props => {
  return (
    <Navbar.Mobile reveal={props.reveal}>
      <DashboardHomeLink {...props} navTags={DASHBOARD_NAV_TAGS} />
      <ListingsLinkMenu {...props} navTags={LISTINGS_NAV_TAGS} />
      <ReservationsLinkMenu {...props} navTags={RESERVATIONS_NAV_TAGS} />
      <InboxLink {...props} navTags={INBOX_NAV_TAGS} />
      <PermissionComponent user={props.user} permission="view_accounting">
        <AccountingLinkMenu {...props} navTags={ACCOUNTING_NAV_TAGS} />
      </PermissionComponent>
      <RvShareLink
        {...props}
        navTags={DISTRIBUTION_NAV_TAGS}
        variation="primary"
        label="Distribution"
      />
      <PeopleLinkMenu {...props} navTags={PEOPLE_NAV_TAGS} />
      <FeatureTierDisplay allowedTiers={['freemium']}>
        <RentalAgreementLink
          {...props}
          navTags={WEBSITE_NAV_TAGS}
          variation="primary"
          label="Contracts"
        />
      </FeatureTierDisplay>
      <FeatureTierDisplay allowedTiers={['premium']}>
        <RentalAgreementLink
          {...props}
          isPremium
          navTags={RVSHARE_CONTRACTS_NAV_TAGS}
          variation="primary"
          label="Contracts"
        />
      </FeatureTierDisplay>
      {/* <FeatureTierDisplay allowedTiers={['premium']}>
        <WebsiteEditorLink
          {...props}
          navTags={WEBSITE_NAV_TAGS}
          variation="primary"
          label="Website Editor"
        />
      </FeatureTierDisplay> */}
      <MyAccountMobileLinkMenu {...props} navTags={MY_ACCOUNT_NAV_TAGS} />
      <HelpLink {...props} />
      <LogoutLink {...props} variation="primary" />
    </Navbar.Mobile>
  );
};

export default RvFleetMobile;
