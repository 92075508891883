import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Box,
  CallToActionButton,
  Spacer
} from '@directsoftware/ui-kit-web-admin';

const ShowMoreWrapper = ({ children, addBorder, addPadding }) => {
  const ref = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const [addUi, setAddUi] = useState(false);

  useEffect(
    () => {
      const contentHeight = ref.current.clientHeight;
      setAddUi(contentHeight > 256);
    },
    [children]
  );

  return (
    <Box>
      <Box
        className={classnames('details-layout-show-more-container', {
          '-fixedHeight': addUi,
          '-expand': showMore,
          '-addBorder': addBorder,
          '-addPadding': addPadding
        })}
      >
        <div ref={ref} id="showMoreContainer">
          {children}
        </div>
      </Box>
      {addUi && (
        <>
          <Spacer size="xs" />
          <CallToActionButton
            onClick={() => setShowMore(!showMore)}
            isFullWidth
            variation="secondary"
            appearance="ghost"
          >
            {showMore ? 'Show Less' : 'Show More'}
          </CallToActionButton>
        </>
      )}
    </Box>
  );
};

ShowMoreWrapper.propTypes = {
  children: PropTypes.any,
  addBorder: PropTypes.bool,
  addPadding: PropTypes.bool
};

ShowMoreWrapper.defaultProps = {};

export default ShowMoreWrapper;
