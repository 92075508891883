import React from 'react';

import OpportunityService from 'adminApi/OpportunityService';
import displayError from 'sharedComponents/ErrorDisplay';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';

export default class EmployeeSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      employeeOptions: []
    };
  }

  componentDidMount() {
    this.fetchPossibleEmployees();
  }

  fetchPossibleEmployees = () => {
    OpportunityService.possibleEmployees(this.props.organizationID)
      .then(response => {
        const employeeOptions = response.employees.map(employee => ({
          label: employee.name,
          value: employee.id
        }));
        this.setState({ employeeOptions });
      })
      .catch(err => displayError({ message: 'Error fetching employees', err }));
  };

  render() {
    return (
      <InputSelect
        name={this.props.name || 'employeeId'}
        placeholder={this.props.placeholder || 'Select team member...'}
        isClearable
        options={this.state.employeeOptions}
        onChange={this.props.onChange}
        isDisabled={this.props.disabled}
      />
    );
  }
}
