import React from 'react';

export default class BackToBackTip extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  toggleExpanded = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    return (
      <figure className={'back-to-back-tip-link'}>
        <img
          onClick={e => this.toggleExpanded(e)}
          src="/assets/admin/back-to-back-warning.png"
          alt="direct"
          width="50"
        />
        {this.state.expanded ? (
          <figure className={`tip-container-overlay`}>
            <a
              className={`close-link`}
              href="#"
              onClick={e => this.toggleExpanded(e)}
            >
              ×
            </a>
            <figure className={`tip-container`}>
              WARNING: This unit has back to back reservations. A guest is
              checking into this unit on the same day the previous guest checks
              out.
            </figure>
          </figure>
        ) : null}
      </figure>
    );
  }
}
