import request from "apiClient";
function list(orgId, brandId) {
  return request({
    url: `/api/${orgId}/unit_listings`,
    method: "GET",
    params: { brand_id: brandId }
  });
}
function get(orgId, unitListingId) {
  return request({
    url: `/api/${orgId}/unit_listings/${unitListingId}`,
    method: "GET"
  });
}
function updateUnitListing(orgId, unitId, listingId, { data }) {
  return request({
    url: `/api/${orgId}/units/${unitId}/listings/${listingId}`,
    method: "PATCH",
    data
  });
}
function updateVehicleListing(orgId, vehicleId, listingId, { data }) {
  return request({
    url: `/api/${orgId}/vehicles/${vehicleId}/listings/${listingId}`,
    method: "PATCH",
    data
  });
}
const UnitListingService = {
  get,
  list,
  updateUnitListing,
  updateVehicleListing
};
export default UnitListingService;
