import request from "apiClient";
function list(orgId, pageSize, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/employees`,
    method: "GET",
    params: {
      limit: pageSize,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.users,
      groups: response.groups,
      pages: Math.ceil(response.count / pageSize),
      mention_users: response.mention_users
    };
  });
}
function get(orgId, employeeId) {
  return request({
    url: `/api/${orgId}/employees/${employeeId}`,
    method: "GET"
  });
}
function update(orgId, employeeId, params) {
  return request({
    url: `/api/${orgId}/employees/${employeeId}`,
    method: "PATCH",
    data: params
  });
}
function getStripeCustomer(organizationId, userId) {
  return request({
    url: `/api/${organizationId}/users/${userId}/stripe_customer`,
    method: "GET"
  });
}
function resetLogin(organizationId, employeeId) {
  return request({
    url: `/api/${organizationId}/employees/${employeeId}/reset_login`,
    method: "POST"
  });
}
function stripeInvoice(organizationId, userId) {
  return request({
    url: `/api/${organizationId}/users/${userId}/stripe_invoices`,
    method: "GET"
  });
}
const UserService = {
  get,
  list,
  update,
  getStripeCustomer,
  resetLogin,
  stripeInvoice
};
export default UserService;
