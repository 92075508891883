import React, { useEffect, useState } from 'react';
import {
  CallToActionButton,
  IconButton,
  IconFontAwesome,
  PageHeader,
  VisibilityHelper
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import CreateReservationModal from 'adminComponents/CreateReservationModal';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { RESERVATIONS_NAV_TAGS } from '../../../../../shared/nav';

const ReservationsList = props => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const manageModal = value => {
    setOpenModal(value);
  };

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: RESERVATIONS_NAV_TAGS.PRIMARY,
        secondary: RESERVATIONS_NAV_TAGS.secondary.LIST_VIEW
      })
    );
  }, []);

  return (
    <PageHeader containerVariation="fluid">
      <PageHeader.Left>
        Reservations: <span>List</span>
      </PageHeader.Left>
      <PageHeader.Right>
        <VisibilityHelper hideStartingFrom="tablet">
          <IconButton
            onClick={() => {
              manageModal(true);
            }}
          >
            <IconFontAwesome name="plus" />
          </IconButton>
        </VisibilityHelper>
        <VisibilityHelper showStartingFrom="tablet">
          <CallToActionButton
            onClick={() => manageModal(true)}
            prependIcon={<IconFontAwesome name="plus" />}
          >
            Create
          </CallToActionButton>
        </VisibilityHelper>
        <CreateReservationModal
          reveal={openModal}
          closeButton={() => manageModal(false)}
          brands={props.brands}
          channels={props.channels}
          organization={props.organization}
          user={props.user}
        />
      </PageHeader.Right>
    </PageHeader>
  );
};

export default ReservationsList;
