import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const WebsiteEditorLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags,
  variation,
  label,
  ...props
}) => {
  return (
    <Navbar.Link
      href="/brands"
      variation={variation}
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={activeRoutes.secondary === navTags.secondary.SITE_EDITOR}
    >
      {label}
    </Navbar.Link>
  );
};

WebsiteEditorLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object,
  variation: PropTypes.string,
  label: PropTypes.string
};

WebsiteEditorLink.defaultProps = {
  variation: 'secondary',
  label: 'Website Editor'
};

export default WebsiteEditorLink;
