import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const ReservationsVehiclesCalendarLink = ({
  activeRoutes,
  closeMenuPanel,
  navTags,
  closeMobileNav,
  organization,
  ...props
}) => {
  const [showVehicleLink, setShowVehicleLink] = useState(false);

  useEffect(() => {
    setShowVehicleLink(organization.organization_type === 'rv_fleet');
  }, []);

  return showVehicleLink ? (
    <Navbar.Link
      href="/reservations/vehicles_calendar"
      variation="secondary"
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={
        activeRoutes.secondary === navTags.secondary.VEHICLES_CALENDAR_VIEW
      }
    >
      Vehicles Calendar
    </Navbar.Link>
  ) : (
    <></>
  );
};

ReservationsVehiclesCalendarLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object
};

export default ReservationsVehiclesCalendarLink;
