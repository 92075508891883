import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CallToActionButton,
  IconFontAwesome,
  IconLink,
  PageHeader,
  Tooltip
} from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { WEBSITE_NAV_TAGS } from '../../../../../shared/nav';
import {
  selectWebsiteCMSPageBuilderState,
  selectWebsiteCMSState,
  updateWebsiteCMSPageBuilder
} from '../../../../redux/slices/websiteCMS';

const PageEditHome = props => {
  const dispatch = useDispatch();
  const { cmsVersion, brand } = useSelector(selectWebsiteCMSState);
  const { isDirty, data: pageData } = useSelector(
    selectWebsiteCMSPageBuilderState
  );
  const [siteName, setSiteName] = useState('');

  useEffect(
    () => {
      setSiteName(brand?.name);
    },
    [brand]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: WEBSITE_NAV_TAGS.PRIMARY,
        secondary: WEBSITE_NAV_TAGS.secondary.SITE_EDITOR
      })
    );
  }, []);

  return (
    <PageHeader
      height="l"
      containerVariation={cmsVersion === 'v2' ? 'fluid' : 'extended'}
    >
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/brands',
            label: 'Website List'
          },
          {
            href: `/brands/${props.brandId}/pages`,
            label: siteName
          },
          {
            label: 'Edit Page'
          }
        ]}
      >
        {pageData.cms_display_name || 'Home'}
      </PageHeader.Left>
      {cmsVersion === 'v2' && (
        <PageHeader.Right>
          <Tooltip tipContent="Preview">
            <IconLink
              variation="secondary"
              appearance="ghost"
              href={`/dashboard/${props.organization.subdomain}/brands/${
                props.brandId
              }/pages/home/preview-v2`}
              openNewTab
              type="link"
            >
              <IconFontAwesome name="eye" />
            </IconLink>
          </Tooltip>
          <CallToActionButton
            onClick={() => {
              dispatch(updateWebsiteCMSPageBuilder({ isSaving: true }));
            }}
            prependIcon={<IconFontAwesome name="floppyDisk" />}
            isDisabled={!isDirty}
          >
            Save
          </CallToActionButton>
        </PageHeader.Right>
      )}
    </PageHeader>
  );
};

export default PageEditHome;
