import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import GlobalSearchService from 'adminApi/GlobalSearchService';
import {
  updateNavigation,
  selectNavigationState,
  resetNavGlobalSearch
} from 'reduxSlices/navigation';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import moment from 'moment';

const GlobalSearchNav = ({ searchModels, searchTips, openCustomerSidebar }) => {
  const { id: organizationId, subdomain: organizationSubdomain } = useSelector(
    state => state.userDetails.organization
  );
  const navState = useSelector(selectNavigationState);
  const dispatch = useDispatch();
  const [resultsArray, setResultsArray] = useState([]);
  const [parsedResultsArray, setParsedResultsArray] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const searchParams = {
    page: 1,
    listViewSize: 5
  };

  const reduceResults = results => {
    return results.filter(result => result.totalCount > 0);
  };

  const buildSearchResult = (model, results, subdomain) => {
    switch (model) {
      case 'employees':
        return results.map(result => ({
          headline: result.name,
          description: result.email || null,
          type: 'router-link',
          href: `/employees/${result.action}`
        }));
      case 'customers':
        return results.map(result => ({
          headline: result.name,
          description: result.email || null,
          type: 'button',
          onClick: () => {
            openCustomerSidebar(result);
          }
        }));
      case 'statements':
        return results.map(result => ({
          headline: `${result.id} - ${result.name}`,
          description: `${moment(result.start_date).format(
            'MMM DD, YYYY'
          )} — ${moment(result.end_date).format('MMM DD, YYYY')}`,
          type: 'link',
          href: `/dashboard/${subdomain}/accounting/statements/${result.action}`
        }));
      case 'bookings':
        return results.map(result => ({
          headline: `${result.booking_code} - ${result.name}`,
          type: 'link',
          href: `/dashboard/${subdomain}/reservations/${result.booking_code}`
        }));
      case 'vehicles':
        return results.map(result => ({
          headline: result.name,
          type: 'link',
          href: `/dashboard/${subdomain}/vehicles/${result.action}`
        }));
      case 'properties':
        return results.map(result => ({
          headline: result.name,
          type: 'router-link',
          href: `/properties/${result.action}`
        }));
      default:
        return [];
    }
  };

  const buildSearchResultGroup = (
    model,
    totalCount,
    results,
    searchTerm,
    subdomain
  ) => {
    return {
      iconName: model.iconName,
      category: model.label,
      seeAllHref: `${model.seeAllHref}${searchTerm}`,
      totalCount,
      results:
        results.length === 0
          ? []
          : buildSearchResult(model.value, results, subdomain)
    };
  };

  const parsedModels = searchFilter => {
    const filter = searchFilter || navState.searchFilter;
    return filter === 'all'
      ? searchModels.map(model => model.value).filter(model => model !== 'all')
      : [filter];
  };

  const globalSearchTopResults = (
    orgId,
    searchTerm,
    searchFilter,
    subdomain
  ) => {
    dispatch(updateNavigation({ isSearching: true }));

    const models = parsedModels(searchFilter);

    models.forEach(model => {
      GlobalSearchService.list(
        orgId,
        searchParams.page,
        searchTerm,
        model,
        searchParams.listViewSize
      )
        .then(response => {
          // dispatch(updateNavigation({ isSearching: false }));
          const fullModel = searchModels.filter(
            full => full.value === model
          )[0];
          const count = response.recordCount;
          setResultsArray(prevState => [
            ...prevState,
            buildSearchResultGroup(
              fullModel,
              count,
              count > 0 ? response[model] : [],
              searchTerm,
              subdomain
            )
          ]);
        })
        .catch(err => {
          // dispatch(updateNavigation({ isSearching: false }));
          setResultsArray(prevState => [
            ...prevState,
            {
              model,
              errorMessage: `Error loading Search results for ${model}`,
              err
            }
          ]);
        });
    });
  };

  useEffect(
    () => {
      const totalModels = parsedModels().length;
      if (navState.isSearching && resultsArray.length === totalModels) {
        let checkCount = 0;
        resultsArray.forEach(result => {
          checkCount += result.totalCount;
        });
        setParsedResultsArray(
          checkCount > 0 ? reduceResults(resultsArray) : []
        );
        dispatch(
          updateNavigation({
            isSearching: false
          })
        );
      }
    },
    [navState.isSearching, resultsArray]
  );

  const dbGlobalSearchTopResults = useMemo(() => {
    return debounce(globalSearchTopResults, 300);
  }, []);

  useEffect(
    () => {
      if (navState.searchTerm !== '') {
        setResultsArray([]);
        dbGlobalSearchTopResults(
          organizationId,
          navState.searchTerm,
          navState.searchFilter,
          organizationSubdomain
        );
      }
    },
    [navState.searchTerm, navState.searchFilter]
  );

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Navbar.Search
      onMobileClick={() => {
        dispatch(
          updateNavigation({
            revealMobileNav: false,
            searchPanelVisible: !navState.searchPanelVisible
          })
        );
      }}
      onSearchClose={() => {
        if (isMounted) {
          dispatch(resetNavGlobalSearch());
          setResultsArray([]);
          setParsedResultsArray(null);
        }
      }}
      isSearching={navState.isSearching}
      results={parsedResultsArray}
      searchTerm={navState.searchTerm}
      inputSelectProps={{
        value: searchModels.filter(
          model => model.value === navState.searchFilter
        ),
        options: searchModels,
        onChange: option => {
          dispatch(
            updateNavigation({
              searchFilter: option.value
            })
          );
        }
      }}
      inputTextProps={{
        value: navState.searchTerm,
        onChange: e => {
          dispatch(
            updateNavigation({
              searchTerm: e.target.value
            })
          );
        }
      }}
      tips={searchTips}
    />
  );
};

GlobalSearchNav.propTypes = {
  searchModels: PropTypes.array.isRequired,
  searchTips: PropTypes.array.isRequired,
  openCustomerSidebar: PropTypes.func
};

export default GlobalSearchNav;
