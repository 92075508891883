import React from 'react';
import { InputText, IconFontAwesome } from '@directsoftware/ui-kit-web-admin';

export default class SearchableClearableField extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return this.props.useDesignKit ? (
      <InputText
        name={this.props.name}
        onChange={this.props.onChange}
        placeholder={this.props.placeholder}
        value={this.props.value}
        prependIcon={<IconFontAwesome name="search" />}
        removeBottomSpacer={this.props.removeBottomSpacer}
        inputWidth={this.props.inputWidth}
        isDense={this.props.isDense}
      />
    ) : (
      <figure className="searchable-field" style={this.props.containerStyles}>
        <div className="clearable-field">
          <InputText
            name={this.props.name}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder}
            value={this.props.value}
            prependIcon={<IconFontAwesome name="search" />}
          />
        </div>
      </figure>
    );
  }
}
