import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  CallToActionButton,
  IconButton,
  IconFontAwesome,
  PageHeader,
  VisibilityHelper
} from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { PEOPLE_NAV_TAGS } from '../../../../../shared/nav';
import { updatePeopleLeads } from '../../../../redux/slices/people';

const CrmHome = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: PEOPLE_NAV_TAGS.PRIMARY,
        secondary: PEOPLE_NAV_TAGS.secondary.CRM
      })
    );
  }, []);

  return (
    <PageHeader>
      <PageHeader.Left>
        People: <span>CRM Pipeline</span>
      </PageHeader.Left>
      <PageHeader.Right>
        <VisibilityHelper hideStartingFrom="tablet">
          <IconButton
            onClick={() => {
              dispatch(updatePeopleLeads({ openLeadModal: true }));
            }}
          >
            <IconFontAwesome name="plus" />
          </IconButton>
        </VisibilityHelper>
        <VisibilityHelper showStartingFrom="tablet">
          <CallToActionButton
            onClick={() => {
              dispatch(updatePeopleLeads({ openLeadModal: true }));
            }}
            prependIcon={<IconFontAwesome name="plus" />}
          >
            Create
          </CallToActionButton>
        </VisibilityHelper>
      </PageHeader.Right>
    </PageHeader>
  );
};

export default CrmHome;
