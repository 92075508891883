import styled from "styled-components";
const RadioOption = styled.div`
  display: flex;

  label {
    padding: 0;
    margin-left: 8px;
    color: black;
  }
`;
export { RadioOption };
