const bookingDotComRefundOptions = [
  { value: "no_refund", label: "No refund offered" },
  { value: "full", label: "Full refund anytime" },
  { value: "day7", label: "Full refund up until 7 days prior to arrival" },
  { value: "day30", label: "Full refund up until 30 days prior to arrival" },
  { value: "day42", label: "Full refund up until 42 days prior to arrival" },
  { value: "day60", label: "Full refund up until 60 days prior to arrival" }
];
const homeawayRefundOptions = [
  { value: "no_refund", label: "No refund offered" },
  {
    value: "relaxed",
    label: "Full refund up until 14 days prior to arrival, 50% refund up until 7 days prior to arrival"
  },
  {
    value: "moderate",
    label: "Full refund up until 30 days prior to arrival, 50% refund up until 14 days prior to arrival"
  },
  {
    value: "firm",
    label: "Full refund up until 60 days prior to arrival, 50% refund up until 30 days prior to arrival"
  },
  { value: "strict", label: "Full refund up until 60 days prior to arrival" },
  { value: "custom", label: "Custom" }
];
const airbnbRefundOptions = [
  {
    value: "flexible",
    label: "Full refund 1 day prior to arrival, except airbnb fees"
  },
  {
    value: "moderate",
    label: "Full refund 5 days prior to arrival, except airbnb fees"
  },
  {
    value: "strict_14_with_grace_period",
    label: "Full refund within 48 hours of booking if before 14 days of checkin. After, 50% refund up until 1 week prior to arrival"
  },
  {
    value: "super_strict_30",
    label: "50% refund up until 30 days prior to arrival, except airbnb fees"
  },
  {
    value: "super_strict_60",
    label: "50% refund up until 60 days prior to arrival, except airbnb fees"
  }
];
const refundOptions = [
  { value: "no_refund", label: "No refund offered" },
  { value: "full", label: "Full refund anytime" },
  { value: "day7", label: "Full refund if cancelled one week before stay" },
  { value: "day30", label: "Full refund if cancelled 30 days before stay" },
  { value: "day45", label: "Full refund if cancelled 45 days before stay" },
  { value: "day60", label: "Full refund if cancelled 60 days before stay" },
  { value: "day90", label: "Full refund if cancelled 90 days before stay" },
  { value: "custom", label: "Custom policy" }
];
const googleRefundOptions = [
  { value: "no_refund", label: "No refund offered" },
  { value: "full", label: "Full refund anytime" },
  { value: "day7", label: "Full refund if cancelled one week before stay" },
  { value: "day30", label: "Full refund if cancelled 30 days before stay" },
  { value: "day45", label: "Full refund if cancelled 45 days before stay" },
  { value: "day60", label: "Full refund if cancelled 60 days before stay" },
  { value: "day90", label: "Full refund if cancelled 90 days before stay" }
];
const rvshareRefundOptions = [
  {
    value: "flexible",
    label: "Flexible",
    fullText: "Renters are entitled to a full refund, less the RVshare service fee, for all cancellations made at least 30 days prior to the rental start date. Renters are entitled to a 50% refund, less the RVshare service fee, for all cancellations made less than 30 days prior to the rental start date. A 24-hour cancellation grace period is given to all bookings made at least 7 days prior to the rental start date. For all bookings made within 7 days of the rental start date, a 1-hour cancellation grace period is given. If a booking is cancelled during a grace period, a full refund including the RVshare service fee will be granted. Once the rental commences, the booking is non-refundable."
  },
  {
    value: "standard",
    label: "Standard",
    fullText: "Renters are entitled to a full refund, less the RVshare service fee, for all cancellations made at least 30 days prior to the rental start date. Renters are entitled to a 50% refund, less the RVshare service fee, for all cancellations made at least 14 days prior to the rental start date. Less than 14 days prior to the rental start date, the booking is non-refundable. A 24-hour cancellation grace period is given to all bookings made at least 7 days prior to the rental start date. For all bookings made within 7 days of the rental start date, a 1-hour grace period is given. If a booking is cancelled during a grace period, a full refund including the RVshare service fee will be granted."
  },
  {
    value: "strict",
    label: "Strict",
    fullText: "Renters are entitled to a full refund, less the RVshare service fee, for all cancellations made at least 30 days prior to the rental start date. Less than 30 days prior to the rental start date, the booking is non-refundable. A 24-hour cancellation grace period is given to all bookings made at least 7 days prior to the rental start date. For all bookings made within 7 days of the rental start date, a 1-hour grace period is given. If a booking is cancelled during a grace period, a full refund including the RVshare service fee will be granted."
  }
];
export {
  refundOptions,
  rvshareRefundOptions,
  bookingDotComRefundOptions,
  homeawayRefundOptions,
  airbnbRefundOptions,
  googleRefundOptions
};
