import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FlexBox,
  IconButton,
  IconFontAwesome,
  Spacer,
  TextBody,
  TextH2
} from '@directsoftware/ui-kit-web-admin';

const BookingDetails = ({ listingName, bookingTypeName }) => {
  const [showBookingDetails, setShowBookingDetails] = useState(true);
  return (
    <>
      <FlexBox alignItems="center">
        <Box flex="1">
          <TextH2>Booking Details</TextH2>
        </Box>
        <IconButton
          variation="secondary"
          appearance="ghost"
          size="dense"
          onClick={() => {
            setShowBookingDetails(!showBookingDetails);
          }}
        >
          <IconFontAwesome
            name={showBookingDetails ? 'chevronUp' : 'chevronDown'}
          />
        </IconButton>
      </FlexBox>
      {showBookingDetails && (
        <>
          <Spacer />
          <FlexBox gap="xs">
            <Box style={{ width: 104 }}>
              <TextBody weight="semibold">Listing Name:</TextBody>
            </Box>
            <Box flex="1" style={{ minWidth: '0', maxWidth: 474 }}>
              <TextBody truncate>{listingName}</TextBody>
            </Box>
          </FlexBox>
          <FlexBox gap="xs">
            <Box style={{ width: 104 }}>
              <TextBody weight="semibold">Booking Type:</TextBody>
            </Box>
            <Box flex="1">
              <TextBody>{bookingTypeName}</TextBody>
            </Box>
          </FlexBox>
        </>
      )}
    </>
  );
};

BookingDetails.propTypes = {
  listingName: PropTypes.string,
  bookingTypeName: PropTypes.string
};

export default BookingDetails;
