import React from 'react';
import styled from 'styled-components';

import Opportunities from './Opportunities';
import Messages from './Messages';
import Reservations from './Reservations';
import Notes from './Notes';
import { Reviews } from './Reviews';

const SectionsContainer = styled.div`
  width: 100%;
  padding: 24px 0;
  height: calc(100vh - 256px);
`;

const ScrollableSection = styled.div`
  height: 100%;
  padding: 0 24px;
  overflow-y: scroll;
`;

const ActiveSection = props => {
  return {
    opportunities: <Opportunities {...props} />,
    messages: <Messages {...props} />,
    reservations: <Reservations {...props} />,
    reviews: <Reviews {...props} />,
    notes: <Notes {...props} />
  }[props.activeSection];
};

const SectionsRouter = props => (
  <SectionsContainer>
    <ScrollableSection>{ActiveSection(props)}</ScrollableSection>
  </SectionsContainer>
);

export default SectionsRouter;
