import React from 'react';
import { InputCheckbox } from '@directsoftware/ui-kit-web-admin';

export default class DirectCheckbox extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <InputCheckbox
        value={this.props.name}
        checked={this.props.checked}
        onChange={this.props.onChange}
        removeBottomSpacer={this.props.removeBottomSpacer}
      >
        {this.props.labelText}
      </InputCheckbox>
    );
  }
}
