import React from 'react';

export default class AsideOrganizationBasic extends React.Component {
  constructor(props, _railsContext) {
    super(props);
  }

  render() {
    return (
      <div>
        <figure>
          <small className="subtitle">Your Organization</small>
          <p>
            An organization is like the bones of your company - it’s where you
            set up your payment processing system and add employees. None of the
            information added to your organization will end up on your site;
            you’ll build that later.
          </p>
        </figure>

        <figure>
          <small className="subtitle">Organization Name</small>
          <p>Don’t worry, you can change your organization name later.</p>
        </figure>

        <figure>
          <small className="subtitle">Company Type</small>
          <p>What type of business do you run?</p>
        </figure>

        <figure>
          <small className="subtitle">Organization Type</small>
          <p>Do you rent out hotel rooms or vacation rentals?</p>
        </figure>

        <figure>
          <small className="subtitle">Description</small>
          <p>
            This description helps us better tailor your Direct™ platform, and
            is totally optional.
          </p>
        </figure>
      </div>
    );
  }
}
