import React, { useState } from 'react';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import { useDetectMobile } from 'sharedHooks';
import LinksDesktop from './links-desktop';
import LinksMobile from './links-mobile';

const ListingsLinkMenu = props => {
  const { isLargeDesktop } = useDetectMobile();
  const [showPanel, setShowPanel] = useState(false);
  const closeMenuPanel = () => {
    setShowPanel(false);
  };

  return (
    <Navbar.LinkMenu
      primaryLinkText="Listings"
      primaryLinkProps={{
        isActive: props.activeRoutes.primary === props.navTags.PRIMARY,
        onClick: () => setShowPanel(!showPanel)
      }}
      onPanelClickOutside={() => setShowPanel(false)}
      showMenuPanel={showPanel}
    >
      {isLargeDesktop ? (
        <LinksDesktop {...props} closeMenuPanel={closeMenuPanel} />
      ) : (
        <LinksMobile {...props} closeMenuPanel={closeMenuPanel} />
      )}
    </Navbar.LinkMenu>
  );
};

export default ListingsLinkMenu;
