import React from 'react';
import styled from 'styled-components';

import IntegerSelect from 'adminComponents/Selects/IntegerSelect';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export default class QuoteNumGuestsUpdater extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numGuests: props.quote.num_guests
    };
  }

  onChange = option => {
    this.setState({ numGuests: option.value }, () => {
      this.props.onChange(this.state.numGuests);
    });
  };

  render() {
    return (
      <Container>
        <div style={{ marginRight: 16, minWidth: 75 }}>
          <IntegerSelect
            label={'Guests'}
            name={'num_guests'}
            value={this.state.numGuests}
            minInt={1}
            maxInt={24}
            onChange={this.onChange}
          />
        </div>
        <a style={{ paddingTop: 24 }} onClick={this.props.onCancel}>
          Cancel
        </a>
      </Container>
    );
  }
}

QuoteNumGuestsUpdater.defaultProps = {
  onCancel: () => null,
  onChange: _numGuests => null
};
