import request from "apiClient";
function list(orgId, limit, page, sorted, filtered, dateFilters, search) {
  return request({
    url: `/api/${orgId}/work_orders`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered,
      date_filters: dateFilters,
      search
    }
  }).then((response) => {
    return {
      rows: response.workOrders,
      pages: Math.ceil(response.count / limit),
      pusherId: response.pusherId
    };
  });
}
function completed(orgId, limit, page, sorted, filtered, dateFilters, search) {
  return request({
    url: `/api/${orgId}/work_orders/completed`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered,
      date_filters: dateFilters,
      search
    }
  }).then((response) => {
    return {
      rows: response.workOrders,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function create(orgId, data) {
  return request({
    url: `/api/${orgId}/work_orders`,
    method: "POST",
    data
  });
}
function update(orgId, workOrderId, data) {
  return request({
    url: `/api/${orgId}/work_orders/${workOrderId}`,
    method: "PUT",
    data
  });
}
function get(orgId, workOrderId) {
  return request({
    url: `/api/${orgId}/work_orders/${workOrderId}`,
    method: "GET"
  });
}
function propertyList(orgId, limit, page, sorted, filtered, propertyId) {
  return request({
    url: `/api/${orgId}/work_orders/property_list`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered,
      property_id: propertyId
    }
  }).then((response) => {
    return {
      rows: response.work_orders,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function unitList(orgId, limit, page, sorted, filtered, unitId) {
  return request({
    url: `/api/${orgId}/work_orders/unit_list`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered,
      unit_id: unitId
    }
  }).then((response) => {
    return {
      rows: response.work_orders,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function byAssignee(orgId, params = {}) {
  return request({
    url: `/api/${orgId}/work_orders/by_assignee`,
    method: "GET",
    params
  });
}
function reassignWorkOrder(orgId, data) {
  return request({
    url: `/api/${orgId}/work_orders/reassign`,
    method: "POST",
    data
  });
}
const WorkOrderService = {
  get,
  list,
  completed,
  update,
  create,
  propertyList,
  unitList,
  byAssignee,
  reassignWorkOrder
};
export default WorkOrderService;
