// Dependencies
// -----------------------------------------------
import React from 'react';

// import { PortfolioService } from 'adminApi';

// Components
// -----------------------------------------------
import { SearchableClearableField } from 'adminComponents';
// import { StatementDatePicker } from './statement-date-picker';

// -----------------------------------------------
// COMPONENT->STATEMENTS-FILTER ------------------
// -----------------------------------------------
export default class ListConversationsFilter extends React.Component {
  // -----------------------------------------------
  // CONSTRUCTOR -----------------------------------
  // -----------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      search: this.props.filters.search
    };
  }

  updateFilters = data => {
    const newFilter = {};
    newFilter[data.filter] = data.value;

    this.setState(newFilter, () => {
      this.props.applyFilters(newFilter);
    });
  };

  onSearchChange = (e, value) => {
    e.preventDefault();
    this.updateFilters({
      filter: 'search',
      value
    });
  };

  // -----------------------------------------------
  // RENDER ----------------------------------------
  // -----------------------------------------------
  render() {
    return (
      <div style={{ zIndex: 2 }}>
        <SearchableClearableField
          name="search"
          label="Search"
          placeholder="Property name or street address"
          onChange={e => this.onSearchChange(e, e.target.value)}
          onClear={e => this.onSearchChange(e, '')}
          value={this.state.search}
        />
      </div>
    );
  }
}
