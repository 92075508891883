/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React from 'react';

export default class InlineTip extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  toggleExpanded = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expanded: !this.state.expanded });
  };

  getOverlayClasses = () => {
    let defaultClassList = 'tip-container-overlay';
    if (this.props.alwaysFullscreen) {
      defaultClassList += ' always-fullscreen';
    } else if (this.props.leftTip) {
      defaultClassList += ' left-tip';
    }
    return defaultClassList;
  };

  render() {
    return (
      <figure
        className={`inline-tip ${
          this.props.alwaysFullscreen ? 'always-fullscreen' : ''
        } ${this.props.icon === 'star' ? 'inline-tip-star' : ''}`}
        style={this.props.iconStyle}
      >
        <a
          className={`tip-toggle-link ${
            this.props.alwaysFullscreen ? 'always-fullscreen' : ''
          }`}
          href="#"
          onClick={e => this.toggleExpanded(e)}
        >
          {this.props.icon === 'star' ? <span>&#9733;</span> : `?`}
        </a>
        {this.state.expanded ? (
          <figure
            className={this.getOverlayClasses()}
            style={this.props.overlayStyles} // make this position fixed to bring to front of screen
          >
            <a
              className={`close-link ${
                this.props.alwaysFullscreen ? 'always-fullscreen' : ''
              }`}
              href="#"
              onClick={e => this.toggleExpanded(e)}
            >
              ×
            </a>
            <figure
              className={`tip-container ${
                this.props.alwaysFullscreen ? 'always-fullscreen' : ''
              }`}
            >
              {this.props.tip}
            </figure>
          </figure>
        ) : null}
      </figure>
    );
  }
}
