import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CallToActionLink,
  Divider,
  FlexBox,
  FormField,
  IconButton,
  IconFontAwesome,
  InputButtonGroup,
  Label,
  Pill,
  Spacer,
  TextBody,
  TextDense,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import UnitService from 'adminApi/UnitService';
import WidgetDatePicker from 'sharedComponents/WidgetDatePicker';
import { QuoteService } from 'adminApi';
import displayError from 'sharedComponents/ErrorDisplay';
import moment from 'moment';
import isNull from 'lodash/isNull';
import QuoteEditingForm from 'adminComponents/QuoteEditingForm';
import numWords from 'num-words';
import { useDetectMobile } from 'sharedHooks';
import BookingDetails from 'adminComponents/CreateReservationModal/BookingDetails';
import { getVehicleAvailability } from 'adminApi/VehicleService';
import { updateNewBooking } from '../../redux/slices/createBooking';

const BookingStepTwo = ({
  organization,
  activateAdvanceButton,
  channels,
  onDatesChange,
  handleContentIsScrollable
}) => {
  const { isMobile, isHD } = useDetectMobile();
  const booking = useSelector(state => state.newBooking);
  const dispatch = useDispatch();
  const [bookingStartDate, setBookingStartDate] = useState(null);
  const [bookingEndDate, setBookingEndDate] = useState(null);
  const [availabilityCalendar, setAvailabilityCalendar] = useState(null);
  const [defaultAvailability, setDefaultAvailability] = useState(null);
  const [quote, setQuote] = useState(null);
  const [quoteProcessing, setQuoteProcessing] = useState(false);
  const [newQuote, setNewQuote] = useState(null);
  const [numGuests, setNumGuests] = useState(1);
  const [channelId, setChannelId] = useState(0);
  const [outOfStayRange, setOutOfStayRange] = useState(false);
  const [stayMin, setStayMin] = useState(null);
  const [stayMax, setStayMax] = useState(null);
  const [customStayMin, setCustomStayMin] = useState(0);
  const [customStayMax, setCustomStayMax] = useState(0);
  const [checkCouponCode, setCheckCouponCode] = useState('');
  const [allCoupons, setAllCoupons] = useState([]);
  const [couponIsValid, setCouponIsValid] = useState(true);
  const [appliedCouponCode, setAppliedCouponCode] = useState(null);
  const [showCouponsPrompt, setShowCouponsPrompt] = useState(false);
  const [totalDaysSelected, setTotalDaysSelected] = useState(0);

  const getDaysArray = (start, end) => {
    // var here because let and const break it
    for (
      var arr = [], dt = new Date(start);
      dt <= end;
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(moment(new Date(dt)).format('DD-MM-YYYY'));
    }
    return arr;
  };

  const refreshQuote = freshQuote => {
    if (!freshQuote.line_items) {
      setQuoteProcessing(false);
      return null;
    } // early return for if there is no quote.
    const travelInsuranceAdded =
      freshQuote.line_items.filter(li => li.name === 'Travel Insurance Fee')
        .length > 0;
    dispatch(
      updateNewBooking({
        total: (freshQuote.total_cents / 100.0).toFixed(2),
        quote_id: freshQuote.id,
        channel_id: freshQuote.channel_id,
        travelInsuranceAdded
      })
    );
    setNewQuote(null);
    setQuote(freshQuote.id ? freshQuote : null);
    setQuoteProcessing(false);
  };

  const checkMinMaxStay = () => {
    const totalDays = bookingEndDate.diff(bookingStartDate, 'days');
    setTotalDaysSelected(totalDays);
    let customMin = 0;
    let customMax = 0;
    getDaysArray(bookingStartDate, bookingEndDate).map(d => {
      if (availabilityCalendar[d]) {
        if (availabilityCalendar[d].stayMin < customMin || customMin === 0) {
          const newMin = parseInt(availabilityCalendar[d].stayMin);
          isNaN(newMin) ? (customMin = 0) : (customMin = newMin);
        }
        if (availabilityCalendar[d].stayMax > customMin) {
          customMax = parseInt(availabilityCalendar[d].stayMax);
        }
      }
    });

    setCustomStayMin(customMin);
    setCustomStayMax(customMax);

    if (
      (totalDays < customMin || (totalDays > customMax && customMax !== 0)) &&
      booking.booking_type === 'guest'
    ) {
      setOutOfStayRange(true);
      setQuote({});
      refreshQuote && refreshQuote({});
      return true;
    } else if (
      (totalDays < stayMin || totalDays > stayMax) &&
      customMax === 0 &&
      booking.booking_type === 'guest'
    ) {
      setOutOfStayRange(true);
      setQuote({});
      refreshQuote && refreshQuote({});
      return true;
    }
  };

  const getNewQuote = () => {
    if (
      !bookingStartDate ||
      !bookingEndDate ||
      channelId === undefined ||
      checkMinMaxStay()
    ) {
      return null;
    }
    const quoteParams = {
      bookable_id: booking.bookable_id,
      check_in: bookingStartDate,
      check_out: bookingEndDate,
      channel_id: channelId,
      num_guests: numGuests,
      quote: undefined,
      coupon_code: checkCouponCode,
      vehicle_booking: booking.vehicle_booking
    };

    QuoteService.create(organization.id, quoteParams)
      .then(response => {
        setNewQuote(response.quote);
        setQuoteProcessing(true);
      })
      .catch(err => {
        displayError({ message: 'Error creating quote', err });
      });
  };

  const verifyCouponCode = () => {
    if (isNull(allCoupons)) {
      setCouponIsValid(false);
    } else if (allCoupons.includes(checkCouponCode?.toLowerCase())) {
      setCouponIsValid(true);
      setAppliedCouponCode(checkCouponCode);
      getNewQuote();
    } else {
      setCouponIsValid(false);
    }
  };

  const renderQuote = () => {
    if (quoteProcessing && !outOfStayRange)
      return (
        <>
          <Divider padding="m" />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 256,
              width: '100%',
              fontSize: 61,
              color: '#7940ff'
            }}
          >
            <IconFontAwesome name="spinner" spin />
          </div>
        </>
      );

    return (
      <>
        {quote && quote.id && (
          <>
            <Divider padding="m" />
            <QuoteEditingForm
              quote={quote}
              organizationID={organization.id}
              afterEdit={(freshQuote, _newChargeAmount) => {
                setNewQuote(freshQuote);
                setQuoteProcessing(true);
              }}
              afterLineItemCreated={freshQuote => {
                setNewQuote(freshQuote);
                setQuoteProcessing(true);
              }}
              disableNumGuestsEditing
              disableDateEditing
              afterDelete={freshQuote => {
                setNewQuote(freshQuote);
                setQuoteProcessing(true);
              }}
              vehicleBooking={booking.vehicle_booking}
              vehicleId={booking.bookable_id}
            />
          </>
        )}
      </>
    );
  };

  const alphabetizedChannels = [...channels].sort((a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  /**
   * On mount, get data we need
   */
  useEffect(() => {
    if (booking.vehicle_booking) {
      getVehicleAvailability({
        orgId: organization.id,
        vehicleId: booking.bookable_id
      }).then(response => {
        setAvailabilityCalendar(response.cx_availability_calendar);
        setStayMin(response.default_stay_min);
        setStayMax(response.default_stay_max);
      });
    } else {
      UnitService.getUnitAvailability(
        organization.id,
        booking.bookable_id
      ).then(response => {
        setAvailabilityCalendar(response.availability_calendar);
        setStayMin(response.min_stay);
        setStayMax(response.max_stay);
      });
      QuoteService.fetchCouponCodes(organization.id, booking.bookable_id, booking.vehicle_booking ? 'Vehicle' : 'Unit').then(
        // currently not supported for vehicles
        response => {
          let coupons = [];
          let showCouponPrompt = true;
          if (Array.isArray(response)) {
            coupons = response;
            showCouponPrompt = false;
          } else if (response.data && response.data.length > 0) {
            coupons = response.data;
            showCouponPrompt = false;
          }
          setShowCouponsPrompt(showCouponPrompt);
          setAllCoupons(coupons);
        }
      );
    }
    handleContentIsScrollable(true);
  }, []);

  // get new quote
  useEffect(
    () => {
      if (bookingStartDate && bookingEndDate) getNewQuote();
    },
    [bookingStartDate, bookingEndDate, numGuests, channelId]
  );

  // refresh quote
  useEffect(
    () => {
      if (quoteProcessing && newQuote) refreshQuote(newQuote);
    },
    [quoteProcessing, newQuote]
  );

  // render quote
  useEffect(
    () => {
      renderQuote();
    },
    [quote, quoteProcessing]
  );

  // trigger next button
  useEffect(
    () => {
      if (booking.quote_id === '' || booking.quote_id === null)
        activateAdvanceButton(true);
      else activateAdvanceButton(false);
    },
    [booking]
  );

  return (
    <Box
      className="createReservationModal__contentWrapper"
      paddingTop="s"
      paddingBottom="m"
    >
      <BookingDetails
        listingName={booking.listing_name}
        bookingTypeName={booking.booking_type_name}
      />
      <Divider padding="s" />
      <TextH2>Booking Info</TextH2>
      <Spacer />
      {showCouponsPrompt && (
        <>
          <FlexBox
            className="createReservationModal__noCoupons"
            alignItems="center"
            flexGrow={1}
          >
            <IconButton
              size="dense"
              appearance="ghost"
              onClick={() => setShowCouponsPrompt(false)}
            >
              <IconFontAwesome name="times" />
            </IconButton>
            <Box flex="1">This listing has no coupons to apply.</Box>
            <CallToActionLink
              href="/promotions"
              size="dense"
              appearance="ghost"
            >
              Create Coupon
            </CallToActionLink>
          </FlexBox>
          <Spacer size="xs" />
        </>
      )}
      {booking.bookable_id && availabilityCalendar && (
        <WidgetDatePicker
          bookingCalendar={availabilityCalendar}
          defaultAvailability={defaultAvailability}
          label="Set Your Booking Range"
          organizationID={organization.id}
          unitID={booking.listing_id}
          startDate={bookingStartDate}
          endDate={bookingEndDate}
          initialStartDate={bookingStartDate}
          initialEndDate={bookingEndDate}
          onDatesChange={({ startDate, endDate }) => {
            setOutOfStayRange(false);
            setBookingStartDate(startDate);
            setBookingEndDate(endDate);
            onDatesChange(startDate, endDate);
          }}
          numberOfMonths={2}
          readOnly
          removeOldStyling
          onPickerFocused={value => {
            handleContentIsScrollable(!value);
          }}
          orientation={isMobile ? 'vertical' : 'horizontal'}
          withFullScreenPortal={!isHD}
        />
      )}
      {outOfStayRange && (
        <>
          <FlexBox
            className="createReservationModal__notification"
            gap="s"
            alignItems="flex-start"
            padding="s"
          >
            <FlexBox
              className="createReservationModal__notification_icon"
              justifyContent="center"
              alignItems="center"
            >
              <IconFontAwesome name="exclamationTriangle" />
            </FlexBox>
            <Box flex="1" paddingTop="xxs">
              <TextBody weight="semibold">
                {`A ${
                  totalDaysSelected < 10
                    ? numWords(totalDaysSelected)
                    : totalDaysSelected
                } night stay fails this listing's length-of-stay rules.`}
              </TextBody>
              <Spacer size="xs" />
              <ul>
                <li>
                  <TextDense>Minimum Stay:</TextDense>{' '}
                  <TextDense weight="semibold">
                    {`${customStayMin > 0 ? customStayMin : stayMin} nights`}
                  </TextDense>
                </li>
                <li>
                  <TextDense>Maximum Stay:</TextDense>{' '}
                  <TextDense weight="semibold">
                    {`${customStayMax > 0 ? customStayMax : stayMax} nights`}
                  </TextDense>
                </li>
              </ul>
            </Box>
          </FlexBox>
          <Spacer />
        </>
      )}
      {!booking.bookable_id ||
        (!availabilityCalendar && (
          <WidgetDatePicker
            label="Getting Available Dates..."
            startDate={bookingStartDate}
            endDate={bookingEndDate}
            initialStartDate={bookingStartDate}
            initialEndDate={bookingEndDate}
            onDatesChange={() => {}}
            removeOldStyling
            disabled
          />
        ))}
      <FormField
        labelText="Number of Guests"
        labelHtmlFor="numGuests"
        inputType="incrementer"
        inputProps={{
          value: numGuests,
          min: 1,
          max: 24,
          onChange: value => {
            setNumGuests(value);
            setQuoteProcessing(true);
          },
          inputWidth: 'm'
        }}
      />
      <FormField
        labelText="Distribution Channel"
        labelHtmlFor="channel"
        inputType="select"
        inputProps={{
          onChange: selectedOption => {
            setChannelId(selectedOption.value);
            setQuoteProcessing(true);
          },
          options: alphabetizedChannels,
          value: alphabetizedChannels.filter(
            channel => channel.value === channelId
          ),
          inputWidth: 'm'
        }}
      />
      {allCoupons.length > 0 && (
        <FlexBox alignItems="flex-end" gap="xs">
          <Box flex="1">
            <Label>Apply any Coupon Codes</Label>
            <InputButtonGroup
              buttonLabel="Apply"
              inputType="text"
              inputDescription={
                !couponIsValid
                  ? 'Sorry, this coupon cannot be used for this booking'
                  : ''
              }
              removeBottomSpacer
              inputProps={{
                onChange: e => {
                  setCheckCouponCode(e.target.value);
                  if (e.target.value === '') setCouponIsValid(true);
                },
                value: checkCouponCode,
                isError: !couponIsValid && checkCouponCode !== ''
              }}
              buttonProps={{
                variation: 'secondary',
                onClick: () => {
                  if (checkCouponCode !== '') verifyCouponCode();
                },
                isDisabled: checkCouponCode === ''
              }}
            />
          </Box>
          {appliedCouponCode && (
            <FlexBox style={{ height: 40 }} alignItems="center">
              <Pill color="green">Code Applied</Pill>
            </FlexBox>
          )}
        </FlexBox>
      )}
      {renderQuote()}
    </Box>
  );
};

BookingStepTwo.propTypes = {
  organization: PropTypes.object,
  activateAdvanceButton: PropTypes.func,
  channels: PropTypes.array,
  onDatesChange: PropTypes.func,
  handleContentIsScrollable: PropTypes.func
};

BookingStepTwo.defaultProps = {};

export default BookingStepTwo;
