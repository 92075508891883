import React from 'react';
import Modal from 'react-modal';
import displayError from 'sharedComponents/ErrorDisplay';
import { IndicatorToggle } from 'adminComponents';

// Services
// -----------------------------------------------
import { ChannelMappingService } from 'adminApi';

export default class ChannelMappingModal extends React.Component {
  static propTypes = {};

  constructor(props, _railsContext) {
    super(props);
    this.state = {
      channel: this.props.channel,
      isDirty: false,
      isSaving: false,
      hotelCode: '',
      roomCode: '',
      rateCode: '',
      syncRatesAvailabilityOnly: false,
      showSmartSearch: false
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(newProps) {
    if (newProps.mapping && newProps.propertyMapping) {
      if (this.props.channel.id == 5) {
        const {
          rate_plan_id,
          expedia_hotel_id,
          expedia_room_id
        } = newProps.mapping.channel_data;

        this.setState({
          hotelCode: expedia_hotel_id,
          rateCode: rate_plan_id,
          roomCode: expedia_room_id
        });
      } else {
        const rates = newProps.mapping.channel_data.channel_rates || newProps.propertyMapping.channel_data.channel_rates;
        let rateCode = '';
        const roomCode = newProps.mapping.channel_data.channel_id;
        let showSmartSearch = false;
        const syncRatesAvailabilityOnly =
          newProps.mapping.channel_data.sync_rates_availability_only || false;

        if (Array.isArray(rates)) {
          rateCode = rates[0];
        } else {
          rateCode = rates;
        }

        if (
          rateCode == undefined ||
          rateCode == '' ||
          roomCode == undefined ||
          roomCode == ''
        ) {
          showSmartSearch = true;
        }

        this.setState({
          hotelCode: newProps.mapping.channel_data.hotel_code,
          roomCode: roomCode,
          rateCode: rateCode,
          showSmartSearch: showSmartSearch,
          syncRatesAvailabilityOnly
        });
      }
    }
  }

  onChange = e => {
    e.preventDefault();
    const data = { isDirty: true };
    data[e.target.name] = e.target.value;
    this.setState(data);
  };

  finishEditingAndRevert = () => {
    this.setState(
      {
        isDirty: false,
        isSaving: false
      },
      () => {
        this.props.closeModal();
      }
    );
  };

  finishEditingAndUpdate = () => {
    this.setState(
      {
        isDirty: false,
        isSaving: false
      },
      () => {
        this.props.closeModal();
      }
    );
  };

  updateChannelData = () => {
    let {
      mappable_id,
      mappable_type,
      id,
      channel_id,
      channel_data
    } = this.props.mapping;
    if (this.props.channel.id === 5) {
      channel_data['expedia_room_id'] = this.state.roomCode;
      channel_data['expedia_hotel_id'] = this.state.hotelCode;
      channel_data['rate_plan_id'] = this.state.rateCode;
    } else {
      channel_data['channel_id'] = this.state.roomCode;
      channel_data['hotel_code'] = this.state.hotelCode;
      channel_data['channel_rates'] = this.state.rateCode;
      channel_data[
        'sync_rates_availability_only'
      ] = this.state.syncRatesAvailabilityOnly;
    }

    const data = {
      mappable_id: mappable_id,
      mappable_type: mappable_type,
      channel_data: channel_data
    };

    ChannelMappingService.update(
      this.props.organization.id,
      channel_id,
      id,
      data
    )
      .then(response => {
        this.setState({ isSaving: false, mapping: response }, () => {
          this.props.closeModal();
        });
      })
      .catch(err =>
        displayError({ message: 'Error updating channel mapping', err })
      );
  };

  attemptSave = () => {
    this.setState(
      {
        isSaving: true
      },
      () => {
        this.updateChannelData();
      }
    );
  };

  lookupHotelCode = () => {
    let { id, channel_id } = this.props.mapping;

    ChannelMappingService.lookupHotelCode(
      this.props.organization.id,
      channel_id,
      id,
      this.state.hotelCode
    )
      .then(response => {
        const codes = response;
        this.setState({
          roomCode: codes['room_id'],
          rateCode: codes['rate_id']
        });
      })
      .catch(err =>
        displayError({ message: 'Error fetching hotel code', err })
      );
  };

  render() {
    if (!this.props.mapping || !this.props.propertyMapping) return null;

    const customStyles = {
      overlay: {
        zIndex: 999999999,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'center'
      },
      content: {
        position: 'relative',
        flex: '0 1 500px',
        boxShadow: '0px 2px 3px 0px rgba(0,0,0,0.25)',
        borderRadius: 0,
        padding: 0,
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start'
      }
    };

    return (
      <Modal
        isOpen={this.props.modalOpen}
        onRequestClose={this.props.closeModal}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        ariaHideApp={false}
      >
        <section
          style={{
            padding: '5px 10px',
            color: '#ffffff',
            background: '#00cc99',
            fontSize: '14px'
          }}
        >
          {this.props.propertyMapping.name}
        </section>
        <section>
          <figure className={`consolidated-item-form-section`}>
            <header className="consolidated-item-header">
              <h3>Edit Channel Mapping</h3>
              <figure className="consolidated-item-edit-toggle">
                <a
                  className="negative"
                  href="#"
                  onClick={e => this.finishEditingAndRevert(e)}
                >
                  Cancel
                </a>
                {this.state.isDirty ? (
                  <a
                    className="positive"
                    href="#"
                    onClick={e => this.attemptSave(e)}
                  >
                    Save
                  </a>
                ) : (
                  <a className="disabled">Save</a>
                )}
              </figure>
            </header>
            <form className="consolidated-item-form-editing">
              <div className="consolidated-item-subsection">
                <label>Hotel Code</label>
                <input
                  className="magnified"
                  type="text"
                  name="hotelCode"
                  onChange={this.onChange}
                  value={this.state.hotelCode}
                />
                {this.state.showSmartSearch && this.props.channel.id !== 5 && (
                  <a onClick={() => this.lookupHotelCode()}>SmartSearch</a>
                )}
              </div>
              <div className="consolidated-item-subsection">
                <label>Room Code</label>
                <input
                  className="magnified"
                  type="text"
                  name="roomCode"
                  onChange={this.onChange}
                  value={this.state.roomCode}
                />
              </div>
              <div className="consolidated-item-subsection">
                <label>Standard Rate Plan ID</label>
                <input
                  className="magnified"
                  type="text"
                  name="rateCode"
                  onChange={this.onChange}
                  value={this.state.rateCode}
                />
              </div>
              <div>
                {this.props.channel.id !== 5 && (
                  <>
                    <label>Sync Rates and Availability Only?</label>
                    <IndicatorToggle
                      toggleAction={() =>
                        this.setState({
                          syncRatesAvailabilityOnly: !this.state
                            .syncRatesAvailabilityOnly,
                          isDirty: true
                        })
                      }
                      toggleStatus={this.state.syncRatesAvailabilityOnly}
                      toggleFalseLabel="No"
                      toggleTrueLabel="Yes"
                    />
                  </>
                )}
              </div>
            </form>
          </figure>
        </section>
      </Modal>
    );
  }
}
