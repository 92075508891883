import React from 'react';
import moment from 'moment';
import FormColumnSection from './form-column-section';

export default class ViewingForm extends React.Component {
  constructor(props) {
    super(props);
  }

  renderValue = item => {
    if (item.viewValue) return item.viewValue;

    if (item.type == 'datepicker') {
      if (item.noHour) {
        return moment(this.props.formState[item.key]).format('MMMM Do YYYY'); // Don't include time if  noHour is true
      }
      return moment(this.props.formState[item.key]).format('LLL');
    } else if (item.type === 'boolean') {
      return this.props.formState[item.key] ? 'Enabled' : 'Disabled';
    } else if (item.type === 'wysiwyg') {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: this.props.formState[item.key] }}
        />
      );
    } else if (item.type === 'photoID' || item.type === 'image') {
      if (!item.value) return 'Blank';
      return (
        <img
          src={item.value}
          alt={item.alt || 'id-img'}
          style={item.style || {}}
        />
      );
    } else {
      return this.props.formState[item.key];
    }
  };

  renderColumnSection = items => (
    <FormColumnSection
      items={items}
      renderItem={this.renderValue}
      key={`column-${items[0].key}`}
    />
  );

  render() {
    return (
      <section
        className="consolidated-item-form-viewing"
        style={{ maxWidth: '100%', overflow: 'visible' }}
      >
        {this.props.items.map(item => {
          if (toString.call(item) === '[object Array]') {
            return this.renderColumnSection(item);
          }

          return (
            <section className="consolidated-item-subsection" key={item.key}>
              <label>{item.label}</label>
              {this.renderValue(item)}
            </section>
          );
        })}
      </section>
    );
  }
}
