const workOrderTypeOptions = [
  { label: "Cleaning", value: "cleaning" },
  { label: "Concierge", value: "concierge" },
  { label: "Guest Services", value: "guest_services" },
  { label: "Inspection", value: "inspection" },
  { label: "Maintenance", value: "maintenance" },
  { label: "Other", value: "other" }
];
const jobTypeOptions = [
  { value: "damage", label: "Damage" },
  { value: "repair", label: "Repair" },
  { value: "preventative", label: "Preventative" }
];
export { workOrderTypeOptions, jobTypeOptions };
