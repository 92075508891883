import React from 'react';

import { NavContainer, NavTab } from './NavStyles';

const tabs = ['opportunities', 'messages', 'reservations', 'reviews', 'notes'];

const tabIconUrl = (tab, activeSection) => {
  let baseUrl = `/assets/brand/icon-sidebar-${tab}`;

  if (tab === activeSection) {
    baseUrl += '-active';
  }

  return `${baseUrl}.png`;
};

const Nav = ({ activeSection, setActiveSection }) => (
  <NavContainer>
    {tabs.map(tab => (
      <NavTab
        key={tab}
        className={'tooltip'}
        onClick={() => setActiveSection(tab)}
      >
        <span className="tooltiptext">{tab}</span>
        <img src={tabIconUrl(tab, activeSection)} alt="tab-icon" />
      </NavTab>
    ))}
  </NavContainer>
);

export default Nav;
