import request from "apiClient";
export default class OrganizationService {
  static update(organizationId, data = {}) {
    return request({
      url: `/api/organizations/${organizationId}`,
      method: "PUT",
      data
    });
  }
  static index(queryString) {
    return request({
      url: `/api/organizations?${queryString}`,
      method: "GET"
    });
  }
  static registerWithKeydata(organizationId, data = {}) {
    return request({
      url: `/api/organizations/${organizationId}/register_with_keydata`,
      method: "PUT",
      data
    });
  }
  static ensureKeydataReady(organizationId) {
    return request({
      url: `/api/organizations/${organizationId}/ensure_keydata_ready`,
      method: "PUT"
    });
  }
  static getEmbedCode(organizationId) {
    return request({
      url: `/api/organizations/${organizationId}/get_embed_code`,
      method: "PUT"
    });
  }
}
