import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  activeRoutes: {
    primary: "",
    secondary: "",
    tertiary: ""
  },
  revealMobileNav: false,
  isSearching: false,
  searchTerm: "",
  searchPanelVisible: false,
  searchFilter: "all",
  hideNavbar: false,
  hidePageHeader: false,
  navigationId: null
};
const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    updateNavigation: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetNavigation: () => {
      return initialState;
    },
    resetNavGlobalSearch: (state) => {
      return {
        ...state,
        revealMobileNav: false,
        isSearching: false,
        searchTerm: "",
        searchPanelVisible: false,
        searchFilter: "all",
        searchTopResults: null
      };
    },
    updateActiveRoutes: (state, action) => {
      const primary = action.payload.primary || "";
      const secondary = action.payload.secondary || "";
      const tertiary = action.payload.tertiary || "";
      return {
        ...state,
        activeRoutes: { primary, secondary, tertiary }
      };
    },
    resetActiveRoutes: (state) => {
      return { ...state, activeRoutes: { ...initialState.activeRoutes } };
    }
  }
});
export const {
  updateActiveRoutes,
  resetActiveRoutes,
  updateNavigation,
  resetNavigation,
  resetNavGlobalSearch
} = navigationSlice.actions;
export default navigationSlice.reducer;
export const selectNavigationState = createSelector((state) => state.navigation, (navigation) => navigation);
