const CurrencyDefinitions = [
  {
    symbol: "$",
    label: "US Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "usd",
    name_plural: "US dollars"
  },
  {
    symbol: "CA$",
    label: "Canadian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "cad",
    name_plural: "Canadian dollars"
  },
  {
    symbol: "\u20AC",
    label: "Euro",
    symbol_native: "\u20AC",
    decimal_digits: 2,
    rounding: 0,
    value: "eur",
    name_plural: "euros"
  },
  {
    symbol: "AU$",
    label: "Australian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "aud",
    name_plural: "Australian dollars"
  },
  {
    symbol: "R$",
    label: "Brazilian Real",
    symbol_native: "R$",
    decimal_digits: 2,
    rounding: 0,
    value: "brl",
    name_plural: "Brazilian reals"
  },
  {
    symbol: "CHF",
    label: "Swiss Franc",
    symbol_native: "CHF",
    decimal_digits: 2,
    rounding: 0.05,
    value: "chf",
    name_plural: "Swiss francs"
  },
  {
    symbol: "CN\xA5",
    label: "Chinese Yuan",
    symbol_native: "CN\xA5",
    decimal_digits: 2,
    rounding: 0,
    value: "cny",
    name_plural: "Chinese yuan"
  },
  {
    symbol: "\xA3",
    label: "British Pound Sterling",
    symbol_native: "\xA3",
    decimal_digits: 2,
    rounding: 0,
    value: "gbp",
    name_plural: "British pounds sterling"
  },
  {
    symbol: "HK$",
    label: "Hong Kong Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "hkd",
    name_plural: "Hong Kong dollars"
  },
  {
    symbol: "Rs",
    label: "Indian Rupee",
    symbol_native: "\u099F\u0995\u09BE",
    decimal_digits: 2,
    rounding: 0,
    value: "inr",
    name_plural: "Indian rupees"
  },
  {
    symbol: "\xA5",
    label: "Japanese Yen",
    symbol_native: "\uFFE5",
    decimal_digits: 0,
    rounding: 0,
    value: "jpy",
    name_plural: "Japanese yen"
  },
  {
    symbol: "\u20A9",
    label: "South Korean Won",
    symbol_native: "\u20A9",
    decimal_digits: 0,
    rounding: 0,
    value: "krw",
    name_plural: "South Korean won"
  },
  {
    symbol: "MX$",
    label: "Mexican Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "mxn",
    name_plural: "Mexican pesos"
  },
  {
    symbol: "Nkr",
    label: "Norwegian Krone",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    value: "nok",
    name_plural: "Norwegian kroner"
  },
  {
    symbol: "NZ$",
    label: "New Zealand Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "nzd",
    name_plural: "New Zealand dollars"
  },
  {
    symbol: "RUB",
    label: "Russian Ruble",
    symbol_native: "\u0440\u0443\u0431.",
    decimal_digits: 2,
    rounding: 0,
    value: "rub",
    name_plural: "Russian rubles"
  },
  {
    symbol: "Skr",
    label: "Swedish Krona",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    value: "sek",
    name_plural: "Swedish kronor"
  },
  {
    symbol: "S$",
    label: "Singapore Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    value: "sgd",
    name_plural: "Singapore dollars"
  },
  {
    symbol: "TL",
    label: "Turkish Lira",
    symbol_native: "TL",
    decimal_digits: 2,
    rounding: 0,
    value: "try",
    name_plural: "Turkish Lira"
  },
  {
    symbol: "R",
    label: "South African Rand",
    symbol_native: "R",
    decimal_digits: 2,
    rounding: 0,
    value: "zar",
    name_plural: "South African rand"
  },
  {
    symbol: "$",
    label: "Chilean Peso",
    symbol_native: "$",
    decimal_digits: 0,
    rounding: 0,
    value: "clp",
    name_plural: "Chilean Pesos"
  },
  {
    symbol: "\u0E3F",
    label: "Thai Baht",
    symbol_native: "\u0E3F",
    decimal_digits: 2,
    rounding: 0,
    value: "thb",
    name_plural: "Thai Baht"
  }
];
export default CurrencyDefinitions;
