import React from 'react';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import PermissionComponent from 'PermissionComponent';
import PropertiesLink from 'adminNavbar/listings/links/properties-link';
import VehiclesLink from 'adminNavbar/listings/links/vehicles-link';
import PortfoliosLink from 'adminNavbar/listings/links/portfolios-link';
import SubPortfoliosLink from 'adminNavbar/listings/links/sub-portfolios-link';
import UnitGroupsLink from 'adminNavbar/listings/links/unit-groups-link';
import RateGroupsLink from 'adminNavbar/listings/links/rate-groups-link';
import PromotionsLink from 'adminNavbar/listings/links/promotions-link';
import RoomTypesLink from 'adminNavbar/listings/links/room-types-link';
import AuditPropertiesLink from 'adminNavbar/listings/links/audit-properties-link';
import BulkSettingsLink from 'adminNavbar/listings/links/bulk-settings-link';

const LinksMobile = props => {
  return (
    <>
      <PermissionComponent user={props.user} permission="view_property_index">
        <PropertiesLink {...props} />
      </PermissionComponent>
      <VehiclesLink {...props} />
      <PermissionComponent user={props.user} permission="view_portfolios">
        {(props.user.permissions.ledger ||
          props.user.permissions.view_site_editor ||
          props.user.permissions.site_editor_auto_emails) && (
          <PermissionComponent user={props.user} permission="ledger">
            <BulkSettingsLink {...props} />
          </PermissionComponent>
        )}
        <Navbar.LinkMenuGroup groupTitle="Segmentations">
          <PortfoliosLink {...props} />
          <SubPortfoliosLink {...props} />
          <UnitGroupsLink {...props} />
        </Navbar.LinkMenuGroup>
        <Navbar.LinkMenuGroup groupTitle="Other Features">
          <RateGroupsLink {...props} />
          <PromotionsLink {...props} />
          <RoomTypesLink {...props} />
          <PermissionComponent
            user={props.user}
            permission="view_property_index"
          >
            <AuditPropertiesLink {...props} />
          </PermissionComponent>
        </Navbar.LinkMenuGroup>
      </PermissionComponent>
    </>
  );
};

export default LinksMobile;
