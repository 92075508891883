const createQueryString = (queryParams) => {
  let queryString = "";
  let count = 0;
  Object.keys(queryParams).map((key) => {
    const value = queryParams[key];
    if (value || value !== "") {
      queryString += `${count > 0 ? "&" : "?"}${key}=${value}`;
      count += 1;
    }
  });
  return queryString;
};
export default createQueryString;
