import React, { useEffect } from 'react';
import {
  CallToActionLink,
  IconFontAwesome,
  PageHeader,
  VisibilityHelper
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import PermissionComponent from 'PermissionComponent';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';

const PropertiesHome = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: LISTINGS_NAV_TAGS.PRIMARY,
        secondary: LISTINGS_NAV_TAGS.secondary.PROPERTIES
      })
    );
  }, []);

  return (
    <PageHeader>
      <PageHeader.Left>
        Listings: <span>Properties</span>
      </PageHeader.Left>
      <PermissionComponent
        user={props.user}
        permission="view_properties"
        restriction="properties_read_only"
      >
        <PageHeader.Right>
          <VisibilityHelper hideStartingFrom="tablet">
            <CallToActionLink
              href="/bulk-edit/properties"
              size="dense"
              variation="secondary"
            >
              <IconFontAwesome name="layerGroup" />
            </CallToActionLink>
            <CallToActionLink href="/properties/new" size="dense">
              <IconFontAwesome name="plus" />
            </CallToActionLink>
          </VisibilityHelper>
          <VisibilityHelper showStartingFrom="tablet">
            <CallToActionLink
              href="/bulk-edit/properties"
              prependIcon={<IconFontAwesome name="layerGroup" />}
              variation="secondary"
            >
              Edit
            </CallToActionLink>
            <CallToActionLink
              href="/properties/new"
              prependIcon={<IconFontAwesome name="plus" />}
            >
              Create
            </CallToActionLink>
          </VisibilityHelper>
        </PageHeader.Right>
      </PermissionComponent>
    </PageHeader>
  );
};

export default PropertiesHome;
