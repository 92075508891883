import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CallToActionButton,
  Dropdown,
  IconFontAwesome,
  PageHeader,
  Tooltip,
  IconLink
} from '@directsoftware/ui-kit-web-admin';
import { getVehicle } from 'adminApi/VehicleService';
import { getEnvironment } from 'src/admin/utilities';
import { showExternalRVshareLink } from 'src/shared/utils';
import {
  updateListingDetails,
  updateListingImages
} from '../../../../redux/slices/listings';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';

const VehicleDetails = props => {
  const dispatch = useDispatch();
  const vehicleRedux = useSelector(state => state.vehicle);
  const activeRoutes = useSelector(state => state.navigation.activeRoutes);
  const [vehicle, setVehicle] = useState('');

  useEffect(
    () => {
      if (vehicleRedux?.id === props.vehicleId) {
        setVehicle(vehicleRedux);
      } else {
        getVehicle({ orgId: props.organization.id, vehicleId: props.vehicleId })
          .then(res => {
            setVehicle(res);
          })
          .catch(err => console.log(err));
      }
    },
    [vehicleRedux]
  );

  return (
    <PageHeader height="l" containerVariation="fluid">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/vehicles',
            label: 'Vehicles'
          },
          {
            label: 'Details'
          }
        ]}
      >
        {vehicle.name}
      </PageHeader.Left>
      {activeRoutes.tertiary === LISTINGS_NAV_TAGS.tertiary.IMAGES && (
        <PageHeader.Right>
          <CallToActionButton
            onClick={() => {
              dispatch(updateListingImages({ uploadVehiclePhotos: true }));
            }}
            prependIcon={<IconFontAwesome name="upload" />}
          >
            Upload
          </CallToActionButton>
        </PageHeader.Right>
      )}
      {activeRoutes.tertiary !== LISTINGS_NAV_TAGS.tertiary.IMAGES && (
        <PageHeader.Right>
          {showExternalRVshareLink(vehicle) && (
            <Tooltip tipContent="View on RVshare">
              <IconLink
                href={`https://${
                  getEnvironment() === 'production' ? 'www' : 'uat'
                }.rvshare.com/rvs/details/${vehicle.external_id}`}
                type="link"
                openNewTab
                variation="secondary"
                appearance="ghost"
              >
                <IconFontAwesome name="externalLinkAlt" />
              </IconLink>
            </Tooltip>
          )}
          <Dropdown
            controlLabel="Actions"
            panelWidthVariation="auto"
            controlType="call-to-action-button"
            alignPanel="bottomRight"
            panelContent={[
              {
                label: 'Download iCal',
                type: 'link',
                href: vehicle.direct_ical_url,
                addPadding: true,
                openNewTab: true
              },
              {
                label: 'Clone This Vehicle',
                type: 'button',
                onClick: () => {
                  dispatch(updateListingDetails({ openCloneVehicle: true }));
                },
                addPadding: true
              },
              {
                label: 'View Reservations',
                type: 'link',
                href: `/dashboard/${
                  props.organization.subdomain
                }/reservations?vehicle_id=${props.vehicleId}`,
                addPadding: true,
                openNewTab: true
              }
            ]}
          />
        </PageHeader.Right>
      )}
    </PageHeader>
  );
};

export default VehicleDetails;
