import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CallToActionLink,
  IconFontAwesome,
  IconLink,
  PageHeader,
  VisibilityHelper
} from '@directsoftware/ui-kit-web-admin';
import { FeatureTierDisplay } from 'src/admin/components/FeatureTierDisplay';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { WEBSITE_NAV_TAGS } from '../../../../../shared/nav';

const SiteEditorHome = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: WEBSITE_NAV_TAGS.PRIMARY,
        secondary: WEBSITE_NAV_TAGS.secondary.SITE_EDITOR
      })
    );
  }, []);

  const { isRVshareUser } = useSelector(state => state.userDetails);
  const newBrandPath = '/brands/new';
  const newBrandPathV2 = `${newBrandPath}?cms_version=v2`;

  const renderPageHeader = () => {
    return (
      <PageHeader>
        <PageHeader.Left>Website Editor</PageHeader.Left>
        <PageHeader.Right>
          <>
            <VisibilityHelper hideStartingFrom="tablet">
              <IconLink
                href={newBrandPathV2}
                size="dense"
              >
                <IconFontAwesome name="plus" />
              </IconLink>
            </VisibilityHelper>
            <VisibilityHelper showStartingFrom="tablet">
              <CallToActionLink
                href={newBrandPathV2}
                prependIcon={<IconFontAwesome name="plus" />}
              >
                Create
              </CallToActionLink>
            </VisibilityHelper>
          </>
        </PageHeader.Right>
      </PageHeader>
    );
  };

  return (
    <>
      {isRVshareUser ? (
        <FeatureTierDisplay allowedTiers={['premium']}>
          {renderPageHeader()}
        </FeatureTierDisplay>
      ) : (
        renderPageHeader()
      )}
    </>
  );
};

export default SiteEditorHome;
