import request from "apiClient";
const listDeliveryLocations = ({ orgId, vehicleId }) => {
  return request({
    url: `/api/${orgId}/vehicles/${vehicleId}/delivery_locations`,
    method: "GET"
  });
};
const updateDeliveryLocation = ({ id, orgId, vehicleId, updates }) => {
  return request({
    url: `/api/${orgId}/vehicles/${vehicleId}/delivery_locations/${id}`,
    method: "PATCH",
    data: { delivery_location: updates }
  });
};
const createDeliveryLocation = ({ vehicleId, orgId, data }) => {
  return request({
    url: `/api/${orgId}/vehicles/${vehicleId}/delivery_locations`,
    method: "POST",
    data: { delivery_location: data }
  });
};
const deleteDeliveryLocation = ({ orgId, vehicleId, id }) => {
  return request({
    url: `/api/${orgId}/vehicles/${vehicleId}/delivery_locations/${id}`,
    method: "DELETE"
  });
};
const showDeliveryLocation = ({ orgId, vehicleId, id }) => {
  return request({
    url: `/api/${orgId}/vehicles/${vehicleId}/delivery_locations/${id}`,
    method: "GET"
  });
};
export {
  listDeliveryLocations,
  updateDeliveryLocation,
  createDeliveryLocation,
  deleteDeliveryLocation,
  showDeliveryLocation
};
