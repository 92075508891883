// Dependencies
// -----------------------------------------------
import React from 'react';

// Components
// -----------------------------------------------
import {
  dispatchError,
  ConsolidatedItemEditToggleHeader,
  SimplifiedWysiwyg
} from 'adminComponents';

// Styled Components
// -----------------------------------------------
import { UnitService } from 'adminApi';
import { RadioOption } from './styled';

// Services
// -----------------------------------------------

// -----------------------------------------------
// COMPONENT->MANAGE-SINGLE-FIELDS-INSTRUCTIONS --
// -----------------------------------------------
export default class ManageSingleFieldsInstructions extends React.Component {
  // ---------------------------------------------
  // CONSTRUCTOR ---------------------------------
  // ---------------------------------------------
  constructor(props, _railsContext) {
    super(props);
    this.codeTypes = {
      last_4_digits:
        'Unique 4 digit lock box code provided by system (unique for each rental)',
      custom_code: 'Use the same code I provide for every rental',
      other: 'Other (please specify below)'
    };

    this.state = {
      checkInInstructions: this.props.unit?.check_in_instructions,
      checkInInstructionsCache: this.props.unit?.check_in_instructions,
      isDirty: false,
      isEditing: false,
      isSaving: false,
      isSaved: false
    };
  }

  onChange = (name, value) => {
    const instructionsChange = this.state.checkInInstructions;
    instructionsChange[name] = value;

    this.setState({ isDirty: true, checkInInstructions: instructionsChange });
  };

  toggleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  renderCustomCode = () => {
    return this.state.checkInInstructions?.access_code_type === 'custom_code';
  };

  finishEditing = saved => {
    const changeState = {
      isDirty: false,
      isEditing: false,
      isSaving: false,
      isSaved: saved
    };

    if (saved) {
      changeState.checkInInstructions = this.state.checkInInstructionsCache;
    } else {
      changeState.checkInInstructionsCache = this.state.checkInInstructions;
    }

    this.setState(changeState);
  };

  attemptSave = () => {
    this.setState({ isSaving: true, isSaved: false }, () => {
      UnitService.update(this.props.organization.id, this.props.unit.id, {
        check_in_instructions: this.state.checkInInstructions
      })
        .then(response => {
          this.finishEditing(true);
        })
        .catch(error => {
          dispatchError();
          console.warn(jqXhr);
          this.finishEditing(false);
        });
    });
  };

  renderEditing() {
    const instructions = this.state.checkInInstructions;
    return (
      <form className="consolidated-item-form-editing">
        <div className="consolidated-item-subsection">
          <label>How will guests gain access to the home?</label>
          {Object.keys(this.codeTypes).map((codeType, index) => (
            <RadioOption key={index}>
              <input
                type="radio"
                name="access_code_type"
                id={`access_code_type_${index}`}
                value={codeType}
                checked={instructions.access_code_type == codeType}
                onChange={e => this.onChange(e.target.name, e.target.value)}
              />
              <label htmlFor={`access_code_type_${index}`}>
                {this.codeTypes[codeType]}
              </label>
            </RadioOption>
          ))}

          {this.renderCustomCode() && (
            <div>
              <label htmlFor="access_code">
                Enter code here and include instructions for what to do with
                this code below
              </label>
              <input
                id="access_code"
                type="text"
                name="access_code"
                className="magnified"
                placeholder="Custom Access Code"
                value={instructions.access_code}
                onChange={e => this.onChange(e.target.name, e.target.value)}
              />
            </div>
          )}
          <label htmlFor="additional">Additional Instructions</label>
          <SimplifiedWysiwyg
            content={instructions.additional}
            name="additional"
            onContentChange={this.onChange}
          />
        </div>
      </form>
    );
  }

  renderViewing() {
    const instructions = this.state.checkInInstructions;
    return (
      <section className="consolidated-item-form-viewing">
        <div className="consolidated-item-subsection">
          <h4>Access Code Type</h4>
          {this.codeTypes[(instructions?.access_code_type)]}
        </div>
        {this.renderCustomCode() && (
          <div className="consolidated-item-subsection">
            <h4>Access Code</h4>
            {instructions.access_code}
          </div>
        )}
        <div className="consolidated-item-subsection">
          <h4>Additional</h4>
          <div dangerouslySetInnerHTML={{ __html: instructions?.additional }} />
        </div>
      </section>
    );
  }

  // ---------------------------------------------
  // RENDER --------------------------------------
  // ---------------------------------------------
  render() {
    return (
      <figure
        className={`consolidated-item-form-section ${
          this.state.isEditing ? 'form-editing' : ''
        }`}
      >
        <ConsolidatedItemEditToggleHeader
          toggleEditing={this.toggleEditing}
          finishEditingAndRevert={() => this.finishEditing(false)}
          finishEditingAndUpdate={() => this.finishEditing(true)}
          attemptSave={this.attemptSave}
          isDirty={this.state.isDirty}
          isEditing={this.state.isEditing}
          isSaving={this.state.isSaving}
          title="Check-in Instructions"
          blockEdit={this.props.blockEdit}
        />
        {this.state.isEditing ? this.renderEditing() : this.renderViewing()}
      </figure>
    );
  }
}
