import React from 'react';
import { CustomerSidebarService } from 'adminApi';
import { CustomerContext } from 'adminContexts';
import displayError from 'sharedComponents/ErrorDisplay';

export default class CustomerProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: {},
      sidebarOpen: false,
      setCustomer: this.setCustomer,
      updateCustomer: this.updateCustomer,
      onSetSidebarOpen: this.onSetSidebarOpen,
      organizationID: this.props.organizationID,
      organization: this.props.organization,
      user: this.props.user,
      setActiveSection: this.setActiveSection,
      activeSection: null || 'opportunities',
      activeConversation: null,
      setActiveConversation: this.setActiveConversation,
      enterMessageDetailView: this.enterMessageDetailView
    };
  }

  setActiveSection = activeSection => {
    this.setState({ activeSection });
  };

  setActiveConversation = conversation => {
    if (!conversation) {
      return this.setState({ activeConversation: null });
    }

    CustomerSidebarService.fetchConversation(
      this.state.organizationID,
      conversation.customer_id,
      conversation.id
    ).then(response => {
      this.setState({ activeConversation: response.conversation });
    });
  };

  setCustomer = (customer, activeSection) => {
    CustomerSidebarService.getCustomer(
      this.state.organizationID,
      customer.id
    ).then(response => {
      this.setState(
        {
          customer: response,
          sidebarOpen: true
        },
        () => {
          if (activeSection) {
            return this.setActiveSection(activeSection);
          } else {
            this.setActiveSection('opportunities');
          }
        }
      );
    });
  };

  enterMessageDetailView = (customer, activeConversation) => {
    this.setActiveConversation(activeConversation);
    this.setCustomer(customer, 'messages');
  };

  updateCustomer = data => {
    const customer = this.state.customer;

    if (!customer) return null;

    CustomerSidebarService.editCustomer(
      this.state.organizationID,
      customer.id,
      data
    )
      .then(response => {
        this.setState({
          customer: response,
          sidebarOpen: true
        });
      })
      .catch(err => displayError({ message: 'Error editing customer', err }));
  };

  onSetSidebarOpen = sidebarOpen => {
    const updates = { sidebarOpen };

    if (!sidebarOpen) {
      updates.customer = {};
      updates.activeConversation = null;
    }

    this.setState(updates);
  };

  render() {
    return (
      <CustomerContext.Provider value={this.state}>
        {this.props.children}
      </CustomerContext.Provider>
    );
  }
}
