import React from 'react';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import WorkOrdersLink from 'adminNavbar/workflows/links/work-orders-link';
import SchedulingLink from 'adminNavbar/workflows/links/scheduling-link';
import WorkReportsLink from 'adminNavbar/workflows/links/work-reports-link';
import TemplatesLink from 'adminNavbar/workflows/links/templates-link';
import PermissionComponent from 'PermissionComponent';

const LinksDesktop = props => {
  return (
    <>
      <Navbar.LinkMenuColumn>
        <Navbar.LinkMenuGroup groupTitle="Workflows">
          <PermissionComponent user={props.user} permission="work_orders">
            <WorkOrdersLink {...props} />
          </PermissionComponent>
          <SchedulingLink {...props} />
          <PermissionComponent user={props.user} permission="work_reports">
            <WorkReportsLink {...props} />
          </PermissionComponent>
          <PermissionComponent user={props.user} permission="templates">
            <TemplatesLink {...props} />
          </PermissionComponent>
        </Navbar.LinkMenuGroup>
      </Navbar.LinkMenuColumn>
    </>
  );
};

export default LinksDesktop;
