import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Dropdown,
  IconFontAwesome,
  ListItemLink,
  PageHeader
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { RESERVATIONS_NAV_TAGS } from '../../../../../shared/nav';
import PermissionComponent from '../../../../PermissionComponent';
import { updateReservationDetails } from '../../../../redux/slices/reservations';
import { useDetectMobile } from '../../../../../shared/hooks';

const ReservationsDetails = props => {
  const dispatch = useDispatch();
  const { isTouch } = useDetectMobile();
  const [ownerOrFriendBooking, setOwnerOrFriendBooking] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [markCardInvalid, setMarkCardInvalid] = useState(false);
  const [cancelInvalidCard, setCancelInvalidCard] = useState(false);
  const [viewGuestReciept, setViewGuestReciept] = useState(false);
  const [emailGuest, setEmailGuest] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showRestoreOptions, setShowRestoreOptions] = useState(false);
  const [showUnarchive, setShowUnarchive] = useState(false);
  const [showUnCancel, setShowUnCancel] = useState(false);
  const [activeBooking, setActiveBooking] = useState(false);

  useEffect(
    () => {
      if (props.booking) {
        setOwnerOrFriendBooking(
          props.booking.booking_type === 'owner' ||
            props.booking.booking_type === 'friend'
        );
        setReadOnly(props.user.permissions.reservations_read_only);
        setMarkCardInvalid(props.booking.channel_id === 1);
        setCancelInvalidCard(
          props.booking.invalid_card_enqued &&
            moment(props.booking.invalid_card_enqued).add(1, 'd') < moment()
        );
        setViewGuestReciept(props.listing?.domain);
        setEmailGuest(props.customer.email);
        setIsAdmin(props.user.admin);
        setShowRestoreOptions(props.user);
        setShowUnarchive(props.booking.archived);
        setShowUnCancel(props.booking.cancelled && !props.vehicle_booking);
        setActiveBooking(!(props.booking.archived || props.booking.cancelled));
      }
    },
    [props.booking]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: RESERVATIONS_NAV_TAGS.PRIMARY,
        secondary: RESERVATIONS_NAV_TAGS.secondary.LIST_VIEW
      })
    );
  }, []);

  return (
    <PageHeader height="l" containerVariation="fluid">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/reservations',
            label: 'Reservations List'
          },
          {
            label: 'Booking Details'
          }
        ]}
      >
        {props.organization.organization_type === 'rv_fleet'
          ? 'Renter Name: '
          : 'Guest Name: '}
        <span>{props.customer.name}</span>
      </PageHeader.Left>
      <PageHeader.Right>
        <PermissionComponent
          user={props.user}
          permission="view_reservations"
          restriction="reservations_read_only"
        >
          <Dropdown
            alignPanel="bottomRight"
            controlLabel="Actions"
            panelContent={
              <>
                <Box paddingHorizontal="xxs">
                  {!readOnly && (
                    <>
                      <ListItemLink
                        type="button"
                        label="Send Check-in Instructions"
                        onClick={() => {
                          dispatch(
                            updateReservationDetails({ sendCheckIn: true })
                          );
                        }}
                      />
                      <ListItemLink
                        type="button"
                        label="Send Payment Request"
                        onClick={() => {
                          dispatch(
                            updateReservationDetails({
                              sendPaymentRequest: true
                            })
                          );
                        }}
                      />
                    </>
                  )}
                  {markCardInvalid && (
                    <ListItemLink
                      type="button"
                      label="Mark Guest Card Invalid"
                      onClick={() => {
                        dispatch(
                          updateReservationDetails({
                            markGuestCardInvalid: true
                          })
                        );
                      }}
                    />
                  )}
                  {cancelInvalidCard && (
                    <ListItemLink
                      type="button"
                      label="Cancel Due to Inactive Card"
                      onClick={() => {
                        dispatch(
                          updateReservationDetails({ cancelInactiveCard: true })
                        );
                      }}
                    />
                  )}
                  {viewGuestReciept && (
                    <ListItemLink
                      type="link"
                      openNewTab
                      label="View Guest Receipt"
                      href={`https://${
                        props.listing.domain.url
                      }/my-bookings/receipt/${props.booking.booking_code}`}
                    />
                  )}
                  {emailGuest && (
                    <ListItemLink
                      type="link"
                      openNewTab
                      label="Email Guest"
                      href={`mailto:${
                        props.customer.email
                      }?subject=Update for Booking: ${
                        props.booking.booking_code
                      }`}
                    />
                  )}
                  {!isTouch && (
                    <ListItemLink
                      type="button"
                      label="Print Reservation"
                      onClick={() => {
                        dispatch(
                          updateReservationDetails({ clickPrintButton: true })
                        );
                      }}
                    />
                  )}
                  {activeBooking && (
                    <PermissionComponent
                      user={props.user}
                      permission="view_reservations"
                      restriction={
                        ownerOrFriendBooking || 'reservations_read_only'
                      }
                    >
                      <Divider paddingTop="xxs" paddingBottom="xs" />
                      <ListItemLink
                        type="button"
                        label="Cancel Reservation"
                        onClick={() => {
                          dispatch(
                            updateReservationDetails({
                              cancelBooking: true
                            })
                          );
                        }}
                        icon={<IconFontAwesome name="ban" />}
                      />
                    </PermissionComponent>
                  )}
                </Box>
                {showRestoreOptions && (showUnarchive || showUnCancel) && (
                  <>
                    <Divider paddingTop="xxs" paddingBottom="xs" />
                    <Box paddingHorizontal="xxs">
                      {showUnarchive && (
                        <ListItemLink
                          type="button"
                          label="Unarchive Reservation"
                          onClick={() => {
                            dispatch(
                              updateReservationDetails({
                                unarchiveBooking: true
                              })
                            );
                          }}
                        />
                      )}
                      {showUnCancel && (
                        <ListItemLink
                          type="button"
                          label="Uncancel Reservation"
                          onClick={() => {
                            dispatch(
                              updateReservationDetails({
                                uncancelBooking: true
                              })
                            );
                          }}
                        />
                      )}
                    </Box>
                  </>
                )}
                {isAdmin && (
                  <>
                    <Divider paddingTop="xxs" paddingBottom="xs" />
                    <Box paddingHorizontal="xxs">
                      <ListItemLink
                        type="button"
                        label="Delete Reservation"
                        onClick={() => {
                          dispatch(
                            updateReservationDetails({ deleteBooking: true })
                          );
                        }}
                        isDanger
                        icon={<IconFontAwesome name="trashAlt" />}
                      />
                    </Box>
                  </>
                )}
              </>
            }
          />
        </PermissionComponent>
      </PageHeader.Right>
    </PageHeader>
  );
};

export default ReservationsDetails;
