import React from 'react';

import SimpleSelect from './SimpleSelect';

const defaultSelectData = {
  model: 'Brand',
  select: ['id', 'name']
};

const BrandSelect = props => (
  <SimpleSelect
    selectData={{ ...defaultSelectData, ...props.defaultSelectData }}
    label={'Website'}
    placeholder={'Select a website...'}
    name={'brand_select'}
    {...props}
  />
);

export default BrandSelect;
