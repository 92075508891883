import React from 'react';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import EmployeesLink from 'adminNavbar/people/links/employees-link';
import UserGroupsLink from 'adminNavbar/people/links/user-groups-link';
import LeadsListLink from 'adminNavbar/people/links/leads-list-link';
import CustomersListLink from 'adminNavbar/people/links/customers-list-link';
import CrmLink from 'adminNavbar/people/links/crm-link';
import PermissionComponent from 'PermissionComponent';

const LinksDesktop = props => {
  return (
    <>
      <Navbar.LinkMenuColumn>
        <Navbar.LinkMenuGroup groupTitle="People">
          <EmployeesLink variation="secondary" {...props} />
          <PermissionComponent user={props.user} permission="groups">
            <UserGroupsLink {...props} />
          </PermissionComponent>
          <PermissionComponent user={props.user} permission="leads">
            <LeadsListLink {...props} />
          </PermissionComponent>
          <PermissionComponent user={props.user} permission="customers">
            <CustomersListLink {...props} />
          </PermissionComponent>
          <PermissionComponent user={props.user} permission="pipeline">
            <CrmLink {...props} />
          </PermissionComponent>
        </Navbar.LinkMenuGroup>
      </Navbar.LinkMenuColumn>
    </>
  );
};

export default LinksDesktop;
