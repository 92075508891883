import React from 'react';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';
import find from 'lodash/find';
import get from 'lodash/get';
import { DirectCheckbox, InputMoney } from 'adminComponents';
import WidgetDatePicker from 'sharedComponents/WidgetDatePicker';
import { Delete } from 'adminComponents/icons';
import UnitService from 'adminApi/UnitService';
import moment from 'moment';
import { getVehicleAvailability } from 'adminApi/VehicleService';

const options = [
  { value: 'override', label: 'Override the amount' },
  { value: 'increase', label: 'Increase the amount' },
  { value: 'decrease', label: 'Decrease the amount' }
];

export default class LineItemEditor extends React.Component {
  state = {
    changeType: null,
    changeAmount: null,
    startDate: this.props.startDate,
    endDate: this.props.endDate,
    initialStartDate: this.props.startDate,
    initialEndDate: this.props.endDate,
    editingDates: this.props.editingDates,
    availabilityCalendar: null,
    editing: false
  };

  componentDidMount() {
    this.fetchAvailability();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.changeType === this.state.changeType &&
      nextProps.changeAmount === this.state.changeAmount
    ) {
      return null;
    }

    return this.setState({
      changeType: nextProps.changeType,
      changeAmount: nextProps.changeAmount
    });
  }

  fetchAvailability = () => {
    const { organizationID, unitID, vehicleBooking } = this.props;
    if (vehicleBooking) {
      getVehicleAvailability({
        orgId: organizationID,
        vehicleId: unitID
      }).then(res => {
        this.setState({ availabilityCalendar: res.availability_calendar });
      });
    } else {
      UnitService.getUnitAvailability(organizationID, unitID).then(response => {
        this.setState({ availabilityCalendar: response.availability_calendar });
      });
    }
  };

  onDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate }, () => {
      this.props.onDatesChange &&
        this.props.onDatesChange({ startDate, endDate });
    });
  };

  toggleEditingDates = () => {
    const updates = { editingDates: !this.state.editingDates };
    if (!updates.editingDates) {
      updates.startDate = moment(this.state.initialStartDate);
      updates.endDate = moment(this.state.initialEndDate);
    }
    this.setState(updates);
  };

  renderBookingRangeEditor = () => {
    if (this.props.lineItem.item_type !== 'room_rate') {
      return null;
    }

    if (this.props.disableDateEditing) {
      return null;
    }

    return (
      <div>
        <DirectCheckbox
          name="editingDates"
          checked={this.state.editingDates}
          onChange={this.toggleEditingDates}
          labelText="I would like to adjust the dates of this reservation"
        />
        {this.state.editingDates && this.state.availabilityCalendar && (
          <WidgetDatePicker
            bookingCalendar={this.state.availabilityCalendar}
            organizationID={this.props.organizationID}
            unitID={this.props.unitID}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            initialStartDate={this.state.initialStartDate}
            initialEndDate={this.state.initialEndDate}
            onDatesChange={this.onDatesChange}
          />
        )}
      </div>
    );
  };

  renderChangeForm = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ width: '180px', marginRight: '16px' }}>
          <label>Change Type:</label>
          <InputSelect
            name="change_type"
            className="magnified"
            options={options}
            isClearable={false}
            isSearchable={false}
            placeholder="Select..."
            onChange={option => {
              const newChangeAmount =
                option.value === 'override' &&
                get(this, 'props.lineItem.total_cents', 0) / 100.0;
              return this.setState({
                changeType: option.value,
                changeAmount: newChangeAmount || 0
              });
            }}
            value={find(options, { value: this.state.changeType })}
          />
        </div>
        {this.state.changeType && (
          <InputMoney
            name="change_amount"
            label="Amount:"
            currency={this.props.currency}
            setMoney={changeAmount => this.setState({ changeAmount })}
            value={this.state.changeAmount}
            containerStyles={{ marginRight: '16px' }}
            symbolStyles={{ padding: '7px 12px', borderRadius: '4px 0 0 4px' }}
            inputStyles={{ padding: '7px 12px', borderRadius: '0 4px 4px 0' }}
          />
        )}
        {this.state.changeType &&
          this.state.changeAmount !== null &&
          this.calculateButton('Calculate')}
        <a onClick={this.props.onCancel}>Cancel</a>
      </div>
      {!this.state.editing && (
        <Delete
          style={{
            cursor: 'pointer'
          }}
          onClick={() => this.deleteLineItem()}
        />
      )}
    </div>
  );

  calculateButton = text => {
    return (
      <div style={{ marginRight: '16px' }}>
        <label>&nbsp;</label>
        <a
          className="button"
          onClick={() => {
            this.props.ownerOrFriendBooking
              ? this.props.onCalculateClick({
                  changeType: 'increase',
                  changeAmount: 0.0
                })
              : this.props.onCalculateClick(this.state);
          }}
          style={{ padding: '13px 24px' }}
        >
          {text}
        </a>
      </div>
    );
  };

  deleteLineItem = () => {
    this.setState({ editing: true }, () => {
      this.props.onDeleteClick('delete', 0);
    });
  };

  renderDisclaimer = () => {
    if (this.props.lineItem.item_type === 'discount') {
      return (
        <div>
          <h6 style={{ fontSize: 'smaller', fontWeight: 'lighter' }}>
            * Once you have edited the discount amount, it will not be
            recalculated with future edits
          </h6>
        </div>
      );
    }
  };

  render() {
    if (!this.props.lineItem) {
      return null;
    }

    const { ownerOrFriendBooking } = this.props;
    return (
      <div style={{ marginBottom: '16px' }}>
        <h4 style={{ textAlign: 'center' }}>
          Editing {this.props.lineItem.name}
        </h4>
        {this.renderBookingRangeEditor()}
        {this.renderDisclaimer()}
        {ownerOrFriendBooking
          ? this.calculateButton('Apply')
          : this.renderChangeForm()}
      </div>
    );
  }
}

LineItemEditor.defaultProps = {
  onCancel: () => null
};
