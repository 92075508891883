import React from 'react';
import PropTypes from 'prop-types';
import { ajaxDataWithAuthorization } from 'apiClient';
import { paymentProcessorOptions } from 'sharedOptions';
import { dispatchError } from '../../../components/error-alert';
import ConsolidatedItemEditToggleHeader from '../../../components/consolidated-item-edit-toggle-header';

export default class ManageOrganizationFieldsBusiness extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired
  };

  constructor(props, _railsContext) {
    super(props);

    this.state = {
      company_type: this.props.organization.company_type,
      company_type_cache: this.props.organization.company_type,
      organization_type: this.props.organization.organization_type,
      organization_type_cache: this.props.organization.organization_type,
      payment_processor: this.props.organization.payment_processor,
      payment_processor_cache: this.props.organization.payment_processor,
      isDirty: false,
      isEditing: false,
      isSaving: false,
      isSaved: false
    };
  }

  onChange = e => {
    e.preventDefault();
    const stateChange = {};
    stateChange[e.target.name] = e.target.value;
    stateChange.isDirty = true;
    this.setState(stateChange);
  };

  toggleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  finishEditingAndRevert = () => {
    const stateChange = {};
    stateChange.company_type = this.state.company_type_cache;
    stateChange.organization_type = this.state.organization_type_cache;
    stateChange.payment_processor = this.state.payment_processor_cache;
    stateChange.isDirty = false;
    stateChange.isEditing = false;
    stateChange.isSaving = false;
    stateChange.isSaved = false;
    this.setState(stateChange);
  };

  finishEditingAndUpdate = () => {
    const stateChange = {};
    stateChange.company_type_cache = this.state.company_type;
    stateChange.organization_type_cache = this.state.organization_type;
    stateChange.payment_processor_cache = this.state.payment_processor;
    stateChange.isDirty = false;
    stateChange.isEditing = false;
    stateChange.isSaving = false;
    stateChange.isSaved = true;
    this.setState(stateChange);
  };

  attemptSave = () => {
    this.setState(
      {
        isSaving: true,
        isSaved: false
      },
      () => {
        $.ajax(
          ajaxDataWithAuthorization({
            type: 'PATCH',
            url: `/api/organizations/${this.props.organization.id}`,
            context: this,
            dataType: 'json',
            data: {
              company_type: this.state.company_type,
              organization_type: this.state.organization_type,
              payment_processor: this.state.payment_processor
            }
          })
        )
          .done(function(data) {
            this.finishEditingAndUpdate();
          })
          .fail(function(jqXhr) {
            dispatchError();
            console.warn(jqXhr);
            this.finishEditingAndRevert();
          });
      }
    );
  };

  renderEditing() {
    const organizationTypeOptions = [
      { value: 'bnb', label: 'Bed and Breakfast' },
      { value: 'hotel', label: 'Hotel' },
      { value: 'hostel', label: 'Hostel' },
      { value: 'vacation_rental', label: 'Vacation rental' },
      { value: 'rv_fleet', label: 'RV Fleet' }
    ];
    const companyTypeOptions = [
      { value: 'corporation', label: 'Corporation' },
      { value: 'sole_prop', label: 'Sole proprietorship' },
      { value: 'non_profit', label: 'Non-profit organization' },
      { value: 'partnership', label: 'Partnership' },
      { value: 'llc', label: 'LLC' },
      { value: 'other', label: 'Other' }
    ];
    return (
      <form className="consolidated-item-form-editing">
        <section className="consolidated-item-subsection three-col three-col-33-33-33">
          <div>
            <label>Company Type</label>
            <div className="select-wrapper">
              <select
                className="magnified"
                name="company_type"
                onChange={this.onChange}
                value={this.state.company_type}
              >
                {companyTypeOptions.map(type => (
                  <option value={type.value} key={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <label>Organization Type</label>
            <div className="select-wrapper">
              <select
                className="magnified"
                name="organization_type"
                onChange={this.onChange}
                value={this.state.organization_type}
              >
                {organizationTypeOptions.map(type => (
                  <option value={type.value} key={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <label>Payment Processor</label>
            <div className="select-wrapper">
              <select
                className="magnified"
                name="payment_processor"
                onChange={this.onChange}
                value={this.state.payment_processor}
              >
                {paymentProcessorOptions.map(type => (
                  <option value={type.value} key={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </section>
      </form>
    );
  }

  renderViewing() {
    return (
      <section className="consolidated-item-form-viewing">
        <section className="consolidated-item-subsection three-col three-col-33-33-33">
          <div>
            <h4>Company Type</h4>
            {this.state.company_type}
          </div>
          <div>
            <h4>Organization Type</h4>
            {this.state.organization_type}
          </div>
          <div>
            <h4>Payment Processor</h4>
            {this.state.payment_processor}
          </div>
        </section>
      </section>
    );
  }

  render() {
    return (
      <figure
        className={`consolidated-item-form-section ${
          this.state.isEditing ? 'form-editing' : ''
        }`}
      >
        <ConsolidatedItemEditToggleHeader
          toggleEditing={this.toggleEditing}
          finishEditingAndRevert={this.finishEditingAndRevert}
          finishEditingAndUpdate={this.finishEditingAndUpdate}
          attemptSave={this.attemptSave}
          isDirty={this.state.isDirty}
          isEditing={this.state.isEditing}
          isSaving={this.state.isSaving}
          title="Business Settings"
        />
        {this.state.isEditing ? this.renderEditing() : this.renderViewing()}
      </figure>
    );
  }
}
