import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const MySettingsBillingLink = ({
  activeRoutes,
  closeMobileNav,
  navTags,
  ...props
}) => {
  const { organization } = props;

  return (
    <Navbar.Link
      href={`/dashboard/${organization.subdomain}/settings/billing`}
      variation="secondary"
      linkType="link"
      isActive={activeRoutes.secondary === navTags.secondary.MY_BILLING}
    >
      Billing
    </Navbar.Link>
  );
};

MySettingsBillingLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  navTags: PropTypes.object
};

export default MySettingsBillingLink;
