import React, { useEffect, useState } from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import displayError from 'sharedComponents/ErrorDisplay';
import RoomTypeService from 'adminApi/RoomTypeService';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';

const RoomTypesDetails = props => {
  const dispatch = useDispatch();
  const [roomTypeName, setRoomTypeName] = useState('');

  useEffect(
    () => {
      RoomTypeService.get(props.organization.id, props.roomTypeId)
        .then(response => {
          setRoomTypeName(response?.room_type?.name);
        })
        .catch(err =>
          displayError({ message: 'Error Loading Room Type', err })
        );
    },
    [props]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: LISTINGS_NAV_TAGS.PRIMARY,
        secondary: LISTINGS_NAV_TAGS.secondary.ROOM_TYPES
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/room-types',
            label: 'Room Types'
          },
          {
            label: 'Details'
          }
        ]}
      >
        {roomTypeName}
      </PageHeader.Left>
    </PageHeader>
  );
};

export default RoomTypesDetails;
