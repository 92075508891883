import React from 'react';
import find from 'lodash/find';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';

import { CountryOptions } from 'adminComponents';

const CountrySelect = props => {
  return (
    <div style={props.style}>
      <label htmlFor="currency">Country</label>
      <InputSelect
        name="country"
        className="magnified"
        options={CountryOptions}
        isClearable={false}
        isSearchable={false}
        placeholder="Select country"
        value={find(CountryOptions, option => {
          return option.value === props.country;
        })}
        onChange={props.setCountry}
      />
    </div>
  );
};

export default CountrySelect;
