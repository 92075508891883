import React from 'react';
import DefaultRight from './linkCollections/default/default-right';
import RvFleetRight from './linkCollections/rv-fleet/rv-fleet-right';

const NavbarRight = props => {
  const renderLinkCollection = () => {
    switch (props.linkCollection) {
      case 'rv-fleet':
        return <RvFleetRight {...props} />;
      case 'admin':
      default:
        return <DefaultRight {...props} />;
    }
  };
  return <>{renderLinkCollection()}</>;
};

export default NavbarRight;
