import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  FormGroupHeader,
  IconFontAwesome,
  InfoPanel,
  InputCheckbox,
  Spacer,
  TextH2,
  TextTitle
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectNewOrganization,
  updateNewOrganization
} from 'reduxSlices/createOrganization';
import TermsConditionsAug2023 from '../../../../components/terms-conditions-aug2023';
import TermsConditionsRvsMay2024 from '../../../../components/terms-conditions-rvs-may2024';

const CreateOrganizationTermsOfService = ({
  handleDisableNextButton,
  user
}) => {
  const [acceptsTerms, setAcceptsTerms] = useState(false);
  const dispatch = useDispatch();
  const organization = useSelector(selectNewOrganization);

  useEffect(
    () => {
      handleDisableNextButton(!organization.acceptsTerms);
    },
    [organization]
  );

  return (
    <Box className="newOrganization__formWrapper" paddingBottom="l">
      <TextTitle>Terms of Service</TextTitle>
      <Spacer />
      <InfoPanel
        icon={<IconFontAwesome name="exclamationTriangle" />}
        color="yellow"
        headline="Please scroll to the bottom to indicate you've read and understood this agreement"
      />
      <Divider padding="l" />
      {user.rvshare_user_id || organization.organizationType === 'rv_fleet' ? (
        <TermsConditionsRvsMay2024 isContained={false} />
      ) : (
        <TermsConditionsAug2023 isContained={false} />
      )}
      {/* <TermsConditions isContained={false} /> */}
      <Spacer />
      <FormGroupHeader horizontalPadding="none" title="Terms Agreement" />
      <TextH2>
        Please indicate you've read and agree with these terms to continue
        creating your new organization
      </TextH2>
      <Spacer size="m" />
      <InputCheckbox
        onChange={() => {
          setAcceptsTerms(!acceptsTerms);
          dispatch(updateNewOrganization({ acceptsTerms: !acceptsTerms }));
        }}
      >
        <span>
          I have read and agree to the Manager License Agreement and{' '}
          <a
            href="https://directsoftware.com/legal/terms-of-use"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            href="https://www.directsoftware.com/legal/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>{' '}
          for using DIRECT®
        </span>
      </InputCheckbox>
    </Box>
  );
};

CreateOrganizationTermsOfService.propTypes = {
  handleDisableNextButton: PropTypes.func
};

CreateOrganizationTermsOfService.defaultProps = {};

export default CreateOrganizationTermsOfService;
