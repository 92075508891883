import React from 'react';
import { PortalWithState } from 'react-portal';
import {
  CallToActionButton,
  FlexBox,
  IconButton,
  IconFontAwesome,
  Spacer
} from '@directsoftware/ui-kit-web-admin';

export default class PortalModal extends React.Component {
  constructor(props) {
    super(props);
  }

  renderSubmitAction = closePortal => {
    if (this.props.closeOnSubmit) {
      return this.props.submitAction(closePortal);
    }
    return this.props.submitAction;
  };

  renderCancelAction = closePortal => {
    if (this.props.hideCancelAction) return null;

    if (this.props.cancelAction) {
      return this.props.cancelAction(closePortal);
    }

    return (
      <CallToActionButton variation="secondary" isGhost onClick={closePortal}>
        Cancel
      </CallToActionButton>
    );
  };

  render() {
    return (
      <PortalWithState
        closeOnOutsideClick={!this.props.disableCloseOnOutsideClick}
        onClose={this.props.onClose}
        closeOnEsc
        defaultOpen={this.props.defaultOpen}
      >
        {({ openPortal, closePortal, isOpen, portal }) => (
          <React.Fragment>
            {this.props.openByClickOn(openPortal)}
            {portal(
              <aside className="consolidated-modal-overlay">
                {!this.props.header && !this.props.hideX && (
                  <IconButton
                    onClick={this.props.closePortal}
                    variation="secondary"
                    isGhost
                  >
                    <IconFontAwesome name="times" />
                  </IconButton>
                )}
                <section
                  className="consolidated-modal"
                  style={this.props.modalStyles}
                >
                  {this.props.header && (
                    <header className="consolidated-modal-header">
                      <FlexBox
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{this.props.header}</span>
                        {!this.props.hideX && (
                          <IconButton
                            onClick={closePortal}
                            variation="secondary"
                            isGhost
                            size="dense"
                          >
                            <IconFontAwesome name="times" />
                          </IconButton>
                        )}
                      </FlexBox>
                    </header>
                  )}
                  {typeof this.props.children === 'function'
                    ? this.props.children(isOpen)
                    : this.props.children}
                  {this.props.submitAction && (
                    <FlexBox alignItems="center" paddingVertical="s">
                      {this.renderSubmitAction(closePortal)}
                      <Spacer size="xs" direction="horizontal" />
                      {this.renderCancelAction(closePortal)}
                    </FlexBox>
                  )}
                </section>
              </aside>
            )}
          </React.Fragment>
        )}
      </PortalWithState>
    );
  }
}

PortalModal.defaultProps = {
  openByClickOn: _openPortal => {},
  defaultOpen: false
};
