import React, { useState } from 'react';
import SimpleSelect from 'adminComponents/Selects/SimpleSelect';
import LedgerAccountSelect from 'adminComponents/Selects/LedgerAccountSelect';
import { nativeSymbolFromValue } from 'sharedComponents/currency-display';
import {
  Box,
  FormField,
  FormGroupHeader,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import AddExpenseRow from './AddExpenseRow';
import ExpensesRow from './ExpensesRow';

const CreateBillForm = ({
  onChange,
  newBill,
  currency,
  addExpense,
  deleteExpense,
  organization
}) => {
  const payTo = [{ value: 0, label: 'Company' }, { value: 1, label: 'Vendor' }];

  const styles = {
    fullWidth: {
      width: '100%',
      marginBottom: '5%'
    },
    symbol: {
      height: '36px',
      padding: '8px'
    },
    lineBreak: {
      background: '#EDEDED',
      border: 'none',
      height: '1px'
    },
    padded: {
      padding: '5px'
    },
    lineDiv: {
      marginTop: '25px',
      textAlign: 'left'
    },
    ledgerSelect: {
      width: '40%',
      padding: '5px'
    }
  };

  const customStyles = {
    menu: base => ({
      ...base,
      textAlign: 'left'
    })
  };

  const [isFocusedPostDate, setIsFocusedPostDate] = useState(false);
  const [isFocusedDueDate, setIsFocusedDueDate] = useState(false);

  return (
    <>
      <FormGroupHeader
        horizontalPadding="none"
        removeTopBorder
        removeTopMargin
        title="BILL DETAILS"
      />
      <Box>
        <FormField
          labelText="Pay To"
          labelHtmlFor="pay_to"
          inputType="select"
          inputProps={{
            onChange: option => onChange('pay_to', option.value),
            options: payTo,
            inputWidth: 's',
            value: payTo.filter(p => p.value === newBill.pay_to)[0] || null
          }}
        />
      </Box>
      <Box>
        {newBill.pay_to === 1 || newBill.pay_to === '' ? (
          <SimpleSelect
            label="Vendor"
            selectData={{
              model: 'Employee',
              select: ['id', 'name'],
              where: ['role = ? OR role = ?', 4, 5],
              order: 'name'
            }}
            onSelect={selection => {
              onChange('employee_id', selection.id);
            }}
            defaultValue={options => {
              return options.find(o => o.value === newBill.employee_id) || null;
            }}
            isSearchable
            isDisabled={newBill.pay_to === ''}
            useDesignKit
          />
        ) : (
          <FormField
            labelText="Company"
            labelHtmlFor="employee_id"
            inputType="text"
            inputProps={{
              value: organization.name,
              disabled: true
            }}
          />
        )}
      </Box>
      <Box>
        <FormField
          labelText="Amount"
          labelHtmlFor="amount"
          inputType="currency"
          inputProps={{
            value: newBill.amount,
            onValueChange: (value, name, values) => {
              onChange('amount', value);
            },
            inputWidth: 's',
            currencyPrefix: nativeSymbolFromValue(organization.currency)
          }}
        />
      </Box>
      <Box>
        <FormField
          labelText="Post Date"
          labelHtmlFor="post_date"
          inputType="date"
          inputProps={{
            date: newBill.post_date,
            onDateChange: selectedDate => {
              onChange('post_date', selectedDate);
            },
            isFocused: isFocusedPostDate,
            onFocusChange: ({ focused }) => setIsFocusedPostDate(focused),
            inputWidth: 'm'
          }}
        />
      </Box>
      <Box>
        <FormField
          labelText="Due Date"
          labelHtmlFor="due_date"
          inputType="date"
          inputProps={{
            date: newBill.due_date,
            onDateChange: selectedDate => {
              onChange('due_date', selectedDate);
            },
            isFocused: isFocusedDueDate,
            onFocusChange: ({ focused }) => setIsFocusedDueDate(focused),
            inputWidth: 'm'
          }}
        />
      </Box>
      <FormGroupHeader horizontalPadding="none" title="OPTIONAL" />
      <Box>
        <FormField
          labelText="Invoice #"
          labelHtmlFor="invoice_num"
          inputType="text"
          inputProps={{
            value: newBill.invoice_num,
            onChange: event => {
              onChange('invoice_num', event.target.value);
            },
            inputWidth: 's'
          }}
        />
      </Box>
      <Box>
        <FormField
          labelText="Memo"
          labelHtmlFor="memo"
          inputType="textarea"
          inputProps={{
            value: newBill.memo,
            onChange: event => {
              onChange('memo', event.target.value);
            }
          }}
        />
      </Box>
      <FormGroupHeader horizontalPadding="none" title="EXPENSE DETAILS" />
      {newBill.expenses.length > 0 && (
        <Box>
          <ExpensesRow
            expenses={newBill.expenses}
            deleteExpense={deleteExpense}
            organization={organization}
            useDesignKit
          />
          <Spacer size="xs" />
        </Box>
      )}
      <AddExpenseRow
        currency={currency}
        addExpense={addExpense}
        organization={organization}
      />
      <FormGroupHeader horizontalPadding="none" title="LEDGER ACCOUNTS" />
      <Box>
        <LedgerAccountSelect
          onSelect={option => onChange('debit_account_id', option.id)}
          name="debit_account"
          label="Payable Account"
          defaultValue={options => {
            return options.find(o => o.value === newBill.dedit_account_id);
          }}
          useDesignKit
        />
      </Box>
      <Box>
        <LedgerAccountSelect
          onSelect={option => onChange('credit_account_id', option.id)}
          name="credit_account"
          label="Expense Account"
          defaultValue={options => {
            return options.find(o => o.value === newBill.credit_account_id);
          }}
          useDesignKit
        />
      </Box>
    </>
  );
};
export default CreateBillForm;
