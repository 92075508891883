// Dependencies
// -----------------------------------------------
import React from 'react';
import styled from 'styled-components';

// Components
// -----------------------------------------------
import FilterableSearchbar from 'adminComponents/Searchbar';
import { Box, FlexBox, Spacer } from '@directsoftware/ui-kit-web-admin';
import DirectTableFilter from './direct-table-filter';
import DirectUiKitTable from './direct-ui-kit-table';

// Styles
// -----------------------------------------------
const ScrollContainer = styled.div`
  height: calc(740px - 190px);
  overflow: hidden;
  overflow-y: scroll;
`;

// -----------------------------------------------
// COMPONENT->FILTERABLE-TABLE -------------------
// -----------------------------------------------
class FilterableTable extends React.Component {
  // Constructor
  // ---------------------------------------------
  constructor(props, _railsContext) {
    super(props);
  }

  // Default Props
  // ---------------------------------------------
  static defaultProps = {
    filterOptions: [],
    placeholder: 'Search...',
    removeScrollContainer: false
  };

  renderTable = () => {
    return (
      <DirectUiKitTable
        data={this.props.data}
        pages={this.props.pages}
        loading={this.props.loading}
        defaultPageSize={this.props.defaultPageSize}
        onFetchData={this.props.onFetchData}
        organization={this.props.organization}
        columns={this.props.columns}
        paginateOnly={this.props.paginateOnly}
        removeScrollContainer={this.props.removeDesignKitScrollContainer}
        showNoResultsImage={this.props.showNoResultsImage}
        noResultsHeadline={this.props.noResultsHeadline}
        noResultsDescription={this.props.noResultsDescription}
      />
    );
  };

  // Render
  // ---------------------------------------------
  render() {
    return (
      <div>
        {this.props.useControlPanel ? (
          <>
            <FlexBox gap="xs">
              <Box flex="1" style={{ maxWidth: 350 }}>
                {this.props.showAlternateSearchBar ? (
                  <FilterableSearchbar
                    placeholder={this.props.placeholder}
                    filterOptions={this.props.filterOptions}
                    onFilterChange={this.props.onFilterChange}
                    onClear={this.props.onClear}
                    useDesignKit
                    removeBottomSpacer
                    inputWidth={this.props.searchInputWidth}
                    isDense
                  />
                ) : (
                  <DirectTableFilter
                    name={this.props.name}
                    label={this.props.label}
                    placeholder={this.props.placeholder}
                    filter={this.props.filter}
                    onFilterChange={this.props.onFilterChange}
                    useDesignKit
                    removeBottomSpacer
                    inputWidth={this.props.searchInputWidth}
                    isDense
                  />
                )}
              </Box>
            </FlexBox>
            <Spacer />
          </>
        ) : (
          <>
            {this.props.showAlternateSearchBar ? (
              <FilterableSearchbar
                placeholder={this.props.placeholder}
                filterOptions={this.props.filterOptions}
                onFilterChange={this.props.onFilterChange}
                onClear={this.props.onClear}
              />
            ) : (
              <DirectTableFilter
                name={this.props.name}
                label={this.props.label}
                placeholder={this.props.placeholder}
                filter={this.props.filter}
                onFilterChange={this.props.onFilterChange}
              />
            )}
          </>
        )}
        {this.props.removeScrollContainer ? (
          this.renderTable()
        ) : (
          <ScrollContainer>{this.renderTable()}</ScrollContainer>
        )}
      </div>
    );
  }
}

// Export
// -----------------------------------------------
export default FilterableTable;
