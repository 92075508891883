import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from '@directsoftware/ui-kit-web-admin';

class MinimumAgeSelect extends React.Component {
  state = {
    minimum_age: this.props.minimum_age
  };

  handleChange = value => {
    this.setState({ minimum_age: value }, () => {
      this.props.onChange({ minimum_age: this.state.minimum_age });
    });
  };

  render() {
    const { minimum_age } = this.state;
    return (
      <>
        <div className="minimum-age-select__container">
          <FormField
            inputType="incrementer"
            labelText="Minimum Age"
            labelHtmlFor="minimum_age"
            inputProps={{
              id: 'minimum_age',
              min: 18,
              max: 100,
              name: 'minimum_age',
              onChange: this.handleChange,
              value: minimum_age
            }}
          />
        </div>
      </>
    );
  }
}

MinimumAgeSelect.propTypes = {
  minimum_age: PropTypes.number,
  onChange: PropTypes.func
};

MinimumAgeSelect.defaultProps = {
  minimum_age: 21
};

export default MinimumAgeSelect;
