import React from 'react';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';
import Spinner from './spinner';

export default class CsvUploader extends React.Component {
  static propTypes = {
    csv: PropTypes.object
  };

  constructor(props, _railsContext) {
    super(props);
    this.state = {
      csv: '',
      isLoaded: false,
      isLoading: false,
      errorMessage: null
    };
  }

  onDropzoneDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      this.attemptUploadFile(acceptedFiles[0]);
    }
  };

  attemptUploadFile = fileQueued => {
    this.setState({ isLoaded: false, isLoading: true }, () => {
      this.uploadFile(fileQueued);
      this.setState({ isLoaded: true, isLoading: false, errorMessage: null });
    });
  };

  uploadFile = fileQueued => {
    return this.props.onFileUpload(fileQueued);
  };

  renderLoading() {
    return <Spinner />;
  }

  renderViewing() {
    if (this.state.isLoaded) {
      return (
        <div style={{ textAlign: 'center' }}>
          <p>CSV was uploaded successfully!</p>
        </div>
      );
    } else {
      return (
        <Dropzone
          activeClassName=""
          className={`card ${!this.state.isLoaded ? 'empty-state' : ''}`}
          multiple={false}
          onDrop={this.onDropzoneDrop}
          ref={node => {
            this.dropzone = node;
          }}
        >
          <div>
            <a className="button">Upload CSV</a>
            <p>or drag it into this box.</p>
          </div>
        </Dropzone>
      );
    }
  }

  render() {
    return (
      <section>
        {this.state.isLoading ? this.renderLoading() : this.renderViewing()}

        {this.state.errorMessage && !this.state.isLoaded && (
          <p style={{ padding: '16px', textAlign: 'center' }}>
            {this.state.errorMessage}
          </p>
        )}
      </section>
    );
  }
}
