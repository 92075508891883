// Dependencies
// -----------------------------------------------
import React from 'react';

// -----------------------------------------------
// COMPONENT->SINGLE-MENU ------------------------
// -----------------------------------------------
const SingleMenu = props => {
  return (
    <section className="consolidated-item-menu">
      <ul>
        <li className={props.active == 'manage' ? 'active' : ''}>
          <a
            href={`/dashboard/${props.organization.subdomain}/properties/${
              props.property.id
            }`}
          >
            Property Overview
          </a>
        </li>
        <li className={props.active == 'images' ? 'active' : ''}>
          <a
            href={`/dashboard/${props.organization.subdomain}/properties/${
              props.property.id
            }/images`}
          >
            Property Images
          </a>
        </li>
        <li className={props.active == 'availability' ? 'active' : ''}>
          <a
            href={`/dashboard/${props.organization.subdomain}/properties/${
              props.property.id
            }/units/${props.unit.id}/availability`}
          >
            Default Availability
          </a>
        </li>
        {props.listing ? (
          <li className={props.active == 'pricing' ? 'active' : ''}>
            <a
              href={`/dashboard/${props.organization.subdomain}/properties/${
                props.property.id
              }/units/${props.unit.id}/listings/${props.listing.id}/pricing`}
              className={!props.defaultAvailabilityComplete && 'disabled'}
              style={{ color: !props.defaultAvailabilityComplete && '#666666' }}
            >
              Default Pricing
            </a>
          </li>
        ) : null}
        {props.listing ? (
          <li
            className={props.active == 'availability-calendar' ? 'active' : ''}
          >
            <a
              href={`/dashboard/${props.organization.subdomain}/properties/${
                props.property.id
              }/units/${props.unit.id}/listings/${props.listing.id}/calendar`}
              className={!props.defaultPricingComplete && 'disabled'}
              style={{ color: !props.defaultPricingComplete && '#666666' }}
            >
              Calendar
            </a>
          </li>
        ) : null}
        <li className={props.active == 'reservations' ? 'active' : ''}>
          <a
            href={`/dashboard/${props.organization.subdomain}/properties/${
              props.property.id
            }/#section-bookings`}
          >
            Reservations
          </a>
        </li>
      </ul>
    </section>
  );
};

// -----------------------------------------------
// EXPORT ----------------------------------------
// -----------------------------------------------
export default SingleMenu;
