import request from "apiClient";
function list(orgId) {
  return request({
    url: `/api/${orgId}/channels`,
    method: "GET"
  });
}
function get(orgId, slug) {
  return request({
    url: `/api/${orgId}/channels/${slug}`,
    method: "GET"
  });
}
function importListings(orgId, channelId, userId) {
  const url = `/api/${orgId}/${channelId === 3 ? "airbnb" : "vehicles"}/import_listings?user_id=${userId}`;
  return request({
    url,
    method: "GET"
  });
}
const ChannelService = {
  get,
  list,
  importListings
};
export default ChannelService;
