const models = [
  { label: "All", value: "all" },
  {
    label: "Properties",
    value: "properties",
    iconName: "house",
    seeAllHref: "/global_search/detailed_property?querystring="
  },
  {
    label: "Vehicles",
    value: "vehicles",
    iconName: "rv",
    seeAllHref: "/global_search/detailed_vehicle?querystring="
  },
  {
    label: "Reservations",
    value: "bookings",
    iconName: "conciergeBell",
    seeAllHref: "/global_search/detailed_booking?querystring="
  },
  {
    label: "Statements",
    value: "statements",
    iconName: "fileInvoice",
    seeAllHref: "/global_search/detailed_statement?querystring="
  },
  {
    label: "Customers",
    value: "customers",
    iconName: "peopleGroup",
    seeAllHref: "/global_search/detailed_customer?querystring="
  },
  {
    label: "Users",
    value: "employees",
    iconName: "users",
    seeAllHref: "/global_search/detailed_employee?querystring="
  }
];
const tips = [
  {
    iconName: "house",
    headline: "Properties",
    description: "Try searching for Property Name, Description, etc."
  },
  {
    iconName: "rv",
    headline: "Recreational Vehicles",
    description: "Try searching for Vehicle Name, Description, etc."
  },
  {
    iconName: "conciergeBell",
    headline: "Reservations",
    description: "Try searching for Booking Code, Guest Name, Listing Name, etc."
  },
  {
    iconName: "fileInvoice",
    headline: "Statements",
    description: "Try searching for Statement ID, Payee, etc"
  },
  {
    iconName: "users",
    headline: "Users",
    description: "Try searching for Name, Email, etc."
  },
  {
    iconName: "peopleGroup",
    headline: "Customers",
    description: "Try searching for Name, Email, etc."
  }
];
const globalSearchOptions = {
  vr_owner: {
    models: models.filter((model) => model.label !== "Vehicles"),
    tips: tips.filter((tip) => tip.headline !== "Recreational Vehicles")
  },
  fleet_owner: {
    models: models.filter((model) => model.label !== "Properties"),
    tips: tips.filter((tip) => tip.headline !== "Properties")
  }
};
export default globalSearchOptions;
