import request from "apiClient";
function list(orgId, filtered, limit, page, sorted) {
  return request({
    url: `/api/${orgId}/promotions`,
    method: "GET",
    params: {
      filtered,
      limit,
      page,
      sorted
    }
  }).then((response) => {
    return {
      promotions: response.promotions,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function get(orgId, promotionId) {
  return request({
    url: `/api/${orgId}/promotions/${promotionId}`,
    method: "GET"
  });
}
function create(orgId, data) {
  return request({
    url: `/api/${orgId}/promotions`,
    method: "POST",
    data
  });
}
function update(orgId, data, promoId) {
  return request({
    url: `/api/${orgId}/promotions/${promoId}`,
    method: "PATCH",
    data
  });
}
function unlinked(orgId, promotionId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/promotions/${promotionId}/unlinked`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.units,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function addListing(orgId, promotionId, listingId, className) {
  return request({
    url: `/api/${orgId}/promotions/${promotionId}/add_listing`,
    method: "POST",
    data: {
      promotion_id: promotionId,
      listing_id: listingId,
      klass_name: className
    }
  });
}
const PromotionService = {
  list,
  get,
  create,
  update,
  unlinked,
  addListing
};
export default PromotionService;
