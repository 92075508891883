import React from 'react';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';
import find from 'lodash/find';
import range from 'lodash/range';
import map from 'lodash/map';

const integerOptions = (minInt, maxInt) => {
  const numArray = range(minInt, maxInt + 1);

  return map(numArray, num => {
    return { label: `${num}`, value: num };
  });
};

const IntegerSelect = props => {
  const { label, name, value, minInt, maxInt, onChange } = props;

  const options = integerOptions(minInt, maxInt);

  return (
    <React.Fragment>
      {label && <label>{label}</label>}
      <InputSelect
        name={name}
        className="magnified"
        options={options}
        isClearable={false}
        isSearchable={false}
        onChange={onChange}
        value={find(options, option => {
          return option.value === value;
        })}
        styles={{
          menuList: (provided, _state) => ({
            ...provided,
            maxHeight: '128px'
          })
        }}
      />
    </React.Fragment>
  );
};

export default IntegerSelect;
