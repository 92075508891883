import React from 'react';
import { FormField } from '@directsoftware/ui-kit-web-admin';

export const UnitCodeInput = ({ onChange, value }) => {
  return (
    <div>
      <FormField
        inputType="text"
        labelText="Unit Code"
        labelHtmlFor="unit_code"
        inputProps={{
          id: 'unit_code',
          name: 'unit_code',
          onChange,
          value
        }}
      />
    </div>
  );
};
