import request from "apiClient";
export default class RateGroupRangeService {
  static index(organizationId, filter) {
    return request({
      url: `/api/${organizationId}/rate_group_ranges?filter=${filter}`,
      method: "GET"
    });
  }
  static new(organizationId) {
    return request({
      url: `/api/${organizationId}/rate_group_ranges/new`,
      method: "GET"
    });
  }
  static create(organizationId, data, rateGroupId) {
    return request({
      url: `/api/${organizationId}/rate_group_ranges`,
      method: "POST",
      data,
      rate_group_id: rateGroupId
    });
  }
  static unitGroups(orgId, rateGroupRangeId, rateGroupId, limit, page, sorted, filtered) {
    return request({
      url: `/api/${orgId}/rate_group_ranges/${rateGroupRangeId}/unit_groups`,
      method: "GET",
      params: {
        rate_group_id: rateGroupId,
        limit,
        page,
        sorted,
        filtered
      }
    }).then((response) => {
      return {
        rows: response.unit_groups,
        pages: Math.ceil(response.count / limit)
      };
    });
  }
  static addUnit(orgId, rateGroupRangeId, rateGroupId, unitId, className) {
    return request({
      url: `/api/${orgId}/rate_group_ranges/${rateGroupRangeId}/add_unit`,
      method: "POST",
      data: {
        rate_group_range_id: rateGroupRangeId,
        rate_group_id: rateGroupId,
        unit_id: unitId,
        klass_name: className
      }
    });
  }
  static removeUnit(orgId, unitId, rateGroupRangeId) {
    return request({
      url: `/api/${orgId}/rate_group_ranges/${rateGroupRangeId}/remove_unit`,
      method: "PATCH",
      data: {
        unit_id: unitId,
        rate_group_range_id: rateGroupRangeId
      }
    });
  }
  static unlinked(orgId, rateGroupRangeId, limit, page, sorted, filtered) {
    return request({
      url: `/api/${orgId}/rate_group_ranges/${rateGroupRangeId}/unlinked`,
      method: "GET",
      params: {
        limit,
        page,
        sorted,
        filtered
      }
    }).then((response) => {
      return {
        units: response.units,
        pages: Math.ceil(response.count / limit)
      };
    });
  }
  static fetch(organizationId, rateGroupRangeId, filter) {
    return request({
      url: `/api/${organizationId}/rate_group_ranges/${rateGroupRangeId}`,
      method: "GET",
      params: {
        filter
      }
    });
  }
  static delete(organizationId, rateGroupRangeId) {
    return request({
      url: `/api/${organizationId}/rate_group_ranges/${rateGroupRangeId}`,
      method: "DELETE"
    });
  }
  static update(organizationId, rateGroupRangeId, data) {
    return request({
      url: `/api/${organizationId}/rate_group_ranges/${rateGroupRangeId}`,
      method: "PATCH",
      data
    });
  }
}
