import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  CallToActionButton,
  ChannelLogo,
  FlexBox,
  IconFontAwesome,
  PageHeader
} from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { DISTRIBUTION_NAV_TAGS } from '../../../../../shared/nav';
import {
  selectChannelsAirbnb,
  updateChannelsAirbnb
} from '../../../../redux/slices/channels/channels';

const AirbnbHome = props => {
  const dispatch = useDispatch();
  const airbnbState = useSelector(selectChannelsAirbnb);

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: DISTRIBUTION_NAV_TAGS.PRIMARY,
        secondary: DISTRIBUTION_NAV_TAGS.secondary.AIRBNB
      })
    );
  }, []);

  return (
    <PageHeader containerVariation="fluid">
      <PageHeader.Left>
        <FlexBox alignItems="center" gap="s">
          <Box>Distribution:</Box>
          <Box style={{ marginTop: -4 }}>
            <ChannelLogo
              channel="airbnb"
              presetHeight="custom"
              customHeight={32}
            />
          </Box>
        </FlexBox>
      </PageHeader.Left>
      {airbnbState.allowAddAccount && (
        <PageHeader.Right>
          <CallToActionButton
            onClick={() => {
              dispatch(updateChannelsAirbnb({ addAccountStepOne: true }));
            }}
            prependIcon={<IconFontAwesome name="plus" />}
          >
            Add Account
          </CallToActionButton>
        </PageHeader.Right>
      )}
    </PageHeader>
  );
};

export default AirbnbHome;
