import React from 'react';
import PropTypes from 'prop-types';
import { ajaxDataWithAuthorization } from 'apiClient';
import Dropzone from 'react-dropzone';
import Spinner from './spinner';

export default class HeroImageUploader extends React.Component {
  static propTypes = {
    hero_image: PropTypes.object,
    organization: PropTypes.object.isRequired,
    image_url: PropTypes.string.isRequired
  };

  constructor(props, _railsContext) {
    super(props);
    this.state = {
      hero_image_preview: this.props.hero_image
        ? this.props.hero_image.image.url
        : null,
      uploaded: false,
      uploading: false
    };
  }

  onDropzoneDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      this.setState(
        {
          hero_image_preview: acceptedFiles[0].preview,
          hero_image_queued: acceptedFiles[0],
          uploading: true
        },
        () => {
          this.uploadAndPoll();
        }
      );
    }
  };

  uploadAndPoll = () => {
    var d = new FormData();
    d.append('file', this.state.hero_image_queued);
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'POST',
        url: this.props.image_url,
        context: this,
        data: d,
        processData: false,
        contentType: false,
        dataType: 'json',
        cache: false
      })
    )
      .done(function(data) {
        this.setState({ uploading: false, uploaded: true });
        return data;
      })
      .fail(function(jqXhr) {
        this.setState({ uploading: false, uploaded: false });
        return jqXhr;
      });
  };

  deleteHero = e => {
    e.preventDefault();
    this.setState(
      {
        hero_image_preview: null,
        hero_image_queued: null,
        uploaded: false,
        uploading: false
      },
      () => {
        this.deleteAndPoll();
      }
    );
  };

  deleteAndPoll = () => {
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'DELETE',
        url: this.props.image_url,
        context: this,
        contentType: false,
        cache: false
      })
    )
      .done(function(data) {
        return data;
      })
      .fail(function(jqXhr) {
        return jqXhr;
      });
  };

  render() {
    return (
      <section
        className={`hero-image-uploader ${
          this.state.uploading ? 'uploading' : ''
        }`}
      >
        <section className="image-section">
          <Dropzone
            activeClassName=""
            accept="image/*"
            className={`card ${
              !this.state.hero_image_preview ? 'empty-state' : ''
            }`}
            multiple={false}
            onDrop={this.onDropzoneDrop}
            ref={node => {
              this.dropzone = node;
            }}
          >
            {this.state.hero_image_preview ? (
              <img src={this.state.hero_image_preview} alt="preview-hero" />
            ) : (
              <div>
                <a className="button">Upload photo</a>
                <p>or drag it into this box.</p>
              </div>
            )}
          </Dropzone>
          {this.state.uploading ? <Spinner /> : null}
        </section>
        {this.state.hero_image_preview && !this.state.uploading ? (
          <a
            href="#"
            className="button negative"
            onClick={e => this.deleteHero(e)}
          >
            Delete photo
          </a>
        ) : null}
      </section>
    );
  }
}
