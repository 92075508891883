import request from "apiClient";
export default class SelectService {
  static index(organizationID, params) {
    return request({
      url: `/api/${organizationID}/selects`,
      method: "GET",
      params
    });
  }
}
