import request from "apiClient";
function listCustomers(orgId, pageSize, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/customers`,
    method: "GET",
    params: {
      limit: pageSize,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.customers,
      pages: Math.ceil(response.count / pageSize)
    };
  });
}
function listLeads(orgId, pageSize, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/leads`,
    method: "GET",
    params: {
      limit: pageSize,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.leads,
      pages: Math.ceil(response.count / pageSize)
    };
  });
}
const CustomerService = {
  listCustomers,
  listLeads
};
export default CustomerService;
