import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import PermissionComponent from 'PermissionComponent';

const CrmLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags,
  ...props
}) => {
  return (
    <PermissionComponent user={props.user} permission="pipeline">
      <Navbar.Link
        href="/pipeline"
        variation="secondary"
        onClick={() => {
          closeMenuPanel();
          if (closeMobileNav)
            // desktop links wont have this
            closeMobileNav();
        }}
        isActive={activeRoutes.secondary === navTags.secondary.CRM}
      >
        CRM Pipeline
      </Navbar.Link>
    </PermissionComponent>
  );
};

CrmLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object
};

export default CrmLink;
