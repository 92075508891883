import React, { useEffect, useState } from 'react';
import find from 'lodash/find';
import {
  Box,
  FlexBox,
  IconButton,
  IconFontAwesome,
  Spacer,
  TextBody,
  TextDense
} from '@directsoftware/ui-kit-web-admin';
import { formatCurrency } from 'src/shared/utils';
import SelectService from 'adminApi/SelectService';

const ExpensesRow = ({ expenses, deleteExpense, organization }) => {
  const [unitList, setUnitList] = useState([]);
  const expenseOptions = [
    { value: 0, label: 'Company' },
    { value: 1, label: 'Unit' }
  ];

  const removeExpense = (e, expense, index) => {
    e.preventDefault();
    deleteExpense(expense, index);
  };

  const setDefaultValue = (options, employeeId) => {
    return options.filter(option => option.value === employeeId);
  };

  useEffect(() => {
    SelectService.index(organization.id, {
      model: 'Unit',
      select: ['id', 'name']
    }).then(response => {
      const options = response.options.map(data => {
        return {
          label: data.name,
          value: data.id,
          data
        };
      });

      setUnitList(options);
    });
  }, []);

  return expenses.map((expense, index) => (
    <>
      <FlexBox className="expensesRow" paddingLeft="s">
        <Box flex="1">
          <TextBody isFullWidth weight="semibold" textColor="dark-gray">
            {`Expense ${index + 1}`}
          </TextBody>
          <Spacer size="xxs" />
          <TextDense isFullWidth>
            {`${formatCurrency(expense.amount, organization.currency)} to ${
              find(
                expenseOptions,
                option => option.value === expense.expense_to
              ).label
            }`}
          </TextDense>
          <TextDense>
            {expense.expense_to === 0
              ? organization.name
              : find(unitList, option => option.value === expense.unit_id)
                  ?.label}
          </TextDense>
          {expense.memo && (
            <>
              <Spacer size="xs" />
              <TextDense>{expense.memo}</TextDense>
            </>
          )}
        </Box>
        <Box>
          <IconButton
            appearance="ghost"
            variation="secondary"
            onClick={e => removeExpense(e, expense, index)}
          >
            <IconFontAwesome name="times" />
          </IconButton>
        </Box>
      </FlexBox>
      <Spacer />
    </>
  ));
};

export default ExpensesRow;
