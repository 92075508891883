import request from "apiClient";
function list(orgId, filtered, limit, page, sorted) {
  return request({
    url: `/api/${orgId}/room_types`,
    method: "GET",
    params: {
      filtered,
      limit,
      page,
      sorted
    }
  }).then((response) => {
    return {
      rows: response.room_types,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function get(orgId, roomTypeId) {
  return request({
    url: `/api/${orgId}/room_types/${roomTypeId}`,
    method: "GET"
  });
}
function units(orgId, roomTypeId, limit, page, filtered) {
  return request({
    url: `/api/${orgId}/room_types/${roomTypeId}/units`,
    method: "GET",
    params: {
      limit,
      page,
      filtered
    }
  });
}
function removeUnit(orgId, roomTypeId, unitId) {
  return request({
    url: `/api/${orgId}/room_types/${roomTypeId}/remove_unit`,
    method: "PATCH",
    data: { unit_id: unitId }
  });
}
function unlinked(orgId, roomTypeId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/room_types/${roomTypeId}/unlinked`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      units: response.units,
      pages: Math.ceil(response / limit)
    };
  });
}
function unlinkedImages(orgId, roomTypeId) {
  return request({
    url: `/api/${orgId}/room_types/${roomTypeId}/unlinked_images`,
    method: "GET"
  });
}
function addRoomPhoto(orgId, roomTypeId, photoId) {
  return request({
    url: `/api/${orgId}/room_types/${roomTypeId}/add_room_photo`,
    method: "POST",
    data: {
      property_image_id: photoId,
      room_type_id: roomTypeId
    }
  });
}
function removeRoomPhoto(orgId, roomTypeId, photoId) {
  return request({
    url: `/api/${orgId}/room_types/${roomTypeId}/remove_room_photo`,
    method: "PATCH",
    data: {
      property_image_id: photoId,
      room_type_id: roomTypeId
    }
  });
}
function addUnit(orgId, roomTypeId, unitId) {
  return request({
    url: `/api/${orgId}/room_types/${roomTypeId}/add_unit`,
    method: "PATCH",
    data: {
      unit_id: unitId,
      room_type_id: roomTypeId
    }
  });
}
function create(orgId, data) {
  return request({
    url: `/api/${orgId}/room_types`,
    method: "POST",
    data
  });
}
function findListing(orgId, brandId, listingId) {
  return request({
    url: `/api/${orgId}/room_types/find_listing`,
    method: "GET",
    params: {
      brand_id: brandId,
      listing_id: listingId
    }
  });
}
const roomTypeService = {
  list,
  get,
  units,
  removeUnit,
  unlinked,
  unlinkedImages,
  addRoomPhoto,
  removeRoomPhoto,
  addUnit,
  create,
  findListing
};
export default roomTypeService;
