// Dependencies
// -----------------------------------------------
import React from 'react';
import styled from 'styled-components';

// Components
// -----------------------------------------------

// Styles
// -----------------------------------------------
const CreateButton = styled.div`
  background: #387bd2;
  border: 1px solid #265694;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  line-height: 62px;
  padding: 0 24px;
  text-align: center;
  font-family: din-2014, sans-serif;
  font-weight: 700;
  font-style: normal;
`;

const CreateOverlay = styled.div`
  background: rgba(0, 0, 0, 0);
  bottom: 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5002;
`;

const Menu = styled.div`
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 4px 16px 0 rgba(64, 64, 64, 0.24);
  padding: 16px 16px 8px;
  position: absolute;
  right: 0;
  top: 72px;
  width: 160px;
  z-index: 5003;
`;

const MenuTitle = styled.div`
  color: #404040;
  font-family: 'Rajdhani', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 8px;
  text-transform: uppercase;
`;

const MenuItem = styled.div`
  color: #606b6f;
  cursor: pointer;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 8px;
`;

const AddIcon = styled.div`
  background: inherit;
  border: 0;
  display: inline-block;
  height: 24px;
  lineheight: 24px;
  margin: 0 8px 0 0;
  padding: 0;
  width: 24px;
`;

const arrowStyles = {
  fontSize: '14px',
  marginLeft: '8px'
};

// -----------------------------------------------
// COMPONENT->NEW-ACTION-MODAL -------------------
// -----------------------------------------------
export default class NewActionModal extends React.Component {
  constructor(props, _railsContext) {
    super(props);

    this.state = {
      createModalOpen: false,
      newReservationModalOpen: false
    };
  }

  openCreate = () => {
    this.setState({
      createModalOpen: true
    });
  };

  closeCreate = () => {
    this.setState({
      createModalOpen: false
    });
  };

  openNewReservation = () => {
    this.setState({
      newReservationModalOpen: true
    });
  };

  closeNewReservation = () => {
    this.setState({
      newReservationModalOpen: false
    });
  };

  render() {
    return (
      <>
      </>
    );
  }
}
