import React, { useEffect, useState } from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import { PaymentAccountService } from 'adminApi';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { ACCOUNTING_NAV_TAGS } from '../../../../../shared/nav';

const BankAccountDetails = props => {
  const dispatch = useDispatch();
  const [accountName, setAccountName] = useState('');

  useEffect(
    () => {
      PaymentAccountService.get(props.organization.id, props.accountID).then(
        response => {
          setAccountName(response.payment_account.display_name);
        }
      );
    },
    [props]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: ACCOUNTING_NAV_TAGS.PRIMARY,
        secondary: ACCOUNTING_NAV_TAGS.secondary.BANK_ACCOUNTS
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/accounting/bank-accounts',
            label: 'Bank Accounts'
          },
          {
            label: 'Edit Account Details'
          }
        ]}
      >
        {accountName}
      </PageHeader.Left>
    </PageHeader>
  );
};

export default BankAccountDetails;
