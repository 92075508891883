import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ChannelsHome from './channels-home';
import AirbnbHome from './airbnb-home';
import BookingComHome from './booking-com-home';
import HomeAwayHome from './homeaway-home';
import ExpediaHome from './expedia-home';
import RvShareHome from './rvshare-home';
import GoogleHome from './google-home';

const DistributionRoutes = props => {
  return (
    <Switch>
      <Route path="/channels/google" render={() => <GoogleHome {...props} />} />
      <Route
        path="/channels/rvshare"
        render={() => <RvShareHome {...props} />}
      />
      <Route
        path="/channels/expedia"
        render={() => <ExpediaHome {...props} />}
      />
      <Route
        path="/channels/homeaway"
        render={() => <HomeAwayHome {...props} />}
      />
      <Route
        path="/channels/booking"
        render={() => <BookingComHome {...props} />}
      />
      <Route path="/channels/airbnb" render={() => <AirbnbHome {...props} />} />
      <Route path="/channels" render={() => <ChannelsHome {...props} />} />
    </Switch>
  );
};

export default DistributionRoutes;
