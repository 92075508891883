import request from "apiClient";
export default class DocumentService {
  static create(organizationID, data) {
    return request({
      url: `/api/${organizationID}/documents`,
      method: "POST",
      data
    });
  }
  static destroy(organizationID, documentID) {
    return request({
      url: `/api/${organizationID}/documents/${documentID}`,
      method: "DELETE"
    });
  }
}
