import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  FlexBox,
  IconFontAwesome,
  IconLink,
  PageHeader,
  TextTitle
} from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from 'reduxSlices/navigation';
import { ACCOUNTING_NAV_TAGS } from 'sharedNav';
import { useClickOutsideListener } from 'src/shared/hooks';
import { selectStatementsState } from 'src/admin/redux/slices/liabilitiesAndStatements';

const PayeeLiabilities = props => {
  const dispatch = useDispatch();
  const { payeeName } = useSelector(selectStatementsState);
  const panelRef = useRef();
  const [showPayeeList, setShowPayeeList] = useState(false);
  const { isOutsideClick: controlOutsideClick } = useClickOutsideListener(
    panelRef
  );

  useEffect(
    () => {
      if (showPayeeList) setShowPayeeList(false);
    },
    [controlOutsideClick]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: ACCOUNTING_NAV_TAGS.PRIMARY,
        secondary: ACCOUNTING_NAV_TAGS.secondary.LIABILITIES_AND_STATEMENTS
      })
    );
  }, []);

  return (
    <PageHeader containerVariation="fluid" height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/accounting/liabilities-and-statements',
            label: 'Liabilities & Statements'
          },
          {
            label: 'Payee Liabilities'
          }
        ]}
        hasCustomChildren
      >
        <FlexBox alignItems="center" gap="xs" isTextTruncateWrapper>
          <Box>
            <TextTitle className="payeeSelector__label">Liabilities:</TextTitle>
          </Box>
          <Box
            className="payeeSelector__triggerWrapper"
            setPositionRelative
            domRef={panelRef}
          >
            {/* Remove the comment in the associated CSS file when the Payee List is reinstated */}
            <Box
              renderAs="button"
              onClick={() => setShowPayeeList(true)}
              type="button"
              className="payeeSelector__trigger"
            >
              {payeeName}
            </Box>
            {/* <Panel
              addShadow
              useFlexBox
              flexDirection="column"
              paddingVertical="s"
              className={classnames('payeeSelector__panel', {
                reveal: showPayeeList
              })}
            >
              <Box paddingHorizontal="xs">
                <TextDense textTransform="uppercase" textColor="light-gray">
                  Switch to Payee
                </TextDense>
                <Spacer />
                <InputText
                  onChange={() => {}}
                  addShadeBg
                  prependIcon={<IconFontAwesome name="search" />}
                  isDense
                />
                <Divider />
              </Box>
              <FlexBox
                flexDirection="column"
                flex="1"
                style={{ overflow: 'auto' }}
              >
                <Box>
                  <ListItemLink
                    type="router-link"
                    label="Payee Name"
                    icon={<IconFontAwesome name="chevronRight" />}
                    href="/"
                    removeBottomMargin
                    removeBorderRadius
                    iconPresetColor="blue"
                  />
                  <ListItemLink
                    type="router-link"
                    label="Payee Name"
                    icon={<IconFontAwesome name="chevronRight" />}
                    href="/"
                    removeBottomMargin
                    removeBorderRadius
                    iconPresetColor="blue"
                  />
                  <ListItemLink
                    type="router-link"
                    label="Payee Name"
                    icon={<IconFontAwesome name="chevronRight" />}
                    href="/"
                    removeBottomMargin
                    removeBorderRadius
                    iconPresetColor="blue"
                  />
                  <ListItemLink
                    type="router-link"
                    label="Payee Name"
                    icon={<IconFontAwesome name="chevronRight" />}
                    href="/"
                    removeBottomMargin
                    removeBorderRadius
                    iconPresetColor="blue"
                  />
                  <ListItemLink
                    type="router-link"
                    label="Payee Name"
                    icon={<IconFontAwesome name="chevronRight" />}
                    href="/"
                    removeBottomMargin
                    removeBorderRadius
                    iconPresetColor="blue"
                  />
                </Box>
              </FlexBox>
              <Box paddingHorizontal="xs">
                <Divider />
              </Box>
            </Panel> */}
          </Box>
        </FlexBox>
      </PageHeader.Left>
      <PageHeader.Right>
        <IconLink href="/" openNewTab variation="secondary" appearance="ghost">
          <IconFontAwesome name="userCircle" />
        </IconLink>
      </PageHeader.Right>
    </PageHeader>
  );
};

export default PayeeLiabilities;
