// Dependencies
// -----------------------------------------------
import React from 'react';

// -----------------------------------------------
// COMPONENT->OVERVIEW-SINGLE-ASIDE-DESCRIPTION --
// -----------------------------------------------
const OverviewSingleAsideDescription = () => {
  // ---------------------------------------------
  // RETURN --------------------------------------
  // ---------------------------------------------
  return (
    <div>
      <figure>
        <small className="subtitle">Short Summary</small>
        <p>
          This will display at the top of your listings pages, as a sub-header.
          Consider using eye-catching or descriptive language here.
        </p>
      </figure>

      <figure>
        <small className="subtitle">Description</small>
        <p>
          This is the long-form description of your property. While it can be as
          short or as long as you’d like, make sure to include everything
          potential guests would need or want to know before booking.
        </p>
      </figure>
    </div>
  );
};

// -----------------------------------------------
// EXPORT ----------------------------------------
// -----------------------------------------------
export default OverviewSingleAsideDescription;
