import React from 'react';
import { Switch, Route } from 'react-router-dom';
import GlobalSearchHome from './global-search-home';
import DetailedSearchPropertyHeader from './detailed-search-property-header';
import DetailedSearchBookingsHeader from './detailed-search-bookings-header';
import DetailedSearchVehiclesHeader from './detailed-search-vehicles-header';
import DetailedSearchStatementsHeader from './detailed-search-statements-header';
import DetailedSearchCustomersHeader from './detailed-search-customers-header';
import DetailedSearchEmployeesHeader from './detailed-search-employees.header';

const GlobalSearchRoutes = props => {
  return (
    <Switch>
      <Route
        path="/global_search/detailed_employee"
        render={() => <DetailedSearchEmployeesHeader {...props} />}
      />
      <Route
        path="/global_search/detailed_customer"
        render={() => <DetailedSearchCustomersHeader {...props} />}
      />
      <Route
        path="/global_search/detailed_statement"
        render={() => <DetailedSearchStatementsHeader {...props} />}
      />
      <Route
        path="/global_search/detailed_vehicle"
        render={() => <DetailedSearchVehiclesHeader {...props} />}
      />
      <Route
        path="/global_search/detailed_booking"
        render={() => <DetailedSearchBookingsHeader {...props} />}
      />
      <Route
        path="/global_search/detailed_property"
        render={() => <DetailedSearchPropertyHeader {...props} />}
      />
      <Route
        path="/globalsearch"
        render={() => <GlobalSearchHome {...props} />}
      />
    </Switch>
  );
};

export default GlobalSearchRoutes;
