import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const MyAccountLink = ({ activeRoutes, closeMobileNav, navTags, ...props }) => {
  return (
    <Navbar.Link
      href={
        props.user.type === 'user' ||
        props.user.role_name === 'organization_owner'
          ? `/users/edit`
          : `/dashboard/${props.subdomain}/employees/edit`
      }
      variation="secondary"
      linkType="link"
      isActive={activeRoutes.secondary === navTags.secondary.MY_ACCOUNT}
    >
      My Account
    </Navbar.Link>
  );
};

MyAccountLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  navTags: PropTypes.object
};

export default MyAccountLink;
