import React, { useEffect, useState } from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import displayError from 'sharedComponents/ErrorDisplay';
import UnitGroupService from 'adminApi/UnitGroupService';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';

const UnitGroupDetails = props => {
  const dispatch = useDispatch();
  const [unitGroupName, setUnitGroupName] = useState('');

  useEffect(
    () => {
      UnitGroupService.get(props.organization.id, props.unitGroupId)
        .then(unitGroup => {
          setUnitGroupName(unitGroup.name);
        })
        .catch(err =>
          displayError({ message: 'Error Loading Unit Group', err })
        );
    },
    [props]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: LISTINGS_NAV_TAGS.PRIMARY,
        secondary: LISTINGS_NAV_TAGS.secondary.UNIT_GROUPS
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/unit-groups',
            label: 'Unit Groups'
          },
          {
            label: 'Details'
          }
        ]}
      >
        {unitGroupName}
      </PageHeader.Left>
    </PageHeader>
  );
};

export default UnitGroupDetails;
