const dateFilterOptions = [
  { value: "check_in", label: "Check In" },
  { value: "check_out", label: "Check Out" },
  { value: "created_at", label: "Created At" }
];
const dateFilterExpandedOptions = {
  occupancy: [{ value: "check_in", label: "Date Range" }],
  business_payables: [{ value: "check_in", label: "Post Date" }],
  folio_balances: [
    { value: "check_in", label: "Check In" },
    { value: "check_out", label: "Check Out" }
  ],
  show_stay_dates: [
    { value: "check_in", label: "Check In" },
    { value: "check_out", label: "Check Out" },
    { value: "created_at", label: "Created At" },
    { value: "stay_dates", label: "Traveled Over" }
  ],
  default: [
    { value: "check_in", label: "Check In" },
    { value: "check_out", label: "Check Out" },
    { value: "created_at", label: "Created At" }
  ],
  vehicle_delivery: [
    { value: "stay_dates", label: "All Bookings" },
    { value: "check_in", label: "Departures" },
    { value: "check_out", label: "Returns" }
  ]
};
export { dateFilterOptions, dateFilterExpandedOptions };
