import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CallToActionButton,
  CallToActionLink,
  FlexBox,
  IconFontAwesome,
  Spacer,
  TextBody,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { selectUserOrganization } from '../../redux/slices/userDetails';

const ProcessingOverlay = ({ processResult, closeOverlay, bookingCode }) => {
  const { subdomain } = useSelector(selectUserOrganization);
  const renderContent = () => {
    switch (processResult) {
      case 'error':
        return (
          <>
            <Box className="createReservationModal__processing_icon -error">
              <IconFontAwesome name="exclamationTriangle" />
            </Box>
            <Spacer size="xxs" />
            <TextH2>We could not create this reservation</TextH2>
            <Spacer size="xxs" />
            <TextBody textAlign="center" isFullWidth>
              Please check the info you submitted and try again
            </TextBody>
            <Spacer size="m" />
            <CallToActionButton
              onClick={() => closeOverlay()}
              appearance="outline"
              variation="secondary"
              appendIcon={<IconFontAwesome name="times" />}
            >
              Review Booking Info
            </CallToActionButton>
          </>
        );
      case 'success':
        return (
          <>
            <Box className="createReservationModal__processing_icon -success">
              <IconFontAwesome name="check" />
            </Box>
            <Spacer size="xxs" />
            <TextH2>Booking complete!</TextH2>
            <Spacer size="xxs" />
            <TextBody textAlign="center" isFullWidth>
              You should be redirected in a few seconds, but if not, use the
              link below
            </TextBody>
            <Spacer size="m" />
            <CallToActionLink
              href={`/dashboard/${subdomain}/reservations/${bookingCode}`}
              appearance="outline"
              variation="secondary"
              type="link"
              openNewTab
            >
              {`View Booking ${bookingCode}`}
            </CallToActionLink>
          </>
        );
      default:
        return (
          <>
            <Box className="createReservationModal__processing_icon">
              <IconFontAwesome name="spinner" spin />
            </Box>
            <Spacer size="xxs" />
            <TextH2>Creating your reservation</TextH2>
            <Spacer size="xxs" />
            <TextBody textAlign="center" isFullWidth>
              This might take a couple minutes...
            </TextBody>
          </>
        );
    }
  };
  return (
    <FlexBox
      className="createReservationModal__processing"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      paddingHorizontal="m"
    >
      {renderContent()}
    </FlexBox>
  );
};

ProcessingOverlay.propTypes = {
  processResult: PropTypes.string,
  bookingCode: PropTypes.string,
  closeOverlay: PropTypes.func
};

ProcessingOverlay.defaultProps = {};

export default ProcessingOverlay;
