import React from 'react';
import moment from 'moment';
import find from 'lodash/find';
import get from 'lodash/get';

import BookingWidgetContainer from 'adminComponents/BookingWidget/BookingWidgetContainer';
import { InputMoney, DirectCheckbox } from 'adminComponents';
import { QuoteService, BookingService } from 'adminApi';
import displayError from 'sharedComponents/ErrorDisplay';
import WidgetDatePicker from 'sharedComponents/WidgetDatePicker';
import { IconFontAwesome } from '@directsoftware/ui-kit-web-admin';
import { getVehicleAvailability } from 'adminApi/VehicleService';
import UnitService from 'adminApi/UnitService';
import LineItemEditor from './LineItemEditor';
import EditedQuoteForm from './EditedQuoteForm';
import EditedBookingNetDetailForm from './EditedBookingNetDetailForm';
import QuoteLineItemCreator from './QuoteLineItemCreator';
import QuoteNumGuestsUpdater from './QuoteNumGuestsUpdater';

export default class QuoteEditingForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      protectedQuote: props.quote,
      safeToEditQuote: null,
      editedQuote: null,
      originalBookingNetDetail: null, // this will technically be the duplicate, but this value should stay the same throughout the editing process to compare differences
      editedBookingNetDetail: null,
      selectedLineItem: null,
      changeType: null,
      changeAmount: 0,
      chargeAmount: 0,
      applyCharge: props.applyCharge,
      loading: true,
      addingLineItemType: null,
      updatingNumGuests: props.updatingNumGuests
    };
  }

  componentDidMount() {
    this.fetchAvailability();
    QuoteService.duplicate(
      this.props.organizationID,
      this.state.protectedQuote.id
    )
      .then(response => {
        this.setState({ safeToEditQuote: response.quote }, () => {
          QuoteService.duplicateBookingNetDetail(
            this.props.organizationID,
            this.state.protectedQuote.id,
            this.state.safeToEditQuote.id
          )
            .then(response => {
              this.setState({
                loading: false,
                originalBookingNetDetail: response.booking_net_detail,
                selectedLineItem: this.getInitialLineItem()
              });
            })
            .catch(err =>
              displayError({
                message: 'Error duplicating booking net detail',
                err
              })
            );
        });
      })
      .catch(err => displayError({ message: 'Error duplicating quote', err }));
  }

  fetchAvailability = () => {
    const { organizationID, unitID, vehicleBooking, vehicleId } = this.props;
    if (vehicleBooking) {
      getVehicleAvailability({
        orgId: organizationID,
        vehicleId
      }).then(res => {
        this.setState({ availabilityCalendar: res.availability_calendar });
      });
    } else {
      UnitService.getUnitAvailability(organizationID, unitID).then(response => {
        this.setState({ availabilityCalendar: response.availability_calendar });
      });
    }
  };

  getInitialLineItem = () => {
    if (!this.props.initialLineItem) return null;

    return find(
      get(this, 'state.safeToEditQuote.line_items', []),
      item => item.name === this.props.initialLineItem
    );
  };

  getAdditionalGuestFeeLineItem = quote => {
    const additionalGuestFeeLineItem = find(
      quote.line_items,
      item => item.name === 'Additional Guest Fee'
    );

    if (!additionalGuestFeeLineItem) {
      return 0;
    }

    return additionalGuestFeeLineItem;
  };

  onLineItemSelect = selectedLineItem => {
    this.setState({
      selectedLineItem,
      editedQuote: null,
      editedBookingNetDetail: null
    });
  };

  onDatesChange = ({ startDate, endDate }) => {
    if (!startDate || !endDate) return null;

    const data = {
      bookable_id: this.props.vehicleBooking
        ? this.props.vehicleId
        : this.state.safeToEditQuote.unit_id,
      vehicle_booking: this.props.vehicleBooking,
      check_in: startDate,
      check_out: endDate,
      num_guests: this.state.safeToEditQuote.num_guests,
      channel_id: this.state.safeToEditQuote.channel_id,
      booking_code: this.props.bookingCode
    };

    QuoteService.create(this.props.organizationID, data)
      .then(response => {
        const diff =
          response.quote.room_rate_cents -
          this.state.safeToEditQuote.room_rate_cents;
        const changeType = diff < 0 ? 'decrease' : 'increase';
        const changeAmount = Math.abs(diff / 100.0);
        const newStayLength = moment(endDate).diff(moment(startDate), 'days');

        this.setState({ changeType, changeAmount }, () => {
          QuoteService.update(
            this.props.organizationID,
            this.state.safeToEditQuote.id,
            {
              check_in: startDate,
              check_out: endDate,
              billable_nights: newStayLength,
              booking_code: this.props.bookingCode
            }
          ).catch(err =>
            displayError({ message: 'Error updating quote', err })
          );
        });
      })
      .catch(err => {
        displayError({ message: 'Error creating quote', err });
      });
  };

  onNumGuestsChange = numGuests => {
    const data = {
      bookable_id: this.props.vehicleBooking
        ? this.props.vehicleId
        : this.state.safeToEditQuote.unit_id,
      vehicle_booking: this.props.vehicleBooking,
      check_in: this.state.safeToEditQuote.check_in,
      check_out: this.state.safeToEditQuote.check_out,
      num_guests: numGuests,
      channel_id: this.state.safeToEditQuote.channel_id,
      booking_code: this.props.bookingCode
    };
    if (
      this.state.protectedQuote.total_cents === 0 &&
      this.state.protectedQuote.line_items.length === 1
    ) {
      return BookingService.simpleUpdate(
        this.props.organizationID,
        this.props.bookingCode,
        {
          num_guests: numGuests,
          bookable_id: this.props.vehicleBooking
            ? this.props.vehicleId
            : this.state.safeToEditQuote.unit_id
        }
      ).then(res => {
        window.location.reload();
      });
    } else {
      QuoteService.create(this.props.organizationID, data).then(response => {
        const oldLi = this.getAdditionalGuestFeeLineItem(
          this.state.safeToEditQuote
        );
        const newLi = this.getAdditionalGuestFeeLineItem(response.quote);
        const diff = newLi.total_cents - oldLi.total_cents;
        const changeType = diff < 0 ? 'decrease' : 'increase';
        const changeAmount = Math.abs(diff / 100.0);
        this.setState({ selectedLineItem: oldLi }, () => {
          this.setState({ changeType, changeAmount }, () => {
            QuoteService.update(
              this.props.organizationID,
              this.state.safeToEditQuote.id,
              { num_guests: numGuests }
            ).catch(err =>
              displayError({ message: 'Error updating quote', err })
            );
          });
        });
      });
    }
  };

  onCalculateClick = ({ changeType, changeAmount }) => {
    this.setState({ changeType, changeAmount }, () => {
      QuoteService.updateLineItem(
        this.props.organizationID,
        this.state.safeToEditQuote.id,
        this.state.selectedLineItem?.id,
        this.calcNewLineItemTotalCents()
      )
        .then(response => {
          this.setState({ editedQuote: response.quote }, () => {
            QuoteService.updateBookingNetDetail(
              this.props.organizationID,
              this.state.editedQuote.id
            )
              .then(response => {
                this.setState(
                  {
                    editedBookingNetDetail: response.booking_net_detail,
                    chargeAmount: this.calcNewChargeTotal(
                      response.booking_net_detail
                    )
                  },
                  () => {
                    this.afterEdit();
                  }
                );
              })
              .catch(err =>
                displayError({
                  message: 'Error updating booking net detail',
                  err
                })
              );
          });
        })
        .catch(err =>
          displayError({ message: 'Error updating line item', err })
        );
    });
  };

  onDeleteClick = (changeType, changeAmount) => {
    this.setState({ changeType, changeAmount }, () => {
      QuoteService.updateLineItem(
        this.props.organizationID,
        this.state.safeToEditQuote.id,
        this.state.selectedLineItem?.id,
        this.calcNewLineItemTotalCents()
      )
        .then(response => {
          this.setState({ editedQuote: response.quote }, () => {
            QuoteService.updateBookingNetDetail(
              this.props.organizationID,
              this.state.editedQuote.id
            )
              .then(response => {
                this.setState(
                  {
                    editedBookingNetDetail: response.booking_net_detail,
                    chargeAmount: this.calcNewChargeTotal(
                      response.booking_net_detail
                    )
                  },
                  () => {
                    this.props.afterDelete(this.state.editedQuote);
                  }
                );
              })
              .catch(err =>
                displayError({
                  message: 'Error updating booking net detail',
                  err
                })
              );
          });
        })
        .catch(err =>
          displayError({ message: 'Error updating line item', err })
        );
    });
  };

  afterEdit = () => {
    this.props.afterEdit &&
      this.props.afterEdit(this.state.editedQuote, this.state.chargeAmount);
  };

  updateChargeAmount = chargeAmount => {
    this.setState({ chargeAmount }, () => {
      this.afterEdit();
    });
  };

  calcNewChargeTotal = newBookingNetDetail => {
    const newBookingTotalCents = newBookingNetDetail.booking_total_cents;
    const oldBookingTotalCents = this.state.originalBookingNetDetail
      .booking_total_cents;

    return ((newBookingTotalCents - oldBookingTotalCents) / 100.0).toFixed(2);
  };

  calcNewLineItemTotalCents = () => {
    const {
      changeType,
      changeAmount,
      selectedLineItem: { total_cents: oldTotal }
    } = this.state;
    let multiplier;
    let newChangeAmount;
    switch (changeType) {
      case 'delete':
        return changeType;
      case 'increase':
        multiplier = 100.0;
        newChangeAmount = parseFloat(changeAmount) * multiplier;
        return Math.round(oldTotal + newChangeAmount);
      case 'decrease':
        multiplier = -100.0;
        newChangeAmount = parseFloat(changeAmount) * multiplier;
        return Math.round(oldTotal + newChangeAmount);
      case 'override':
        return changeAmount * 100.0;
    }
  };

  toggleApplyCharge = () => {
    this.setState({ applyCharge: !this.state.applyCharge }, () => {
      this.props.updateApplyCharge &&
        this.props.updateApplyCharge(this.state.applyCharge);
    });
  };

  afterQuoteLineItemCreate = quote => {
    this.setState({ loading: true }, () => {
      this.setState({ safeToEditQuote: quote, loading: false }, () =>
        this.props.afterLineItemCreated(quote)
      );
    });
  };

  updateOwnerDatesButton = text => {
    return (
      <div style={{ marginRight: '16px' }}>
        <label>&nbsp;</label>
        <a
          className="button"
          onClick={() => {
            this.updateOwnerDates();
          }}
          style={{ padding: '13px 24px' }}
        >
          {text}
        </a>
      </div>
    );
  };

  updateOwnerDates = () => {
    this.setState({ loading: true }, () => {
      BookingService.update(this.props.organizationID, this.props.bookingCode, {
        check_in: this.state.startDate,
        check_out: this.state.endDate
      })
        .then(response => {
          window.location.reload();
        })
        .catch(err => displayError({ message: 'Error updating booking', err }));
    });
  };

  changeDates = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  render() {
    const quote = this.state.safeToEditQuote;

    if (this.state.loading) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 256,
            width: '100%',
            fontSize: 61,
            color: '#7940ff'
          }}
        >
          <IconFontAwesome name="spinner" spin />
        </div>
      );
    }

    return (
      <div>
        <label>Click The Line Item You Would Like To Adjust:</label>
        <BookingWidgetContainer
          quote={quote}
          allowEdits
          onLineItemSelect={this.onLineItemSelect}
          selectedLineItem={this.state.selectedLineItem}
          style={{ maxWidth: '640px' }}
        />
        {this.props.nonGuestBooking && (
          <WidgetDatePicker
            bookingCalendar={this.state.availabilityCalendar}
            organizationID={this.props.organizationID}
            unitID={this.props.unitID}
            startDate={moment(quote.check_in, 'YYYY-MM-DD')}
            endDate={moment(quote.check_out, 'YYYY-MM-DD')}
            initialStartDate={moment(quote.check_in, 'YYYY-MM-DD')}
            initialEndDate={moment(quote.check_out, 'YYYY-MM-DD')}
            onDatesChange={this.changeDates}
          />
        )}
        {this.props.nonGuestBooking &&
          this.state.startDate &&
          this.state.endDate &&
          this.updateOwnerDatesButton('Confirm')}
        {!this.state.selectedLineItem ? (
          <React.Fragment>
            {!this.state.updatingNumGuests && (
              <div>
                <label>
                  <span>
                    Or add a{' '}
                    <a
                      onClick={() =>
                        this.setState({ addingLineItemType: 'fees' })
                      }
                      style={{ display: 'inline' }}
                    >
                      fee
                    </a>
                    {this.props.vehicleBooking && (
                      <a
                        onClick={() =>
                          this.setState({
                            addingLineItemType: 'delivery locations'
                          })
                        }
                        style={{ display: 'inline' }}
                      >
                        , delivery location
                      </a>
                    )}{' '}
                    or{' '}
                    <a
                      onClick={() =>
                        this.setState({ addingLineItemType: 'taxes' })
                      }
                      style={{ display: 'inline' }}
                    >
                      tax
                    </a>{' '}
                    line item.
                  </span>
                </label>
                {this.state.addingLineItemType && (
                  <QuoteLineItemCreator
                    quote={quote}
                    lineItemType={this.state.addingLineItemType}
                    organizationID={this.props.organizationID}
                    afterCreate={this.afterQuoteLineItemCreate}
                    onCancel={() => this.setState({ addingLineItemType: null })}
                  />
                )}
              </div>
            )}
            {!this.state.addingLineItemType &&
              !this.props.disableNumGuestsEditing && (
                <div>
                  <label>
                    <span>
                      Or{' '}
                      <a
                        onClick={() =>
                          this.setState({ updatingNumGuests: true })
                        }
                        style={{ display: 'inline' }}
                      >
                        update number of guests
                      </a>
                    </span>
                  </label>
                  {this.state.updatingNumGuests && (
                    <QuoteNumGuestsUpdater
                      quote={quote}
                      organizationID={this.props.organizationID}
                      onChange={this.onNumGuestsChange}
                      onCancel={() =>
                        this.setState({ updatingNumGuests: false })
                      }
                    />
                  )}
                </div>
              )}
          </React.Fragment>
        ) : (
          <LineItemEditor
            lineItem={this.state.selectedLineItem}
            onCalculateClick={this.onCalculateClick}
            currency={quote.currency}
            startDate={moment(quote.check_in, 'YYYY-MM-DD')}
            endDate={moment(quote.check_out, 'YYYY-MM-DD')}
            onDatesChange={this.onDatesChange}
            unitID={
              this.props.vehicleBooking ? this.props.vehicleId : quote.unit_id
            }
            vehicleBooking={this.props.vehicleBooking}
            vehicle={this.props.vehicle}
            changeAmount={this.state.changeAmount}
            changeType={this.state.changeType}
            organizationID={this.props.organizationID}
            disableDateEditing={this.props.disableDateEditing}
            onCancel={() => this.setState({ selectedLineItem: null })}
            editingDates
            afterCreate={this.afterQuoteLineItemCreate}
            onDeleteClick={this.onDeleteClick}
            ownerOrFriendBooking={this.props.ownerOrFriendBooking}
          />
        )}

        {this.state.editedQuote && this.state.selectedLineItem && (
          <EditedQuoteForm
            editedQuote={this.state.editedQuote}
            safeToEditQuote={this.state.safeToEditQuote}
            selectedLineItem={this.state.selectedLineItem}
            currency={quote.currency}
          />
        )}
        {this.state.editedBookingNetDetail && this.state.selectedLineItem && (
          <div>
            <EditedBookingNetDetailForm
              editedBookingNetDetail={this.state.editedBookingNetDetail}
              originalBookingNetDetail={this.state.originalBookingNetDetail}
              currency={quote.currency}
            />
            <label>
              NOTE: Funds Collected and System Fees are calculated after you
              decide how to apply the charge. Also, the Final Net To You total
              is subject to change depending on how you process the charges.
            </label>
            {this.props.updateApplyCharge && !this.props.hideChargeFields && (
              <div>
                <div>
                  <InputMoney
                    name="charge_amount"
                    label="Recommended Amount To Charge In Order To Cover This Change:"
                    currency={quote.currency}
                    setMoney={this.updateChargeAmount}
                    value={this.state.chargeAmount}
                    containerStyles={{ marginRight: '16px', maxWidth: '350px' }}
                    symbolStyles={{
                      padding: '7px 12px',
                      borderRadius: '4px 0 0 4px'
                    }}
                    inputStyles={{
                      padding: '7px 12px',
                      borderRadius: '0 4px 4px 0'
                    }}
                  />
                </div>

                <DirectCheckbox
                  name="applyCharge"
                  checked={this.state.applyCharge}
                  onChange={this.toggleApplyCharge}
                  labelText="I would like to process this charge amount after applying this change."
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

QuoteEditingForm.defaultProps = {
  applyCharge: false,
  updatingNumGuests: false
};
