import request from "apiClient";
export default class ExternalContractService {
  static index(organizationId, brandId) {
    return request({
      url: `/api/${organizationId}/external_contracts?brand_id=${brandId}`,
      method: "GET"
    });
  }
  static update(organizationId, externalContractId, data) {
    return request({
      url: `/api/${organizationId}/external_contracts/${externalContractId}`,
      method: "PATCH",
      data
    });
  }
  static create(organizationId, data) {
    return request({
      url: `/api/${organizationId}/external_contracts`,
      method: "POST",
      data
    });
  }
  static get(organizationId, externalContractId) {
    return request({
      url: `/api/${organizationId}/external_contracts/${externalContractId}`,
      method: "GET"
    });
  }
  static units(organizationID, externalContractId, queryString) {
    return request({
      url: `/api/${organizationID}/external_contracts/${externalContractId}/units${queryString}`,
      method: "GET"
    });
  }
  static toggleUnit(organizationID, externalContractId, id, className) {
    return request({
      url: `/api/${organizationID}/external_contracts/${externalContractId}/units`,
      method: "PATCH",
      data: {
        klass_id: id,
        klass_name: className
      }
    });
  }
}
