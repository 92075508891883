import React from 'react';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const HelpLink = () => {
  return (
    <Navbar.Link
      href="https://help.getdirect.io/en/"
      openInNewTab
      linkType="link"
    >
      Help
    </Navbar.Link>
  );
};

export default HelpLink;
