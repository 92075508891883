import React from 'react';
import { Switch, Route } from 'react-router-dom';
import UnifiedInboxNav from './unified-inbox';

const UnifiedInboxRoutes = props => {
  return (
    <Switch>
      <Route
        path="/inbox"
        render={() => {
          return <UnifiedInboxNav {...props} />;
        }}
      />
    </Switch>
  );
};

export default UnifiedInboxRoutes;
