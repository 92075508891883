import React from 'react';

import { Times } from 'adminComponents/icons';
import { CustomerContext } from 'adminContexts';

const ContainerStyles = {
  height: '100%',
  width: '30px',
  textAlign: 'center',
  cursor: 'pointer'
};

const CloseCustomerSidebarLink = () => (
  <CustomerContext.Consumer>
    {state => (
      <div
        style={ContainerStyles}
        onClick={() => {
          state.onSetSidebarOpen(false);
          window.location.reload(false);
        }}
      >
        <Times />
      </div>
    )}
  </CustomerContext.Consumer>
);

export default CloseCustomerSidebarLink;
