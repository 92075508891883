const formatLocationAddress = (location) => {
  const firstAddressLine = `${[location.adr_street, location.adr_unit].join(" ")},`;
  const secondAddressLine = [
    location.adr_city,
    location.adr_state,
    location.adr_country,
    location.adr_postal_code
  ].join(", ");
  const emptyAddressSegment = (segment) => Array.from(new Set(segment.replace(/ /g, ""))).join() === ",";
  const removeLeadingSpaceBeforeComma = (str) => str.replace(/ \,/g, ",");
  return `<address>${!emptyAddressSegment(firstAddressLine) && removeLeadingSpaceBeforeComma(firstAddressLine)}<br />${!emptyAddressSegment(secondAddressLine) && removeLeadingSpaceBeforeComma(secondAddressLine)}</address>`;
};
const showExternalRVshareLink = (vehicle) => {
  return vehicle.external_id && vehicle.rvshare_channel_mapping && vehicle.rvshare_channel_mapping.status === "active" && vehicle.rvshare_channel_mapping.visible;
};
export { formatLocationAddress, showExternalRVshareLink };
