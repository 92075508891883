// Dependencies
// -----------------------------------------------
import React from 'react';
import styled from 'styled-components';

// Styled Components
// -----------------------------------------------
const TipContainer = styled.figure`
  width: 100%;
  margin: 0;

  .tip-toggle-link {
    border: none;
    float: none;
    background: none;
    width: 100% !important;
    display: block;
    margin: 0;
  }

  .tip-container-overlay {
    max-width: 200px;
  }

  .tip-container {
    font-size: 10px !important;
  }
`;

// -----------------------------------------------
// COMPONENT->BOOKING-INLINE-TIP -----------------
// -----------------------------------------------
export default class BookingInlineTip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  toggleExpanded = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    return (
      <TipContainer className="inline-tip">
        <a
          className="tip-toggle-link"
          href="#"
          onClick={e => this.toggleExpanded(e)}
        />
        {this.state.expanded && (
          <figure className="tip-container-overlay">
            <a
              className="close-link"
              href="#"
              onClick={e => this.toggleExpanded(e)}
            >
              ×
            </a>
            <figure className="tip-container" style={{ minWidth: '165px' }}>
              {this.props.tip}
            </figure>
          </figure>
        )}
      </TipContainer>
    );
  }
}
