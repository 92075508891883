import request from "apiClient";
export default class BookingNotificationService {
  static create(organizationID, bookingID, nType) {
    return request({
      url: `/api/${organizationID}/bookings/sent_notifications`,
      method: "POST",
      data: {
        booking_id: bookingID,
        n_type: nType
      }
    });
  }
}
