import React from 'react';
import { Navbar as NavigationBar } from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import { useDetectMobile } from 'sharedHooks';
import PropTypes from 'prop-types';
import {
  selectNavigationState,
  updateNavigation
} from 'reduxSlices/navigation';
import NavbarLeft from './navbar-left';
import NavbarMobile from './navbar-mobile';
import NavbarRight from './navbar-right';

const Navbar = props => {
  const { isLargeDesktop } = useDetectMobile();
  const navState = useSelector(selectNavigationState);
  const dispatch = useDispatch();

  const closeMobileNav = () => {
    if (!isLargeDesktop) {
      dispatch(updateNavigation({ revealMobileNav: false }));
    }
  };

  return (
    <NavigationBar>
      <NavbarLeft
        {...props}
        activeRoutes={navState.activeRoutes}
        homeLinkOnClick={() => {
          dispatch(updateNavigation({ revealMobileNav: false }));
        }}
      />
      <NavbarRight
        {...props}
        onMobileClick={() => {
          dispatch(
            updateNavigation({
              revealMobileNav: !navState.revealMobileNav,
              searchPanelVisible: false
            })
          );
        }}
        toggleMobileIcon={navState.revealMobileNav}
        activeRoutes={navState.activeRoutes}
      />
      <NavbarMobile
        {...props}
        reveal={navState.revealMobileNav}
        activeRoutes={navState.activeRoutes}
        closeMobileNav={closeMobileNav}
      />
    </NavigationBar>
  );
};

Navbar.propTypes = {
  linkCollection: PropTypes.oneOf(['admin', 'rv-fleet'])
};

Navbar.defaultProps = {
  linkCollection: 'admin'
};

export default Navbar;
