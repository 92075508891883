import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import NotificationService from 'adminApi/NotificationService';
import displayError from 'sharedComponents/ErrorDisplay';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { MESSAGING_NAV_TAGS } from '../../../../../shared/nav';

const DirectSystemEmailDetail = props => {
  const dispatch = useDispatch();
  const [emailName, setEmailName] = useState('');

  useEffect(
    () => {
      NotificationService.get(props.organization.id, props.notificationId)
        .then(response => {
          setEmailName(response.notification.name);
        })
        .catch(err =>
          displayError({ message: 'Error loading notification.', err })
        );
    },
    [props]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: MESSAGING_NAV_TAGS.PRIMARY,
        secondary: MESSAGING_NAV_TAGS.secondary.SYSTEM_EMAILS
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/messaging/direct-system-generated-auto-emails',
            label: 'Direct System Emails'
          },
          {
            label: 'Edit Message'
          }
        ]}
      >
        {emailName}
      </PageHeader.Left>
    </PageHeader>
  );
};

export default DirectSystemEmailDetail;
