import React from 'react';
import find from 'lodash/find';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';
import CurrencyDefinitions from 'sharedUtils/currency-definitions';

const CurrencySelect = props => {
  return (
    <div style={props.style}>
      <label htmlFor="currency">Currency</label>
      <InputSelect
        name="currency"
        className="magnified"
        options={CurrencyDefinitions}
        isClearable={false}
        isSearchable={false}
        placeholder="Select currency"
        value={find(
          CurrencyDefinitions,
          option => option.value === props.currency
        )}
        onChange={props.onChange}
      />
    </div>
  );
};

export default CurrencySelect;
