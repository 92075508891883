import React from 'react';

import SimpleSelect from './SimpleSelect';

const defaultSelectData = {
  model: 'LedgerAccount',
  where: ['visible = true'],
  select: ['id', 'ledger_number', 'name'],
  order: ['ledger_number']
};

const LedgerAccountSelect = props => (
  <SimpleSelect
    selectData={{ ...defaultSelectData, ...props.defaultSelectData }}
    label={props.label}
    placeholder="Select..."
    name={props.name}
    optionLabel={option => `${option.ledger_number} - ${option.name}`}
    isClearable={props.isClearable}
    isSearchable
    defaultValue={props.defaultValue}
    useDesignKit={props.useDesignKit}
    {...props}
  />
);

export default LedgerAccountSelect;

LedgerAccountSelect.defaultProps = {
  isClearable: true,
  label: 'Account',
  name: 'ledger_account'
};
