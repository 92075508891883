const organizationTypeOptions = [
  { value: "bnb", label: "Bed and Breakfast" },
  { value: "hotel", label: "Hotel" },
  { value: "hostel", label: "Hostel" },
  { value: "vacation_rental", label: "Vacation rental" },
  { value: "rv_fleet", label: "RV Fleet" }
];
const companyTypeOptions = [
  { value: "corporation", label: "Corporation" },
  { value: "sole_prop", label: "Sole proprietorship" },
  { value: "non_profit", label: "Non-profit organization" },
  { value: "partnership", label: "Partnership" },
  { value: "llc", label: "LLC" },
  { value: "other", label: "Other" }
];
const languageOptions = [
  { value: "en", label: "English", nativeName: "English" },
  { value: "ar", label: "Arabic", nativeName: "\u0627\u0644\u0639\u0631\u0628\u064A\u0629" },
  { value: "bn", label: "Bengali", nativeName: "\u09AC\u09BE\u0982\u09B2\u09BE" },
  {
    value: "zh",
    label: "Chinese",
    nativeName: "\u4E2D\u6587 (Zh\u014Dngw\xE9n), \u6C49\u8BED, \u6F22\u8A9E"
  },
  { value: "cs", label: "Czech", nativeName: "\u010Cesky, \u010Ce\u0161tina" },
  { value: "da", label: "Danish", nativeName: "Dansk" },
  { value: "nl", label: "Dutch", nativeName: "Nederlands, Vlaams" },
  { value: "et", label: "Estonian", nativeName: "Eesti, Eesti keel" },
  { value: "fr", label: "French", nativeName: "Fran\xE7ais" },
  { value: "de", label: "German", nativeName: "Deutsch" },
  { value: "el", label: "Greek, Modern", nativeName: "\u0395\u03BB\u03BB\u03B7\u03BD\u03B9\u03BA\u03AC" },
  { value: "hi", label: "Hindi", nativeName: "\u0939\u093F\u0928\u094D\u0926\u0940, \u0939\u093F\u0902\u0926\u0940" },
  { value: "hu", label: "Hungarian", nativeName: "Magyar" },
  { value: "id", label: "Indonesian", nativeName: "Bahasa Indonesia" },
  { value: "it", label: "Italian", nativeName: "Italiano" },
  {
    value: "ja",
    label: "Japanese",
    nativeName: "\u65E5\u672C\u8A9E (\u306B\u307B\u3093\u3054\uFF0F\u306B\u3063\u307D\u3093\u3054)"
  },
  { value: "jv", label: "Javanese", nativeName: "basa Jawa" },
  {
    value: "ko",
    label: "Korean",
    nativeName: "\uD55C\uAD6D\uC5B4 (\u97D3\u570B\u8A9E), \uC870\uC120\uB9D0 (\u671D\u9BAE\u8A9E)"
  },
  { value: "nb", label: "Norwegian Bokm\xE5l", nativeName: "Norsk bokm\xE5l" },
  { value: "pa", label: "Panjabi, Punjabi", nativeName: "\u0A2A\u0A70\u0A1C\u0A3E\u0A2C\u0A40, \u067E\u0646\u062C\u0627\u0628\u06CC\u200E" },
  { value: "pl", label: "Polish", nativeName: "polski" },
  { value: "pt", label: "Portuguese", nativeName: "Portugu\xEAs" },
  { value: "ru", label: "Russian", nativeName: "P\u0443\u0441\u0441\u043A\u0438\u0439 \u044F\u0437\u044B\u043A" },
  {
    value: "es",
    label: "Spanish; Castilian",
    nativeName: "Espa\xF1ol, Castellano"
  },
  { value: "sv", label: "Swedish", nativeName: "svenska" },
  { value: "th", label: "Thai", nativeName: "\u0E44\u0E17\u0E22" },
  { value: "tr", label: "Turkish", nativeName: "T\xFCrk\xE7e" },
  { value: "vi", label: "Vietnamese", nativeName: "Ti\u1EBFng Vi\u1EC7t" }
];
const paymentProcessorOptions = [
  { value: "lynnbrook", label: "Lynnbrook" },
  { value: "stripe", label: "Stripe" },
  { value: "unspecified", label: "Undecided - Will Select Later" }
];
export {
  organizationTypeOptions,
  companyTypeOptions,
  languageOptions,
  paymentProcessorOptions
};
