import request from "apiClient";
export default class StatementService {
  static index(organizationId, queryString) {
    return request({
      url: `/api/${organizationId}/statements${queryString}`,
      method: "GET"
    });
  }
  static get(organizationId, statementId) {
    return request({
      url: `/api/${organizationId}/statements/${statementId}`,
      method: "GET"
    });
  }
  static update(organizationId, statementId, data) {
    return request({
      url: `/api/${organizationId}/statements/${statementId}`,
      method: "PUT",
      data
    });
  }
  static dismiss(organizationId, statementId) {
    return request({
      url: `/api/${organizationId}/statements/${statementId}/dismiss`,
      method: "PATCH"
    });
  }
  static recreate(organizationId, statementId) {
    return request({
      url: `/api/${organizationId}/statements/${statementId}/recreate`,
      method: "PATCH"
    });
  }
  static getIncomes(organizationId, statementId) {
    return request({
      url: `/api/${organizationId}/statements/${statementId}/unit_incomes`,
      method: "GET"
    });
  }
  static billIncomes(organizationId, statementId) {
    return request({
      url: `/api/${organizationId}/statements/${statementId}/bill_incomes`,
      method: "GET"
    });
  }
  static getExpenses(organizationId, statementId) {
    return request({
      url: `/api/${organizationId}/statements/${statementId}/unit_expenses`,
      method: "GET"
    });
  }
  static history(organizationId, statementId) {
    return request({
      url: `/api/${organizationId}/statements/${statementId}/history`,
      method: "GET"
    });
  }
}
