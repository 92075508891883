import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Container,
  PageHeader,
  Spacer,
  TextBody,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { DASHBOARD_NAV_TAGS } from '../../../../../shared/nav';
import { useDetectMobile } from '../../../../../shared/hooks';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { isTouch } = useDetectMobile();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: DASHBOARD_NAV_TAGS.PRIMARY
      })
    );
    const root = document.getElementsByTagName('html')[0];
    const main = document.getElementsByClassName('mainApp')[0];
    root.classList.add('dashboard__html');
    main.classList.add('dashboard__mainApp');
    return () => {
      root.classList.remove('dashboard__html');
      main.classList.remove('dashboard__mainApp');
    };
  }, []);

  return (
    <>
      <Box className="dashboard__pageHeader" paddingBottom="m">
        <PageHeader>
          <PageHeader.Left>Dashboard</PageHeader.Left>
        </PageHeader>
        <Container variation="extended">
          {isTouch ? (
            <TextBody textColor="white" weight="regular">
              Quick insights into your organization
            </TextBody>
          ) : (
            <TextH2 textColor="white" weight="regular">
              Quick insights into your organization
            </TextH2>
          )}
        </Container>
        <Spacer size="xxl" />
      </Box>
    </>
  );
};

export default Dashboard;
