import request from "apiClient";
const getUrl = ({ orgId, vehicleId, unitId, fromUnit, verb, bedroomId }) => {
  switch (verb) {
    case "post":
    case "get":
      return fromUnit ? `/api/${orgId}/units/${unitId}/bedrooms` : `/api/${orgId}/vehicles/${vehicleId}/bedrooms`;
    case "patch":
    case "put":
    case "delete":
      return fromUnit ? `/api/${orgId}/units/${unitId}/bedrooms/${bedroomId}` : `/api/${orgId}/vehicles/${vehicleId}/bedrooms/${bedroomId}`;
    default:
      return "";
  }
};
const listBedrooms = ({ orgId, vehicleId, unitId, fromUnit }) => {
  const url = getUrl({ verb: "get", fromUnit, unitId, vehicleId, orgId });
  return request({
    url,
    method: "GET"
  });
};
const createBedroom = ({ orgId, vehicleId, unitId, fromUnit, data }) => {
  const url = getUrl({ verb: "post", fromUnit, unitId, orgId, vehicleId });
  return request({
    url,
    method: "POST",
    data
  });
};
const updateBedroom = ({ orgId, vehicleId, unitId, fromUnit, data, bedroomId }) => {
  const url = getUrl({ verb: "patch", fromUnit, unitId, orgId, vehicleId, bedroomId });
  return request({
    url,
    method: "PATCH",
    data
  });
};
const removeBedroom = ({ orgId, vehicleId, unitId, fromUnit, bedroomId }) => {
  const url = getUrl({ verb: "delete", fromUnit, unitId, orgId, vehicleId, bedroomId });
  return request({
    url,
    method: "DELETE"
  });
};
export default {
  listBedrooms,
  createBedroom,
  updateBedroom,
  removeBedroom
};
