import React from 'react';
import EmployeesLink from 'adminNavbar/people/links/employees-link';

const LinksMobile = props => {
  return (
    <>
      <EmployeesLink {...props} />
    </>
  );
};

export default LinksMobile;
