import React from 'react';
import WorkOrdersLink from 'adminNavbar/workflows/links/work-orders-link';
import SchedulingLink from 'adminNavbar/workflows/links/scheduling-link';
import WorkReportsLink from 'adminNavbar/workflows/links/work-reports-link';
import TemplatesLink from 'adminNavbar/workflows/links/templates-link';
import PermissionComponent from 'PermissionComponent';

const WorkflowsMobileLinks = props => {
  return (
    <>
      <PermissionComponent user={props.user} permission="work_orders">
        <WorkOrdersLink {...props} />
      </PermissionComponent>
      <SchedulingLink {...props} />
      <PermissionComponent user={props.user} permission="work_reports">
        <WorkReportsLink {...props} />
      </PermissionComponent>
      <PermissionComponent user={props.user} permission="templates">
        <TemplatesLink {...props} />
      </PermissionComponent>
    </>
  );
};

export default WorkflowsMobileLinks;
