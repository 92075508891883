import React from 'react';
import PropTypes from 'prop-types';
import { FlexBox, Spacer } from '@directsoftware/ui-kit-web-admin';

const ControlPanel = ({ children, justifyContent, flexDirection, gap }) => {
  return (
    <>
      <FlexBox
        justifyContent={justifyContent}
        flexDirection={flexDirection}
        padding="xs"
        gap={gap}
        className="controlPanel"
      >
        {children}
      </FlexBox>
      <Spacer size="s" />
    </>
  );
};

ControlPanel.propTypes = {
  children: PropTypes.any,
  justifyContent: PropTypes.oneOf(['space-between', 'flex-start']),
  flexDirection: PropTypes.oneOf(['row', 'column']),
  gap: PropTypes.oneOf(['xs', 's'])
};

ControlPanel.defaultProps = {
  justifyContent: 'space-between',
  flexDirection: 'row',
  gap: 'xs'
};

export default ControlPanel;
