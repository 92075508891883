import request from "apiClient";
export default class FeeAccountService {
  static index(organizationId, filter, activeOnly) {
    return request({
      url: `/api/${organizationId}/fee_accounts?filter=${filter}&active_only=${activeOnly}`,
      method: "GET"
    });
  }
  static new(organizationId) {
    return request({
      url: `/api/${organizationId}/fee_accounts/new`,
      method: "GET"
    });
  }
  static create(organizationId, data) {
    if (data.name.toLowerCase() == "custom") {
      data.cancellation ? data.name = "Cancellation Fee" : data.name = "Custom Fee";
    }
    return request({
      url: `/api/${organizationId}/fee_accounts`,
      method: "POST",
      data
    });
  }
  static fetch(organizationId, feeAccountId) {
    return request({
      url: `/api/${organizationId}/fee_accounts/${feeAccountId}`,
      method: "GET"
    });
  }
  static delete(organizationId, feeAccountId) {
    return request({
      url: `/api/${organizationId}/fee_accounts/${feeAccountId}`,
      method: "DELETE"
    });
  }
  static update(organizationId, feeAccountId, data) {
    return request({
      url: `/api/${organizationId}/fee_accounts/${feeAccountId}`,
      method: "PUT",
      data
    });
  }
}
