import React from 'react';
import NoteService from 'adminApi/NoteService';
import { Card } from '../SectionsStyles';
import { OrgFormattedDate } from 'adminComponents';
import displayError from 'sharedComponents/ErrorDisplay';
import MentionsTextBox from 'adminComponents/mentions-textbox';
import debounce from 'lodash/debounce';

import { Spinner } from 'adminComponents';

export default class NotesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: this.props.opportunity.notes || [],
      mentionees: null,
      textareaValue: '',
      reloaded: true,
      sending: false
    };
  }

  addMentionee = debounce((mentions, value) => {
    this.setState({ mentionees: mentions, textareaValue: value });
  }, 500);

  reload = () => {
    this.setState({ reloaded: true });
  };

  saveNote = () => {
    NoteService.create(this.props.organizationID, {
      notable_type: 'Opportunity',
      notable_id: this.props.opportunity.id,
      visible: true,
      message: this.state.textareaValue,
      mentionees: this.state.mentionees
    })
      .then(response => {
        const notes = [
          { created_at: response.created_at, message: response.message },
          ...this.state.notes
        ];
        this.setState({ notes, textareaValue: '', reloaded: false }, () =>
          this.reload()
        );
      })
      .catch(err => displayError({ message: 'Error saving note', err }));
  };

  renderNote = note => {
    return (
      <Card key={note.id}>
        <div>
          <div
            style={{
              fontSize: '12px',
              color: 'rgb(96, 107, 111)',
              marginBottom: '4px'
            }}
          >
            {<OrgFormattedDate date={note.created_at} />}
          </div>
          <div dangerouslySetInnerHTML={{ __html: note.message }} />
        </div>
      </Card>
    );
  };

  render() {
    return (
      this.state.reloaded && (
        <div>
          <MentionsTextBox
            organization={this.props.organization}
            addMentionee={this.addMentionee}
            users={this.props.users}
          />
          {this.state.sending ? (
            <Spinner />
          ) : (
            <a
              className="button"
              style={{ float: 'right', margin: '10px 0px' }}
              href="#"
              onClick={() => this.saveNote()}
            >
              Save note
            </a>
          )}
          {this.state.notes.map(note => this.renderNote(note))}
        </div>
      )
    );
  }
}
