import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BulkSettingsHome from './bulk-settings-home';
import BulkSettingsFeeAccountDetails from './bulk-settings-fee-account-details';
import BulkSettingsTaxAccountDetails from './bulk-settings-tax-account-details';
import BulkSettingsDeductionAccountDetails from './bulk-settings-deduction-accounts-detail';
import BulkSettingsUsageAccountDetails from './bulk-settings-usage-accounts.details';
import BulkSettingsHistory from './bulk-settings-history';

const OtherFeaturesRoutes = props => {
  return (
    <Switch>
      <Route
        path="/accounting/bulk-settings/usage-accounts/:usageAccountId"
        render={routeProps => (
          <BulkSettingsUsageAccountDetails
            {...props}
            usageAccountId={routeProps.match.params.usageAccountId}
          />
        )}
      />
      <Route
        path="/accounting/bulk-settings/deduction-accounts/:deductionAccountId"
        render={routeProps => (
          <BulkSettingsDeductionAccountDetails
            {...props}
            deductionAccountId={routeProps.match.params.deductionAccountId}
          />
        )}
      />
      <Route
        path="/accounting/bulk-settings/tax-accounts/:taxAccountId"
        render={routeProps => (
          <BulkSettingsTaxAccountDetails
            {...props}
            taxAccountId={routeProps.match.params.taxAccountId}
          />
        )}
      />
      <Route
        path="/accounting/bulk-settings/fee-accounts/:feeAccountId"
        render={routeProps => (
          <BulkSettingsFeeAccountDetails
            {...props}
            feeAccountId={routeProps.match.params.feeAccountId}
          />
        )}
      />
      <Route
        path="/accounting/bulk-settings/history"
        render={routeProps => <BulkSettingsHistory {...props} />}
      />
      <Route
        path="/accounting/bulk-settings"
        render={routeProps => <BulkSettingsHome {...props} />}
      />
    </Switch>
  );
};

export default OtherFeaturesRoutes;
