import React, { useEffect, useState, useRef } from 'react';
import { ajaxDataWithAuthorization } from 'apiClient';
import { loadStripe } from '@stripe/stripe-js';
import { StripeService } from 'adminApi';
import {
  Box,
  FlexBox,
  Logomark,
  StandByOverlay,
  Toast,
  Wizard
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectNewOrganization,
  updateNewOrganization
} from 'reduxSlices/createOrganization';
import { useDetectMobile } from 'sharedHooks';
import CreateOrganizationOverview from '../molecules/create-organization/create-organization-overview';
import CreateOrganizationTermsOfService from '../molecules/create-organization/create-organization-terms-of-service';
import CreateOrganizationBusinessLocation from '../molecules/create-organization/create-organization-business-location';

const CreateOrganization = ({ user, parentId }) => {
  const ToastRef = useRef();
  const { height } = useDetectMobile();
  const dispatch = useDispatch();
  const organization = useSelector(selectNewOrganization);

  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [disableNextButton, setDisableNextButton] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingSuccessful, setProcessingSuccessful] = useState(false);

  const handleDisableNextButton = value => {
    setDisableNextButton(value);
  };

  const createOrganization = () => {
    setIsProcessing(true);
    const randomNumber = new Date().valueOf();
    const subdomainWithId = `${organization.subdomain}-${randomNumber}`;
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'POST',
        url: '/api/organizations',
        data: {
          parent_id: organization.parentId,
          company_type: organization.companyType,
          currency: organization.currency,
          description: organization.description,
          language: organization.language,
          name: organization.name,
          organization_type: organization.organizationType,
          payment_processor: organization.paymentProcessor,
          subdomain: subdomainWithId,
          user_id: user.id,
          location_attributes: {
            adr_street: organization.adrStreet,
            adr_unit: organization.adrUnit,
            adr_city: organization.adrCity,
            adr_state: organization.adrState,
            adr_country: organization.adrCountry,
            adr_postal_code: organization.adrPostalCode
          },
          brands_attributes: [
            {
              name: organization.brandName,
              description: organization.brandDescription
            }
          ]
        }
      })
    )
      .done(function(data) {
        setProcessingSuccessful(true);
        ToastRef.current.toastSuccess('Organization created successfully!');
        const organizationSlug = data.subdomain;
        StripeService.getStripeCheckoutSession({
          org_slug: organizationSlug
        })
          .then(async res => {
            const publishableKey = res.stripe_publishable_key;
            const stripe = await loadStripe(publishableKey);
            stripe.redirectToCheckout({ sessionId: res.session_id });
          })
          .catch(err => {
            setIsProcessing(false);
            ToastRef.current.toastError(
              'Stripe Connection: Failed',
              'We were unable to connect you to Stripe and complete billing. Please check the info you entered and try again, or contact support',
              {
                actionHref: 'mailTo:help@getdirect.io',
                actionLabel: 'Contact Support',
                actionHrefType: 'link',
                actionHrefNewTab: true
              },
              Infinity
            );
            console.log(err);
          });
      })
      .fail(function(jqXhr) {
        setIsProcessing(false);
        ToastRef.current.toastError(
          'Create Organization: Failed',
          'We were unable to create your Organization. Please double-check the info you entered, or contact support',
          {
            actionHref: 'mailTo:help@getdirect.io',
            actionLabel: 'Contact Support',
            actionHrefType: 'link',
            actionHrefNewTab: true
          },
          Infinity
        );
        console.warn(jqXhr);
      });
  };

  useEffect(() => {
    dispatch(
      updateNewOrganization({
        parentId,
        tier: parentId ? 'suborg' : 'starter',
        interval: parentId ? 'custom' : 'monthly'
      })
    );
  }, []);

  return (
    <div className="newOrganization__wrapper" style={{ height }}>
      <Toast ref={ToastRef} />
      <StandByOverlay
        headline={
          processingSuccessful
            ? 'Connecting to Stripe...'
            : 'Creating Your Organization...'
        }
        reveal={isProcessing}
      />
      <Wizard
        logoLinkProps={{ type: 'link', href: '/my-organizations' }}
        wizardTitle="Create an Organization"
        wizardOnChange={({ newStepIndex }) => {
          if (!completedSteps.includes(newStepIndex))
            setCompletedSteps(prevState => [...prevState, currentStep]);
          setCurrentStep(newStepIndex);
        }}
        wizardOnFinish={createOrganization}
        currentStep={currentStep}
        completedSteps={completedSteps}
        disableNextButton={disableNextButton}
        finishButtonLabel="Continue to Billing"
      >
        <Wizard.StepPanel>
          <Wizard.Step stepTitle="Set Up Your Organization">
            <CreateOrganizationOverview
              user={user}
              handleDisableNextButton={handleDisableNextButton}
            />
          </Wizard.Step>
          <Wizard.Step stepTitle="Business Location">
            <CreateOrganizationBusinessLocation
              handleDisableNextButton={handleDisableNextButton}
            />
          </Wizard.Step>
          <Wizard.Step stepTitle="Terms of Service">
            <CreateOrganizationTermsOfService
              handleDisableNextButton={handleDisableNextButton}
              user={user}
            />
          </Wizard.Step>
        </Wizard.StepPanel>
        <Wizard.AsidePanel>
          <Wizard.Aside>
            <FlexBox
              flexDirection="column"
              className="newOrganization__aside_default"
              justifyContent="center"
              alignItems="center"
            >
              <Box className="newOrganization__aside_defaultLogo">
                <Logomark appearance="white" />
              </Box>
            </FlexBox>
          </Wizard.Aside>
        </Wizard.AsidePanel>
      </Wizard>
    </div>
  );
};

export default CreateOrganization;
