import React from 'react';
import AllChannelsLink from 'adminNavbar/distribution/links/all-channels-link';
import AirbnbLink from 'adminNavbar/distribution/links/airbnb-link';
import BookingComLink from 'adminNavbar/distribution/links/booking-com-link';
import HomeAwayLink from 'adminNavbar/distribution/links/home-away-link';
import ExpediaLink from 'adminNavbar/distribution/links/expedia-link';
import RvShareLink from 'adminNavbar/distribution/links/rv-share-link';
import GoogleLink from 'adminNavbar/distribution/links/google-link';

const DistributionMobileLinks = props => {
  return (
    <>
      <AllChannelsLink {...props} />
      <AirbnbLink {...props} />
      <BookingComLink {...props} />
      <HomeAwayLink {...props} />
      <ExpediaLink {...props} />
      <RvShareLink {...props} />
      <GoogleLink {...props} />
    </>
  );
};

export default DistributionMobileLinks;
