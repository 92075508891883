// Dependencies
// -----------------------------------------------
import React from 'react';
import { toast } from 'react-toastify';

// Components
// -----------------------------------------------
import { TextInput } from 'adminComponents';
import Notification from 'sharedComponents/Notification';

import { UserService } from 'adminApi';
import displayError from 'sharedComponents/ErrorDisplay';

// Constants
// -----------------------------------------------
const inputChecks = {
  cc_number: {
    maxLength: 16,
    nextInput: 'cc_month'
  },
  cc_month: {
    maxLength: 2,
    nextInput: 'cc_year'
  },
  cc_year: {
    maxLength: 4,
    nextInput: 'cc_cvc'
  }
};

// -----------------------------------------------
// COMPONENT->FIELDS-ORGANIZATION-CREDIT-CARD ----
// -----------------------------------------------
export default class FieldsOrganizationCreditCard extends React.Component {
  // ---------------------------------------------
  // CONSTRUCTOR ---------------------------------
  // ---------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      cc_name: '',
      cc_number: '',
      cc_month: '',
      cc_year: '',
      cc_cvc: '',
      submitting: false
    };
  }

  updateState = (name, value) => {
    if (inputChecks[name] && inputChecks[name]['maxLength'] === value.length) {
      this[inputChecks[name]['nextInput']].focus();

      // The following puts the cursor at the end of the next input
      const tempVal = this[inputChecks[name]['nextInput']].value;
      this[inputChecks[name]['nextInput']].value = '';
      this[inputChecks[name]['nextInput']].value = tempVal;
    }

    this.setState({ [name]: value });
  };

  onSubmit = e => {
    e.preventDefault();
    let { cc_name, cc_number, cc_month, cc_year, cc_cvc } = this.state;

    this.setState({ submitting: true }, () => {
      UserService.createStripeCustomer(
        0, // this orgId is irrelevant, but needed for an id to "search"
        this.props.user.id,
        {
          cc_name: cc_name,
          cc_number: cc_number.replace(/ /g, '').replace(/-/g, ''),
          cc_month: cc_month,
          cc_year: cc_year,
          cc_cvc: cc_cvc.replace(/ /g, '')
        }
      )
        .then(response => {
          this.setState({ submitting: false }, () => {
            toast.info('Card Saved Successfully.');
            this.props.setCCAccepted(true);
          });
        })
        .catch(err => {
          this.props.setCCAccepted(false);
          displayError({ message: 'Error creating stripe customer', err });
        });
    });
  };

  // ---------------------------------------------
  // RENDER --------------------------------------
  // ---------------------------------------------
  render() {
    return (
      <fieldset>
        <Notification />
        <header className="fieldset-header">
          <legend>Set up your credit card information</legend>
        </header>
        {this.props.parentId && (
          <p>
            Credit Card details are required for distribution eligibility on
            Airbnb since Airbnb is the merchant of record. No charges will be
            processed unless you are receiving Airbnb reservations through our
            system, in which case we will be collecting the guest fee for each
            transaction.
          </p>
        )}
        <TextInput
          label="Name on Card"
          name="cc_name"
          value={this.state.cc_name}
          placeholder="Name"
          onChange={this.updateState}
        />
        <TextInput
          label="Card Number"
          name="cc_number"
          value={this.state.cc_number}
          placeholder="XXXX XXXX XXXX XXXX"
          onChange={this.updateState}
          additionalProps={{ maxLength: 16 }}
        />
        <section className="three-col three-col-33-33-33">
          <TextInput
            label="Exp Month"
            name="cc_month"
            value={this.state.cc_month}
            placeholder="XX"
            onChange={this.updateState}
            additionalProps={{
              maxLength: 2,
              ref: ref => (this.cc_month = ref)
            }}
          />
          <TextInput
            label="Exp Year"
            name="cc_year"
            value={this.state.cc_year}
            placeholder="XXXX"
            onChange={this.updateState}
            additionalProps={{ maxLength: 4, ref: ref => (this.cc_year = ref) }}
          />
          <TextInput
            label="CVC"
            name="cc_cvc"
            value={this.state.cc_cvc}
            placeholder="XXX"
            onChange={this.updateState}
            additionalProps={{ maxLength: 4, ref: ref => (this.cc_cvc = ref) }}
          />
        </section>
        <a
          onClick={e => this.onSubmit(e)}
          className={`button ${this.state.submitting && 'disabled'}`}
          style={{ float: 'right', marginTop: '16px' }}
        >
          Save Credit Card
        </a>
      </fieldset>
    );
  }
}
