import React from 'react';

import NotesList from './NotesList';
import { UserService } from 'adminApi';

export default class ManageNotes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: []
    };
  }

  componentDidMount = () => {
    UserService.list(this.props.organization.id)
      .then(response => {
        this.setState({ users: response.mention_users });
      })
      .catch(err =>
        displayError({ message: 'Error fetching employees', autoClose: 5000 })
      );
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{ marginBottom: '8px', fontSize: '12px', cursor: 'pointer' }}
          onClick={this.props.loadOpportunities}
        >
          <img
            style={{ height: '10px', width: 'auto', paddingRight: '8px' }}
            alt="arrow-left"
            src="/assets/brand/arrow-left.svg"
          />
          ALL OPPORTUNITIES
        </div>
        <NotesList
          opportunity={this.props.opportunity}
          organizationID={this.props.organizationID}
          organization={this.props.organization}
          users={this.state.users}
        />
      </React.Fragment>
    );
  }
}
