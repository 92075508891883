import React, { useEffect, useState } from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';
import PortfolioService from '../../../../api/PortfolioService';

const PortfolioDetails = props => {
  const dispatch = useDispatch();
  const [portfolioName, setPortfolioName] = useState('');

  useEffect(
    () => {
      PortfolioService.get(props.organization.id, props.portfolioId).then(
        portfolio => {
          setPortfolioName(portfolio.name);
        }
      );
    },
    [props]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: LISTINGS_NAV_TAGS.PRIMARY,
        secondary: LISTINGS_NAV_TAGS.secondary.PORTFOLIOS
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/portfolios',
            label: 'Portfolios'
          },
          {
            label: 'Details'
          }
        ]}
      >
        {portfolioName}
      </PageHeader.Left>
    </PageHeader>
  );
};

export default PortfolioDetails;
