import request from "apiClient";
function index(organizationId, params = {}) {
  return request({
    url: `/api/${organizationId}/ledger_transaction_events`,
    method: "GET",
    params
  });
}
function update(organizationId, eventId, data) {
  return request({
    url: `/api/${organizationId}/ledger_transaction_events/${eventId}`,
    method: "PATCH",
    data
  });
}
const LedgerTransactionEventService = {
  index,
  update
};
export default LedgerTransactionEventService;
