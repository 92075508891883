// substitute, if true will replace the unit/property name
const MultiUnitCodeDesplay = (unitCode, propertyCode, unitName, substitute) => {
  if (unitCode) {
    return substitute ? unitCode : unitName + ' - ' + unitCode;
  } else if (propertyCode) {
    return substitute ? propertyCode : unitName + ' - ' + propertyCode;
  } else {
    return unitName;
  }
};

export default MultiUnitCodeDesplay;
