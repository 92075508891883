import React from 'react';
import { ajaxDataWithAuthorization } from 'apiClient';
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { stateFromHTML } from 'draft-js-import-html';

export default class ToggleableWysiwyg extends React.Component {
  constructor(props, _railsContext) {
    super(props);
    const editorState = this.initializeEditorState();
    this.state = {
      content: this.props.content,
      editorState: editorState,
      wysiwygToggled: this.props.wysiwygToggled
    };
  }

  initializeEditorState = () => {
    if (this.props.content) {
      const contentState = stateFromHTML(this.props.content);
      return EditorState.createWithContent(contentState);
    } else {
      return EditorState.createEmpty();
    }
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  onContentStateChange = contentState => {
    const content = draftToHtml(contentState, null, false, null);
    let stateChange = {};
    stateChange['content'] = content;
    this.setState(stateChange, () => {
      this.props.onContentChange(this.state.content);
    });
  };

  onContentHtmlChange = e => {
    const content = e.target.value;
    const blocksFromHtml = htmlToDraft(content);
    const contentBlocks = blocksFromHtml.contentBlocks;
    const contentState = ContentState.createFromBlockArray(contentBlocks);
    const editorState = EditorState.createWithContent(contentState);

    let stateChange = {};
    stateChange['content'] = e.target.value;
    stateChange['editorState'] = editorState;
    this.setState(stateChange, () => {
      this.props.onContentChange(this.state.content);
    });
  };

  toggleWysiwyg = e => {
    e.preventDefault();
    this.setState({ wysiwygToggled: !this.state.wysiwygToggled });
  };

  manageUpload = file => {
    return new Promise((resolve, reject) => {
      var d = new FormData();
      d.append('file', file);
      $.ajax(
        ajaxDataWithAuthorization({
          type: 'POST',
          url: '/api/' + this.props.organization.id + '/images/generic',
          context: this,
          data: d,
          processData: false,
          contentType: false,
          dataType: 'json',
          cache: false
        })
      )
        .done(function(data) {
          resolve({ data: { link: data.image.url } });
        })
        .fail(function(jqXhr) {
          reject();
        });
    });
  };

  render() {
    const { contentState, editorState } = this.state;
    const toolbar_slim = {
      image: {
        alignmentEnabled: false,
        uploadEnabled: true,
        uploadCallback: this.manageUpload
      },
      inline: {
        inDropdown: true
      },
      link: {
        inDropdown: true
      },
      list: {
        inDropdown: true
      },
      textAlign: {
        inDropdown: true
      },
      options: [
        'blockType',
        'fontSize',
        'textAlign',
        'colorPicker',
        'inline',
        'list',
        'link',
        'embedded',
        'image',
        'history'
      ]
    };
    const toolbar_wide = {
      image: {
        alignmentEnabled: false,
        uploadEnabled: true,
        uploadCallback: this.manageUpload
      },
      textAlign: {
        inDropdown: true
      },
      options: [
        'blockType',
        'fontSize',
        'textAlign',
        'colorPicker',
        'inline',
        'list',
        'link',
        'embedded',
        'image',
        'history'
      ]
    };

    return (
      <figure className="toggleable-wysiwyg">
        <header className="toggleable-wysiwyg-toggle">
          <a
            href="#"
            className={this.state.wysiwygToggled ? 'active' : 'inactive'}
            onClick={e => this.toggleWysiwyg(e)}
          >
            Rich Text
          </a>
          <a
            href="#"
            className={!this.state.wysiwygToggled ? 'active' : 'inactive'}
            onClick={e => this.toggleWysiwyg(e)}
          >
            HTML
          </a>
        </header>
        {this.state.wysiwygToggled ? (
          <div>
            <Editor
              contentState={contentState}
              editorState={editorState}
              toolbarClassName="home-toolbar"
              wrapperClassName="home-wrapper"
              editorClassName={`home-editor${
                this.props.disabled ? ' disabled' : ''
              }`}
              onContentStateChange={this.onContentStateChange}
              onEditorStateChange={this.onEditorStateChange}
              toolbar={toolbar_wide}
              readOnly={this.props.disabled}
            />
          </div>
        ) : (
          <textarea
            onChange={this.onContentHtmlChange}
            value={this.state.content}
            disabled={this.props.disabled}
          />
        )}
      </figure>
    );
  }
}

ToggleableWysiwyg.defaultProps = {
  wysiwygToggled: true,
  disabled: false
};
