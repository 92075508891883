import React from 'react';
import PermissionComponent from 'PermissionComponent';
import BankAccountsLink from 'adminNavbar/accounting/links/bank-accounts-link';
import ReportingLink from 'adminNavbar/accounting/links/reporting-link';

const LinksMobile = props => {
  return (
    <>
      <PermissionComponent user={props.user} permission="bank_accounts">
        {props.organization.payment_processor !== 'unspecified' &&
          props.user.role_name === 'organization_owner' && (
            <BankAccountsLink {...props} />
          )}
      </PermissionComponent>
      <PermissionComponent user={props.user} permission="view_reporting">
        <ReportingLink {...props} />
      </PermissionComponent>
    </>
  );
};

export default LinksMobile;
