import request from "apiClient";
export default class TaxAccountService {
  static index(organizationId, filter, activeOnly) {
    return request({
      url: `/api/${organizationId}/tax_accounts?filter=${filter}&active_only=${activeOnly}`,
      method: "GET"
    });
  }
  static new(organizationId) {
    return request({
      url: `/api/${organizationId}/tax_accounts/new`,
      method: "GET"
    });
  }
  static create(organizationId, data, feeAccountIds) {
    return request({
      url: `/api/${organizationId}/tax_accounts`,
      method: "POST",
      data,
      params: {
        fee_account_ids: feeAccountIds
      }
    });
  }
  static fetch(organizationId, taxAccountId) {
    return request({
      url: `/api/${organizationId}/tax_accounts/${taxAccountId}`,
      method: "GET"
    });
  }
  static delete(organizationId, taxAccountId) {
    return request({
      url: `/api/${organizationId}/tax_accounts/${taxAccountId}`,
      method: "DELETE"
    });
  }
  static update(organizationId, taxAccountId, data, feeAccountIds) {
    return request({
      url: `/api/${organizationId}/tax_accounts/${taxAccountId}`,
      method: "PUT",
      data,
      params: {
        fee_account_ids: feeAccountIds
      }
    });
  }
}
