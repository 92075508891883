// Dependencies
// -----------------------------------------------
import React from 'react';

// Components
// -----------------------------------------------
import {
  dispatchError,
  ConsolidatedItemEditToggleHeader
} from 'adminComponents';

// Styled Components
// -----------------------------------------------

import cloneDeep from 'lodash/cloneDeep';

// Services
// -----------------------------------------------
import { PropertyService } from 'adminApi';
import { RadioOption } from './styled';

// -----------------------------------------------
// COMPONENT->MANAGE-SINGLE-FIELDS-INSTRUCTIONS --
// -----------------------------------------------
export default class ManageSingleFieldsCleaningPractices extends React.Component {
  // ---------------------------------------------
  // CONSTRUCTOR ---------------------------------
  // ---------------------------------------------
  constructor(props, _railsContext) {
    super(props);
    this.cleaningTypes = {
      CLEANING_DISINFECTION:
        'Is this property being cleaned with disinfectant?',
      COMMON_SURFACE_DISINFECTANT_CLEANED:
        'Are high-touch surfaces cleaned with disinfectant?',
      ENHANCED_CLEANING_PRACTICES:
        'Are any enhanced cleaning and saftey measures currently being implemented at this property?',
      LINENS_HIGH_TEMP_WASH:
        'Are all towels and bedding washed in water that is at least 60°C/140°F between stays?'
    };

    this.state = {
      CLEANING_DISINFECTION: this.props.features_cleaning.CLEANING_DISINFECTION,
      COMMON_SURFACE_DISINFECTANT_CLEANED: this.props.features_cleaning
        .COMMON_SURFACE_DISINFECTANT_CLEANED,
      ENHANCED_CLEANING_PRACTICES: this.props.features_cleaning
        .ENHANCED_CLEANING_PRACTICES,
      LINENS_HIGH_TEMP_WASH: this.props.features_cleaning.LINENS_HIGH_TEMP_WASH,
      isDirty: false,
      isEditing: false,
      isSaving: false,
      isSaved: false,
      cachedProps: cloneDeep(this.props.features_cleaning)
    };
  }

  onChange = (name, value) => {
    const change = this.state[name];
    if (value === 'YES' && change.value !== true) {
      change.value = true;
    } else if (value === 'NO' && change.value !== false) {
      change.value = false;
    } else {
      change.value = null;
    }
    this.setState({ isDirty: true, name: change });
  };

  toggleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  finishEditing = saved => {
    let changeState = {
      isDirty: false,
      isEditing: false,
      isSaving: false,
      isSaved: saved
    };

    if (saved) {
      const clone = cloneDeep(this.state);
      delete clone.cachedProps;
      changeState.cachedProps = clone;
    } else {
      changeState = {
        ...changeState,
        CLEANING_DISINFECTION: this.state.cachedProps.CLEANING_DISINFECTION,
        COMMON_SURFACE_DISINFECTANT_CLEANED: this.state.cachedProps
          .COMMON_SURFACE_DISINFECTANT_CLEANED,
        ENHANCED_CLEANING_PRACTICES: this.state.cachedProps
          .ENHANCED_CLEANING_PRACTICES,
        LINENS_HIGH_TEMP_WASH: this.state.cachedProps.LINENS_HIGH_TEMP_WASH
      };
    }
    this.setState(changeState);
  };

  attemptSave = () => {
    this.setState({ isSaving: true, isSaved: false }, () => {
      PropertyService.update(
        this.props.organization.id,
        this.props.property.id,
        {
          features_cleaning: JSON.stringify({
            CLEANING_DISINFECTION: this.state.CLEANING_DISINFECTION,
            COMMON_SURFACE_DISINFECTANT_CLEANED: this.state
              .COMMON_SURFACE_DISINFECTANT_CLEANED,
            ENHANCED_CLEANING_PRACTICES: this.state.ENHANCED_CLEANING_PRACTICES,
            LINENS_HIGH_TEMP_WASH: this.state.LINENS_HIGH_TEMP_WASH
          })
        }
      )
        .then(response => {
          this.finishEditing(true);
        })
        .catch(error => {
          dispatchError();
          this.finishEditing(false);
        });
    });
  };

  renderEditing() {
    return (
      <form className="consolidated-item-form-editing">
        <div className="consolidated-item-subsection">
          {Object.keys(this.cleaningTypes).map((cleaningType, index) => (
            <div className="consolidated-item-subsection">
              <h4>{this.cleaningTypes[cleaningType]}</h4>
              <RadioOption key={index}>
                <input
                  type="radio"
                  name={cleaningType}
                  id={`${cleaningType}_yes`}
                  value="YES"
                  checked={
                    this.state[cleaningType] && this.state[cleaningType].value
                  }
                  onChange={e => this.onChange(e.target.name, e.target.value)}
                />
                <label htmlFor={`${cleaningType}_yes`}>Yes</label>
                <input
                  type="radio"
                  name={cleaningType}
                  id={`${cleaningType}_no`}
                  value="NO"
                  checked={
                    this.state[cleaningType] &&
                    !this.state[cleaningType].value &&
                    this.state[cleaningType].value !== null
                  }
                  onChange={e => this.onChange(e.target.name, e.target.value)}
                />
                <label htmlFor={`${cleaningType}_no`}>No</label>
              </RadioOption>
            </div>
          ))}
        </div>
      </form>
    );
  }

  renderViewing() {
    return (
      <form className="consolidated-item-form-editing">
        <div className="consolidated-item-subsection">
          {Object.keys(this.cleaningTypes).map((cleaningType, index) => (
            <div className="consolidated-item-subsection">
              <h4>{this.cleaningTypes[cleaningType]}</h4>
              <text>
                {this.state[cleaningType] && this.state[cleaningType].value
                  ? 'Yes'
                  : 'No'}
              </text>
            </div>
          ))}
        </div>
      </form>
    );
  }

  // ---------------------------------------------
  // RENDER --------------------------------------
  // ---------------------------------------------
  render() {
    return (
      <figure
        className={`consolidated-item-form-section ${
          this.state.isEditing ? 'form-editing' : ''
        }`}
      >
        <ConsolidatedItemEditToggleHeader
          toggleEditing={this.toggleEditing}
          finishEditingAndRevert={() => this.finishEditing(false)}
          finishEditingAndUpdate={() => this.finishEditing(true)}
          attemptSave={this.attemptSave}
          isDirty={this.state.isDirty}
          isEditing={this.state.isEditing}
          isSaving={this.state.isSaving}
          title="Cleaning Practices"
          blockEdit={this.props.blockEdit}
        />
        {this.state.isEditing ? this.renderEditing() : this.renderViewing()}
      </figure>
    );
  }
}
