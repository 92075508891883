import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CallToActionButton,
  ConfirmDialog,
  IconButton,
  IconFontAwesome,
  IconLink,
  PageHeader,
  StandByOverlay,
  Tooltip
} from '@directsoftware/ui-kit-web-admin';
import { BrandService } from 'src/admin/api';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { WEBSITE_NAV_TAGS } from '../../../../../shared/nav';
import {
  selectWebsiteCMSPageBuilderState,
  selectWebsiteCMSState,
  updateWebsiteCMSPageBuilder
} from '../../../../redux/slices/websiteCMS';

const PageEditHome = props => {
  const dispatch = useDispatch();
  const { cmsVersion, brand } = useSelector(selectWebsiteCMSState);
  const { isDirty, isSaved, data: pageData } = useSelector(
    selectWebsiteCMSPageBuilderState
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [deletingPage, setDeletingPage] = useState(false);
  const [siteName, setSiteName] = useState('');
  const [pageName, setPageName] = useState('');

  const deletePage = () => {
    setShowConfirm(false);
    setDeletingPage(true);
    BrandService.deletePage(props.organization.id, props.brandId, props.pageId)
      .then(() => {
        window.location = `/dashboard/${props.organization.subdomain}/brands/${
          props.brandId
        }/pages`;
      })
      .catch(err => console.log('delete page error', err));
  };

  const loadProps = () => {
    BrandService.getPages(props.organization.id, props.brandId)
      .then(data => {
        const pageProps = data.pages.find(p => p.id === Number(props.pageId));
        setPageName(pageProps.cms_display_name || pageProps.title);
      })
      .catch(err => console.log(err));
  };

  useEffect(
    () => {
      if (isSaved) setPageName(pageData.cms_display_name || pageData.title);
    },
    [isSaved]
  );

  useEffect(
    () => {
      setPageName(pageData.cms_display_name || pageData.title);
    },
    [pageData]
  );

  useEffect(
    () => {
      if (brand) {
        setSiteName(brand.name);
      }
    },
    [brand]
  );

  useEffect(
    () => {
      if (!props.page) {
        loadProps();
      }
    },
    [props]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: WEBSITE_NAV_TAGS.PRIMARY,
        secondary: WEBSITE_NAV_TAGS.secondary.SITE_EDITOR
      })
    );
  }, []);

  return (
    <>
      <StandByOverlay
        reveal={deletingPage}
        position="fixed"
        headline="Deleting this page..."
      />
      <PageHeader
        height="l"
        containerVariation={cmsVersion === 'v2' ? 'fluid' : 'extended'}
      >
        <PageHeader.Left
          breadcrumbs={[
            {
              href: '/brands',
              label: 'Website List'
            },
            {
              href: `/brands/${props.brandId}/pages`,
              label: siteName
            },
            {
              label: 'Edit Page'
            }
          ]}
        >
          {pageName}
        </PageHeader.Left>
        {cmsVersion === 'v2' && (
          <PageHeader.Right>
            <Tooltip tipContent="Delete Page">
              <IconButton
                onClick={() => setShowConfirm(true)}
                variation="danger"
                appearance="ghost"
              >
                <IconFontAwesome name="trashAlt" />
              </IconButton>
            </Tooltip>
            <Tooltip tipContent="Preview">
              <IconLink
                variation="secondary"
                appearance="ghost"
                href={`/dashboard/${props.organization.subdomain}/brands/${
                  props.brandId
                }/pages/${props.pageId}/preview-v2`}
                openNewTab
                type="link"
              >
                <IconFontAwesome name="eye" />
              </IconLink>
            </Tooltip>
            <CallToActionButton
              onClick={() => {
                dispatch(updateWebsiteCMSPageBuilder({ isSaving: true }));
              }}
              prependIcon={<IconFontAwesome name="floppyDisk" />}
              isDisabled={!isDirty}
            >
              Save
            </CallToActionButton>
          </PageHeader.Right>
        )}
      </PageHeader>
      <ConfirmDialog
        reveal={showConfirm}
        title="Delete this Page?"
        description="Are you sure you want to delete this page? This cannot be undone."
        confirmOnClick={() => deletePage()}
        cancelOnClick={() => setShowConfirm(false)}
        closeOnClick={() => setShowConfirm(false)}
        confirmLabel="Delete Page"
        isDanger
      />
    </>
  );
};

export default PageEditHome;
