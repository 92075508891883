import request from "apiClient";
export default class IdPhotoService {
  static index(organizationId, queryString) {
    return request({
      url: `/api/${organizationId}/id_photos${queryString}`,
      method: "GET"
    });
  }
  static create(organizationId, data = {}) {
    return request({
      url: `/api/${organizationId}/id_photos`,
      method: "POST",
      data
    });
  }
  static delete(organizationId, idPhotoId) {
    return request({
      url: `/api/${organizationId}/id_photos/${idPhotoId}`,
      method: "DELETE"
    });
  }
}
