import React from 'react';
import Loading from 'react-loading-components';

const Loadable = props => {
  if (props.loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: props.justify || 'center',
          alignItems: 'center',
          height: props.height || '300px',
          width: props.width || '100%'
        }}
      >
        <Loading type="tail_spin" width={50} height={50} fill="#00CC99" />
      </div>
    );
  }

  return <div>{props.children}</div>;
};

export default Loadable;
