import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { PEOPLE_NAV_TAGS } from '../../../../../shared/nav';
import TeamService from '../../../../api/TeamService';

const UserGroupEdit = props => {
  const dispatch = useDispatch();
  const [teamName, setTeamName] = useState('');

  useEffect(
    () => {
      TeamService.get(props.organization.id, props.teamId).then(team => {
        setTeamName(team.name);
      });
    },
    [props]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: PEOPLE_NAV_TAGS.PRIMARY,
        secondary: PEOPLE_NAV_TAGS.secondary.USER_GROUPS
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/groups',
            label: 'User Groups'
          },
          {
            label: 'Edit Details'
          }
        ]}
      >
        {teamName}
      </PageHeader.Left>
    </PageHeader>
  );
};

export default UserGroupEdit;
