import React from 'react';
import { Switch, Route } from 'react-router-dom';
import UserGroupsHome from './user-groups-home';
import LeadsHome from './leads-home';
import CustomersHome from './customers-home';
import CrmHome from './crm-home';
import EmployeesHome from './employees-home';
import EmployeesNew from './employees-new';
import EmployeeEdit from './employee-edit';
import UserGroupsNew from './user-groups-new';
import UserGroupEdit from './user-group-edit';

const PeopleRoutes = props => {
  return (
    <Switch>
      <Route path="/pipeline" render={() => <CrmHome {...props} />} />
      <Route path="/customers" render={() => <CustomersHome {...props} />} />
      <Route path="/leads" render={() => <LeadsHome {...props} />} />
      <Route path="/groups/new" render={routeProps => <UserGroupsNew />} />
      <Route
        path="/groups/:teamId"
        render={routeProps => (
          <UserGroupEdit {...props} teamId={routeProps.match.params.teamId} />
        )}
      />
      <Route path="/groups" render={() => <UserGroupsHome {...props} />} />
      <Route path="/employees/new" render={() => <EmployeesNew {...props} />} />
      <Route
        path="/employees/:employeeId"
        render={routeProps => (
          <EmployeeEdit
            {...props}
            {...routeProps}
            employeeId={routeProps.match.params.employeeId}
          />
        )}
      />
      <Route path="/employees" render={() => <EmployeesHome {...props} />} />
    </Switch>
  );
};

export default PeopleRoutes;
