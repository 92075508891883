import request from "apiClient";
export default class GeneralLedgerAccountService {
  static index(organizationId, params = {}) {
    return request({
      url: `/api/${organizationId}/ledger_accounts`,
      method: "GET",
      params
    });
  }
  static new(organizationId) {
    return request({
      url: `/api/${organizationId}/ledger_accounts/new`,
      method: "GET"
    });
  }
  static create(organizationId, data) {
    return request({
      url: `/api/${organizationId}/ledger_accounts`,
      method: "POST",
      data
    });
  }
  static fetch(organizationId, ledgerAccountId) {
    return request({
      url: `/api/${organizationId}/ledger_accounts/${ledgerAccountId}`,
      method: "GET"
    });
  }
  static delete(organizationId, ledgerAccountId) {
    return request({
      url: `/api/${organizationId}/ledger_accounts/${ledgerAccountId}`,
      method: "DELETE"
    });
  }
  static update(organizationId, ledgerAccountId, data) {
    return request({
      url: `/api/${organizationId}/ledger_accounts/${ledgerAccountId}`,
      method: "PATCH",
      data
    });
  }
  static syncBalances(orgId, ledgerAccountId) {
    return request({
      url: `/api/${orgId}/ledger_accounts/${ledgerAccountId}/sync_balances`,
      method: "PATCH"
    });
  }
}
