import request from "apiClient";
export default class GlobalSearchService {
  static list(orgId, pageNo, filtered, index, displaySize) {
    if (index !== "All") {
      return request({
        url: `/global_search/search`,
        method: "GET",
        params: {
          org_id: orgId,
          page_no: pageNo,
          query_string: filtered,
          s_index: index,
          page_size: displaySize
        }
      });
    } else {
      return request({
        url: `/global_search/search`,
        method: "GET",
        params: {
          org_id: orgId,
          page_no: pageNo,
          query_string: filtered,
          page_size: displaySize
        }
      });
    }
  }
}
