import * as React from "react";
export const useOverflow = (ref, callback, isHorizontal = false) => {
  const [isOverflow, setIsOverflow] = React.useState();
  React.useLayoutEffect(() => {
    const { current } = ref;
    const trigger = () => {
      const hasOverflow = isHorizontal ? current.scrollWidth > current.clientWidth : current.scrollHeight > current.clientHeight;
      setIsOverflow(hasOverflow);
      if (callback)
        callback(hasOverflow);
    };
    if (current) {
      if ("ResizeObserver" in window) {
        new ResizeObserver(trigger).observe(current);
      }
      trigger();
    }
  }, [callback, ref]);
  return isOverflow;
};
