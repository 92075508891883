const inboxUiState = {
  conversationIsVisible: false,
  bookingsDetailsIsVisible: false,
  enableSelectConversations: false,
  conversationsLoading: true,
  searchingConversations: false,
  initialLoad: true,
  userIsTyping: false,
  messageIsSending: false,
  messageFailed: false,
  failedMessageAttempts: 0,
  minimizeSidebar: false
};
const activeConversationState = {
  id: null,
  check_in: null,
  check_out: null,
  check_in_time: null,
  check_out_time: null,
  customer: {},
  channel_id: null,
  message_count: 0,
  updated_at: null,
  property_id: null,
  property_unit_code: null,
  unit_id: null,
  unit_name: null,
  unit_unit_code: null,
  booking_id: null,
  activeThread: [],
  otherThreads: [],
  is_airbnb_pre_approved: false
};
const conversationListState = {
  defaultPageSize: 50,
  page: 0,
  pages: 0,
  total_count: 0,
  unread_message_count: 0,
  current_page: 1,
  total_pages: 0,
  viewer: {},
  selectedMessages: [],
  selectedConversations: [],
  allConversationsSelected: false,
  allConversationsSelectedAction: null,
  conversations: []
};
const inboxNav = {
  inboxes: [
    {
      route: "/inbox",
      title: "All Conversations",
      icon: "inbox",
      inboxLabel: "all"
    },
    {
      route: "/inbox/imp",
      title: "Important",
      icon: "star",
      inboxLabel: "imp"
    },
    {
      route: "/inbox/archive",
      title: "Archived",
      icon: "archive",
      inboxLabel: "archive"
    }
  ],
  admin: [
    {
      route: "/inbox/settings",
      title: "Settings",
      icon: "cog"
    }
  ]
};
const inboxSidebar = {
  activeTab: "booking",
  tabs: [
    {
      id: "booking",
      label: "Booking",
      icon: "conciergeBell"
    },
    {
      id: "user",
      label: "User Profile",
      icon: "userCircle"
    },
    {
      id: "reviews",
      label: "Reviews",
      icon: "stars"
    },
    {
      id: "notes",
      label: "Notes",
      icon: "pencilAlt"
    }
  ],
  booking: null,
  reviews: []
};
const inboxFilters = {
  search: "",
  portfolio_id: null,
  subportfolio_id: null,
  unit_group_id: null,
  status: []
};
const inboxParams = {
  inboxLabel: "",
  conversationId: ""
};
const pendingMessage = {
  body: "",
  attachmentPreviews: []
};
export {
  inboxUiState,
  activeConversationState,
  conversationListState,
  inboxNav,
  inboxSidebar,
  inboxParams,
  inboxFilters,
  pendingMessage
};
