import request from "apiClient";
export default class UsageAccountService {
  static index(organizationId, filter) {
    return request({
      url: `/api/${organizationId}/usage_accounts?filter=${filter}`,
      method: "GET"
    });
  }
  static new(organizationId) {
    return request({
      url: `/api/${organizationId}/usage_accounts/new`,
      method: "GET"
    });
  }
  static create(organizationId, data) {
    return request({
      url: `/api/${organizationId}/usage_accounts`,
      method: "POST",
      data
    });
  }
  static fetch(organizationId, usageAccountId) {
    return request({
      url: `/api/${organizationId}/usage_accounts/${usageAccountId}`,
      method: "GET"
    });
  }
  static delete(organizationId, usageAccountId) {
    return request({
      url: `/api/${organizationId}/usage_accounts/${usageAccountId}`,
      method: "DELETE"
    });
  }
  static update(organizationId, usageAccountId, data) {
    return request({
      url: `/api/${organizationId}/usage_accounts/${usageAccountId}`,
      method: "PUT",
      data
    });
  }
  static possibleUsages(organizationId, usageAccountId, filter, vehicleId) {
    return request({
      url: `/api/${organizationId}/usage_accounts/${usageAccountId}/possible_usages?filter=${filter}`,
      method: "GET"
    });
  }
}
