import React from 'react';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import PaymentsEarningsSummaryLink from 'adminNavbar/payments/links/payments-earnings-summary-link';
import PaymentsStatementsLink from 'adminNavbar/payments/links/payments-statements-link';
import PaymentsInvoicesLink from 'adminNavbar/payments/links/payments-invoices-link';
import PaymentsBankAccountsLink from 'adminNavbar/payments/links/payments-bank-accounts';
import PermissionComponent from 'PermissionComponent';

const LinksDesktop = props => {
  return (
    <>
      <Navbar.LinkMenuColumn>
        <PermissionComponent user={props.user} permission="earnings_summary">
          <PaymentsEarningsSummaryLink {...props} />
        </PermissionComponent>
        <PermissionComponent user={props.user} permission="statements">
          <PaymentsStatementsLink {...props} />
        </PermissionComponent>
        <PermissionComponent user={props.user} permission="invoices">
          <PaymentsInvoicesLink {...props} />
        </PermissionComponent>
        <PermissionComponent user={props.user} permission="bank_accounts">
          <PaymentsBankAccountsLink {...props} />
        </PermissionComponent>
      </Navbar.LinkMenuColumn>
    </>
  );
};

export default LinksDesktop;
