import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { UNIFIED_INBOX_NAV_TAGS } from '../../../../../shared/nav';

const UnifiedInboxNav = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: UNIFIED_INBOX_NAV_TAGS.PRIMARY
      })
    );
  }, []);
  return <></>;
};

export default UnifiedInboxNav;
