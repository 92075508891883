// Dependencies
// -----------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';

// Components
// -----------------------------------------------
import {
  CallToActionButton,
  IconFontAwesome,
  InputButtonGroup,
  Box,
  FlexBox,
  IconButton,
  SlideoutPanel,
  PopoverForm,
  InputSelect,
  Label,
  VisibilityHelper,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import styled from 'styled-components';
import ListReservationsFilter from '../pages/DashboardReservation/molecules/list-reservations-filter';
import { useDetectMobile } from '../../shared/hooks';

const ActionBarSection = styled.section`
  margin: 0 0 16px;
`;

const ActionBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionBarGroup = styled.div`
  display: flex;
`;

const ActionBarSearch = styled.div`
  flex-grow: 1;
  margin: 0 8px 0 0;
  max-width: 400px;
`;

export { ActionBarSection, ActionBarGroup, ActionBarWrapper, ActionBarSearch };

// -----------------------------------------------
// COMPONENT->ACTION-BAR -------------------------
// -----------------------------------------------
export default class ActionBar extends React.Component {
  static propTypes = {
    brands: PropTypes.array.isRequired,
    organization: PropTypes.object.isRequired
  };

  constructor(props, _railsContext) {
    super(props);

    this.state = {
      search: this.props.filters.search,
      openModal: false,
      openSlideOut: false
    };
  }

  updateFilters = data => {
    const newFilter = {};
    newFilter[data.filter] = data.value;

    this.setState(newFilter, () => {
      this.props.applyFilters(newFilter);
    });
  };

  onSearchChange = (e, value) => {
    e.preventDefault();
    this.setState({ search: value });
  };

  manageModal = value => {
    this.setState({ openModal: value });
  };

  renderFiltersAndSort = () => {
    return (
      <>
        <Box>
          <CallToActionButton
            onClick={() => {
              this.setState({ openSlideOut: true });
            }}
            appendIcon={<IconFontAwesome name="slidersH" />}
            size="dense"
            variation="secondary"
            appearance="outline"
          >
            Filters
          </CallToActionButton>
          <SlideoutPanel
            reveal={this.state.openSlideOut}
            title="Reservation Filters"
            closeButtonOnClick={() => {
              this.setState({ openSlideOut: false });
            }}
          >
            <ListReservationsFilter
              filtersModal={this.props.filtersModal}
              filtersOpen={this.props.filtersOpen}
              closeModal={this.props.closeModal}
              filters={this.props.filters}
              user={this.props.user}
              view={this.props.view}
              applyFilters={this.props.applyFilters}
              organization={this.props.organization}
              match={this.props.match}
              setFilters={this.props.setFilters}
            />
          </SlideoutPanel>
        </Box>
        <Box>
          <PopoverForm
            hideActionBar
            triggerLabel="Sort"
            triggerProps={{
              size: 'dense',
              variation: 'secondary',
              appearance: 'outline',
              appendIcon: <IconFontAwesome name="sort" />
            }}
            dropdownAlignment="bottomLeft"
            dropdownWidth="s"
          >
            <Box>
              <Label>Sort By</Label>
              <InputSelect
                inputId="sortBy"
                name="sortBy"
                clearable={false}
                searchable={false}
                options={this.props.sortOptions}
                value={this.props.sortValue}
                onChange={this.props.sortOnChange}
                defaultValue={this.props.sortOptions[0]}
              />
            </Box>
            <CustomSpacer />
          </PopoverForm>
        </Box>
        <VisibilityHelper showStartingFrom="tablet">
          {this.props.view === 'list' && (
            <Box>
              <IconButton
                onClick={this.props.legendModal}
                size="dense"
                variation="secondary"
                appearance="ghost"
              >
                <IconFontAwesome name="eye" />
              </IconButton>
            </Box>
          )}
        </VisibilityHelper>
      </>
    );
  };

  renderInput = () => {
    return (
      <InputButtonGroup
        inputType="text"
        inputProps={{
          name: 'property_name',
          placeholder: 'Search listing, person, or booking ID',
          onChange: event => {
            const value = event.target.value;
            this.onSearchChange(event, value);
            if (value === '') {
              this.props.applyFilters({ search: '' });
            }
          },
          value: this.state.search,
          onKeyDown: event => {
            if (event.key === 'Enter')
              this.props.applyFilters({ search: this.state.search });
          }
        }}
        buttonProps={{
          variation: 'secondary',
          onClick: () => this.props.applyFilters({ search: this.state.search })
        }}
        buttonType="icon-button"
        buttonIcon={<IconFontAwesome name="search" />}
        isDense
        removeBottomSpacer
      />
    );
  };

  // ---------------------------------------------
  // RENDER --------------------------------------
  // ---------------------------------------------
  render() {
    return (
      <>
        <VisibilityHelper hideStartingFrom="tablet">
          <FlexBox gap="xs">
            <Box flex="1" style={{ maxWidth: 350 }}>
              {this.renderInput()}
            </Box>
            {this.renderFiltersAndSort()}
          </FlexBox>
        </VisibilityHelper>
        <VisibilityHelper showStartingFrom="tablet">
          <FlexBox gap="xs">
            <Box flex="1" style={{ maxWidth: 350 }}>
              {this.renderInput()}
            </Box>
            {this.renderFiltersAndSort()}
          </FlexBox>
        </VisibilityHelper>
      </>
    );
  }
}

const CustomSpacer = () => {
  const { isMobile } = useDetectMobile();
  return isMobile ?
    <>
      <Spacer size="xxl" />
      <Spacer size="xxl" />
      <Spacer size="xxl" />
    </>
    :
    <></>
}