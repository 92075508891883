import React from 'react';

import queryString from 'query-string';

import Spinner from '../../../components/spinner';

export default class ResponseStripeOrganization extends React.Component {
  constructor(props, _railsContext) {
    super(props);

    this.state = {
      stripeCode: null,
      stripeError: null,
      stripeState: null
    };
  }

  componentDidMount() {
    this.getBrowserInitialState();
  }

  getBrowserInitialState = () => {
    const queryInfo = this.parseQuery();
    this.setState({
      stripeCode: queryInfo.stripeCode || null,
      stripeError: queryInfo.stripeError || null,
      stripeState: queryInfo.stripeState || null
    });
  };

  parseQuery = () => {
    const parsedQuery = queryString.parse(location.search);
    let queryInfo = {};
    if (parsedQuery['status']) {
      queryInfo['stripeState'] = parsedQuery['status'];
    }
    if (parsedQuery['error']) {
      queryInfo['stripeError'] = parsedQuery['error'];
    }
    return queryInfo;
  };

  redirectToDashboard = () => {
    window.location = `/dashboard/${
      this.props.organization.subdomain
    }/accounting/bank-accounts`;
  };

  render() {
    return (
      <section>
        {this.state.stripeState === null ? <Spinner /> : null}
        {this.state.stripeState === 'error' ? (
          <div>
            <div className="wrapper-s align-center">
              <h3 className="failure">Stripe not connected</h3>
            </div>
            <div className="wrapper-s">
              <Spinner />
            </div>
            <div className="wrapper-s align-center">
              <h3>It looks like the connection to Stripe didn't happen.</h3>
              <p>
                You can always set up Stripe later by going to your{' '}
                <a
                  href={`/dashboard/${
                    this.props.organization.subdomain
                  }/settings`}
                >
                  organization settings
                </a>
                . If this is is in error, please contact our{' '}
                <a href="mailto:help@getdirect.io">support team</a>.
              </p>
              <p>
                You are now being redirected to your organization's{' '}
                <a href={`/dashboard/${this.props.organization.subdomain}`}>
                  dashboard
                </a>
                .
              </p>
              <p>
                If you are not redirected within five seconds, please{' '}
                <a href={`/dashboard/${this.props.organization.subdomain}`}>
                  click this link
                </a>
                .
              </p>
            </div>
            {this.redirectToDashboard()}
          </div>
        ) : null}
        {this.state.stripeState === 'success' ? (
          <div>
            <div className="wrapper-s align-center">
              <h3 className="success">Stripe connection successful</h3>
            </div>
            <div className="wrapper-s">
              <Spinner />
            </div>
            <div className="wrapper-s align-center">
              <h3>You’re now connected to Stripe.</h3>
              <p>Your online payment system is set up and ready to go!</p>
              <p>
                You are now being redirected to your organization's{' '}
                <a href={`/dashboard/${this.props.organization.subdomain}`}>
                  dashboard
                </a>
                .
              </p>
              <p>
                If you are not redirected within five seconds, please{' '}
                <a href={`/dashboard/${this.props.organization.subdomain}`}>
                  click this link
                </a>
                .
              </p>
            </div>
            {this.redirectToDashboard()}
          </div>
        ) : null}
      </section>
    );
  }
}
