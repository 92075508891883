// Dependencies
// -----------------------------------------------
import React from 'react';
import Geosuggest from 'react-geosuggest';
import Script from 'react-load-script';

// Components
// -----------------------------------------------
import { Spinner } from 'adminComponents';
import { Label, Spacer } from '@directsoftware/ui-kit-web-admin';
import { formatLocationAddress, translateObjectKeysFromCamelCaseToSnakeCase} from 'sharedUtils'

export default class DeliveryLocationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.name,
      googlePlaceId: '',
      adrStreet: this.props.adrStreet,
      adrUnit: this.props.adrUnit,
      adrCity: this.props.adrCity,
      adrState: this.props.adrState,
      adrCountry: this.props.adrCountry,
      adrPostalCode: this.props.adrPostalCode,
      adrCountryShort: this.props.adrCountryShort,
      mapsLoaded: false,
      mapsLoading: true,
      setGeoInputFocused: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  handleMapScriptError = () => {
    this.setState({
      mapsLoaded: false,
      mapsLoading: false
    });
  };

  handleMapScriptLoad = () => {
    this.setState({
      mapsLoaded: true,
      mapsLoading: false
    });
  };

  findAddressPart = (adrComponents, adr_part) => {
    return adrComponents.find(e => {
      return e.types[0] === adr_part;
    });
  };

  updateOnSuggestSelected = suggest => {
    try {
      const lat = suggest.location.lat;
      const lng = suggest.location.lng;
      const businessName = suggest.gmaps.name;
      const businessPlaceId = suggest.placeId;
      const adrComponents = suggest.gmaps.address_components;
      const adrPostalCode = this.findAddressPart(adrComponents, 'postal_code');
      const adrCountry = this.findAddressPart(adrComponents, 'country');
      const adrState = this.findAddressPart(
        adrComponents,
        'administrative_area_level_1'
      );
      const adrCity = this.findAddressPart(adrComponents, 'locality');
      const adrStreetName = this.findAddressPart(adrComponents, 'route');
      const adrStreetNumber = this.findAddressPart(
        adrComponents,
        'street_number'
      );

      this.setState({
        name: businessName,
        googlePlaceId: businessPlaceId,
        adrStreet: `${adrStreetNumber ? adrStreetNumber.long_name : ''} ${adrStreetName ? adrStreetName.long_name : ''
          }`,
        adrUnit: '',
        adrCity: adrCity ? adrCity.long_name : '',
        adrState: adrState ? adrState.long_name : '',
        adrCountry: adrCountry ? adrCountry.long_name : '',
        adrPostalCode: adrPostalCode ? adrPostalCode.long_name : '',
        adrCountryShort: adrCountry ? adrCountry.short_name : '',
        geo_latitude: lat,
        geo_longitude: lng
      });

      this.adrGeoSuggest.update(this.state.name || this.state.adrStreet);

      this.props.setLocationAttributes(this.returnValues());
    } catch (e) {
      this.setState({
        adrStreet: ''
      });
    }
  };

  updateOnChange = e => {
    const stateChange = {};
    stateChange[e.target.name] = e.target.value;
    this.setState(stateChange, () => {
      this.props.setLocationAttributes(this.returnValues());
    });
  };

  returnValues = () => {
    const values = {...this.state};
    delete values.mapsLoaded;
    delete values.mapsLoading;

    return values;
  };

  renderGeoSuggestedAddress = () => {
    const { name, adrStreet, mapsLoaded, setGeoInputFocused } = this.state;
    return (
      <div>
        {adrStreet && <div dangerouslySetInnerHTML={{ __html: formatLocationAddress(translateObjectKeysFromCamelCaseToSnakeCase(this.state))}} />}
        <Spacer size="xs" />
        {mapsLoaded && (
          <>
            <Label>{this.props.customLabel || 'Delivery Location'}</Label>
            <Geosuggest
              placeholder="123 Road St."
              types={['geocode', 'establishment']}
              suggestsHiddenClassName="hidden"
              initialValue={name}
              ref={node => {
                this.adrGeoSuggest = node;
              }}
              onSuggestSelect={this.updateOnSuggestSelected}
              className={`newOrganization__textInputWrapper${setGeoInputFocused ? ' focused' : ''
                }`}
              inputClassName="newOrganization__textInput"
              suggestsClassName="newOrganization__suggestsWrapper"
              suggestItemClassName="newOrganization__suggestItem"
              onFocus={() => this.setState({ setGeoInputFocused: true })}
              onBlur={() => this.setState({ setGeoInputFocused: false })}
            />
          </>
        )}
      </div>
    );
  };

  render() {
    return (
      <fieldset>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAPRfOoJzqa0XyJWMg-oU7CeaMpNbxkOaE&libraries=places"
          onError={this.handleMapScriptError.bind(this)}
          onLoad={this.handleMapScriptLoad.bind(this)}
        />
        {this.state.mapsLoading ? (
          <Spinner />
        ) : (
          this.renderGeoSuggestedAddress()
        )}
      </fieldset>
    );
  }
}
