import React from 'react';
import { CustomerSidebarService } from 'adminApi';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { OrgFormattedDate } from 'adminComponents';
import displayError from 'sharedComponents/ErrorDisplay';

const ResLink = styled(NavLink)`
  text-decoration: none;
  border: none;
  &:hover {
    text-decoration: none;
    border: none;
  }
`;

export default class SidebarReservations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reservations: [],
      loading: true
    };
  }

  componentDidMount() {
    this.loadReservations();
  }

  loadReservations = () => {
    CustomerSidebarService.getCustomerReservations(
      this.props.organizationID,
      this.props.customer.id
    )
      .then(response =>
        this.setState({ reservations: response.reservations, loading: false })
      )
      .catch(err =>
        displayError({ message: 'Error fetching customer reservations', err })
      );
  };

  renderReservations = () => {
    if (this.state.reservations.length === 0) {
      return <div>No Reservations Found</div>;
    }
    return this.state.reservations.map(reservation =>
      this.reservationCard(reservation)
    );
  };

  backgroundImageUrl = url => {
    let imageUrl = 'url(/assets/fallback/property_default.png)';

    if (url) {
      imageUrl = `url(${url})`;
    }

    return imageUrl;
  };

  reservationCard = reservation => {
    return (
      <ResLink to={`/reservations/${reservation.booking_code}`} target="_blank">
        <div
          key={reservation.id}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            backgroundColor: 'white',
            boxShadow: '2px 2px 5px -2px rgba(194,194,194,1)',
            padding: '25px',
            marginBottom: '15px'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div>
              <div
                className="prop-image-thumbnail"
                style={{
                  borderRadius: '25px',
                  backgroundImage: this.backgroundImageUrl(
                    reservation.image_url
                  )
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0 20px'
              }}
            >
              <div style={{ fontWeight: '500' }}>
                <NavLink to={reservation.rental_link} target="_blank" rel="noopener noreferrer">
                  {reservation.rental_name.slice(0, 30)}...
                </NavLink>
              </div>
              <div style={{ fontSize: '12px', color: '#606B6F' }}>
                {<OrgFormattedDate date={reservation.check_in} />}-
                {<OrgFormattedDate date={reservation.check_out} />}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <img
              src={`/assets/admin/${reservation.channel_logo}`}
              alt="res-logo"
              width="auto"
              style={{ height: 20 }}
              height="20px"
            />
            <div
              className="icon-default"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 0 0 25px'
              }}
            >
              <img
                style={{ height: '15px', width: 'auto' }}
                alt="right-arrow"
                src="/assets/brand/arrow-right.png"
              />
            </div>
          </div>
        </div>
      </ResLink>
    );
  };

  render() {
    return this.state.loading ? null : this.renderReservations();
  }
}
