import React from 'react';
import { MentionsInput, Mention } from 'react-mentions';

export default class MentionsTextbox extends React.Component {
  constructor() {
    super();
    this.state = {
      textareaValue: '',
      mentionData: null,
      users: []
    };
  }

  handleChange = (event, newValue, newPlainTextValue, mentions) => {
    this.setState(
      {
        textareaValue: newValue,
        mentionData: { newValue, newPlainTextValue, mentions }
      },
      () => this.props.addMentionee(mentions, newValue)
    );
  };

  render() {
    const userMentionData = this.props.users.map(myUser => ({
      id: myUser.id,
      display: `${myUser.display}`
    }));

    return (
      <>
        <div>
          <MentionsInput
            value={this.state.textareaValue}
            onChange={this.handleChange}
            markup="@{{__type__||__id__||__display__}}"
            placeholder="use the @ symbol to tag other users."
            className="mentions"
            multiLine
          >
            <Mention
              type="user"
              trigger="@"
              data={userMentionData}
              className="mentions__mention"
            />
          </MentionsInput>
        </div>
      </>
    );
  }
}
