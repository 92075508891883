import React from 'react';
import {
  CallToActionLink,
  IconFontAwesome,
  Spacer
} from '@directsoftware/ui-kit-web-admin';

const WorkOrderTableHeader = () => {
  return (
    <>
      <Spacer />
      <section className="consolidated-item-header-section">
        <h3>Work Orders</h3>
        <span>
          <CallToActionLink
            href="/workflows/work-orders?modalOpen=true"
            appendIcon={<IconFontAwesome name="plus" />}
          >
            Add Work Order
          </CallToActionLink>
        </span>
      </section>
    </>
  );
}
export default WorkOrderTableHeader;
