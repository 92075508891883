import request from "apiClient";
export default class PointCentralMappingService {
  static list(organizationID, mappableType, queryParams) {
    return request({
      url: `/api/${organizationID}/point_central/mappings${queryParams}`,
      method: "GET"
    });
  }
  static fetch(organizationID, mappableType, mappableID) {
    return request({
      url: `/api/${organizationID}/point_central/mappings/${mappableID}?mappable_type=${mappableType}`,
      method: "GET"
    });
  }
  static update(organizationID, mappingID, data) {
    return request({
      url: `/api/${organizationID}/point_central/mappings/${mappingID}`,
      method: "PATCH",
      data
    });
  }
}
