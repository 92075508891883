import styled from 'styled-components';

const CustomerInfoContainer = styled.div`
  background-color: #fff;
  display: flex;
  padding: 32px 32px 0;
  min-height: 204px;
`;

const AvatarContainer = styled.div`
  width: 100px;
  display: flex;
  justify-content: center;
`;

export { CustomerInfoContainer, AvatarContainer };
