// Dependencies
// -----------------------------------------------
import React, { useState } from 'react';
import { PropertyService } from 'adminApi';
import PortalModal from 'sharedComponents/PortalModal';
import Spinner from 'adminComponents/spinner';
import slice from 'lodash/slice';

// Components
// -----------------------------------------------
import {
  CallToActionButton,
  ConfirmDialog,
  Dropdown,
  IconFontAwesome,
  StandByOverlay,
  VisibilityHelper
} from '@directsoftware/ui-kit-web-admin';
import ManagePropertyNewNote from './manage-property-new-note';

export const actionStyles = {
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'baseline',
  padding: '20px 0'
};

export const modalStyles = {
  height: '35%'
};

const cancelStyles = {
  textTransform: 'uppercase',
  color: '#c0c4cc',
  paddingRight: '20px'
};

// -----------------------------------------------
// COMPONENT->MANAGE-SINGLE-COMMAND-PALETTE ------
// -----------------------------------------------
const ManageSingleCommandPalette = ({
  organization,
  property,
  user,
  history
}) => {
  const [optionsToggled, setOptionsToggled] = useState(false);
  const [cloneName, setCloneName] = useState(`CLONE: [${property.name}]`);
  const [isCloning, setIsCloning] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showDeleteOverlay, setShowDeleteOverlay] = useState(false);

  const toggleOptions = e => {
    e.preventDefault();
    setOptionsToggled(!optionsToggled);
  };

  const cloneProperty = async (propertyId, callback) => {
    setIsCloning(true);
    PropertyService.cloneProperty(organization.id, propertyId, {
      name: cloneName
    })
      .then(res => {
        history.push(`/properties/${res.id}`);
        history.go(0);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setIsCloning(false);
        callback();
      });
  };

  const deleteProperty = () => {
    PropertyService.deleteProperty(organization.id, property.id)
      .then(() => history.goBack())
      .catch(err => console.error(err));
  };

  const handleCloneCancel = (callback = () => null) => {
    setCloneName(`CLONE: [${property.name}]`);
    setShowCloneModal(false);
    callback();
  };

  const returnPanelContent = () => {
    const { permissions } = user;
    const viewerCanAccessReservations =
      permissions.view_reservations && !permissions.properties_read_only;
    const panelContent = [
      {
        label: 'Manage Images',
        href: `/properties/${property.id}/images`,
        type: 'router-link',
        removeBorderRadius: true,
        addPadding: true
      },
      {
        label: 'Add a Unit',
        href: `/properties/${property.id}/units/new`,
        type: 'router-link',
        removeBorderRadius: true,
        addPadding: true
      },
      {
        label: 'Add a Work Order',
        href: `/workflows/work-orders?modalOpen=true`,
        type: 'router-link',
        removeBorderRadius: true,
        addPadding: true
      },
      {
        label: 'Add a Note',
        type: 'button',
        onClick: () => setShowNoteModal(true),
        removeBorderRadius: true,
        addPadding: true
      },
      {
        label: 'Clone This Property',
        type: 'button',
        onClick: () => setShowCloneModal(true),
        removeBorderRadius: true,
        addPadding: true
      },
      {
        label: 'View Reservations',
        type: 'link',
        href: `/dashboard/${organization.subdomain}/reservations?property_id=${
          property.id
        }`,
        addPadding: true,
        removeBorderRadius: true,
        openNewTab: true
      }
    ];

    if (property.direct_ical_url) {
      panelContent.push({
        label: 'Download iCal',
        type: 'link',
        href: property.direct_ical_url,
        addPadding: true,
        openNewTab: true
      })
    }

    if (user.admin) {
      panelContent.push({
        addDivider: true
      });
      panelContent.push({
        label: 'Delete Listing',
        type: 'button',
        onClick: () => {
          setShowDeleteDialog(true);
        },
        isDanger: true,
        icon: <IconFontAwesome name="trashAlt" />,
        iconPresetColor: 'red',
        removeBorderRadius: true,
        addPadding: true
      });
    }

    return viewerCanAccessReservations
      ? panelContent
      : slice(panelContent, 0, -1);
  };

  // ---------------------------------------------
  // RENDER --------------------------------------
  // ---------------------------------------------
  return (
    <>
      <StandByOverlay
        reveal={showDeleteOverlay}
        position="fixed"
        headline="Deleting this Listing..."
        description={`You'll be redirected when it's done.`}
      />
      <VisibilityHelper showStartingFrom="tablet">
        <Dropdown
          controlLabel="Actions"
          panelWidthVariation="auto"
          controlType="call-to-action-button"
          alignPanel="bottomRight"
          panelContent={returnPanelContent()}
        />
      </VisibilityHelper>
      <VisibilityHelper hideStartingFrom="tablet">
        <Dropdown
          controlLabel={<IconFontAwesome name="ellipsisV" />}
          controlProps={{
            variation: 'secondary',
            appearance: 'ghost'
          }}
          panelWidthVariation="auto"
          controlType="icon-button"
          alignPanel="bottomRight"
          panelContent={returnPanelContent()}
        />
      </VisibilityHelper>
      {showNoteModal && (
        <ManagePropertyNewNote
          property={property}
          organization={organization}
          fetchNotes={() => {
            window.location.reload();
          }}
          hideOpenButton
          defaultOpen
        />
      )}
      {showCloneModal && (
        <PortalModal
          defaultOpen
          openByClickOn={() => <></>}
          disableCloseOnOutsideClick
          submitAction={closePortal => {
            return isCloning ? (
              <Spinner />
            ) : (
              <CallToActionButton
                onClick={() => cloneProperty(property.id, closePortal)}
              >
                Clone
              </CallToActionButton>
            );
          }}
          cancelAction={closePortal => (
            <CallToActionButton
              variation="secondary"
              appearance="ghost"
              onClick={() => handleCloneCancel(closePortal)}
            >
              Cancel
            </CallToActionButton>
          )}
          closeOnSubmit
          actionStyles={actionStyles}
          modalStyles={modalStyles}
          header="Clone Property"
          onClose={handleCloneCancel}
        >
          <div>
            <input
              type="text"
              value={cloneName}
              onChange={e => setCloneName(e.target.value)}
            />
          </div>
        </PortalModal>
      )}
      <ConfirmDialog
        description={`Are you sure you want to delete ${
          property?.name
        }? This will delete all associated listings on any brand.`}
        confirmOnClick={() => {
          deleteProperty();
          setShowDeleteDialog(false);
          setShowDeleteOverlay(true);
        }}
        cancelOnClick={() => setShowDeleteDialog(false)}
        confirmLabel="Yes, Delete"
        title="Delete this Listing?"
        reveal={showDeleteDialog}
        isDanger
      />
    </>
  );
};

export default ManageSingleCommandPalette;
