import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import queryString from 'query-string';
import { BalanceService } from 'adminApi';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { ACCOUNTING_NAV_TAGS } from '../../../../../shared/nav';

const BalanceDetails = props => {
  const dispatch = useDispatch();
  const [accountName, setAccountName] = useState('');

  useEffect(
    () => {
      const parsedQuery = queryString.parse(props.location.search);
      const payeeType = parsedQuery.payee_type;

      BalanceService.get(props.organization.id, props.payeeId, payeeType).then(
        response => {
          setAccountName(response.balance.payee.name);
        }
      );
    },
    [props]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: ACCOUNTING_NAV_TAGS.PRIMARY,
        secondary: ACCOUNTING_NAV_TAGS.secondary.PAYOUTS_BALANCES
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/accounting',
            label: 'Payouts & Balances'
          },
          {
            label: 'Details'
          }
        ]}
      >
        Balance for: <span>{accountName}</span>
      </PageHeader.Left>
    </PageHeader>
  );
};

export default BalanceDetails;
