import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  startDeleteInvoice: false,
  startSaveInvoice: false
};
const createInvoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    updateInvoices: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetInvoices: (state) => {
      return { ...state, ...initialState };
    }
  }
});
export const { updateInvoices, resetInvoices } = createInvoicesSlice.actions;
export default createInvoicesSlice.reducer;
export const selectInvoicesState = createSelector((state) => state.invoices, (invoices) => invoices);
