import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { toast } from 'react-toastify';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { ACCOUNTING_NAV_TAGS } from '../../../../../shared/nav';
import CreateBillModal from '../../../../bills/components/CreateBillModal';
import {
  selectAccountingInvoicesState,
  updateAccountingInvoices
} from '../../../../redux/slices/accounting';
import isNull from 'lodash/isNull';

const InvoicesHome = props => {
  const dispatch = useDispatch();
  const { refreshInvoices } = useSelector(selectAccountingInvoicesState);

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: ACCOUNTING_NAV_TAGS.PRIMARY,
        secondary: ACCOUNTING_NAV_TAGS.secondary.INVOICES
      })
    );
  }, []);

  return (
    <PageHeader>
      <PageHeader.Left>
        Accounting: <span>Invoices</span>
      </PageHeader.Left>
      {props.user.role >= 2 && (
        <PageHeader.Right>
          <CreateBillModal
            organization={props.organization}
            isRefreshing={refreshInvoices}
            billCreateError={error => {
              if (isNull(error)) {
                toast.error(
                  "We're sorry, your bill could not be created right now"
                );
              } else {
                toast.error(`invoice # ${error.data.errors.invoice_num}`);
              }
            }}
            loadBillsAfterCreate={() => {
              dispatch(updateAccountingInvoices({ refreshInvoices: true }));
            }}
            currency={props.organization.currency}
          />
        </PageHeader.Right>
      )}
    </PageHeader>
  );
};

export default InvoicesHome;
