import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@directsoftware/ui-kit-web-admin';

/**
 *  Turning off highlights for now until offshore team completes match place work
 */
const TextHighlighterComponent = ({ text, textToHighlight }) => {
  return (
    /* <Box
      renderAs="span"
      display="inline"
      dangerouslySetInnerHTML={textHighlighter(text, textToHighlight)}
    /> */
    <Box renderAs="span" display="inline">
      {text}
    </Box>
  );
};

TextHighlighterComponent.propTypes = {
  text: PropTypes.string,
  textToHighlight: PropTypes.string
};

export default TextHighlighterComponent;
