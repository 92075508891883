import React from 'react';
import { Switch, Route } from 'react-router-dom';
import TemplatesHome from './templates-home';
import TemplatesDetails from './templates-details';
import SchedulingHome from './scheduling-home';
import WorkOrdersHome from './work-orders-home';
import WorkOrderDetails from './work-order-details';
import WorkOrderNew from './work-order-new';
import WorkReportsHome from './work-reports-home';
import WorkReportsDetails from './work-reports-details';

const WorkflowsRoutes = props => {
  return (
    <Switch>
      <Route
        path="/workflows/work-reports/:workReportId"
        render={routeProps => (
          <WorkReportsDetails
            {...props}
            workReportId={routeProps.match.params.workReportId}
          />
        )}
      />
      <Route
        path="/workflows/work-reports"
        render={() => <WorkReportsHome {...props} />}
      />
      <Route
        path="/workflows/work-orders/new"
        render={() => <WorkOrderNew />}
      />
      <Route
        path="/workflows/work-orders/:workOrderId"
        render={routeProps => (
          <WorkOrderDetails
            {...props}
            workOrderId={routeProps.match.params.workOrderId}
          />
        )}
      />
      <Route
        path="/workflows/work-orders"
        render={() => <WorkOrdersHome {...props} />}
      />
      <Route
        path="/workflows/scheduling"
        render={() => <SchedulingHome {...props} />}
      />
      <Route
        path="/workflows/:workflowId"
        render={routeProps => (
          <TemplatesDetails
            {...props}
            templateId={routeProps.match.params.workflowId}
          />
        )}
      />
      <Route path="/workflows" render={() => <TemplatesHome {...props} />} />
    </Switch>
  );
};

export default WorkflowsRoutes;
