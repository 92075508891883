// This is used for connect account verification, so this is where users will submit their SSN and id verification
// they still have to submit their bank account info through the accounts page
import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { StripeService } from 'adminApi';
import isNull from 'lodash/isNull';

const StripeInformation = styled.div`
  margin-bottom: 35px;
  border: 2px solid #e6ebf1;
  border-radius: 5px;
  padding: 20px 135px 20px 25px;
  background: url(/assets/admin/Stripe-blurple_sm.png) right 25px center
    no-repeat;
  background-size: 80px auto;
`;

const RedirectToStripe = (organizationId, employeeId, url) => {
  StripeService.redirectToStripeOnboarding(organizationId, employeeId, {
    original_url: url
  })
    .then(response => window.open(response.url))
    .catch(error =>
      toast.error(`Error redirecting to Stripe ${error.data.error}`)
    );
};

const ConnectOnboarding = props => {
  return (
    <div>
      <section style={{ display: 'flex', justifyContent: 'center' }}>
        <StripeInformation>
          <p>
            We use Stripe to make sure your details are secure. Click{' '}
            {isNull(props.employee.stripe_account_id) ? (
              <strong>Connect</strong>
            ) : (
              <strong>Update</strong>
            )}{' '}
            to set up your payments on Stripe.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <a
              onClick={() =>
                RedirectToStripe(
                  props.organization.id,
                  props.employeeId,
                  props.url
                )
              }
            >
              {isNull(props.employee.stripe_account_id) ? (
                <h2>Connect</h2>
              ) : (
                <h2>Update</h2>
              )}
            </a>
          </div>
        </StripeInformation>
      </section>
    </div>
  );
};

export default ConnectOnboarding;
