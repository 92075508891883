// Dependencies
// -----------------------------------------------
import React from 'react';
import ReactPaginate from 'react-paginate';

// -----------------------------------------------
// COMPONENT->PAGINATION -------------------------
// -----------------------------------------------
const Pagination = props => {
  // ---------------------------------------------
  // RETURN --------------------------------------
  // ---------------------------------------------
  return (
    <div className="consolidated-key">
      <div
        style={{ float: 'right', paddingBottom: '10px' }}
        className="no-wrap"
      >
        <ReactPaginate
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={<a href="">...</a>}
          breakClassName={'break-me'}
          pageCount={props.pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          onPageChange={props.handlePageClick}
          forcePage={props.currentPage}
          containerClassName={''}
          subContainerClassName={'pages consolidated-key'}
          activeClassName={'active'}
          style={{ float: 'right' }}
        />
      </div>
    </div>
  );
};

// ---------------------------------------------
// EXPORT --------------------------------------
// ---------------------------------------------
export default Pagination;
