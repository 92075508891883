import React, { useState, useEffect } from 'react';
import { CustomerSidebarService } from 'adminApi';
import displayError from 'sharedComponents/ErrorDisplay';
import { ReviewCardWrapper } from './styles';
import { ReviewCard } from './review-card';
export const Reviews = ({ organizationID, customer }) => {
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    CustomerSidebarService.getCustomerReviews(organizationID, customer.id)
      .then(res => setReviews(res.reviews))
      .catch(_err =>
        displayError({ message: 'Could not fetch reviews', autoClose: true })
      );
  }, []);
  return (
    <>
      <h4 style={{ fontWeight: 'bold' }}>Reviews</h4>
      <ul>
        {reviews.map(review => (
          <ReviewCardWrapper key={review.id}>
            <ReviewCard review={review} />
          </ReviewCardWrapper>
        ))}
      </ul>
    </>
  );
};
