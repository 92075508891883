import React from 'react';
import { CustomerSidebarService, MessageService } from 'adminApi';
import UserAvatar from 'adminComponents/Avatars/UserAvatar';
import { InputTextarea } from '@directsoftware/ui-kit-web-admin';
import { CustomerContext } from 'adminContexts';
import Loadable from 'sharedComponents/Loadable';
import { OrgFormattedDate } from 'adminComponents';
import displayError from 'sharedComponents/ErrorDisplay';
import styled from 'styled-components';
import { Card } from '../SectionsStyles';
import Thread from './Thread';

const Form = styled.form`
  margin-bottom: 50px;
`;

const FormButtonArea = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;

export default class SidebarMessages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      conversations: [],
      newMessage: '',
      loading: true,
      sending: false
    };
  }

  componentDidMount() {
    this.loadConversations();
  }

  loadConversations = () => {
    CustomerSidebarService.getCustomerConversations(
      this.props.organizationID,
      this.props.customer.id
    )
      .then(response =>
        this.setState({ conversations: response.conversations, loading: false })
      )
      .catch(err =>
        displayError({ message: 'Error getting conversations', err })
      );
  };

  getInitials = name => {
    let initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || '') + (initials.pop() || '')
    ).toUpperCase();
    return initials;
  };

  onChange = e => {
    this.setState({ newMessage: e.target.value });
  };

  sendMessage = e => {
    e.preventDefault();
    this.setState({ sending: true });
    const senderType = `${this.props.user.type
      .charAt(0)
      .toUpperCase()}${this.props.user.type.slice(1)}`;
    const data = {
      body: this.state.newMessage,
      conversation_id: this.context.activeConversation.id,
      sender_type: senderType,
      sender_id: this.props.user.id,
      sender_name: this.props.user.name
    };

    MessageService.create(this.props.organizationID, data)
      .then(() =>
        this.setState(
          { newMessage: '', sending: false },
          this.context.setActiveConversation(this.context.activeConversation)
        )
      )
      .catch(err => displayError({ message: 'Error creating message', err }));
  };

  renderConversations = () => {
    return this.state.conversations && this.state.conversations.length ? (
      this.state.conversations.map(conversation => {
        return this.conversationCard(conversation);
      })
    ) : (
      <div>No Conversations Found</div>
    );
  };

  sendPreApproval = async message => {
    const { organizationID, customer } = this.props;
    const { conversation_id: conversationId, id: messageId } = message;

    await CustomerSidebarService.sendPreApproval(
      organizationID,
      customer.id,
      conversationId,
      messageId
    );
  };

  renderThread = () => {
    return (
      this.context.activeConversation && (
        <>
          <div
            onClick={() => this.setActiveConversation()}
            style={{
              marginBottom: '20px',
              fontSize: '12px',
              cursor: 'pointer'
            }}
          >
            <img
              style={{ height: '10px', width: 'auto', paddingRight: '8px' }}
              src="/assets/brand/arrow-left.svg"
              alt="arrow-left"
            />
            ALL MESSAGES
          </div>

          {this.context.activeConversation.messages.length > 0 ? (
            <Thread
              messages={this.context.activeConversation.messages}
              showAirbnbPreApproval={
                this.context.activeConversation.show_airbnb_pre_approval
              }
              handlePreApproveClicked={this.sendPreApproval}
            />
          ) : (
            <div>No messages yet. Write one below!</div>
          )}
          <Form style={{ marginBottom: '50px' }}>
            <InputTextarea
              onChange={this.onChange}
              value={this.state.newMessage}
              name="message"
              placeholder="Write a message..."
              rows={8}
            />
            <FormButtonArea>
              <Loadable
                loading={this.state.sending}
                height="50px"
                justify="flex-end"
              >
                <a onClick={e => this.sendMessage(e)} className="button">
                  Send
                </a>
              </Loadable>
            </FormButtonArea>
          </Form>
        </>
      )
    );
  };

  setActiveConversation = conversation => {
    if (!conversation) {
      this.context.setActiveConversation();
    }
    return this.context.setActiveConversation(conversation);
  };

  renderContent = () => {
    return this.context.activeConversation
      ? this.renderThread()
      : this.renderConversations();
  };

  conversationCard = conversation => {
    return (
      <Card
        onClick={() => this.setActiveConversation(conversation)}
        key={conversation.id}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <div>
            <UserAvatar
              size="small"
              user={{ name: conversation.last_sender }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0 20px'
            }}
          >
            <div style={{ fontWeight: '500' }}>{conversation.subject}</div>
            <div style={{ fontSize: '14px', color: '#606B6F' }}>
              <OrgFormattedDate date={conversation.last_message} />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img
            src={`/assets/admin/${conversation.channel_logo}`}
            alt="convo-logo"
            style={{ height: 20 }}
            width="auto"
            height="20px"
          />
          <div
            className="icon-default"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 0 0 25px'
            }}
          >
            <img
              style={{ height: '15px', width: 'auto' }}
              alt="arrow-right"
              src="/assets/brand/arrow-right.png"
            />
          </div>
        </div>
      </Card>
    );
  };

  render() {
    return this.state.loading ? null : this.renderContent();
  }
}

SidebarMessages.contextType = CustomerContext;
