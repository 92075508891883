// Dependencies
// -----------------------------------------------
import React from 'react';
import find from 'lodash/find';
import capitalize from 'lodash/capitalize';
import upperCase from 'lodash/upperCase';
import trim from 'lodash/trim';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';
import { toast } from 'react-toastify';

// Components
// -----------------------------------------------
import {
  CurrencySelect,
  TextInput,
  ConsolidatedItemEditToggleHeader,
  CountrySelect,
  InlineTip
} from 'adminComponents';
import displayError from 'sharedComponents/ErrorDisplay';

// Services
// -----------------------------------------------
import { StripeBankAccountService } from 'adminApi';

// Constants
// -----------------------------------------------
const accountHolderOptions = [
  { label: 'Individual', value: 'individual' },
  { label: 'Company', value: 'company' }
];

const ip = require('ip');

export default class StripeAccountFieldsBank extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ip_address: ip.address(),
      currency: null,
      country: null,
      account_holder_name: '',
      display_name: '',
      routing_number: '',
      account_number: '',
      iban_number: '',
      account_number_last_4: '',
      account_holder_type: null,
      currency_cache: null,
      country_cache: null,
      account_holder_name_cache: '',
      display_name_cache: '',
      routing_number_cache: '',
      account_number_cache: '',
      iban_number_cache: '',
      account_number_last_4_cache: '',
      account_holder_type_cache: null,
      isDirty: false,
      isEditing: this.props.isEditing || false,
      isSaving: false,
      isSaved: false,
      account_holder_type_option: null,
      holder_type: 'Employee',
      holder_id: this.props.employeeId
    };
  }

  componentDidMount() {
    if (this.props.stripe_account) {
      this.updateAllState(this.props.stripe_account);
    }
    if (this.props.direct_account) {
      this.setState({
        display_name: this.props.direct_account.display_name || ''
      });
    }
  }

  updateState = (name, value) => {
    const stateChange = {};
    if (name === 'accounting_number' || name === 'routing_number') {
      stateChange[name] = trim(value);
      stateChange.isDirty = true;
      this.setState(stateChange);
    } else {
      stateChange[name] = value;
      stateChange.isDirty = true;
      this.setState(stateChange);
    }
  };

  updateAllState = stripe_account => {
    if (!stripe_account) return null;

    if (stripe_account.error != null) {
      console.log(stripe_account.error);
      toast.error(
        stripe_account.error.data && stripe_account.error.data.error
          ? stripe_account.error.data.error
          : stripe_account.error
      );
      return;
    }

    if (
      !stripe_account.external_accounts ||
      !stripe_account.external_accounts.data ||
      stripe_account.external_accounts.data.length == 0
    )
      return null;

    const external_account = stripe_account.external_accounts.data[0];

    if (!external_account) return null;

    this.setState({
      currency: external_account.currency,
      country: external_account.country,
      routing_number: external_account.routing_number,
      iban_number: external_account.iban_number,
      account_number_last_4: external_account.last4,
      account_holder_type: external_account.account_holder_type,
      account_holder_name: external_account.account_holder_name,
      currency_cache: external_account.currency,
      country_cache: external_account.country,
      routing_number_cache: external_account.routing_number,
      iban_number_cache: external_account.iban_number,
      account_number_last_4_cache: external_account.last4,
      account_holder_type_cache: external_account.account_holder_type,
      account_holder_name_cache: external_account.account_holder_name,
      account_holder_type_option: find(accountHolderOptions, option => {
        return option.value === external_account.account_holder_type;
      })
    });
  };

  toggleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  finishEditingAndRevert = () => {
    const stateChange = {};
    stateChange.currency = this.state.currency_cache;
    stateChange.country = this.state.country_cache;
    stateChange.routing_number = this.state.routing_number_cache;
    stateChange.account_number_last_4 = this.state.account_number_last_4_cache;
    stateChange.iban_number = this.state.iban_number_cache;
    stateChange.account_holder_type = this.state.account_holder_type_cache;
    stateChange.account_holder_name = this.state.account_holder_name_cache;
    stateChange.isDirty = false;
    stateChange.isEditing = false;
    stateChange.isSaving = false;
    stateChange.isSaved = false;

    this.setState(stateChange);
  };

  finishEditingAndUpdate = stripe_account => {
    let stateChange = {};
    stateChange['isDirty'] = false;
    stateChange['isEditing'] = false;
    stateChange['isSaving'] = false;
    stateChange['isSaved'] = true;

    this.setState(stateChange, () =>
      this.updateAllState(stripe_account.stripe_data)
    );
  };

  attemptSave = () =>
    this.setState({ isSaving: true, isSaved: false }, () => {
      this.createBankAccount();
    });

  createBankAccount = () => {
    StripeBankAccountService.addBankAccount(
      this.props.organization.id,
      this.state
    )
      .then(response => {
        toast.info('Bank account successfully updated.');
        this.props.updateAccountCreated(response);
        this.finishEditingAndUpdate(response);
      })
      .catch(err => {
        const message = `Error adding bank account: ${
          err.data && err.data.error ? err.data.error : ''
        }`;
        console.log(message, err);
        this.setState({
          isDirty: false,
          isEditing: true,
          isSaving: false,
          isSaved: false
        });
        displayError({ message });
      });
  };

  renderValue = value => {
    return value ? value : 'Not Set';
  };

  onSelectChange = (name, option) => {
    this.setState({
      [name]: option.value,
      [name + '_option']: option,
      isDirty: true
    });
  };

  renderViewing = () => (
    <section
      className="consolidated-item-form-viewing"
      style={{ maxWidth: '100%' }}
    >
      <div className="consolidated-item-subsection">
        <section className="two-col two-col-50-50">
          <div>
            <label>Display Name</label>
            <p>{this.renderValue(this.state.display_name)}</p>
          </div>
        </section>
        <section className="two-col two-col-50-50">
          <div>
            <label>Account Holder Name</label>
            <p>{this.renderValue(this.state.account_holder_name)}</p>
          </div>
          <div>
            <label>Account Holder Type</label>
            <p>
              {capitalize(this.renderValue(this.state.account_holder_type))}
            </p>
          </div>
        </section>
      </div>
      <div className="consolidated-item-subsection">
        <section className="two-col two-col-50-50">
          <div>
            <label>Currency</label>
            <p>
              {this.state.currency ? upperCase(this.state.currency) : 'Not Set'}
            </p>
          </div>
          <div>
            <label>Country</label>
            <p>{this.renderValue(this.state.country)}</p>
          </div>
        </section>
      </div>
      <div className="consolidated-item-subsection">
        <section className="two-col two-col-50-50">
          <div>
            <label>
              Routing number or equivalent bank route for non-US payees
            </label>
            <p>{this.renderValue(this.state.routing_number)}</p>
          </div>
          <div>
            <label>Account Number</label>
            <p>
              {this.state.account_number_last_4
                ? `XXXXXX${this.state.account_number_last_4}`
                : 'Not Set'}
            </p>
          </div>
        </section>
      </div>
      <div className="consolidated-item-subsection">
        <div>
          <label>Iban Number</label>
          <p>
            {this.state.iban_number
              ? this.state.iban_number.substring(0, 4).padEnd(22, 'X')
              : 'Not Set'}
          </p>
        </div>
      </div>
    </section>
  );

  renderEditing = () => (
    <form
      className="consolidated-item-form-editing"
      style={{ maxWidth: '100%' }}
    >
      <div className="consolidated-item-subsection">
        <section className="two-col two-col-50-50">
          <TextInput
            label="Account Holder Name"
            name="account_holder_name"
            onChange={this.updateState}
            value={this.state.account_holder_name}
            placeholder="Account Holder Name"
          />
          <TextInput
            label="Display Name"
            name="display_name"
            onChange={this.updateState}
            value={this.state.display_name}
            placeholder="Display Name"
          />
        </section>
      </div>
      <div className="consolidated-item-subsection">
        <section className="three-col three-col-33-33-33">
          <div>
            <label>Account Holder Type</label>
            <InputSelect
              name="account_holder_type"
              className="magnified"
              options={accountHolderOptions}
              isClearable={false}
              isSearchable={false}
              placeholder="Select Account Type"
              value={find(accountHolderOptions, {
                value: this.state.account_holder_type_option
              })}
              onChange={option =>
                this.onSelectChange('account_holder_type', option)
              }
            />
          </div>
          <CurrencySelect
            currency={this.state.currency}
            setCurrency={option => this.onSelectChange('currency', option)}
            onChange={option => this.onSelectChange('currency', option)}
          />
          <CountrySelect
            country={this.state.country}
            setCountry={value => this.onSelectChange('country', value)}
          />
        </section>
      </div>
      <div className="consolidated-item-subsection">
        <section className="two-col two-col-50-50">
          <TextInput
            label={
              <span>
                Routing number or equivalent bank route for non-US payees
                <InlineTip
                  tip={<p>This field may be optional for non-US payees</p>}
                />
              </span>
            }
            name="routing_number"
            onChange={this.updateState}
            value={this.state.routing_number}
            placeholder="Routing number or equivalent bank route for non-US payees"
          />
          <TextInput
            label={
              <span>
                Account Number
                <InlineTip
                  tip={<p>This field may be optional for non-US payees</p>}
                />
              </span>
            }
            name="account_number"
            onChange={this.updateState}
            value={this.state.account_number}
            placeholder="Account Number"
          />
        </section>
      </div>
      <div className="consolidated-item-subsection">
        <TextInput
          label={
            <span>
              IBAN Number
              <InlineTip
                tip={<p>This field may be optional for non-US payees</p>}
              />
            </span>
          }
          name="iban_number"
          onChange={this.updateState}
          value={this.state.iban_number}
          placeholder="IBAN Number"
        />
      </div>
    </form>
  );

  render() {
    if (!this.props.account_created) {
      return (
        <figure className="consolidated-item-form-section -disabled">
          <header className="consolidated-item-header">
            <h3>Bank Settings</h3>
          </header>
          {this.renderViewing()}
        </figure>
      );
    }

    return (
      <figure
        className={`consolidated-item-form-section ${
          this.state.isEditing ? 'form-editing' : ''
        }`}
        style={{ width: '100%' }}
      >
        <ConsolidatedItemEditToggleHeader
          toggleEditing={this.toggleEditing}
          finishEditingAndRevert={this.finishEditingAndRevert}
          finishEditingAndUpdate={this.finishEditingAndUpdate}
          attemptSave={this.attemptSave}
          isDirty={this.state.isDirty}
          isEditing={this.state.isEditing}
          isSaving={this.state.isSaving}
          title="Bank Settings"
        />
        {this.state.isEditing ? this.renderEditing() : this.renderViewing()}
      </figure>
    );
  }
}
