import capitalize from "lodash/capitalize";
export const removeUnderscoresForTitle = (textWithUnderscores, rest) => `${textWithUnderscores.replace(/_/g, " ")} ${rest}`;
export const toSnakeCase = (textWithSpaces) => textWithSpaces.toLowerCase().replace(/\s+/g, "_");
export const fromSnakeCase = (snakeCaseText) => snakeCaseText.replace(/_/g, " ");
export const titleize = (text) => {
  return text ? fromSnakeCase(text).split(" ").map((str) => capitalize(str)).join(" ") : text;
};
export const commaSeparatedBalance = (amount) => {
  const decimalString = (amount / 100).toFixed(2);
  const commaString = decimalString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return commaString;
};
export const getViewValue = (value, unit) => `${value || 0} ${unit}`;
export const booleanViewValue = (value) => value ? "\u2714" : "\u274C";
export const humanReadablePropertyName = (str) => {
  return str.replace(/^[\s_]+|[\s_]+$/g, "").replace(/[_\s]+/g, " ").replace(/^[a-z]/, function(m) {
    return m.toUpperCase();
  });
};
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const textHighlighter = (term, filter) => {
  return term ? {
    __html: term.toString().replace(new RegExp(`(${filter})`, "ig"), '<span class="globalSearch__matchHighlight">$1</span>')
  } : term;
};
