// Dependencies
// -----------------------------------------------
import React from 'react';
import deepcopy from 'deepcopy';

// Components
// -----------------------------------------------
import { FeatureCheckbox } from 'adminComponents';

// ------------------------------------------------
// COMPONENT->FEATURES-SINGLE-FIELDS-FEATURE-LIST -
// ------------------------------------------------
const FeaturesSingleFieldsFeatureList = props => {
  const onSingleChange = e => {
    var fields = deepcopy(props.fields);
    fields[e.target.value]['value'] = e.target.checked;
    updateFieldSubset(fields);
  };

  const onAllChange = (e, value) => {
    e.preventDefault();
    var fields = deepcopy(props.fields);
    for (var key in fields) {
      fields[key]['value'] = value;
    }
    updateFieldSubset(fields);
  };

  const updateFieldSubset = fields => {
    props.onObjectChange(props.fields_name, fields);
  };

  // ---------------------------------------------
  // RETURN --------------------------------------
  // ---------------------------------------------
  return (
    <fieldset>
      <header className="fieldset-header">
        <legend>{props.fields_label}</legend>
        <ul className="features-amenities-toggles">
          <li>
            <a
              href="#"
              className="positive"
              onClick={e => onAllChange(e, true)}
            >
              Select all
            </a>
          </li>
          <li>
            <a
              href="#"
              className="negative"
              onClick={e => onAllChange(e, false)}
            >
              Deselect all
            </a>
          </li>
        </ul>
      </header>
      <div className="checkbox-group">
        {Object.keys(props.fields).map(fieldKey => (
          <FeatureCheckbox
            key={fieldKey}
            fieldKey={fieldKey}
            field={props.fields[fieldKey]}
            updateIndividualField={onSingleChange}
          />
        ))}
      </div>
    </fieldset>
  );
};

// -----------------------------------------------
// EXPORT ----------------------------------------
// -----------------------------------------------
export default FeaturesSingleFieldsFeatureList;
