import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PaymentsHome from './payments-home';
import PaymentsStatements from './payments-statements';
import PaymentsInvoices from './payments-invoices';
import PaymentsBankAccounts from './payments-bank-accounts';
import PaymentsStatementDetails from './payments-statement-details';

const PaymentsRoutes = props => {
  return (
    <Switch>
      <Route
        path="/payments/bank-accounts"
        render={routeProps => <PaymentsBankAccounts {...props} />}
      />
      <Route
        path="/payments/invoices"
        render={routeProps => <PaymentsInvoices {...props} />}
      />
      <Route
        path="/payments/statements/:id"
        render={routeProps => (
          <PaymentsStatementDetails
            {...props}
            statementId={routeProps.match.params.id}
          />
        )}
      />
      <Route
        path="/payments/statements"
        render={routeProps => <PaymentsStatements {...props} />}
      />
      <Route
        path="/payments"
        render={routeProps => <PaymentsHome {...props} />}
      />
    </Switch>
  );
};

export default PaymentsRoutes;
