import React from 'react';
import ConfirmLink from 'sharedComponents/ConfirmLink';

const DeleteButton = props => {
  // Early return null if user can't delete
  if (!props.can_delete) return null;

  return (
    <ConfirmLink
      action={props.action}
      isDestructiveAction
      actionArgs={props.actionArgs}
      confirmMessage={props.confirmMessage}
      confirmText={props.confirmText}
      cancelText={props.cancelText}
      title={props.title}
    >
      {props.children}
    </ConfirmLink>
  );
};

export default DeleteButton;
