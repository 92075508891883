import request from "apiClient";
export default class CsvImportService {
  static parse(organizationId, file) {
    return request({
      url: `/api/${organizationId}/csv_imports/parse`,
      method: "POST",
      data: file
    });
  }
  static create(organizationId, csv_data) {
    return request({
      url: `/api/${organizationId}/csv_imports`,
      method: "POST",
      data: {
        csv_data
      }
    });
  }
  static get(organizationId, csvImportId) {
    return request({
      url: `/api/${organizationId}/csv_imports/${csvImportId}`,
      method: "GET"
    });
  }
  static createDirectObjects(organizationId, data) {
    return request({
      url: `/api/${organizationId}/csv_imports/create_direct_objects`,
      method: "POST",
      data
    });
  }
  static sendUnitData(organizationId, data) {
    return request({
      url: `/api/${organizationId}/csv_imports/send_unit_data`,
      method: "POST",
      data
    });
  }
}
