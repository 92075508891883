import React from 'react';
import {
  Box,
  FlexBox,
  IconFontAwesome,
  TextBody,
  TextH2
} from '@directsoftware/ui-kit-web-admin';

const EmptyConfiguration = ({ title, prompt, isLoading }) => {
  return (
    <div className="consolidated-item-subsection">
      <FlexBox gap="s" alignItems="center">
        <Box>
          <Box className="empty-set-icon">
            <IconFontAwesome
              name={isLoading ? 'spinner' : 'emptySet'}
              iconStyle="duotone"
              spin={isLoading}
            />
          </Box>
        </Box>
        {(title || prompt) && (
          <Box flex="1">
            {title && (
              <>
                <TextH2>{title}</TextH2>
                <br />
              </>
            )}
            {prompt && <TextBody>{prompt}</TextBody>}
          </Box>
        )}
      </FlexBox>
    </div>
  );
};

export default EmptyConfiguration;
