import request from "apiClient";
function create(orgId, params) {
  return request({
    url: `/api/${orgId}/point_central/registrations`,
    method: "POST",
    data: params
  });
}
const PointCentralRegistrationService = {
  create
};
export default PointCentralRegistrationService;
