// Dependencies
// -----------------------------------------------
import React from 'react';
import { ajaxDataWithAuthorization } from 'apiClient';

// Components
// -----------------------------------------------
import {
  ConsolidatedItemEditToggleHeader,
  dispatchError,
  SimplifiedWysiwyg
} from 'adminComponents';
import PermissionComponent from 'PermissionComponent';
import { MinimumAgeSelect } from 'adminComponents';
import {
  CallToActionLink,
  IconFontAwesome,
  FormField,
  FlexBox,
  Box
} from '@directsoftware/ui-kit-web-admin';

// -----------------------------------------------
// COMPONENT->MANAGE-SINGLE-FIELDS-LODGING -------
// -----------------------------------------------
export default class ManageSingleFieldsLodging extends React.Component {
  // ---------------------------------------------
  // CONSTRUCTOR ---------------------------------
  // ---------------------------------------------
  constructor(props, _railsContext) {
    super(props);

    this.state = {
      minimum_age_rule: this.props.unit?.features_suitability
        ?.SUITABILITY_MINIMUM_AGE_LIMIT?.value,
      minimum_age: this.props.unit?.minimum_age || 21,
      minimum_age_cache: this.props.unit?.minimum_age || 21,
      num_bathrooms: this.props.unit?.num_bathrooms,
      num_bathrooms_cache: this.props.unit?.num_bathrooms,
      num_bedrooms: this.props.unit?.num_bedrooms || 0,
      num_bedrooms_cache: this.props.unit?.num_bedrooms || 0,
      num_sleep: this.props.unit?.num_sleep,
      num_sleep_cache: this.props.unit?.num_sleep,
      summary_description: this.props.unit?.summary_description,
      summary_description_cache: this.props.unit?.summary_description,
      size: this.props.unit?.size,
      size_cache: this.props.unit?.size,
      measurement_type: this.props.unit?.measurement_type,
      measurement_type_cache: this.props.unit?.measurement_type,
      isDirty: false,
      isEditing: false,
      isSaving: false,
      isSaved: false
    };
  }

  onChange = e => {
    e.preventDefault();
    const stateChange = {};
    stateChange[e.target.name] = e.target.value;
    stateChange.isDirty = true;
    this.setState(stateChange);
  };

  onUOMChange = e => {
    const stateChange = {};
    stateChange.measurement_type = e.value;
    stateChange.isDirty = true;
    this.setState(stateChange);
  };

  onWysiwygChange = (name, val) => {
    const stateChange = {};
    stateChange[name] = val;
    stateChange.isDirty = true;
    this.setState(stateChange);
  };

  setNumSleep = e => {
    const stateChange = {};
    stateChange.num_sleep = e.target.value;
    stateChange.isDirty = true;
    this.setState(stateChange);
  };

  onMinimumAgeChange = change => {
    this.setState({ minimum_age: change.minimum_age, isDirty: true });
  };

  toggleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  finishEditingAndRevert = () => {
    const stateChange = {};
    stateChange.num_sleep = this.state.num_sleep_cache;
    stateChange.size = this.state.size_cache;
    stateChange.summary_description = this.state.summary_description_cache;
    stateChange.minimum_age = this.state.minimum_age_cache;
    stateChange.isDirty = false;
    stateChange.isEditing = false;
    stateChange.isSaving = false;
    stateChange.isSaved = false;
    this.setState(stateChange);
  };

  finishEditingAndUpdate = () => {
    const stateChange = {};
    stateChange.num_sleep_cache = this.state.num_sleep;
    stateChange.size_cache = this.state.size;
    stateChange.summary_description_cache = this.state.summary_description;
    stateChange.isDirty = false;
    stateChange.isEditing = false;
    stateChange.isSaving = false;
    stateChange.isSaved = true;
    stateChange.minimum_age_cache = this.state.minimum_age;
    this.setState(stateChange);
  };

  attemptSave = () => {
    this.setState(
      {
        isSaving: true,
        isSaved: false
      },
      () => {
        $.ajax(
          ajaxDataWithAuthorization({
            type: 'PATCH',
            url: `/api/${this.props.organization.id}/units/${
              this.props.unit.id
            }`,
            context: this,
            dataType: 'json',
            data: {
              num_sleep: this.state.num_sleep,
              summary_description: this.state.summary_description,
              size: this.state.size,
              measurement_type: this.state.measurement_type,
              minimum_age: this.state.minimum_age
            }
          })
        )
          .done(function(data) {
            this.finishEditingAndUpdate();
          })
          .fail(function(jqXhr) {
            dispatchError();
            console.warn(jqXhr);
            this.finishEditingAndRevert();
          });
      }
    );
  };

  renderEditing() {
    const measurementTypeOptions = [
      { value: 'sq_feet', label: 'Sq Feet' },
      { value: 'sq_meters', label: 'Sq Meters' }
    ];
    const inputStyles = {
      width: '10.3em',
      height: '2.2em',
      fontSize: '18px'
    };

    const selectStyles = {
      width: '10em'
    };
    const { minimum_age_rule, minimum_age } = this.state;
    return (
      <form className="consolidated-item-form-editing">
        <div className="consolidated-item-subsection">
          <FlexBox gap="xs" flexWrap="wrap">
            <Box style={{ width: 200 }}>
              <FormField
                inputType="number"
                labelText="Max occupancy"
                labelHtmlFor="num_sleep"
                inputProps={{
                  id: 'num_sleep',
                  name: 'num_sleep',
                  onChange: this.setNumSleep,
                  value: this.state.num_sleep
                }}
              />
            </Box>
            <Box style={{ width: 200 }}>
              <MinimumAgeSelect
                minimum_age={minimum_age}
                onChange={this.onMinimumAgeChange}
              />
            </Box>
            <Box style={{ width: 200 }}>
              <FormField
                inputType="number"
                labelText="Size"
                labelHtmlFor="size"
                inputProps={{
                  id: 'size',
                  name: 'size',
                  onChange: this.onChange,
                  placeholder: 'Enter property size',
                  value: this.state.size,
                  min: 1,
                  max: 999999999
                }}
              />
            </Box>
            <Box style={{ width: 200 }}>
              <FormField
                inputType="select"
                labelText="Unit of Measurement"
                labelHtmlFor="measurement_type"
                inputProps={{
                  inputId: 'measurement_type',
                  name: 'measurement_type',
                  onChange: this.onUOMChange,
                  options: measurementTypeOptions,
                  placeholder: 'Select a measurement type...',
                  value: measurementTypeOptions.filter(
                    option => option.value === this.state.measurement_type
                  )
                }}
              />
            </Box>
          </FlexBox>
        </div>
        <div className="consolidated-item-subsection">
          <label htmlFor="summary_description">Description</label>
          <SimplifiedWysiwyg
            content={this.state.summary_description}
            name="summary_description"
            id="summary_description"
            onContentChange={this.onWysiwygChange}
          />
        </div>
        <section className="consolidated-item-subsection two-col two-col-50-50">
          <div>
            <h4>{`${this.state.num_bedrooms} Bedrooms`}</h4>
            <CallToActionLink
              size="dense"
              appearance="ghost"
              appendIcon={<IconFontAwesome name="longArrowRight" />}
              href={`/properties/${this.props.property.id}/units/${
                this.props.unit.id
              }/bedrooms`}
            >
              Manage Bedrooms
            </CallToActionLink>
          </div>
          <div>
            <h4>{`${this.state.num_bathrooms} Bathrooms`}</h4>
            <CallToActionLink
              size="dense"
              appearance="ghost"
              appendIcon={<IconFontAwesome name="longArrowRight" />}
              href={`/properties/${this.props.property.id}/units/${
                this.props.unit.id
              }/bathrooms`}
            >
              Manage Bathrooms
            </CallToActionLink>
          </div>
        </section>
      </form>
    );
  }

  renderViewing() {
    const { organization, property, unit, user } = this.props;

    return (
      <section className="consolidated-item-form-viewing">
        <div className="consolidated-item-subsection">
          <h4>Max occupancy</h4>
          {this.state.num_sleep}
        </div>
        {this.state.minimum_age_rule && (
          <div className="consolidated-item-subsection">
            <h4>Minimum Age Requirement</h4>
            {this.state.minimum_age}
          </div>
        )}
        <div className="consolidated-item-subsection">
          <h4>Square Footage</h4>
          {`${this.state.size} ${this.state.measurement_type?.replace(
            '_',
            ' '
          )}`}
        </div>
        <div className="consolidated-item-subsection">
          <h4>Description</h4>
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.summary_description
            }}
          />
        </div>
        <section className="consolidated-item-subsection two-col two-col-50-50">
          <div>
            <h4>{`${this.state.num_bedrooms} Bedrooms`}</h4>
            <PermissionComponent
              user={this.props.user}
              permission="view_properties"
              restriction="properties_read_only"
            >
              <CallToActionLink
                size="dense"
                appearance="ghost"
                appendIcon={<IconFontAwesome name="longArrowRight" />}
                href={`/properties/${property.id}/units/${unit?.id}/bedrooms`}
              >
                Manage Bedrooms
              </CallToActionLink>
            </PermissionComponent>
          </div>
          <div>
            <h4>{`${this.state.num_bathrooms} Bathrooms`}</h4>
            <PermissionComponent
              user={this.props.user}
              permission="view_properties"
              restriction="properties_read_only"
            >
              <CallToActionLink
                size="dense"
                appearance="ghost"
                appendIcon={<IconFontAwesome name="longArrowRight" />}
                href={`/properties/${property.id}/units/${unit?.id}/bathrooms`}
              >
                Manage Bathrooms
              </CallToActionLink>
            </PermissionComponent>
          </div>
        </section>
      </section>
    );
  }

  // ---------------------------------------------
  // RENDER --------------------------------------
  // ---------------------------------------------
  render() {
    return (
      <figure
        className={`consolidated-item-form-section ${
          this.state.isEditing ? 'form-editing' : ''
        }`}
      >
        <ConsolidatedItemEditToggleHeader
          toggleEditing={this.toggleEditing}
          finishEditingAndRevert={this.finishEditingAndRevert}
          finishEditingAndUpdate={this.finishEditingAndUpdate}
          attemptSave={this.attemptSave}
          isDirty={this.state.isDirty}
          isEditing={this.state.isEditing}
          isSaving={this.state.isSaving}
          title="Lodging Overview"
          blockEdit={this.props.blockEdit}
        />
        {this.state.isEditing ? this.renderEditing() : this.renderViewing()}
      </figure>
    );
  }
}

ManageSingleFieldsLodging.defaultProps = {
  fromProperty: true
};
