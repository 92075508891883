import React from 'react';
import { CustomerSidebarService } from 'adminApi';
import reject from 'lodash/reject';
import filter from 'lodash/filter';
import OpportunityCard from '../Opportunities/OpportunityCard';
import OpportunityService from 'adminApi/OpportunityService';
import ManageNotes from './ManageNotes';
import displayError from 'sharedComponents/ErrorDisplay';

const statusArray = ['converted', 'not_converted'];

export default class SidebarNotes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opportunities: [],
      activeOpportunity: null,
      loading: true
    };
  }

  componentDidMount() {
    this.loadOpportunities();
  }

  loadOpportunities = () => {
    this.setState({ loading: true, activeOpportunity: null }, () => {
      CustomerSidebarService.getCustomerOpportunities(
        this.props.organizationID,
        this.props.customer.id
      )
        .then(response => {
          this.setState({
            opportunities: response.opportunities,
            loading: false
          });
        })
        .catch(err =>
          displayError({
            message: 'Error fetching customer opportunities',
            err
          })
        );
    });
  };

  setActiveOpportunity = opportunityId => {
    if (opportunityId === null) {
      this.setState({ activeOpportunity: null });
    }

    this.setState({ loading: true }, () => {
      OpportunityService.fetch(this.props.organizationID, opportunityId)
        .then(response => {
          this.setState({
            activeOpportunity: response.opportunity,
            loading: false
          });
        })
        .catch(err =>
          displayError({ message: 'Error fetching opportunity', err })
        );
    });
  };

  renderCurrentOpportunities = () => {
    const { opportunities } = { ...this.state };

    if (opportunities.length === 0)
      return <div>No Current Opportunities Found</div>;

    return reject(opportunities, o => statusArray.includes(o.status)).map(o => (
      <OpportunityCard
        opportunity={o}
        setActiveOpportunity={this.setActiveOpportunity}
      />
    ));
  };

  renderPastOpportunities = () => {
    const { opportunities } = { ...this.state };

    if (opportunities.length === 0)
      return <div>No Past Opportunities Found</div>;

    return filter(opportunities, o => statusArray.includes(o.status)).map(o => (
      <OpportunityCard
        opportunity={o}
        setActiveOpportunity={this.setActiveOpportunity}
      />
    ));
  };

  renderContent = () => {
    if (this.state.activeOpportunity) {
      return (
        <ManageNotes
          opportunity={this.state.activeOpportunity}
          loadOpportunities={this.loadOpportunities}
          organizationID={this.props.organizationID}
          organization={this.props.organization}
        />
      );
    } else {
      return [
        this.renderCurrentOpportunities(),
        <div
          style={{
            color: '#606B6F',
            fontSize: '14px',
            margin: '10px',
            textAlign: 'left',
            width: '100%'
          }}
        >
          PAST OPPORTUNITIES
        </div>,
        this.renderPastOpportunities()
      ];
    }
  };

  render() {
    if (this.state.loading) return null;

    return this.renderContent();
  }
}
