import request from "apiClient";
export default class StripeConnectAccountService {
  static getConnectObject(organizationId) {
    return request({
      url: `/api/${organizationId}/stripe_connect_accounts/connect_object`,
      method: "GET"
    });
  }
  static updateSpecialPricing(organizationId, accountId, specialPricing, startDate) {
    return request({
      url: `/api/${organizationId}/stripe_connect_accounts/update_special_pricing`,
      method: "POST",
      data: {
        stripe_connect_account_id: accountId,
        special_pricing_enabled: specialPricing,
        start_date: startDate
      }
    });
  }
}
