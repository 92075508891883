import React from 'react';

export default class InputLimiter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const warn = this.props.total - this.props.used < 0.075 * this.props.total;
    return (
      <figure className={warn ? 'input-limiter warn' : 'input-limiter'}>
        <b>{this.props.total - this.props.used}</b>
        {this.showTotal ? ` / ${this.props.total}` : ''}
      </figure>
    );
  }
}
