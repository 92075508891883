import request from "apiClient";
const securityDepositStats = (orgId) => {
  return request({
    url: `/api/${orgId}/dashboard/security_deposit_stats`,
    method: "GET"
  });
};
const securityDepositDueBookings = (orgId) => {
  return request({
    url: `/api/${orgId}/dashboard/security_deposit_due_bookings`,
    method: "GET"
  });
};
const expiringBookingStats = (orgId) => {
  return request({
    url: `/api/${orgId}/dashboard/expiring_booking_stats`,
    method: "GET"
  });
};
const expiringBookingRequests = (orgId) => {
  return request({
    url: `/api/${orgId}/dashboard/expiring_booking_requests`,
    method: "GET"
  });
};
const paymentDueBookingsStats = (orgId) => {
  return request({
    url: `/api/${orgId}/dashboard/payment_due_bookings_stats`,
    method: "GET"
  });
};
const paymentDueBookings = (orgId) => {
  return request({
    url: `/api/${orgId}/dashboard/payment_due_bookings`,
    method: "GET"
  });
};
const arrivingAndDepartingBookings = (orgId, startDate) => {
  return request({
    url: `/api/${orgId}/dashboard/arriving_and_departing_bookings?start_date=${startDate}`,
    method: "GET"
  });
};
const DashboardService = {
  securityDepositStats,
  expiringBookingStats,
  paymentDueBookingsStats,
  paymentDueBookings,
  expiringBookingRequests,
  securityDepositDueBookings,
  arrivingAndDepartingBookings
};
export default DashboardService;
