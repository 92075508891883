import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconFontAwesome,
  Modal,
  PageHeader,
  SlideoutPanel,
  VisibilityHelper,
  CallToActionButton,
  Toast,
  StandByOverlay
} from '@directsoftware/ui-kit-web-admin';
import PermissionComponent from 'PermissionComponent';
import moment from 'moment';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { WORKFLOWS_NAV_TAGS } from '../../../../../shared/nav';
import { useDetectMobile } from '../../../../../shared/hooks';
import NewWorkOrder from '../../../../work_orders/new-work-order';
import WorkOrderService from '../../../../api/WorkOrderService';
import {
  resetNewWorkOrder,
  updateWorkOrdersUI
} from '../../../../redux/slices/workOrder';

const WorkOrdersHome = props => {
  const dispatch = useDispatch();
  const { isMobile } = useDetectMobile();
  const ToastRef = useRef();
  const workOrder = useSelector(state => state.workOrders.newWorkOrder);
  const [reveal, setReveal] = useState(false);
  const [activateSave, setActivateSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const closeModal = () => {
    dispatch(resetNewWorkOrder());
    dispatch(updateWorkOrdersUI({ cleanUpNewOrder: true }));
    setReveal(false);
  };

  const onSubmit = () => {
    setIsSaving(true);
    const {
      amount,
      unit_id,
      assignee_id,
      assignee_type,
      wo_type,
      job_type,
      date,
      time,
      description,
      title,
      requested_by,
      booking_id,
      task_types
    } = workOrder;

    const data = {
      amount_cents: parseFloat(amount) * 100,
      unit_id,
      assignee_type,
      assignee_id,
      wo_type,
      job_type: wo_type === 'maintenance' ? job_type : null,
      due_on: moment(`${date} ${time}`),
      wo_source: `manual`,
      description,
      title,
      task_types: task_types.map(t => t.value),
      requester_id: requested_by.value,
      requester_type: requested_by.type,
      booking_id
    };

    WorkOrderService.create(props.organization.id, data)
      .then(response => {
        setIsSaving(false);
        ToastRef.current.toastSuccess('Work Order Created');
        dispatch(updateWorkOrdersUI({ cleanUpNewOrder: true }));
        dispatch(resetNewWorkOrder());
        setReveal(false);
      })
      .catch(err => {
        setIsSaving(false);
        ToastRef.current.toastError(
          'Work Order not Created',
          'We were unable to create your new work order. Please check that the information you entered is valid.'
        );
      });
  };

  useEffect(
    () => {
      const { unit_id, wo_type, date, title, description } = workOrder; // all the required fields
      if (unit_id && wo_type && date && title && description) {
        setActivateSave(true);
      }
    },
    [workOrder]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: WORKFLOWS_NAV_TAGS.PRIMARY,
        secondary: WORKFLOWS_NAV_TAGS.secondary.WORK_ORDERS
      })
    );
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search);
    if (urlParams.get('modalOpen')) {
      setReveal(true);
    }
  }, []);

  return (
    <>
      <Toast ref={ToastRef} />
      <PageHeader>
        <PageHeader.Left>
          Workflows: <span>Work Orders</span>
        </PageHeader.Left>
        <PermissionComponent user={props.user} permission="create_work_orders">
          <PageHeader.Right>
            <VisibilityHelper hideStartingFrom="tablet">
              <CallToActionButton
                onClick={() => {
                  setReveal(true);
                }}
                prependIcon={<IconFontAwesome name="plus" />}
                size="dense"
              >
                Create
              </CallToActionButton>
            </VisibilityHelper>
            <VisibilityHelper showStartingFrom="tablet">
              <CallToActionButton
                onClick={() => {
                  setReveal(true);
                }}
                prependIcon={<IconFontAwesome name="plus" />}
              >
                Create
              </CallToActionButton>
            </VisibilityHelper>
          </PageHeader.Right>
        </PermissionComponent>
      </PageHeader>
      {isMobile ? (
        <Modal
          reveal={reveal}
          closeOnClick={() => closeModal()}
          title="Create Work Order"
          size="cover"
        >
          <Modal.Content contentIsScrollable>
            <NewWorkOrder {...props} />
          </Modal.Content>
          <Modal.Footer
            primaryOnClick={() => onSubmit()}
            primaryLabel="Create"
            secondaryOnClick={() => closeModal()}
            primaryIsDisabled={!activateSave}
          />
        </Modal>
      ) : (
        <SlideoutPanel
          reveal={reveal}
          contentIsScrollable
          closeOnClickOutside
          closeButtonOnClick={() => closeModal()}
          removeContentPadding
          title="Create Work Order"
          primaryActionProps={{
            actionType: 'button',
            label: 'Create',
            action: () => onSubmit(),
            isDisabled: !activateSave
          }}
          secondaryActionProps={{
            actionType: 'button',
            label: 'Cancel',
            action: () => closeModal()
          }}
        >
          <StandByOverlay reveal={isSaving} position="absolute" />
          <NewWorkOrder {...props} />
        </SlideoutPanel>
      )}
    </>
  );
};

export default WorkOrdersHome;
