import React from 'react';
import DefaultMobile from './linkCollections/default/default-mobile';
import RvFleetMobile from './linkCollections/rv-fleet/rv-fleet-mobile';

const NavbarMobile = props => {
  const renderLinkCollection = () => {
    switch (props.linkCollection) {
      case 'rv-fleet':
        return <RvFleetMobile {...props} />;
      case 'admin':
      default:
        return <DefaultMobile {...props} />;
    }
  };
  return <>{renderLinkCollection()}</>;
};

export default NavbarMobile;
