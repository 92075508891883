// Dependencies
// -----------------------------------------------
import React from 'react';
import isNull from 'lodash/isNull';
import moment from 'moment';
import styled from 'styled-components';

// Components
// -----------------------------------------------
import WidgetDatePicker from 'sharedComponents/WidgetDatePicker';
import ChannelSelect from 'adminComponents/ChannelSelect';
import IntegerSelect from 'adminComponents/Selects/IntegerSelect';
import displayError from 'sharedComponents/ErrorDisplay';

// Services
// -----------------------------------------------
import { QuoteService } from 'adminApi';
import UnitService from 'adminApi/UnitService';
import OutOfStayRange from './out-of-stay-range';
import BookingWidgetContainer from './BookingWidgetContainer';

// Styles
// -----------------------------------------------
const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export default class BookingWidget extends React.Component {
  state = {
    unit_id: this.props.unitID,
    check_in: this.props.checkIn,
    check_out: this.props.checkOut,
    channel_id: this.props.channelID,
    num_guests: this.props.numGuests || 1,
    quote: null,
    couponCode: '',
    badCode: false,
    allCouponCodes: null,
    outOfStayRange: false
  };

  componentDidMount = () => {
    this.fetchCouponCodes();
    this.fetchAvailability();
  };

  fetchCouponCodes = () => {
    QuoteService.fetchCouponCodes(
      this.props.organizationID,
      this.state.unit_id,
      'Unit'
    ).then(response => {
      this.setState({ allCouponCodes: response });
    });
  };

  fetchAvailability = () => {
    UnitService.getUnitAvailability(
      this.props.organizationID,
      this.state.unit_id
    ).then(response => {
      this.setState({
        availabilityCalendar: response.availability_calendar,
        minStay: response.min_stay,
        maxStay: response.max_stay
      });
    });
  };

  getDaysArray = (start, end) => {
    // var here because let and const break it
    for (
      var arr = [], dt = new Date(start);
      dt <= end;
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(moment(new Date(dt)).format('DD-MM-YYYY'));
    }
    return arr;
  };

  checkMinMaxStay = () => {
    const totalDays = this.state.check_out.diff(this.state.check_in, 'days');
    let customMin = 0;
    let customMax = 0;
    this.getDaysArray(this.state.check_in, this.state.check_out).map(d => {
      if (this.state.availabilityCalendar[d]) {
        if (
          this.state.availabilityCalendar[d].stayMin < customMin ||
          customMin === 0
        ) {
          const newMin = parseInt(this.state.availabilityCalendar[d].stayMin);
          isNaN(newMin) ? (customMin = 0) : (customMin = newMin);
        }
        if (this.state.availabilityCalendar[d].stayMax > customMin) {
          customMax = parseInt(this.state.availabilityCalendar[d].stayMax);
        }
      }
    });

    if (
      (totalDays < customMin || (totalDays > customMax && customMax != 0)) &&
      this.props.bookingType === 'guest'
    ) {
      this.setState({ outOfStayRange: true, customMin, customMax, quote: {} });
      this.props.afterQuoteCreated && this.props.afterQuoteCreated({});
      return true;
    } else if (
      (totalDays < this.state.minStay || totalDays > this.state.maxStay) &&
      customMax === 0 &&
      this.props.bookingType === 'guest'
    ) {
      this.setState({
        outOfStayRange: true,
        customMin: null,
        customMax: null,
        quote: {}
      });
      this.props.afterQuoteCreated && this.props.afterQuoteCreated({});
      return true;
    }
  };

  newQuote = () => {
    if (
      !this.state.check_in ||
      !this.state.check_out ||
      this.state.channel_id === undefined ||
      this.checkMinMaxStay()
    )
      return null;

    const quoteParams = {
      unit_id: this.state.unit_id,
      check_in: this.state.check_in,
      check_out: this.state.check_out,
      channel_id: this.state.channel_id,
      num_guests: this.state.num_guests,
      quote: undefined,
      coupon_code: this.state.couponCode
    };

    QuoteService.create(this.props.organizationID, quoteParams)
      .then(response => {
        this.setState({ quote: response.quote }, () => {
          this.props.afterQuoteCreated &&
            this.props.afterQuoteCreated(this.state.quote);
        });
      })
      .catch(err => {
        displayError({ message: 'Error creating quote', err });
      });
  };

  onDatesChange = ({
    startDate,
    endDate
  }) => {
    this.setState(
      { check_in: startDate, check_out: endDate, outOfStayRange: false },
      () => {
        this.newQuote();
        this.props.onDatesChange &&
          this.props.onDatesChange({ startDate, endDate });
      }
    );
  };

  onChannelChange = channel_id => {
    this.setState({ channel_id }, () => {
      this.newQuote();
    });
  };

  onNumGuestsChange = num_guests => {
    this.setState({ num_guests }, () => {
      this.newQuote();
    });
  };

  updateCouponCode = couponCode => {
    this.setState({ couponCode, badCode: false });
  };

  addCouponCode = couponCode => {
    this.setState({ couponCode }, () => {
      this.newQuote();
    });
  };

  verifyCouponCode = () => {
    if (isNull(this.state.allCouponCodes)) {
      this.setState({ badCode: true });
    } else if (this.state.allCouponCodes.includes(this.state.couponCode?.toLowerCase())) {
      this.setState({ badCode: false }, () => {
        this.addCouponCode(this.state.couponCode);
      });
    } else {
      this.setState({ badCode: true });
    }
  };

  render() {
    return (
      <div>
        <QuoteWrapper>
          {this.state.unit_id && this.state.availabilityCalendar && (
            <WidgetDatePicker
              bookingCalendar={this.state.availabilityCalendar}
              label="Booking Range"
              organizationID={this.props.organizationID}
              unitID={this.state.unit_id}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              initialStartDate={this.state.startDate}
              initialEndDate={this.state.endDate}
              onDatesChange={this.onDatesChange}
              readOnly
            />
          )}
          <div style={{ marginLeft: '8px', minWidth: '75px' }}>
            <IntegerSelect
              label="Guests"
              name="numGuests"
              minInt={1}
              maxInt={24}
              value={this.state.num_guests}
              onChange={option => this.onNumGuestsChange(option.value)}
            />
          </div>
          <ChannelSelect
            channelID={this.state.channel_id}
            onChannelChange={this.onChannelChange}
            styles={{
              marginLeft: '8px',
              maxWidth: '288px',
              width: '100%'
            }}
            singleChannelOnly
          />
        </QuoteWrapper>
        <div>
          <input
            name="coupon_code"
            value={this.state.couponCode}
            onChange={e => this.updateCouponCode(e.target.value)}
            placeholder="Coupon Code"
            style={
              this.state.badCode
                ? { border: '2px solid red', height: '3em', margin: '10px 0px' }
                : { height: '3em', margin: '10px 0px' }
            }
          />
          <a className="button" onClick={() => this.verifyCouponCode()}>
            Apply Code
          </a>
          {this.state.badCode && (
            <p style={{ color: 'red', marginRight: '50%' }}>
              Sorry, this coupon cannot be used for this booking
            </p>
          )}
        </div>
        {this.state.outOfStayRange && <OutOfStayRange props={this.state} />}
        {!this.props.hideContainer && (
          <BookingWidgetContainer quote={this.state.quote} />
        )}
      </div>
    );
  }
}
