import moment from "moment";
export const parseDate = (date) => moment.utc(date).format("MM/DD/YYYY");
export const parseDateForRct = (date, startDate = false) => {
  const formatString = "DD-MM-YYYY";
  if (startDate) {
    return moment(date, formatString).startOf("day").unix() * 1e3;
  } else {
    return moment(date, formatString).endOf("day").unix() * 1e3;
  }
};
export const convertTime = (timeVal) => {
  let time = timeVal.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [timeVal];
  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? " AM" : " PM";
    time[0] = +time[0] % 12 || 12;
  }
  return time.join("");
};
export const numberOfDays = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, "days");
};
