import React from 'react';
import PropTypes from 'prop-types';

import { ajaxDataWithAuthorization } from 'apiClient';
import {
  ConfirmDialog,
  Dropdown,
  IconFontAwesome,
  StandByOverlay,
  VisibilityHelper
} from '@directsoftware/ui-kit-web-admin';
import slice from 'lodash/slice';
import { dispatchError } from '../../../components/error-alert';

export default class ManagePropertyCommandPalette extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired
  };

  constructor(props, _railsContext) {
    super(props);
    this.state = {
      optionsToggled: false,
      showDeleteDialog: false,
      showDeleteOverlay: false
    };
  }

  toggleOptions = e => {
    e.preventDefault();
    this.setState({ optionsToggled: !this.state.optionsToggled });
  };

  deleteProperty = () => {
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'DELETE',
        url: `/api/${this.props.organization.id}/properties/${
          this.props.property.id
        }`,
        context: this,
        cache: false
      })
    )
      .done(function(data) {
        window.location = `/dashboard/${
          this.props.organization.subdomain
        }/properties/`;
      })
      .fail(function(jqXhr) {
        dispatchError();
        console.error(jqXhr);
      });
  };

  renderPanelContent = () => {
    const {
      organization,
      user: { permissions }
    } = this.props;
    const viewerCanAccessReservations =
      permissions.view_reservations && !permissions.properties_read_only;
    const panelContent = [
      {
        label: 'Manage Images',
        href: `/properties/${this.props.property.id}/images`,
        type: 'router-link',
        addPadding: true,
        removeBorderRadius: true
      },
      {
        label: 'Add a Unit',
        href: `/properties/${this.props.property.id}/units/new`,
        type: 'router-link',
        addPadding: true,
        removeBorderRadius: true
      },
      {
        label: 'View Reservations',
        type: 'link',
        href: `/dashboard/${organization.subdomain}/reservations?property_id=${
          this.props.property.id
        }`,
        addPadding: true,
        openNewTab: true,
        removeBorderRadius: true
      }
    ];

    if (this.props.property.direct_ical_url) {
      panelContent.push({
        label: 'Download iCal',
        type: 'link',
        href: this.props.property.direct_ical_url,
        addPadding: true,
        openNewTab: true
      })
    }

    if (this.props.user.admin) {
      panelContent.push({
        addDivider: true
      });
      panelContent.push({
        label: 'Delete Listing',
        type: 'button',
        onClick: () => {
          this.setState({
            showDeleteDialog: true
          });
        },
        isDanger: true,
        icon: <IconFontAwesome name="trashAlt" />,
        iconPresetColor: 'red',
        removeBorderRadius: true,
        addPadding: true
      });
    }
    return viewerCanAccessReservations
      ? panelContent
      : slice(panelContent, 0, -1);
  };

  render() {
    return (
      <>
        <StandByOverlay
          reveal={this.state.showDeleteOverlay}
          position="fixed"
          headline="Deleting this Listing..."
          description={`You'll be redirected when it's done.`}
        />
        {/* <DeleteButton
          action={this.deleteProperty}
          confirmMessage={`Are you sure you want to delete ${
            this.props.property.name
          }? This will delete all of this property's units, listings, and associated bookings.`}
          confirmText="Delete"
          cancelText="Cancel"
          can_delete={this.props.user.can_delete}
        >
          <IconButton appearance="ghost" variation="danger" onClick={() => {}}>
            <IconFontAwesome name="trashAlt" />
          </IconButton>
        </DeleteButton> */}
        <VisibilityHelper showStartingFrom="tablet">
          <Dropdown
            controlLabel="Actions"
            panelWidthVariation="auto"
            controlType="call-to-action-button"
            alignPanel="bottomRight"
            panelContent={this.renderPanelContent()}
          />
        </VisibilityHelper>
        <VisibilityHelper hideStartingFrom="tablet">
          <Dropdown
            controlLabel={<IconFontAwesome name="ellipsisV" />}
            panelWidthVariation="auto"
            controlType="icon-button"
            alignPanel="bottomRight"
            controlProps={{
              appearance: 'ghost',
              variation: 'secondary'
            }}
            panelContent={this.renderPanelContent()}
          />
        </VisibilityHelper>
        <ConfirmDialog
          description={`Are you sure you want to delete ${
            this.props.property?.name
          }? This will delete all associated listings on any brand.`}
          confirmOnClick={() => {
            this.deleteProperty();
            this.setState({
              showDeleteDialog: false,
              showDeleteOverlay: true
            });
          }}
          cancelOnClick={() => {
            this.setState({
              showDeleteDialog: false
            });
          }}
          confirmLabel="Yes, Delete"
          title="Delete this Listing?"
          reveal={this.state.showDeleteDialog}
          isDanger
        />
      </>
    );
  }
}
