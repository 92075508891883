import request from "apiClient";
const create = ({
  organization,
  unit,
  listing,
  vehicle,
  fromProperty,
  data
}) => {
  const url = fromProperty ? `/api/${organization.id}/units/${unit.id}/listings/${listing.id}/deposits` : `/api/${organization.id}/vehicles/${vehicle.id}/deposits`;
  return request({
    url,
    method: "POST",
    data
  });
};
const update = ({
  organization,
  unit,
  listing,
  vehicle,
  fromProperty,
  data,
  depositId
}) => {
  const url = fromProperty ? `/api/${organization.id}/units/${unit.id}/listings/${listing.id}/deposits/${depositId}` : `/api/${organization.id}/vehicles/${vehicle.id}/deposits/${depositId}`;
  return request({
    url,
    method: "PATCH",
    data
  });
};
const deleteDeposit = ({
  organization,
  unit,
  listing,
  vehicle,
  fromProperty,
  depositId
}) => {
  const url = fromProperty ? `/api/${organization.id}/units/${unit.id}/listings/${listing.id}/deposits/${depositId}` : `/api/${organization.id}/vehicles/${vehicle.id}/deposits/${depositId}`;
  return request({
    url,
    method: "DELETE"
  });
};
const DepositService = { create, update, deleteDeposit };
export default DepositService;
