import request from "apiClient";
function list(orgId) {
  return request({
    url: `/api/${orgId}/permissions`,
    method: "GET"
  });
}
const PermissionService = {
  list
};
export default PermissionService;
