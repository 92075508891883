import React from 'react';

export default class AsideOrganizationLanguageCurrency extends React.Component {
  constructor(props, _railsContext) {
    super(props);
  }

  render() {
    return (
      <div>
        <figure>
          <small className="subtitle">Language</small>
          <p>
            Select the language most likely to be used by the majority of your
            employees. Some browsers, like Google Chrome, offer free translation
            services so your site can be enjoyed by people all over.
          </p>
        </figure>

        <figure>
          <small className="subtitle">Currency</small>
          <p>This is the default currency used for all of your trascations.</p>
        </figure>
      </div>
    );
  }
}
