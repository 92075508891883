// Dependencies
// -----------------------------------------------
import React from 'react';
import { ajaxDataWithAuthorization } from 'apiClient';
import cloneDeep from 'lodash/cloneDeep';

// Components
// -----------------------------------------------
import displayError from 'sharedComponents/ErrorDisplay';
// import { InputIncrementer } from 'adminComponents';
import {
  IconFontAwesome,
  InfoPanel,
  InputIncrementer,
  Label,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import PermissionComponent from 'PermissionComponent';
import { IndividualBathroomFields } from '../atoms';

// -----------------------------------------------
// COMPONENT->OVERVIEW-SINGLE-FIELDS-BATHROOMS ---
// -----------------------------------------------
export default class OverviewSingleFieldsBathrooms extends React.Component {
  state = {
    bathrooms: this.props.bathrooms
  };

  componentDidMount() {
    if (!this.props.bathrooms) {
      const p = this.fetchBathrooms();
      p.then(data => {
        this.setState({ bathrooms: data.data ? data.data : data });
      }).catch(err =>
        displayError({ message: 'Error fetching bathrooms', err })
      );
    }
  }

  componentDidUpdate() {
    if (this.props?.isSaving) {
      this.props.onSimpleChange('bathrooms', this.state.bathrooms);
    }
  }

  componentWillUnmount() {
    this.props.onSimpleChange('bathrooms', this.state.bathrooms);
  }

  onBathroomsIncrement = value => {
    const addBathroom = [
      ...this.state.bathrooms,
      {
        id: this.state.bathrooms.length,
        unit_id: this.props.unit.id,
        organization_id: this.props.organization.id,
        bathroom_type: 'full',
        amenities: {
          AMENITY_BIDET: { label: 'Bidet', value: false },
          AMENITY_COMBO_TUB_SHOWER: {
            label: 'Combination tub/shower',
            value: false
          },
          AMENITY_JETTED_TUB: { label: 'Jetted tub', value: false },
          AMENITY_OUTDOOR_SHOWER: { label: 'Outdoor shower', value: false },
          AMENITY_SHOWER: { label: 'Shower', value: false },
          AMENITY_TOILET: { label: 'Toilet', value: false },
          AMENITY_TUB: { label: 'Tub', value: false }
        }
      }
    ];
    this.updateBathrooms(addBathroom);
  };

  onBathroomsDecrement = value => {
    const removeBathroom = this.state.bathrooms;
    removeBathroom.pop();
    this.updateBathrooms(removeBathroom);
  };

  fetchBathrooms = () => {
    const fetchBathrooms = new Promise((resolve, reject) => {
      $.ajax(
        ajaxDataWithAuthorization({
          type: 'GET',
          url: `/api/${this.props.organization.id}/units/${
            this.props.unit.id
          }/bathrooms`,
          context: this
        })
      )
        .done(function(data) {
          resolve(data);
        })
        .fail(function(jqXhr) {
          reject(jqXhr);
        });
    });
    return fetchBathrooms;
  };

  updateBathrooms = data => {
    let count = 0.0;
    if (data.length > 0) {
      data.forEach(b => {
        b.bathroom_type === 'full' ? (count += 1.0) : (count += 0.5);
      });
    }
    this.props.onSimpleChange('num_bathrooms', count);
    this.setState({ bathrooms: data });
  };

  updateBathroom = data => {
    const updateBathrooms = cloneDeep([...this.state.bathrooms]);
    updateBathrooms[data.index].bathroom_type = data.bathroom_type;
    updateBathrooms[data.index].amenities = data.amenities;
    this.updateBathrooms(updateBathrooms);
  };

  // ---------------------------------------------
  // RENDER --------------------------------------
  // ---------------------------------------------
  render() {
    return (
      <fieldset>
        {this.props.isDirty && (
          <>
            <InfoPanel
              headline="Click Save Bathrooms to finalize your changes"
              description="Sorry for the hassle, we are working on making saves instant."
              color="blue"
              icon={<IconFontAwesome name="infoCircle" />}
              isDismissible
            />
            <Spacer />
          </>
        )}
        <PermissionComponent
          user={this.props.user}
          permission="view_properties"
          restriction="properties_read_only"
        >
          <Label htmlFor="num_bathrooms">
            <span>How many bathrooms are available to guests?</span>
          </Label>
          <InputIncrementer
            onChange={() => {}}
            value={this.props.num_bathrooms}
            inputWidth="m"
            min={0}
            max={24}
            name="bathroom"
            onDecrement={this.onBathroomsDecrement}
            onIncrement={this.onBathroomsIncrement}
            inputIsReadOnly
          />
        </PermissionComponent>
        {this.state.bathrooms && this.state.bathrooms.length > 0 ? (
          <div>
            {this.state.bathrooms.map((bathroom, i) => (
              <IndividualBathroomFields
                key={bathroom.id}
                index={i}
                bathroom={bathroom}
                unit={this.props.unit}
                organization={this.props.organization}
                updateBathroom={this.updateBathroom}
                user={this.props.user}
              />
            ))}
          </div>
        ) : null}
      </fieldset>
    );
  }
}
