import React from 'react';
import { StripeBalanceCard } from 'adminComponents/Stripe';
import { ListCarousel } from 'adminComponents';
import { StripeService } from 'adminApi';
import Loading from 'react-loading-components';

export default class StripeBalanceCardsCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      balances: [],
      loading: true,
      lastBalancePaid: this.props.lastBalancePaid
    };
  }

  componentDidMount() {
    this.getAccountBalances();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.lastBalancePaid &&
      nextProps.lastBalancePaid !== this.state.lastBalancePaid
    ) {
      const balance = nextProps.lastBalancePaid;
      const trustAccount = balance.trust_accounts[0];
      if (!trustAccount) return null;
      const stripeBalance = this.state.balances.find(
        stripeBalance =>
          trustAccount.display_name ===
          stripeBalance.stripe_connect_account.name
      );

      this.setState({ lastBalancePaid: balance });
      this.getAccountBalance(stripeBalance);
    } else if (
      nextProps.stripeBalances &&
      nextProps.stripeBalances !== this.state.balances
    ) {
      this.setState({ balances: nextProps.stripeBalances });
    }
  }

  getAccountBalances = () => {
    StripeService.listStripeBalances(this.props.organization.id).then(
      response => {
        this.setState({ balances: response.balances, loading: false }, () => {
          this.props.afterBalancesLoad &&
            this.props.afterBalancesLoad(this.state.balances);
        });
      }
    );
  };

  getAccountBalance = stripeBalance => {
    if (!stripeBalance) return null;

    const balances = this.state.balances;
    const balanceIndex = balances.indexOf(stripeBalance);
    const balance = balances[balanceIndex];

    if (balance) {
      balance.loading = true;
      balances[balanceIndex] = balance;
    }

    this.setState({ balances }, () => {
      this.props.afterBalancesLoad &&
        this.props.afterBalancesLoad(this.state.balances);

      StripeService.getStripeBalance(
        this.props.organization.id,
        stripeBalance.stripe_connect_account.id
      ).then(response => {
        balances[balanceIndex] = response;
        this.setState({ balances }, () => {
          this.props.afterBalancesLoad &&
            this.props.afterBalancesLoad(this.state.balances);
        });
      });
    });
  };

  stripeCardsList = () => {
    return this.state.balances.map(balance => (
      <StripeBalanceCard
        key={`stripe-balance-${balance.stripe_connect_account.id}`}
        orgBalance={balance}
        loadingBalance={balance.loading}
        currency={this.props.organization.currency}
      />
    ));
  };

  loadingBalances = () => (
    <div>
      <Loading type="tail_spin" width={50} height={50} fill="#00CC99" />
    </div>
  );

  render() {
    if (this.state.loading) return this.loadingBalances();
    const itemList = this.stripeCardsList();

    return <ListCarousel itemList={itemList} />;
  }
}
