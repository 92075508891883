import React from 'react';
import Slider from 'react-slick';

export default class ListCarousel extends React.Component {
  render() {
    const defaultSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 975,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };

    return (
      <Slider {...defaultSettings} {...this.props.settings}>
        {this.props.itemList}
      </Slider>
    );
  }
}
