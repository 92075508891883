import React from 'react';
import moment from 'moment';
import { Pill, Box, TextDense } from '@directsoftware/ui-kit-web-admin';

const renderDateString = (startDateString, endDateString) => {
  const startDate = moment(startDateString, 'YYYY-MM-DD');
  const endDate = moment(endDateString, 'YYYY-MM-DD');
  const checkInDay = startDate.format('D');
  const checkInMonth = startDate.format('MMM');
  const checkInYear = startDate.format('YYYY');
  const checkOutDay = endDate.format('D');
  const checkOutMonth = endDate.format('MMM');
  const checkOutYear = endDate.format('YYYY');

  let dateString = `${checkInMonth} ${checkInDay}`;

  if (checkInYear !== checkOutYear) {
    dateString += `, ${checkInYear} - ${checkOutMonth} ${checkOutDay}, ${checkOutYear}`;
  } else if (checkInMonth !== checkOutMonth) {
    dateString += ` - ${checkOutMonth} ${checkOutDay}, ${checkOutYear}`;
  } else {
    dateString += ` - ${checkOutDay}, ${checkOutYear}`;
  }

  return dateString;
};

const renderPaymentStatusPill = (paidInFull, totalPaid) => {
  const paidValueColor = 'green';
  const unpaidValueColor = 'yellow';
  const partialPaymentValueColor = 'yellow';

  let color = '';
  let text = '';
  if (paidInFull) {
    color = paidValueColor;
    text = 'Paid';
  } else if (parseFloat(totalPaid.replace('$', '')) === 0) {
    color = unpaidValueColor;
    text = 'Unpaid';
  } else {
    color = partialPaymentValueColor;
    text = 'Partial Payment';
  }
  return <Pill color={color}>{text}</Pill>;
};

const vehicleRentalStatusColor = status => {
  const startStatuses = [
    'We drop off',
    'Stationary: renter arrives',
    'Renter pick up'
  ];
  const endStatuses = [
    'We pick up',
    'Stationary: renter departs',
    'Renter drop off'
  ];
  if (startStatuses.includes(status)) {
    return 'purple';
  } else if (endStatuses.includes(status)) {
    return 'green';
  } else {
    return 'gray';
  }
};

const renderVehicleAddOns = addOns => {
  return addOns.map((addOn, index) => (
    <Box key={index}>
      <TextDense>{addOn}</TextDense>
    </Box>
  ));
};

export {
  renderDateString,
  renderPaymentStatusPill,
  vehicleRentalStatusColor,
  renderVehicleAddOns
};
