import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  box-shadow: 2px 2px 5px -2px rgba(194, 194, 194, 1);
  padding: 24px;
  margin-bottom: 16px;
  cursor: pointer;
`;

const MessageContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  justify-content: ${props => props.justifyPosition};
`;

const Message = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #f1f3f6;
  box-shadow: 2px 2px 5px -2px rgba(194, 194, 194, 1);
  padding: 25px;
  max-width: 65%;
`;

const TextBgOverride = styled.div`
  * {
    background-color: transparent !important;
  }
`;

export { Card, MessageContainer, Message, TextBgOverride };
