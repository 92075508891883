const siteLanguageOptions = [
  { code: "en", name: "English", nativeName: "English" },
  { code: "ar", name: "Arabic", nativeName: "\u0627\u0644\u0639\u0631\u0628\u064A\u0629" },
  { code: "bn", name: "Bengali", nativeName: "\u09AC\u09BE\u0982\u09B2\u09BE" },
  {
    code: "zh",
    name: "Chinese",
    nativeName: "\u4E2D\u6587 (Zh\u014Dngw\xE9n), \u6C49\u8BED, \u6F22\u8A9E"
  },
  { code: "cs", name: "Czech", nativeName: "\u010Cesky, \u010Ce\u0161tina" },
  { code: "da", name: "Danish", nativeName: "Dansk" },
  { code: "nl", name: "Dutch", nativeName: "Nederlands, Vlaams" },
  { code: "et", name: "Estonian", nativeName: "Eesti, Eesti keel" },
  { code: "fr", name: "French", nativeName: "Fran\xE7ais" },
  { code: "de", name: "German", nativeName: "Deutsch" },
  { code: "el", name: "Greek, Modern", nativeName: "\u0395\u03BB\u03BB\u03B7\u03BD\u03B9\u03BA\u03AC" },
  { code: "hi", name: "Hindi", nativeName: "\u0939\u093F\u0928\u094D\u0926\u0940, \u0939\u093F\u0902\u0926\u0940" },
  { code: "hu", name: "Hungarian", nativeName: "Magyar" },
  { code: "id", name: "Indonesian", nativeName: "Bahasa Indonesia" },
  { code: "it", name: "Italian", nativeName: "Italiano" },
  {
    code: "ja",
    name: "Japanese",
    nativeName: "\u65E5\u672C\u8A9E (\u306B\u307B\u3093\u3054\uFF0F\u306B\u3063\u307D\u3093\u3054)"
  },
  { code: "jv", name: "Javanese", nativeName: "basa Jawa" },
  {
    code: "ko",
    name: "Korean",
    nativeName: "\uD55C\uAD6D\uC5B4 (\u97D3\u570B\u8A9E), \uC870\uC120\uB9D0 (\u671D\u9BAE\u8A9E)"
  },
  { code: "nb", name: "Norwegian Bokm\xE5l", nativeName: "Norsk bokm\xE5l" },
  { code: "pa", name: "Panjabi, Punjabi", nativeName: "\u0A2A\u0A70\u0A1C\u0A3E\u0A2C\u0A40, \u067E\u0646\u062C\u0627\u0628\u06CC\u200E" },
  { code: "pl", name: "Polish", nativeName: "polski" },
  { code: "pt", name: "Portuguese", nativeName: "Portugu\xEAs" },
  { code: "ru", name: "Russian", nativeName: "P\u0443\u0441\u0441\u043A\u0438\u0439 \u044F\u0437\u044B\u043A" },
  {
    code: "es",
    name: "Spanish; Castilian",
    nativeName: "Espa\xF1ol, Castellano"
  },
  { code: "sv", name: "Swedish", nativeName: "svenska" },
  { code: "th", name: "Thai", nativeName: "\u0E44\u0E17\u0E22" },
  { code: "tr", name: "Turkish", nativeName: "T\xFCrk\xE7e" },
  { code: "vi", name: "Vietnamese", nativeName: "Ti\u1EBFng Vi\u1EC7t" }
];
export { siteLanguageOptions };
