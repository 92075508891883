import React from 'react';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const HelpIconLink = () => {
  return (
    <Navbar.IconLink
      href="https://help.getdirect.io/en/"
      iconName="questionCircle"
      openInNewTab
      type="link"
    />
  );
};

export default HelpIconLink;
