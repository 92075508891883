import React from 'react';

const lineItemDifference = (oldLineItem, newLineItem) => {
  oldLineItem
    ? oldLineItem
    : (oldLineItem = { total_cents: newLineItem.total_cents * 2 });
  return newLineItem.total_cents - oldLineItem.total_cents;
};

const color = (oldLineItem, newLineItem) => {
  return lineItemDifference(oldLineItem, newLineItem) < 0
    ? '#FB5872'
    : '#50E3C2';
};

const operator = (oldLineItem, newLineItem) => {
  return lineItemDifference(oldLineItem, newLineItem) < 0 ? '-' : '+';
};

const floatToString = value => {
  return parseFloat(Math.abs(value) / 100.0)
    .toFixed(2)
    .toString();
};

const itemDisplayRate = item => {
  if (!item.rate) return null;

  return typeof item.rate === 'string'
    ? item.rate
    : `${floatToString(item.rate * 100.0)}%`;
};

const lineItemLabel = item => {
  const rate = itemDisplayRate(item);
  const name =
    item.additional_data && item.additional_data.display_name
      ? item.additional_data.display_name
      : item.name;

  return rate !== null ? `${name} (${rate})` : name;
};

const LineItemWithDifferences = props => {
  const oldLineItem = props.oldLineItem;
  const newLineItem = props.newLineItem;
  const currency = props.currency || 'usd';
  const difference = lineItemDifference(oldLineItem, newLineItem);

  if (difference === 0 && !props.alwaysShow) {
    return null;
  }

  return (
    <tr>
      <td style={props.subLineItem && { paddingLeft: '30px' }}>
        {props.lineItemLabel || lineItemLabel(newLineItem)}
      </td>
      <td
        style={{
          textAlign: 'right',
          width: '150px',
          color: color(oldLineItem, newLineItem)
        }}
      >
        <small className="currency-meta">{currency}</small>{' '}
        {operator(oldLineItem, newLineItem)}
        {floatToString(difference)}
      </td>
      <td style={{ textAlign: 'right', width: '150px' }}>
        <small className="currency-meta">{currency}</small>{' '}
        {floatToString(newLineItem.total_cents)}
      </td>
    </tr>
  );
};

export default LineItemWithDifferences;
