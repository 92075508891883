import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { FlexBox, Box, TextBody } from '@directsoftware/ui-kit-web-admin';

const TwoColumnListTable = ({ listItems, displayAs }) => {
  return (
    <FlexBox
      renderAs={displayAs === 'table' ? 'ul' : 'div'}
      flexDirection="column"
      className={
        displayAs === 'table'
          ? 'two-col-list-table'
          : 'two-col-list-table list-view'
      }
    >
      {listItems &&
        listItems.length > 0 &&
        listItems.map((item, idx) =>
          displayAs === 'list' ? (
            <FlexBox
              key={`list-item-${idx}`}
              className="two-col-list-table__listItem"
              flexDirection="column"
            >
              <TextBody weight="bold" className="two-col-list-table__label">
                {item.label}
              </TextBody>
              <Box>{ReactHtmlParser(item.value)}</Box>
            </FlexBox>
          ) : (
            <FlexBox
              key={`list-item-${idx}`}
              justifyContent="space-between"
              renderAs="li"
            >
              <Box>{item.label}</Box>
              <Box>
                <TextBody weight="semibold">{item.value}</TextBody>
              </Box>
            </FlexBox>
          )
        )}
    </FlexBox>
  );
};

TwoColumnListTable.propTypes = {
  listItems: PropTypes.array,
  displayAs: PropTypes.oneOf(['table', 'list'])
};

TwoColumnListTable.defaultProps = {
  displayAs: 'table'
};

export default TwoColumnListTable;
