import React from 'react';
import { ajaxDataWithAuthorization } from 'apiClient';
import PortalModal from 'sharedComponents/PortalModal';
import MentionsTextBox from 'adminComponents/mentions-textbox';
import debounce from 'lodash/debounce';
import { UserService } from 'adminApi';
import { Spinner } from 'adminComponents';
import {
  CallToActionButton,
  IconFontAwesome
} from '@directsoftware/ui-kit-web-admin';
import { dispatchError } from '../../../components/error-alert';

export default class ManagePropertyNewNote extends React.Component {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      mentioned: false,
      mentionees: null,
      textareaValue: '',
      users: [],
      sending: false
    };
  }

  componentDidMount = () => {
    UserService.list(this.props.organization.id)
      .then(response => {
        this.setState({ users: response.mention_users });
      })
      .catch(err =>
        displayError({ message: 'Error fetching employees', autoClose: 5000 })
      );
  };

  addMentionee = debounce((mentions, value) => {
    this.setState({ mentionees: mentions, textareaValue: value });
  }, 500);

  createNewNote = (e, closePortal) => {
    e.preventDefault();
    this.setState({ sending: true });
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'POST',
        url: `/api/${this.props.organization.id}/notes`,
        context: this,
        dataType: 'json',
        data: {
          notable_type: 'Property',
          message: this.state.textareaValue,
          notable_id: this.props.property.id,
          mentionees: this.state.mentionees
        }
      })
    )
      .done(function(data) {
        this.props.fetchNotes();
        closePortal();
      })
      .fail(function(jqXhr) {
        dispatchError();
        console.error(jqXhr);
      });
  };

  render() {
    return (
      <PortalModal
        defaultOpen={this.props.defaultOpen}
        openByClickOn={openPortal => (
          <>
            {!this.props.hideOpenButton && (
              <CallToActionButton
                appendIcon={<IconFontAwesome name="plus" />}
                onClick={openPortal}
              >
                Add a Note
              </CallToActionButton>
            )}
          </>
        )}
        header="New note"
        submitAction={closePortal =>
          !this.state.sending && (
            <CallToActionButton
              onClick={e => this.createNewNote(e, closePortal)}
            >
              Save
            </CallToActionButton>
          )
        }
        disableCloseOnOutsideClick
        closeOnSubmit
      >
        {this.state.sending ? (
          <Spinner />
        ) : (
          <MentionsTextBox
            organization={this.props.organization}
            addMentionee={this.addMentionee}
            users={this.state.users}
          />
        )}
      </PortalModal>
    );
  }
}
