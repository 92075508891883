import React from 'react';
import PropTypes from 'prop-types';

export default class ManageOrganizationFieldsLynnbrook extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <figure id="section-lynnbrook" className="consolidated-item-form-section">
        <header className="consolidated-item-header">
          <h3>Lynnbrook Settings</h3>
        </header>
        <section className="consolidated-item-form-viewing">
          <p>
            Your organization is connected to Lynnbrook for payment processing.
          </p>
          <p>
            Please contact{' '}
            <a href="mailto:help@getdirect.io">help@getdirect.io</a> if you
            would like to make changes to your Lynnbrook account details.
          </p>
        </section>
      </figure>
    );
  }
}
