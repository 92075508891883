import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SideTabBar } from '@directsoftware/ui-kit-web-admin';
import SideTabBarWaypoint from 'adminComponents/side-tab-bar-waypoint';
import { useDispatch, useSelector } from 'react-redux';
import PermissionComponent from 'PermissionComponent';
import { updateActiveRoutes } from '../../../redux/slices/navigation';
import {
  WEBSITE_NAV_TAGS,
  RVSHARE_CONTRACTS_NAV_TAGS
} from '../../../../shared/nav';

const WebsiteEditorSideTabs = props => {
  const dispatch = useDispatch();
  const { activeRoutes } = useSelector(state => state.navigation);
  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const isRvFleet = props.organization.organization_type === 'rv_fleet';
  const featureTier = props.organization.feature_tier;
  const hideSidebarOnContracts = props.location?.pathname.includes(
    'contracts/rental-agreement'
  );

  const setNavTags = tertiary => {
    if (isRvFleet && hideSidebarOnContracts) {
      dispatch(
        updateActiveRoutes({
          primary: RVSHARE_CONTRACTS_NAV_TAGS.PRIMARY
        })
      );
    } else {
      dispatch(
        updateActiveRoutes({
          primary: WEBSITE_NAV_TAGS.PRIMARY,
          secondary: WEBSITE_NAV_TAGS.secondary.SITE_EDITOR,
          tertiary
        })
      );
    }
  };

  useEffect(
    () => {
      setNavTags(props.tertiaryNavTagOnMount);
    },
    [props.tertiaryNavTagOnMount]
  );

  useEffect(() => {
    if (isRvFleet && hideSidebarOnContracts) {
      const sideBarContainer = document.getElementsByClassName(
        'side-tab-bar-left'
      );
      sideBarContainer[0].classList.add('display-none');
    }

    return () => {
      if (isRvFleet && hideSidebarOnContracts) {
        const sideBarContainer = document.getElementsByClassName(
          'side-tab-bar-left'
        );
        sideBarContainer[0].classList.remove('display-none');
      }
    };
  }, []);

  if (isRvFleet && hideSidebarOnContracts) return null;

  return (
    <SideTabBarWaypoint indicatorPosition={indicatorPosition}>
      <SideTabBar.Tab
        href={`/brands/${props.brandId}`}
        isActive={activeRoutes.tertiary === WEBSITE_NAV_TAGS.tertiary.OVERVIEW}
        setIndicatorPosition={position => setIndicatorPosition(position)}
        onClick={() => {
          setNavTags(WEBSITE_NAV_TAGS.tertiary.OVERVIEW);
        }}
      >
        Site Info
      </SideTabBar.Tab>
      <PermissionComponent user={props.user} permission="site_editor_pages">
        <SideTabBar.Tab
          href={`/brands/${props.brandId}/pages`}
          isActive={activeRoutes.tertiary === WEBSITE_NAV_TAGS.tertiary.PAGES}
          setIndicatorPosition={position => setIndicatorPosition(position)}
          onClick={() => {
            setNavTags(WEBSITE_NAV_TAGS.tertiary.PAGES);
          }}
        >
          Pages
        </SideTabBar.Tab>
      </PermissionComponent>
      <PermissionComponent user={props.user} permission="site_editor_customize">
        <SideTabBar.Tab
          href={`/brands/${props.brandId}/customize`}
          isActive={activeRoutes.tertiary === WEBSITE_NAV_TAGS.tertiary.CMS}
          setIndicatorPosition={position => setIndicatorPosition(position)}
          onClick={() => {
            setNavTags(WEBSITE_NAV_TAGS.tertiary.CMS);
          }}
        >
          Settings
        </SideTabBar.Tab>
      </PermissionComponent>
      <PermissionComponent
        user={props.user}
        permission="site_editor_site_navigation"
      >
        <SideTabBar.Tab
          href={`/brands/${props.brandId}/menus`}
          isActive={
            activeRoutes.tertiary === WEBSITE_NAV_TAGS.tertiary.NAVIGATION
          }
          setIndicatorPosition={position => setIndicatorPosition(position)}
          onClick={() => {
            setNavTags(WEBSITE_NAV_TAGS.tertiary.NAVIGATION);
          }}
        >
          Navigation
        </SideTabBar.Tab>
      </PermissionComponent>
      <PermissionComponent
        user={props.user}
        permission="site_editor_contact_info"
      >
        <SideTabBar.Tab
          href={`/brands/${props.brandId}/contact`}
          isActive={
            activeRoutes.tertiary === WEBSITE_NAV_TAGS.tertiary.CONTACT_INFO
          }
          setIndicatorPosition={position => setIndicatorPosition(position)}
          onClick={() => {
            setNavTags(WEBSITE_NAV_TAGS.tertiary.CONTACT_INFO);
          }}
        >
          Contact Info
        </SideTabBar.Tab>
      </PermissionComponent>
      {!props.isV2Brand && (
        <>
          <PermissionComponent
            user={props.user}
            permission="site_editor_customize"
          >
            <SideTabBar.Tab
              href={`/brands/${props.brandId}/tracking`}
              isActive={
                activeRoutes.tertiary === WEBSITE_NAV_TAGS.tertiary.ANALYTICS
              }
              setIndicatorPosition={position => setIndicatorPosition(position)}
              onClick={() => {
                setNavTags(WEBSITE_NAV_TAGS.tertiary.ANALYTICS);
              }}
            >
              Analytics & Tracking
            </SideTabBar.Tab>
          </PermissionComponent>
          <PermissionComponent
            user={props.user}
            permission="site_editor_contracts"
          >
            {!isRvFleet && (
              <SideTabBar.Tab
                href={`/brands/${props.brandId}/contracts/rental-agreement`}
                isActive={
                  activeRoutes.tertiary ===
                  WEBSITE_NAV_TAGS.tertiary.RENTAL_AGREEMENT
                }
                setIndicatorPosition={position =>
                  setIndicatorPosition(position)
                }
                onClick={() => {
                  setNavTags(WEBSITE_NAV_TAGS.tertiary.RENTAL_AGREEMENT);
                }}
              >
                Rental Agreement
              </SideTabBar.Tab>
            )}
            <SideTabBar.Tab
              href={`/brands/${props.brandId}/contracts/id-verification`}
              isActive={
                activeRoutes.tertiary ===
                WEBSITE_NAV_TAGS.tertiary.ID_VERIFICATION
              }
              setIndicatorPosition={position => setIndicatorPosition(position)}
              onClick={() => {
                setNavTags(WEBSITE_NAV_TAGS.tertiary.ID_VERIFICATION);
              }}
            >
              ID Verification
            </SideTabBar.Tab>
            <SideTabBar.Tab
              href={`/brands/${props.brandId}/contracts/external`}
              isActive={
                activeRoutes.tertiary ===
                WEBSITE_NAV_TAGS.tertiary.EXTERNAL_CONTRACTS
              }
              setIndicatorPosition={position => setIndicatorPosition(position)}
              onClick={() => {
                setNavTags(WEBSITE_NAV_TAGS.tertiary.EXTERNAL_CONTRACTS);
              }}
            >
              External Contracts
            </SideTabBar.Tab>
          </PermissionComponent>
          <SideTabBar.Tab
            href={`/dashboard/${props.organization.subdomain}/brands/${
              props.brandId
            }/listings_new`}
            isActive={
              activeRoutes.tertiary === WEBSITE_NAV_TAGS.tertiary.ADD_LISTINGS
            }
            setIndicatorPosition={position => setIndicatorPosition(position)}
            onClick={() => {
              setNavTags(WEBSITE_NAV_TAGS.tertiary.ADD_LISTINGS);
            }}
            linkType="link"
          >
            Add Listings
          </SideTabBar.Tab>
          <SideTabBar.Tab
            href={`/brands/${props.brandId}/url-redirects`}
            isActive={
              activeRoutes.tertiary === WEBSITE_NAV_TAGS.tertiary.URL_REDIRECTS
            }
            setIndicatorPosition={position => setIndicatorPosition(position)}
            onClick={() => {
              setNavTags(WEBSITE_NAV_TAGS.tertiary.URL_REDIRECTS);
            }}
          >
            URL Redirects
          </SideTabBar.Tab>
        </>
      )}
    </SideTabBarWaypoint>
  );
};

WebsiteEditorSideTabs.propTypes = {
  tertiaryNavTagOnMount: PropTypes.string
};

export default WebsiteEditorSideTabs;
