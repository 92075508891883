import React from 'react';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';

import LineItemDifferencesTable from './LineItemDifferencesTable';
import LineItemWithDifferences from './LineItemWithDifferences';

const EditedBookingNetDetailForm = props => {
  const subLineItems = liType => {
    const newLis = filter(
      props.editedBookingNetDetail.line_items,
      li => li.item_type === liType
    );

    return map(newLis, newLi => {
      const oldLi = find(
        props.originalBookingNetDetail.line_items,
        oldLi => oldLi.id === newLi.id
      ) || { total_cents: 0 }; // Need this check for newly created items that need to be edited. This results in not the correct difference showing, but can be fixed later
      return oldLi.total_cents === 0 ? null : (
        <LineItemWithDifferences
          key={`edited-booking-net-detail-form-${oldLi.id}-${newLi.id}`}
          currency={props.currency}
          oldLineItem={oldLi}
          newLineItem={newLi}
          subLineItem
        />
      );
    });
  };

  const totalLineItem = (attrKey, name) => {
    const oldLi = {
      total_cents: props.originalBookingNetDetail[attrKey]
    };

    const newLi = {
      total_cents: props.editedBookingNetDetail[attrKey],
      name: name
    };

    return (
      <LineItemWithDifferences
        currency={props.currency}
        oldLineItem={oldLi}
        newLineItem={newLi}
        alwaysShow={
          attrKey === 'final_net' || attrKey === 'booking_total_cents'
        }
      />
    );
  };

  return (
    <LineItemDifferencesTable
      label={'Payout Information Line Items This Change Will Affect:'}
    >
      {totalLineItem('booking_total_cents', 'START: BOOKING TOTAL')}
      {totalLineItem('payouts_cents', 'TOTAL PAYOUTS YOU NEED TO MAKE')}
      {subLineItems('payouts')}
      {totalLineItem('taxes_cents', 'TOTAL TAXES')}
      {subLineItems('taxes')}
      {totalLineItem('final_net_cents', 'FINAL NET TO YOU')}
      {subLineItems('final_net')}
    </LineItemDifferencesTable>
  );
};

export default EditedBookingNetDetailForm;
