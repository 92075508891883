import React from 'react';
import PropTypes from 'prop-types';

import ManageOrganizationDelete from '../molecules/manage-organization-delete';
import ManageOrganizationLock from '../molecules/manage-organization-lock';
import ManageOrganizationFieldsBasic from '../molecules/manage-organization-fields-basic';
import ManageOrganizationFieldsBusiness from '../molecules/manage-organization-fields-business';
import ManageOrganizationFieldsLocation from '../molecules/manage-organization-fields-location';
import ManageOrganizationFieldsStripeConnect from '../molecules/manage-organization-fields-stripe-connect';
import ManageOrganizationFieldsLynnbrook from '../molecules/manage-organization-fields-lynnbrook';

export default class ManageOrganization extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    organization: PropTypes.object.isRequired,
    stripe_connect_account: PropTypes.object
  };

  constructor(props, _railsContext) {
    super(props);
  }

  render() {
    return (
      <section>
        <section className="consolidated-manager">
          <ManageOrganizationFieldsBasic
            organization={{...this.props.organization, brands: this.props.brands}}
          />

          <ManageOrganizationFieldsBusiness
            organization={this.props.organization}
          />

          <ManageOrganizationFieldsLocation
            organization={this.props.organization}
            location={this.props.direct_location}
          />
          {this.props.organization.payment_processor === 'stripe' && (
            <ManageOrganizationFieldsStripeConnect
              organization={this.props.organization}
              country_iso_2={this.props.direct_location.country_iso_2}
              first_name={this.props.user.first_name}
              last_name={this.props.user.last_name}
              location={this.props.direct_location}
              stripe_connect_account={this.props.stripe_connect_account}
              stripe_connect_key={this.props.stripe_connect_key}
              user={this.props.user}
            />
          )}
          {this.props.organization.payment_processor === 'lynnbrook' && (
            <ManageOrganizationFieldsLynnbrook
              organization={this.props.organization}
            />
          )}
          {this.props.user.admin === true && (
            <>
              <ManageOrganizationLock organization={this.props.organization} />
              <ManageOrganizationDelete
                organization={this.props.organization}
              />
            </>
          )}
        </section>
      </section>
    );
  }
}
