// Dependencies
// -----------------------------------------------
import React from 'react';

// Components
// -----------------------------------------------
import { InputLimiter } from 'adminComponents';
import { InputRichTextarea } from '@directsoftware/ui-kit-web-admin';

// -----------------------------------------------
// COMPONENT->OVERVIEW-SINGLE-FIELDS-DESCRIPTION -
// -----------------------------------------------
const OverviewSingleFieldsDescription = props => {
  const onSimpleChange = e => {
    e.preventDefault();
    props.onSimpleChange(e.target.name, e.target.value);
  };

  const onWysiwygChange = (name, value) => {
    props.onSimpleChange(name, value);
  };

  // ---------------------------------------------
  // RETURN --------------------------------------
  // ---------------------------------------------
  return (
    <fieldset>
      <header className="fieldset-header">
        <legend>Property Description</legend>
        <p>Tell us more about your property.</p>
      </header>

      <label htmlFor="headline">
        <span>Short summary</span>
        <InputLimiter
          used={props.summary_headline ? props.summary_headline.length : 0}
          total={140}
        />
      </label>
      <input
        type="text"
        name="summary_headline"
        className="magnified"
        placeholder="My Property"
        maxLength="140"
        value={props.summary_headline}
        onChange={e => onSimpleChange(e)}
      />
      <span
        className={`distribution-label ${
          props.summary_headline && props.summary_headline.length > 19
            ? `positive`
            : `negative`
        }`}
      >
        Summary must be <b>20</b> characters or longer to be eligible for
        distribution.
      </span>
      <label htmlFor="headline">
        <span>Airbnb Headline</span>
        <InputLimiter
          used={props.summary_headline ? props.summary_headline.length : 0}
          total={80}
        />
      </label>
      <input
        type="text"
        name="airbnb_headline"
        className="magnified"
        placeholder="My Property"
        maxLength="140"
        value={props.airbnb_headline}
        onChange={e => onSimpleChange(e)}
      />
      <span
        className={`distribution-label ${
          props.airbnb_headline && props.airbnb_headline.length > 7
            ? `positive`
            : `negative`
        }`}
      >
        Headline must be <b>8</b> characters or longer to be eligible for
        distribution on airbnb.
      </span>
      <label htmlFor="description">
        <span>Property Description</span>
      </label>
      <InputRichTextarea
        onChange={(html, text) => {
          onWysiwygChange('summary_description', html);
          onWysiwygChange('summary_description_plain_text', text);
        }}
        value={props.summary_description}
      />
      <span
        className={`distribution-label ${
          props.summary_description && props.summary_description.length > 399
            ? `positive`
            : `negative`
        }`}
      >
        Description must be <b>400</b> characters or longer to be eligible for
        distribution.
      </span>
    </fieldset>
  );
};

// -----------------------------------------------
// EXPORT ----------------------------------------
// -----------------------------------------------
export default OverviewSingleFieldsDescription;
