// Dependencies
// -----------------------------------------------
import React from 'react';

import { IconFontAwesome } from '@directsoftware/ui-kit-web-admin';

const Envelope = props => (
  <IconFontAwesome name="envelope" iconStyle="regular" iconProps={props} />
);

const Certificate = props => (
  <IconFontAwesome name="certificate" iconProps={props} />
);

const EllipsisH = props => (
  <IconFontAwesome name="ellipsisH" iconProps={props} />
);
const ExclamationCircle = props => (
  <IconFontAwesome name="exclamationCircle" iconProps={props} />
);
const Delete = props => <IconFontAwesome name="trash" iconProps={props} />;

const Search = props => <IconFontAwesome name="search" iconProps={props} />;

const Stripe = props => <IconFontAwesome name="stripe" iconProps={props} />;

const CheckCircle = props => (
  <IconFontAwesome name="checkCircle" iconProps={props} />
);

const TimesCircle = props => (
  <IconFontAwesome name="timesCircle" iconProps={props} />
);

const RegularTimesCircle = props => (
  <IconFontAwesome name="timesCircle" iconStyle="regular" iconProps={props} />
);

const Bank = props => <IconFontAwesome name="university" iconProps={props} />;

const KebabMenuIcon = props => (
  <IconFontAwesome name="ellipsisV" iconProps={props} />
);

const InfoCircle = props => (
  <IconFontAwesome name="infoCircle" iconProps={props} />
);

const ChevronDown = props => (
  <IconFontAwesome name="chevronDown" iconProps={props} />
);

const ChevronUp = props => (
  <IconFontAwesome name="chevronUp" iconProps={props} />
);

const ChevronRight = props => (
  <IconFontAwesome name="chevronRight" iconProps={props} />
);

const Times = props => <IconFontAwesome name="times" iconProps={props} />;

const CodeIcon = props => <IconFontAwesome name="code" iconProps={props} />;

const Star = props => <IconFontAwesome name="star" iconProps={props} />;

const EmptyStar = props => (
  <IconFontAwesome name="star" iconStyle="regular" iconProps={props} />
);

export {
  Envelope,
  Certificate,
  EllipsisH,
  ExclamationCircle,
  Delete,
  Search,
  Stripe,
  CheckCircle,
  TimesCircle,
  RegularTimesCircle,
  Bank,
  KebabMenuIcon,
  InfoCircle,
  ChevronDown,
  ChevronUp,
  ChevronRight,
  Times,
  CodeIcon,
  Star,
  EmptyStar
};
