import request from "apiClient";
export default class BulkEditService {
  static update(organizationId, propertiesListIds, data = {}, activeFeature) {
    return request({
      url: `/api/${organizationId}/bulk_edit?properties_list_ids=${propertiesListIds}&active_feature=${activeFeature}`,
      method: "PATCH",
      data
    });
  }
}
