import React from 'react';
import styled from 'styled-components';

import { InputIncrementer } from 'adminComponents';

const StyledTextInput = styled.input`
  &[type='text'],
  &[type='email'],
  &[type='tel'] {
    border-radius: 4px;
    border-color: #cccccc;
    font-size: 16px;
    padding: 8px 10px;
  }
`;

const IncrementerContainer = styled.div`
  .input-incrementer {
    input[type='text'].magnified {
      border-radius: 4px;
      border-color: #cccccc;
      padding: 8px 10px;
    }

    .input-incrementer-buttons {
      height: 41px;
      right: 2px;

      a {
        width: 41px;
        font-size: 16px;
        line-height: 39px;
      }
    }
  }
`;

const CustomerFields = props => {
  const {
    customer_name,
    customer_email,
    customer_telephone,
    guests,
    onChange
  } = props;

  return (
    <div style={{ marginBottom: '16px' }}>
      <section className="two-col two-col-50-50">
        <figure className="field-customer-name">
          <label htmlFor="customer_name">
            <span>Full name</span>
          </label>
          <StyledTextInput
            type="text"
            name="customer_name"
            onChange={e => onChange(e.target.name, e.target.value)}
            placeholder="Jane Smith"
            value={customer_name}
            required
          />
        </figure>
        <figure className="field-customer-email">
          <label htmlFor="customer_email">
            <span>Email</span>
          </label>
          <StyledTextInput
            type="email"
            name="customer_email"
            onChange={e => onChange(e.target.name, e.target.value)}
            placeholder="name@email.com"
            value={customer_email}
            required
          />
        </figure>
      </section>
      <section className="two-col two-col-50-50">
        <figure className="field-customer-telephone">
          <label htmlFor="customer_telephone">
            <span>Telephone number</span>
          </label>
          <StyledTextInput
            type="tel"
            name="customer_telephone"
            onChange={e => onChange(e.target.name, e.target.value)}
            placeholder="+1 (123) 123-1234"
            maxLength={20}
            value={customer_telephone}
            required
          />
        </figure>
        <figure className="field-guests">
          <label htmlFor="guests">
            <span>Number of guests</span>
          </label>
          <IncrementerContainer>
            <InputIncrementer
              name="guests"
              min="1"
              max="48"
              value={guests}
              callbackIncrement={value => onChange('guests', value)}
              callbackDecrement={value => onChange('guests', value)}
            />
          </IncrementerContainer>
        </figure>
      </section>
    </div>
  );
};

export default CustomerFields;
