import styled from 'styled-components';

const QuoteTable = styled.table`
  margin: 16px 0 !important;
`;

const QuoteRow = styled.tr`
  td {
    padding: 5.656px !important;

    &.quote-sub-item {
      padding-left: 24px !important;
    }
  }

  ${props =>
    props.editable
      ? `
    cursor: pointer;

    &:hover {
      background-color: rgba(222, 235, 255, .53);
    }
  `
      : ''}

  ${props =>
    props.selected
      ? `
    background-color: rgba(222, 235, 255, .53);
  `
      : ''}
`;

export { QuoteTable, QuoteRow };
