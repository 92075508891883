import React from 'react';
import find from 'lodash/find';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';

const options = [
  { value: '', label: 'All Statuses' },
  { value: 'full', label: 'Paid in full' },
  { value: 'partial', label: 'Partial payment' },
  { value: 'not_paid', label: 'No payment' }
];

const PaymentStatusFilter = props => (
  <figure style={{ flex: `1 1 auto`, marginRight: '5px' }}>
    <label htmlFor="payment_status">Payment Status</label>
    <InputSelect
      name="payment_status"
      className="magnified"
      options={options}
      isClearable={false}
      isSearchable={false}
      placeholder="All Statuses"
      onChange={option => props.updateFilter('payment_status', option.value)}
      value={find(options, { value: props.payment_status })}
    />
  </figure>
);

export default PaymentStatusFilter;
