import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  ConfirmDialog,
  Divider,
  Dropdown,
  IconFontAwesome,
  ListItemLink,
  PageHeader
} from '@directsoftware/ui-kit-web-admin';
import { BrandService } from 'adminApi';
import { ajaxDataWithAuthorization } from 'apiClient';
import { dispatchError } from 'adminComponents';
import moment from 'moment';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { WEBSITE_NAV_TAGS } from '../../../../../shared/nav';
import { selectWebsiteCMSState } from '../../../../redux/slices/websiteCMS';

const SiteEditorEdit = props => {
  const dispatch = useDispatch();
  const { cmsVersion } = useSelector(selectWebsiteCMSState);
  const [siteName, setSiteName] = useState('');
  const isRvFleet = props.organization.organization_type === 'rv_fleet';
  const isContracts = props.location?.pathname.includes(
    'contracts/rental-agreement'
  );
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const deleteBrand = () => {
    BrandService.destroy(props.organization.id, props.brandId)
      .then(data => {
        window.location = `/dashboard/${props.organization.subdomain}/brands`;
      })
      .catch(err => {
        dispatchError();
        console.error(err);
      });
  };

  const createNewPage = () => {
    const data = {
      title: `New page ${moment().format('YYYY-MM-DD hh:mma')}`
    };
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'POST',
        url: `/api/${props.organization.id}/brands/${props.brandId}/pages`,
        data
      })
    )
      .done(function(data) {
        const pageID = data.id;
        window.location = `/dashboard/${props.organization.subdomain}/brands/${
          props.brandId
        }/pages/${pageID}/edit`;
      })
      .fail(function(jqXhr) {
        dispatchError();
        console.error(jqXhr);
      });
  };

  useEffect(
    () => {
      BrandService.get(props.organization.id, props.brandId)
        .then(res => {
          setSiteName(res.brand.name);
        })
        .catch(err => console.warn(err));
    },
    [props]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: WEBSITE_NAV_TAGS.PRIMARY,
        secondary: WEBSITE_NAV_TAGS.secondary.SITE_EDITOR
      })
    );
  }, []);

  return isRvFleet && isContracts ? (
    <PageHeader>
      <PageHeader.Left>Contracts</PageHeader.Left>
    </PageHeader>
  ) : (
    <>
      <PageHeader
        height="l"
        containerVariation={cmsVersion === 'v2' ? 'fluid' : 'extended'}
      >
        <PageHeader.Left
          breadcrumbs={[
            {
              href: '/brands',
              label: 'Website List'
            },
            {
              label: siteName
            }
          ]}
        >
          {siteName}
        </PageHeader.Left>
        {cmsVersion === 'v2' && (
          <PageHeader.Right>
            <Dropdown
              alignPanel="bottomRight"
              controlLabel="Actions"
              panelContent={
                <>
                  <Box paddingHorizontal="xxs">
                    <ListItemLink
                      type="button"
                      label="Create a New Page"
                      onClick={() => createNewPage()}
                    />
                  </Box>
                  <Divider paddingTop="xxs" paddingBottom="xs" />
                  <Box paddingHorizontal="xxs">
                    <ListItemLink
                      type="button"
                      label="Delete Website"
                      isDanger
                      onClick={() => setShowDeleteConfirmation(true)}
                      icon={<IconFontAwesome name="trashAlt" />}
                    />
                  </Box>
                </>
              }
            />
          </PageHeader.Right>
        )}
      </PageHeader>
      <ConfirmDialog
        reveal={showDeleteConfirmation}
        title="Delete This Website"
        description={`Are you sure you want to delete ${
          props?.brand?.name
        }? This will delete all of this brand's pages, and may cause errors with its archived listings.`}
        closeOnClick={() => setShowDeleteConfirmation(false)}
        cancelOnClick={() => setShowDeleteConfirmation(false)}
        confirmOnClick={() => deleteBrand()}
        confirmLabel="Delete Website"
        isDanger
        isDestructiveAction
      />
    </>
  );
};

export default SiteEditorEdit;
