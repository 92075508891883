import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import FeeAccountService from 'adminApi/FeeAccountService';
import displayError from 'sharedComponents/ErrorDisplay';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';

const BulkSettingsFeeAccountDetails = props => {
  const dispatch = useDispatch();
  const [feeAccountName, setFeeAccountName] = useState('');
  const [isMounted, setIsMounted] = useState(false);

  useEffect(
    () => {
      FeeAccountService.fetch(props.organization.id, props.feeAccountId)
        .then(response => {
          setFeeAccountName(response.fee_account.name);
          setIsMounted(true);
        })
        .catch(err =>
          displayError({ message: 'Error loading fee account', err })
        );
    },
    [props]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: LISTINGS_NAV_TAGS.PRIMARY,
        secondary: LISTINGS_NAV_TAGS.secondary.FEE_ACCOUNTS
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/accounting/bulk-settings',
            label: 'Fee Account Manager'
          },
          {
            label: 'Fee Account'
          }
        ]}
      >
        {!isMounted ? (
          <span style={{ textIndent: -1000, display: 'block' }}>
            Not Mounted
          </span>
        ) : (
          <>{feeAccountName !== '' ? feeAccountName : '(No Name Given)'}</>
        )}
      </PageHeader.Left>
    </PageHeader>
  );
};

export default BulkSettingsFeeAccountDetails;
