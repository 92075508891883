const bulkEditorUi = {
  activeColumn: 0,
  isUpdating: false,
  propertyFeatureFilter: "single-unit"
};
const bulkEditorActiveFeature = {
  id: ""
};
const bulkEditorCandidateList = {
  candidates: [],
  total_count: 0,
  current_page: 1,
  queryParams: {
    filter_property_name: "",
    filter_property_min_num_beds: null,
    filter_property_min_num_baths: null,
    filter_property_min_num_guests: null,
    filter_property_max_num_beds: null,
    filter_property_max_num_baths: null,
    filter_property_max_num_guests: null,
    filter_property_check_in: null,
    filter_property_check_out: null,
    filter_property_portfolio_id: "",
    filter_property_subportfolio_id: "",
    filter_property_unit_group_id: ""
  },
  selectedCandidates: [],
  allCandidatesSelected: false
};
export { bulkEditorUi, bulkEditorActiveFeature, bulkEditorCandidateList };
