import request from "apiClient";
export default class MessageService {
  static index(organizationID, queryString) {
    return request({
      url: `/api/${organizationID}/messages${queryString}`,
      method: "GET"
    });
  }
  static create(organizationId, data = {}) {
    return request({
      url: `/api/${organizationId}/messages`,
      method: "POST",
      data
    });
  }
  static markAsRead(organizationId, messageId, data = {}) {
    return request({
      url: `/api/${organizationId}/messages/mark_as_read/${messageId}`,
      method: "PUT",
      data
    });
  }
  static markAsReadMany(organizationId, data = {}) {
    return request({
      url: `/api/${organizationId}/messages/mark_as_read_many`,
      method: "PUT",
      data
    });
  }
  static markAsReadAll(organizationId, data = {}) {
    return request({
      url: `/api/${organizationId}/messages/mark_as_read_all`,
      method: "PUT",
      data
    });
  }
}
