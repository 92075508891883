import React from 'react';
import { parseDate } from 'sharedUtils/date-helpers';
import {
  PictureFrame,
  Date,
  Status,
  ReviewCardHeader,
  FlexColumn,
  FlexRow,
  RightContainer,
  RatingContainer
} from './styles';
import Rater from 'react-rater';
export const ReviewCard = ({ review }) => {
  return (
    <div style={{ width: 'inherit' }}>
      <ReviewCardHeader>
        <FlexRow>
          <div style={{ marginRight: '10%' }}>
            <PictureFrame url={review.property_image_url} />
          </div>
          <FlexColumn>
            <div>{review.property_name}</div>
            <Date>
              {'Dates Stayed: ' +
                parseDate(review.check_in_date) +
                ' - ' +
                parseDate(review.check_out_date)}
            </Date>
          </FlexColumn>
        </FlexRow>
        <RightContainer>
          <img
            src={`/assets/admin/${review.logo_url}`}
            alt="res-logo"
            width="auto"
            style={{ height: 20 }}
            height="20px"
          />
          <Status status={review.status}>{review.status}</Status>
        </RightContainer>
      </ReviewCardHeader>
      <section>
        <RatingContainer>
          <Rater rating={review.rating} interactive={false} />
        </RatingContainer>
        <p>{review.title}</p>
        <br />
        <p>{review.body}</p>
        <Date>{'Created on ' + parseDate(review.reviewed_date)}</Date>
        {review.response && (
          <>
            <div>{'Responded on ' + parseDate(review.responded_at)}</div>
            <p>{review.response}</p>
          </>
        )}
      </section>
    </div>
  );
};
