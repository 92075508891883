import React from 'react';
import get from 'lodash/get';

import { OrganizationContext } from 'adminContexts';
import SelectService from 'adminApi/SelectService';
import InlineTip from 'adminComponents/inline-tip';
import {
  FormField,
  InputSelect,
  Label
} from '@directsoftware/ui-kit-web-admin';

// This component should only be used if you know your
// data options are roughly between 0-100. After that, this
// component gets pretty rough since it takes a long time to
// render client side.

// If you know you have options > 100, you should use the
// SearchableSelect component instead
export default class SimpleSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      loading: true
    };
  }

  componentDidMount() {
    SelectService.index(
      this.context.organizationId,
      this.props.selectData
    ).then(response => {
      const options = response.options.map(data => {
        return {
          label: this.customLabel(data),
          value: data[this.props.optionValue],
          data
        };
      });

      this.setState({ options, loading: false });
    });
  }

  customLabel = data => {
    const label = this.props.optionLabel;
    // The options are either string or function
    if (typeof label === 'string') {
      return data[this.props.optionLabel];
    }

    return label(data);
  };

  setDefaultValue = () => {
    const { options } = this.state;
    return this.props.defaultValue(options);
  };

  render() {
    if (this.state.loading) return null;

    const {
      label,
      name,
      placeholder,
      onSelect,
      tip,
      isSearchable,
      isDisabled,
      useDesignKit,
      inputWidth
    } = this.props;

    return useDesignKit ? (
      <FormField
        labelText={label}
        labelMarkRequiredField={this.props.required}
        labelHtmlFor={name}
        inputType="select"
        inputProps={{
          options: this.state.options,
          isSearchable,
          isClearable: true,
          onClear: option => onSelect(get(option, 'data', {})),
          value: this.setDefaultValue(),
          onChange: option => onSelect(get(option, 'data', {})),
          placeholder: this.props.placeholder,
          description: this.props.description,
          isDisabled,
          inputWidth
        }}
      />
    ) : (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <Label markRequiredField={this.props.required}>{label}</Label>
          {tip && <InlineTip tip={tip} alwaysFullscreen />}
        </div>
        <InputSelect
          name={name}
          placeholder={placeholder}
          options={this.state.options}
          searchable={isSearchable}
          isClearable
          onClear={option => onSelect(get(option, 'data', {}))}
          value={this.setDefaultValue()}
          onChange={option => onSelect(get(option, 'data', {}))}
          disabled={isDisabled}
        />
      </React.Fragment>
    );
  }
}

SimpleSelect.defaultProps = {
  label: 'Select',
  optionLabel: 'name',
  optionValue: 'id',
  placeholder: 'Select...',
  name: 'simple_select',
  selectData: {},
  defaultValue: _options => null,
  onSelect: _data => {},
  isSearchable: false,
  isDisabled: false
};

SimpleSelect.contextType = OrganizationContext;
