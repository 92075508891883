// Dependencies
// -----------------------------------------------
import React from 'react';
import { FormField } from '@directsoftware/ui-kit-web-admin';

// Components
// -----------------------------------------------
import {
  dispatchError,
  ConsolidatedItemEditToggleHeader
} from 'adminComponents';

// Styled Components
// -----------------------------------------------
import { UnitService } from 'adminApi';

// Services
// -----------------------------------------------

// -----------------------------------------------
// COMPONENT->MANAGE-SINGLE-FIELDS-INSTRUCTIONS --
// -----------------------------------------------
export default class ManageSingleFieldsEmergencyContact extends React.Component {
  // ---------------------------------------------
  // CONSTRUCTOR ---------------------------------
  // ---------------------------------------------
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      emergencyContact: {
        firstName: this.props.unit?.emergency_contact_first_name,
        lastName: this.props.unit?.emergency_contact_last_name,
        phone: this.props.unit?.emergency_contact_phone
      },
      emergencyContactCache: {
        firstName: this.props.unit?.emergency_contact_first_name,
        lastName: this.props.unit?.emergency_contact_last_name,
        phone: this.props.unit?.emergency_contact_phone
      },
      isDirty: false,
      isEditing: false,
      isSaving: false,
      isSaved: false
    };
  }

  onChange = (name, value) => {
    const contactChange = this.state.emergencyContact;
    contactChange[name] = value;

    this.setState({ isDirty: true, emergencyContact: contactChange });
  };

  toggleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  finishEditingAndRevert = () => {
    this.finishEditing(false);
  };

  finishEditingAndUpdate = () => {
    this.finishEditing(true);
  };

  finishEditing = saved => {
    const changeState = {
      isDirty: false,
      isEditing: false,
      isSaving: false,
      isSaved: saved
    };

    if (saved) {
      changeState.emergencyContactCache = this.state.emergencyContact;
    } else {
      changeState.emergencyContact = this.state.emergencyContactCache;
    }

    this.setState(changeState);
  };

  attemptSave = () => {
    this.setState({ isSaving: true, isSaved: false }, () => {
      UnitService.update(this.props.organization.id, this.props.unit.id, {
        emergency_contact_first_name: this.state.emergencyContact.firstName,
        emergency_contact_last_name: this.state.emergencyContact.lastName,
        emergency_contact_phone: this.state.emergencyContact.phone
      })
        .then(response => {
          this.finishEditing(true);
        })
        .catch(error => {
          dispatchError();
          console.warn(jqXhr);
          this.finishEditing(false);
        });
    });
  };

  renderEditing() {
    const contact = this.state.emergencyContact;
    return (
      <form className="consolidated-item-form-editing">
        <div className="consolidated-item-subsection">
          <FormField
            inputType="text"
            labelText="First Name"
            labelHtmlFor="firstName"
            inputProps={{
              id: 'firstName',
              name: 'firstName',
              onChange: e => this.onChange(e.target.name, e.target.value),
              value: contact.firstName,
              placeholder: 'First Name'
            }}
          />
          <FormField
            inputType="text"
            labelText="Last Name"
            labelHtmlFor="lastName"
            inputProps={{
              name: 'lastName',
              id: 'lastName',
              onChange: e => this.onChange(e.target.name, e.target.value),
              value: contact.lastName,
              placeholder: 'Last Name'
            }}
          />
          <FormField
            inputType="text"
            labelText="Phone"
            labelHtmlFor="phone"
            inputProps={{
              id: 'phone',
              name: 'phone',
              onChange: e => this.onChange(e.target.name, e.target.value),
              value: contact.phone,
              placeholder: 'Phone'
            }}
          />
        </div>
      </form>
    );
  }

  renderViewing() {
    const contact = this.state.emergencyContact;
    return (
      <section className="consolidated-item-form-viewing">
        <div className="consolidated-item-subsection">
          <h4>First Name</h4>
          {contact.firstName}
        </div>
        <div className="consolidated-item-subsection">
          <h4>Last Name</h4>
          {contact.lastName}
        </div>
        <div className="consolidated-item-subsection">
          <h4>Phone Number</h4>
          {contact.phone}
        </div>
      </section>
    );
  }

  // ---------------------------------------------
  // RENDER --------------------------------------
  // ---------------------------------------------
  render() {
    return (
      <figure
        className={`consolidated-item-form-section ${
          this.state.isEditing ? 'form-editing' : ''
        }`}
      >
        <ConsolidatedItemEditToggleHeader
          toggleEditing={this.toggleEditing}
          finishEditingAndRevert={this.finishEditingAndRevert}
          finishEditingAndUpdate={this.finishEditingAndUpdate}
          attemptSave={this.attemptSave}
          isDirty={this.state.isDirty}
          isEditing={this.state.isEditing}
          isSaving={this.state.isSaving}
          title="Emergency Contact Information"
          blockEdit={this.props.blockEdit}
        />
        {this.state.isEditing ? this.renderEditing() : this.renderViewing()}
      </figure>
    );
  }
}
