import request from "apiClient";
export default class ExpenseService {
  static update(organizationId, expenseId, data) {
    return request({
      url: `/api/${organizationId}/expenses/${expenseId}`,
      method: "PATCH",
      data
    });
  }
}
