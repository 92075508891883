import React from 'react';
import PermissionComponent from 'PermissionComponent';
import {
  ACCOUNTING_NAV_TAGS,
  INBOX_NAV_TAGS,
  LISTINGS_NAV_TAGS,
  MORE_NAV_TAGS,
  PAYMENTS_NAV_TAGS,
  RESERVATIONS_NAV_TAGS
} from 'sharedNav';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import InboxLink from 'adminNavbar/inbox/links/inbox-link';
import AccountingLinkMenu from './link-menus/accounting/accounting-link-menu';
import ReservationsLinkMenu from './link-menus/reservations/reservations-link-menu';
import ListingsLinkMenu from './link-menus/listings/listings-link-menu';
import PaymentsLinkMenu from './link-menus/payments/payments-link-menu';
import DefaultLeftMoreLink from './default-left-more-link';

const DefaultLeft = props => {
  const logoLink = () => {
    if (props.user.permissions.view_properties === true) {
      return '/properties';
    } else if (props.user.permissions.view_reservations === true) {
      return '/reservations/list';
    } else if (props.user.permissions.view_distribution === true) {
      return '/channels';
    } else if (props.user.permissions.view_site_editor === true) {
      return '/brands';
    } else if (props.user.permissions.view_people === true) {
      return '/employees';
    } else if (props.user.permissions.view_accounting === true) {
      if (props.user.permissions.payouts_balances === true) {
        return '/accounting';
      } else if (props.user.permissions.statements === true) {
        return '/accounting/statements';
      } else if (props.user.permissions.invoices === true) {
        return '/accounting/invoices';
      } else if (props.user.permissions.earnings_summary === true) {
        return '/accounting/earnings-summaries';
      } else if (props.user.permissions.ledger === true) {
        return '/accounting/bookings-summary';
      }
    } else if (props.user.permissions.view_reporting === true) {
      return '/reporting';
    } else if (props.user.permissions.view_workflows === true) {
      return props.user.permissions.templates === true
        ? '/workflows'
        : '/workflows/work-orders';
    }
  };

  return (
    <Navbar.Left
      homeLink={props.user.role === 4 ? '/' : logoLink()}
      homeLinkOnClick={props.homeLinkOnClick}
    >
      <ListingsLinkMenu {...props} navTags={LISTINGS_NAV_TAGS} />
      <ReservationsLinkMenu {...props} navTags={RESERVATIONS_NAV_TAGS} />
      <PermissionComponent user={props.user} permission="messages">
        <InboxLink {...props} navTags={INBOX_NAV_TAGS} />
      </PermissionComponent>
      <PermissionComponent user={props.user} permission="view_accounting">
        {props.user.role_name === 'organization_owner' ? (
          <AccountingLinkMenu {...props} navTags={ACCOUNTING_NAV_TAGS} />
        ) : (
          <PaymentsLinkMenu {...props} navTags={PAYMENTS_NAV_TAGS} />
        )}
      </PermissionComponent>
      <DefaultLeftMoreLink {...props} navTags={MORE_NAV_TAGS} />
    </Navbar.Left>
  );
};

export default DefaultLeft;
