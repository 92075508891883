import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const DashboardHomeLink = ({ activeRoutes, closeMobileNav, navTags, user }) => {
  const logoLink = () => {
    if (user.permissions.view_properties === true) {
      return '/properties';
    } else if (user.permissions.view_reservations === true) {
      return '/reservations/list';
    } else if (user.permissions.view_distribution === true) {
      return '/channels';
    } else if (user.permissions.view_site_editor === true) {
      return '/brands';
    } else if (user.permissions.view_people === true) {
      return '/employees';
    } else if (user.permissions.view_accounting === true) {
      if (user.permissions.payouts_balances === true) {
        return '/accounting';
      } else if (user.permissions.statements === true) {
        return '/accounting/statements';
      } else if (user.permissions.invoices === true) {
        return '/accounting/invoices';
      } else if (user.permissions.earnings_summary === true) {
        return '/accounting/earnings-summaries';
      } else if (user.permissions.ledger === true) {
        return '/accounting/bookings-summary';
      }
    } else if (user.permissions.view_reporting === true) {
      return '/reporting';
    } else if (user.permissions.view_workflows === true) {
      return user.permissions.templates === true
        ? '/workflows'
        : '/workflows/work-orders';
    }
  };

  return (
    <Navbar.Link
      href={user.role === 4 ? '/' : logoLink()}
      isActive={activeRoutes.primary === navTags.PRIMARY}
      onClick={() => {
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
    >
      Dashboard
    </Navbar.Link>
  );
};

DashboardHomeLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  navTags: PropTypes.object
};

export default DashboardHomeLink;
