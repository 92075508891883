import { useEffect, useState } from "react";
function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      Reflect.apply(fn, this, arguments);
    }, ms);
  };
}
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: void 0,
    height: void 0
  });
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }, 50);
    window.addEventListener("resize", debouncedHandleResize);
    debouncedHandleResize();
    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, []);
  return windowSize;
};
