import React from 'react';

import { QuoteTable } from 'adminComponents/BookingWidget/BookingWidgetStyles';

const LineItemDifferencesTable = props => {
  return (
    <div>
      <label>{props.label}</label>
      <QuoteTable
        className="consolidated-simple-table"
        style={{ maxWidth: '640px' }}
      >
        <thead>
          <th />
          <th style={{ textAlign: 'right', width: '150px' }}>Change Amount</th>
          <th style={{ textAlign: 'right', width: '150px' }}>New Amount</th>
        </thead>
        <tbody>
          <tr />
          {props.children}
        </tbody>
      </QuoteTable>
    </div>
  );
};

export default LineItemDifferencesTable;
