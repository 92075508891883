// Dependencies
// -----------------------------------------------
import React from 'react';

// -----------------------------------------------
// COMPONENT->OVERVIEW-SINGLE-ASIDE-BATHROOMS ----
// -----------------------------------------------
const OverviewSingleAsideBathrooms = () => {
  // ---------------------------------------------
  // RETURN --------------------------------------
  // ---------------------------------------------
  return (
    <div>
      <figure>
        <small className="subtitle">Bathrooms</small>
        <p>How many full and partial bathrooms does this property have?</p>
      </figure>

      <figure>
        <small className="subtitle">Facility Types</small>
        <p>What is each bathroom outfitted with?</p>
      </figure>
    </div>
  );
};

// -----------------------------------------------
// EXPORT ----------------------------------------
// -----------------------------------------------
export default OverviewSingleAsideBathrooms;
