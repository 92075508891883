import request from "apiClient";
export default class EmployeeStatementService {
  static index(organizationId, employeeId) {
    return request({
      url: `/api/${organizationId}/employees/${employeeId}/statements`,
      method: "GET"
    });
  }
  static update(organizationId, employeeId, statementsId, status) {
    return request({
      url: `/api/${organizationId}/employees/${employeeId}/statements/${statementsId}?status=${status}`,
      method: "PATCH"
    });
  }
}
