import React, { useState } from 'react';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import { useDetectMobile } from 'sharedHooks';
import LinksMobile from './links-mobile';

const MyAccountMobileLinkMenu = props => {
  const { isLargeDesktop } = useDetectMobile();
  const [showPanel, setShowPanel] = useState(false);
  const closeMenuPanel = () => {
    setShowPanel(false);
  };

  return (
    <Navbar.LinkMenu
      primaryLinkText="My Account"
      primaryLinkProps={{
        isActive: props.activeRoutes.primary === props.navTags.PRIMARY,
        onClick: () => setShowPanel(!showPanel)
      }}
      onPanelClickOutside={() => setShowPanel(false)}
      showMenuPanel={showPanel}
    >
      {!isLargeDesktop && (
        <LinksMobile {...props} closeMenuPanel={closeMenuPanel} />
      )}
    </Navbar.LinkMenu>
  );
};

export default MyAccountMobileLinkMenu;
