import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const MySettingsLink = ({
  activeRoutes,
  closeMobileNav,
  navTags,
  ...props
}) => {
  const { organization } = props;

  return (
    <Navbar.Link
      href={
        props.user.type === 'user' ||
        props.user.role_name === 'organization_owner'
          ? `/dashboard/${organization.subdomain}/settings`
          : `/dashboard/${organization.subdomain}/account`
      }
      variation="secondary"
      linkType="link"
      isActive={
        props.user.type === 'user' ||
        props.user.role_name === 'organization_owner'
          ? activeRoutes.secondary === navTags.secondary.MY_SETTINGS
          : activeRoutes.secondary === navTags.secondary.MY_SETTINGS_NON_ADMIN
      }
    >
      General
    </Navbar.Link>
  );
};

MySettingsLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  navTags: PropTypes.object
};

export default MySettingsLink;
