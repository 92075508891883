import React, { useEffect } from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { ACCOUNTING_NAV_TAGS } from '../../../../../shared/nav';

const ChartOfAccountsSettings = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: ACCOUNTING_NAV_TAGS.PRIMARY,
        secondary: ACCOUNTING_NAV_TAGS.secondary.CHART_OF_ACCOUNTS
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/accounting/chart-of-accounts',
            label: 'Chart of Accounts'
          },
          {
            label: 'Settings'
          }
        ]}
      >
        Chart of Accounts: <span>Settings</span>
      </PageHeader.Left>
    </PageHeader>
  );
};

export default ChartOfAccountsSettings;
