import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const MySettingsIntegrationsLink = ({
  activeRoutes,
  closeMobileNav,
  navTags,
  ...props
}) => {
  const { organization } = props;

  return (
    <Navbar.Link
      href={`/dashboard/${organization.subdomain}/settings/integrations`}
      variation="secondary"
      linkType="link"
      isActive={activeRoutes.secondary === navTags.secondary.MY_INTEGRATIONS}
    >
      Integrations
    </Navbar.Link>
  );
};

MySettingsIntegrationsLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  navTags: PropTypes.object
};

export default MySettingsIntegrationsLink;
