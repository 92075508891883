import request from "apiClient";
function list(orgId, limit, page, sorted, filtered, dateFilter, search) {
  return request({
    url: `/api/${orgId}/work_reports`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered,
      date_filters: dateFilter,
      search
    }
  }).then((response) => {
    return {
      rows: response.workReports,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function completed(orgId, limit, page, sorted, filtered, dateFilter, search) {
  return request({
    url: `/api/${orgId}/work_reports/completed`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered,
      date_filters: dateFilter,
      search
    }
  }).then((response) => {
    return {
      rows: response.workReports,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function create(orgId, data) {
  return request({
    url: `/api/${orgId}/work_reports`,
    method: "POST",
    data
  });
}
function update(orgId, workReportId, data) {
  return request({
    url: `/api/${orgId}/work_reports/${workReportId}`,
    method: "PUT",
    data
  });
}
function get(orgId, workReportId) {
  return request({
    url: `/api/${orgId}/work_reports/${workReportId}`,
    method: "GET"
  });
}
function updateDeduction(orgId, deduction) {
  return request({
    url: `/api/${orgId}/deductions/${deduction.id}`,
    method: "PATCH",
    data: { deduction }
  });
}
const WorkReportService = {
  get,
  list,
  completed,
  update,
  create,
  updateDeduction
};
export default WorkReportService;
