import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const RoomTypesLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags,
  ...props
}) => {
  return (
    <Navbar.Link
      href="/room-types"
      variation="secondary"
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={activeRoutes.secondary === navTags.secondary.ROOM_TYPES}
    >
      Room Types
    </Navbar.Link>
  );
};

RoomTypesLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object
};

export default RoomTypesLink;
