import React from 'react';
import find from 'lodash/find';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';
import InputLimiter from '../../../components/input-limiter';

export default class FieldsOrganizationBasic extends React.Component {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      nameLength: this.props.name.length || 0
    };
  }

  sanitizeSubdomain = str => {
    return str
      .replace("'", '')
      .replace(/[^A-Z0-9]/gi, '-')
      .toLowerCase()
      .substring(0, 80);
  };

  setName = e => {
    e.preventDefault();
    const name = e.target.value;
    const subdomain = this.sanitizeSubdomain(name);
    this.setState({ nameLength: name.length });
    this.props.setName(name);
    this.props.setSubdomain(subdomain);
  };

  setCompanyType = val => {
    this.props.setCompanyType(val.value);
  };

  setOrganizationType = val => {
    this.props.setOrganizationType(val.value);
  };

  setDescription = e => {
    e.preventDefault();
    this.props.setDescription(e.target.value);
  };

  render() {
    const organizationTypeOptions = [
      { value: 'bnb', label: 'Bed and Breakfast' },
      { value: 'hotel', label: 'Hotel' },
      { value: 'hostel', label: 'Hostel' },
      { value: 'vacation_rental', label: 'Vacation rental' },
      { value: 'rv_fleet', label: 'RV Fleet' }
    ];
    const companyTypeOptions = [
      { value: 'corporation', label: 'Corporation' },
      { value: 'sole_prop', label: 'Sole proprietorship' },
      { value: 'non_profit', label: 'Non-profit organization' },
      { value: 'partnership', label: 'Partnership' },
      { value: 'llc', label: 'LLC' },
      { value: 'other', label: 'Other' }
    ];

    return (
      <fieldset>
        <header className="fieldset-header">
          <legend>Set up your account</legend>
          <p>Tell us a little bit about your business.</p>
        </header>

        <label htmlFor="name">
          <span>Organization name</span>
          <InputLimiter used={this.state.nameLength} total={140} />
        </label>
        <input
          type="text"
          name="name"
          className="magnified"
          placeholder="My organization"
          maxLength="140"
          value={this.props.name}
          onChange={e => this.setName(e)}
        />

        <label htmlFor="companyType">
          <span>Company type</span>
        </label>
        <InputSelect
          name="companyType"
          className="magnified"
          options={companyTypeOptions}
          isClearable={false}
          isSearchable={false}
          placeholder="Company type"
          value={find(companyTypeOptions, option => {
            return option.value === this.props.companyType;
          })}
          onChange={this.setCompanyType}
        />

        <label htmlFor="organizationType">
          <span>Organization type</span>
        </label>
        <InputSelect
          name="organizationType"
          className="magnified"
          options={organizationTypeOptions}
          isClearable={false}
          isSearchable={false}
          placeholder="Organization type"
          value={find(organizationTypeOptions, option => {
            return option.value === this.props.organizationType;
          })}
          onChange={this.setOrganizationType}
        />

        <label htmlFor="description">Description</label>
        <textarea
          className="magnified"
          name="description"
          placeholder=""
          rows="5"
          value={this.props.description}
          onChange={this.setDescription}
        />
      </fieldset>
    );
  }
}
