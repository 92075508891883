// Dependencies
// -----------------------------------------------
import React from 'react';

// Components
// -----------------------------------------------
import {
  InputIncrementer,
  SimplifiedWysiwyg,
  MinimumAgeSelect
} from 'adminComponents';

// -----------------------------------------------
// COMPONENT->OVERVIEW-SINGLE-FIELDS-LODGING -----
// -----------------------------------------------
const OverviewSingleFieldsLodging = props => {
  const onSimpleChange = e => {
    e.preventDefault();
    props.onSimpleChange(e.target.name, e.target.value);
  };

  const onWysiwygChange = (name, value) => {
    props.onSimpleChange(name, value);
  };

  const onIncrementChange = value => {
    props.onSimpleChange('num_sleep', value);
  };

  // ---------------------------------------------
  // RETURN --------------------------------------
  // ---------------------------------------------
  return (
    <fieldset>
      <header className="fieldset-header">
        <legend>Lodging Overview</legend>
        <p>
          Give us some details about the lodging amenities and features of this
          property.
        </p>
      </header>
      <label htmlFor="description">
        <span>Lodging description</span>
      </label>
      <SimplifiedWysiwyg
        content={props.unit_description}
        name="unit_description"
        onContentChange={onWysiwygChange}
      />

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <label htmlFor="num_sleep">
            <span>How many guests can this property accomodate?</span>
          </label>
          <InputIncrementer
            name="num_sleep"
            label="guest"
            min="1"
            max="24"
            value={props.num_sleep}
            callbackIncrement={onIncrementChange}
            callbackDecrement={onIncrementChange}
          />
        </div>
        <MinimumAgeSelect
          minimum_age={21}
          onChange={change =>
            props.onSimpleChange('minimum_age', change.minimum_age)
          }
        />
      </div>
    </fieldset>
  );
};

// -----------------------------------------------
// EXPORT ----------------------------------------
// -----------------------------------------------
export default OverviewSingleFieldsLodging;
