import React from 'react';
import MediaQuery from 'react-responsive';
import { CallToActionLink, FlexBox } from '@directsoftware/ui-kit-web-admin';

export default class Navigation extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FlexBox
        paddingHorizontal="s"
        justifyContent="space-between"
        alignItems="center"
        style={{ height: 60, borderBottom: '1px solid #e5e2e2' }}
      >
        <MediaQuery maxWidth={799}>
          <img
            src="/assets/brand/logo_direct_shield.svg"
            alt="Direct"
            style={{ height: '32px' }}
          />
        </MediaQuery>
        <MediaQuery minWidth={800}>
          <img
            src="/assets/brand/logo_direct_wordmark.svg"
            alt="Direct"
            style={{ height: '32px' }}
          />
        </MediaQuery>
        <MediaQuery minWidth={500}>
          <FlexBox gap="xs" alignItems="center">
            <CallToActionLink href="/sign-in" appearance="ghost">
              Sign In
            </CallToActionLink>
            <CallToActionLink
              href="https://www.getdirect.io/"
              appearance="ghost"
              openNewTab
              type="link"
            >
              About
            </CallToActionLink>
          </FlexBox>
        </MediaQuery>
      </FlexBox>
    );
  }
}
