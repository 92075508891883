import request from "apiClient";
function list(orgId) {
  return request({
    url: `/api/${orgId}/access_roles`,
    method: "GET"
  });
}
const AccessRoleService = {
  list
};
export default AccessRoleService;
