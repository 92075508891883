import styled from 'styled-components';
import { gridSize, colors } from '@atlaskit/theme';

const ReadContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  margin-top: 8px;
  padding: ${gridSize()}px ${gridSize() - 2}px;
`;

const InlineEditContainer = styled.div`
  width: 100%;

  form {
    width: 100%;
  }

  input + div {
    width: initial;

    div {
      width: 32px;
    }
  }

  div {
    width: 100%;

    :first-child {
      margin: 0;
    }
  }
`;

const EditViewInput = styled.input`
  border-radius: 3px;
  box-sizing: border-box;
  cursor: inherit;
  outline: none;
  padding: ${gridSize()}px ${gridSize() - 2}px;
  width: 100%;
  border: 2px solid ${colors.N40};
  font-family: 'Avenir Next', 'Avenir', 'Century Gothic', 'Catamaran', Helvetica,
    Arial, sans-serif;

  :focus {
    border: 2px solid ${colors.B100};
  }

  ${props =>
    props.titleType === 'h3' &&
    `
    font-weight: 500;
    color: #000;
    font-size: 22.624px;
    height: 42px;
  `}
`;

export { ReadContainer, InlineEditContainer, EditViewInput };
