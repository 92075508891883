import React from 'react';
import { Box, Navbar, Spacer } from '@directsoftware/ui-kit-web-admin';
import PermissionComponent from 'PermissionComponent';
import PropertiesLink from 'adminNavbar/listings/links/properties-link';
import VehiclesLink from 'adminNavbar/listings/links/vehicles-link';
import PortfoliosLink from 'adminNavbar/listings/links/portfolios-link';
import SubPortfoliosLink from 'adminNavbar/listings/links/sub-portfolios-link';
import UnitGroupsLink from 'adminNavbar/listings/links/unit-groups-link';
import RateGroupsLink from 'adminNavbar/listings/links/rate-groups-link';
import PromotionsLink from 'adminNavbar/listings/links/promotions-link';
import RoomTypesLink from 'adminNavbar/listings/links/room-types-link';
import AuditPropertiesLink from 'adminNavbar/listings/links/audit-properties-link';
import PropertiesBulkEditLink from 'adminNavbar/listings/links/properties-bulk-edit-link';
import VehiclesBulkEditLink from 'adminNavbar/listings/links/vehicles-bulk-edit-link';
import BulkSettingsLink from 'adminNavbar/listings/links/bulk-settings-link';
import PropertyListReportLink from 'adminNavbar/listings/links/property-list-report-link';
import VehicleListReportLink from 'adminNavbar/listings/links/vehicle-list-report-link';

const LinksDesktop = props => {
  return (
    <>
      <PermissionComponent user={props.user} permission="view_property_index">
        <Navbar.LinkMenuColumn addRightBorder>
          <PermissionComponent
            user={props.user}
            permission="view_property_index"
          >
            <PropertiesLink {...props} variation="featured" />
          </PermissionComponent>
          {(props.user.permissions.ledger ||
            props.user.permissions.view_site_editor ||
            props.user.permissions.site_editor_auto_emails) && (
              <PermissionComponent user={props.user} permission="ledger">
                <Box style={{ paddingLeft: 44 }}>
                  <BulkSettingsLink {...props} />
                </Box>
                <Spacer size="xxs" />
              </PermissionComponent>
            )}
          <PermissionComponent
            user={props.user}
            permission="view_properties"
            restriction="properties_read_only"
          >
            <Box style={{ paddingLeft: 44 }}>
              <PropertiesBulkEditLink {...props} />
            </Box>
          </PermissionComponent>
          <VehiclesLink {...props} variation="featured" />
          {props.organization.organization_type === 'rv_fleet' && (
            <>
              <PermissionComponent user={props.user} permission="view_vehicles" restriction="vehicles_read_only">
                <Box style={{ paddingLeft: 48 }}>
                  <VehiclesBulkEditLink {...props} />
                </Box>
              </PermissionComponent>
              <PermissionComponent user={props.user} permission="view_vehicle_index">
                <Box style={{ paddingLeft: 48 }}>
                  <VehicleListReportLink closeMobileNav={props.closeMobileNav} closeMenuPanel={props.closeMenuPanel} />
                </Box>
              </PermissionComponent>
            </>
          )}
          <PermissionComponent
            user={props.user}
            permission="view_property_index"
          >
            <Box style={{ paddingLeft: 44 }}>
              <PropertyListReportLink closeMobileNav={props.closeMobileNav} closeMenuPanel={props.closeMenuPanel} />
            </Box>
          </PermissionComponent>
        </Navbar.LinkMenuColumn>
      </PermissionComponent>
      <PermissionComponent user={props.user} permission="view_portfolios">
        <Navbar.LinkMenuColumn>
          <Navbar.LinkMenuGroup groupTitle="Segmentations">
            <PortfoliosLink {...props} />
            <SubPortfoliosLink {...props} />
            <UnitGroupsLink {...props} />
          </Navbar.LinkMenuGroup>
        </Navbar.LinkMenuColumn>
        <Navbar.LinkMenuColumn>
          <Navbar.LinkMenuGroup groupTitle="Other Features">
            <RateGroupsLink {...props} />
            <PromotionsLink {...props} />
            <RoomTypesLink {...props} />
            <PermissionComponent
              user={props.user}
              permission="view_property_index"
            >
              <AuditPropertiesLink {...props} />
            </PermissionComponent>
          </Navbar.LinkMenuGroup>
        </Navbar.LinkMenuColumn>
      </PermissionComponent>
    </>
  );
};

export default LinksDesktop;
