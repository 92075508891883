import React from 'react';

const statusArray = ['not_converted', 'converted'];

const opportunityStatus = (status, not_converted_reason) => {
  if (status === 'not_converted') {
    return not_converted_reason || 'Not Converted';
  }

  return status;
};

const OpportunityStatusLabel = ({ opportunity, styles }) => {
  const { status, not_converted_reason } = opportunity;
  if (!statusArray.includes(status)) return null;

  return (
    <div className={`opportunity-status ${status}`} style={styles}>
      {opportunityStatus(status, not_converted_reason)}
    </div>
  );
};

export default OpportunityStatusLabel;
