import React from 'react';

import Dropzone from 'react-dropzone';
import Spinner from './spinner';

import { DocumentService } from 'adminApi';
import displayError from 'sharedComponents/ErrorDisplay';

export default class DocumentUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isLoading: false
    };
  }

  onDropzoneDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      this.attemptUploadFile(acceptedFiles[0]);
    }
  };

  attemptUploadFile = file_queued => {
    this.setState({ isLoaded: false, isLoading: true }, () => {
      var formData = new FormData();
      formData.append('document', file_queued);
      formData.append('documentable_id', this.props.documentable_id);
      formData.append('documentable_type', this.props.documentable_type);

      DocumentService.create(this.props.organization.id, formData)
        .then(data => {
          this.setState(
            {
              isLoaded: true,
              isLoading: false
            },
            () => this.props.afterUpload(data.document)
          );
        })
        .catch(err => {
          this.setState({
            isLoaded: false,
            isLoading: false
          });
          displayError({ message: 'Error uploading file', err });
        });
    });
  };

  renderLoading = () => {
    return <Spinner />;
  };

  renderViewing = () => {
    return (
      <Dropzone
        activeClassName=""
        className={`card empty-state`}
        multiple={false}
        onDrop={this.onDropzoneDrop}
        ref={node => {
          this.dropzone = node;
        }}
      >
        <div>
          <a className="button">Upload document</a>
          <p>or drag it into this box.</p>
        </div>
      </Dropzone>
    );
  };

  render() {
    return (
      <section>
        {this.state.isLoading ? this.renderLoading() : this.renderViewing()}
      </section>
    );
  }
}
