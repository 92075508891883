import React from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';

const UnitsAdd = props => {
  return (
    <PageHeader height="l" containerVariation="fluid">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/properties',
            label: 'Listings: Properties'
          },
          {
            label: `Details (Multi-unit)`,
            href: `/properties/${props.propertyId}/manage-property-units`
          },
          {
            label: `Add a Unit`
          }
        ]}
        backLinkHref={`/properties/${props.propertyId}/manage-property-units`}
      >
        {props.property?.name}
      </PageHeader.Left>
    </PageHeader>
  );
};

UnitsAdd.propTypes = {};

UnitsAdd.defaultProps = {};

export default UnitsAdd;
