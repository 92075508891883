import request from "apiClient";
export default class OrganizationFeeService {
  static get(organizationID) {
    return request({
      url: `/api/${organizationID}/organization_fees`,
      method: "GET"
    });
  }
  static update(organizationID, organizationFeeID, data) {
    return request({
      url: `/api/${organizationID}/organization_fees/${organizationFeeID}`,
      method: "PATCH",
      data
    });
  }
  static create(organizationId, data) {
    return request({
      url: `/api/${organizationId}/organization_fees`,
      method: "POST",
      data
    });
  }
}
