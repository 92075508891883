import React from 'react';
import map from 'lodash/map';
import find from 'lodash/find';
import reject from 'lodash/reject';

import LineItemWithDifferences from './LineItemWithDifferences';
import LineItemDifferencesTable from './LineItemDifferencesTable';
import { CurrencyDisplay } from 'sharedComponents';

export default class EditedQuoteForm extends React.Component {
  constructor(props) {
    super(props);
  }

  renderSelectedItem = updatedSelectedLineItem => {
    let lineItemLabel = null;

    if (updatedSelectedLineItem.item_type === 'room_rate') {
      lineItemLabel = (
        <span>
          Room Rate (<CurrencyDisplay currencyValue={this.props.currency} />
          {updatedSelectedLineItem.rate.toFixed(2)} avg. x{' '}
          {this.props.editedQuote.billable_nights} nights)
        </span>
      );
    }

    return (
      <LineItemDifferencesTable
        label={'How This Line Item Will Appear With This Change:'}
      >
        <LineItemWithDifferences
          currency={this.props.currency}
          oldLineItem={this.props.selectedLineItem}
          newLineItem={updatedSelectedLineItem}
          lineItemLabel={lineItemLabel}
          alwaysShow
        />
      </LineItemDifferencesTable>
    );
  };

  renderUpdatedItems = () => {
    const nonSelectedItems = reject(
      this.props.editedQuote.line_items,
      li => li.id === this.props.selectedLineItem.id
    );

    return (
      <LineItemDifferencesTable
        label={'Other Reservation Payment Line Items This Change Will Affect:'}
      >
        {map(nonSelectedItems, li => {
          const oldLi = this.props.safeToEditQuote.line_items.find(
            oli => oli.id === li.id
          );

          return (
            <LineItemWithDifferences
              key={`edited-quote-form-${oldLi ? oldLi.id : 0}-${li.id}`}
              currency={this.props.currency}
              oldLineItem={oldLi}
              newLineItem={li}
              alwaysShow={li.item_type === 'total'}
            />
          );
        })}
      </LineItemDifferencesTable>
    );
  };

  render() {
    const updatedSelectedLineItem = find(
      this.props.editedQuote.line_items,
      li => li.id === this.props.selectedLineItem.id
    );

    return (
      <div>
        {updatedSelectedLineItem
          ? this.renderSelectedItem(updatedSelectedLineItem)
          : null}
        {this.renderUpdatedItems()}
      </div>
    );
  }
}
