import React from 'react';
import styled from 'styled-components';
import TimeAgo from 'react-timeago';
import englishStrings from 'react-timeago/lib/language-strings/en';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import { Stripe, CheckCircle, TimesCircle } from 'adminComponents/icons';
import Loading from 'react-loading-components';
import { nativeSymbolFromValue } from 'sharedComponents/currency-display';

const CardContainer = styled.div`
  width: 96%;
  margin: 4px auto;
  box-shadow: 0 0 8px -4px #000000;
  border-radius: 8px;
`;

const CardTop = styled.div`
  background-color: #f1f1f7;
  padding: 8px 16px 16px;
  border-radius: 8px 8px 0 0;
`;

const CardCurrentBalance = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    white-space: nowrap;
  }
`;

const CardBottom = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const overrides = {
  suffixAgo: null,
  seconds: 'just now',
  minute: '%d minute ago',
  minutes: '%d minutes ago'
};

const formatter = buildFormatter({ ...englishStrings, ...overrides });

const statusChoices = {
  set: {
    color: '#50E3C2',
    text: 'All set!',
    icon: <CheckCircle style={{ color: '#50E3C2', fontSize: '20px' }} />
  },
  limited: {
    color: '#FFC15A',
    text: 'Limited',
    icon: <CheckCircle style={{ color: '#FFC15A', fontSize: '20px' }} />
  },
  depleted: {
    color: '#FB5872',
    text: 'Depleted',
    icon: <TimesCircle style={{ color: '#FB5872', fontSize: '20px' }} />
  }
};

const statusIcon = (required, current) => {
  let status = 'limited';

  if (required <= current || required === 0) {
    status = 'set';
  } else if (current === 0) {
    status = 'depleted';
  }

  const statusChoice = statusChoices[status];

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          color: statusChoice.color,
          fontSize: '12px',
          marginRight: '6px',
          fontWeight: '200'
        }}
      >
        {statusChoice.text}
      </span>
      {statusChoice.icon}
    </div>
  );
};

const commaSeparatedBalance = (amount, currency) => {
  const decimalString = (amount / 100.0).toFixed(2);
  const commaString = decimalString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${nativeSymbolFromValue(currency)}${commaString}`;
};

const StripeBalanceCard = props => {
  if (!props.orgBalance) return null;
  if (props.loadingBalance) {
    return (
      <CardContainer
        key={props.orgBalance.stripe_connect_account.id}
        style={{
          height: '210px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Loading type="tail_spin" width={50} height={50} fill="#00CC99" />
      </CardContainer>
    );
  }

  return (
    <CardContainer key={props.orgBalance.stripe_connect_account.id}>
      <CardTop>
        <div style={{ textAlign: 'right' }}>
          <Stripe style={{ color: '#6772e5', fontSize: '32px' }} />
        </div>
        <div style={{ marginBottom: '16px' }}>
          {props.orgBalance.stripe_connect_account.name}
        </div>
        <div style={{ fontWeight: 'bold' }}>
          {commaSeparatedBalance(
            props.orgBalance.stripe_balances.available[0].amount,
            props.currency
          )}
        </div>
        <CardCurrentBalance>
          <div style={{ fontSize: '12px' }}>CURRENT STRIPE BALANCE</div>
          <div style={{ fontSize: '8px' }}>
            <TimeAgo
              date={props.orgBalance.timestamp}
              formatter={formatter}
              minPeriod={60}
            />
          </div>
        </CardCurrentBalance>
      </CardTop>
      <CardBottom>
        <div>
          <div style={{ fontWeight: 'bold' }}>
            {commaSeparatedBalance(
              props.orgBalance.required_balance,
              props.currency
            )}
          </div>
          <div style={{ fontSize: '12px' }}>PAYOUT BALANCE REQUIRED</div>
        </div>
        <div>
          {statusIcon(
            props.orgBalance.required_balance,
            props.orgBalance.stripe_balances.available[0].amount
          )}
        </div>
      </CardBottom>
    </CardContainer>
  );
};

export default StripeBalanceCard;
