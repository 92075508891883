import React from 'react';

import Geosuggest from 'react-geosuggest';
import Script from 'react-load-script';

import Spinner from '../../../components/spinner';

export default class FieldsOrganizationLocation extends React.Component {
  constructor(props, _railsContext) {
    super(props);

    this.state = {
      adrStreet: this.props.adrStreet,
      adrUnit: this.props.adrUnit,
      adrCity: this.props.adrCity,
      adrState: this.props.adrState,
      adrCountry: this.props.adrCountry,
      adrPostalCode: this.props.adrPostalCode,
      mapsLoaded: false,
      mapsLoading: true
    };
  }

  handleMapScriptError = () => {
    this.setState({
      mapsLoaded: false,
      mapsLoading: false
    });
  };

  handleMapScriptLoad = () => {
    this.setState({
      mapsLoaded: true,
      mapsLoading: false
    });
  };

  updateOnSuggestSelected = suggest => {
    const adrComponents = suggest.gmaps.address_components;
    const adrPostalCode = adrComponents.find(e => {
      return e.types[0] === 'postal_code';
    });
    const adrCountry = adrComponents.find(e => {
      return e.types[0] === 'country';
    });
    const adrState = adrComponents.find(e => {
      return e.types[0] === 'administrative_area_level_1';
    });
    const adrCity = adrComponents.find(e => {
      return e.types[0] === 'locality';
    });
    const adrStreetName = adrComponents.find(e => {
      return e.types[0] === 'route';
    });
    const adrStreetNumber = adrComponents.find(e => {
      return e.types[0] === 'street_number';
    });
    this.setState({
      adrStreet:
        (adrStreetNumber ? adrStreetNumber.long_name : '') +
        ' ' +
        (adrStreetName ? adrStreetName.long_name : ''),
      adrUnit: '',
      adrCity: adrCity ? adrCity.long_name : '',
      adrState: adrState ? adrState.long_name : '',
      adrCountry: adrCountry ? adrCountry.long_name : '',
      adrPostalCode: adrPostalCode ? adrPostalCode.long_name : ''
    });
    this.adrGeoSuggest.update(this.state.adrStreet);
    this.props.setLocationAttributes(
      this.state.adrStreet,
      this.state.adrUnit,
      this.state.adrCity,
      this.state.adrState,
      this.state.adrCountry,
      this.state.adrPostalCode
    );
  };

  updateAdrUnit = e => {
    this.setState({ adrUnit: e.target.value });
    this.props.setLocationAttributes(
      this.state.adrStreet,
      this.state.adrUnit,
      this.state.adrCity,
      this.state.adrState,
      this.state.adrCountry,
      this.state.adrPostalCode
    );
  };

  updateAdrStreet = e => {
    this.setState({ adrStreet: e.target.value });
    this.props.setLocationAttributes(
      this.state.adrStreet,
      this.state.adrUnit,
      this.state.adrCity,
      this.state.adrState,
      this.state.adrCountry,
      this.state.adrPostalCode
    );
  };

  updateAdrCity = e => {
    this.setState({ adrCity: e.target.value });
    this.props.setLocationAttributes(
      this.state.adrStreet,
      this.state.adrUnit,
      this.state.adrCity,
      this.state.adrState,
      this.state.adrCountry,
      this.state.adrPostalCode
    );
  };

  updateAdrState = e => {
    this.setState({ adrState: e.target.value });
    this.props.setLocationAttributes(
      this.state.adrStreet,
      this.state.adrUnit,
      this.state.adrCity,
      this.state.adrState,
      this.state.adrCountry,
      this.state.adrPostalCode
    );
  };

  updateAdrCountry = e => {
    this.setState({ adrCountry: e.target.value });
    this.props.setLocationAttributes(
      this.state.adrStreet,
      this.state.adrUnit,
      this.state.adrCity,
      this.state.adrState,
      this.state.adrCountry,
      this.state.adrPostalCode
    );
  };

  updateAdrPostalCode = e => {
    this.setState({ adrPostalCode: e.target.value });
    this.props.setLocationAttributes(
      this.state.adrStreet,
      this.state.adrUnit,
      this.state.adrCity,
      this.state.adrState,
      this.state.adrCountry,
      this.state.adrPostalCode
    );
  };

  renderGeoSuggestedAddress() {
    const {
      adrStreet,
      adrUnit,
      adrCity,
      adrState,
      adrCountry,
      adrPostalCode
    } = this.state;
    return (
      <div>
        <label>
          <span>Street number</span>
        </label>
        <Geosuggest
          inputClassName="magnified"
          placeholder="123 Road St."
          types={['geocode']}
          suggestsHiddenClassName="hidden"
          initialValue={adrStreet}
          ref={node => {
            this.adrGeoSuggest = node;
          }}
          onSuggestSelect={this.updateOnSuggestSelected}
        />

        <label htmlFor="adrUnit">
          <span>
            Apt, suite, etc. <small>(optional)</small>
          </span>
        </label>
        <input
          className="magnified"
          type="text"
          name="adrUnit"
          placeholder="Ste. 1000"
          value={adrUnit}
          onChange={this.updateAdrUnit}
        />

        <section className="two-col two-col-50-50">
          <div>
            <label htmlFor="adrCity">
              <span>City</span>
            </label>
            <input
              className="magnified"
              type="text"
              name="adrCity"
              placeholder="Chicago"
              value={adrCity}
              onChange={this.updateAdrCity}
            />
          </div>

          <div>
            <label htmlFor="adrState">
              <span>State / Province</span>
            </label>
            <input
              className="magnified"
              type="text"
              name="adrState"
              placeholder="IL"
              value={adrState}
              onChange={this.updateAdrState}
            />
          </div>
        </section>

        <section className="two-col two-col-70-30">
          <div>
            <label htmlFor="adrCountry">
              <span>Country</span>
            </label>
            <input
              className="magnified"
              type="text"
              name="adrCountry"
              placeholder="United States"
              value={adrCountry}
              onChange={this.updateAdrCountry}
            />
          </div>

          <div>
            <label htmlFor="adrPostalCode">
              <span>Postal code</span>
            </label>
            <input
              className="magnified"
              type="text"
              name="adrPostalCode"
              placeholder="60622"
              value={adrPostalCode}
              onChange={this.updateAdrPostalCode}
            />
          </div>
        </section>
      </div>
    );
  }

  renderStandardAddress() {
    const {
      adrStreet,
      adrUnit,
      adrCity,
      adrState,
      adrCountry,
      adrPostalCode
    } = this.state;
    return (
      <div>
        <label htmlFor="adrStreet">Street Number</label>
        <input
          className="magnified"
          type="text"
          name="adrStreet"
          placeholder="123 Road St."
          value={adrStreet}
          onChange={this.updateAdrStreet}
        />

        <label htmlFor="adrUnit">
          Apt, Suite, etc. <small>(optional)</small>
        </label>
        <input
          className="magnified"
          type="text"
          name="adrUnit"
          placeholder="Ste. 1000"
          value={adrUnit}
          onChange={this.updateAdrUnit}
        />

        <label htmlFor="adrCity">City</label>
        <input
          className="magnified"
          type="text"
          name="adrCity"
          placeholder="Chicago"
          value={adrCity}
          onChange={this.updateAdrCity}
        />

        <label htmlFor="adrState">State/Province</label>
        <input
          className="magnified"
          type="text"
          name="adrState"
          placeholder="IL"
          value={adrState}
          onChange={this.updateAdrState}
        />

        <label htmlFor="adrCountry">Country</label>
        <input
          className="magnified"
          type="text"
          name="adrCountry"
          placeholder="United States"
          value={adrCountry}
          onChange={this.updateAdrCountry}
        />

        <label htmlFor="adrPostalCode">Postal Code</label>
        <input
          className="magnified"
          type="text"
          name="adrPostalCode"
          placeholder="60622"
          value={adrPostalCode}
          onChange={this.updateAdrPostalCode}
        />
      </div>
    );
  }

  renderSmartOrDumbAddress() {
    if (this.state.mapsLoaded) {
      return this.renderGeoSuggestedAddress();
    } else {
      return this.renderStandardAddress();
    }
  }

  render() {
    return (
      <fieldset>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAPRfOoJzqa0XyJWMg-oU7CeaMpNbxkOaE&libraries=places"
          onError={this.handleMapScriptError.bind(this)}
          onLoad={this.handleMapScriptLoad.bind(this)}
        />
        <header className="fieldset-header">
          <legend>Business Address</legend>
          <p>Please provide your business’s physical address.</p>
        </header>
        {this.state.mapsLoading ? <Spinner /> : this.renderSmartOrDumbAddress()}
      </fieldset>
    );
  }
}
