import request from "apiClient";
function list(orgId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/workflows`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.workflows,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function create(orgId, data) {
  return request({
    url: `/api/${orgId}/workflows`,
    method: "POST",
    data
  });
}
function update(orgId, workflowId, data) {
  return request({
    url: `/api/${orgId}/workflows/${workflowId}`,
    method: "PUT",
    data
  });
}
function get(orgId, workflowId) {
  return request({
    url: `/api/${orgId}/workflows/${workflowId}`,
    method: "GET"
  });
}
function addUnit(orgId, workflowId, unitId) {
  return request({
    url: `/api/${orgId}/workflows/${workflowId}/add_unit`,
    method: "POST",
    data: { unit_id: unitId }
  });
}
function removeUnit(orgId, workflowId, unitId) {
  return request({
    url: `/api/${orgId}/workflows/${workflowId}/remove_unit`,
    method: "PATCH",
    data: { unit_id: unitId }
  });
}
function unlinked(orgId, workflowId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/workflows/${workflowId}/unlinked`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.units,
      pages: Math.ceil(response.count / limit)
    };
  });
}
function units(orgId, workflowId, limit, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/workflows/${workflowId}/units`,
    method: "GET",
    params: {
      limit,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.units,
      pages: Math.ceil(response.count / limit)
    };
  });
}
const WorkflowService = {
  get,
  list,
  create,
  update,
  units,
  unlinked,
  addUnit,
  removeUnit
};
export default WorkflowService;
