import React from 'react';
import PermissionComponent from 'PermissionComponent';
import {
  ACCOUNTING_NAV_TAGS,
  DASHBOARD_NAV_TAGS,
  DISTRIBUTION_NAV_TAGS,
  INBOX_NAV_TAGS,
  LISTINGS_NAV_TAGS,
  MESSAGING_NAV_TAGS,
  MY_ACCOUNT_NAV_TAGS,
  PAYMENTS_NAV_TAGS,
  PEOPLE_NAV_TAGS,
  RESERVATIONS_NAV_TAGS,
  WEBSITE_NAV_TAGS,
  WORKFLOWS_NAV_TAGS
} from 'sharedNav';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import DashboardHomeLink from 'adminNavbar/dashboard/links/dashboard-home-link';
import InboxLink from 'adminNavbar/inbox/links/inbox-link';
import WebsiteEditorLink from 'adminNavbar/site-editor/links/website-editor-link';
import HelpLink from 'adminNavbar/support/links/help-link';
import LogoutLink from 'adminNavbar/my-account/links/logout-link';
import ListingsLinkMenu from './link-menus/listings/listings-link-menu';
import ReservationsLinkMenu from './link-menus/reservations/reservations-link-menu';
import AccountingLinkMenu from './link-menus/accounting/accounting-link-menu';
import DistributionLinkMenu from './link-menus/distribution/distribution-link-menu';
import WorkflowsLinkMenu from './link-menus/workflows/workflows-link-menu';
import PeopleLinkMenu from './link-menus/people/people-link-menu';
import MessagingLinkMenu from './link-menus/messaging/messaging-link-menu';
import MyAccountMobileLinkMenu from './link-menus/my-account/my-account-mobile-link-menu';
import PaymentsLinkMenu from './link-menus/payments/payments-link-menu';

const DefaultMobile = props => {
  return (
    <Navbar.Mobile reveal={props.reveal}>
      <DashboardHomeLink {...props} navTags={DASHBOARD_NAV_TAGS} />
      <ListingsLinkMenu {...props} navTags={LISTINGS_NAV_TAGS} />
      <ReservationsLinkMenu {...props} navTags={RESERVATIONS_NAV_TAGS} />
      <PermissionComponent user={props.user} permission="messages">
        <InboxLink {...props} navTags={INBOX_NAV_TAGS} />
      </PermissionComponent>
      <PermissionComponent user={props.user} permission="view_accounting">
        {props.user.role_name === 'organization_owner' ? (
          <AccountingLinkMenu {...props} navTags={ACCOUNTING_NAV_TAGS} />
        ) : (
          <PaymentsLinkMenu {...props} navTags={PAYMENTS_NAV_TAGS} />
        )}
      </PermissionComponent>
      <DistributionLinkMenu {...props} navTags={DISTRIBUTION_NAV_TAGS} />
      <WorkflowsLinkMenu {...props} navTags={WORKFLOWS_NAV_TAGS} />
      <PeopleLinkMenu {...props} navTags={PEOPLE_NAV_TAGS} />
      {(props.user.permissions.ledger ||
        props.user.permissions.view_site_editor ||
        props.user.permissions.site_editor_auto_emails) && (
        <>
          <MessagingLinkMenu {...props} navTags={MESSAGING_NAV_TAGS} />
          {(props.user.permissions.ledger ||
            props.user.permissions.view_site_editor) && (
            <PermissionComponent
              user={props.user}
              permission="view_site_editor"
            >
              <WebsiteEditorLink
                {...props}
                navTags={WEBSITE_NAV_TAGS}
                variation="primary"
              />
            </PermissionComponent>
          )}
        </>
      )}
      <MyAccountMobileLinkMenu {...props} navTags={MY_ACCOUNT_NAV_TAGS} />
      <HelpLink {...props} />
      <LogoutLink {...props} variation="primary" />
    </Navbar.Mobile>
  );
};

export default DefaultMobile;
