import React, { useEffect, useState } from 'react';
import {
  IconFontAwesome,
  FlexBox,
  Dropdown,
  Box,
  ListItemLink,
  SlideoutPanel,
  StandByOverlay
} from '@directsoftware/ui-kit-web-admin';
import CreateReservationModal from 'src/admin/components/CreateReservationModal';
import NewWorkOrder from 'src/admin/work_orders/new-work-order';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { resetNewWorkOrder } from 'src/admin/redux/slices/workOrder';
import { selectUserDetails } from 'src/admin/redux/slices/userDetails';
import { useDetectMobile } from 'src/shared/hooks';
import PermissionComponent from 'PermissionComponent';
import WorkOrderService from '../../../api/WorkOrderService';

const GlobalCreate = props => {
  const { isMobile } = useDetectMobile();
  const dispatch = useDispatch();
  const { isRVshareUser } = useSelector(selectUserDetails);
  const workOrder = useSelector(state => state.workOrders.newWorkOrder);
  const [resetKey, setResetKey] = useState(0);
  const [openReservationModal, setOpenReservationModal] = useState(false);
  const [openWorkOrderModal, setOpenWorkOrderModal] = useState(false);
  const [activateSave, setActivateSave] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newWorkOrderID, setNewWorkOrderID] = useState(null);
  const [workOrderError, setWorkOrderError] = useState(false);

  const resetDropdown = () => {
    dispatch(resetNewWorkOrder());
    setResetKey(prevKey => prevKey + 1);
  };

  const renderCreateListing = () => {
    return (
      <ListItemLink
        label="Create Listing"
        onClick={() => resetDropdown()}
        href={`/properties/new${isRVshareUser ? '/?createMode=vehicle' : ''}`}
        type="router-link"
        removeBorderRadius
        removeBottomMargin
        icon={<IconFontAwesome name="house" iconStyle="regular" />}
        iconPresetColor="blue"
      />
    );
  };

  const resetWorkOrder = () => {
    setOpenWorkOrderModal(false);
    setNewWorkOrderID(false);
    setWorkOrderError(false);
    resetDropdown();
  };

  const onSubmit = () => {
    setIsSaving(true);
    const {
      amount,
      unit_id,
      assignee_id,
      assignee_type,
      wo_type,
      job_type,
      date,
      time,
      description,
      title,
      requested_by,
      booking_id,
      task_types
    } = workOrder;

    const data = {
      amount_cents: parseFloat(amount) * 100,
      unit_id,
      assignee_type,
      assignee_id,
      wo_type,
      job_type: wo_type === 'maintenance' ? job_type : null,
      due_on: moment(`${date} ${time}`),
      wo_source: `manual`,
      description,
      title,
      task_types: task_types.map(t => t.value),
      requester_id: requested_by?.value,
      requester_type: requested_by?.type,
      booking_id
    };

    WorkOrderService.create(props.organization.id, data)
      .then(response => {
        setIsSaving(false);
        dispatch(resetNewWorkOrder());
        setNewWorkOrderID(response.id);
      })
      .catch(err => {
        setIsSaving(false);
        setWorkOrderError(true);
        /* ToastRef.current.toastError(
          'Work Order not Created',
          'We were unable to create your new work order. Please check that the information you entered is valid.'
        ); */
      });
  };

  useEffect(
    () => {
      const { unit_id, wo_type, date, title, description } = workOrder; // all the required fields
      if (unit_id && wo_type && date && title && description) {
        setActivateSave(true);
      }
    },
    [workOrder]
  );

  return (
    <>
      <FlexBox
        justifyContent="center"
        alignItems="center"
        paddingLeft={isMobile ? 'xxs' : 'xs'}
      >
        <Dropdown
          key={resetKey}
          panelContent={
            <Box style={{ width: 232 }}>
              {isRVshareUser ? (
                renderCreateListing()
              ) : (
                <PermissionComponent
                  user={props.user}
                  permission="view_properties"
                  restriction="properties_read_only"
                >
                  {renderCreateListing()}
                </PermissionComponent>
              )}
              <ListItemLink
                label="Create Reservation"
                onClick={() => {
                  setOpenReservationModal(true);
                  resetDropdown();
                }}
                type="button"
                removeBorderRadius
                removeBottomMargin
                icon={
                  <IconFontAwesome name="conciergeBell" iconStyle="regular" />
                }
                iconPresetColor="blue"
              />
              <ListItemLink
                label="Create User"
                onClick={() => resetDropdown()}
                href="/employees/new"
                type="router-link"
                removeBorderRadius
                removeBottomMargin
                icon={<IconFontAwesome name="userCircle" iconStyle="regular" />}
                iconPresetColor="blue"
              />
              {!isRVshareUser && (
                <PermissionComponent
                  user={props.user}
                  permission="create_work_orders"
                >
                  <ListItemLink
                    label="Create Work Order"
                    onClick={() => {
                      setOpenWorkOrderModal(true);
                      resetDropdown();
                    }}
                    type="button"
                    removeBorderRadius
                    removeBottomMargin
                    icon={
                      <IconFontAwesome
                        name="screwdriverWrench"
                        iconStyle="regular"
                      />
                    }
                    iconPresetColor="blue"
                  />
                </PermissionComponent>
              )}
            </Box>
          }
          alignPanel="bottomRight"
          controlType="icon-button"
          controlLabel={<IconFontAwesome name="plus" />}
          controlProps={{ size: 'dense' }}
        />
      </FlexBox>
      <CreateReservationModal
        reveal={openReservationModal}
        closeButton={() => setOpenReservationModal(false)}
        brands={props.brands}
        channels={props.channels}
        organization={props.organization}
        user={props.user}
      />
      {!isRVshareUser && (
        <SlideoutPanel
          reveal={openWorkOrderModal}
          contentIsScrollable
          closeButtonOnClick={() => resetWorkOrder()}
          closeOnClickOutside
          removeContentPadding
          title="Create Work Order"
          primaryActionProps={{
            actionType: 'button',
            label: 'Create',
            action: () => onSubmit(),
            isDisabled: !activateSave
          }}
          secondaryActionProps={{
            actionType: 'button',
            label: 'Cancel',
            action: () => resetWorkOrder()
          }}
        >
          <StandByOverlay reveal={isSaving} position="absolute" />
          {newWorkOrderID && (
            <StandByOverlay
              reveal
              icon={<IconFontAwesome name="checkCircle" />}
              iconPresetColor="green"
              position="absolute"
              headline="New Work Order Created"
              actionHref={`/dashboard/${
                props.organization.subdomain
              }/workflows/work-orders/${newWorkOrderID}`}
              actionLabel="Manage"
              description="Use the link below to manage your new work order."
              actionHrefOpenNewTab
              actionHrefType="link"
            />
          )}
          {workOrderError && (
            <StandByOverlay
              reveal
              icon={<IconFontAwesome name="times" />}
              iconPresetColor="red"
              position="absolute"
              headline="Work Order Not Created"
              actionOnClick={() => {
                setWorkOrderError(false);
              }}
              actionLabel="Change Info"
              description="We were unable to create your new work order. Please check that the information you entered is valid."
            />
          )}
          <NewWorkOrder {...props} />
        </SlideoutPanel>
      )}
    </>
  );
};

GlobalCreate.propTypes = {};

export default GlobalCreate;
