import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { GLOBAL_SEARCH_NAV_TAGS } from '../../../../../shared/nav';

const DetailedSearchVehiclesHeader = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: GLOBAL_SEARCH_NAV_TAGS.PRIMARY
      })
    );
  }, []);

  return (
    <PageHeader>
      <PageHeader.Left>
        Global Search Results: <span>Vehicles</span>
      </PageHeader.Left>
    </PageHeader>
  );
};

export default DetailedSearchVehiclesHeader;
