import React from 'react';
import PropTypes from 'prop-types';

import ConfirmLink from 'sharedComponents/ConfirmLink';
import { ajaxDataWithAuthorization } from 'apiClient';
import {
  ConfirmDialog,
  Dropdown,
  IconFontAwesome,
  StandByOverlay,
  VisibilityHelper
} from '@directsoftware/ui-kit-web-admin';
import slice from 'lodash/slice';
import { dispatchError } from '../../../components/error-alert';

export default class ManageUnitCommandPalette extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired
  };

  constructor(props, _railsContext) {
    super(props);
    this.state = {
      optionsToggled: false,
      showDuplicateUnit: false,
      showDeleteDialog: false,
      showDeleteOverlay: false
    };
  }

  toggleOptions = e => {
    e.preventDefault();
    this.setState({ optionsToggled: !this.state.optionsToggled });
  };

  deleteUnit = () => {
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'DELETE',
        url: `/api/${this.props.organization.id}/units/${this.props.unit.id}`,
        context: this,
        cache: false
      })
    )
      .done(function(data) {
        window.location = `/dashboard/${
          this.props.organization.subdomain
        }/properties/${this.props.property.id}`;
      })
      .fail(function(jqXhr) {
        dispatchError();
        console.error(jqXhr);
      });
  };

  duplicateUnit = () => {
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'POST',
        url: `/api/${this.props.organization.id}/units/${
          this.props.unit.id
        }/copy`,
        context: this,
        cache: false
      })
    )
      .done(function(data) {
        window.location = `/dashboard/${
          this.props.organization.subdomain
        }/properties/${this.props.property.id}`;
      })
      .fail(function(jqXhr) {
        console.error(jqXhr);
      });
  };

  returnPanelContent = () => {
    const {
      user: { permissions }
    } = this.props;
    const viewerCanAccessReservations =
      permissions.view_reservations && !permissions.properties_read_only;
    const panelContent = [
      {
        label: 'Manage Images',
        href: `/properties/${this.props.property.id}/units/${
          this.props.unit.id
        }/images`,
        type: 'router-link',
        addPadding: true
      },
      {
        label: 'Manage Availability',
        href: `/properties/${this.props.property.id}/units/${
          this.props.unit.id
        }/availability`,
        type: 'router-link',
        addPadding: true
      },
      {
        label: 'Manage Availability Calendar',
        href: `/properties/${this.props.property.id}/units/${
          this.props.unit.id
        }/availability-calendar`,
        type: 'router-link',
        addPadding: true
      },
      {
        label: 'Duplicate Unit',
        type: 'button',
        addPadding: true,
        onClick: () => {
          this.setState({ showDuplicateUnit: true });
        }
      },
      {
        label: 'View Reservations',
        type: 'router-link',
        href: `/reservations?property_id=${this.props.property.id}`,
        addPadding: true
      }
    ];

    if (this.props.unit.direct_ical_url) {
      panelContent.push({
        label: 'Download iCal',
        type: 'link',
        href: this.props.unit.direct_ical_url,
        addPadding: true,
        openNewTab: true
      })
    }
    if (this.props.user.admin) {
      panelContent.push({
        addDivider: true
      });
      panelContent.push({
        label: 'Delete Listing',
        type: 'button',
        onClick: () => {
          this.setState({
            showDeleteDialog: true
          });
        },
        isDanger: true,
        icon: <IconFontAwesome name="trashAlt" />,
        iconPresetColor: 'red',
        removeBorderRadius: true,
        addPadding: true
      });
    }
    return viewerCanAccessReservations
      ? panelContent
      : slice(panelContent, 0, -1);
  };

  render() {
    return (
      <>
        <StandByOverlay
          reveal={this.state.showDeleteOverlay}
          position="fixed"
          headline="Deleting this Listing..."
          description={`You'll be redirected when it's done.`}
        />
        {/* <DeleteButton
          action={this.deleteUnit}
          confirmMessage={`Are you sure you want to delete ${
            this.props.unit.name
          }? This will delete all associated listings on any brand.`}
          confirmText="Delete"
          cancelText="Cancel"
          can_delete={this.props.user.can_delete}
        >
          <IconButton appearance="ghost" variation="danger">
            <IconFontAwesome name="trashAlt" />
          </IconButton>
        </DeleteButton> */}
        <VisibilityHelper showStartingFrom="tablet">
          <Dropdown
            controlLabel="Actions"
            panelWidthVariation="auto"
            controlType="call-to-action-button"
            alignPanel="bottomRight"
            panelContent={this.returnPanelContent()}
          />
        </VisibilityHelper>
        <VisibilityHelper hideStartingFrom="tablet">
          <Dropdown
            controlLabel={<IconFontAwesome name="ellipsisV" />}
            controlProps={{
              variation: 'secondary',
              appearance: 'ghost'
            }}
            panelWidthVariation="auto"
            controlType="icon-button"
            alignPanel="bottomRight"
            panelContent={this.returnPanelContent()}
          />
        </VisibilityHelper>
        <ConfirmLink
          action={this.duplicateUnit}
          confirmMessage={`Are you sure you want to duplicate ${
            this.props.unit.name
          }?`}
          confirmText="Duplicate Unit"
          cancelText="Cancel"
          showConfirm={this.state.showDuplicateUnit}
          showConfirmOnClick={() => {
            this.setState({
              showDuplicateUnit: false
            });
          }}
        />
        <ConfirmDialog
          description={`Are you sure you want to delete ${
            this.props.property?.name
          }? This will delete all associated listings on any brand.`}
          confirmOnClick={() => {
            this.deleteUnit();
            this.setState({
              showDeleteDialog: false,
              showDeleteOverlay: true
            });
          }}
          cancelOnClick={() => {
            this.setState({
              showDeleteDialog: false
            });
          }}
          confirmLabel="Yes, Delete"
          title="Delete this Listing?"
          reveal={this.state.showDeleteDialog}
          isDanger
        />
      </>
    );
  }
}
