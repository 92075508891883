import React, { useEffect, useState } from 'react';
import {
  PageHeader,
  IconButton,
  IconFontAwesome,
  CallToActionButton
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import { useDetectMobile } from 'src/shared/hooks';
import CreateReservationModal from 'src/admin/components/CreateReservationModal';
import { RESERVATIONS_NAV_TAGS } from 'sharedNav';
import { updateActiveRoutes } from 'reduxSlices/navigation';

const ReservationsVehiclesCalendar = props => {
  const { isMobile } = useDetectMobile();
  const dispatch = useDispatch();
  const [openCreateReservation, setOpenCreateReservation] = useState(false);

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: RESERVATIONS_NAV_TAGS.PRIMARY,
        secondary: RESERVATIONS_NAV_TAGS.secondary.VEHICLES_CALENDAR_VIEW
      })
    );
  }, []);

  return (
    <PageHeader containerVariation="fluid">
      <PageHeader.Left>
        Reservations: <span>Vehicles Calendar</span>
      </PageHeader.Left>
      <PageHeader.Right>
        {isMobile ? (
          <IconButton
            onClick={() => setOpenCreateReservation(true)}
            size="dense"
          >
            <IconFontAwesome name="plus" />
          </IconButton>
        ) : (
          <CallToActionButton
            onClick={() => setOpenCreateReservation(true)}
            prependIcon={<IconFontAwesome name="plus" />}
          >
            Create
          </CallToActionButton>
        )}
        <CreateReservationModal
          reveal={openCreateReservation}
          closeButton={() => setOpenCreateReservation(false)}
          brands={props.brands}
          channels={props.channels}
          organization={props.organization}
          user={props.user}
        />
      </PageHeader.Right>
    </PageHeader>
  );
};

export default ReservationsVehiclesCalendar;
