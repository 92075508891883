const sortOptions = {
  tax_audit: [
    { label: "Booking Code", value: "", mobileLabel: "Booking" },
    { label: "Post Date", value: `post_date`, mobileLabel: "Post Date" },
    {
      label: "Tax Account",
      value: `ledger_account_id`,
      mobileLabel: "Tax Account"
    }
  ]
};
export default sortOptions;
