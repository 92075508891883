import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { PEOPLE_NAV_TAGS } from '../../../../../shared/nav';

const EmployeesNew = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: PEOPLE_NAV_TAGS.PRIMARY,
        secondary: PEOPLE_NAV_TAGS.secondary.EMPLOYEES
      })
    );
  }, []);

  return (
    <PageHeader containerVariation="fluid" height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/employees',
            label: 'Users'
          },
          {
            label: 'Create User'
          }
        ]}
      >
        Create a User
      </PageHeader.Left>
    </PageHeader>
  );
};

export default EmployeesNew;
