import request from "apiClient";
const createVehicleBooking = ({ bookingData, organization, booking }) => {
  return request({
    url: `/api/${organization.id}/vehicles/${booking.bookable_id}/listings/${booking.listing_id}/bookings`,
    method: "POST",
    data: bookingData
  });
};
const VehicleBookingsService = { createVehicleBooking };
export default VehicleBookingsService;
