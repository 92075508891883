import React from 'react';
import { OrganizationContext } from 'adminContexts';
import moment from 'moment';

export default class OrgFormattedDate extends React.Component {
  render() {
    return (
      <OrganizationContext.Consumer>
        {state =>
          moment(this.props.date).format(
            state.dateFormat(state.organization.global_date_format)
          )
        }
      </OrganizationContext.Consumer>
    );
  }
}

OrgFormattedDate.contextType = OrganizationContext;
