import React from 'react';
import { FormField } from '@directsoftware/ui-kit-web-admin';

export default class InputTimeHandler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || ''
    };
  }

  handleCallback = () => {
    if (this.props.callback) {
      this.props.callback(this.state.value);
    }
  };

  updateTime = e => {
    e.preventDefault();
    this.setState({ value: e.target.value }, () => {
      this.handleCallback();
    });
  };

  render() {
    return (
      <FormField
        labelText={this.props.label}
        labelHtmlFor={this.props.name}
        inputType="text"
        inputProps={{
          value: this.state.value,
          onChange: e => this.updateTime(e),
          type: 'time'
        }}
      />
    );
  }
}
