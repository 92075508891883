// Dependencies
// -----------------------------------------------
import React from 'react';
import Cookies from 'universal-cookie';

// Constants
// -----------------------------------------------
const cookies = new Cookies();

// -----------------------------------------------
// COMPONENT->DONT-SHOW-MODAL-CHECKBOX -----------
// -----------------------------------------------
export default class DontShowModalCheckbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false
    };
  }

  toggleChecked = () => {
    this.setState({ checked: !this.state.checked }, () => {
      if (this.state.checked) {
        cookies.set(this.props.cookieName, true);
      } else {
        cookies.set(this.props.cookieName, false);
      }
    });
  };

  render() {
    return (
      <figure
        className="checkbox"
        style={{ position: 'relative', zIndex: 99999999 }}
      >
        <input
          type="checkbox"
          name="dontShow"
          id="dontShow"
          onChange={this.toggleChecked}
        />
        <label htmlFor="dontShow">
          <span>Please don't show this message again.</span>
        </label>
      </figure>
    );
  }
}
