import React from 'react';
import { Box, Divider, Navbar, Spacer } from '@directsoftware/ui-kit-web-admin';
import MyAccountLink from 'adminNavbar/my-account/links/my-account-link';
import MyOrganizationsLink from 'adminNavbar/my-account/links/my-organizations-link';
import MySettingsLink from 'adminNavbar/my-account/links/my-settings-link';
import LogoutLink from 'adminNavbar/my-account/links/logout-link';
import MySettingsBillingLink from 'adminNavbar/my-account/links/my-settings-billing-link';
import MySettingsIntegrationsLink from 'adminNavbar/my-account/links/my-settings-integrations-link';

const LinksDesktop = props => {
  return (
    <>
      <Navbar.LinkMenuColumn>
        <MyAccountLink {...props} />
        {props.user.admin && <MyOrganizationsLink {...props} />}
        <Box className="nav-v3-column-divider">
          <Divider />
        </Box>
        <Spacer size="xxs" />
        <Navbar.LinkMenuGroup groupTitle="Settings" removeBottomMargin>
          <MySettingsLink {...props} />
          {props.user.role >= 4 && (
            <>
              <MySettingsBillingLink {...props} />
              <MySettingsIntegrationsLink {...props} />
            </>
          )}
        </Navbar.LinkMenuGroup>
        <Box className="nav-v3-column-divider">
          <Divider />
        </Box>
        <LogoutLink {...props} />
      </Navbar.LinkMenuColumn>
    </>
  );
};

export default LinksDesktop;
