import styled from "styled-components";
export const ReviewCardWrapper = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  box-shadow: 2px 2px 5px -2px rgba(194, 194, 194, 1);
  padding: 25px;
  margin-bottom: 15px;
`;
export const ReviewCardHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10%;
`;
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;
export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
`;
export const PictureFrame = styled.div`
  background-image: ${(props) => `url(${props.url})`};
  background-size: cover;
  height: 50px;
  width: 50px;
  background-position: center;
  border-radius: 25px;
`;
export const Date = styled.div`
  color: #bbc5c7;
  font-size: 0.7em;
`;
export const Status = styled.div`
  text-transform: uppercase;
  font-size: 0.8em;
  color: ${(props) => props.status === "published" ? "green" : props.status === "rejected" ? "red" : "blue"};
`;
export const StarContainer = styled.span`
  .react-rater {
    display: flex;
    justify-content: center;
  }

  .react-rater-star {
    font-size: 16px;
    margin-right: 4px;
    position: relative;
    color: white;
    text-shadow: -1px -1px 0 #e5e2e2, 1px -1px 0 #e5e2e2, -1px 1px 0 #e5e2e2,
      1px 1px 0 #e5e2e2;

    &.will-be-active,
    &.is-active {
      color: gold;

      text-shadow: none;
    }

    &.is-active-half::before {
      color: gold;
      content: '\u2605';
      position: absolute;
      left: 0;
      width: 50%;
      overflow: hidden;
    }
  }
`;
export const RatingContainer = styled(StarContainer)`
  .react-rater {
    justify-content: flex-start;
  }
`;
