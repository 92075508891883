import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SiteEditorHome from './site-editor-home';
import SiteEditorEdit from './site-editor-edit';
import NewWebsite from './new-website';
import PageEditHome from './page-edit-home';
import PageEdit from './page-edit';

const SiteEditorRoutes = props => {
  return (
    <Switch>
      <Route path="/brands/new" render={() => <NewWebsite {...props} />} />
      <Route
        path="/brands/:brandId/pages/home"
        render={routeProps => (
          <PageEditHome {...props} brandId={routeProps.match.params.brandId} />
        )}
      />
      <Route
        path="/brands/:brandId/pages/:pageId/edit"
        render={routeProps => {
          return (
            <PageEdit
              brandId={routeProps.match.params.brandId}
              pageId={routeProps.match.params.pageId}
              {...props}
            />
          );
        }}
      />
      <Route
        path="/brands/:brandId"
        render={routeProps => (
          <SiteEditorEdit
            {...props}
            brandId={routeProps.match.params.brandId}
          />
        )}
      />
      <Route path="/brands" render={() => <SiteEditorHome {...props} />} />
    </Switch>
  );
};

export default SiteEditorRoutes;
