import React from 'react';
import sortBy from 'lodash/sortBy';
import isNull from 'lodash/isNull';
import SelectService from 'adminApi/SelectService';
import MultiUnitCodeDisplay from './multi-unit-code-display';
import { FormField, InputSelect } from '@directsoftware/ui-kit-web-admin';

export default class UnitSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { unitOptions: [], selectedOption: '' };
  }

  componentDidMount() {
    this.fetchUnits();
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    this.props.handleSelect('unit_id', selectedOption.value);
  };

  fetchUnits() {
    const data = {
      model: 'Unit',
      select: [
        'id',
        'name',
        'units.unit_code as unit_unit_code',
        'properties.name as property_name',
        'properties.multi_unit as multi_unit',
        'properties.unit_code as property_unit_code'
      ],
      joins: ['inner join properties on properties.id = units.property_id']
    };
    SelectService.index(this.props.organization.id, data)
      .then(response => {
        const unitOptions = sortBy(response.options, ['property_name']).map(
          option => {
            if (option.multi_unit) {
              return {
                value: option.id,
                label: MultiUnitCodeDisplay(
                  option.unit_unit_code,
                  option.property_unit_code,
                  `${option.property_name} | ${option.name}`
                )
              };
            } else {
              return {
                value: option.id,
                label: MultiUnitCodeDisplay(
                  option.unit_unit_code,
                  option.property_unit_code,
                  option.name
                )
              };
            }
          }
        );
        this.setState({ unitOptions });
      })
      .catch(err => displayError({ message: 'Error retrieving units', err }));
  }

  getName = (multiUnit, option) => {
    if (multiUnit) {
      if (!isNull(option.unit_code)) {
        return `${option.property_name} | ${option.name} | ${option.unit_code}`;
      } else {
        return `${option.property_name} | ${option.name}`;
      }
    } else {
      if (!isNull(option.p_unit_code)) {
        return `${option.property_name} | ${option.p_unit_code}`;
      } else {
        return option.property_name;
      }
    }
  };

  setDefaultValue = () => {
    const { options } = this.state;
    if (this.props.defaultValue) {
      return this.props.defaultValue(options);
    }
  };

  render() {
    const { selectedOption } = this.state;
    return this.props.useDesignKit ? (
      <FormField
        inputProps={{
          value: this.state.unitOptions.find(
            item => item.value === selectedOption
          ),
          onChange: ({ value, label }) => {
            this.handleChange({ value });
          },
          options: this.state.unitOptions,
          placeholder: 'Select a unit...'
        }}
        labelText="Unit"
        inputType="select"
        labelHtmlFor="unit"
        {...this.props}
      />
    ) : (
      <div>
        <InputSelect
          name="unit"
          placeholder="Select a unit..."
          options={this.state.unitOptions}
          onChange={this.handleChange}
          value={selectedOption}
          defaultValue={this.setDefaultValue()}
          {...this.props}
        />
      </div>
    );
  }
}
