// Dependencies
// -----------------------------------------------
import React from 'react';

// Components
// -----------------------------------------------
import { SimplifiedWysiwyg } from 'adminComponents';

// -----------------------------------------------
// COMPONENT->OVERVIEW-SINGLE-FIELDS-RULES -------
// -----------------------------------------------
const OverviewSingleFieldsRules = props => {
  const onWysiwygChange = (name, value) => {
    props.onSimpleChange(name, value);
  };

  // ---------------------------------------------
  // RETURN --------------------------------------
  // ---------------------------------------------
  return (
    <fieldset>
      <header className="fieldset-header">
        <legend>Accommodations and Rules</legend>
      </header>

      <label htmlFor="description">
        <span>Accommodations</span>
      </label>
      <SimplifiedWysiwyg
        content={props.summary_accommodations}
        name="summary_accommodations"
        onContentChange={onWysiwygChange}
      />

      <label htmlFor="description">
        <span>Rules</span>
      </label>
      <SimplifiedWysiwyg
        content={props.summary_rules}
        name="summary_rules"
        onContentChange={onWysiwygChange}
      />
    </fieldset>
  );
};

// -----------------------------------------------
// EXPORT ----------------------------------------
// -----------------------------------------------
export default OverviewSingleFieldsRules;
