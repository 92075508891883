import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './dashboard';

const DashboardRoutes = () => {
  return (
    <Switch>
      <Route path="/new" exact render={() => <Dashboard />} />
      <Route path="/" exact render={() => <Dashboard />} />
    </Switch>
  );
};

export default DashboardRoutes;
