import React from 'react';
import DefaultLeft from './linkCollections/default/default-left';
import RvFleetLeft from './linkCollections/rv-fleet/rv-fleet-left';

const NavbarLeft = props => {
  const renderLinkCollection = () => {
    switch (props.linkCollection) {
      case 'rv-fleet':
        return <RvFleetLeft {...props} />;
      case 'admin':
      default:
        return <DefaultLeft {...props} />;
    }
  };
  return <>{renderLinkCollection()}</>;
};

export default NavbarLeft;
