import React, { useEffect, useRef } from 'react';
import {
  Box,
  CallToActionButton,
  ChannelLogo,
  FlexBox,
  IconFontAwesome,
  Panel,
  Toast
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ChannelService, ChannelMappingService } from 'adminApi';
import { listVehicles } from 'adminApi/VehicleService';
import { useDetectMobile } from '../../../../shared/hooks';
import {
  selectUserDetails,
  updateUserDetails
} from '../../../redux/slices/userDetails';
import { updateChannelsRvShareUI } from '../../../redux/slices/channels/channels';

const RVshareOnboardingBanner = ({ variation, label }) => {
  const { isLargeTabletOrGreater } = useDetectMobile();
  const dispatch = useDispatch();
  const ToastRef = useRef();
  const userDetails = useSelector(selectUserDetails);
  const rvShareIsSyncing = useSelector(
    state => state.channels.rvshare.ui.isSyncing
  );
  let checkBookings = null;

  const fetchVehicles = () => {
    const params = {
      search: undefined,
      page: 1,
      limit: 20
    };
    return listVehicles({ orgId: userDetails.organization.id, params })
      .then(data => {
        return data.totalCount > 0;
      })
      .finally(() => console.log('no bookings synced yet...'));
  };

  const importListings = () => {
    dispatch(updateChannelsRvShareUI({ isSyncing: true }));
    return ChannelService
      .importListings(
        userDetails.organization.id,
        6,
        userDetails.rvshare.external_id
      )
      .then(() => {
        ToastRef.current.toastSuccess(
          'RVshare Sync Started',
          'This process may take some time to complete, depending on the amount of data we need to import. We will send an email confirmation when your import is complete.',
          null,
          Infinity
        );
        ChannelMappingService.update(
          userDetails.organization.id,
          6,
          userDetails.rvshare.id,
          {
            ...userDetails.rvshare.mapping_data,
            channel_data: {
              ...userDetails.rvshare.mapping_data.channel_data,
              hasAttemptedSync: true
            }
          }
        )
          .then(() => {})
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        ToastRef.current.toastError(
          'Could Not Start RVshare Sync',
          err.response.data.message
        );
      });
  };

  useEffect(
    () => {
      let attempts = 0;
      if (rvShareIsSyncing && !userDetails.rvshare.hasAttemptedSync) {
        checkBookings = setInterval(() => {
          attempts += 1;
          if (attempts > 5) {
            clearInterval(checkBookings);
            dispatch(updateChannelsRvShareUI({ isSyncing: false }));
            dispatch(
              updateUserDetails({
                rvshare: {
                  ...userDetails.rvshare,
                  hasAttemptedSync: true,
                  syncMonitor: 'attempt limit'
                }
              })
            );
          } else {
            fetchVehicles().then(response => {
              if (response) {
                clearInterval(checkBookings);
                dispatch(updateChannelsRvShareUI({ isSyncing: false }));
                dispatch(
                  updateUserDetails({
                    rvshare: {
                      ...userDetails.rvshare,
                      hasAttemptedSync: true,
                      syncMonitor: 'success'
                    }
                  })
                );
              }
            });
          }
        }, 5000);
      }
    },
    [rvShareIsSyncing]
  );

  useEffect(() => {
    return () => {
      clearInterval(checkBookings);
    };
  }, []);

  return (
    <>
      <Toast ref={ToastRef} />
      {variation === 'banner' ? (
        <Panel addShadow>
          <FlexBox className="rvshareOnboarding__banner">
            <FlexBox>
              <FlexBox
                alignItems="center"
                justifyContent="center"
                className="rvshareOnboarding__banner_rvshareLogo"
              >
                <ChannelLogo
                  channel="rvshare"
                  presetHeight="custom"
                  customHeight={32}
                />
              </FlexBox>
              <FlexBox
                alignItems="center"
                justifyContent="center"
                className="rvshareOnboarding__banner_directLogo"
              >
                <ChannelLogo
                  channel="direct"
                  presetHeight="custom"
                  customHeight={32}
                />
              </FlexBox>
            </FlexBox>
            <FlexBox
              flex="1"
              alignItems="center"
              justifyContent={isLargeTabletOrGreater ? 'flex-start' : 'center'}
              paddingHorizontal="s"
              className="rvshareOnboarding__banner_text"
            >
              Syncing your account will import your RVshare data, including
              Vehicles, Bookings, Messages, and more!
            </FlexBox>
            <FlexBox
              alignItems="center"
              justifyContent="center"
              paddingHorizontal="s"
              className="rvshareOnboarding__banner_button"
            >
              {rvShareIsSyncing ? (
                <Box className="rvshareOnboarding__banner_spinner">
                  <IconFontAwesome name="spinner" spin />
                </Box>
              ) : (
                <CallToActionButton
                  onClick={() => importListings()}
                  prependIcon={<IconFontAwesome name="rotate" />}
                  isFullWidth={!isLargeTabletOrGreater}
                >
                  {label}
                </CallToActionButton>
              )}
            </FlexBox>
          </FlexBox>
        </Panel>
      ) : (
        <CallToActionButton
          onClick={() => importListings()}
          prependIcon={
            <IconFontAwesome name="rotate" spin={rvShareIsSyncing} />
          }
          isFullWidth={!isLargeTabletOrGreater}
          isDisabled={rvShareIsSyncing}
        >
          {label}
        </CallToActionButton>
      )}
    </>
  );
};

RVshareOnboardingBanner.propTypes = {
  variation: PropTypes.oneOf(['banner', 'button']),
  label: PropTypes.string
};

RVshareOnboardingBanner.defaultProps = {
  variation: 'banner',
  label: 'Sync Account'
};

export default RVshareOnboardingBanner;
