import React from 'react';
import MyAccountLink from 'adminNavbar/my-account/links/my-account-link';
import MyOrganizationsLink from 'adminNavbar/my-account/links/my-organizations-link';
import MySettingsLink from 'adminNavbar/my-account/links/my-settings-link';
import MySettingsBillingLink from 'adminNavbar/my-account/links/my-settings-billing-link';
import MySettingsIntegrationsLink from 'adminNavbar/my-account/links/my-settings-integrations-link';

const LinksMobile = props => {
  return (
    <>
      <MyAccountLink {...props} />
      <MySettingsLink {...props} />
      {props.user.admin && <MyOrganizationsLink {...props} />}
      {props.user.role >= 4 && (
        <>
          <MySettingsBillingLink {...props} />
          <MySettingsIntegrationsLink {...props} />
        </>
      )}
    </>
  );
};

export default LinksMobile;
