import React from 'react';
import ErrorAlert from './error-alert';

export default class PseudoModal extends React.Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      forceClose: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.forceClose) {
      this.setState({ forceClose: true });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
  };

  renderSubmitAction = () => {
    if (this.props.closeOnSubmit) {
      return <li>{this.props.submitAction(this.props.closePortal)}</li>;
    }
    return <li>{this.props.submitAction}</li>;
  };

  renderCancelAction = () => {
    if (this.props.cancelAction) {
      return <li>{this.props.cancelAction(this.props.closePortal)}</li>;
    }

    return (
      <li>
        <a className="negative" onClick={this.props.closePortal}>
          Cancel
        </a>
      </li>
    );
  };

  render() {
    return (
      <aside className="consolidated-modal-overlay">
        {!this.props.header && (
          <a
            className="consolidated-modal-close"
            onClick={this.props.closePortal}
          >
            ×
          </a>
        )}
        <ErrorAlert />
        <section className="consolidated-modal" style={this.props.modalStyles}>
          {this.props.header ? (
            <header className="consolidated-modal-header">
              <span>{this.props.header}</span>
              <a
                className="consolidated-modal-close"
                onClick={this.props.closePortal}
              >
                ×
              </a>
            </header>
          ) : null}
          {this.props.children}
          {this.state.forceClose && <a onClick={this.props.closePortal()} />}
          {this.props.submitAction && (
            <ul style={this.props.actionStyles}>
              {this.renderSubmitAction()}
              {this.renderCancelAction()}
            </ul>
          )}
        </section>
      </aside>
    );
  }
}
