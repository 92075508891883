import request from "apiClient";
export default class AirbnbService {
  static connectParams(organizationID) {
    return request({
      url: `/api/${organizationID}/airbnb/connect_params`,
      method: "GET"
    });
  }
  static resyncCalendars(organizationID) {
    return request({
      url: `/api/${organizationID}/airbnb/sync_calendars`,
      method: "GET"
    });
  }
  static airbnbLogs(organizationId, params) {
    return request({
      url: `/api/${organizationId}/airbnb_logs`,
      method: "GET",
      params
    });
  }
}
