import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import classNames from 'classnames';

const legendData = [
  {
    key: 'available',
    label: 'Instant Bookable',
    LegendIcon: () => (
      <i className="key-item" style={{ backgroundColor: '#ffffff' }} />
    )
  },
  {
    key: 'inquiry',
    label: 'Bookable by request',
    LegendIcon: () => (
      <i className="key-item" style={{ backgroundColor: '#5794E4' }} />
    )
  },
  {
    key: 'unavailable',
    label: 'Unavailable',
    LegendIcon: () => (
      <i className="key-item" style={{ backgroundColor: '#FB5872' }} />
    )
  },
  {
    key: 'custom',
    label: 'Custom availability set',
    LegendIcon: () => (
      <i
        className="key-item"
        style={{ backgroundColor: '#ffffff', position: 'relative' }}
      >
        <div className="calendar-day-info-edited" />
      </i>
    )
  }
];

function filterLegendData(legendKeys) {
  if (!legendKeys) return legendData;

  return legendData.filter(({ key }) => legendKeys.includes(key));
}

export default class CalendarStepper extends React.Component {
  static propTypes = {
    steps: PropTypes.array.isRequired,
    startingStepIndex: PropTypes.number,
    legendKeys: PropTypes.array
  };

  constructor(props, _railsContext) {
    super(props);

    this.state = {
      calendar: this.props.calendar,
      currentStepIndex: this.props.startingStepIndex || 0,
      hintsVisible: true
    };
  }

  componentDidUpdate(prevProps) {
    if (this.state.currentStepIndex !== prevProps.currentStepIndex) {
      this.setStep(prevProps.currentStepIndex);
    }
  }

  setStep = currentStepIndex => {
    this.setState({
      currentStepIndex
    });
  };

  toggleHintsVisiblity = e => {
    e.preventDefault();
    const hintsVisible = this.state.hintsVisible;

    this.setState({
      hintsVisible: !hintsVisible
    });
  };

  jumpToStep = (e, stepIndex) => {
    e.preventDefault();
    const { steps } = this.props;
    const currentStep = steps[this.state.currentStepIndex];
    if (
      stepIndex > this.state.currentStepIndex &&
      currentStep.handleIncrement
    ) {
      currentStep.handleIncrement.function(currentStep.handleIncrement.params);
      return;
    } else if (
      stepIndex < this.state.currentStepIndex &&
      currentStep.handleDecrement
    ) {
      currentStep.handleDecrement.function(currentStep.handleDecrement.params);
      return;
    }
    this.props.setStepIndex(stepIndex);

    this.setState({ currentStepIndex: stepIndex });
  };

  onSelectStep = e => {
    const stepIndex = parseInt(e.target.value);
    this.jumpToStep(e, stepIndex);
  };

  renderStepLabels() {
    const steps = this.props.steps;
    const currentStep = steps[this.state.currentStepIndex];
    return (
      <header className="stepper-step-labels">
        <MediaQuery maxWidth={639}>
          <div className="select-wrapper">
            <select
              value={this.state.currentStepIndex}
              name="current_step"
              onChange={this.onSelectStep}
              disabled={currentStep.disabled}
            >
              {steps.map(step => (
                <option key={step.index} value={step.index}>
                  {step.label}
                </option>
              ))}
            </select>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={640} maxWidth={799}>
          <ul>
            {steps.map(step => (
              <li
                key={step.index}
                className={
                  step.index == this.state.currentStepIndex ? 'active' : ''
                }
              >
                <a
                  href="#"
                  className={classNames({ disabled: currentStep.disabled })}
                  onClick={e => this.jumpToStep(e, step.index)}
                >
                  {step.label}
                </a>
              </li>
            ))}
          </ul>
        </MediaQuery>
        <MediaQuery minWidth={800} maxWidth={1279}>
          <div className="select-wrapper">
            <select
              value={this.state.currentStepIndex}
              name="current_step"
              onChange={this.onSelectStep}
              disabled={currentStep.disabled}
            >
              {steps.map(step => (
                <option key={step.index} value={step.index}>
                  {step.label}
                </option>
              ))}
            </select>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1280}>
          <ul>
            {steps.map(step => (
              <li
                key={step.index}
                className={
                  step.index == this.state.currentStepIndex ? 'active' : ''
                }
              >
                <a
                  href="#"
                  className={classNames({ disabled: currentStep.disabled })}
                  style={{
                    color:
                      step !== currentStep && currentStep.disabled && '#E5E2E2'
                  }}
                  onClick={e => this.jumpToStep(e, step.index)}
                >
                  {step.label}
                </a>
              </li>
            ))}
          </ul>
        </MediaQuery>
      </header>
    );
  }

  render() {
    const { steps, calendar } = this.props;
    const currentStep = steps[this.state.currentStepIndex];

    return (
      <figure className="stepper">
        <ReactCSSTransitionGroup
          transitionName="stepper-transition"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={1}
          component="main"
        >
          <div key={currentStep.index}>
            {this.props.showStepLabels ? this.renderStepLabels() : null}
            <section className="wrapper">
              {this.props.title ? (
                <section className="consolidated-item-command-palette">
                  <div className="consolidated-item-command-palette-content">
                    <header className="consolidated-item-command-palette-breadcrumbs">
                      <span className="consolidated-item-command-palette-heading">
                        {this.props.title}
                      </span>
                    </header>
                  </div>
                </section>
              ) : null}
              {currentStep.component}
            </section>
          </div>
        </ReactCSSTransitionGroup>
        {!this.props.hideCalendar && (
          <MediaQuery query="(max-width: 799px)">
            {!this.props.editingForm && (
              <aside>
                <figure className="card" style={{ margin: 0 }} key={0}>
                  {calendar}
                </figure>
              </aside>
            )}
          </MediaQuery>
        )}
        <MediaQuery query="(min-width: 800px)">
          <ReactCSSTransitionGroup
            transitionName="stepper-transition"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={1}
            component="aside"
          >
            <div>
              <section className="consolidated-key">
                <ul>
                  {filterLegendData(this.props.legendKeys).map(
                    ({ key, label, LegendIcon }) => (
                      <li key={key}>
                        <LegendIcon />
                        <span className="key-descriptor">{label}</span>
                      </li>
                    )
                  )}
                </ul>
              </section>
              <figure className="card" style={{ margin: 0 }} key={0}>
                {calendar}
              </figure>
            </div>
          </ReactCSSTransitionGroup>
        </MediaQuery>
      </figure>
    );
  }
}
