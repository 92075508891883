import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  padding: 6px 8px 4px;
  border-radius: 4px;
  width: min-content;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  white-space: 'nowrap';
  height: 2.5em
    ${props =>
      props.tag === 'vip' &&
      `
    background-color: #5794E4;
  `}
    ${props =>
      props.tag === 'blacklisted' &&
      `
    background-color: #404040;
  `};
`;

const LeadTag = ({ lead }) => {
  if (!lead || !lead.tag) return null;

  return <Container tag={lead.tag}>{lead.tag}</Container>;
};

export default LeadTag;
