import React from 'react';

import SearchableSelect from './SearchableSelect';

const defaultSelectData = {
  model: 'Booking',
  select: ['id', 'booking_code']
};

const BookingSelect = props => (
  <SearchableSelect
    selectData={{ ...defaultSelectData, ...props.defaultSelectData }}
    label={'Booking'}
    placeholder={'Search by code...'}
    name={'booking_select'}
    optionLabelKey={'booking_code'}
    searchableKeys={['booking_code']}
    {...props}
  />
);

export default BookingSelect;
