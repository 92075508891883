// PROPS THAT CAN BE PASSED TO THIS COMPONENT
// devRestriction = when we want to make certain features and such available on local machines and staging, but hide them in production
const DevRestrictionComponent = props => {
  if (
    window.location.hostname !== 'www.lvh.me' &&
    window.location.hostname !== 'staging.getdirect.io'
  ) {
    return null;
  }

  return props.children;
};

export default DevRestrictionComponent;
