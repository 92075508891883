import React from 'react';
import moment from 'moment';
import get from 'lodash/get';

import OpportunityDisplayValue from 'adminComponents/OpportunityDisplayValue';
import UnitAvatar from 'adminComponents/Avatars/UnitAvatar';
import { Card } from '../SectionsStyles';
import OpportunityStatusLabel from './OpportunityStatusLabel';

const dates = (checkIn, checkOut) => {
  if (!checkIn || !checkOut) return null;

  return (
    <div style={{ fontSize: '12px', color: '#606B6F' }}>
      {moment(checkIn).format('MMMM DD')}-{moment(checkOut).format('DD, YYYY')}
    </div>
  );
};

const OpportunityCard = ({ opportunity, setActiveOpportunity }) => {
  const { unit, currency, value, check_in, check_out } = opportunity;

  return (
    <Card onClick={() => setActiveOpportunity(opportunity.id)}>
      <div style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '16px'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <UnitAvatar imageUrl={get(unit, 'featured_image_url')} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0 20px'
              }}
            >
              <div style={{ fontWeight: '500' }}>
                {get(unit, 'display_name', 'No Unit')}
              </div>
              <div style={{ fontWeight: '500' }}>
                {get(unit, 'unit_code', 'No Unit Code')}
              </div>
              {dates(check_in, check_out)}
            </div>
            {dates(check_in, check_out)}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <OpportunityDisplayValue
            currency={currency}
            value={value}
            withColors
          />
          <div
            className="icon-default"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 0 0 25px'
            }}
          >
            {/* set a tag here to open opportunity view */}
            <img
              style={{ height: '15px', width: 'auto' }}
              alt="arrow-right"
              src="/assets/brand/arrow-right.png"
            />
          </div>
        </div>
      </div>
      <OpportunityStatusLabel
        opportunity={opportunity}
        styles={{ margin: 0 }}
      />
    </Card>
  );
};

export default OpportunityCard;
