import React from 'react';
import {
  Box,
  ChannelLogo,
  FlexBox,
  IconFontAwesome,
  Panel,
  Spacer,
  TextBody,
  TextCaption,
  Pill
} from '@directsoftware/ui-kit-web-admin';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { getChannelLogoTag } from '../../shared/utils';

const ReservationCard = ({
  booking,
  orgSubdomain,
  isDeparting,
  isArriving
}) => {
  const isVehicle = booking.vehicle_booking;
  const bookingUrl = `/dashboard/${orgSubdomain}/reservations/${
    booking.booking_code
  }`;

  const getTimeRemaining = booking => {
    const expirationDate = moment(booking.created_at).add(1, 'days');
    const timeRemaining = moment().isAfter(expirationDate)
      ? 'Expired'
      : moment(expirationDate).fromNow();
    return timeRemaining;
  };

  const bookingStatusPill = booking => {
    if (booking.confirmed && !booking.cancelled && !booking.archived) {
      return <Pill color="green">Confirmed</Pill>;
    } else if (booking.cancelled && !booking.archived) {
      return <Pill color="red">Cancelled</Pill>;
    } else if (booking.generated_as_type === 'request' && !booking.archived) {
      return <Pill color="blue">Request</Pill>;
    } else if (booking.archived) {
      return <Pill color="gray">Archived</Pill>;
    } else if (getTimeRemaining(booking) === 'Expired') {
      return <Pill color="gray">Expired</Pill>;
    } else {
      return null;
    }
  };

  return (
    <Panel padding="s" setPositionRelative className="reservationCard">
      <Box
        renderAs="a"
        href={bookingUrl}
        className="reservationCard__link"
        target="_blank"
      />
      <FlexBox justifyContent="space-between" alignItems="center">
        <FlexBox alignItems="center" gap="xs">
          <FlexBox
            className={booking.channel_id ? '' : 'reservationCard__directLogo'}
            justifyContent="center"
            alignItems="center"
          >
            <ChannelLogo
              channel={getChannelLogoTag(booking.channel_id)}
              variation="icon"
              presetHeight="s"
            />
          </FlexBox>
          <Box>
            <TextCaption textColor="light-gray">
              {booking.booking_code}
            </TextCaption>
          </Box>
          <Box>{bookingStatusPill(booking)}</Box>
        </FlexBox>
        <Box className="reservationCard__icon">
          <IconFontAwesome name="chevronRight" />
        </Box>
      </FlexBox>
      <Spacer />
      <TextBody weight="semibold" textColor="dark-gray" isFullWidth>
        {isVehicle ? booking.vehicle_name : booking.property_name}
      </TextBody>
      <Spacer size="xxs" />
      <TextCaption textColor="light-gray">{booking.customer.name}</TextCaption>
      <FlexBox gap="xs">
        <TextCaption
          textColor={isArriving ? 'dark-gray' : 'light-gray'}
          weight="bold"
        >
          {moment(booking.check_in).format('MMM DD, YYYY')}
        </TextCaption>
        <TextCaption textColor="light-gray">
          <IconFontAwesome name="longArrowRight" iconStyle="regular" />
        </TextCaption>
        <TextCaption textColor={isDeparting ? 'dark-gray' : 'light-gray'}>
          {moment(booking.check_out).format('MMM DD, YYYY')}
        </TextCaption>
      </FlexBox>
    </Panel>
  );
};

ReservationCard.propTypes = {
  booking: PropTypes.object,
  orgSubdomain: PropTypes.string,
  isDeparting: PropTypes.bool,
  isArriving: PropTypes.bool
};

export default ReservationCard;
