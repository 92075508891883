import React from 'react';
import PropTypes from 'prop-types';
import { Box, FlexBox, Panel } from '@directsoftware/ui-kit-web-admin';
import classnames from 'classnames';

const PreviewSelectButton = ({ isActive, preview, label, onSelect }) => {
  return (
    <Panel style={{ height: '100%' }}>
      <FlexBox
        className={classnames('previewSelectButton', {
          isActive
        })}
        flexDirection="column"
        renderAs="button"
        onClick={onSelect}
      >
        <Box>{preview}</Box>
        <Box flex="1" className="previewSelectButton__label">
          {label}
        </Box>
      </FlexBox>
    </Panel>
  );
};

PreviewSelectButton.propTypes = {
  isActive: PropTypes.bool,
  preview: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onSelect: PropTypes.func
};

export default PreviewSelectButton;
