import request from "apiClient";
export default class PaymentAccountService {
  static index(organizationId, queryString = "") {
    return request({
      url: `/api/${organizationId}/payment_accounts${queryString}`,
      method: "GET"
    });
  }
  static get(organizationId, accountId) {
    return request({
      url: `/api/${organizationId}/payment_accounts/${accountId}`,
      method: "GET"
    });
  }
  static update(organizationId, accountId, data) {
    return request({
      url: `/api/${organizationId}/payment_accounts/${accountId}`,
      method: "PATCH",
      data
    });
  }
}
