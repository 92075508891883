// Dependencies
// -----------------------------------------------
import React from 'react';
import queryString from 'query-string';

// Services
// -----------------------------------------------
import { StripeConnectAccountService } from 'adminApi';

// -----------------------------------------------
// COMPONENT->STRIPE-CONNECT-BUTTON --------------
// -----------------------------------------------
export default class StripeConnectButton extends React.Component {
  // ---------------------------------------------
  // CONSTRUCTOR ---------------------------------
  // ---------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      stripeQueryString: ''
    };
  }

  componentDidMount() {
    StripeConnectAccountService.getConnectObject(
      this.props.organizationId
    ).then(response => {
      this.setState({
        stripeQueryString: queryString.stringify(response.params)
      });
    });
  }

  redirectToStripeOauth = e => {
    e.preventDefault();
    window.location = `https://connect.stripe.com/oauth/authorize?${
      this.state.stripeQueryString
    }`;
  };

  // ---------------------------------------------
  // RENDER --------------------------------------
  // ---------------------------------------------
  render() {
    return (
      <a
        href="#"
        onClick={e => this.redirectToStripeOauth(e)}
        className={`button ${this.props.stripeStyling && 'stripe-connect'}`}
      >
        <span>{this.props.text}</span>
      </a>
    );
  }
}
