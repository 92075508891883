import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ReservationsList from './reservations-list';
import ReservationsCalendar from './reservations-calendar';
import ReservationsDetails from './reservations-details';
import ReservationsReviews from './reservations-reviews';
import ReservationsVehiclesCalendar from './reservations-vehicles-calendar';

const ReservationsRoutes = props => {
  return (
    <Switch>
      <Route
        path="/reviews"
        render={() => <ReservationsReviews {...props} />}
      />
      <Route
        path="/reservations/vehicles_calendar"
        render={() => <ReservationsVehiclesCalendar {...props} />}
      />
      <Route
        path="/reservations/calendar"
        render={() => <ReservationsCalendar {...props} />}
      />
      <Route
        path="/reservations/list"
        render={() => <ReservationsList {...props} />}
      />
      <Route
        path="/reservations/:bookingCode"
        render={routeProps => (
          <ReservationsDetails
            {...props}
            bookingCode={routeProps.match.params.bookingCode}
          />
        )}
      />
      <Route
        path="/reservations"
        render={() => <ReservationsList {...props} />}
      />
    </Switch>
  );
};

export default ReservationsRoutes;
