import React from 'react';
import SearchableClearableField from '../searchable-clearable-field';

export default class DirectTableFilter extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = e => {
    e.preventDefault();
    this.props.onFilterChange(e.target.value);
  };

  onClear = e => {
    e.preventDefault();
    this.props.onFilterChange('');
  };

  render() {
    const { unsetClassName } = this.props;
    return this.props.useDesignKit ? (
      <SearchableClearableField
        name={this.props.name}
        label={this.props.label}
        placeholder={this.props.placeholder}
        onChange={this.onChange}
        onClear={this.onClear}
        value={this.props.filter}
        useDesignKit={this.props.useDesignKit}
        removeBottomSpacer={this.props.removeBottomSpacer}
        inputWidth={this.props.inputWidth}
        isDense={this.props.isDense}
      />
    ) : (
      <section className={unsetClassName ? '' : 'consolidated-filters'}>
        <SearchableClearableField
          name={this.props.name}
          label={this.props.label}
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          onClear={this.onClear}
          value={this.props.filter}
        />
      </section>
    );
  }
}
