import React, { useEffect } from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { MY_ACCOUNT_NAV_TAGS } from '../../../../../shared/nav';

const IntegrationsHome = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: MY_ACCOUNT_NAV_TAGS.PRIMARY,
        secondary: MY_ACCOUNT_NAV_TAGS.secondary.MY_INTEGRATIONS
      })
    );
  }, []);

  return (
    <PageHeader>
      <PageHeader.Left>
        Settings: <span>Integrations</span>
      </PageHeader.Left>
    </PageHeader>
  );
};

export default IntegrationsHome;
