// Dependencies
// -----------------------------------------------
import React from 'react';
import { OrganizationContext } from 'adminContexts';
import get from 'lodash/get';
import { InputDateRange, Label } from '@directsoftware/ui-kit-web-admin';
import moment from 'moment';

export default class DefaultRangePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: null,
      startDate: props.initialStartDate,
      endDate: props.initialEndDate
    };
  }

  componentWillReceiveProps(nextProps) {
    const { startDateOverride, endDateOverride } = nextProps;

    if (!startDateOverride || !endDateOverride) return null;

    this.setState({
      startDate: startDateOverride,
      endDate: endDateOverride
    });
  }

  componentDidUpdate(prevProps) {
    const prevInitalStart = moment(prevProps.initialStartDate).format(
      'YYYY MMM DD'
    );
    const newInitialStart = moment(this.props.initialStartDate).format(
      'YYYY MMM DD'
    );
    const prevInitalEnd = moment(prevProps.initialEndDate).format(
      'YYYY MMM DD'
    );
    const newInitialEnd = moment(this.props.initialEndDate).format(
      'YYYY MMM DD'
    );
    if (prevInitalStart !== newInitialStart) {
      this.setState({ startDate: this.props.initialStartDate });
    }
    if (prevInitalEnd !== newInitialEnd) {
      this.setState({ endDate: this.props.initialEndDate });
    }
  }

  onDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  onFocusChange = focusedInput => {
    if (this.props.onPickerFocused) {
      this.props.onPickerFocused(focusedInput);
    }
    this.setState({ focusedInput }, () => {
      if (
        (this.props.allowNullEndDate ||
          (this.state.startDate && this.state.endDate)) &&
        !this.state.focusedInput
      ) {
        this.props.onDatesSet({
          startDate: this.state.startDate,
          endDate: this.state.endDate
        });
      }
    });
  };

  onDatesUnset = () => {
    const data = { startDate: null, endDate: null };

    this.setState(data, () => {
      this.props.onDatesSet(data);
    });
  };

  renderPicker = () => (
    <InputDateRange
      startDatePlaceholderText={this.props.startPlaceholder}
      endDatePlaceholderText={this.props.endPlaceholder}
      onDatesChange={this.onDatesChange}
      onFocusChange={this.onFocusChange}
      focusedInput={this.state.focusedInput}
      numberOfMonths={this.props.numberOfMonths}
      startDate={this.state.startDate}
      startDateId="default_range_picker_start_id"
      endDate={this.state.endDate}
      endDateId="default_range_picker_end_id"
      minimumNights={
        Number.isInteger(this.props.minimumNightsOverride)
          ? this.props.minimumNightsOverride
          : this.props.minimumNights
      }
      isOutsideRange={this.props.isOutsideRange}
      isDayBlocked={this.props.isDayBlocked}
      renderCalendarDay={this.props.renderCalendarDay}
      hideKeyboardShortcutsPanel
      disabled={this.props.disabled}
      displayFormat={
        this.props.displayFormat || get(this, 'context.dateFormat', () => {})()
      } // The only time displayFormat is passed in props is on the cx app. The admin app takes advantage of the OrganizationConext
      readOnly={this.props.readOnly}
      openDirection={this.props.openDirection}
      orientation={this.props.orientation}
      withFullScreenPortal={this.props.withFullScreenPortal}
      navNext={this.props.navNext}
      navPrev={this.props.navPrev}
      description={this.props.description}
      removeBottomSpacer={this.props.removeBottomSpacer}
      inputWidth={this.props.inputWidth}
      isDense={this.props.isDense}
    />
  );

  render() {
    if (!this.state.startDate && this.props.initialStartDate) {
      this.state.startDate = this.props.initialStartDate;
      this.state.endDate = this.props.initialEndDate;
    }

    return this.props.removeOldStyling ? (
      <>
        {this.props.label && <Label>{this.props.label}</Label>}
        {this.renderPicker()}
      </>
    ) : (
      <figure style={this.props.styles} className={this.props.containerClass}>
        {this.props.label && <Label>{this.props.label}</Label>}
        <div className="datepicker">
          {this.renderPicker()}
          {this.props.renderUnsetLink(this.onDatesUnset)}
        </div>
      </figure>
    );
  }
}

DefaultRangePicker.defaultProps = {
  isOutsideRange: () => false,
  isDayBlocked: () => false,
  startPlaceholder: 'Start',
  endPlaceholder: 'End',
  numberOfMonths: 1,
  minimumNights: 1,
  renderUnsetLink: func => null,
  readOnly: false,
  removeOldStyling: false
};

DefaultRangePicker.contextType = OrganizationContext;
