import React, { useEffect, useState } from 'react';
import { listVehicles } from 'adminApi/VehicleService';
import {
  CallToActionLink,
  IconFontAwesome,
  PageHeader,
  VisibilityHelper
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';
import { selectUserDetails } from '../../../../redux/slices/userDetails';

const VehiclesHome = props => {
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetails);
  const [totalCount, setTotalCount] = useState(0);

  const renderCTAs = () => (
    <>
      <VisibilityHelper hideStartingFrom="tablet">
        {totalCount > 0 && (
          <CallToActionLink
            href="/bulk-edit/vehicles"
            size="dense"
            variation="secondary"
          >
            <IconFontAwesome name="layerGroup" />
          </CallToActionLink>
        )}
        <CallToActionLink
          href="/properties/new/?createMode=vehicle"
          size="dense"
        >
          <IconFontAwesome name="plus" />
        </CallToActionLink>
      </VisibilityHelper>
      <VisibilityHelper showStartingFrom="tablet">
        {totalCount > 0 && (
          <CallToActionLink
            href="/bulk-edit/vehicles"
            prependIcon={<IconFontAwesome name="layerGroup" />}
            variation="secondary"
          >
            Edit
          </CallToActionLink>
        )}
        <CallToActionLink
          href="/properties/new/?createMode=vehicle"
          prependIcon={<IconFontAwesome name="plus" />}
        >
          Create
        </CallToActionLink>
      </VisibilityHelper>
    </>
  );

  useEffect(() => {
    listVehicles({ orgId: props.organization.id, params: {} }).then(data => {
      setTotalCount(data.totalCount);
    });
    dispatch(
      updateActiveRoutes({
        primary: LISTINGS_NAV_TAGS.PRIMARY,
        secondary: LISTINGS_NAV_TAGS.secondary.VEHICLES
      })
    );
  }, []);

  return (
    <>
      <PageHeader>
        <PageHeader.Left>
          Listings: <span>Vehicles</span>
        </PageHeader.Left>
        <PageHeader.Right>
          {userDetails.isRVshareUser && !userDetails.rvshare.hasAttemptedSync
            ? null
            : renderCTAs()}
        </PageHeader.Right>
      </PageHeader>
    </>
  );
};

export default VehiclesHome;
