import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CallToActionButton,
  CallToActionLink,
  FlexBox,
  IconFontAwesome,
  TextBody,
  TextH1,
  TextH2,
  VisibilityHelper
} from '@directsoftware/ui-kit-web-admin';
import { useDetectMobile } from '../hooks';

const PageStateMessage = ({
  iconProps,
  headline,
  description,
  ctaProps,
  ctaLabel,
  ctaType
}) => {
  const { isMobile } = useDetectMobile();

  return (
    <FlexBox
      className="pageState"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      paddingVertical={isMobile ? 'm' : 'l'}
      paddingHorizontal="s"
    >
      <Box className="pageState__icon" paddingBottom="xs">
        <IconFontAwesome {...iconProps} />
      </Box>
      <VisibilityHelper showStartingFrom="tablet">
        <TextH1 textAlign="center" textColor="dark-gray">
          {headline}
        </TextH1>
      </VisibilityHelper>
      <VisibilityHelper hideStartingFrom="tablet">
        <TextH2 textAlign="center" textColor="dark-gray">
          {headline}
        </TextH2>
      </VisibilityHelper>
      {description && <TextBody textAlign="center">{description}</TextBody>}
      {ctaProps && (
        <Box paddingTop="m">
          {ctaType === 'button' ? (
            <CallToActionButton {...ctaProps}>{ctaLabel}</CallToActionButton>
          ) : (
            <CallToActionLink {...ctaProps}>{ctaLabel}</CallToActionLink>
          )}
        </Box>
      )}
    </FlexBox>
  );
};

PageStateMessage.propTypes = {
  iconProps: PropTypes.object,
  headline: PropTypes.string,
  description: PropTypes.string,
  ctaProps: PropTypes.object,
  ctaLabel: PropTypes.string,
  ctaType: PropTypes.oneOf(['link', 'button'])
};

PageStateMessage.defaultProps = {
  ctaType: 'button'
};

export default PageStateMessage;
