"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureTierDisplay = void 0;
const useOrganizationContext_1 = require("src/admin/hooks/useOrganizationContext");
const FeatureTierDisplay = ({ allowedTiers, children }) => {
    const { organization } = (0, useOrganizationContext_1.useOrganizationContext)();
    if (allowedTiers.includes(organization.feature_tier)) {
        return children;
    }
    return null;
};
exports.FeatureTierDisplay = FeatureTierDisplay;
