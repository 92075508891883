import request from "apiClient";
export default class BillService {
  static list(orgId, pageSize, page, sorted, filtered) {
    return request({
      url: `/api/${orgId}/bills`,
      method: "GET",
      params: {
        limit: pageSize,
        page,
        sorted,
        filtered
      }
    }).then((response) => {
      return {
        rows: response.bills,
        pages: Math.ceil(response.count / pageSize)
      };
    });
  }
  static index(organizationId, params = {}) {
    return request({
      url: `/api/${organizationId}/bills`,
      method: "GET",
      params
    });
  }
  static create(organizationId, data) {
    return request({
      url: `/api/${organizationId}/bills`,
      method: "POST",
      data
    });
  }
  static fetch(organizationId, billId) {
    return request({
      url: `/api/${organizationId}/bills/${billId}`,
      method: "GET"
    });
  }
  static delete(organizationId, billId) {
    return request({
      url: `/api/${organizationId}/bills/${billId}`,
      method: "DELETE"
    });
  }
  static update(organizationId, billId, data) {
    return request({
      url: `/api/${organizationId}/bills/${billId}`,
      method: "PATCH",
      data
    });
  }
}
