import React from 'react';
import find from 'lodash/find';

import PortalModal from 'sharedComponents/PortalModal';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';

const NotConvertedOptions = [
  { label: 'Price too high', value: 'price_too_high' },
  { label: 'Dates not available', value: 'dates_not_available' },
  { label: 'Found something else', value: 'found_something_else' },
  { label: 'Not ready to book', value: 'not_ready_to_book' },
  { label: 'Bad experience with company in past', value: 'bad_experience' },
  { label: 'Other', value: 'other' }
];

export default class NotConvertedModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      not_converted_reason: null,
      reason_text: null,
      status: 'not_converted',
      modalOpen: false // status and modalOpen do not get updated. Used for updating in parent
    };
  }

  onChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onCancel = () => {
    this.props.onModalChangesComplete();
  };

  onSubmit = () => {
    this.props.onModalChangesComplete(this.state);
  };

  renderSubmitButton = () => (
    <a
      className="button"
      onClick={this.onSubmit}
      id="save_reason_submit_button"
    >
      Save
    </a>
  );

  render() {
    return (
      <PortalModal
        header="Why was this not converted?"
        modalStyles={{ textAlign: 'unset', width: '560px', height: '380px' }}
        submitAction={this.renderSubmitButton()}
        cancelAction={_closePortal => (
          <a
            className="button negative"
            onClick={this.onCancel}
            style={{ marginRight: '8px' }}
          >
            Cancel
          </a>
        )}
        defaultOpen
        disableCloseOnOutsideClick
        actionStyles={{ display: 'flex', flexDirection: 'row-reverse' }}
      >
        <label>Not Converted Reason</label>
        <InputSelect
          name="not_converted_reason"
          placeholder={this.state.reason}
          options={NotConvertedOptions}
          value={find(NotConvertedOptions, { value: this.state.reason })}
          onChange={option =>
            this.onChange('not_converted_reason', option.value)
          }
        />
        {this.state.not_converted_reason == 'other' && (
          <textarea
            style={{ marginTop: '16px' }}
            name="reason-text"
            className="magnified"
            onChange={e => this.onChange('reason_text', e.target.value)}
            minLength="1"
            rows={6}
            placeholder="Describe the reason in this section..."
            pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
          />
        )}
        <div style={{ marginBottom: '16px' }} />
      </PortalModal>
    );
  }
}
