import React from 'react';
import MessageCard from './MessageCard';

export default class Thread extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return this.props.messages.map((message, index) => {
      return (
        <MessageCard
          message={message}
          showAirbnbPreApproval={index === 0}
          handlePreApproveClicked={this.props.handlePreApproveClicked}
        />
      );
    });
  }
}
