import styled from 'styled-components';

const WidgetCalendarDay = styled.td`
  ${props =>
    props.status === 'check_in' &&
    `
    td.CalendarDay:not(.CalendarDay__blocked_out_of_range) {
      background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, #DCDDE3 50%);
      background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, #DCDDE3 50%);
      background: linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, #DCDDE3 50%);

      border-right-color: #DCDDE3;
      border-top-color: #DCDDE3;
    }
  `}

  ${props =>
    props.status === 'check_out' &&
    `
    td.CalendarDay:not(.CalendarDay__blocked_out_of_range) {
      background: -moz-linear-gradient(45deg, #DCDDE3 50%, rgba(0, 0, 0, 0) 50%);
      background: -webkit-linear-gradient(45deg, #DCDDE3 50%, rgba(0, 0, 0, 0) 50%);
      background: linear-gradient(45deg, #DCDDE3 50%, rgba(0, 0, 0, 0) 50%);

      border-left-color: #DCDDE3;
      border-bottom-color: #DCDDE3;
    }
  `}

  ${props =>
    props.status === 'check_in_with_previous_check_out' &&
    `
    td.CalendarDay:not(.CalendarDay__blocked_out_of_range) {
      background: -moz-linear-gradient(45deg, #DCDDE3 50%, #5284ca 50%);
      background: -webkit-linear-gradient(45deg, #DCDDE3 50%, #5284ca 50%);
      background: linear-gradient(45deg, #DCDDE3 50%, #5284ca 50%);

      border-left-color: #DCDDE3;
      border-bottom-color: #DCDDE3;
    }
  `}

  ${props =>
    props.status === 'check_out_with_previous_check_in' &&
    `
    td.CalendarDay:not(.CalendarDay__blocked_out_of_range) {
      background: -moz-linear-gradient(45deg, #5284ca 50%, #DCDDE3 50%);
      background: -webkit-linear-gradient(45deg, #5284ca 50%, #DCDDE3 50%);
      background: linear-gradient(45deg, #5284ca 50%, #DCDDE3 50%);

      border-right-color: #DCDDE3;
      border-top-color: #DCDDE3;
    }
  `}

  ${props =>
    props.status === 'unavailable' &&
    `
    td.CalendarDay:not(.CalendarDay__blocked_out_of_range) {
      background: #DCDDE3;
      border: #DCDDE3 1px solid;
    }
  `}

  ${props =>
    props.status === 'blocked' &&
    `
    td.CalendarDay:not(.CalendarDay__blocked_out_of_range) {
      background: #DCDDE3;
      border: #DCDDE3 1px solid;
    }
  `}

  ${props =>
    props.status === 'check_in_out' &&
    `
    td.CalendarDay:not(.CalendarDay__blocked_out_of_range) {
      background: #DCDDE3;
      border: #DCDDE3 1px solid;
    }
  `}

  ${props =>
    props.status === 'check_in_only' &&
    `
    td.CalendarDay:not(.CalendarDay__blocked_out_of_range) {
      background: -moz-linear-gradient(45deg, #DCDDE3 50%, rgba(0, 0, 0, 0) 50%);
      background: -webkit-linear-gradient(45deg, #DCDDE3 50%, rgba(0, 0, 0, 0) 50%);
      background: linear-gradient(45deg, #DCDDE3 50%, rgba(0, 0, 0, 0) 50%);

      border-left-color: #DCDDE3;
      border-bottom-color: #DCDDE3;
    }
  `}

  ${props =>
    props.status === 'check_out_only' &&
    `
    td.CalendarDay:not(.CalendarDay__blocked_out_of_range) {
      background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, #DCDDE3 50%);
      background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, #DCDDE3 50%);
      background: linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, #DCDDE3 50%);

      border-right-color: #DCDDE3;
      border-top-color: #DCDDE3;
    }
  `}

`;

export { WidgetCalendarDay };
