// Dependencies
// -----------------------------------------------
import React from 'react';

// -----------------------------------------------
// COMPONENT->OVERVIEW-SINGLE-ASIDE-RULES --------
// -----------------------------------------------
const OverviewSingleAsideRules = () => {
  // ---------------------------------------------
  // RETURN --------------------------------------
  // ---------------------------------------------
  return (
    <div>
      <figure>
        <small className="subtitle">Accommodations</small>
        <p>
          This field is simply for any extra info you want guests to know about
          your property.
        </p>
      </figure>

      <figure>
        <small className="subtitle">Rules</small>
        <p>
          This field is for all the rules you would like your guests to follow
          while staying at your property, no matter how big or small.
        </p>
      </figure>
    </div>
  );
};

// -----------------------------------------------
// EXPORT ----------------------------------------
// -----------------------------------------------
export default OverviewSingleAsideRules;
