import React from 'react';
import InputLimiter from '../../../components/input-limiter';
export default class FieldsOrganizationBrand extends React.Component {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      brandName: this.props.brandName || '',
      brandDescription: this.props.brandDescription || ''
    };
  }

  setBrandName = e => {
    e.preventDefault();
    const brandName = e.target.value;
    this.props.setBrandName(brandName);
  };

  setBrandDescription = e => {
    e.preventDefault();
    const brandDescription = e.target.value;
    this.props.setBrandDescription(brandDescription);
  };

  render() {
    return (
      <fieldset>
        <header className="fieldset-header">
          <legend>Set up your brand</legend>
          <p>Tell us a little bit about your brand.</p>
        </header>

        <label htmlFor="name">
          <span>Brand name</span>
          <InputLimiter used={this.props.brandName.length} total={140} />
        </label>
        <input
          type="text"
          name="name"
          className="magnified"
          placeholder="My brand"
          maxLength="140"
          value={this.props.brandName}
          onChange={e => this.setBrandName(e)}
        />

        <label htmlFor="description">Description</label>
        <textarea
          className="magnified"
          name="description"
          placeholder=""
          rows="5"
          value={this.props.brandDescription}
          onChange={e => this.setBrandDescription(e)}
        />
      </fieldset>
    );
  }
}
