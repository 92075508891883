import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  showDeductionModal: false,
  showAdjustmentsModal: false
};
const workReportsSlice = createSlice({
  name: "workReports",
  initialState,
  reducers: {
    updateWorkReports: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetWorkReports: (state) => {
      return { ...state, ...initialState };
    }
  }
});
export const { updateWorkReports, resetWorkReports } = workReportsSlice.actions;
export const selectWorkReports = createSelector((state) => state.workReports, (workReports) => workReports);
export default workReportsSlice.reducer;
