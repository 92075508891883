import React from 'react';
import Dropzone from 'react-dropzone';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import Spinner from './spinner';
import { Delete } from 'adminComponents/icons';

import { IdPhotoService } from 'adminApi';
import displayError from 'sharedComponents/ErrorDisplay';

const DropContainer = styled.div`
  border: 4px dashed #e5e2e2;
  padding: 16px;
  text-align: center;
`;

export default class IdPhotoUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isLoading: false,
      idPhoto: this.props.idPhoto
    };
  }

  onDropzoneDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      this.attemptUploadFile(acceptedFiles[0]);
    }
  };

  attemptUploadFile = file_queued => {
    this.setState({ isLoaded: false, isLoading: true }, () => {
      var formData = new FormData();
      formData.append('document', file_queued);

      if (this.props.idSide) {
        formData.append('id_side', this.props.idSide);
      }

      if (this.props.identifiableType && this.props.identifiableId) {
        formData.append('identifiable_id', this.props.identifiableId);
        formData.append('identifiable_type', this.props.identifiableType);
      }

      IdPhotoService.create(this.props.organizationId, formData)
        .then(data => {
          this.setState(
            {
              isLoaded: true,
              isLoading: false,
              idPhoto: data.id_photo
            },
            () =>
              this.props.afterUpload && this.props.afterUpload(data.id_photo)
          );
        })
        .catch(err => {
          this.setState({
            isLoaded: false,
            isLoading: false
          });
          displayError({ message: 'Error uploading file', err });
        });
    });
  };

  deleteIdPhoto = () => {
    const photoBeforeDelete = this.state.idPhoto;

    IdPhotoService.delete(this.props.organizationId, this.state.idPhoto.id)
      .then(() => {
        this.setState({ idPhoto: null }, () => {
          this.props.afterPhotoDelete &&
            this.props.afterPhotoDelete(photoBeforeDelete);
        });
      })
      .catch(err => {
        displayError({ message: 'Photo could not be deleted', err });
      });
  };

  renderLoading = () => {
    return <Spinner />;
  };

  renderViewing = () => {
    if (this.state.idPhoto && !isEmpty(this.state.idPhoto)) {
      return (
        <div style={{ position: 'relative' }}>
          <img
            src={this.state.idPhoto.url}
            style={{ width: '100%' }}
            alt="id-photo"
          />
          <Delete
            style={{
              position: 'absolute',
              right: '8px',
              bottom: '8px',
              cursor: 'pointer'
            }}
            onClick={this.deleteIdPhoto}
          />
        </div>
      );
    } else {
      return (
        <Dropzone
          activeClassName=""
          className={`card empty-state`}
          multiple={false}
          onDrop={this.onDropzoneDrop}
          ref={node => {
            this.dropzone = node;
          }}
          accept="image/*"
        >
          <DropContainer>
            <a className="button">
              {this.props.buttonText ||
                "Upload Photo of Driver's License or Passport"}
            </a>
            <p>or drag it into this box.</p>
          </DropContainer>
        </Dropzone>
      );
    }
  };

  render() {
    return (
      <section>
        {this.state.isLoading ? this.renderLoading() : this.renderViewing()}
      </section>
    );
  }
}
