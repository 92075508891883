import request from "apiClient";
export default class ChannelMappingService {
  static index(organizationID, channelID, queryString) {
    return request({
      url: `/api/${organizationID}/channels/${channelID}/channel_mappings${queryString}`,
      method: "GET"
    });
  }
  static create(organizationID, channelID, data) {
    return request({
      url: `/api/${organizationID}/channels/${channelID}/channel_mappings`,
      method: "POST",
      data
    });
  }
  static update(organizationID, channelID, channelMappingID, data) {
    return request({
      url: `/api/${organizationID}/channels/${channelID}/channel_mappings/${channelMappingID}`,
      method: "PATCH",
      data
    });
  }
  static external_listings_index(organizationID, channelID) {
    return request({
      url: `/api/${organizationID}/channels/${channelID}/channel_mappings/external_listings`,
      method: "GET"
    });
  }
  static map_listing(organizationID, channelID, channelMappingID, data) {
    return request({
      url: `/api/${organizationID}/channels/${channelID}/channel_mappings/${channelMappingID}/map`,
      method: "POST",
      data
    });
  }
  static lookupHotelCode(organizationID, channelID, channelMappingID, hotelCode) {
    return request({
      url: `/api/${organizationID}/channels/${channelID}/channel_mappings/${channelMappingID}/lookup/${hotelCode}`,
      method: "GET"
    });
  }
  static refresh(organizationID, channelID, channelMappingID, unitId) {
    return request({
      url: `/api/${organizationID}/channels/${channelID}/channel_mappings/${channelMappingID}/refresh/${unitId}`,
      method: "GET"
    });
  }
  static activateUnit(organizationID, channelID, channelMappingID, unitId, type) {
    return request({
      url: `/api/${organizationID}/channels/${channelID}/channel_mappings/${channelMappingID}/activate/${unitId}?type=${type}`,
      method: "GET"
    });
  }
  static createVehicleListing(organizationID, channelID, channelMappingID, vehicleId) {
    return request({
      url: `/api/${organizationID}/channels/${channelID}/channel_mappings/${channelMappingID}/create_vehicle_listing/${vehicleId}`,
      method: "GET"
    });
  }
  static activateVehicleListing(organizationID, channelID, channelMappingID, vehicleId) {
    return request({
      url: `/api/${organizationID}/channels/${channelID}/channel_mappings/${channelMappingID}/activate_vehicle_listing/${vehicleId}`,
      method: "GET"
    });
  }
  static UploadChannelPhoto(organizationID, channelID, channelMappingID, vehicleId) {
    return request({
      url: `/api/${organizationID}/channels/${channelID}/channel_mappings/${channelMappingID}/activate_vehicle_listing/${vehicleId}`,
      method: "GET"
    });
  }
  static createSubscription(organizationID, channelID, channelMappingID, data = {}) {
    return request({
      url: `/api/${organizationID}/channels/${channelID}/channel_mappings/${channelMappingID}/subscription`,
      method: "POST",
      data
    });
  }
  static updateSubscription(organizationID, channelID, channelMappingID, data = {}) {
    return request({
      url: `/api/${organizationID}/channels/${channelID}/channel_mappings/${channelMappingID}/subscription/${data.id}`,
      method: "PATCH",
      data
    });
  }
  static airbnbImport(organizationID, channelID, channelMappingID, data = {}) {
    return request({
      url: `/api/${organizationID}/channels/${channelID}/channel_mappings/${channelMappingID}/airbnb_import`,
      method: "POST",
      data
    });
  }
  static unlist_reasons(organizationID, channelID, channelMappingID) {
    return request({
      url: `/api/${organizationID}/channels/${channelID}/channel_mappings/${channelMappingID}/unlist_reasons`,
      method: "GET"
    });
  }
}
