import React from 'react';
import { VehicleContext } from 'adminContexts';

const VehicleProvider = props => {
  const vehicleGlobals = {
    vehicleTypeOptions: [
      { label: 'Class A Motorhome', value: 'class_a_motorhome' },
      { label: 'Class B Camping Van', value: 'class_b_camping_van' },
      { label: 'Class C Motorhome', value: 'class_c_motorhome' },
      { label: 'Fifth Wheel', value: 'fifth_wheel' },
      { label: 'Pop Up Camper', value: 'pop_up_camper' },
      { label: 'Toy Hauler', value: 'toy_hauler' },
      { label: 'Travel Trailer', value: 'travel_trailer' },
      { label: 'Park Model', value: 'park_model' }
    ]
  };

  return (
    <VehicleContext.Provider value={vehicleGlobals}>
      {props.children}
    </VehicleContext.Provider>
  );
};

export default VehicleProvider;
