import React from 'react';
import CurrencyDefinitions from 'sharedUtils/currency-definitions';
import find from 'lodash/find';

const repeatCount = value => {
  if (value >= 300000) return 4;
  if (value >= 130000) return 3;
  if (value >= 30000) return 2;
  if (value >= 7900) return 1;

  return 0;
};

const containerStyles = {
  position: 'relative',
  padding: '6px 8px 4px',
  backgroundColor: '#e0e0e0',
  borderRadius: '4px',
  width: 'min-content',
  fontSize: '10px',
  fontWeight: 'bold',
  color: '#888',
  cursor: 'default',
  whiteSpace: 'nowrap'
};

const tipStyles = {
  position: 'absolute',
  backgroundColor: '#404040',
  color: 'white',
  borderRadius: '4px',
  padding: '4px 8px',
  top: '-30px',
  left: 0
};

const arrowStyles = {
  position: 'absolute',
  top: '-9px',
  left: '25%',
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: '4px 4px 0 4px',
  borderColor: '#404040 transparent transparent transparent'
};

export default class OpportunityDisplayValue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    this.currencyDefinition = find(
      CurrencyDefinitions,
      definition => definition.value === props.currency
    );
  }

  displayValueSymbol = () => {
    const { value, currency } = { ...this.props };
    const symbol = this.currencyDefinition.symbol;
    const count = repeatCount(value);

    return count > 0 ? symbol.repeat(count) : symbol;
  };

  displayValue = () => {
    return (
      this.currencyDefinition.symbol +
      (this.props.value / 100.0).toFixed(this.currencyDefinition.decimal_digits)
    );
  };

  backgroundColors = () => {
    if (!this.props.withColors) return {};

    const count = repeatCount(this.props.value);

    if (count === 0 || count === 1)
      return { backgroundColor: '#40907A', color: '#000' };
    if (count === 2) return { backgroundColor: '#66B6A7', color: '#000' };
    if (count === 3) return { backgroundColor: '#14C3A2', color: '#fff' };
    if (count === 4) return { backgroundColor: '#0BE5A8', color: '#fff' };

    return {};
  };

  render() {
    const colorStyle = this.backgroundColors();

    return (
      <div
        onMouseEnter={() => this.setState({ open: true })}
        onMouseLeave={() => this.setState({ open: false })}
        style={{ ...containerStyles, ...colorStyle }}
      >
        {this.displayValueSymbol()}
        {this.state.open && (
          <React.Fragment>
            <div style={tipStyles}>{this.displayValue()}</div>
            <div style={arrowStyles} />
          </React.Fragment>
        )}
      </div>
    );
  }
}
