import request from "apiClient";
export default class StatementService {
  static index(organizationId, queryString) {
    return request({
      url: `/api/${organizationId}/statements/balances${queryString}`,
      method: "GET"
    });
  }
  static get(organizationId, payeeId, payeeType) {
    return request({
      url: `/api/${organizationId}/statements/balances/${payeeId}?payee_type=${payeeType}`,
      method: "GET"
    });
  }
  static update(organizationId, statementId, data) {
    return request({
      url: `/api/${organizationId}/statements/balances/${statementId}`,
      method: "PUT",
      data
    });
  }
}
