import React from 'react';

import { CustomerContext } from 'adminContexts';
import { CallToActionButton } from '@directsoftware/ui-kit-web-admin';
import PropTypes from 'prop-types';
import TextHighlighterComponent from '../../pages/DashboardGlobalSearch/components/text-highlighter-component';

const OpenCustomerSidebarLink = props => {
  const { customer, styles, activeSection, displayName, highlightText } = props;
  if (!customer) return null;

  return (
    <CustomerContext.Consumer>
      {state => (
        <CallToActionButton
          onClick={() => state.setCustomer(customer, activeSection)}
          appearance="ghost"
          size="dense"
          variation={props.variation}
          isInline={props.isInline}
        >
          {highlightText ? (
            <TextHighlighterComponent
              text={displayName || customer.name}
              textToHighlight={highlightText}
            />
          ) : (
            displayName || customer.name
          )}
        </CallToActionButton>
      )}
    </CustomerContext.Consumer>
  );
};

OpenCustomerSidebarLink.propTypes = {
  variation: PropTypes.oneOf(['primary', 'secondary', 'danger'])
};

OpenCustomerSidebarLink.defaultProps = {
  variation: 'primary'
};

export default OpenCustomerSidebarLink;
