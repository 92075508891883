import React from 'react';
import InlineEdit from '@atlaskit/inline-edit';

import {
  ReadContainer,
  InlineEditContainer,
  EditViewInput
} from './InlineHeaderEditStyles';

export default class InlineHeaderEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editValue: props.defaultValue
    };
  }

  onConfirm = editValue => {
    this.setState({ editValue }, () => {
      this.props.onConfirm(editValue);
    });
  };

  render() {
    const editValue = this.state.editValue;

    return (
      <InlineEditContainer>
        <InlineEdit
          defaultValue={editValue}
          editView={({ ref, ...rest }) => (
            <EditViewInput
              {...rest}
              innerRef={ref}
              titleType={this.props.titleType}
              autoFocus
            />
          )}
          readView={() => (
            <ReadContainer>{this.props.readView(editValue)}</ReadContainer>
          )}
          onConfirm={this.onConfirm}
        />
      </InlineEditContainer>
    );
  }
}

InlineHeaderEdit.defaultProps = {
  titleType: 'h3'
};
