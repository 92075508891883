import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Modal,
  SlideoutPanel,
  Stepper
} from '@directsoftware/ui-kit-web-admin';
import BookingStepOne from 'adminComponents/CreateReservationModal/BookingStepOne';
import BookingStepTwo from 'adminComponents/CreateReservationModal/BookingStepTwo';
import BookingStepThree from 'adminComponents/CreateReservationModal/BookingStepThree';
import { useDispatch, useSelector } from 'react-redux';
import { isInclusivelyBeforeDay } from 'react-dates';
import { toast } from 'react-toastify';
import { ajaxDataWithAuthorization } from 'apiClient';
import { useDetectMobile } from 'sharedHooks';
import ProcessingOverlay from 'adminComponents/CreateReservationModal/ProcessingOverlay';
import { VehicleBookingsService } from 'adminApi';
import {
  resetNewBooking,
  updateNewBooking
} from '../../redux/slices/createBooking';
import ChannelService from '../../api/ChannelService';

const CreateReservationModal = ({
  reveal,
  closeButton,
  brands,
  channels,
  organization,
  user
}) => {
  const { isMobile } = useDetectMobile();
  const [advanceIsDisabled, setAdvanceIsDisabled] = useState(true);
  const [contentIsScrollable, setContentIsScrollable] = useState(false);
  const [unmountWizard, setUnmountWizard] = useState(false);
  const [checkInMomentObject, setCheckInMomentObject] = useState(null);
  const [checkOutMomentObject, setCheckOutMomentObject] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processResult, setProcessResult] = useState('init');
  const [newBookingCode, setNewBookingCode] = useState('');
  const [availableChannels, setAvailableChannels] = useState([]);
  const booking = useSelector(state => state.newBooking);
  const dispatch = useDispatch();

  const formatChannelName = channelName => {
    let formattedName = channelName;
    switch (channelName) {
      case 'Rvshare':
        formattedName = 'RVshare';
        break;
      case 'Rvezy':
        formattedName = 'RVezy';
        break;
      case 'Rvngo':
        formattedName = 'RVnGO';
        break;
      case 'Goodsam':
        formattedName = 'Good Sam';
        break;
      default:
        formattedName = channelName;
    }
    return formattedName;
  };

  const loadChannels = () => {
    ChannelService.list(organization.id).then(response => {
      setAvailableChannels([
        { label: 'Direct', value: 0 },
        ...response.organizationChannels
          .filter(orgChannel => orgChannel.status === 'active')
          .map(filteredChannel => {
            let channel_name = channels.filter(
              channel => channel.id === filteredChannel.channel_id
            )[0].name;
            channel_name =
              channel_name === 'Rvshare' ? 'RVshare' : channel_name;
            return {
              label:
                filteredChannel.channel_id === 2
                  ? 'Vrbo'
                  : formatChannelName(channel_name),
              value: filteredChannel.channel_id
            };
          })
      ]);
    });
  };

  const handleAdvanceThreshold = value => {
    setAdvanceIsDisabled(value);
  };

  const handleContentIsScrollable = value => {
    setContentIsScrollable(value);
  };

  const onDatesChange = (check_in, check_out) => {
    if (isInclusivelyBeforeDay(check_in, check_out)) {
      const booking_range = [];
      const d = check_in.clone();
      while (isInclusivelyBeforeDay(d, check_out)) {
        booking_range.push({
          key: d.format('DD-MM-YYYY'),
          day: d.day()
        });
        d.add(1, 'days');
      }
      dispatch(
        updateNewBooking({
          booking_range
        })
      );
      setCheckInMomentObject(check_in);
      setCheckOutMomentObject(check_out);
    }
  };

  const attemptCreateBooking = () => {
    const bookingData = {
      booking_range: JSON.stringify(booking.booking_range),
      booking_type: booking.booking_type,
      check_in: checkInMomentObject.format('DD-MM-YYYY'),
      check_out: checkOutMomentObject.format('DD-MM-YYYY'),
      confirmed: booking.confirmed,
      customer_id: booking.customer_id,
      customer_email: booking.customer_email,
      customer_name: booking.customer_name,
      customer_telephone: booking.customer_telephone,
      customer_addr_line_one: booking.customer_addr_line_one,
      customer_addr_line_two: booking.customer_addr_line_two,
      customer_city: booking.customer_city,
      customer_state: booking.customer_state,
      customer_country: booking.customer_country,
      customer_zip: booking.customer_zip,
      listing_id: parseInt(booking.listing_id),
      total: parseFloat(booking.total),
      channel_id: parseInt(booking.channel_id),
      sendConfirmation: booking.sendConfirmation,
      quote_id: booking.quote_id,
      channel_fee_payable: booking.channel_fee_payable,
      owner_will_self_clean: booking.owner_will_self_clean
    };
    setIsProcessing(true);
    if (booking.vehicle_booking) {
      VehicleBookingsService.createVehicleBooking({
        organization,
        booking,
        bookingData
      })
        .then(response => {
          setProcessResult('success');
          setNewBookingCode(response.booking_code);
        })
        .catch(err => {
          setProcessResult('error');
          toast.error(err);
        });
    } else {
      $.ajax(
        ajaxDataWithAuthorization({
          type: 'POST',
          url: `/api/${organization.id}/bookings`,
          context: this,
          dataType: 'json',
          data: bookingData
        })
      )
        .done(function(data) {
          setProcessResult('success');
          setNewBookingCode(data.booking_code);
        })
        .fail(function(jqXhr) {
          setProcessResult('error');
          toast.error(
            jqXhr.responseJSON ? jqXhr.responseJSON.error : jqXhr.responseText
          );
          console.warn(jqXhr);
        });
    }
  };

  const renderReservationContent = () => {
    return (
      <>
        {isProcessing && (
          <ProcessingOverlay
            processResult={processResult}
            closeOverlay={() => setIsProcessing(false)}
            bookingCode={newBookingCode}
          />
        )}
        <Box className="createReservationModal__contentWrapper">
          {!unmountWizard && (
            <Stepper
              disableAdvanceButton={advanceIsDisabled}
              contentIsScrollable={contentIsScrollable}
              onChange={() => setContentIsScrollable(false)}
              onFinish={() => {
                attemptCreateBooking();
              }}
              contentHorizontalPadding="m"
              footerHorizontalPadding="m"
              progressHorizontalPadding="m"
            >
              <Stepper.Step
                nextStepIndex={booking.booking_type !== 'guest' ? 2 : null}
              >
                <BookingStepOne
                  brands={brands}
                  user={user}
                  organization={organization}
                  activateAdvanceButton={handleAdvanceThreshold}
                  onDatesChange={onDatesChange}
                  handleContentIsScrollable={handleContentIsScrollable}
                />
              </Stepper.Step>
              <Stepper.Step>
                <BookingStepTwo
                  handleContentIsScrollable={handleContentIsScrollable}
                  channels={availableChannels}
                  organization={organization}
                  activateAdvanceButton={handleAdvanceThreshold}
                  onDatesChange={onDatesChange}
                />
              </Stepper.Step>
              <Stepper.Step
                previousStepIndex={booking.booking_type !== 'guest' ? 0 : null}
              >
                <BookingStepThree
                  handleContentIsScrollable={handleContentIsScrollable}
                  disableAdvanceButton={handleAdvanceThreshold}
                  checkIn={checkInMomentObject}
                  checkOut={checkOutMomentObject}
                  organization={organization}
                  user={user}
                />
              </Stepper.Step>
            </Stepper>
          )}
        </Box>
      </>
    );
  };

  useEffect(
    () => {
      if (!reveal) {
        dispatch(resetNewBooking());
        setTimeout(() => {
          setUnmountWizard(true);
        }, 275);
      } else {
        setUnmountWizard(false);
      }
    },
    [reveal]
  );

  useEffect(() => {
    loadChannels();
  }, []);

  return isMobile ? (
    <Modal
      title="Create a Reservation"
      reveal={reveal}
      closeOnClick={closeButton}
      size="cover"
    >
      <Modal.Content contentIsScrollable={contentIsScrollable}>
        {renderReservationContent()}
      </Modal.Content>
    </Modal>
  ) : (
    <SlideoutPanel
      reveal={reveal}
      closeButtonOnClick={() => {
        closeButton();
        setIsProcessing(false);
        setProcessResult('init');
        setNewBookingCode('');
      }}
      closeOnClickOutside
      title="Create a Reservation"
      contentIsScrollable={contentIsScrollable}
      removeContentPadding
    >
      {renderReservationContent()}
    </SlideoutPanel>
  );
};

CreateReservationModal.propTypes = {
  reveal: PropTypes.bool,
  closeButton: PropTypes.func,
  brands: PropTypes.array,
  organization: PropTypes.object,
  user: PropTypes.object,
  channels: PropTypes.array
};

CreateReservationModal.defaultProps = {};

export default CreateReservationModal;
