import React from 'react';

export default class ListBrandsCommandPalette extends React.Component {
  static propTypes = {};

  constructor(props, _railsContext) {
    super(props);
  }

  render() {
    return (
      <section className="consolidated-item-command-palette">
        <div className="consolidated-item-command-palette-content">
          <header className="consolidated-item-command-palette-breadcrumbs">
            <span className="consolidated-item-command-palette-heading">
              My Organizations
            </span>
          </header>
          <nav className="consolidated-item-command-palette-nav">
            <a className="button" href="/my-organizations/create">
              New organization
            </a>
          </nav>
        </div>
      </section>
    );
  }
}
