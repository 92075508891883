import React, { useState, useEffect } from 'react';
import {
  Box,
  Modal,
  FlexBox,
  CallToActionButton,
  Divider,
  FormFieldGridRow,
  Label,
  InputCurrency,
  StandByOverlay,
  FormGroupHeader,
  FormField,
  TextBody,
  InputSwitch,
  RadioButtonGrid,
  Spacer,
  TextDense,
  IconFontAwesome,
  FinancialLineItems,
  TextH2,
  InfoPanel,
  IconButton
} from '@directsoftware/ui-kit-web-admin';
import WidgetDatePicker from 'sharedComponents/WidgetDatePicker';
import { InputTimeHandler } from 'adminComponents';
import displayError from 'sharedComponents/ErrorDisplay';
import {
  listDeliveryLocations,
  createDeliveryLocation
} from 'adminApi/DeliveryLocationService';
import CurrencyDefinitions from 'sharedUtils/currency-definitions';
import { QuoteService } from 'adminApi';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import findIndex from 'lodash/findIndex';
import SelectService from 'adminApi/SelectService';
import { toast } from 'react-toastify';
import { translateObjectKeysFromCamelCaseToSnakeCase } from 'sharedUtils';
import { useDetectMobile } from '../../shared/hooks';
import { formatCurrency } from '../../shared/utils/currency-helpers';
import DeliveryLocationForm from '../pages/vehicles/atoms/DeliveryLocationForm';

const RvshareBookingEditModal = ({
  organizationID,
  vehicleID,
  initialStartDate,
  initialEndDate,
  initialPickUp,
  initialDropOff,
  initialRoomRate,
  orgCurrency,
  initialNumberOfNights,
  totalRoomRate,
  lineItems,
  totalPrice,
  bookingID,
  quote,
  unitListingId,
  generatorIncluded,
  mileageIncluded,
  rvshareBookingCancellationPolicy,
  bookingRequest,
  deliveryLocationID,
  vehicle,
  defaultMileage,
  defaultGenerator,
  securityDepositAmount
}) => {
  const filterLineItems = lineItemName => {
    return lineItems.filter(
      lineItem => lineItem.name?.toLowerCase() === lineItemName.toLowerCase()
    )[0];
  };
  const [feeLineItems, setFeeLineItems] = useState(
    lineItems.filter(item => item.item_type === 'fees')
  );
  const [newVehicleId, setNewVehicleId] = useState(vehicleID);
  const generateFeeLineItems = () => {
    const mandatoryFeeLineItems = [];
    feeLineItems
      .filter(lineItem => !lineItem.optional)
      .map(fee =>
        mandatoryFeeLineItems.push({
          id: fee.id,
          label: fee.name,
          value: parseFloat(fee.total_cents) / 100,
          editable: true,
          addToQuote: true,
          rate: fee.rate,
          frequency: isNull(fee.rate) ? 'one_time' : 'nightly'
        })
      );
    return mandatoryFeeLineItems;
  };
  const generateUpgradeLineItems = () => {
    const upgradeFeeLineItems = [];
    feeLineItems
      .filter(lineItem => lineItem.optional)
      .map(fee =>
        upgradeFeeLineItems.push({
          id: fee.id,
          label: fee.name,
          value: parseFloat(fee.total_cents) / 100,
          editable: true,
          addToQuote: true,
          rate: fee.rate,
          frequency: fee.additional_data.frequency
        })
      );
    return upgradeFeeLineItems;
  };
  const { isMobile, isTablet, isDesktop } = useDetectMobile();
  const [showModal, setShowModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCalculateDialog, setOpenCalculateDialog] = useState(false);
  const [showSaveOverlay, setShowSaveOverlay] = useState(false);
  const [newStartDate, setNewStartDate] = useState(initialStartDate);
  const [newEndDate, setNewEndDate] = useState(initialEndDate);
  const [pickUp, setPickUp] = useState(initialPickUp);
  const [dropOff, setDropOff] = useState(initialDropOff);
  const [roomRate, setRoomRate] = useState(
    initialRoomRate ? initialRoomRate.toFixed(2) : 0
  );
  const [newRoomRate, setNewRoomRate] = useState(0);
  const [numberOfNights, setNumberOfNights] = useState(initialNumberOfNights);
  const [changeTotal, setChangeTotal] = useState(0);
  const [quoteRecalculated, setQuoteRecalculated] = useState(false);
  const [possibleUpgrades, setPossibleUpgrades] = useState([]);
  const [upgrades, setUpgrades] = useState([]);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [possibleDeliveryLocations, setPossibleDeliveryLocations] = useState(
    []
  );
  const [deliveryLocation, setDeliveryLocation] = useState(deliveryLocationID);
  const [customGooglePlaceId, setCustomGooglePlaceId] = useState(null); // try to get initial value from delivery location
  const [serviceFee, setServiceFee] = useState(
    (
      parseFloat(filterLineItems('rvshare service fee')?.total_cents) / 100
    ).toFixed(2)
  );
  const [insuranceFee, setInsuranceFee] = useState(
    (
      parseFloat(filterLineItems('rvshare insurance fee')?.total_cents) / 100
    ).toFixed(2)
  );
  const [calculatedTaxes, setCalculatedTaxes] = useState(
    (
      parseFloat(filterLineItems('rvshare calculated taxes')?.total_cents) / 100
    ).toFixed(2)
  );
  const [reservationTotal, setReservationTotal] = useState(
    parseFloat(totalPrice).toFixed(2)
  );
  const [unlimitedHours, setUnlimitedHours] = useState(false);
  const [maxGeneratorHours, setMaxGeneratorHours] = useState(generatorIncluded);
  const [maxMileageIncluded, setMaxMileageIncluded] = useState(
    typeof mileageIncluded === 'string'
      ? mileageIncluded.toLowerCase()
      : mileageIncluded
  );
  const [refundableSecurityDeposit, setRefundableSecurityDeposit] = useState(
    securityDepositAmount
  );
  const [cancellationPolicy, setCancellationPolicy] = useState(
    rvshareBookingCancellationPolicy
  );
  const [isProcessing, setIsProcessing] = useState(false);
  const [groupedFeesForQuote, setGroupedFeesForQuote] = useState(
    generateFeeLineItems()
  );
  const [groupedUpgradesForQuote, setGroupedUpgradesForQuote] = useState(
    generateUpgradeLineItems()
  );
  const [newDefaultGenerator, setNewDefaultGenerator] = useState(
    defaultGenerator
  );
  const [newDefaultMileage, setNewDefaultMileage] = useState(defaultMileage);
  const [quoteSent, setQuoteSent] = useState(false);
  const [vehicleType, setVehicleType] = useState(
    ['pop_up_camper', 'fifth_wheel', 'travel_trailer', 'toy_hauler'].includes(
      vehicle.vehicle_type
    )
      ? 'towable'
      : 'drivable'
  );
  const tempQuoteTotals = type => {
    if (type === 'fees') {
      return groupedFeesForQuote
        .filter(
          fee => fee.addToQuote && !Number.isNaN(parseFloat(fee.value, 2))
        )
        .map(fee => parseFloat(fee.value, 2))
        .reduce((acc, o) => acc + o, 0);
    }
    if (type === 'upgrades') {
      return groupedUpgradesForQuote
        .filter(
          fee => fee.addToQuote && !Number.isNaN(parseFloat(fee.value, 2))
        )
        .map(fee =>
          fee.frequency !== 'per_stay' &&
          fee.frequency !== 'one_time' &&
          !isNull(fee.frequency) &&
          !isUndefined(fee.frequency)
            ? parseFloat(fee.rate, 2) * numberOfNights
            : parseFloat(fee.value, 2)
        )
        .reduce((acc, o) => acc + o, 0);
    }
    if (type === 'rates') {
      return isNull(roomRate) || isUndefined(roomRate)
        ? 0
        : roomRate * parseInt(numberOfNights);
    }
    if (type === 'taxes') {
      return Number.isNaN(parseFloat(calculatedTaxes, 2))
        ? 0.0
        : parseFloat(calculatedTaxes, 2);
    }
    return 0;
  };

  const tempQuoteGrandTotal = () => {
    return (
      parseFloat(tempQuoteTotals('fees'), 2) +
      parseFloat(tempQuoteTotals('upgrades'), 2) +
      parseFloat(tempQuoteTotals('taxes'), 2) +
      parseFloat(tempQuoteTotals('rates'), 2)
    ).toFixed(2);
  };

  const currencyData = CurrencyDefinitions.filter(
    currency => currency.value === orgCurrency
  )[0];

  const centsToDollarString = cents => {
    return (cents / 100.0).toFixed(2);
  };

  const options = possibleUpgrades.map(item => ({
    label: `${item.name} (${centsToDollarString(item.total_cents)})`,
    value: item.id,
    data: item,
    external_id: item.external_id
  }));

  const isSaving = () => {
    setShowSaveOverlay(true);
  };

  const handleCancel = () => {
    setNewStartDate(initialStartDate);
    setNewEndDate(initialEndDate);
    setDropOff(initialDropOff);
    setPickUp(initialPickUp);
    setRoomRate(initialRoomRate);
    setNumberOfNights(initialNumberOfNights);
    setNewRoomRate(0);
    setChangeTotal(0);
    setIsProcessing(false);
    setShowModal(false);
    setQuoteRecalculated(false);
    setQuoteSent(false);
  };

  const recalculateQuote = () => {
    isSaving();
    QuoteService.calculateRvshareBooking(
      organizationID,
      newStartDate,
      newEndDate,
      newVehicleId,
      pickUp,
      dropOff,
      roomRate * numberOfNights,
      bookingID,
      maxGeneratorHours,
      maxMileageIncluded,
      cancellationPolicy,
      groupedUpgradesForQuote.filter(
        fee => fee.addToQuote && !Number.isNaN(fee.value)
      ),
      refundableSecurityDeposit,
      customGooglePlaceId,
      deliveryLocation
    )
      .then(response => {
        setChangeTotal(
          (response.booking.totalPriceWithInsurance - totalPrice).toFixed(2)
        );
        setNewRoomRate(
          (response.booking.rentalPrice / numberOfNights).toFixed(2)
        );
        setRoomRate((response.booking.rentalPrice / numberOfNights).toFixed(2));
        setReservationTotal(
          parseFloat(response.booking.totalPriceWithInsurance).toFixed(2)
        );
        const updatedFeeLineItems = feeLineItems;
        updatedFeeLineItems[
          findIndex(
            feeLineItems,
            feeLi => feeLi.label === 'RVshare Insurance Fee'
          )
        ].value = parseFloat(response.booking.insurancePrice, 2);
        updatedFeeLineItems[
          findIndex(
            feeLineItems,
            feeLi => feeLi.label === 'RVshare Service Fee'
          )
        ].value = parseFloat(response.booking.serviceFee);
        const existingDeliveryFeeIndex = findIndex(
          feeLineItems,
          feeLi => feeLi.label.toLowerCase() === 'delivery fee'
        );
        if (existingDeliveryFeeIndex > 0) {
          const newDeliveryFee = response.booking.additionalFees.find(
            fee => fee.name.toLowerCase() === 'delivery fee'
          );
          if (newDeliveryFee)
            updatedFeeLineItems[existingDeliveryFeeIndex].value = parseFloat(newDeliveryFee.upgradePrice);
        }
        setFeeLineItems(updatedFeeLineItems);
        setGroupedFeesForQuote(updatedFeeLineItems);
        setInsuranceFee(parseFloat(response.booking.insurancePrice));
        setServiceFee(parseFloat(response.booking.serviceFee));
        setCalculatedTaxes(parseFloat(response.booking.taxPrice));
        // handleUpgradeChanges(response.booking.selectedUpgrades.nodes);
        setShowSaveOverlay(false);
        setIsProcessing(false);
        setQuoteRecalculated(true);
      })
      .catch(response => {
        setShowSaveOverlay(false);
        setIsProcessing(false);
        return toast.error(response.data.error);
      });
  };

  const sendNewQuote = () => {
    QuoteService.rvshareQuote(
      organizationID,
      null,
      newStartDate,
      newEndDate,
      newVehicleId,
      pickUp,
      dropOff,
      roomRate * numberOfNights,
      maxGeneratorHours,
      maxMileageIncluded,
      null,
      cancellationPolicy,
      null,
      null,
      groupedUpgradesForQuote.filter(
        fee => fee.addToQuote && !Number.isNaN(fee.value)
      ),
      bookingID,
      refundableSecurityDeposit,
      customGooglePlaceId
    )
      .then(response => {
        window.location.reload();
      })
      .catch(error => {
        setShowSaveOverlay(false);
        setIsProcessing(false);
        return toast.error(`failed to send quote ${error}`);
      });
  };

  const sendAlteration = () => {
    QuoteService.sendAlteration(
      organizationID,
      newStartDate,
      newEndDate,
      newVehicleId,
      pickUp,
      dropOff,
      roomRate * numberOfNights,
      bookingID,
      maxGeneratorHours,
      maxMileageIncluded,
      cancellationPolicy,
      deliveryLocation,
      groupedUpgradesForQuote.filter(
        fee => fee.addToQuote && !Number.isNaN(fee.value)
      ),
      refundableSecurityDeposit,
      customGooglePlaceId,
      feeLineItems.find(fee => fee.label.toLowerCase() === 'delivery fee')?.value
    )
      .then(response => {
        window.location.reload();
      })
      .catch(response => {
        setShowSaveOverlay(false);
        setIsProcessing(false);
        return toast.error(response.data.error);
      });
  };

  const onDatesChange = ({ startDate, endDate }) => {
    setNewStartDate(startDate);
    setNewEndDate(endDate);
    setQuoteRecalculated(false);
  };

  const getNumberOfNights = () => {
    const date1 = new Date(newStartDate);
    const date2 = new Date(newEndDate);
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const numOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return numOfNights;
  };

  const setModalSize = () => {
    if (isMobile) return 'cover';
    if (isTablet) return 'form';
    if (isDesktop) return 'xl';
  };

  const fetchVehicles = () => {
    if (newStartDate && newEndDate) {
      QuoteService.retrieveAvailableVehicles(
        organizationID,
        newStartDate,
        newEndDate,
        newVehicleId
      ).then(response => {
        const newVehicleOptions = response.options.map(data => {
          return {
            label: data.name,
            value: data.id,
            data
          };
        });

        setVehicleOptions(newVehicleOptions);
      });
    } else {
      SelectService.index(organizationID, {
        model: 'Vehicle',
        joins: [
          'LEFT JOIN unit_listings ON unit_listings.vehicle_id = vehicles.id'
        ],
        select: [
          'id',
          'name',
          'external_id',
          "CASE WHEN vehicle_type >= 4 THEN 'towable' ELSE 'drivable' END as vehicle_type",
          'unit_listings.rvshare_cancellation_policy'
        ]
      }).then(response => {
        const newVehicleOptions = response.options.map(data => {
          return {
            label: data.name,
            value: data.id,
            data
          };
        });

        setVehicleOptions(newVehicleOptions);
      });
    }
  };

  const renderRvshareForm = () => {
    const tempCancellationPolicies = [
      { label: 'Strict', value: 'strict' },
      { label: 'Standard', value: 'standard' },
      { label: 'Flexible', value: 'flexible' }
    ];
    return (
      <>
        <FlexBox
          paddingHorizontal="s"
          paddingTop="xs"
          paddingBottom="m"
          className="bookingEditModal__formWrapper"
          flexDirection="column"
        >
          <Box>
            <FormGroupHeader
              title="Listing Details"
              horizontalPadding="none"
              removeTopMargin
              removeTopBorder
            />
            <FormField
              labelText="RV Listing"
              labelHtmlFor="rv_listing"
              inputType="select"
              inputProps={{
                options: vehicleOptions.filter(
                  listing => !isNull(listing.data.external_id)
                ),
                value: vehicleOptions.filter(
                  listings => listings.value === newVehicleId
                )[0],
                onChange: option => {
                  const newFees = feeLineItems.filter(
                    fee => fee.label !== 'Delivery fee'
                  );
                  setNewVehicleId(option.value);
                  setDeliveryLocation('');
                  setQuoteRecalculated(false);
                  setRoomRate(0);
                  setNewRoomRate(0);
                  setFeeLineItems(newFees);
                  setGroupedFeesForQuote(newFees);
                  setVehicleType(option.data.vehicle_type);
                  setMaxMileageIncluded(0);
                  setRefundableSecurityDeposit(
                    option.data.security_deposit_amount
                  );
                  setNewDefaultGenerator(option.data.generator_usage_included);
                  setNewDefaultMileage(option.data.mileage_included);
                }
              }}
            />
            <DeliveryLocationForm
              setLocationAttributes={values => {
                setQuoteRecalculated(false);
                setDeliveryLocation('');
                return setCustomGooglePlaceId(values.googlePlaceId);
              }}
              customLabel="Custom Delivery Location"
            />
            {possibleDeliveryLocations && (
              <FormField
                labelText="Delivery Location"
                labelHtmlFor="delivery_location"
                inputType="select"
                inputProps={{
                  isClearable: true,
                  options: possibleDeliveryLocations.filter(
                    location => !isNull(location.data.external_id)
                  ),
                  value:
                    possibleDeliveryLocations.filter(
                      location => location.data.external_id === deliveryLocation
                    )[0] || '',
                  onChange: (option, actions) => {
                    if (actions.action === 'clear') {
                      setDeliveryLocation('');
                      const newFees = feeLineItems.filter(
                        fee => fee.label !== 'Delivery fee'
                      );
                      setFeeLineItems(newFees);
                      setGroupedFeesForQuote(newFees);
                      setQuoteRecalculated(false);
                    } else {
                      setDeliveryLocation(option.data.external_id);
                      const newFees = feeLineItems.filter(
                        fee => fee.label !== 'Delivery fee'
                      );
                      newFees.push({
                        label: 'Delivery fee',
                        value: parseFloat(option.data.price).toFixed(2),
                        editable: false,
                        addToQuote: true
                      });
                      setFeeLineItems(newFees);
                      setGroupedFeesForQuote(newFees);
                      setQuoteRecalculated(false);
                      setCustomGooglePlaceId(null);
                    }
                  }
                }}
              />
            )}
            <WidgetDatePicker
              // bookingCalendar={availabilityCalendar}
              label="Pick Up/Drop Off Dates"
              organizationID={organizationID}
              unitID={vehicleID}
              startDate={newStartDate}
              endDate={newEndDate}
              onDatesChange={onDatesChange}
              removeOldStyling
            />
            <FormFieldGridRow
              formFields={[
                <InputTimeHandler
                  name="defaultTimeCheckIn"
                  label="Pick Up Time"
                  value={pickUp}
                  callback={e => {
                    setPickUp(e);
                  }}
                />,
                <InputTimeHandler
                  name="defaultTimeCheckOut"
                  label="Drop Off Time"
                  value={dropOff}
                  callback={e => {
                    setDropOff(e);
                  }}
                />
              ]}
            />
            <Label>Average Nightly Room Rate</Label>
            <FormFieldGridRow
              formFields={[
                <InputCurrency
                  onValueChange={value => {
                    setRoomRate(value);
                  }}
                  value={roomRate}
                  prefix={currencyData.symbol}
                  inputWidth="m"
                  addSuffix={`X ${numberOfNights} nights`}
                />,
                <FlexBox gap="xs">
                  <TextBody
                    textColor="dark-gray"
                    weight="semibold"
                    className="bookingEditModal__textInFieldRow"
                  >
                    {formatCurrency(
                      tempQuoteTotals('rates'),
                      currencyData.value
                    )}
                  </TextBody>
                  <TextBody className="bookingEditModal__textInFieldRow">
                    Total
                  </TextBody>
                </FlexBox>
              ]}
            />
            <FormGroupHeader title="Policies" horizontalPadding="none" />
            <FormField
              labelText="Max Generator Hours"
              labelHtmlFor="max_generator_hours"
              inputType="number"
              inputProps={{
                value: unlimitedHours ? 'unlimited' : maxGeneratorHours,
                onChange: e => setMaxGeneratorHours(e.target.value),
                addSuffix: 'hours',
                inputWidth: 's',
                isDisabled: unlimitedHours,
                description: `Default: ${newDefaultGenerator} hours per night`
              }}
            />
            <InputSwitch
              onChange={() => {
                setUnlimitedHours(!unlimitedHours);
                setMaxGeneratorHours(unlimitedHours ? newDefaultGenerator : -1);
              }}
              isChecked={unlimitedHours}
              label="Unlimited Generator Hours"
            />
            {vehicleType === 'drivable' && (
              <>
                <FormField
                  labelText="Mileage Included"
                  labelHtmlFor="max_mileage_included"
                  inputType="number"
                  inputProps={{
                    value: maxMileageIncluded,
                    onChange: e => setMaxMileageIncluded(e.target.value),
                    addSuffix: 'miles',
                    inputWidth: 's',
                    isDisabled: maxMileageIncluded === 'unlimited',
                    description: `Default: ${newDefaultMileage} miles per night`
                  }}
                />
                <InputSwitch
                  onChange={() => {
                    return maxMileageIncluded === 'unlimited'
                      ? setMaxMileageIncluded(newDefaultMileage)
                      : setMaxMileageIncluded('unlimited');
                  }}
                  isChecked={maxMileageIncluded === 'unlimited'}
                  label="Allow Unlimited Mileage"
                />
              </>
            )}
            <FormField
              labelText="Refundable Security Deposit"
              labelHtmlFor="refundable_security_deposit"
              inputType="currency"
              inputProps={{
                value: refundableSecurityDeposit,
                onValueChange: value => setRefundableSecurityDeposit(value),
                inputWidth: 's',
                prefix: currencyData.symbol
              }}
            />
            <Label>Cancellation Policy</Label>
            <Spacer size="xs" />
            <RadioButtonGrid
              radioButtons={tempCancellationPolicies.map(policy => {
                return {
                  checked: policy.value === cancellationPolicy,
                  label: policy.label,
                  onClick: () => setCancellationPolicy(policy.value)
                };
              })}
              numberOfColumns="1"
            />
            <FormGroupHeader
              title="Additional Upgrades"
              horizontalPadding="none"
            />
          </Box>
        </FlexBox>
        <FlexBox
          paddingHorizontal="s"
          paddingTop="s"
          paddingBottom="m"
          flexDirection="column"
          className="bookingEditModal__receiptWrapper"
        >
          <Box>
            <TextH2 textColor="gray">Quote Line Items</TextH2>
            <Spacer />
            <FinancialLineItems
              lineItems={[
                {
                  label: 'Room Rate',
                  value: tempQuoteTotals('rates')
                },
                {
                  label: 'Fees',
                  value: tempQuoteTotals('fees'),
                  subItems: groupedFeesForQuote
                    .filter(fee => fee.addToQuote && !Number.isNaN(fee.value))
                    .map(fee => {
                      return {
                        label: fee.label,
                        value: fee.value
                      };
                    })
                },
                {
                  label: 'Upgrades',
                  value: tempQuoteTotals('upgrades'),
                  subItems: groupedUpgradesForQuote
                    .filter(fee => fee.addToQuote && !Number.isNaN(fee.value))
                    .map(fee => {
                      return {
                        label: fee.label,
                        value:
                          fee.frequency === 'nightly' ||
                          fee.frequency === 'daily'
                            ? parseFloat(fee.rate, 2) * numberOfNights
                            : parseFloat(fee.value, 2)
                      };
                    })
                },
                {
                  label: 'Calculated Taxes',
                  value: tempQuoteTotals('taxes')
                }
              ]}
            />
            <Divider paddingBottom="s" />
            {changeTotal !== '0.00' &&
              changeTotal !== '0' &&
              changeTotal !== 0 && (
                <FlexBox justifyContent="space-between" alignItems="center">
                  <TextH2 textColor="gray">Change Total</TextH2>
                  <TextH2>
                    {formatCurrency(changeTotal, currencyData.value)}
                  </TextH2>
                </FlexBox>
              )}
            <Divider padding="s" />
            <FlexBox justifyContent="space-between" alignItems="center">
              <TextH2 textColor="gray">Booking Total</TextH2>
              <TextH2>
                {formatCurrency(tempQuoteGrandTotal(), currencyData.value)}
              </TextH2>
            </FlexBox>
            <Divider padding="s" />
            {/* <FlexBox justifyContent="space-between" alignItems="center">
              <TextH2 textColor="gray">Your Payout</TextH2>
              <TextH2>{formatCurrency(0, currencyData.value)}</TextH2>
            </FlexBox> */}
            <Spacer />
            {quoteRecalculated && (
              <>
                <InfoPanel
                  icon={<IconFontAwesome name="infoCircle" />}
                  headline="Quote has been recalculated"
                  description="If you send this new quote, an alteration request will be sent to the customer, but will not change the booking in Direct until the customer confirms the changes"
                />
                <Spacer size="xs" />
              </>
            )}
            {quoteSent && (
              <>
                <InfoPanel
                  color="green"
                  icon={<IconFontAwesome name="circleCheck" />}
                  headline="New Quote Sent to Customer"
                  description="As a reminder, the booking in Direct won't change until the customer confirms the changes"
                  actionOnClick={() => handleCancel()}
                  actionLabel="Close Modal"
                />
                <Spacer size="xs" />
              </>
            )}
            <CallToActionButton
              onClick={() => {
                if (!quoteRecalculated) {
                  setIsProcessing(true);
                  recalculateQuote();
                } else {
                  setIsProcessing(true);
                  if (bookingRequest) {
                    sendNewQuote();
                  } else {
                    sendAlteration();
                  }
                  setTimeout(() => {
                    setIsProcessing(false);
                    setQuoteRecalculated(false);
                    setQuoteSent(true);
                  }, 20000);
                }
              }}
              appearance={quoteRecalculated ? 'filled' : 'outline'}
              isFullWidth
              isDisabled={isProcessing}
              prependIcon={
                isProcessing ? <IconFontAwesome name="spinner" spin /> : null
              }
            >
              {quoteRecalculated ? 'Send New Quote' : 'Recalculate Quote'}
            </CallToActionButton>
            <Spacer size="xs" />
            <CallToActionButton
              onClick={() => handleCancel()}
              variation="secondary"
              appearance="ghost"
              isFullWidth
            >
              Cancel
            </CallToActionButton>
            <FormField
              labelText="Add Additional Upgrades"
              inputType="select"
              inputProps={{
                options: groupedUpgradesForQuote.filter(
                  fee => fee.editable && !fee.addToQuote
                ),
                value: '',
                onChange: option => {
                  const newArr = [...groupedUpgradesForQuote];
                  const index = groupedUpgradesForQuote.findIndex(
                    obj => obj.id === option.id
                  );
                  newArr[index].addToQuote = true;
                  setGroupedUpgradesForQuote(newArr);
                  setQuoteRecalculated(false);
                },
                isDisabled:
                  groupedUpgradesForQuote.filter(
                    fee => fee.editable && !fee.addToQuote
                  ).length === 0
              }}
            />
            {groupedUpgradesForQuote
              .filter(upgrade => upgrade.editable && upgrade.addToQuote)
              .map((upgrade, idx) => (
                <FlexBox
                  alignItems="center"
                  className="bookingEditModal__feeUpgradeItem"
                  key={`${idx}-upgrade`}
                >
                  <Box flex="1">
                    <TextBody weight="semibold" isFullWidth>
                      {upgrade.label}
                    </TextBody>
                    <TextDense>
                      {`${currencyData.symbol}${upgrade.value}`}
                    </TextDense>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => {
                        const newArr = [...groupedUpgradesForQuote];
                        const index = groupedUpgradesForQuote.findIndex(
                          obj => obj.id === upgrade.id
                        );
                        newArr[index].addToQuote = false;
                        setGroupedUpgradesForQuote(newArr);
                        setQuoteRecalculated(false);
                      }}
                      variation="secondary"
                      appearance="ghost"
                    >
                      <IconFontAwesome name="times" />
                    </IconButton>
                  </Box>
                </FlexBox>
              ))}
          </Box>
        </FlexBox>
      </>
    );
  };

  useEffect(
    () => {
      setNumberOfNights(getNumberOfNights());
    },
    [newStartDate]
  );

  useEffect(
    () => {
      setNumberOfNights(getNumberOfNights());
    },
    [newEndDate]
  );

  useEffect(
    () => {
      setQuoteRecalculated(false);
      if (!isNull(newVehicleId)) {
        listDeliveryLocations({
          orgId: organizationID,
          vehicleId: newVehicleId
        })
          .then(res => {
            const newDeliveryLocations = res.locations.map(data => {
              return {
                label: data.adr_street,
                value: data.id,
                data
              };
            });
            setPossibleDeliveryLocations(newDeliveryLocations);
          })
          .catch(() => {
            toast.error('Could not load delivery locations');
          });
      }
      QuoteService.retrievePotentialRvshareUpgrades(
        organizationID,
        newVehicleId
      ).then(response => {
        response.upgrades.map(fee => {
          groupedUpgradesForQuote.filter(lineItem => !lineItem.addToQuote);
          groupedUpgradesForQuote.push({
            id: fee.id,
            label: fee.name,
            value: parseFloat(fee.calculation_amount).toFixed(2),
            editable: true,
            addToQuote: false,
            rate: fee.calculation_amount,
            frequency: fee.frequency
          });
        });
      });
      QuoteService.retrieveRequiredRvshareFees(
        organizationID,
        newVehicleId
      ).then(response => {
        const newFees = groupedFeesForQuote.filter(
          fee =>
            fee.label === 'RVshare Service Fee' ||
            fee.label === 'RVshare Insurance Fee' ||
            fee.label === 'Delivery fee'
        );
        response.upgrades.map(fee =>
          newFees.push({
            id: fee.id,
            label: fee.name,
            value: parseFloat(fee.calculation_amount).toFixed(2),
            editable: true,
            addToQuote: true,
            optional: false,
            frequency: fee.frequency
          })
        );
        setFeeLineItems(newFees);
        setGroupedFeesForQuote(newFees);
      });
    },
    [newVehicleId]
  );

  useEffect(
    () => {
      setQuoteRecalculated(false);
    },
    [roomRate]
  );

  useEffect(
    () => {
      setQuoteRecalculated(false);
    },
    [groupedUpgradesForQuote]
  );

  useEffect(() => {
    if (!isNull(newVehicleId)) {
      listDeliveryLocations({
        orgId: organizationID,
        vehicleId: newVehicleId
      })
        .then(res => {
          const newDeliveryLocations = res.locations.map(data => {
            return {
              label: data.adr_street,
              value: data.id,
              data
            };
          });
          setPossibleDeliveryLocations(newDeliveryLocations);
        })
        .catch(() => {
          toast.error('Could not load delivery locations');
        });
    }
  }, []);

  useEffect(() => {
    QuoteService.possibleFeeItems(organizationID, quote.id, false)
      .then(response => {
        setPossibleUpgrades(response.fees);
      })
      .catch(err => displayError({ message: 'Error getting fee items', err }));
  }, []);

  useEffect(() => {
    fetchVehicles();
  }, []);

  return (
    <>
      <CallToActionButton
        onClick={() => setShowModal(true)}
        variation="secondary"
        appearance="outline"
      >
        Edit
      </CallToActionButton>
      <Modal
        title="Edit Booking Quote"
        reveal={showModal}
        closeOnClick={() => {
          setShowModal(false);
        }}
        size={setModalSize()}
        fullHeight
      >
        <Modal.Content contentIsScrollable>
          <FlexBox
            setPositionRelative
            flexDirection={isTablet || isMobile ? 'column' : 'row-reverse'}
            className="bookingEditModal__wrapper"
          >
            {showSaveOverlay && <StandByOverlay position="absolute" />}
            {renderRvshareForm()}
          </FlexBox>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default RvshareBookingEditModal;
