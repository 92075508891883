import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SideTabBar } from '@directsoftware/ui-kit-web-admin';
import { useDetectMobile } from '../../shared/hooks';

const SideTabBarWaypoint = ({ children, indicatorPosition }) => {
  const { isTabletOrGreater } = useDetectMobile();

  useEffect(
    () => {
      if (isTabletOrGreater) {
        document.body.classList.add('adjust-for-side-tab');
      } else {
        document.body.classList.remove('adjust-for-side-tab');
      }
    },
    [isTabletOrGreater]
  );

  useEffect(() => {
    return () => {
      document.body.classList.remove('adjust-for-side-tab');
    };
  }, []);

  return (
    <SideTabBar indicatorPosition={indicatorPosition}>{children}</SideTabBar>
  );
};

SideTabBarWaypoint.propTypes = {
  children: PropTypes.any,
  indicatorPosition: PropTypes.number
};

export default SideTabBarWaypoint;
