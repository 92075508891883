import React, { useEffect } from 'react';
import {
  IconButton,
  IconFontAwesome,
  PageHeader
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import { fromSnakeCase } from 'sharedUtils';
import capitalize from 'lodash/capitalize';
import { ACCOUNTING_NAV_TAGS } from 'sharedNav';
import { updateReports } from 'src/admin/redux/slices/reports';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';

const ReportingType = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: ACCOUNTING_NAV_TAGS.PRIMARY,
        secondary: ACCOUNTING_NAV_TAGS.secondary.REPORTING
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/reporting',
            label: 'Reporting'
          },
          {
            label: 'Report Type'
          }
        ]}
      >
        <div className="capitalize-first-letter">
          {`${capitalize(fromSnakeCase(props.reportType))} Report`}
        </div>
      </PageHeader.Left>
      {props.reportType === 'delivery' && (
        <PageHeader.Right>
          <IconButton
            onClick={() => {
              dispatch(updateReports({ printDeliveryReport: true }));
            }}
            variation="secondary"
            appearance="ghost"
          >
            <IconFontAwesome name="print" />
          </IconButton>
        </PageHeader.Right>
      )}
    </PageHeader>
  );
};

export default ReportingType;
