import React, { useEffect, useState } from 'react';
import {
  CallToActionButton,
  IconButton,
  IconFontAwesome,
  PageHeader,
  Tooltip
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import PermissionComponent from 'PermissionComponent';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';
import { ManageSingleCommandPalette } from '../../../../pages/DashboardSingle/molecules';
import ManagePropertyCommandPalette from '../../../../pages/DashboardProperty/molecules/manage-property-command-palette';
import PropertyService from '../../../../api/PropertyService';
import { updateListingImages } from '../../../../redux/slices/listings';

const PropertiesDetails = props => {
  const dispatch = useDispatch();
  const activeRoutes = useSelector(state => state.navigation.activeRoutes);
  const [showMultiUnitView, setShowMultiUnitView] = useState(false);
  const [propertyProps, setPropertyProps] = useState(props.property);
  const [isImagesPage, setIsImagesPage] = useState(false);

  useEffect(
    () => {
      if (props?.activeProperty?.multi_unit) {
        setShowMultiUnitView(true);
      }
    },
    [props, props.location]
  );

  useEffect(
    () => {
      if (!propertyProps) {
        PropertyService.get(props.organization.id, props.propertyId).then(
          response => {
            setPropertyProps(response.property);
          }
        );
      }
      setIsImagesPage(
        activeRoutes.tertiary === LISTINGS_NAV_TAGS.tertiary.IMAGES
      );
    },
    [props]
  );

  return props.activeProperty ? (
    <>
      <PageHeader height="l" containerVariation="fluid">
        <PageHeader.Left
          breadcrumbs={[
            {
              href: '/properties',
              label: 'Listings: Properties'
            },
            {
              label: props?.activeProperty?.multi_unit
                ? `Details (Multi-unit)`
                : `Details (Single Unit)`
            }
          ]}
          backLinkHref={isImagesPage ? `/properties/${props.propertyId}` : null}
        >
          {props.activeProperty.name}
        </PageHeader.Left>
        {isImagesPage && (
          <PageHeader.Right>
            <PermissionComponent
              user={props.user}
              permission="property_index_images"
              restriction="properties_read_only"
            >
              <IconButton
                onClick={() => {
                  dispatch(updateListingImages({ resyncPropertyPhotos: true }));
                }}
                variation="secondary"
                appearance="ghost"
              >
                <Tooltip theme="dark" tipContent="Resync Airbnb">
                  <IconFontAwesome name="rotate" />
                </Tooltip>
              </IconButton>
            </PermissionComponent>
            <CallToActionButton
              onClick={() => {
                dispatch(updateListingImages({ uploadPropertyPhotos: true }));
              }}
              prependIcon={<IconFontAwesome name="upload" />}
            >
              Upload
            </CallToActionButton>
          </PageHeader.Right>
        )}
        {!isImagesPage && propertyProps && (
          <PageHeader.Right>
            {showMultiUnitView ? (
              <PermissionComponent
                user={props.user}
                permission="view_properties"
                restriction="properties_read_only"
              >
                <ManagePropertyCommandPalette
                  organization={props.organization}
                  property={propertyProps}
                  user={props.user}
                />
              </PermissionComponent>
            ) : (
              <PermissionComponent
                user={props.user}
                permission="view_properties"
                restriction="properties_read_only"
              >
                <ManageSingleCommandPalette
                  organization={props.organization}
                  property={propertyProps}
                  unit={props.unit}
                  user={props.user}
                  history={props.history}
                />
              </PermissionComponent>
            )}
          </PageHeader.Right>
        )}
      </PageHeader>
    </>
  ) : null;
};

export default PropertiesDetails;
