import React from 'react';
import ListingsRoutes from './page-headers/listings/routes';
import ReservationsRoutes from './page-headers/reservations/routes';
import UnifiedInboxRoutes from './page-headers/unified-inbox/routes';
import DistributionRoutes from './page-headers/channels/routes';
import AccountingRoutes from './page-headers/accounting/routes';
import SiteEditorRoutes from './page-headers/site-editor/routes';
import PeopleRoutes from './page-headers/people/routes';
import DashboardRoutes from './page-headers/dashboard/routes';
import ReportingRoutes from './page-headers/reporting/routes';
import WorkflowsRoutes from './page-headers/workflows/routes';
import SettingsRoutes from './page-headers/settings/routes';
import PaymentsRoutes from './page-headers/payments/routes';
import OtherFeaturesRoutes from './page-headers/other-features/routes';
import MessagingRoutes from './page-headers/messaging/routes';
import GlobalSearchRoutes from './page-headers/global-search/routes';

const NavbarPageHeader = props => {
  return (
    <>
      <DashboardRoutes {...props} />
      <ListingsRoutes {...props} />
      <ReservationsRoutes {...props} />
      <UnifiedInboxRoutes {...props} />
      <DistributionRoutes {...props} />
      <AccountingRoutes {...props} />
      <PaymentsRoutes {...props} />
      <SiteEditorRoutes {...props} />
      <PeopleRoutes {...props} />
      <ReportingRoutes {...props} />
      <WorkflowsRoutes {...props} />
      <SettingsRoutes {...props} />
      <MessagingRoutes {...props} />
      <OtherFeaturesRoutes {...props} />
      <GlobalSearchRoutes {...props} />
    </>
  );
};

export default NavbarPageHeader;
