import includes from "lodash/includes";
const canViewChangelog = (user) => {
  const roles = ["organization_owner", "reservationist", "property_manager"];
  return includes(roles, user.role_name);
};
const checkPermissions = (user, permission, restriction) => {
  let permissionsGranted = false;
  if (user) {
    if (user.permissions[permission]) {
      permissionsGranted = true;
    }
    if (restriction && user.permissions[restriction])
      permissionsGranted = false;
  }
  return permissionsGranted;
};
const checkDevRestriction = () => {
  return window.location.hostname === "www.lvh.me" || window.location.hostname === "staging.getdirect.io";
};
export { canViewChangelog, checkPermissions, checkDevRestriction };
