import React from 'react';
import find from 'lodash/find';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';

const options = [
  { value: '', label: 'All Statuses' },
  { value: 'booking_request', label: 'Requested' },
  { value: 'confirmed_booking', label: 'Confirmed' },
  { value: 'archived_booking', label: 'Archived' },
  { value: 'cancelled_booking', label: 'Cancelled' },
  { value: 'expired_booking', label: 'Expired' },
  { value: 'rejected_booking', label: 'Rejected' }
];

const BookingStatusFilter = props => (
  <figure style={{ flex: `1 1 auto`, marginRight: '5px' }}>
    <label htmlFor="booking_status">Booking Status</label>
    <InputSelect
      name="booking_status"
      className="magnified"
      options={options}
      isClearable={false}
      isSearchable={false}
      placeholder="All Statuses"
      onChange={option => props.updateFilter('booking_status', option.value)}
      value={find(options, { value: props.booking_status })}
    />
  </figure>
);

export default BookingStatusFilter;
