import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import Stepper from '../../../components/stepper';
import AsideOrganizationBasic from '../molecules/aside-organization-basic';
import AsideOrganizationLanguageCurrency from '../molecules/aside-organization-language-currency';

import FieldsOrganizationBasic from '../molecules/fields-organization-basic';
import FieldsOrganizationLocation from '../molecules/fields-organization-location';
import FieldsOrganizationLanguageCurrency from '../molecules/fields-organization-language-currency';
import FieldsOrganizationTerms from '../molecules/fields-organization-terms';
import FieldsOrganizationCreditCard from '../molecules/fields-organization-credit-card';
import FieldsOrganizationBrand from '../molecules/fields-organization-brand';

import { ajaxDataWithAuthorization } from 'apiClient';
import { StripeService } from 'adminApi';
import { toast } from 'react-toastify';

export default class NewOrganization extends React.Component {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      parentId: props.parentId,
      companyType: '',
      currency: '',
      description: '',
      language: '',
      name: '',
      organizationType: '',
      subdomain: '',
      adrStreet: '',
      adrUnit: '',
      adrCity: '',
      adrState: '',
      adrCountry: '',
      adrPostalCode: '',
      termsAccepted: false,
      ccAccepted: false,
      tier: props.parentId ? 'suborg' : 'starter',
      interval: props.parentId ? 'custom' : 'monthly',
      submitting: false,
      brandName: '',
      brandDescription: ''
    };
  }

  createOrganization = e => {
    e.preventDefault();
    this.setState({ submitting: true });
    const randomNumber = new Date().valueOf();
    const subdomainWithId = this.state.subdomain + '-' + randomNumber;
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'POST',
        url: '/api/organizations',
        context: this,
        data: {
          parent_id: this.state.parentId,
          company_type: this.state.companyType,
          currency: this.state.currency,
          description: this.state.description,
          language: this.state.language,
          name: this.state.name,
          organization_type: this.state.organizationType,
          subdomain: subdomainWithId,
          user_id: this.props.user.id,
          location_attributes: {
            adr_street: this.state.adrStreet,
            adr_unit: this.state.adrUnit,
            adr_city: this.state.adrCity,
            adr_state: this.state.adrState,
            adr_country: this.state.adrCountry,
            adr_postal_code: this.state.adrPostalCode
          },
          brands_attributes: [
            {
              name: this.state.brandName,
              description: this.state.brandDescription
            }
          ]
        }
      })
    )
      .done(function(data) {
        const organizationSlug = data.subdomain;
        StripeService.getStripeCheckoutSession({
          org_slug: organizationSlug
        }).then(async res => {
          const publishableKey = res.stripe_publishable_key;
          var stripe = await loadStripe(publishableKey);
          stripe.redirectToCheckout({ sessionId: res.session_id });
        });
      })
      .fail(function(jqXhr) {
        toast.error(
          'Error, could not create right now, please contact support'
        );
        this.setState({ submitting: false });
        console.warn(jqXhr);
      });
  };

  setCompanyType = companyType => {
    this.setState({ companyType: companyType });
  };

  setCurrency = currency => {
    this.setState({ currency: currency });
  };

  setDescription = description => {
    this.setState({ description: description });
  };

  setLanguage = language => {
    this.setState({ language: language });
  };

  setName = name => {
    this.setState({ name: name });
  };

  setOrganizationType = organizationType => {
    this.setState({ organizationType: organizationType });
  };

  setSubdomain = subdomain => {
    this.setState({ subdomain: subdomain });
  };

  setLocationAttributes = (
    adrStreet,
    adrUnit,
    adrCity,
    adrState,
    adrCountry,
    adrPostalCode
  ) => {
    this.setState({
      adrStreet: adrStreet,
      adrUnit: adrUnit,
      adrCity: adrCity,
      adrState: adrState,
      adrCountry: adrCountry,
      adrPostalCode: adrPostalCode
    });
  };

  setBrandName = brandName => {
    this.setState({ brandName: brandName });
  };

  setBrandDescription = brandDescription => {
    this.setState({ brandDescription: brandDescription });
  };

  setSubscriptionData = (data, callback) => {
    this.setState(data);
    callback(true);
  };

  setTermsAccepted = termsAccepted => {
    this.setState({ termsAccepted: termsAccepted });
  };

  setCCAccepted = ccAccepted => {
    this.setState({ ccAccepted: ccAccepted });
  };

  fieldsBasicIncomplete = () => {
    if (
      this.state.companyType == '' ||
      this.state.organizationType == '' ||
      this.state.name == '' ||
      this.state.subdomain == ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  fieldsLanguageCurrencyIncomplete = () => {
    if (this.state.currency == '' || this.state.language == '') {
      return true;
    } else {
      return false;
    }
  };

  fieldsLocationIncomplete = () => {
    if (
      this.state.adrStreet == '' &&
      this.state.adrUnit == '' &&
      this.state.adrState == '' &&
      this.state.adrCountry == '' &&
      this.state.adrPostalCode == ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  fieldsCCIncomplete = () => {
    return !this.state.ccAccepted;
  };

  fieldsTermsIncomplete = () => {
    if (!this.state.termsAccepted || this.state.submitting) {
      return true;
    } else {
      return (
        this.fieldsBasicIncomplete() &&
        this.fieldsLanguageCurrencyIncomplete() &&
        this.fieldsLocationIncomplete()
      );
    }
  };

  rboSteps = () => {
    return [
      {
        index: 0,
        aside: <AsideOrganizationBasic />,
        component: (
          <FieldsOrganizationBasic
            companyType={this.state.companyType}
            description={this.state.description}
            name={this.state.name}
            organizationType={this.state.organizationType}
            subdomain={this.state.subdomain}
            setCompanyType={this.setCompanyType}
            setDescription={this.setDescription}
            setName={this.setName}
            setOrganizationType={this.setOrganizationType}
            setSubdomain={this.setSubdomain}
          />
        ),
        disabled: this.fieldsBasicIncomplete(),
        label: 'Organization Overview'
      },
      {
        index: 1,
        aside: <AsideOrganizationLanguageCurrency />,
        component: (
          <FieldsOrganizationLanguageCurrency
            currency={this.state.currency}
            language={this.state.language}
            setCurrency={this.setCurrency}
            setLanguage={this.setLanguage}
          />
        ),
        disabled: this.fieldsLanguageCurrencyIncomplete(),
        label: 'Currency and Language'
      },
      {
        index: 2,
        component: (
          <FieldsOrganizationLocation
            adrStreet={this.state.adrStreet}
            adrUnit={this.state.adrUnit}
            adrCity={this.state.adrCity}
            adrState={this.state.adrState}
            adrCountry={this.state.adrCountry}
            adrPostalCode={this.state.adrPostalCode}
            setLocationAttributes={this.setLocationAttributes}
          />
        ),
        disabled: this.fieldsLocationIncomplete(),
        label: 'Business Address'
      },
      {
        index: 3,
        component: (
          <FieldsOrganizationCreditCard
            user={this.props.user}
            parentId={this.state.parentId}
            setCCAccepted={this.setCCAccepted}
          />
        ),
        disabled: this.fieldsCCIncomplete(),
        label: 'Credit Card Information'
      },
      {
        index: 4,
        component: (
          <FieldsOrganizationTerms
            termsAccepted={this.state.termsAccepted}
            setTermsAccepted={this.setTermsAccepted}
          />
        ),
        disabled: this.fieldsTermsIncomplete(),
        label: 'Terms of Service',
        completeClass: ' ',
        completeLabel: 'Next',
        handleComplete: this.createOrganization
      }
    ];
  };

  directSteps = () => {
    return [
      {
        index: 0,
        aside: <AsideOrganizationBasic />,
        component: (
          <FieldsOrganizationBasic
            companyType={this.state.companyType}
            description={this.state.description}
            name={this.state.name}
            organizationType={this.state.organizationType}
            subdomain={this.state.subdomain}
            setCompanyType={this.setCompanyType}
            setDescription={this.setDescription}
            setName={this.setName}
            setOrganizationType={this.setOrganizationType}
            setSubdomain={this.setSubdomain}
          />
        ),
        disabled: this.fieldsBasicIncomplete(),
        label: 'Organization Overview'
      },
      {
        index: 1,
        aside: <AsideOrganizationLanguageCurrency />,
        component: (
          <FieldsOrganizationLanguageCurrency
            currency={this.state.currency}
            language={this.state.language}
            setCurrency={this.setCurrency}
            setLanguage={this.setLanguage}
          />
        ),
        disabled: this.fieldsLanguageCurrencyIncomplete(),
        label: 'Currency and Language'
      },
      {
        index: 2,
        component: (
          <FieldsOrganizationLocation
            adrStreet={this.state.adrStreet}
            adrUnit={this.state.adrUnit}
            adrCity={this.state.adrCity}
            adrState={this.state.adrState}
            adrCountry={this.state.adrCountry}
            adrPostalCode={this.state.adrPostalCode}
            setLocationAttributes={this.setLocationAttributes}
          />
        ),
        disabled: this.fieldsLocationIncomplete(),
        label: 'Business Address'
      },
      {
        index: 3,
        component: (
          <FieldsOrganizationBrand
            brandName={this.state.brandName}
            brandDescription={this.state.brandDescription}
            setBrandName={this.setBrandName}
            setBrandDescription={this.setBrandDescription}
          />
        ),
        label: 'Brand'
      },
      {
        index: 4,
        component: (
          <FieldsOrganizationTerms
            termsAccepted={this.state.termsAccepted}
            setTermsAccepted={this.setTermsAccepted}
          />
        ),
        disabled: this.fieldsTermsIncomplete(),
        label: 'Terms of Service',
        completeClass: ' ',
        completeLabel: 'Next',
        handleComplete: this.createOrganization
      }
    ];
  };

  render() {
    const steps = this.props.parentId ? this.rboSteps() : this.directSteps();

    return (
      <section>
        <Stepper steps={steps} showStepLabels={true} noBack={true}/>
      </section>
    );
  }
}
