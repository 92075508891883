import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SideTabAnchorsCore from './side-tab-anchors-core';

const ListingsSideTabAnchors = ({
  tabs,
  property,
  unit,
  isMultiUnit,
  allProps
}) => {
  const [filteredTabs, setFilteredTabs] = useState([]);
  const checkPermissions = tag => {
    switch (tag) {
      case 'unit-location':
        return allProps.direct_location;
      case 'internet':
        return unit ? unit?.active_on_homeaway : property?.active_on_homeaway;
      case 'guest_controls':
        return unit ? unit?.active_on_airbnb : property?.active_on_airbnb;
      case 'cleaning':
        return unit
          ? unit.property?.features_cleaning
          : property.property?.features_cleaning;
      default:
        return true;
    }
  };

  useEffect(
    () => {
      setFilteredTabs(
        tabs.filter(tab => checkPermissions(tab.checkPermissionTag))
      );
    },
    [tabs]
  );

  return filteredTabs.length > 0 ? (
    <SideTabAnchorsCore sideTabs={filteredTabs} />
  ) : null;
};

ListingsSideTabAnchors.propTypes = {
  tabs: PropTypes.array
};

ListingsSideTabAnchors.defaultProps = {
  tabs: []
};

export default ListingsSideTabAnchors;
