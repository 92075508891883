import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropertiesHome from './properties-home';
import PropertiesDetails from './properties-details';
import PropertiesNew from './properties-new';
import Portfolios from './portfolios';
import SubPortfolios from './sub-portfolios';
import UnitGroups from './unit-groups';
import Promotions from './promotions';
import RateGroups from './rate-groups';
import RoomTypes from './room-types';
import AuditProperties from './audit-properties';
import PortfolioDetails from './portfolio-details';
import SubPortfolioDetails from './sub-portfolio-details';
import UnitGroupDetails from './unit-group-details';
import PromotionDetails from './promotion-details';
import RateGroupDetails from './rate-groups-details';
import RoomTypesDetails from './room-types-details';
import RateGroupRangesDetails from './rate-group-ranges-details';
import VehiclesHome from './vehicles-home';
import VehicleDetails from './vehicle-details';
import UnitDetails from './unit-details';
import PropertiesBulkEdit from './properties-bulk-edit';
import VehiclesBulkEdit from './vehicles-bulk-edit';
import UnitsAdd from './units-add';

const ListingsRoutes = props => {
  const [activeProperty, setActiveProperty] = useState(null);
  const [activeUnit, setActiveUnit] = useState(null);
  const [activeListing, setActiveListing] = useState(null);

  useEffect(
    () => {
      setActiveProperty(props.activeProperty);
      setActiveUnit(props.activeUnit);
      setActiveListing(props.activeListing);
    },
    [props]
  );

  return (
    <Switch>
      <Route
        path="/bulk-edit/vehicles"
        render={() => <VehiclesBulkEdit {...props} />}
      />
      <Route
        path="/bulk-edit/properties"
        render={() => <PropertiesBulkEdit {...props} />}
      />
      <Route
        path="/vehicles/:vehicleId"
        render={routeProps => (
          <VehicleDetails
            {...props}
            vehicleId={routeProps.match.params.vehicleId}
          />
        )}
      />
      <Route path="/vehicles" render={() => <VehiclesHome {...props} />} />
      <Route
        path="/audit-properties"
        render={() => <AuditProperties {...props} />}
      />
      <Route
        path="/rate-group-ranges/:rateGroupRangeId"
        render={routeProps => (
          <RateGroupRangesDetails
            {...props}
            rateGroupRangeId={routeProps.match.params.rateGroupRangeId}
          />
        )}
      />
      <Route
        path="/room-types/:roomTypeId"
        render={routeProps => (
          <RoomTypesDetails
            {...props}
            roomTypeId={routeProps.match.params.roomTypeId}
          />
        )}
      />
      <Route path="/room-types" render={() => <RoomTypes {...props} />} />
      <Route
        path="/rate-groups/:rateGroupId"
        render={routeProps => (
          <RateGroupDetails
            {...props}
            rateGroupId={routeProps.match.params.rateGroupId}
          />
        )}
      />
      <Route path="/rate-groups" render={() => <RateGroups {...props} />} />
      <Route
        path="/promotions/:promotionId"
        render={routeProps => (
          <PromotionDetails
            {...props}
            promotionId={routeProps.match.params.promotionId}
          />
        )}
      />
      <Route path="/promotions" render={() => <Promotions {...props} />} />
      <Route
        path="/unit-groups/:unitGroupId"
        render={routeProps => (
          <UnitGroupDetails
            {...props}
            unitGroupId={routeProps.match.params.unitGroupId}
          />
        )}
      />
      <Route path="/unit-groups" render={() => <UnitGroups {...props} />} />
      <Route
        path="/sub-portfolios/:subportfolioId"
        render={routeProps => (
          <SubPortfolioDetails
            {...props}
            subportfolioId={routeProps.match.params.subportfolioId}
          />
        )}
      />
      <Route
        path="/sub-portfolios"
        render={() => <SubPortfolios {...props} />}
      />
      <Route
        path="/portfolios/:portfolioId"
        render={routeProps => (
          <PortfolioDetails
            {...props}
            portfolioId={routeProps.match.params.portfolioId}
          />
        )}
      />
      <Route path="/portfolios" render={() => <Portfolios {...props} />} />
      <Route
        path="/properties/new"
        render={() => <PropertiesNew {...props} />}
      />
      <Route
        path="/properties/:propertyId/units/new"
        render={routeProps => (
          <UnitsAdd
            {...props}
            routeProps={routeProps}
            propertyId={routeProps.match.params.propertyId}
          />
        )}
      />
      <Route
        path="/properties/:propertyId/units/:unitId"
        render={routeProps => (
          <UnitDetails
            {...props}
            activeProperty={activeProperty}
            activeUnit={activeUnit}
            activeListing={activeListing}
            propertyId={routeProps.match.params.propertyId}
            unitId={routeProps.match.params.unitId}
          />
        )}
      />
      <Route
        path="/properties/:propertyId"
        render={routeProps => (
          <PropertiesDetails
            {...props}
            routeProps={routeProps}
            activeProperty={activeProperty}
            activeUnit={activeUnit}
            activeListing={activeListing}
            propertyId={routeProps.match.params.propertyId}
          />
        )}
      />
      <Route path="/properties" render={() => <PropertiesHome {...props} />} />
    </Switch>
  );
};

export default ListingsRoutes;
