import React from 'react';
import PermissionComponent from 'PermissionComponent';
import {
  INBOX_NAV_TAGS,
  LISTINGS_NAV_TAGS,
  RESERVATIONS_NAV_TAGS,
  ACCOUNTING_NAV_TAGS
} from 'sharedNav';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import InboxLink from 'adminNavbar/inbox/links/inbox-link';
import { MORE_NAV_TAGS_RV_FLEET } from 'src/shared/nav';
import RvFleetLeftMoreLink from 'src/admin/navigation/design-system-nav/navbar/linkCollections/rv-fleet/rv-fleet-left-more-link';
import ListingsLinkMenu from './link-menus/listings/listings-link-menu';
import ReservationsLinkMenu from './link-menus/reservations/reservations-link-menu';
import AccountingLinkMenu from './link-menus/accounting/accounting-link-menu';

const RvFleetLeft = props => {
  return (
    <Navbar.Left homeLink="/" homeLinkOnClick={props.homeLinkOnClick}>
      <ListingsLinkMenu {...props} navTags={LISTINGS_NAV_TAGS} />
      <ReservationsLinkMenu {...props} navTags={RESERVATIONS_NAV_TAGS} />
      <PermissionComponent user={props.user} permission="messages">
        <InboxLink {...props} navTags={INBOX_NAV_TAGS} />
      </PermissionComponent>
      <PermissionComponent user={props.user} permission="view_accounting">
        <AccountingLinkMenu {...props} navTags={ACCOUNTING_NAV_TAGS} />
      </PermissionComponent>
      <RvFleetLeftMoreLink {...props} navTags={MORE_NAV_TAGS_RV_FLEET} />
    </Navbar.Left>
  );
};

export default RvFleetLeft;
