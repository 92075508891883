import React, { useMemo, useState } from 'react';
import SimpleSelect from 'adminComponents/Selects/SimpleSelect';
import { nativeSymbolFromValue } from 'sharedComponents/currency-display';
import {
  Box,
  CallToActionButton,
  Divider,
  FlexBox,
  FormField,
  FormFieldGridRow,
  IconFontAwesome
} from '@directsoftware/ui-kit-web-admin';
import find from 'lodash/find';

const AddExpenseRow = ({ addExpense, currency, organization }) => {
  const [expenseTo, setExpenseTo] = useState('');
  const [unitId, setUnitId] = useState('');
  const [amount, setAmount] = useState('');
  const [memo, setMemo] = useState('');

  const expenseOptions = [
    { value: 0, label: 'Company' },
    { value: 1, label: 'Unit' }
  ];

  const selectedExpenseTo = useMemo(
    () => {
      const tempValue =
        find(expenseOptions, option => option.value === expenseTo) || {};
      return tempValue;
    },
    [expenseTo]
  );

  const updateExpense = expense => {
    addExpense(expense);
    setExpenseTo('');
    setAmount('');
    setUnitId('');
    setMemo('');
  };

  const submitExpense = e => {
    e.preventDefault();

    updateExpense({
      expense_to: expenseTo,
      unit_id: unitId,
      amount,
      memo
    });
  };

  const renderAddButton = () => {
    return (
      <FlexBox alignItems="center" gap="s">
        <Box flex="1">
          <Divider />
        </Box>
        <CallToActionButton
          isDisabled={
            expenseTo === '' ||
            (expenseTo === 1 && unitId === '') ||
            amount === ''
          }
          onClick={e => submitExpense(e)}
          appearance="ghost"
          prependIcon={<IconFontAwesome name="plus" />}
        >
          Add Expense
        </CallToActionButton>
        <Box flex="1">
          <Divider />
        </Box>
      </FlexBox>
    );
  };

  return (
    <>
      <>
        <FormFieldGridRow
          formFields={[
            {
              component: (
                <FormField
                  labelText="Amount"
                  labelHtmlFor="amount"
                  inputType="currency"
                  inputProps={{
                    value: amount,
                    onValueChange: (value, name, values) => {
                      setAmount(value);
                    },
                    currencyPrefix: nativeSymbolFromValue(currency)
                  }}
                />
              )
            },
            {
              component: (
                <FormField
                  inputProps={{
                    value: selectedExpenseTo,
                    onChange: option => setExpenseTo(option.value),
                    options: expenseOptions
                  }}
                  inputType="select"
                  labelText="Expense to"
                  labelHtmlFor="expense_to"
                />
              )
            }
          ]}
        />

        <FormFieldGridRow
          formFields={[
            expenseTo === 1 || expenseTo === '' ? (
              <SimpleSelect
                label="Unit"
                selectData={{
                  model: 'Unit',
                  joins: [
                    'inner join properties on properties.id = units.property_id'
                  ],
                  select: [
                    'id',
                    'name',
                    'properties.unit_code as property_unit_code'
                  ],
                  order: 'name'
                }}
                onSelect={selection => {
                  setUnitId(selection.id);
                }}
                optionLabel={option => {
                  return option.property_unit_code
                    ? `${option.name} - ${option.property_unit_code}`
                    : option.name;
                }}
                defaultValue={options => {
                  return options.find(o => o.value === unitId) || null;
                }}
                isSearchable
                placeholder="Required for each new unit expense..."
                isDisabled={expenseTo === ''}
                useDesignKit
              />
            ) : (
              {
                component: {
                  inputProps: {
                    value: organization.name,
                    disabled: true
                  },
                  inputType: 'text',
                  labelText: 'Company'
                }
              }
            )
          ]}
        />
        <FormFieldGridRow
          formFields={[
            <FormField
              labelText="Memo"
              labelHtmlFor="memo"
              inputType="textarea"
              inputProps={{
                value: memo,
                onChange: event => {
                  setMemo(event.target.value);
                }
              }}
            />
          ]}
        />
      </>
      {renderAddButton()}
    </>
  );
};

export default AddExpenseRow;
