// Dependencies
// -----------------------------------------------
import React from 'react';

// -----------------------------------------------
// COMPONENT->OVERVIEW-SINGLE-ASIDE-BEDROOMS -----
// -----------------------------------------------
const OverviewSingleAsideBedrooms = () => {
  // ---------------------------------------------
  // RETURN --------------------------------------
  // ---------------------------------------------
  return (
    <div>
      <figure>
        <small className="subtitle">Bedrooms</small>
        <p>
          Let your guests know how many beds and bedrooms this property has. You
          can also note any beds or pull-out couches located in your property's
          common areas.
        </p>
      </figure>

      <figure>
        <small className="subtitle">Bed Types</small>
        <p>
          From sleeper sofas and murphy beds to cribs, you can note any kind of
          bed you have.
        </p>
      </figure>
    </div>
  );
};

// -----------------------------------------------
// EXPORT ----------------------------------------
// -----------------------------------------------
export default OverviewSingleAsideBedrooms;
