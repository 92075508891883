import React from 'react';

import { Waypoint } from 'react-waypoint';

export default class TermsConditions extends React.Component {
  render() {
    return (
      <figure
        className={
          this.props.isContained ? 'terms-conditions' : 'new-terms-conditions'
        }
      >
        <div id={this.props.isContained ? 'terms' : ''}>
          <p>
            <h3>
              <strong>Manager License and Services Agreement</strong>
            </h3>
            <br />
            <p>Last Updated: March 17, 2021</p>
            <br />
            <p>
              This Manager License and Services Agreement (this “
              <strong>Agreement</strong>
              ”), effective as of the date this agreement is executed (the “
              <strong>Effective Date</strong>
              ”), constitutes a binding agreement between Vacation Rental
              Partners, Inc. (“
              <strong>VRP</strong>
              ”) and you, an entity that manages property listings via the
              Service (“
              <strong>Manager</strong>
              ,” “
<strong>you</strong>
              ,” or “<strong>your</strong>
              ”).This Agreement sets forth the terms and conditions upon which
              VRP grants Manager a license to use the VRP websites, GetDirect.io
              and its subdomains, DirectSoftware.com and its subdomains,
              including any versions optimized for viewing on a mobile device or
              any version that is made available on a third-party website with
              VRP’s authorization; and all other interactive features, services,
              software and communications provided by VRP (the “
              <strong>Service</strong>
              ”).{' '}
              <strong>
                By clicking “I Agree,” you accept all the terms and conditions
                of this Agreement. If you DO NOT agree to the terms and
                conditions of this Agreement, DO NOT click “I Agree” or use the
                Service.
              </strong>
            </p>
            <br />
            <p>
              <strong>1. The Service. </strong>
              VRP makes available a platform to manage rental properties listed
              by Manager on the Service (“
              <strong>Listed Units</strong>
              ”), operations, distribution to online third-party marketplaces,
              and accept bookings by guests via white label websites. VRP does
              not own, operate, sell, furnish, provide, rent, manage or control
              Listed Units or Manager’s services, including real estate,
              vehicles, boats, and equipment. Manager is solely responsible for
              determining the appropriateness and suitability of any prospective
              guest or any activity booked through the Service.
            </p>
            <br />
            <p>
              <strong>2. License. </strong>
              Subject to Manager’s compliance with the terms, conditions and
              limitations of this Agreement, including payment to VRP of the
              fees set forth herein, VRP grants you a limited, revocable,
              non-exclusive, non-transferable license to access and make use of
              the Service during the term of the Agreement. This license does
              not include any resale or commercial use of the Service or their
              contents; any collection and use of any images or third-party
              content on the Service; any derivative use of the Service or their
              contents; any uncompiling, disassembling, reverse engineering, or
              other such attempts at discovering the source code of any
              Services; any downloading or copying of account information for
              the benefit of another company or user; or any use of data mining,
              robots, or similar data gathering and extraction tools. The
              Service, or any portion thereof, may not be reproduced, sold,
              resold, visited or otherwise exploited for any commercial purpose
              without VRP’s express written consent. You will not access or
              attempt to access content on the Service through any interface
              except for the publicly provided websites or applications provided
              by VRP.
            </p>
            <br />
            <p>
              <strong>3. Intellectual Property.</strong>
            </p>
            <br />
            <p>
              3.1 The Service and all of the content featured or displayed on
              the Service, including without limitation text, graphics,
              photographs, images, sound, and illustrations, and excluding
              Manager Data, defined in Section 6 (“
              <strong>Content</strong>
              ”), is owned by VRP or its licensors, vendors, agents or its
              Content providers. All elements of the Service, including without
              limitation the general design and the Content, are protected by
              trade dress, copyright, moral rights, trademark and other laws
              relating to intellectual property rights. You agree not to
              challenge VRP’s ownership of all right, title and interest in and
              to the Service (or any code underlying the Service). The Service
              may only be used for the intended purpose for which such Service
              is being made available. You are authorized to view, play, print
              and download copyrighted documents, audio and video found on our
              Service for personal, informational, and non-commercial purposes
              only. You may not modify any of the materials and you may not
              copy, distribute, transmit, display, perform, reproduce, publish,
              license, create derivative works from, transfer or sell any
              information or work contained on the Service. The Service, its
              Content and all related rights shall remain the exclusive property
              of VRP or its licensors unless otherwise expressly agreed. You
              will not remove any copyright, trademark or other proprietary
              notices from material found on the Service.
            </p>
            <br />
            <p>
              We will respond to notices of alleged copyright infringement that
              comply with applicable law and are properly provided to VRP. We
              reserve the right to remove any material alleged to be infringing
              without prior notice and without liability to you. When
              appropriate, we may also terminate your account. If you believe
              that your copyrighted information has been used in a way that
              constitutes copyright infringement, please send a notice that
              complies with the requirements of the Digital Millennium Copyright
              Act to our designated agent at:
            </p>
            <br />
            <p>Vacation Rental Partners, Inc.</p>
            <p>1588 S Main St, #102</p>
            <p>Salt Lake City, UT, 84115</p>
            <p>
              Email:{' '}
              <a
                href="mailto:help@getdirect.io"
                target="_blank"
                rel="noreferrer"
              >
                help@getdirect.io
              </a>{' '}
            </p>
            <br />
            <p>
              3.2 All trademarks, service marks and trade names of VRP used in
              the Service (including but not limited to: VRP’s name and logo;
              the Service’s name, design, and any logos) (collectively “
              <strong>Marks</strong>
              ”) are trademarks of VRP or its affiliates, partners, vendors or
              licensors. You may not use, copy, reproduce, republish, upload,
              post, transmit, distribute, or modify the Marks in any way,
              including in advertising or publicity pertaining to distribution
              of materials on the Service, without VRP’s prior written consent.
              VRP prohibits the use of the Marks as a “hot” link on or to any
              other website unless establishment of such a link is approved in
              advance. You shall not use VRP’s name or any language, pictures or
              symbols which could, in VRP’s judgment, imply VRP’s endorsement in
              any written or oral advertising, presentation, brochure,
              newsletter, book, or other written material of whatever nature,
              without prior written consent.
            </p>
            <br />
            <p>
              <strong>4. Use of the Service.</strong>
            </p>
            <br />
            <p>
              4.1 You are prohibited from using the Service to post or send any
              unlawful, threatening, defamatory, libelous, obscene, pornographic
              or profane material or any material that could constitute or
              encourage conduct that would be considered a criminal offense or
              give rise to civil liability, or otherwise violate any law. In
              addition to any remedies that we may have at law or in equity, if
              we determine, in our sole discretion, that you have violated or
              are likely to violate the foregoing prohibitions, we may take any
              action we deem necessary to cure or prevent the violation. We may
              fully cooperate with any law enforcement authorities or court
              order or subpoena requesting or directing us to disclose the
              identity of anyone inappropriately using the Service.
            </p>
            <br />
            <p>
              4.2 Running or displaying the Service or any information or
              material displayed on the Service in frames or through similar
              means on another website without our prior written permission is
              prohibited. From time to time, the Service may contain links to
              websites that are not owned, operated or controlled by VRP or its
              affiliates. All such links are provided solely as a convenience to
              you. If you use these links, you will leave the Service. Neither
              we nor any of our respective affiliates are responsible for any
              content, materials or other information located on or accessible
              from any other website. We do not endorse, guarantee, or make any
              representations or warranties regarding any other websites,
              services, or any other information located or accessible from any
              other websites or services. If you decide to access any other
              websites, you do so entirely at your own risk.
            </p>
            <br />
            <p>
              4.3 You agree that you will not use any robot, spider, scraper or
              other automated means to access the Service for any purpose
              without our express written permission. Additionally, you agree
              that you will not: (i) take any action that imposes, or may impose
              in our sole discretion an unreasonable or disproportionately large
              load on our infrastructure; (ii) interfere or attempt to interfere
              with the proper working of the Service or any activities conducted
              on the Service; or (iii) bypass any measures we may use to prevent
              or restrict access to the Service. We retain the right at our sole
              discretion to deny access to anyone to this Service, at any time
              and for any reason, including, but not limited to, for violation
              of this Agreement.
            </p>
            <br />
            <p>
              4.4 You may not post, upload, publish, submit or transmit any
              content (including but not limited to any text, graphics, images,
              music, software, audio, video, information or other material) via
              the Services that (a) infringes, misappropriates or violates a
              third party’s patent, copyright, trademark, trade secret, moral
              rights or other intellectual property rights, or rights of
              publicity or privacy; (b) violates, or encourages any conduct that
              would violate, any applicable law or regulation or would give rise
              to civil or criminal liability; (c) is fraudulent, false,
              misleading or deceptive; (d) is defamatory, obscene, pornographic,
              vulgar or offensive as determined in VRP’s sole judgment; (e)
              promotes discrimination, bigotry, racism, hatred, harassment or
              harm against any individual or group; (f) is violent or
              threatening or promotes violence or actions that are threatening
              to any other person; or (g) promotes illegal or harmful activities
              or substances. In addition to any other remedies, VRP may
              immediately suspend or terminate any Manager that violates these
              restrictions.
            </p>
            <br />
            <p>
              <strong>5. Bookings.</strong>
            </p>
            <br />
            <p>
              5.1 If a guest makes a booking at any of your Listed Units, any
              agreement you enter into with such guest is between you and the
              guest, and VRP is not a party or responsible for any commitments
              or obligations contained therein.
            </p>
            <br />
            <p>
              5.2 You acknowledge and agree that VRP does not act as an insurer
              or as a contracting agent for you as Manager. VRP recommends that
              you obtain appropriate insurance for your business. Please review
              any insurance policies that you may have carefully, and in
              particular please make sure that you are familiar with and
              understand any exclusions to, and any deductibles that may apply
              for, including, but not limited to, whether or not your insurance
              policy will cover the actions or inactions of guests (and the
              individuals the guest invites, if applicable) while renting your
              Listed Units.
            </p>
            <p>
              5.3 You acknowledge and agree that you are fully authorized,
              licensed and permitted to provide your Listed Units, including the
              authority to license or rent properties. You acknowledge and agree
              that, as between VRP and yourself, you are responsible for the
              acts and omissions for your employees, contractors, vendors,
              agents and all other parties that are involved in providing your
              Listed Units.
            </p>
            <br />
            <p>
              <strong>6. Data Privacy; Security. </strong>
            </p>
            <br />
            <p>
              6.1Data collection and use, including the collection and use of
              personal information, is governed by VRP’s{' '}
              <a
                href="https://www.directsoftware.com/legal/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              , which is incorporated into and is a part of this Agreement.
            </p>
            <br />
            <p>
              6.2 VRP shall implement and maintain commercially reasonable
              technical, physical and organizational security measures intended
              to protect and safeguard the data that is provided to VRP by or on
              behalf of you under this Agreement (“
              <strong>Manager Data</strong>
              ”). In the event that VRP becomes aware of any breach of security
              with respect to Manager Data, VRP shall: (i) provide notice of the
              security breach to Manager; (ii) perform an investigation to learn
              the cause of such security breach; and (iii) take all steps
              reasonably necessary to remedy the event and to prevent such
              security breach from reoccurring.
            </p>
            <br />
            <p>
              6.3 Manager is the sole and exclusive owner of its Manager Data.
            </p>
            <br />
            <p>
              6.4 VRP may de-identify and/or aggregate Manager Data so that it
              does not contain personal information ("
              <strong>De-Identified Data</strong>
              ").{' '}
            </p>
            <br />
            <p>
              6.5 VRP and its licensors may collect, store and process analytics
              data about the Service and Manager’s use of the Service, that does
              not contain personal information, such as machine data, technical
              data, usage data, systems data, data gathered via cookies and
              other online tracking mechanisms, and other similar types of data
              ("
              <strong>Analytics Data</strong>
              ").{' '}
            </p>
            <br />
            <p>
              6.6 VRP is the sole and exclusive owner of all right, title and
              interest in and to Analytics Data and De-Identified Data, which
              VRP may use and share for any business purpose in compliance with
              applicable laws. Without limiting the foregoing, De-Identified
              Data and Analytics Data may be aggregated with other data by VRP
              and used for benchmarking and Service improvement purposes.
            </p>
            <br />
            <p>
              6.7 Manager is solely responsible for all Manager Data input into
              the Service, including its legality and accuracy and the resulting
              output. Manager is responsible for backing up any Manager Data
              input into the Service.
            </p>
            <br />
            <p>
              <strong>7. Right to Cancel, Error, Mistakes. </strong>
              The Service may contain technical inaccuracies and typographical
              or other errors in connection with information displayed. VRP
              assumes no responsibility or liability for such errors,
              inaccuracies, or omissions. VRP has the right to make changes,
              corrections or cancellations to such information, at any time,
              including after confirmation of a transaction. VRP is not
              responsible for communication failures, errors, difficulties, or
              other malfunctions or lost, stolen or misdirected transactions,
              transmissions, messages or entries on or in connection with the
              Service. The Service may not be continuously available due to
              maintenance or repairs or due to computer problems, disruptions in
              Internet service or other unforeseen circumstances.
            </p>
            <br />
            <p>
              <strong>8. Service Plans; Payment.</strong>
            </p>
            <br />
            <p>
              8.1 <strong>Service Plans. </strong>
              VRP offers Starter, Professional, and Enterprise service plans.
              Managers may switch from the Starter to Professional plan at any
              time and on demand in the Service, provided Manager inputs a
              minimum of ten (10) Listed Units into the Service.
            </p>
            <br />
            <p>
              8.2 <strong>Starter Plan. </strong>
              Managers on the Starter plan must have a minimum of one (1) Listed
              Unit and no more than ten (10) Listed Units. Manager shall pay
              percentage-based transaction fees (“
              <strong>Transaction Fees</strong>
              ”), in addition to a monthly subscription fee (“
              <strong>Subscription Fee</strong>
              ”) of $15.00 per Listed Unit per month. Transaction Fees are 1.9%
              of any gross revenue associated with a Listed Unit. VRP will
              charge the Transaction Fees and Subscription Fee to Manager’s
              payment account at the end of Manager’s thirty (30) day billing
              cycle beginning on the Effective Date. At Manager’s option,
              Manager may pay amounts due via ACH or wire transfer via VRP’s
              third-party payment processor. In the event a charge associated
              with a Listed Unit is refunded, VRP shall issue Manager a refund
              of the Transaction Fee associated with such charge. Manager shall
              execute a Direct Starter Order Form and pay a one-time setup fee
              (“
              <strong>Setup Fee</strong>
              ”) and one-time implementation fee (“
              <strong>Implementation Fee</strong>
              ”), as detailed in Section 8.7.
            </p>
            <br />
            <p>
              8.3 <strong>Advanced Plan.</strong>
{' '}
Managers on the Advanced plan
              must have a minimum of eleven (11) Listed Units and no more than
              fifty (50) Listed Units. Manager shall pay Transaction Fees in
              addition to the following Subscription Fee: $8.00 per Listed Unit
              per month. Transaction Fees are 0.9% of any gross revenue
              associated with a Listed Unit. VRP will charge the Transaction
              Fees and Subscription Fee to Manager’s payment account at the end
              of Manager’s thirty (30) day billing cycle beginning on the
              Effective Date. At Manager’s option, Manager may pay amounts due
              via ACH or wire transfer via VRP’s third-party payment processor.
              In the event a charge associated with a Listed Unit is refunded,
              VRP shall issue Manager a refund of the Transaction Fee associated
              with such charge. Manager shall execute a Direct Advanced Order
              Form and pay a Setup Fee and Implementation Fee, as detailed in
              Section 8.7.
</p>
            <br />
            <p>
              8.4 <strong>Professional Plan. </strong>
              Managers on the Professional plan must have a minimum of fifty-one
              (51) Listed Units and no more than two hundred (200) Listed Units.
              Manager shall pay Transactions Fees in addition to a monthly
              Subscription Fee of $5.00 per Listed Unit per month. Transaction
              Fees are 0.75% of any gross revenue associated with a Listed Unit.
              VRP will charge the Transaction Fees and Subscription Fee to
              Manager’s payment account at the end of Manager’s thirty (30) day
              billing cycle beginning on the Effective Date. At Manager’s
              option, Manager may pay amounts due via ACH or wire transfer via
              VRP’s third-party payment processor. In the event a charge
              associated with a Listed Unit is refunded, VRP shall issue Manager
              a refund of the Transaction Fee associated with such charge.
              Manager will execute a Direct Professional Order Form and pay a
              Setup Fee and Implementation Fee, as detailed in Section 8.7.
            </p>
            <br />
            <p>
              8.5 <strong>Total Subscription Fees and Plan Changes. </strong>
              The total amount of Subscription Fees billed to Manager is based
              on the maximum number of Listed Units (whether active or inactive)
              in the Service during a thirty (30) day billing cycle. Manager’s
              plan may adjust based on the number of Listed Units Manager has in
              the Service.In the event Manager’s number of Listed Units falls
              above or below the threshold for Manager’s then-current plan,
              Manager will transition to the new plan at the end of the
              then-current monthly billing cycle.
{' '}
            </p>
            <br />
            <p>
              8.6 <strong>Trial Period. </strong>
              For fifteen (15) days beginning on the Effective Date, Managers on
              either the Starter Plan or Professional Plan will be subject to a
              trial period whereby Manager may add Listed Units, create and
              accept bookings, utilize features of the Service, and freely
              cancel the Service at any time (“
              <strong>Trial Period</strong>
              ”). In the event Manager cancels the Service before the end of the
              Trial Period, Manager will not be charged Transaction Fees or
              Subscription Fees. In the event Manager continues use of the
              Service after the Trial Period, Manager will be charged
              Subscription Fees based on Manager’s maximum number of Listed
              Units during the entire first thirty (30) day billing cycle
              (including the Trial Period). Manager will not be subject to any
              Transaction Fees that accrued during the Trial Period.
            </p>
            <br />
            <p>
              8.7 <strong>Setup Fee and Implementation Fee. </strong>
              Managers on the Starter plan shall pay a one-time Setup Fee of
              $400. Managers on the Advanced plan shall pay a one-time Setup Fee
              of $750. Managers on the Professional Plan shall pay a one-time
              Setup Fee of $1,000. Setup Fees will be invoiced at the end of the
              Trial Period. Upon execution of a Direct Starter Order Form,
              Direct Advanced Order Form, or Direct Professional Order Form,
              Manager shall pay a one-time Implementation Fee against which any
              Setup Fee paid shall be applied as a credit.
            </p>
            <br />
            <p>
              8.8 <strong>Third Party Add-Ons and Integration Fees. </strong>
              VRP works with certain partners to integrate optional third-party
              functionality in order to enhance the Service and provide Managers
              with a better user experience (“
              <strong>Integrations</strong>
              ”). If Manager elects to turn on any such Integrations, Manager
              may be subject to additional and varying costs (“
              <strong>Integration Fees</strong>
              ”). Upon Manager’s selection of any Integrations, Manager agrees
              to pay applicable Integration Fees as set forth at{' '}
              <a
                href="https://www.directsoftware.com/pricing"
                target="_blank"
                rel="noreferrer"
              >
                https://www.directsoftware.com/pricing
              </a>
              . Integrations and Integration Fees are viewable in the
              Integrations area within the settings page of the Service and can
              change at any time without prior notice to Manager. Manager will
              remit payment monthly for any Integration Fees that incurred
              during a thirty (30) day billing cycle.
            </p>
            <br />
            <p>
              <strong>9. Website Development. </strong> From time to time VRP
              may create websites, customer relationship management tools and
              other online features for use by Manager (the “
              <strong>Online Assets</strong>
              ”). Manager acknowledges and agrees that the Online Assets are the
              sole property of VRP and, notwithstanding the inclusion of
              Manager’s marks, are not a “work made for hire” under the
              Copyright Act or any other law. To the extent that the foregoing
              does not apply, Manager hereby irrevocably assigns to VRP, and its
              successors and assigns, for no additional consideration, Manager’s
              entire right, title and interest in and to the Online Assets and
              any intellectual property rights contained therein. Nothing
              contained in this Agreement shall be construed to reduce or limit
              VRP’s right, title or interest in the Online Assets. Further,
              Manager agrees to stop using and return to VRP the Online Assets
              at VRP’s request or upon the termination or expiration of this
              Agreement. This section shall not apply to any websites created by
              Manager (“
              <strong>Manager Assets</strong>
              ”), which shall remain the sole property of Manager unless
              otherwise agreed to by the parties.
            </p>
            <br />
            <p>
              <strong>10. Disclaimers.</strong>
            </p>
            <p>
              10.1 YOUR USE OF THE SERVICE IS AT YOUR RISK. THE CONTENT,
              MATERIALS AND SERVICE ARE PROVIDED ON AN “AS IS” AND “AS
              AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND INCLUDING
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              SECURITY OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY. NEITHER
              VRP, NOR ANY OF ITS AFFILIATES, SUPPLIERS, SERVICE SUPPLIERS OR
              LICENSORS WARRANT THE ACCURACY OR COMPLETENESS OF THE CONTENT OR
              MATERIALS PROVIDED ON OR THROUGH THE SERVICE NOR THAT THE SERVICE
              WILL MEET YOUR REQUIREMENTS. THE SERVICE COULD INCLUDE TECHNICAL
              OR OTHER MISTAKES, INACCURACIES OR TYPOGRAPHICAL ERRORS. WE MAY
              MAKE CHANGES TO THE CONTENT AND SERVICE AT THE DIRECT WEBSITE,
              INCLUDING THE PRICES AND DESCRIPTIONS OF ANY PRODUCTS OR SERVICES
              LISTED HEREIN, AT ANY TIME WITHOUT NOTICE. THE FOREGOING
              EXCLUSIONS OF IMPLIED WARRANTIES DO NOT APPLY TO THE EXTENT
              PROHIBITED BY LAW. NO ADVICE OR INFORMATION, WHETHER ORAL OR
              WRITTEN, OBTAINED FROM VRP OR THROUGH THE SERVICE, CONTENT AND
              SERVICE WILL NOT CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
            </p>
            <br />
            <p>
              10.2 MANAGER IS AN INDEPENDENT CONTRACTOR AND NOT AN AGENT OR
              EMPLOYEE OF VRP OR ITS AFFILIATES. VRP AND ITS AFFILIATES ARE NOT
              LIABLE FOR THE ACTS, ERRORS, OMISSIONS, REPRESENTATIONS,
              WARRANTIES, BREACHES OR NEGLIGENCE OF SUPPLIER OR FOR ANY PERSONAL
              INJURIES, DEATH, PROPERTY DAMAGE, OR OTHER DAMAGES OR EXPENSES
              RESULTING THEREFROM. VRP AND ITS AFFILIATES HAVE NO LIABILITY AND
              WILL MAKE NO REFUND IN THE EVENT OF ANY DELAY, CANCELLATION,
              OVERBOOKING, STRIKE, FORCE MAJEURE OR OTHER CAUSES BEYOND THEIR
              DIRECT CONTROL, AND THEY HAVE NO RESPONSIBILITY FOR ANY ADDITIONAL
              EXPENSE, OMISSIONS, DELAYS, RE-ROUTING OR ACTS OF ANY GOVERNMENT
              OR AUTHORITY. VRP, ITS AFFILIATES AND THEIR RESPECTIVE SUPPLIERS
              MAKE NO GUARANTEES ABOUT THE AVAILABILITY OF SPECIFIC PRODUCTS AND
              SERVICES.
            </p>
            <br />
            <p>
              <strong>
                11. Limitations of Liability; Limitation on Time to File Claims.
              </strong>
            </p>
            <br />
            <p>
              11.1 You expressly understand and agree that VRP does not assume
              any responsibility for and is not liable for any damages to your
              computer, equipment or other property caused by or arising from
              your access to, use of, or browsing the Service, or your
              downloading of any information or materials from this Service. IN
              NO EVENT WILL VRP, OR ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES,
              SHAREHOLDERS, AFFILIATES, AGENTS, SUCCESSORS OR ASSIGNS, BE LIABLE
              TO YOU OR ANYONE ELSE FOR ANY INDIRECT, SPECIAL, PUNITIVE,
              INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT
              LIMITATION, THOSE RESULTING FROM LOST PROFITS, LOSS OF GOODWILL,
              LOST DATA OR BUSINESS INTERRUPTION) ARISING OUT OF THE USE,
              INABILITY TO USE, OR THE RESULTS OF USING THE SERVICE, ANY
              WEBSITES OR APPLICATIONS LINKED TO THE SERVICE, OR THE MATERIALS,
              INFORMATION OR OTHER CONTENT CONTAINED ON THE SERVICE, WHETHER
              BASED ON WARRANTY, CONTRACT, TORT OR ANY OTHER LEGAL THEORY AND
              WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE
              FOREGOING LIMITATIONS OF LIABILITY DO NOT APPLY TO THE EXTENT
              PROHIBITED BY LAW. IN JURISDICTIONS THAT DO NOT ALLOW THE
              EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES,
              VRP’S LIABILITY IN SUCH JURISDICTIONS SHALL BE LIMITED TO THE
              MAXIMUM EXTENT PERMITTED BY LAW.
            </p>
            <br />
            <p>
              11.2 IN NO EVENT SHALL VRP’S TOTAL LIABILITY TO YOU FOR ALL
              DAMAGES, LOSSES, AND CAUSES OF ACTION WHETHER IN CONTRACT, TORT
              (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE EXCEED
              THE SERVICE FEES YOU PAID TO VRP HEREUNDER IN THE SIX MONTH PERIOD
              ENDING ON THE DATE THAT A CLAIM OR DEMAND IS FIRST ASSERTED.
            </p>
            <br />
            <p>
              11.3 TO THE EXTENT ALLOWED BY APPLICABLE LAW, YOU AGREE THAT YOU
              WILL BRING ANY CLAIM OR CAUSE OF ACTION ARISING FROM OR RELATING
              TO YOUR ACCESS OR USE OF THIS SERVICE WITHIN ONE (1) YEAR FROM THE
              DATE ON WHICH SUCH CLAIM OR ACTION AROSE OR ACCRUED OR SUCH CLAIM
              OR CAUSE OF ACTION WILL BE IRREVOCABLY WAIVED.
            </p>
            <br />
            <p>
              <strong>12. Indemnity. </strong>
              You agree to defend, indemnify and hold VRP and its affiliates,
              and its and their equity holders, directors, officers, employees
              and agents, harmless from any and all liabilities, costs, and
              expenses, including reasonable attorneys’ fees, related to or in
              connection with (i) the use or misuse of the Service or your
              placement or transmission of any message or information on this
              Service by you or your authorized users; (ii) your violation of
              any term of this Agreement, including without limitation, your
              breach of any of the representations and warranties above; (iii)
              your violation of any third-party right, including without
              limitation any right of privacy, publicity rights or intellectual
              property rights, including any such violation arising from
              Content, or VRP’s use of Content; (iv) your violation of any law,
              rule or regulation of the United States or any other country,
              including data protection laws; or (v) any claim or damages that
              arise as a result of any information or data that you provide to
              VRP.
            </p>
            <br />
            <p>
              <strong>13. Term; Termination; Survival.</strong>
            </p>
            <br />
            <p>
              13.1 Subject to Section 8.6 above, unless otherwise set forth in
              an Order Form, the Agreement will commence on the Effective Date
              and shall continue for a period of one (1) year (the “
              <strong>Initial Term</strong>
              ”). After the expiration of the Initial Term, the Agreement shall
              automatically renew pursuant to the then-current pricing schedule
              available at{' '}
              <a
                href="https://www.directsoftware.com/pricing"
                target="_blank"
                rel="noreferrer"
              >
                https://www.directsoftware.com/pricing
              </a>{' '}
              for successive one (1) year periods u nless Manager provides VRP
              with sixty (60) days prior written notice of its intent not to
              renew (“
              <strong>Notice of Termination</strong>
              ”). In the event of termination, Manager shall be responsible for
              all payments due in connection with its then-current billing
              cycle, in addition to the two (2) billing cycles following
              Manager’s Notice of Termination.
            </p>
            <br />
            <p>
              13.2 Manager will have a period of thirty (30) days from the date
              of the Notice of Termination or termination date to request access
              to the Manager Data maintained by VRP (“
              <strong>Transition Period</strong>
              ”). Upon expiration of the Transition Period, VRP shall
              permanently delete all Manager Data.
            </p>
            <br />
            <p>
              13.3 In the event Manager fails to pay any fees required under
              this Agreement, VRP will provide notice to Manager and an
              opportunity to cure any non-payment within fifteen (15) days of
              such notice (“
              <strong>Cure Period</strong>
              ”). In the event Manager’s failure to pay continues after the Cure
              Period, VRP reserves the right to suspend Manager’s access to the
              Service until payment has been made. VRP reserves the right to
              terminate this Agreement in the event Manager continuously fails
              to pay any required fees under this Agreement (“
              <strong>Chronic Failures</strong>
              ”). In the event of VRP’s termination for Chronic Failures,
              Manager will not receive any prorated refunds.
            </p>
            <br />
            <p>
              13.4 VRP reserves the right to terminate this Agreement if Manager
              is inactive on the Service or does not have at least one (1)
              Listed Unit in the Service for at least a period of one (1) year
              from the Effective Date.
            </p>
            <br />
            <p>
              13.5 All provisions of this Agreement that by their nature are
              intended to extend beyond the termination of this Agreement for
              any reason shall survive the termination of this Agreement.
            </p>
            <br />
            <p>
              <strong>14. Miscellaneous.</strong>
            </p>
            <br />
            <p>
              14.1 <strong>Governing Law and Venue. </strong>
              This Agreement, and any disputes arising hereunder, shall be
              governed by the laws of the state of Illinois, United States,
              without regard to its conflicts of law principles. Customer agrees
              to exclusive jurisdiction and venue in the state and federal
              courts having jurisdiction in Cook County, Illinois, U.S. and
              irrevocably consents to personal jurisdiction in such courts. The
              parties specifically exclude application of the United Nations
              Convention on Contracts for the International Sale of Goods and
              the Uniform Computer Information Transactions Act (UCITA) to this
              Agreement.
            </p>
            <br />
            <p>
              14.2 <strong>Compliance with Laws. </strong>
              Each party shall comply with all applicable laws, rules and
              regulations relating to its performance under this Agreement.
              Without limiting the generality of the foregoing, Manager shall
              comply with all applicable import and export laws and regulations
              related to the Service for the country where the Service is used
              by Manager as well as with all applicable United States export
              laws (collectively, the "
<strong>Export Laws</strong>
              "). Manager shall not, and shall not permit any third party to:
              (i) export or transmit the Service, directly or indirectly, in
              violation of Export Laws, including to a country subject to a U.S.
              embargo; (ii) access or use the Service in violation of any Export
              Laws; (iii) provide access to the Service across international
              boundaries except in compliance with any Export Laws, including
              those of the originating country.{' '}
            </p>
            <br />
            <p>
              14.3 <strong>Independent Contractors.</strong>
{' '}
Both parties shall
              be, and shall act as, independent contractors. Neither party shall
              have authority to create any obligation or make any commitments on
              the other party’s behalf.
</p>
            <br />
            <p>
              14.4 <strong>Notices. </strong>
{' '}
All notices under this Agreement
              will be in writing and will be deemed to have been duly given when
              received, if personally delivered; when receipt is electronically
              confirmed, if transmitted by facsimile or e-mail; the day after it
              is sent, if sent for next day delivery by recognized overnight
              delivery service; and upon receipt, if sent by certified or
              registered mail, return receipt requested.
</p>
            <br />
            <p>
              14.5 <strong>Use of Names. </strong>
              Neither party may use the names, trademarks, trade names, service
              marks, insignia, or logos ("
              <strong>Marks</strong>
              ") of the other party without such other party's prior written
              consent. Notwithstanding the foregoing, VRP may publically refer
              to Manager as a customer of VRP online, verbally, and in its
              marketing materials.{' '}
            </p>
            <br />
            <p>
              14.6 <strong>Assignment. </strong>
              This Agreement is not assignable by Manager except with VRP’s
              prior written consent, and any such assignment in violation hereof
              is void. VRP may transfer and assign any of its rights and
              obligations under this Agreement without consent.
            </p>
            <br />
            <p>
              14.7 <strong>Force Majeure. </strong>
              No delay, failure, or default by VRP, will constitute a breach of
              this Agreement to the extent it results of causes beyond the
              reasonable control of VRP, including acts of war, terrorism,
              weather, acts of God or nature, strikes, labor disputes, riots,
              civil disorder, embargo, internet outage, health crisis, pandemic,
              earthquake or the like.
            </p>
            <br />
            <p>
              14.8 <strong>Waiver. </strong>
              Neither party shall be deemed to have waived any of its rights
              under this Agreement by lapse of time or by any statement or
              representation other than by an authorized representative in an
              explicit signed, written waiver. No waiver of a breach of this
              Agreement by either party will constitute a waiver of any other
              breach of this Agreement.
            </p>
            <br />
            <p>
              14.9 <strong>Severability. I</strong>
n the event a court of
              competent jurisdiction finds any provision of this Agreement
              invalid or unenforceable, such provision will be interpreted to
              fulfill its intended purpose to the maximum extent permitted by
              applicable law, and if the foregoing is not possible, such
              provision shall be severed from the Agreement. All remaining
              provisions shall continue in full force and effect.
</p>
            <br />
            <p>
              14.10 <strong>Entire Agreement. </strong>
              This Agreement (including any applicable Order Form and all other
              documents reference herein) constitutes the entire agreement
              between the parties with regard to the subject matter herein and
              supersede all prior or contemporaneous negotiations, discussions,
              understandings or agreements between the parties. Neither party
              has relied upon any such prior or contemporaneous communications.
              Any amendment or modification to this Agreement must be in writing
              and signed by the authorized representatives of both parties to be
              effective.
            </p>
            <br />
            <br />
            <hr />
            <br />
            <b>
              <h3>End User Terms of Service</h3>
            </b>
            <br />
          </p>
          <p>Last Updated: August 1, 2019</p>
          <br />
          <p>
            <b>
              THIS IS A BINDING LEGAL AGREEMENT. PLEASE READ THESE TERMS AND
              CONDITIONS OF SERVICE CAREFULLY BEFORE USING THE SERVICE. THIS
              AGREEMENT CONTAINS MANDATORY ARBITRATION, PROHIBITS CLAIMS AFTER
              ONE YEAR AND REQUIRES ALL LAWSUITS TO BE BROUGHT IN ILLINOIS. IF
              YOU DO NOT AGREE TO THIS AGREEMENT, PLEASE DO NOT USE THE SERVICE
              OR ACCESS OUR WEBSITE. WE RESERVE THE RIGHT TO MODIFY OR AMEND
              THIS AGREEMENT FROM TIME TO TIME WITHOUT NOTICE. YOUR CONTINUED
              USE OF OUR SERVICE FOLLOWING THE POSTING OF CHANGES TO THIS
              AGREEMENT WILL MEAN YOU ACCEPT THOSE CHANGES.&#160;
            </b>
          </p>
          <br />
          <p>
            The terms and conditions, together with all updates, supplements,
            additional terms and any of VRP's rules and policies (collectively,
            the "Agreement") are applicable to the VRP websites, GetDirect.io,
            including any versions optimized for viewing on a mobile device or
            any version that is made available on a third-party website with
            VRP's authorization; VRP call center; and all other interactive
            features, services, software and communications provided by VRP (the
            "Service"), however accessed or used, that are operated by us, made
            available by us, or produced and maintained by Vacation Rental
            Partners, Inc. (collectively "VRP" or "we", "us", or "our"). This
            Agreement is applicable to individuals that use the Service,
            directly or indirectly, in any fashion (a "User") (collectively
            "you" or "your").
          </p>
          <br />
          <p>
            <b>
              YOU ACKNOWLEDGE AND AGREE THAT VRP IS NOT A PARTY TO ANY
              AGREEMENTS ENTERED INTO BETWEEN VACATION RENTAL PROPERTY MANAGERS
              ("MANAGERS") AND THEIR GUESTS, HAS NO CONTROL OVER THE CONDUCT OF
              THE MANAGERS OR THEIR SERVICES, AND DISCLAIMS ANY AND ALL
              LIABILITY ASSOCIATED WITH THE USE OF THE MANAGERS' SERVICES. VRP
              IS NOT RESPONSIBLE FOR AND DISCLAIMS ANY AND ALL LIABILITY RELATED
              TO ANY AND ALL LISTINGS, BOOKINGS AND MANAGERS' PRODUCTS AND
              SERVICES.
            </b>
          </p>
          <br />
          <ol style={{ fontWeight: 'bold' }}>
            <li>
              <b>How the Service Works.</b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                The Service can be used to facilitate the listing of our
                Managers' properties and the booking of said properties by
                guests. VRP makes available a platform for Managersto manage
                their operations, distribution to online third-party
                marketplaces, and accept bookings by guests via white label
                websites. VRP is not an owner or operator of the Managers or any
                associated rental property, including, but not limited to, real
                estate, vehicles, boats, or equipment, and VRP does not own,
                sell, furnish, provide, rent, manage or control the Managers'
                properties and services.
              </span>
            </li>
            <br />
            <li>
              <b>Bookings. </b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                As a condition of your use of this Service, you warrant that (a)
                you are 18 years old or older; (b) you possess the legal
                authority to create a binding legal obligation; (c) you will use
                this Service in accordance with this Agreement; (d) you will
                only use this Service to manage the operations of properties;
                (e) all information supplied by you is true, accurate, current
                and complete and you will maintain and promptly update such
                information to keep it true, accurate, current and complete; (f)
                the submitted information and VRP's use thereof as contemplated
                by this Agreement will not infringe any rights of any third
                party, including but not limited to any intellectual property
                rights, privacy rights and rights of publicity; and (g) if you
                have a GetDirect.io account, you will safeguard your account
                information and will be responsible for any use of your account
                by you and anyone other than you, and you will not share your
                username and password with any third party or permit any third
                party to log-on to the Service using your account information.
                You agree to immediately notify us of any unauthorized use of
                your account or any other breach of security of which you become
                aware.&#160;
              </span>
            </li>
            <br />
            <li>
              <b>Account Suspension. </b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                If you provide any information that is untrue, inaccurate, not
                current or incomplete, or VRP has reasonable grounds to suspect
                that such information is untrue, inaccurate, not current or
                incomplete, of for any other reason identified in VRP
                discretion, VRP has the right to suspend or terminate your
                account and refuse any or all future use of the Service.&#160;
              </span>
            </li>
            <br />
            <li>
              <b>Right to Cancel, Errors, Mistakes. </b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                The Service may contain technical inaccuracies and typographical
                or other errors in connection with information displayed. VRP
                assumes no responsibility or liability for such errors,
                inaccuracies, or omissions. VRP has the right to make changes,
                corrections or cancellations to such information , at any time,
                including after confirmation of a transaction. VRP is not
                responsible for communication failures, errors, difficulties, or
                other malfunctions or lost, stolen or misdirected transactions,
                transmissions, messages or entries on or in connection with our
                Service. The Service may not be continuously available due to
                maintenance or repairs or due to computer problems, disruptions
                in Internet service or other unforeseen circumstances.
              </span>
            </li>
            <br />
            <li>
              <b>Licenses.</b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                VRP grants you a limited, revocable, non-exclusive,
                non-transferable license to access and make personal,
                non-commercial use of the Service or their content on the
                corresponding platforms and not to modify all or any portion of
                the Service. This license does not include any resale or
                commercial use of the Service or their contents; any collection
                and use of any images or third-party content on the Service; any
                derivative use of the Service or their contents; any
                uncompiling, disassembling, reverse engineering, or other such
                attempts at discovering the source code of any Services; any
                downloading or copying of account information for the benefit of
                another company or user; or any use of data mining, robots, or
                similar data gathering and extraction tools. The Service, or any
                portion thereof, may not be reproduced, sold, resold, visited or
                otherwise exploited for any commercial purpose without VRP's
                express written consent. You grant to VRP a limited,
                non-exclusive license to access, store, display and transmit the
                information you submit to the Service and disclosure that
                information to the supplier(s) and in the Service as provided in
                our Privacy Notice. You will not access or attempt to access
                content on the Service through any interface except for the
                publicly provided websites or applications provided by VRP.
              </span>
            </li>
            <br />
            <li>
              <b>Copyright and Ownership.</b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                All of the content featured or displayed on the Service,
                including without limitation text, graphics, photographs,
                images, sound, and illustrations ("Content"), is owned by VRP,
                its licensors, vendors, agents or its Content providers. All
                elements of the Service, including without limitation the
                general design and the Content, are protected by trade dress,
                copyright, moral rights, trademark and other laws relating to
                intellectual property rights. The Service may only be used for
                the intended purpose for which such Service is being made
                available. You are authorized to view, play, print and download
                copyrighted documents, audio and video found on our Service for
                personal, informational, and non-commercial purposes only. You
                may not modify any of the materials and you may not copy,
                distribute, transmit, display, perform, reproduce, publish,
                license, create derivative works from, transfer or sell any
                information or work contained on the Service. The Service, its
                Content and all related rights shall remain the exclusive
                property of VRP or its licensors unless otherwise expressly
                agreed. You will not remove any copyright, trademark or other
                proprietary notices from material found on the Service.
                <br />
                <br />
                We will respond to notices of alleged copyright infringement
                that comply with applicable law and are properly provided to
                VRP. We reserve the right to remove any material alleged to be
                infringing without prior notice and without liability to you.
                When appropriate, we may also terminate your account. If you
                believe that your copyrighted information has been used in a way
                that constitutes copyright infringement, please send a notice
                that complies with the requirements of the Digital Millennium
                Copyright Act to our designated agent at:
                <br />
                <br />
                Vacation Rental Partners, Inc.
                <br />
                1588 S Main St, #102
                <br />
                Salt Lake City, UT, 84115
                <br />
                Email: <a href="mailto:help@getdirect.io">help@getdirect.io</a>
                <br />
              </span>
            </li>
            <br />
            <li>
              <b>Trademarks; No Endorsement. </b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                All trademarks, service marks and trade names of VRP used in the
                Service (including but not limited to: VRP's name and logo; the
                Service's name, design, and any logos) (collectively "Marks")
                are trademarks of VRP or its affiliates, partners, vendors or
                licensors. You may not use, copy, reproduce, republish, upload,
                post, transmit, distribute, or modify the Marks in any way,
                including in advertising or publicity pertaining to distribution
                of materials on the Service, without VRP's prior written
                consent. VRP prohibits the use of the Marks as a "hot" link on
                or to any other website unless establishment of such a link is
                approved in advance. You shall not use VRP's name or any
                language, pictures or symbols which could, in VRP's judgment,
                imply VRP's endorsement in any written or oral advertising,
                presentation, brochure, newsletter, book, or other written
                material of whatever nature, without prior written consent.
              </span>
            </li>
            <br />
            <li>
              <b>Inappropriate Material. </b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                You are prohibited from using the Service to post or send any
                unlawful, threatening, defamatory, libelous, obscene,
                pornographic or profane material or any material that could
                constitute or encourage conduct that would be considered a
                criminal offense or give rise to civil liability, or otherwise
                violate any law. In addition to any remedies that we may have at
                law or in equity, if we determine, in our sole discretion, that
                you have violated or are likely to violate the foregoing
                prohibitions, we may take any action we deem necessary to cure
                or prevent the violation. We may fully cooperate with any law
                enforcement authorities or court order or subpoena requesting or
                directing us to disclose the identity of anyone inappropriately
                using the Service.
              </span>
            </li>
            <br />
            <li>
              <b>Third-Party Links. </b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                Running or displaying the Service or any information or material
                displayed on the Service in frames or through similar means on
                another website without our prior written permission is
                prohibited. From time to time, the Service may contain links to
                websites that are not owned, operated or controlled by VRP or
                its affiliates. All such links are provided solely as a
                convenience to you. If you use these links, you will leave the
                Service. Neither we nor any of our respective affiliates are
                responsible for any content, materials or other information
                located on or accessible from any other website. We do not
                endorse, guarantee, or make any representations or warranties
                regarding any other websites, services, or any other information
                located or accessible from any other websites or services. If
                you decide to access any other websites, you do so entirely at
                your own risk.
              </span>
            </li>
            <br />
            <li>
              <b>Access and Interference. </b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                You agree that you will not use any robot, spider, scraper or
                other automated means to access the Service for any purpose
                without our express written permission. Additionally, you agree
                that you will not: (i) take any action that imposes, or may
                impose in our sole discretion an unreasonable or
                disproportionately large load on our infrastructure; (ii)
                interfere or attempt to interfere with the proper working of the
                Service or any activities conducted on the Service; or (iii)
                bypass any measures we may use to prevent or restrict access to
                the Service. We retain the right at our sole discretion to deny
                access to anyone to this Service, at any time and for any
                reason, including, but not limited to, for violation of this
                Agreement.
              </span>
            </li>
            <br />
            <li>
              <b>DISCLAIMERS. </b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                YOUR USE OF THIS SERVICE IS AT YOUR RISK. THE INFORMATION,
                MATERIALS AND SERVICE PROVIDED ON OR THROUGH THE SERVICE ARE
                PROVIDED "AS IS" WITHOUT ANY WARRANTIES OF ANY KIND INCLUDING
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                SECURITY OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY. NEITHER
                VRP, NOR ANY OF ITS AFFILIATES, SUPPLIERS, SERVICE SUPPLIERS OR
                LICENSORS WARRANT THE ACCURACY OR COMPLETENESS OF THE
                INFORMATION, MATERIALS OR SERVICE PROVIDED ON OR THROUGH THE
                SERVICE. THE INFORMATION, MATERIALS AND SERVICE PROVIDED ON OR
                THROUGH THE SERVICE MAY BE OUT OF DATE, AND NEITHER VRP, NOR ANY
                OF ITS AFFILIATES MAKES ANY COMMITMENT OR ASSUMES ANY DUTY TO
                UPDATE SUCH INFORMATION, MATERIALS OR SERVICE. THE FOREGOING
                EXCLUSIONS OF IMPLIED WARRANTIES DO NOT APPLY TO THE EXTENT
                PROHIBITED BY LAW. NO ADVICE OR INFORMATION, WHETHER ORAL OR
                WRITTEN, OBTAINED FROM VRP OR THROUGH THE SERVICE, CONTENT AND
                SERVICE WILL NOT CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
                <br />
                <br />
                THE MANAGER SON THIS SERVICE ARE INDEPENDENT CONTRACTORS AND NOT
                AGENTS OR EMPLOYEES OF VRP OR ITS AFFILIATES. VRP AND ITS
                AFFILIATES ARE NOT LIABLE FOR THE ACTS, ERRORS, OMISSIONS,
                REPRESENTATIONS, WARRANTIES, BREACHES OR NEGLIGENCE OF ANY SUCH
                MANAGERS OR FOR ANY PERSONAL INJURIES, DEATH, PROPERTY DAMAGE,
                OR OTHER DAMAGES OR EXPENSES RESULTING THEREFROM. VRP AND ITS
                AFFILIATES HAVE NO LIABILITY AND WILL MAKE NO REFUND IN THE
                EVENT OF ANY DELAY, CANCELLATION, OVERBOOKING, STRIKE, FORCE
                MAJEURE OR OTHER CAUSES BEYOND THEIR DIRECT CONTROL, AND THEY
                HAVE NO RESPONSIBILITY FOR ANY ADDITIONAL EXPENSE, OMISSIONS,
                DELAYS, RE-ROUTING OR ACTS OF ANY GOVERNMENT OR AUTHORITY.
                RATINGS DISPLAYED ON THIS SERVICE ARE INTENDED AS ONLY GENERAL
                GUIDELINES, AND VRP AND ITS AFFILIATES DO NOT GUARANTEE THE
                ACCURACY OF THE RATINGS. VRP, ITS AFFILIATES AND THEIR
                RESPECTIVE SUPPLIERS MAKE NO GUARANTEES ABOUT THE AVAILABILITY
                OF SPECIFIC PRODUCTS AND SERVICES.
              </span>
            </li>
            <br />
            <li>
              <b>
                LIMITATIONS OF LIABILITY; LIMITATION ON TIME TO FILE CLAIMS.{' '}
              </b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                VRP does not assume any responsibility for and is not liable for
                any damages to your computer, equipment or other property caused
                by or arising from your access to, use of, or browsing the
                Service, or your downloading of any information or materials
                from this Service. IN NO EVENT WILL VRP, OR ANY OF ITS OFFICERS,
                DIRECTORS, EMPLOYEES, SHAREHOLDERS, AFFILIATES, AGENTS,
                SUCCESSORS OR ASSIGNS, BE LIABLE TO YOU OR ANYONE ELSE FOR ANY
                INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES
                (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST
                PROFITS, LOST DATA OR BUSINESS INTERRUPTION) ARISING OUT OF THE
                USE, INABILITY TO USE, OR THE RESULTS OF USING THE SERVICE, ANY
                WEBSITES OR APPLICATIONS LINKED TO THE SERVICE, OR THE
                MATERIALS, INFORMATION OR OTHER CONTENT CONTAINED ON THE
                SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT OR ANY OTHER
                LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES. THE FOREGOING LIMITATIONS OF LIABILITY DO NOT
                APPLY TO THE EXTENT PROHIBITED BY LAW. IN JURISDICTIONS THAT DO
                NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
                CONSEQUENTIAL DAMAGES, VRP'S LIABILITY IN SUCH JURISDICTIONS
                SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
                <br />
                <br />
                IN THE EVENT OF ANY PROBLEM WITH THE SERVICE, YOU AGREE THAT
                YOUR SOLE REMEDY IS TO CEASE USING THE SERVICE. IN NO EVENT
                SHALL VRP'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND
                CAUSES OF ACTION WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT
                LIMITED TO, NEGLIGENCE), OR OTHERWISE EXCEED , IN THE AGGREGATE,
                THE GREATER OF (A) THE SERVICE FEES YOU PAID TO VRP IN
                CONNECTION WITH SUCH TRANSACTION(S) OR (B) ONE HUNDRED DOLLARS
                (US $100.00).
                <br />
                <br />
                TO THE EXTENT ALLOWED BY APPLICABLE LAW, YOU AGREE THAT YOU WILL
                BRING ANY CLAIM OR CAUSE OF ACTION ARISING FROM OR RELATING TO
                YOUR ACCESS OR USE OF THIS SERVICE WITHIN ONE (1) YEAR FROM THE
                DATE ON WHICH SUCH CLAIM OR ACTION AROSE OR ACCRUED OR SUCH
                CLAIM OR CAUSE OF ACTION WILL BE IRREVOCABLY WAIVED.
              </span>
            </li>
            <br />
            <li>
              <b>Indemnity. </b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                You agree to defend, indemnify and hold VRP and its affiliates,
                and its and their equity holders, directors, officers, employees
                and agents, harmless from any and all liabilities, costs, and
                expenses, including reasonable attorneys' fees, related to or in
                connection with (i) the use of the Service or your placement or
                transmission of any message or information on this Service by
                you or your authorized users; (ii) your violation of any term of
                this Agreement, including without limitation, your breach of any
                of the representations and warranties above; (iii) your
                violation of any third-party right, including without limitation
                any right of privacy, publicity rights or intellectual property
                rights, including any such violation arising from Content, or
                VRP's use of Content; (iv) your violation of any law, rule or
                regulation of the United States or any other country; (v) any
                claim or damages that arise as a result of any information that
                you provide to VRP; or (vi) any other party's access and use of
                the Service with your username and password.
              </span>
            </li>
            <br />
            <li>
              <b>Termination. </b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                VRP may suspend or terminate your account or your use of the
                Service at any time, for any reason or for no reason. You are
                personally liable for any orders placed or charges incurred
                through your account prior to termination. We may also suspend
                your access to our Service in the event that (a) you breach this
                Agreement; (b) we are unable to verify or authenticate any
                information you provide to us; or (c) we believe that your
                actions may cause financial loss or legal liability for you, our
                users, or us.
              </span>
            </li>
            <br />
            <li>
              <b>15. Force Majeure. </b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                Neither VRP nor you shall be responsible for damages or for
                delays or failures in performance resulting from acts or
                occurrences beyond their reasonable control, including, without
                limitation: fire, lightning, explosion, Internet or hosting
                outage, power surge or failure, water, acts of God, war,
                revolution, civil commotion or acts of civil or military
                authorities or public enemies; any law, order, regulation,
                ordinance, or requirement of any government or legal body or any
                representative of any such government or legal body; or labor
                unrest, including without limitation, strikes, slowdowns,
                picketing, or boycotts; inability to secure raw materials,
                transportation facilities, fuel or energy shortages, or acts or
                omissions of other common carriers.
              </span>
            </li>
            <br />
            <li>
              <b>Privacy. </b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                Data collection and use, including the collection and use of
                personal information, is governed by VRP's{' '}
                <a href="https://www.directsoftware.com/legal/privacy">
                  Privacy Policy
                </a>
                , which is incorporated into and is a part of this Agreement.
              </span>
            </li>
            <br />
            <li>
              <b>General. </b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                Any claim relating to the use of the Service, the materials
                contained herein or this Agreement is governed by the laws of
                the State of Illinois. You consent to the exclusive jurisdiction
                of the state and federal courts located in State of Illinois. If
                any provision of this Agreement is held to be illegal, invalid
                or unenforceable, such provision shall be struck and amended to
                achieve as closely as possible the effect of the original
                provision and all other provisions of this Agreement will
                continue in full force and effect. You agree that this Agreement
                may be assigned by VRP in our sole discretion. Headings are for
                reference purposes only and in no way define, limit, construe or
                describe the scope or extent of such section. Our failure to act
                with respect to a breach by you or others does not waive our
                right to act with respect to subsequent or similar breaches. No
                agency, partnership, joint venture, employee-employer or
                franchisor-franchisee relationship is intended or created by
                this Agreement. Fictitious names of companies, products, people,
                characters mentioned on the Services are not intended to
                represent any real individual, company or product.
              </span>
            </li>
            <br />
            <li>
              <b>Entire Agreement; Survival. </b>
              <br />
              <span style={{ fontWeight: 'normal' }}>
                <br />
                These terms and conditions are the entire agreement between you
                and VRP and supersede any prior understandings or agreements
                (written or oral). The license granted by your in Section 7
                (Licenses), 14 (Limitations of Liability; Limitation on Time to
                File Claims), 15 (Indemnity), 16 (Release) and 20 (General)
                shall survive any termination or expiration of this Agreement.
                <br />
              </span>
            </li>
          </ol>
        </div>
        {this.props.isContained && (
          <Waypoint
            onEnter={this.props.handleEnter}
            onLeave={this.props.handleLeave}
          />
        )}
      </figure>
    );
  }
}

TermsConditions.defaultProps = {
  isContained: true
};
