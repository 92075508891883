import React from 'react';
import PermissionComponent from 'PermissionComponent';
import ReservationsListLink from 'adminNavbar/reservations/links/reservations-list-link';
import ReservationsCalendarLink from 'adminNavbar/reservations/links/reservations-calendar-link';
import ReservationsReviewsLink from 'adminNavbar/reservations/links/reservations-reviews-link';
import ReservationsVehiclesCalendarLink from 'adminNavbar/reservations/links/reservations-vehicles-calendar-link';

const LinksMobile = props => {
  return (
    <>
      <PermissionComponent user={props.user} permission="list_view">
        <ReservationsListLink {...props} />
      </PermissionComponent>
      <PermissionComponent user={props.user} permission="grid_view">
        <ReservationsCalendarLink {...props} />
        <ReservationsVehiclesCalendarLink {...props} />
      </PermissionComponent>
      <PermissionComponent user={props.user} permission="manage_brand">
        <ReservationsReviewsLink {...props} />
      </PermissionComponent>
    </>
  );
};

export default LinksMobile;
