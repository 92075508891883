import React from 'react';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import VehiclesLink from 'adminNavbar/listings/links/vehicles-link';
import PortfoliosLink from 'adminNavbar/listings/links/portfolios-link';
import SubPortfoliosLink from 'adminNavbar/listings/links/sub-portfolios-link';
import UnitGroupsLink from 'adminNavbar/listings/links/unit-groups-link';
import RateGroupsLink from 'adminNavbar/listings/links/rate-groups-link';
import PromotionsLink from 'adminNavbar/listings/links/promotions-link';
import BulkSettingsLink from 'adminNavbar/listings/links/bulk-settings-link';
import { FeatureTierDisplay } from 'adminComponents/FeatureTierDisplay';
import PermissionComponent from '../../../../../../../PermissionComponent';

const LinksMobile = props => {
  return (
    <>
      <VehiclesLink {...props} />
      {(props.user.permissions.ledger ||
        props.user.permissions.view_site_editor ||
        props.user.permissions.site_editor_auto_emails) && (
        <PermissionComponent user={props.user} permission="ledger">
          <BulkSettingsLink {...props} />
        </PermissionComponent>
      )}
      <Navbar.LinkMenuGroup groupTitle="Segmentations">
        <PortfoliosLink {...props} />
        <SubPortfoliosLink {...props} />
        <UnitGroupsLink {...props} />
      </Navbar.LinkMenuGroup>
      <FeatureTierDisplay allowedTiers={['premium']}>
        <Navbar.LinkMenuGroup groupTitle="Other Features">
          <RateGroupsLink {...props} />
          <PromotionsLink {...props} />
        </Navbar.LinkMenuGroup>
      </FeatureTierDisplay>
    </>
  );
};

export default LinksMobile;
