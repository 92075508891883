import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@directsoftware/ui-kit-web-admin';
import classnames from 'classnames';

const FormContainer = ({ addPadding, children, centerContainer }) => {
  return (
    <Box
      className={classnames('form-container', {
        'add-padding': addPadding,
        centerContainer
      })}
    >
      {children}
    </Box>
  );
};

FormContainer.propTypes = {
  addPadding: PropTypes.bool,
  children: PropTypes.any,
  centerContainer: PropTypes.bool
};

FormContainer.defaultProps = {
  addPadding: false,
  centerContainer: false,
  children: 'Form goes here'
};

export default FormContainer;
