import React from 'react';
import { InputCheckbox } from '@directsoftware/ui-kit-web-admin';

const OwnerSelfCleanCheckbox = ({ booking, viewer, onToggle }) => {
  const shouldShowCleaningPreferenceOption = () =>
    booking.booking_type === 'owner' || booking.booking_type === 'friend';

  const viewerIsOwner = () => viewer.role_name === 'property_contact';

  const labelText = {
    owner:
      'I WILL PROFESSIONALLY CLEAN THE UNIT AT THE END OF MY STAY, I DO NOT WANT THE MANAGEMENT COMPANY’S CLEANING SERVICES FOR THIS RESERVATION BLOCK',
    pmc: 'Owner will clean their own unit at the end of their stay'
  };
  return (
    shouldShowCleaningPreferenceOption() && (
      <section>
        <InputCheckbox
          onChange={() => onToggle()}
          checked={booking.owner_will_self_clean}
        >
          {viewerIsOwner() ? labelText.owner : labelText.pmc}
        </InputCheckbox>
      </section>
    )
  );
};

export default OwnerSelfCleanCheckbox;
