import React from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import moment from 'moment';
import { StatusOptions } from 'adminComponents/OpportunityCreateModal/SelectOptions';
import EmployeeSelect from 'adminComponents/OpportunityCreateModal/EmployeeSelect';
import WidgetDatePicker from 'sharedComponents/WidgetDatePicker';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';
import OpportunityNotConvertedModal from 'adminComponents/OpportunityNotConvertedModal';
import OpportunityConvertedModal from 'adminComponents/OpportunityConvertedModal';

export default class SingleDetailsInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: props.opportunity.check_in
        ? moment(props.opportunity.check_in)
        : null,
      endDate: props.opportunity.check_out
        ? moment(props.opportunity.check_out)
        : null,
      num_guests: this.props.opportunity.num_guests,
      assignee_id: this.props.opportunity.assignee_id,
      unit_id: this.props.opportunity.unit_id,
      status: this.props.opportunity.status,
      channel_id: this.props.opportunity.channel_id,
      not_converted_reason: this.props.opportunity.not_converted_reason,
      reason_text: this.props.opportunity.reason_text,
      modalOpen: false,
      convertedModalOpen: false
    };
  }

  saveProgress = () => {
    const {
      assignee_id,
      startDate,
      endDate,
      num_guests,
      status,
      unit_id,
      channel_id,
      not_converted_reason,
      reason_text,
      booking_id,
      quote_id
    } = this.state;

    this.props.updateOpportunity({
      assignee_id,
      assignee_type: 'Employee', // This may need to be dynamic based on if a user can be set here
      check_in: startDate,
      check_out: endDate,
      unit_id,
      status,
      num_guests,
      channel_id,
      not_converted_reason,
      reason_text,
      booking_id,
      quote_id,
      broadcast: true
    });
  };

  onChange = changes => {
    if (changes['status'] === 'not_converted') {
      this.setState({ modalOpen: true });
      return;
    } else if (changes['status'] === 'converted') {
      this.setState({ convertedModalOpen: true });
      return;
    }

    this.setState(changes, () => this.saveProgress());
  };

  onDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate }, () => {
      this.saveProgress();
    });
  };

  onModalChangesComplete = (updates = {}) => {
    updates.modalOpen = false;
    this.setState(updates, () => this.saveProgress());
  };

  onInputChange = changes => {
    this.setState(changes);
  };

  afterConvertedComplete = updates => {
    this.setState({ convertedModalOpen: false, ...updates }, () =>
      this.saveProgress()
    );
  };

  render() {
    const opportunity = this.props.opportunity;

    return (
      <div
        key={opportunity.id}
        style={{
          width: '100%',
          backgroundColor: 'white',
          boxShadow: '2px 2px 5px -2px rgba(194,194,194,1)',
          padding: '25px',
          marginBottom: '15px'
        }}
      >
        {this.state.modalOpen && (
          <OpportunityNotConvertedModal
            onModalChangesComplete={this.onModalChangesComplete}
          />
        )}
        {this.state.convertedModalOpen && (
          <OpportunityConvertedModal
            opportunity={opportunity}
            afterComplete={this.afterConvertedComplete}
          />
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '0 20px',
            justifyContent: 'space-between',
            borderBottom: '1px solid lightgrey',
            paddingBottom: '20px'
          }}
        >
          <div style={{ paddingTop: '10px' }}>Status</div>
          <div style={{ width: '60%' }}>
            <InputSelect
              name={'status'}
              placeholder={find(StatusOptions, { value: this.state.status })}
              options={StatusOptions}
              value={find(StatusOptions, { value: this.state.status })}
              onChange={option => this.onChange({ status: option.value })}
              isDisabled={this.props.disabled}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '0 20px',
            justifyContent: 'space-between',
            borderBottom: '1px solid lightgrey',
            paddingBottom: '20px',
            paddingTop: '20px'
          }}
        >
          {/* using fake data here until this is built in */}
          <div>Channel</div>
          <img
            src={`/assets/admin/${opportunity.channel_logo}`}
            height="24px"
            width="auto"
            style={{ verticalAlign: 'middle', marginTop: 'auto' }}
            alt="opp-logo"
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '0 20px',
            justifyContent: 'space-between',
            borderBottom: '1px solid lightgrey',
            paddingBottom: '20px',
            paddingTop: '20px'
          }}
        >
          {/* using fake data here until this is built in */}
          <div style={{ paddingTop: '10px' }}>Team member</div>
          <div style={{ width: '60%' }}>
            <EmployeeSelect
              organizationID={this.props.organizationID}
              placeholder={get(
                opportunity,
                'assignee.name',
                'Choose team member'
              )}
              onChange={option => this.onChange({ assignee_id: option.value })}
              disabled={this.props.disabled}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '0 20px',
            justifyContent: 'space-between',
            borderBottom: '1px solid lightgrey',
            paddingBottom: '20px',
            paddingTop: '20px'
          }}
        >
          {/* using fake data here until this is built in */}
          <div style={{ paddingTop: '10px' }}>Stay dates</div>
          <div style={{ width: '60%' }}>
            <WidgetDatePicker
              organizationID={this.props.organizationID}
              unitID={this.state.unit_id}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              initialStartDate={this.state.startDate}
              initialEndDate={this.state.endDate}
              onDatesChange={this.onDatesChange}
              disabled={this.props.disabled}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '0 20px',
            justifyContent: 'space-between',
            paddingTop: '20px'
          }}
        >
          {/* using fake data here until this is built in */}
          <div style={{ paddingTop: '10px' }}>Guests</div>
          <div style={{ width: '60%' }}>
            <input
              type="number"
              name="num_guests"
              min="0"
              max={opportunity.num_sleep}
              placeholder={this.state.num_guests}
              value={this.state.num_guests}
              onChange={e => this.onChange({ num_guests: e.target.value })}
              disabled={this.props.disabled}
            />
          </div>
        </div>
      </div>
    );
  }
}
