import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  CallToActionButton,
  IconButton,
  IconFontAwesome,
  PageHeader
} from '@directsoftware/ui-kit-web-admin';
import { updateInvoices } from 'src/admin/redux/slices/invoices';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { ACCOUNTING_NAV_TAGS } from '../../../../../shared/nav';

const InvoicesDetails = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: ACCOUNTING_NAV_TAGS.PRIMARY,
        secondary: ACCOUNTING_NAV_TAGS.secondary.INVOICES
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/accounting/invoices',
            label: 'Invoices'
          },
          {
            label: 'Details'
          }
        ]}
      >
        Invoice: <span>{props.invoiceId}</span>
      </PageHeader.Left>
      <PageHeader.Right>
        <IconButton
          onClick={() => dispatch(updateInvoices({ startDeleteInvoice: true }))}
          variation="danger"
          appearance="ghost"
        >
          <IconFontAwesome name="trashAlt" />
        </IconButton>
        <CallToActionButton
          onClick={() => dispatch(updateInvoices({ startSaveInvoice: true }))}
          prependIcon={<IconFontAwesome name="pencilAlt" />}
          variation="secondary"
        >
          Edit
        </CallToActionButton>
      </PageHeader.Right>
    </PageHeader>
  );
};

export default InvoicesDetails;
