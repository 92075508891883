import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader, Toast } from '@directsoftware/ui-kit-web-admin';
import { ChannelMappingService } from 'adminApi';
import { DISTRIBUTION_NAV_TAGS } from '../../../../../shared/nav';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { selectChannelsUI } from '../../../../redux/slices/channels/channels';
import {
  selectUserDetails,
  updateUserDetails
} from '../../../../redux/slices/userDetails';
import RVshareOnboardingBanner from '../../../../pages/DashboardOrganization/molecules/rvshare-onboarding-banner';

const RvShareHome = props => {
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetails);
  const channelUI = useSelector(selectChannelsUI);
  const ToastRef = useRef();
  const [userLocalId, setUserLocalId] = useState(null);
  const [activeLocal, setActiveLocal] = useState(false);
  const [initialCheck, setInitialCheck] = useState(true);

  const saveData = () => {
    const channelData = {
      ...userDetails.rvshare.mapping_data,
      status: activeLocal,
      channel_data: {
        ...userDetails.rvshare.mapping_data.channel_data,
        user_id: userLocalId
      }
    };
    ChannelMappingService.update(
      props.organization.id,
      6,
      channelData.id,
      channelData
    )
      .then(response => {
        ToastRef.current.toastSuccess('RVshare Settings Updated');
        dispatch(
          updateUserDetails({
            rvshare: {
              ...userDetails.rvshare,
              external_id: userLocalId,
              active: activeLocal,
              mapping_data: channelData
            }
          })
        );
      })
      .catch(err => {
        ToastRef.current.toastError(
          'Could Not Update',
          'We were not able to update your RVshare settings. Please check you entered in the correct data'
        );
      });
  };

  useEffect(
    () => {
      if (initialCheck) {
        if (userDetails.rvshare?.external_id) {
          setUserLocalId(userDetails.rvshare.external_id);
          setActiveLocal(userDetails.rvshare.active ? 'active' : 'disabled');
          setInitialCheck(false);
        }
      }
    },
    [userDetails]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: DISTRIBUTION_NAV_TAGS.PRIMARY,
        secondary: DISTRIBUTION_NAV_TAGS.secondary.RVSHARE
      })
    );
  }, []);

  return (
    <>
      <Toast ref={ToastRef} />
      <PageHeader>
        <PageHeader.Left>
          Distribution: <span>RVshare</span>
        </PageHeader.Left>
        <PageHeader.Right>
          {userDetails.isRVshareUser &&
            userDetails.rvshare?.hasAttemptedSync && (
              <RVshareOnboardingBanner variation="button" label="Sync" />
            )}
        </PageHeader.Right>
      </PageHeader>
    </>
  );
};

export default RvShareHome;
