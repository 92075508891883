import request from "apiClient";
export default class NoteService {
  static create(organizationID, data) {
    return request({
      url: `/api/${organizationID}/notes`,
      method: "POST",
      data
    });
  }
  static index(organizationId, params) {
    return request({
      url: `/api/${organizationId}/notes`,
      method: "GET",
      params
    });
  }
}
