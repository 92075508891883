import React from 'react';
import {
  FlexBox,
  IconFontAwesome,
  TextBody,
  TextDense
} from '@directsoftware/ui-kit-web-admin';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const HoldingAccountBadge = ({ account, size }) => {
  const renderIcon = () => {
    switch (account) {
      case 'lynnbrook':
        return (
          <TextBody textColor="white" weight="bold">
            L
          </TextBody>
        );
      case 'stripe':
        return (
          <TextDense>
            <IconFontAwesome name="stripeS" presetColors="white" />
          </TextDense>
        );
      default:
        return null;
    }
  };

  return (
    <FlexBox
      className={classnames('holdingAccountBadge', account, {
        [`size-${size}`]: size
      })}
      justifyContent="center"
      alignItems="center"
    >
      {renderIcon()}
    </FlexBox>
  );
};

HoldingAccountBadge.propTypes = {
  account: PropTypes.oneOf(['stripe', 'lynnbrook']),
  size: PropTypes.oneOf(['m', 'l'])
};

HoldingAccountBadge.defaultProps = {
  size: 'm'
};

export default HoldingAccountBadge;
