import React from 'react';
import PropTypes from 'prop-types';
import { ajaxDataWithAuthorization } from 'apiClient';
import { CurrencySelect, InlineTip } from 'adminComponents';
import { dispatchError } from '../../../components/error-alert';
import ConsolidatedItemEditToggleHeader from '../../../components/consolidated-item-edit-toggle-header';
import { FlexBox, IconFontAwesome, Tooltip } from '@directsoftware/ui-kit-web-admin';

export default class ManageOrganizationFieldsBasic extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired
  };

  constructor(props, _railsContext) {
    super(props);

    this.state = {
      currency: this.props.organization.currency,
      currency_cache: this.props.organization.currency,
      description: this.props.organization.description,
      description_cache: this.props.organization.description,
      globalDateFormat: this.props.organization.global_date_format,
      globalDateFormatCache: this.props.organization.global_date_format,
      language: this.props.organization.language,
      language_cache: this.props.organization.language,
      name: this.props.organization.name,
      name_cache: this.props.organization.name,
      subdomain: this.props.organization.subdomain,
      default_brand_id: this.props.organization.brands.find(b => b.default)?.id,
      isDirty: false,
      isEditing: false,
      isSaving: false,
      isSaved: false
    };
  }

  onChange = e => {
    e.preventDefault();
    const stateChange = {};
    stateChange[e.target.name] = e.target.value;
    stateChange.isDirty = true;
    this.setState(stateChange);
  };

  onCurrencyChange = option => {
    this.setState({ currency: option.value, isDirty: true });
  };

  toggleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  finishEditingAndRevert = () => {
    const stateChange = {};
    stateChange.currency = this.state.currency_cache;
    stateChange.description = this.state.description_cache;
    stateChange.language = this.state.language_cache;
    stateChange.globalDateFormat = this.state.globalDateFormatCache;
    stateChange.name = this.state.name_cache;
    stateChange.isDirty = false;
    stateChange.isEditing = false;
    stateChange.isSaving = false;
    stateChange.isSaved = false;
    this.setState(stateChange);
  };

  finishEditingAndUpdate = () => {
    const stateChange = {};
    stateChange.currency_cache = this.state.currency;
    stateChange.description_cache = this.state.description;
    stateChange.language_cache = this.state.language;
    stateChange.globalDateFormatCache = this.state.globalDateFormat;
    stateChange.name_cache = this.state.name;
    stateChange.isDirty = false;
    stateChange.isEditing = false;
    stateChange.isSaving = false;
    stateChange.isSaved = true;
    this.setState(stateChange);
  };

  attemptSave = () => {
    this.setState(
      {
        isSaving: true,
        isSaved: false
      },
      () => {
        $.ajax(
          ajaxDataWithAuthorization({
            type: 'PATCH',
            url: `/api/organizations/${this.props.organization.id}`,
            context: this,
            dataType: 'json',
            data: {
              currency: this.state.currency,
              description: this.state.description,
              language: this.state.language,
              global_date_format: this.state.globalDateFormat,
              name: this.state.name,
              default_brand_id: this.state.default_brand_id
            }
          })
        )
          .done(function (data) {
            this.finishEditingAndUpdate();
          })
          .fail(function (jqXhr) {
            dispatchError();
            console.warn(jqXhr);
            this.finishEditingAndRevert();
          });
      }
    );
  };

  renderEditing() {
    const languageOptions = [
      { value: 'en', label: 'English' },
      { value: 'ar', label: 'العربية' },
      { value: 'bn', label: 'বাংলা' },
      { value: 'zh', label: '中文 (Zhōngwén), 汉语, 漢語' },
      { value: 'cs', label: 'Česky, Čeština' },
      { value: 'da', label: 'Dansk' },
      { value: 'nl', label: 'Nederlands, Vlaams' },
      { value: 'et', label: 'Eesti, Eesti keel' },
      { value: 'fr', label: 'Français' },
      { value: 'de', label: 'Deutsch' },
      { value: 'el', label: 'Ελληνικά' },
      { value: 'hi', label: 'हिन्दी, हिंदी' },
      { value: 'hu', label: 'Magyar' },
      { value: 'id', label: 'Bahasa Indonesia' },
      { value: 'it', label: 'Italiano' },
      { value: 'ja', label: '日本語 (にほんご／にっぽんご)' },
      { value: 'jv', label: 'basa Jawa' },
      { value: 'ko', label: '한국어 (韓國語), 조선말 (朝鮮語)' },
      { value: 'nb', label: 'Norsk bokmål' },
      { value: 'pa', label: 'ਪੰਜਾਬੀ, پنجابی‎' },
      { value: 'pl', label: 'polski' },
      { value: 'pt', label: 'Português' },
      { value: 'ru', label: 'Pусский язык' },
      { value: 'es', label: 'Español, Castellano' },
      { value: 'sv', label: 'svenska' },
      { value: 'th', label: 'ไทย' },
      { value: 'tr', label: 'Türkçe' },
      { value: 'vi', label: 'Tiếng Việt' }
    ];

    const dateDisplayOptions = [
      { value: 'DMY', label: 'DD/MM/YYYY' },
      { value: 'MDY', label: 'MM/DD/YYYY' },
      { value: 'YMD', label: 'YYYY/MM/DD' },
      { value: 'YDM', label: 'YYYY/DD/MM' }
    ];
    return (
      <form className="consolidated-item-form-editing">
        <div className="consolidated-item-subsection">
          <label>Organization Name</label>
          <input
            className="magnified"
            type="text"
            name="name"
            onChange={this.onChange}
            value={this.state.name}
          />
        </div>
        <div className="consolidated-item-subsection">
          <h4>Organization domain</h4>
          <small>This setting cannot be changed.</small>
          <div>{this.state.subdomain}</div>
        </div>
        <div className="consolidated-item-subsection">
          <FlexBox gap='xs' alignItems='center'>
            <h4>Default Brand</h4>
            <div style={{ paddingTop: 12 }}>
              <Tooltip
                tipContent={"Reservations from channels will book on this brand if no default listing is set on the listing level."}
                theme="light"
              >
                <IconFontAwesome name="infoCircle" color="#067bc2" />
              </Tooltip>
            </div>
          </FlexBox>
          <div className="select-wrapper magnified">
            <select
              className="magnified"
              name="default_brand_id"
              onChange={this.onChange}
              value={this.state.default_brand_id}
            >
              <option style={{ display: "none" }} disabled selected value />
              {this.props.organization.brands.map(brand => (
                <option value={brand.id} key={brand.id}>
                  {brand.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <section className="consolidated-item-subsection three-col three-col-33-33-33">
          <div>
            <label>Language</label>
            <div className="select-wrapper magnified">
              <select
                className="magnified"
                name="language"
                onChange={this.onChange}
                value={this.state.language}
              >
                {languageOptions.map(type => (
                  <option value={type.value} key={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <CurrencySelect
            currency={this.state.currency}
            onChange={this.onCurrencyChange}
          />

          <div>
            <label>Date Format</label>
            <div className="select-wrapper magnified">
              <select
                className="magnified"
                name="globalDateFormat"
                onChange={this.onChange}
                value={this.state.globalDateFormat}
              >
                {dateDisplayOptions.map(type => (
                  <option value={type.value} key={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </section>
        <div className="consolidated-item-subsection">
          <label>Description</label>
          <textarea
            className="magnified"
            name="description"
            onChange={this.onChange}
            rows="8"
            value={this.state.description}
          />
        </div>
      </form>
    );
  }

  renderViewing() {
    return (
      <section className="consolidated-item-form-viewing">
        <div className="consolidated-item-subsection">
          <h4>Organization Name</h4>
          {this.state.name}
        </div>
        <div className="consolidated-item-subsection">
          <h4>Organization domain</h4>
          {this.state.subdomain}
        </div>
        <div className="consolidated-item-subsection">
          <FlexBox gap='xs' alignItems='center'>
            <h4>Default Brand</h4>
            <div style={{ paddingTop: 12 }}>
              <Tooltip
                tipContent={"Reservations from channels will book on this brand if no default listing is set on the listing level."}
                theme="light"
              >
                <IconFontAwesome name="infoCircle" color="#067bc2" />
              </Tooltip>
            </div>
          </FlexBox>
          {this.props.organization.brands.find(b => b.id === parseInt(this.state.default_brand_id))?.name}
        </div>
        <section className="consolidated-item-subsection three-col three-col-33-33-33">
          <div>
            <h4>Language</h4>
            <span className="language-meta">{this.state.language}</span>
          </div>
          <div>
            <h4>Currency</h4>
            <span className="currency-meta">{this.state.currency}</span>
          </div>
          <div>
            <h4>Date Display Format</h4>
            <span className="currency-meta">{this.state.globalDateFormat}</span>
          </div>
        </section>
        <div className="consolidated-item-subsection">
          <h4>Description</h4>
          {this.state.description}
        </div>
      </section>
    );
  }

  render() {
    return (
      <figure
        className={`consolidated-item-form-section ${this.state.isEditing ? 'form-editing' : ''
          }`}
      >
        <ConsolidatedItemEditToggleHeader
          toggleEditing={this.toggleEditing}
          finishEditingAndRevert={this.finishEditingAndRevert}
          finishEditingAndUpdate={this.finishEditingAndUpdate}
          attemptSave={this.attemptSave}
          isDirty={this.state.isDirty}
          isEditing={this.state.isEditing}
          isSaving={this.state.isSaving}
          title="Organization Settings"
        />
        {this.state.isEditing ? this.renderEditing() : this.renderViewing()}
      </figure>
    );
  }
}
