import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const AirbnbAutoEmailsLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags
}) => {
  return (
    <Navbar.Link
      href="/messaging/airbnb-custom-auto-messages"
      variation="secondary"
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={
        activeRoutes.secondary === navTags.secondary.AIRBNB_AUTO_MESSAGES
      }
    >
      Airbnb Auto Emails
    </Navbar.Link>
  );
};

AirbnbAutoEmailsLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object
};

export default AirbnbAutoEmailsLink;
