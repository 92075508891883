import sum from 'lodash/sum';
import map from 'lodash/map';
import find from 'lodash/find';
import CurrencyDefinitions from './currency-definitions';

const formatCents = cents => {
  const safeCents = typeof cents === 'string' ? parseFloat(cents) : cents;

  return (safeCents / 100.0).toFixed(2);
};

const dollarsToCents = dollars => {
  const safeDollars =
    typeof dollars === 'string' ? parseFloat(dollars) : dollars;
  return safeDollars * 100;
};

const centsFloatToDecimalString = cents => {
  return (cents / 100.0).toFixed(2);
};

const grossPayoutAmount = payouts => {
  return centsFloatToDecimalString(
    sum(map(payouts, payout => payout.total_cents))
  );
};

const grossMgmtFeeAmount = payouts => {
  return centsFloatToDecimalString(
    sum(map(payouts, payout => payout.total_fees_cents))
  );
};

const floatStringToFixed = dollarValue => {
  return Number(dollarValue).toFixed(2);
};

const symbolFromCurrencyString = currency => {
  return find(CurrencyDefinitions, def => def.value === currency).symbol_native;
};

const addCommas = numString => numString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const formatCurrency = (value, currencyCode, locale) => {
  const formatter = new Intl.NumberFormat(locale || undefined, {
    style: 'currency',
    currency: currencyCode || 'USD'
  });

  return formatter.format(value);
};

const sanitizeMoney = value => {
  let sanitizedVal = parseFloat(value);
  if (!isNaN(sanitizedVal)) {
    if (sanitizedVal < 0) {
      sanitizedVal = 0;
    } else {
      sanitizedVal = sanitizedVal.toFixed(2);
    }
    return sanitizedVal;
  }
};

export {
  formatCents,
  grossMgmtFeeAmount,
  centsFloatToDecimalString,
  grossPayoutAmount,
  dollarsToCents,
  floatStringToFixed,
  symbolFromCurrencyString,
  addCommas,
  formatCurrency,
  sanitizeMoney
};
