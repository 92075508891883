import request from "apiClient";
export default class BathroomService {
  static massUpdate(newBathrooms, oldBathrooms) {
    oldBathrooms.map((ob) => {
      if (!newBathrooms.find((nb) => nb.id === ob.id)) {
        request({
          url: `/api/${ob.organization_id}/units/${ob.unit_id}/bathrooms/${ob.id}`,
          method: "DELETE"
        });
      }
    });
    newBathrooms.map((nb) => {
      const oldBathroom = oldBathrooms.find((ob) => ob.id === nb.id);
      if (oldBathroom) {
        if (nb.bathroom_type !== oldBathroom.bathroom_type || compareAmenities(nb, oldBathroom)) {
          request({
            url: `/api/${nb.organization_id}/units/${nb.unit_id}/bathrooms/${nb.id}`,
            method: "PATCH",
            data: {
              bathroom_type: nb.bathroom_type,
              amenities: JSON.stringify(nb.amenities)
            }
          });
        }
      } else {
        request({
          url: `/api/${nb.organization_id}/units/${nb.unit_id}/bathrooms`,
          method: "POST",
          data: {
            bathroom_type: nb.bathroom_type,
            amenities: JSON.stringify(nb.amenities)
          }
        });
      }
    });
  }
}
function compareAmenities(newBathroom, oldBathroom) {
  let change = false;
  Object.keys(newBathroom.amenities).map((a) => {
    if (newBathroom.amenities[a].value !== oldBathroom.amenities[a].value) {
      return change = true;
    }
  });
  return change;
}
