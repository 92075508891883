import React from 'react';
import { toast } from 'react-toastify';
import { BillService } from 'adminApi';
import moment from 'moment';
import {
  CallToActionButton,
  ConfirmDialog,
  IconFontAwesome,
  SlideoutPanel,
  StandByOverlay
} from '@directsoftware/ui-kit-web-admin';
import CreateBillForm from './CreateBillForm';

const OpenButton = props => (
  <CallToActionButton
    onClick={props.openPortal}
    prependIcon={
      <IconFontAwesome
        name={props.isRefreshing ? 'spinner' : 'fileInvoice'}
        spin={props.isRefreshing}
      />
    }
  >
    Record Bill
  </CallToActionButton>
);

class CreateBillModal extends React.Component {
  state = {
    newBill: {
      pay_to: '',
      payee_name: '',
      amount: '',
      post_date: moment(),
      due_date: moment(),
      invoice_num: '',
      memo: '',
      debit_account_id: '',
      credit_account_id: '',
      expenses: []
    },
    loading: false,
    expense: {},
    reveal: false,
    confirmDialog: false
  };

  defaultState = {
    pay_to: '',
    payee_name: '',
    amount: '',
    post_date: moment(),
    due_date: moment(),
    invoice_num: '',
    memo: '',
    debit_account_id: '',
    credit_account_id: '',
    expenses: []
  };

  openModal = () => {
    this.setState({
      reveal: true
    });
  };

  closeModal = () => {
    this.setState({
      newBill: { ...this.defaultState },
      reveal: false
    });
  };

  showConfirmDialog = () => {
    this.setState({
      confirmDialog: true
    });
  };

  hideConfirmDialog = () => {
    this.setState({
      confirmDialog: false
    });
  };

  onSubmit = close => {
    this.setState({ loading: true });
    BillService.create(this.props.organization.id, this.state.newBill)
      .then(() => {
        this.resetState();
        this.props.loadBillsAfterCreate();
        close();
      })
      .catch(err => {
        this.resetState();
        this.props.billCreateError(err);
        close();
      });
  };

  resetState = () =>
    this.setState({ newBill: this.defaultState, loading: false });

  onChange = (name, value) => {
    this.setState({
      newBill: {
        ...this.state.newBill,
        [name]: value
      }
    });
  };

  setExpenses = () => {
    const newExpenses = [...this.state.newBill.expenses, this.state.expense];
    this.setState({
      newBill: {
        ...this.state.newBill,
        expenses: newExpenses
      }
    });
  };

  addExpense = expense => {
    this.setState({ expense }, () => this.setExpenses());
  };

  deleteExpense = (expense, index) => {
    if (this.state.newBill.expenses.length <= 1) {
      this.setState({
        newBill: {
          ...this.state.newBill,
          expenses: []
        }
      });
    } else {
      const updatedExpenses = this.state.newBill.expenses.splice(index, 1);
      this.setState({
        newBill: {
          ...this.state.newBill,
          expenses: updatedExpenses
        }
      });
    }
  };

  formInvalid = () => {
    // raise notification that required forms not filled out
    toast.error('Required fields not filled out');
  };

  render() {
    const { loading, newBill } = this.state;
    return (
      <>
        <OpenButton
          openPortal={this.openModal}
          isRefreshing={this.props.isRefreshing}
        />
        <SlideoutPanel
          closeButtonOnClick={this.closeModal}
          title="New Bill"
          closeOnClickOutside={false}
          reveal={this.state.reveal}
          secondaryActionProps={{
            action: this.closeModal,
            actionType: 'button',
            label: 'Cancel'
          }}
          primaryActionProps={{
            action: e =>
              newBill.expenses.length > 0
                ? this.onSubmit(this.closeModal)
                : this.showConfirmDialog(),
            actionType: 'button',
            label: 'Create'
          }}
          removeContentTopPadding
        >
          <StandByOverlay reveal={loading} position="absolute" />
          <CreateBillForm
            organization={this.props.organization}
            onChange={this.onChange}
            newBill={newBill}
            currency={this.props.currency}
            addExpense={this.addExpense}
            deleteExpense={this.deleteExpense}
          />
        </SlideoutPanel>
        <ConfirmDialog
          reveal={this.state.confirmDialog}
          description={`It appears you have not entered details for an expense, if you
            wish to add this expense, enter details and click the blue
            “Add” button.`}
          confirmLabel="Create anyways?"
          cancelLabel="Cancel"
          cancelOnClick={() => {
            this.hideConfirmDialog();
          }}
          confirmOnClick={() => {
            this.hideConfirmDialog();
            this.onSubmit(this.closeModal);
          }}
          closeOnClick={() => {
            this.hideConfirmDialog();
          }}
        />
      </>
    );
  }
}
export default CreateBillModal;
