import request from "apiClient";
import isNull from "lodash/isNull";
export default class SingleUnitPropertyService {
  static list(organizationId, query) {
    return request({
      url: `/api/${organizationId}/units?name=${query}`,
      method: "GET"
    });
  }
  static update(organizationId, unitID, params = {}) {
    return request({
      url: `/api/${organizationId}/units/${unitID}`,
      method: "PATCH",
      data: params
    });
  }
  static getUnitInfo(organizationId, unitId) {
    return request({
      url: `/api/${organizationId}/units/${unitId}/unit_info`,
      method: "GET"
    });
  }
  static getUnitDetailsInfo(organizationId, unitId) {
    return request({
      url: `/api/${organizationId}/units/${unitId}/unit_details_info`,
      method: "GET"
    });
  }
  static getUnitAvailability(organizationId, unitId) {
    return request({
      url: `/api/${organizationId}/units/${unitId}/unit_availability_calendar`,
      method: "GET"
    });
  }
  static updateUnitInternetOptions(organizationId, unitId, updates) {
    return request({
      url: `/api/${organizationId}/units/${unitId}/update_internet_options`,
      method: "PATCH",
      data: updates
    });
  }
  static fetchChanges(organizationId, unitId, filter = null) {
    return request({
      url: `/api/${organizationId}/units/${unitId}/history${isNull(filter) ? "" : `?filter=${filter}`}`,
      method: "GET"
    });
  }
  static importICal(organizationId, unitId, iCalUrl, unitAvailabilityId) {
    return request({
      url: `/api/${organizationId}/units/${unitId}/import?url=${iCalUrl}&availability_id=${unitAvailabilityId}`,
      method: "POST"
    });
  }
}
