import React from 'react';
import { Link } from 'react-router-dom';

import { CustomerContext } from 'adminContexts';

const OpenCustomerMessageDetail = props => {
  const { customer, styles, displayName, activeConversation } = props;
  if (!customer) return null;
  return (
    <CustomerContext.Consumer>
      {state => (
        <Link
          to="#"
          onClick={() => {
            state.enterMessageDetailView(customer, activeConversation);
            props.updateToRead && props.updateToRead(activeConversation);
          }}
          style={styles}
        >
          {displayName || customer.name}
        </Link>
      )}
    </CustomerContext.Consumer>
  );
};

export default OpenCustomerMessageDetail;
