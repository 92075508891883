import React, { useEffect, useState } from 'react';
import {
  CallToActionButton,
  IconButton,
  IconFontAwesome,
  PageHeader
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import CreateReservationModal from 'src/admin/components/CreateReservationModal';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { RESERVATIONS_NAV_TAGS } from '../../../../../shared/nav';
import { useDetectMobile } from '../../../../../shared/hooks';
import { updateReservations } from '../../../../redux/slices/reservations';

const ReservationsCalendar = props => {
  const { isMobile } = useDetectMobile();
  const dispatch = useDispatch();
  const [openCreateReservation, setOpenCreateReservation] = useState(false);

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: RESERVATIONS_NAV_TAGS.PRIMARY,
        secondary: RESERVATIONS_NAV_TAGS.secondary.CALENDAR_VIEW
      })
    );
  }, []);

  return (
    <PageHeader containerVariation="fluid">
      <PageHeader.Left>
        Reservations: <span>Calendar</span>
      </PageHeader.Left>
      <PageHeader.Right>
        {isMobile ? (
          <>
            <IconButton
              onClick={() => {
                dispatch(updateReservations({ showCalendarOptions: true }));
              }}
              variation="secondary"
              appearance="ghost"
              size="dense"
            >
              <IconFontAwesome name="cog" />
            </IconButton>
            <IconButton
              onClick={() => setOpenCreateReservation(true)}
              size="dense"
            >
              <IconFontAwesome name="plus" />
            </IconButton>
          </>
        ) : (
          <CallToActionButton
            onClick={() => setOpenCreateReservation(true)}
            prependIcon={<IconFontAwesome name="plus" />}
          >
            Create
          </CallToActionButton>
        )}
        <CreateReservationModal
          reveal={openCreateReservation}
          closeButton={() => setOpenCreateReservation(false)}
          brands={props.brands}
          channels={props.channels}
          organization={props.organization}
          user={props.user}
        />
      </PageHeader.Right>
    </PageHeader>
  );
};

export default ReservationsCalendar;
