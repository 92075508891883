import React from 'react';

import SingleDetailsHeader from './SingleDetailsHeader';
import SingleDetailsInfo from './SingleDetailsInfo';

import OpportunityService from 'adminApi/OpportunityService';
import get from 'lodash/get';
import displayError from 'sharedComponents/ErrorDisplay';

export default class ManageOpportunity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opportunity: this.props.opportunity
    };

    this.organizationID = this.props.organizationID;
  }

  updateOpportunity = updates => {
    OpportunityService.update(
      this.organizationID,
      this.state.opportunity.id,
      updates
    )
      .then(response => {
        this.setState({ opportunity: response.opportunity });
      })
      .catch(err =>
        displayError({ message: 'Error updating opportunity', err })
      );
  };

  render() {
    return (
      <React.Fragment>
        <SingleDetailsHeader
          opportunity={this.state.opportunity}
          loadOpportunities={this.props.loadOpportunities}
        />
        <SingleDetailsInfo
          opportunity={this.state.opportunity}
          updateOpportunity={this.updateOpportunity}
          organizationID={this.organizationID}
          disabled={get(this, 'props.opportunity.status') === 'converted'}
        />
      </React.Fragment>
    );
  }
}
