// Dependencies
// -----------------------------------------------
import React from 'react';
import moment from 'moment';

// -----------------------------------------------
// COMPONENT->FORMATTED-DATE ---------------------
// -----------------------------------------------
const FormattedDate = props => {
  const date = moment(props.date);
  const now = moment();

  // ---------------------------------------------
  // RETURN --------------------------------------
  // ---------------------------------------------
  return (
    <p className={props.className}>
      {date.dayOfYear() == now.dayOfYear()
        ? date.format('h:mma')
        : date.format(props.format === 'DD/MM/YYYY' ? 'D/M/YY' : 'M/D/YY')}
    </p>
  );
};

// ---------------------------------------------
// EXPORT --------------------------------------
// ---------------------------------------------
export default FormattedDate;
