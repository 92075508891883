const { host } = window.location;
export const IS_STAGING = host.indexOf("staging.getdirect.io") >= 0 || host.indexOf("database-transition.herokuapp.com") >= 0;
export const IS_PRODUCTION = host.indexOf("app.getdirect.io") >= 0;
export const IS_DEVELOPMENT = host.indexOf("lvh.me") >= 0;
export const getEnvironment = () => {
  if (IS_DEVELOPMENT)
    return "development";
  if (IS_STAGING)
    return "staging";
  return "production";
};
