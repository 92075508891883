// Dependencies
// -----------------------------------------------
import React from 'react';
import displayError from 'sharedComponents/ErrorDisplay';
import throttle from 'lodash/throttle';
import { toast } from 'react-toastify';

// Components
// -----------------------------------------------
import { BedroomService } from 'adminApi';
import { Spinner } from 'adminComponents';
import { parseIntWithRadix10 } from 'sharedUtils';
import {
  Label,
  InputNumber,
  InputIncrementer
} from '@directsoftware/ui-kit-web-admin';
import PermissionComponent from 'PermissionComponent';
import { IndividualBedroomFields } from '../atoms';

// -----------------------------------------------
// COMPONENT->OVERVIEW-SINGLE-FIELDS-BEDROOMS ----
// -----------------------------------------------
export default class OverviewSingleFieldsBedrooms extends React.Component {
  state = {
    bedrooms: null,
    living_rooms: null,
    num_living_rooms: this.props.num_living_rooms,
    loading: false
  };

  componentDidMount() {
    const p = this.fetchBedrooms();
    p.then(res => {
      this.setState({
        bedrooms: res.bedrooms,
        living_rooms: res.living_rooms,
        num_living_rooms: res.living_rooms.length
      });
    }).catch(err => {
      console.error(err);
    });
  }

  fromUnit = () => window.location.pathname.match('/properties');

  onSleepInBedsChange = e => {
    let sumOfBedsInEachRoom = 0;
    const { bedrooms, living_rooms } = this.state;
    [...bedrooms, ...living_rooms].forEach(
      room =>
        (sumOfBedsInEachRoom += Object.entries(room.amenities).reduce(
          (sum, amenity) => sum + amenity[1].value,
          0
        ))
    );
    if (parseIntWithRadix10(e.target.value) < sumOfBedsInEachRoom) {
      toast.warn(
        'The number of guests that can sleep in beds in this property must be more than the total number of beds in each room'
      );
      this.props.onSimpleChange(
        'num_sleep_in_beds',
        this.props.num_sleep_in_beds
      );
    } else {
      this.props.onSimpleChange('num_sleep_in_beds', e.target.value);
    }
  };

  onLivingRoomsIncrement = value => {
    this.props.onSimpleChange('num_living_rooms', value);
    this.setState({ loading: true });
    const p = this.addLivingRoom();
    p.then(_data => {
      const p2 = this.fetchBedrooms();
      p2.then(res => {
        this.setState({
          bedrooms: res.bedrooms,
          living_rooms: res.living_rooms,
          num_living_rooms: res.living_rooms.length,
          loading: false
        });
      });
    }).catch(err => displayError({ message: 'Error adding bedroom', err }));
  };

  onLivingRoomsDecrement = value => {
    const { living_rooms } = this.state;
    this.props.onSimpleChange('num_living_rooms', value);
    this.setState({ loading: true });
    const p = this.removeBedroom(living_rooms[living_rooms.length - 1].id);
    p.then(_data => {
      const p2 = this.fetchBedrooms();
      p2.then(res => {
        this.setState({
          bedrooms: res.bedrooms,
          living_rooms: res.living_rooms,
          num_living_rooms: res.living_rooms.length,
          loading: false
        });
      }).catch(err => {
        console.error(err);
      });
    }).catch(err => displayError({ message: 'Error removing bedroom', err }));
  };

  onBedroomsIncrement = value => {
    this.props.onSimpleChange('num_bedrooms', value);
    this.setState({ loading: true });
    const p = this.addBedroom();
    p.then(_data => {
      const p2 = this.fetchBedrooms();
      p2.then(res => {
        this.setState({
          bedrooms: res.bedrooms,
          living_rooms: res.living_rooms,
          loading: false
        });
      });
    }).catch(err => displayError({ message: 'Error adding bedroom', err }));
  };

  onBedroomsDecrement = value => {
    const { bedrooms } = this.state;
    this.props.onSimpleChange('num_bedrooms', value);
    this.setState({ loading: true });
    const p = this.removeBedroom(bedrooms[bedrooms.length - 1].id);
    p.then(_data => {
      const p2 = this.fetchBedrooms();
      p2.then(res => {
        this.setState({
          bedrooms: res.bedrooms,
          living_rooms: res.living_rooms,
          loading: false
        });
      }).catch(err => {
        console.error(err);
      });
    }).catch(err => displayError({ message: 'Error removing bedroom', err }));
  };

  fetchBedrooms = () => {
    return BedroomService.listBedrooms({
      orgId: this.props.organization.id,
      vehicleId: this.props.vehicle?.id,
      unitId: this.props.unit?.id,
      fromUnit: this.fromUnit()
    });
  };

  addBedroom = () => {
    return BedroomService.createBedroom({
      orgId: this.props.organization.id,
      vehicleId: this.props.vehicle?.id,
      unitId: this.props.unit?.id,
      fromUnit: this.fromUnit(),
      data: {
        bedroom_type: 'bedroom'
      }
    });
  };

  addLivingRoom = () => {
    return BedroomService.createBedroom({
      orgId: this.props.organization.id,
      vehicleId: this.props.vehicle?.id,
      unitId: this.props.unit?.id,
      fromUnit: this.fromUnit(),
      data: {
        bedroom_type: 'living_sleeping'
      }
    });
  };

  removeBedroom = bedroomId => {
    return BedroomService.removeBedroom({
      orgId: this.props.organization.id,
      unitId: this.props.unit?.id,
      vehicleId: this.props.vehicle?.id,
      fromUnit: this.fromUnit(),
      bedroomId
    });
  };

  aggregateTotalOfBeds = idToExclude => {
    const { bedrooms, living_rooms } = this.state;
    const rooms = [...bedrooms, ...living_rooms];
    const roomsToSum = rooms.filter(room => room.id !== idToExclude);
    let sumOfBeds = 0;
    roomsToSum.forEach(room => {
      sumOfBeds += Object.entries(room.amenities).reduce(
        (sum, amenity) => sum + amenity[1].value,
        0
      );
    });
    return sumOfBeds;
  };

  // ---------------------------------------------
  // RENDER --------------------------------------
  // ---------------------------------------------
  render() {
    return (
      <fieldset>
        <PermissionComponent
          user={this.props.user}
          permission="view_properties"
          restriction="properties_read_only"
        >
          <Label htmlFor="num_bedrooms">
            How many bedrooms are available to guests?
          </Label>
          {this.state.loading ? (
            <Spinner />
          ) : (
            <InputIncrementer
              onChange={() => {}}
              min={0}
              max={24}
              value={this.props.num_bedrooms}
              onIncrement={number => {
                throttle(this.onBedroomsIncrement(number), 1000);
              }}
              onDecrement={number => {
                throttle(this.onBedroomsDecrement(number), 1000);
              }}
              inputWidth="m"
            />
          )}

          <Label htmlFor="num_sleep_in_beds">
            How many guests can sleep here <b>in beds</b>?
          </Label>
          <InputNumber
            name="num_sleep_in_beds"
            value={this.props.num_sleep_in_beds}
            onChange={number => this.onSleepInBedsChange(number)}
            inputWidth="m"
          />
          <Label htmlFor="num_living_rooms">
            How many livings rooms does it have?
          </Label>
          <InputIncrementer
            onChange={() => {}}
            min={0}
            max={24}
            value={this.state.num_living_rooms}
            onIncrement={number => {
              this.onLivingRoomsIncrement(number);
            }}
            onDecrement={number => {
              this.onLivingRoomsDecrement(number);
            }}
            inputWidth="m"
          />
        </PermissionComponent>

        {this.state.living_rooms && this.state.living_rooms.length > 0 && (
          <div>
            {this.state.living_rooms.map((living_room, i) => (
              <IndividualBedroomFields
                key={living_room.id}
                index={i}
                bedroom={living_room}
                unit={this.props.unit}
                organization={this.props.organization}
                totalNumBeds={this.aggregateTotalOfBeds(living_room.id)} // needs to include the count of individual beds in bedrooms minus this one ? and if the count of bedrooms in this room will exceed that, form is invalidate
                numSleep={this.props.num_sleep_in_beds}
                vehicle={this.props.vehicle}
                fromUnit={this.fromUnit()}
                user={this.props.user}
              />
            ))}
          </div>
        )}
        {this.state.bedrooms && this.state.bedrooms.length > 0 && (
          <div>
            {this.state.bedrooms.map((bedroom, i) => (
              <IndividualBedroomFields
                key={bedroom.id}
                index={i}
                bedroom={bedroom}
                unit={this.props.unit}
                organization={this.props.organization}
                totalNumBeds={this.aggregateTotalOfBeds(bedroom.id)}
                numSleep={this.props.num_sleep_in_beds}
                vehicle={this.props.vehicle}
                fromUnit={this.fromUnit()}
                user={this.props.user}
              />
            ))}
          </div>
        )}
      </fieldset>
    );
  }
}
