import React from 'react';
import { withRouter } from 'react-router-dom';
import { CustomerContext } from 'adminContexts';
import MasqueradingOverlay from 'adminComponents/masquerading-overlay';
import { useSelector } from 'react-redux';
import NavbarPageHeader from './navbar-page-header';
import Navbar from './navbar/navbar';
import { selectNavigationState } from '../../redux/slices/navigation';
import { selectUserDetails } from '../../redux/slices/userDetails';
import RVshareOnboardingBanner from '../../pages/DashboardOrganization/molecules/rvshare-onboarding-banner';

const NavContainer = props => {
  const userDetails = useSelector(selectUserDetails);
  const navState = useSelector(selectNavigationState);
  const path = props.location?.pathname;
  const removeNavigation =
    path?.includes('brands') && path?.includes('preview-v2');

  if (removeNavigation) return null;

  return (
    <CustomerContext.Consumer>
      {state => (
        <>
          {!navState.hideNavbar && (
            <Navbar
              linkCollection={
                props.organization.organization_type === 'rv_fleet'
                  ? 'rv-fleet'
                  : 'admin'
              }
              {...props}
              openCustomerSidebar={customer => {
                state.setCustomer(customer);
              }}
            />
          )}
          <MasqueradingOverlay {...props} />
          {userDetails.isRVshareUser &&
            !userDetails.rvshare.hasAttemptedSync && (
              <RVshareOnboardingBanner />
            )}
          {!navState.hidePageHeader && <NavbarPageHeader {...props} />}
        </>
      )}
    </CustomerContext.Consumer>
  );
};

export default withRouter(NavContainer);
