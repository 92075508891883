import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navbar, VisibilityHelper } from '@directsoftware/ui-kit-web-admin';

const VehiclesBulkEditLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags,
  organization,
  ...props
}) => {
  const [showVehicleLink, setShowVehicleLink] = useState(false);

  useEffect(() => {
    setShowVehicleLink(organization.organization_type === 'rv_fleet');
  }, []);

  return showVehicleLink ? (
    <Navbar.Link
      href="/bulk-edit/vehicles"
      variation="secondary"
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={activeRoutes.secondary === navTags.secondary.VEHICLES_BULK_EDIT}
    >
      <VisibilityHelper showStartingFrom="large-desktop">
        Bulk Edit
      </VisibilityHelper>
      <VisibilityHelper hideStartingFrom="large-desktop">
        Vehicles
      </VisibilityHelper>
    </Navbar.Link>
  ) : (
    <></>
  );
};

VehiclesBulkEditLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object
};

export default VehiclesBulkEditLink;
