function encodeAxiosParam(value) {
  let v = value;
  if (toString.call(value) === "[object Date]") {
    v = value.toISOString();
  } else if (typeof value === "object") {
    v = JSON.stringify(value);
  }
  return encodeURIComponent(v);
}
function paramsSerializer(params) {
  return Object.entries(params).filter(([, value]) => value !== void 0 && value !== null && (Array.isArray(value) ? value.length > 0 : true)).map(([key, value]) => {
    if (Array.isArray(value)) {
      return value.map((v) => `${key}[]=${encodeAxiosParam(v)}`).join("&");
    }
    return `${key}=${encodeAxiosParam(value)}`;
  }).join("&");
}
export default paramsSerializer;
