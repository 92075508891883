import request from "apiClient";
export default class ChartOfAccountsService {
  static histories(organizationId, { params }) {
    return request({
      url: `/api/${organizationId}/chart-of-accounts/history`,
      method: "GET",
      params
    });
  }
}
