import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CallToActionButton,
  IconButton,
  IconFontAwesome,
  PageHeader,
  VisibilityHelper
} from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { PEOPLE_NAV_TAGS } from '../../../../../shared/nav';
import UserService from '../../../../api/UserService';
import { updatePeopleUi } from '../../../../redux/slices/people';

const EmployeeEdit = props => {
  const dispatch = useDispatch();
  const [employeeName, setEmployeeName] = useState('');
  const [isAddUnitsPage, setIsAddUnitsPage] = useState(false);

  useEffect(
    () => {
      setIsAddUnitsPage(props.location.pathname.includes('/units'));
      UserService.get(props.organization.id, props.employeeId).then(
        employee => {
          setEmployeeName(employee.name);
        }
      );
    },
    [props]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: PEOPLE_NAV_TAGS.PRIMARY,
        secondary: PEOPLE_NAV_TAGS.secondary.EMPLOYEES
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/employees',
            label: 'Users'
          },
          {
            label: 'Edit Details'
          }
        ]}
      >
        {employeeName}
      </PageHeader.Left>
      {isAddUnitsPage && (
        <PageHeader.Right>
          <VisibilityHelper showStartingFrom="tablet">
            <CallToActionButton
              onClick={() =>
                dispatch(updatePeopleUi({ openAddUnitsModal: true }))
              }
              prependIcon={<IconFontAwesome name="plus" />}
            >
              Add Units
            </CallToActionButton>
          </VisibilityHelper>
          <VisibilityHelper hideStartingFrom="tablet">
            <IconButton
              onClick={() =>
                dispatch(updatePeopleUi({ openAddUnitsModal: true }))
              }
            >
              <IconFontAwesome name="plus" />
            </IconButton>
          </VisibilityHelper>
        </PageHeader.Right>
      )}
    </PageHeader>
  );
};

export default EmployeeEdit;
