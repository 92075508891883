import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const InboxLink = ({ activeRoutes, closeMobileNav, navTags, ...props }) => {
  const { organization } = props;

  return (
    <Navbar.Link
      href={`/dashboard/${organization.subdomain}/inbox`}
      isActive={activeRoutes.primary === navTags.PRIMARY}
      linkType="link"
    >
      Inbox
    </Navbar.Link>
  );
};

InboxLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object
};

export default InboxLink;
