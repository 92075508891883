// Dependencies
// -----------------------------------------------
import React from 'react';
import split from 'lodash/split';
import map from 'lodash/map';
import last from 'lodash/last';
import replace from 'lodash/replace';
import join from 'lodash/join';

// -----------------------------------------------
// COMPONENT->FORMATTED-NAMES --------------------
// -----------------------------------------------
const FormattedNames = props => {
  const renderName = names => {
    names = names.trim();
    let namesArray = split(names, ', ');
    const formattedArray = map(namesArray, name => {
      if (name == 'me') return name;

      const nameArray = split(name, ' ');

      if (nameArray.length === 1) return name;

      const lastName = last(nameArray);
      const lastInitial = split(lastName, '')[0];
      return replace(name, lastName, `${lastInitial}.`);
    });

    return join(formattedArray, ', ');
  };

  // ---------------------------------------------
  // RETURN --------------------------------------
  // ---------------------------------------------
  return <span className={props.className}>{renderName(props.names)}</span>;
};

// ---------------------------------------------
// EXPORT --------------------------------------
// ---------------------------------------------
export default FormattedNames;
