import React from 'react';
import moment from 'moment';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';
import DefaultSingleDatePicker from 'adminComponents/DefaultSingleDatePicker';
import {
  IndicatorToggle,
  InputPercent,
  SimplifiedWysiwyg,
  IdPhotoUploader,
  InputTimeHandler,
  InlineTip
} from 'adminComponents';
import Datetime from 'react-datetime';
import isNull from 'lodash/isNull';
import { isNotNullOrUndefined } from 'sharedUtils';
import { InputNumber, InputText } from '@directsoftware/ui-kit-web-admin';
import FormColumnSection from './form-column-section';

export default class EditingForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minAgeCheckFails: false
    };
  }

  renderInput = item => {
    if (item.type === 'text' || item.type === 'number') {
      return item.type === 'text' ? (
        <InputText
          onChange={e => this.props.onChange(item.key, e.target.value)}
          name={item.key}
          placeholder={item.placeholder}
          value={this.props.formState[item.key]}
          addSuffix={item.suffix}
          id={item.key}
        />
      ) : (
        <InputNumber
          onChange={e => this.props.onChange(item.key, e.target.value)}
          name={item.key}
          placeholder={item.placeholder}
          value={this.props.formState[item.key]}
          addSuffix={item.suffix}
          id={item.key}
          min={item.min}
        />
      );
    } else if (item.type === 'age') {
      return (
        <InputNumber
          onChange={e => {
            this.props.onChange(item.key, e.target.value);
            this.setState({ minAgeCheckFails: e.target.value < 25 });
          }}
          name={item.key}
          placeholder={item.placeholder}
          value={this.props.formState[item.key]}
          addSuffix={item.suffix}
          id={item.key}
          min={item.min}
          isError={this.state.minAgeCheckFails}
          description={
            this.state.minAgeCheckFails
              ? 'Driver must be at least 25 years old'
              : null
          }
        />
      );
    } else if (item.type === 'datepicker') {
      return (
        <DefaultSingleDatePicker
          initialDate={moment(this.props.formState[item.key])}
          onDateSet={date => this.props.onChange(item.key, date.format())}
          id={item.key}
        />
      );
    } else if (item.type === 'select') {
      let selected = {};
      item.options.forEach(option => {
        if (option.value == this.props.formState[item.key]) {
          selected = option;
        }
      });

      return (
        <InputSelect
          inputId={item.key}
          name={item.key}
          placeholder={item.placeholder}
          options={item.options}
          defaultValue={selected}
          onChange={selectedOption =>
            this.props.onChange(item.key, selectedOption.value)
          }
        />
      );
    } else if (item.type === 'multi-select') {
      return (
        <InputSelect
          isMulti
          inputId={item.key}
          defaultValue={item.value}
          name={item.key}
          options={item.options}
          placeholder={item.placeholder}
          onChange={opt => {
            const values = opt.map(o => o.value);
            this.props.onChange(item.key, values);
          }}
        />
      );
    } else if (item.type === 'boolean') {
      return (
        <IndicatorToggle
          toggleAction={(key, status) => this.props.onChange(key, !status)}
          toggleStatus={this.props.formState[item.key]}
          toggleItemId={item.key}
          toggleFalseLabel={
            isNotNullOrUndefined(item.falseLabel) ? item.falseLabel : 'Disabled'
          }
          toggleTrueLabel={
            isNotNullOrUndefined(item.trueLabel) ? item.trueLabel : 'Enabled'
          }
        />
      );
    } else if (item.type === 'percent') {
      return (
        <InputPercent
          setPercent={value => this.props.onChange(item.key, value)}
          value={this.props.formState[item.key]}
        />
      );
    } else if (item.type === 'wysiwyg') {
      return (
        <SimplifiedWysiwyg
          content={this.props.formState[item.key]}
          name={item.key}
          onContentChange={this.props.onChange}
        />
      );
    } else if (item.type === 'photoID') {
      return (
        <IdPhotoUploader
          afterUpload={idPhoto =>
            this.afterUpload(idPhoto, item.key, item.afterUpload)
          }
          afterPhotoDelete={idPhoto =>
            this.afterPhotoDelete(idPhoto, item.key, item.afterPhotoDelete)
          }
          idSide={item.idSide}
          buttonText={item.buttonText}
          organizationId={this.props.organization.id}
          identifiableType={item.identifiableType}
          identifiableId={item.identifiableId}
          idPhoto={item.idPhoto}
        />
      );
    } else if (item.type === 'time') {
      return (
        <InputTimeHandler
          callback={val => this.props.onChange(item.key, val)}
          value={this.props.formState[item.key]}
          name={item.name}
        />
      );
    } else if (item.type === 'datetime') {
      const times = [
        '07:00',
        '07:30',
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30'
      ];
      return (
        <>
          <InlineTip
            tip={<p>must enter military time, example: 19:22 for 7:22pm </p>}
            iconStyle={{ marginLeft: '100%' }}
          />
          <Datetime
            onChange={val => this.props.onChange(item.key, val)}
            value={
              isNull(this.props.formState[item.key])
                ? new Date(moment().format('LLL'))
                : new Date(this.props.formState[item.key])
            }
            inputProps={{ name: item.name }}
          />
        </>
      );
    }
  };

  afterUpload = (idPhoto, itemKey, itemAfterUpload) => {
    this.props.onChange(itemKey, idPhoto.url);
    itemAfterUpload && itemAfterUpload(idPhoto);
  };

  afterPhotoDelete = (idPhoto, itemKey, itemAfterPhotoDelete) => {
    this.props.onChange(itemKey, 'Blank');
    itemAfterPhotoDelete && itemAfterPhotoDelete(idPhoto);
  };

  renderColumnSection = items => (
    <FormColumnSection
      items={items}
      renderItem={this.renderInput}
      key={`column-${items[0].key}`}
    />
  );

  render() {
    return (
      <form style={{ width: '100%' }}>
        <section
          className="consolidated-item-form-editing"
          style={{ maxWidth: '100%' }}
        >
          {this.props.items.map(item => {
            if (toString.call(item) === '[object Array]') {
              return this.renderColumnSection(item);
            }
            if (item.type === 'disabled') {
              return null;
            }

            return (
              <section className="consolidated-item-subsection" key={item.key}>
                <label htmlFor={item.key}>{item.label}</label>
                {this.renderInput(item)}
              </section>
            );
          })}
        </section>
      </form>
    );
  }
}
