import styled from 'styled-components';
import { gridSize, colors } from '@atlaskit/theme';

const ReadContainer = styled.div`
  display: flex;
  max-width: 100%;
  overflow: hidden;
  padding: ${gridSize()}px ${gridSize() - 2}px;
`;

const InputEditContainer = styled.div`
  div {
    background-color: #fff;
    border: none;
    margin: 0;
  }

  + div {
    width: initial;

    div {
      width: 32px;
    }
  }

  input {
    border-radius: 3px;
    box-sizing: border-box;
    height: 36px;
    cursor: inherit;
    outline: none;
    padding: ${gridSize()}px ${gridSize() - 2}px;
    width: 100%;
    border: 2px solid ${colors.N40};
    font-size: 12px;
    font-family: 'Avenir Next', 'Avenir', 'Century Gothic', 'Catamaran',
      Helvetica, Arial, sans-serif;

    :focus {
      border: 2px solid ${colors.B100};
    }
  }
`;

export { ReadContainer, InputEditContainer };
