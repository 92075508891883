import request from "apiClient";
export default class PayoutService {
  static index(organizationId, queryString) {
    return request({
      url: `/api/${organizationId}/payouts${queryString}`,
      method: "GET"
    });
  }
  static update(organizationId, payoutID, data) {
    return request({
      url: `/api/${organizationId}/payouts/${payoutID}`,
      method: "PATCH",
      data
    });
  }
  static paymentIndex(organizationId, queryString) {
    return request({
      url: `/api/${organizationId}/payouts/payments${queryString}`,
      method: "GET"
    });
  }
  static paymentDetails(organizationId, payeeId, queryString) {
    return request({
      url: `/api/${organizationId}/payouts/payments/${payeeId}${queryString}`,
      method: "GET"
    });
  }
  static recreate(organizationId, queryString) {
    return request({
      url: `/api/${organizationId}/payouts/payments_recreate/${queryString}`,
      method: "GET"
    });
  }
  static booking_payouts(organizationId, bookingCode) {
    return request({
      url: `/api/${organizationId}/payouts/booking_payouts/${bookingCode}`,
      method: "GET"
    });
  }
  static updateAdjustment(orgId, data) {
    return request({
      url: `/api/${orgId}/adjustments/${data.id}`,
      method: "PATCH",
      data: { adjustment: data }
    });
  }
}
