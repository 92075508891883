import React from 'react';
import {
  Box,
  FlexBox,
  Panel,
  TextBody
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { selectUserOrganization } from '../redux/slices/userDetails';

const VehicleCard = ({ vehicle }) => {
  const { subdomain } = useSelector(selectUserOrganization);
  const vehicleImage = vehicle => {
    if (vehicle.featured_image) {
      return vehicle.featured_image;
    } else {
      return '/assets/fallback/property_default.png';
    }
  };

  return (
    <Panel setPositionRelative padding="s">
      <a
        className="listing-mobile-link"
        href={`/dashboard/${subdomain}/vehicles/${vehicle.id}`}
      />
      <FlexBox gap="s" alignItems="center">
        <Box setPositionRelative className="listing-image-container">
          <img src={vehicleImage(vehicle)} alt="" />
        </Box>
        <Box style={{ minWidth: 0 }}>
          <TextBody
            textColor="dark-gray"
            weight="semibold"
            truncate
            isFullWidth
          >
            {vehicle.name}
          </TextBody>
          {vehicle.unit_code}
        </Box>
      </FlexBox>
    </Panel>
  );
};

VehicleCard.propTypes = {};

VehicleCard.defaultProps = {};

export default VehicleCard;
