import React from 'react';
import find from 'lodash/find';
import { CurrencySelect } from 'adminComponents';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';

export default class FieldsOrganizationLanguageCurrency extends React.Component {
  constructor(props, _railsContext) {
    super(props);
  }

  setLanguage = option => {
    this.props.setLanguage(option.value);
  };

  setCurrency = option => {
    this.props.setCurrency(option.value);
  };

  render() {
    const languageOptions = [
      { value: 'en', label: 'English', nativeName: 'English' },
      { value: 'ar', label: 'Arabic', nativeName: 'العربية' },
      { value: 'bn', label: 'Bengali', nativeName: 'বাংলা' },
      {
        value: 'zh',
        label: 'Chinese',
        nativeName: '中文 (Zhōngwén), 汉语, 漢語'
      },
      { value: 'cs', label: 'Czech', nativeName: 'Česky, Čeština' },
      { value: 'da', label: 'Danish', nativeName: 'Dansk' },
      { value: 'nl', label: 'Dutch', nativeName: 'Nederlands, Vlaams' },
      { value: 'et', label: 'Estonian', nativeName: 'Eesti, Eesti keel' },
      { value: 'fr', label: 'French', nativeName: 'Français' },
      { value: 'de', label: 'German', nativeName: 'Deutsch' },
      { value: 'el', label: 'Greek, Modern', nativeName: 'Ελληνικά' },
      { value: 'hi', label: 'Hindi', nativeName: 'हिन्दी, हिंदी' },
      { value: 'hu', label: 'Hungarian', nativeName: 'Magyar' },
      { value: 'id', label: 'Indonesian', nativeName: 'Bahasa Indonesia' },
      { value: 'it', label: 'Italian', nativeName: 'Italiano' },
      {
        value: 'ja',
        label: 'Japanese',
        nativeName: '日本語 (にほんご／にっぽんご)'
      },
      { value: 'jv', label: 'Javanese', nativeName: 'basa Jawa' },
      {
        value: 'ko',
        label: 'Korean',
        nativeName: '한국어 (韓國語), 조선말 (朝鮮語)'
      },
      { value: 'nb', label: 'Norwegian Bokmål', nativeName: 'Norsk bokmål' },
      { value: 'pa', label: 'Panjabi, Punjabi', nativeName: 'ਪੰਜਾਬੀ, پنجابی‎' },
      { value: 'pl', label: 'Polish', nativeName: 'polski' },
      { value: 'pt', label: 'Portuguese', nativeName: 'Português' },
      { value: 'ru', label: 'Russian', nativeName: 'Pусский язык' },
      {
        value: 'es',
        label: 'Spanish; Castilian',
        nativeName: 'Español, Castellano'
      },
      { value: 'sv', label: 'Swedish', nativeName: 'svenska' },
      { value: 'th', label: 'Thai', nativeName: 'ไทย' },
      { value: 'tr', label: 'Turkish', nativeName: 'Türkçe' },
      { value: 'vi', label: 'Vietnamese', nativeName: 'Tiếng Việt' }
    ];

    return (
      <fieldset>
        <header className="fieldset-header">
          <legend>Set up your currency and language preferences</legend>
        </header>

        <label htmlFor="language">Language</label>
        <InputSelect
          name="language"
          className="magnified"
          options={languageOptions}
          isClearable={false}
          isSearchable={false}
          placeholder="Select language"
          value={find(languageOptions, option => {
            return option.value === this.props.language;
          })}
          onChange={this.setLanguage}
        />

        <CurrencySelect
          onChange={this.setCurrency}
          currency={this.props.currency}
        />
      </fieldset>
    );
  }
}
