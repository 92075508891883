import React from 'react';
import Loading from 'react-loading-components';
import ReactPaginate from 'react-paginate';
import CurrencyDefinitions from 'sharedUtils/currency-definitions';
import { nanoid } from 'nanoid';
import NoResults from './no-results';

class DirectTable extends React.Component {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      page: 0
    };
  }

  componentDidMount() {
    this.props.onFetchData(this.getResolvedState(), this);
  }

  getResolvedState(props, state) {
    const resolvedState = {
      ...this.compactObject(this.state),
      ...this.compactObject(this.props),
      ...this.compactObject(state),
      ...this.compactObject(props)
    };
    return resolvedState;
  }

  compactObject(obj) {
    const newObj = {};
    if (obj) {
      Object.keys(obj).map(key => {
        if (
          Object.prototype.hasOwnProperty.call(obj, key) &&
          obj[key] !== undefined &&
          typeof obj[key] !== 'undefined'
        ) {
          newObj[key] = obj[key];
        }
        return true;
      });
    }
    return newObj;
  }

  renderCell = (instance, column) => {
    if (typeof column?.Cell === 'function') {
      return column.Cell(instance);
    }

    if (typeof column?.accessor === 'string') {
      let columnValue = instance[column.accessor];
      if (column.accessor === 'calculation_amount') {
        const currencyDetails = CurrencyDefinitions.filter(
          definition => definition.value === this.props.organization.currency
        )[0];
        columnValue = `${currencyDetails.symbol +
          parseFloat(columnValue).toFixed(2)}`;
        columnValue =
          instance.usage_type === 'Mileage'
            ? `${columnValue} per mile`
            : `${columnValue} per hour`;
      }
      if (column.accessor === 'amount_free') {
        columnValue =
          instance.usage_type === 'Mileage'
            ? `${columnValue} miles`
            : `${columnValue} hours`;
      }
      return (
        <td
          key={`${instance.id}-${column.accessor}`}
          style={column.cellStyle ? column.cellStyle : {}}
          colSpan={column.colSpan ? column.colSpan : 1}
        >
          {columnValue}
        </td>
      );
    }

    return !column ? null : (
      <td
        key={`${instance.id}-col-${column.name}-${nanoid(4)}`}
        style={column.cellStyle ? column.cellStyle : {}}
        colSpan={column.colSpan ? column.colSpan : 1}
      >
        {column.accessor(instance)}
      </td>
    );
  };

  renderHeaderCell = column => {
    if (typeof column?.Header === 'function') {
      return column.Header();
    }

    return !column ? null : (
      <th
        key={`col-header-${column.Header}`}
        colSpan={column.colSpan ? column.colSpan : 1}
      >
        {column.Header}
      </th>
    );
  };

  handlePageClick = data => {
    this.setState({ page: data.selected }, () => {
      if (this.props.paginateOnly) return null;
      this.props.onFetchData(this.getResolvedState(), this);
    });
  };

  renderRow = instance => {
    if (instance.customRow) return instance.row();
    if (typeof instance === 'function') {
      return instance();
    }

    return (
      <tr
        key={instance.id}
        style={this.props.solidColor ? { background: 'white' } : {}}
      >
        {this.props.columns.map(column => {
          return this.renderCell(instance, column);
        })}
      </tr>
    );
  };

  renderBody = () => {
    if (!this.props.data) return null;
    let data = this.props.data;

    if (this.props.paginateOnly) {
      const sliceStart = this.state.page * this.props.defaultPageSize;
      const sliceEnd = (this.state.page + 1) * this.props.defaultPageSize;

      data = [...this.props.data].slice(sliceStart, sliceEnd);
    }

    return data.map(instance => this.renderRow(instance));
  };

  render() {
    const pagination = (
      <ReactPaginate
        previousLabel="previous"
        nextLabel="next"
        breakLabel={<a href="">...</a>}
        breakClassName="break-me"
        pageCount={this.props.pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={4}
        onPageChange={this.handlePageClick}
        forcePage={this.state.page}
        containerClassName=""
        activeClassName="active"
      />
    );

    if (this.props.loading == true) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
            width: '100%'
          }}
        >
          <Loading type="tail_spin" width={50} height={50} fill="#00CC99" />
        </div>
      );
    }

    return (
      <div>
        <table
          className={
            this.props.accountingTable
              ? 'consolidated-item-list consolidated-item-list-accounting-table'
              : 'consolidated-item-list consolidated-item-list-table'
          }
        >
          <thead style={this.props.headerStyles}>
            <tr>
              {this.props.columns.map(column => this.renderHeaderCell(column))}
            </tr>
          </thead>
          {this.props.useOwnTbody ? (
            this.props.data &&
            this.props.data.map(instance => this.renderRow(instance))
          ) : (
            <tbody>{this.renderBody()}</tbody>
          )}
        </table>
        {this.props.data.length < 1 && this.props.showNoResultsImage && (
          <NoResults />
        )}
        {this.props.pages > 1 && (
          <div className="consolidated-key" style={{ position: 'relative' }}>
            <div
              style={{ float: 'right', paddingTop: '10px' }}
              className="no-wrap"
            >
              {pagination}
            </div>
          </div>
        )}
      </div>
    );
  }
}

DirectTable.defaultProps = {
  onFetchData: () => {},
  defaultPageSize: 50
};

export default DirectTable;
