import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { WORKFLOWS_NAV_TAGS } from '../../../../../shared/nav';

const TemplatesHome = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: WORKFLOWS_NAV_TAGS.PRIMARY,
        secondary: WORKFLOWS_NAV_TAGS.secondary.TEMPLATES
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/workflows',
            label: 'Templates'
          },
          {
            label: 'Edit Details'
          }
        ]}
      >
        {`Workflow Template ${props.templateId}`}
      </PageHeader.Left>
    </PageHeader>
  );
};

export default TemplatesHome;
