import React from 'react';
import PropTypes from 'prop-types';

import MediaQuery from 'react-responsive';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import classNames from 'classnames';
import {
  CallToActionButton,
  CallToActionLink
} from '@directsoftware/ui-kit-web-admin';

export default class Stepper extends React.Component {
  static propTypes = {
    steps: PropTypes.array.isRequired,
    startingStepIndex: PropTypes.number
  };

  static defaultProps = {
    startingStepIndex: 0
  };

  constructor(props) {
    super(props);

    this.state = {
      currentStepIndex: this.props.activeStep || 0,
      hintsVisible: false
    };
  }

  incrementStep = (e, currentStep) => {
    e.preventDefault();
    if (currentStep.handleIncrement) {
      currentStep.handleIncrement.function(currentStep.handleIncrement.params);
    }
    if (currentStep?.handleIncrement?.overrideStep) {
      return null;
    } else {
      document.getElementById('next button').focus;
      const nextStepIndex = this.state.currentStepIndex + 1;
      this.setState({ currentStepIndex: nextStepIndex });
    }
  };

  decrementStep = (e, currentStep) => {
    e.preventDefault();
    if (currentStep.handleDecrement) {
      currentStep.handleDecrement.function(currentStep.handleDecrement.params);
    }
    const previousStepIndex = this.state.currentStepIndex - 1;
    this.setState({ currentStepIndex: previousStepIndex });
  };

  toggleHintsVisiblity = e => {
    e.preventDefault();
    const hintsVisible = this.state.hintsVisible;
    this.setState({
      hintsVisible: !hintsVisible
    });
  };

  jumpToStep = (e, stepIndex) => {
    e.preventDefault();
    const steps = this.props.steps;
    const currentStep = steps[this.state.currentStepIndex];
    if (
      stepIndex > this.state.currentStepIndex &&
      currentStep.handleIncrement
    ) {
      currentStep.handleIncrement.function(currentStep.handleIncrement.params);
    } else if (
      stepIndex < this.state.currentStepIndex &&
      currentStep.handleDecrement
    ) {
      currentStep.handleDecrement.function(currentStep.handleDecrement.params);
    }
    this.setState({ currentStepIndex: stepIndex });
  };

  onSelectStep = e => {
    this.jumpToStep(e, parseInt(e.target.value));
  };

  renderLink() {
    const {
      props: { steps },
      state: { currentStepIndex }
    } = this;
    const currentStep = steps[currentStepIndex];

    return this.props.noBack ? (
      <div />
    ) : (
      <CallToActionLink
        isDisabled
        variation="secondary"
        href={this.props.backLink ? this.props.backLink : `#`}
        appearance="outline"
      >
        {currentStep?.backLabel || 'Back'}
      </CallToActionLink>
    );
  }

  renderStepLabels() {
    const steps = this.props.steps;
    const currentStep = steps[this.state.currentStepIndex];
    return (
      <header className="stepper-step-labels">
        <MediaQuery maxWidth={1279}>
          <div className="select-wrapper">
            <select
              value={this.state.currentStepIndex}
              name="current_step"
              onChange={this.onSelectStep}
              disabled={currentStep.disabled}
            >
              {steps.map(step => (
                <option key={step.index} value={step.index}>
                  {step.label}
                </option>
              ))}
            </select>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1280}>
          <ul>
            {steps.map(step => (
              <li
                key={step.index}
                className={
                  step.index == this.state.currentStepIndex ? 'active' : ''
                }
                style={this.props.squish && { marginRight: '12px' }}
              >
                <a
                  href="#"
                  className={classNames({ disabled: currentStep.disabled })}
                  style={{
                    color:
                      step !== currentStep && currentStep.disabled && '#E5E2E2'
                  }}
                  onClick={e => this.jumpToStep(e, step.index)}
                >
                  {step.label}
                </a>
              </li>
            ))}
          </ul>
        </MediaQuery>
      </header>
    );
  }

  render() {
    const steps = this.props.steps;
    const currentStep = steps[this.state.currentStepIndex];
    return (
      <figure
        className={`stepper smaller-aside ${currentStep.hideAside &&
          'hide-aside'}`}
      >
        <ReactCSSTransitionGroup
          transitionName="stepper-transition"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={1}
          component="main"
        >
          <div key={currentStep.index}>
            {this.props.showStepLabels ? this.renderStepLabels() : null}
            <section className="wrapper">
              {this.props.title ? (
                <section className="consolidated-item-command-palette">
                  <div className="consolidated-item-command-palette-content">
                    <header className="consolidated-item-command-palette-breadcrumbs">
                      <span className="consolidated-item-command-palette-heading">
                        {this.props.title}
                      </span>
                    </header>
                  </div>
                </section>
              ) : null}
              {currentStep.component}
            </section>
          </div>
          <section className="stepper-button-container">
            {this.state.currentStepIndex > 0 ? (
              <CallToActionButton
                variation="secondary"
                onClick={e => this.decrementStep(e, currentStep)}
                appearance="outline"
              >
                {currentStep.backLabel || 'Back'}
              </CallToActionButton>
            ) : (
              this.renderLink()
            )}
            {this.state.currentStepIndex < steps.length - 1 ? (
              <CallToActionButton
                id="next button"
                onClick={e => this.incrementStep(e, currentStep)}
                isDisabled={currentStep.disabled}
                appearance="outline"
              >
                {currentStep.nextLabel || 'Next'}
              </CallToActionButton>
            ) : (
              ''
            )}
            {this.state.currentStepIndex === steps.length - 1 ? (
              <CallToActionButton
                onClick={e => currentStep.handleComplete(e)}
                isDisabled={currentStep.disabled}
              >
                {currentStep.completeLabel || 'Submit'}
              </CallToActionButton>
            ) : (
              ''
            )}
          </section>
        </ReactCSSTransitionGroup>
        {currentStep.aside ? (
          <MediaQuery query="(max-width: 799px)">
            {this.state.hintsVisible ? (
              <aside>
                <figure className="card" key={currentStep.index}>
                  {currentStep.aside}
                </figure>
              </aside>
            ) : null}
            <a
              href="#"
              className="hints-trigger"
              onClick={e => this.toggleHintsVisiblity(e)}
            >
              ?
            </a>
          </MediaQuery>
        ) : null}
        <MediaQuery query="(min-width: 800px)">
          <ReactCSSTransitionGroup
            transitionName="stepper-transition"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={1}
            component="aside"
          >
            {currentStep.aside ? (
              <div>
                <span href="#" className="hints-icon">
                  ?
                </span>
                <figure className="card" key={currentStep.index}>
                  {currentStep.aside}
                </figure>
              </div>
            ) : null}
          </ReactCSSTransitionGroup>
        </MediaQuery>
      </figure>
    );
  }
}
