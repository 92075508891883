import React from 'react';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import sumBy from 'lodash/sumBy';
import capitalize from 'lodash/capitalize';
import { CurrencyDisplay } from 'sharedComponents/currency-display';
import { QuoteTable, QuoteRow } from './BookingWidgetStyles';

const floatToString = (value, round = true) => {
  return round
    ? parseFloat(value)
        .toFixed(2)
        .toString()
    : parseFloat(value).toString();
};

const quoteLineItems = (lineItems, lineItemType) => {
  return filter(lineItems, lineItem => lineItem.item_type === lineItemType);
};

const lineItemRow = (
  key,
  currency,
  label,
  value,
  style = {},
  applyStyleToAll = false,
  editable = false,
  onLineItemSelect = null,
  selected = false,
  alwaysDisplay = false,
  subItem = false
) => {
  if ((value === 0 || value === 0.0) && !alwaysDisplay) return null;

  const baseStyle = { verticalAlign: 'top' };
  const rightStyle = { ...baseStyle, ...style };
  const leftStyle = applyStyleToAll ? rightStyle : baseStyle;

  return (
    <QuoteRow
      key={key}
      editable={editable}
      selected={selected}
      onClick={onLineItemSelect}
    >
      <td style={leftStyle} className={subItem ? 'quote-sub-item' : undefined}>
        {label}
      </td>
      <td style={rightStyle}>
        <small className="currency-meta">{currency}</small>{' '}
        {typeof value === 'string' ? (
          <span dangerouslySetInnerHTML={{ __html: value }} />
        ) : (
          floatToString(value)
        )}
      </td>
    </QuoteRow>
  );
};

const TotalDiscountedRoomRateLineItem = quote => {
  const roomRateCents = sumBy(
    quoteLineItems(quote.line_items, 'room_rate'),
    li => li.total_cents
  );
  const inflationCents = sumBy(
    quoteLineItems(quote.line_items, 'inflation'),
    li => li.total_cents
  );
  const aggFeeCents = sumBy(
    filter(quoteLineItems(quote.line_items, 'fees'), li =>
      isTrueAggFee(quote, li)
    ),
    li => li.total_cents
  );
  const addfeeCents = sumBy(
    reject(
      quoteLineItems(
        quote.line_items.filter(item => item.name === 'Additional Guest Fee'),
        'fees'
      ),
      li => isTrueAggFee(quote, li)
    ),
    li => li.total_cents
  );

  const discountCents = sumBy(
    quoteLineItems(quote.line_items, 'discount'),
    li => li.total_cents
  );
  const promotionCents = sumBy(
    quoteLineItems(quote.line_items, 'promotions'),
    li => li.total_cents
  );

  return lineItemRow(
    'total-discounted-room-rate-line-item',
    quote.currency,
    'Total Room Rate',
    (roomRateCents +
      inflationCents +
      aggFeeCents +
      addfeeCents -
      discountCents -
      promotionCents) /
      100.0
  );
};

const RoomRateLineItem = (
  quote,
  allowEdits,
  onLineItemSelect,
  selectedLineItem
) => {
  const roomRateLineItems = quoteLineItems(quote.line_items, 'room_rate');
  const nights = quote.billable_nights;

  return roomRateLineItems.map(roomRateItem =>
    lineItemRow(
      roomRateItem.id,
      quote.currency,
      renderRoomRateLabel(quote.currency, nights, roomRateItem),
      roomRateItem.total_cents / 100.0,
      {},
      false,
      allowEdits,
      () => onLineItemSelect(roomRateItem),
      roomRateItem.id === selectedLineItem.id,
      true,
      true
    )
  );
};

const renderRoomRateLabel = (currency, nights, roomRateItem) => {
  return (
    <span>
      Room Rate (
      <CurrencyDisplay currencyValue={currency} />
      {floatToString(roomRateItem.rate)}
{' '}
avg. x{nights}
{' '}
nights)
</span>
  );
};

const DiscountLineItem = (
  quote,
  allowEdits,
  onLineItemSelect,
  selectedLineItem,
  props
) => {
  const discountItems = quoteLineItems(quote.line_items, 'discount');

  if (!discountItems) return null;

  return discountItems.map(discountItem =>
    lineItemRow(
      discountItem.id,
      quote.currency,
      discountItemLabel(discountItem, props),
      discountItem.total_cents / -100.0,
      discountItem.rate < 0 ? {} : { color: 'red' },
      false,
      allowEdits,
      () => onLineItemSelect(discountItem),
      discountItem.id === selectedLineItem.id,
      false,
      true
    )
  );
};

const discountItemLabel = (discountItem, props) => {
  let labelRate = discountItem.rate
    ? `(${floatToString(discountItem.rate)}%)`
    : '';
  let label = discountItem.name;
  if (props.quote?.vehicle_id) {
    if (label === 'Weekly Discount' || label === 'Monthly Discount') {
      label = 'Ext. Stay Pricing:';
      if (labelRate !== '') {
        const rate = floatToString(Math.abs(discountItem.rate));
        labelRate = discountItem.rate < 0 ? `INC ${rate}%` : `DSC ${rate}%`;
      }
    }
  }
  return `${label} ${labelRate}`;
};

const PromotionLineItem = (
  quote,
  allowEdits,
  onLineItemSelect,
  selectedLineItem
) => {
  const promoItems = quoteLineItems(quote.line_items, 'promotions');

  if (!promoItems) return null;

  return promoItems.map(promoItem =>
    lineItemRow(
      promoItem.id,
      quote.currency,
      promoItem.name,
      promoItem.total_cents / -100.0,
      { color: 'red' },
      false,
      false,
      null,
      promoItem.id === selectedLineItem.id,
      false,
      true
    )
  );
};

const isDirect = quote => {
  return quote.channel_id === null || quote.channel_id === undefined;
};

const isTrueAggFee = (quote, li) => {
  // If the quote is for direct, then any fee that is included in the base rent
  // can be considered an agg fee
  if (isDirect(quote)) {
    return li.additional_data.included_in_base_rent;
  }

  // If the quote is for a channel, we can only send percent based fees as agg fees,
  // so if their is a rate available, then we know it is a percent agg and can be included
  return (
    li.additional_data.included_in_base_rent &&
    li.rate !== null &&
    li.rate !== undefined
  );
};

const AggFeeLineItems = (
  quote,
  allowEdits,
  onLineItemSelect,
  selectedLineItem
) => {
  const feeItems = filter(quoteLineItems(quote.line_items, 'fees'), li =>
    isTrueAggFee(quote, li)
  );

  if (!feeItems || feeItems.length === 0) return null;

  return feeItems.map(feeItem =>
    renderFeeLineItem(
      quote,
      feeItem,
      allowEdits,
      onLineItemSelect,
      selectedLineItem,
      false,
      true
    )
  );
};

const NormalFeeLineItems = (
  quote,
  allowEdits,
  onLineItemSelect,
  selectedLineItem
) => {
  const feeItems = reject(
    quoteLineItems(
      quote.line_items.filter(item => item.name !== 'Additional Guest Fee'),
      'fees'
    ),
    li => isTrueAggFee(quote, li)
  );

  if (!feeItems || feeItems.length === 0) return null;

  return feeItems.map(feeItem =>
    renderFeeLineItem(
      quote,
      feeItem,
      allowEdits,
      onLineItemSelect,
      selectedLineItem,
      true,
      false
    )
  );
};

const AdditionalFeeLineItems = (
  quote,
  allowEdits,
  onLineItemSelect,
  selectedLineItem
) => {
  const feeItems = reject(
    quoteLineItems(
      quote.line_items.filter(item => item.name === 'Additional Guest Fee'),
      'fees'
    ),
    li => isTrueAggFee(quote, li)
  );

  if (!feeItems || feeItems.length === 0) return null;

  return feeItems.map(feeItem =>
    renderFeeLineItem(
      quote,
      feeItem,
      allowEdits,
      onLineItemSelect,
      selectedLineItem,
      true,
      true
    )
  );
};

const renderFeeLineItem = (
  quote,
  feeItem,
  allowEdits,
  onLineItemSelect,
  selectedLineItem,
  alwaysDisplay = false,
  subItem = false
) => {
  const frequency = feeItem.additional_data.frequency_at_creation || 'per_stay';
  const name = feeItem.rate
    ? `${feeItem.name} (${floatToString(feeItem.rate)}%)`
    : feeItem.name;

  const nameWithFrequency =
    frequency === 'per_stay' ? name : `${name} (${capitalize(frequency)})`;
  // Channel fees do no abide by frequency, so no need to include
  const channelSafeName = isDirect(quote) ? nameWithFrequency : name;
  const channelAggSafeName = isTrueAggFee(quote, feeItem)
    ? `${channelSafeName}*`
    : channelSafeName;
  return lineItemRow(
    feeItem.id,
    quote.currency,
    channelAggSafeName,
    feeItem.total_cents / 100,
    {},
    false,
    allowEdits,
    () => onLineItemSelect(feeItem),
    feeItem.id === selectedLineItem.id,
    alwaysDisplay || feeItem.additional_data.manually_added,
    subItem
  );
};

const TaxLineItems = (
  quote,
  allowEdits,
  onLineItemSelect,
  selectedLineItem
) => {
  const taxItems = quoteLineItems(quote.line_items, 'taxes');
  return taxItems.map(taxItem => {
    const name = taxItem.rate
      ? `${taxItem.name} (${floatToString(taxItem.rate, false)}%)`
      : taxItem.name;
    return lineItemRow(
      taxItem.id,
      quote.currency,
      name,
      taxItem.total_cents / 100.0,
      {},
      false,
      allowEdits,
      () => onLineItemSelect(taxItem),
      taxItem.id === selectedLineItem.id,
      true
    );
  });
};

const InflatorLineItem = (
  quote,
  allowEdits,
  onLineItemSelect,
  selectedLineItem
) => {
  const inflationItems = quoteLineItems(quote.line_items, 'inflation');
  if (!inflationItems || inflationItems.length === 0) return null;

  return inflationItems.map(inflationItem =>
    lineItemRow(
      inflationItem.id,
      quote.currency,
      `Channel Rate Inflator (${inflationItem.rate}%)`,
      inflationItem.total_cents / 100.0,
      {},
      false,
      allowEdits,
      () => onLineItemSelect(inflationItem),
      inflationItem.id === selectedLineItem.id,
      true,
      true
    )
  );
};

const TotalLineItem = quote => {
  return lineItemRow(
    'booking-total-line-item',
    quote.currency,
    'BOOKING TOTAL',
    quote.total_cents / 100.0,
    { fontWeight: 'bold' },
    true
  );
};

const DepositLineItems = quote => {
  const depositItems = quoteLineItems(quote.line_items, 'deposit');

  if (!depositItems || depositItems.length === 0) return null;

  return depositItems.map(depositItem =>
    renderDepositLineItem(quote.currency, depositItem)
  );
};

const renderDepositLineItem = (currency, depositItem) => {
  const amountString = floatToString(depositItem.total_cents / 100.0);
  const taxableText = depositItem.taxable ? 'Taxable' : 'Non-taxable';
  const amountText = depositItem.rate
    ? `${amountString} (${floatToString(depositItem.rate)}%)`
    : amountString;
  const valueText = `${amountText}<br />${taxableText}`;

  return lineItemRow(depositItem.id, currency, depositItem.name, valueText);
};

const BookingWidgetContainer = props => {
  if (!props.quote) return null;

  const quote = props.quote;
  const allowEdits = props.allowEdits || false;
  const onLineItemSelect = props.onLineItemSelect || function() {};
  const selectedLineItem = props.selectedLineItem || { id: null };

  return (
    <QuoteTable className="consolidated-simple-table" style={props.style}>
      <tbody>
        {TotalDiscountedRoomRateLineItem(quote)}
        {RoomRateLineItem(
          quote,
          allowEdits,
          onLineItemSelect,
          selectedLineItem
        )}
        {PromotionLineItem(
          quote,
          allowEdits,
          onLineItemSelect,
          selectedLineItem
        )}
        {InflatorLineItem(
          quote,
          allowEdits,
          onLineItemSelect,
          selectedLineItem
        )}
        {AggFeeLineItems(quote, allowEdits, onLineItemSelect, selectedLineItem)}
        {AdditionalFeeLineItems(
          quote,
          allowEdits,
          onLineItemSelect,
          selectedLineItem
        )}
        {DiscountLineItem(
          quote,
          allowEdits,
          onLineItemSelect,
          selectedLineItem,
          props
        )}
        {NormalFeeLineItems(
          quote,
          allowEdits,
          onLineItemSelect,
          selectedLineItem
        )}
        {TaxLineItems(quote, allowEdits, onLineItemSelect, selectedLineItem)}
        {TotalLineItem(quote)}
        {DepositLineItems(quote)}
      </tbody>
    </QuoteTable>
  );
};

export default BookingWidgetContainer;
