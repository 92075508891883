import React from 'react';
import FilterContainer from './FilterContainer';
import PropTypes from 'prop-types';

/* 
  This component manages its own state related to loading proper options and values into subsequent dropdowns
  The logic for filtering lists should be implemented in the list component already, this component only allows user to make selections
  It requires a setFilter function and the organization Id to be passed down into it, 
  and passes the following object into that function: 
  { filter: <portfolio_id, subportfolio_id, or unit_group_id>, value: <id of what is selected> , label: <name of selection, if needed> }
  Styling is set in app/assets/stylesheets/admin/_inventory_segment_filters.scss
  It can be passed a flag to set the the max-width property to 1280px to match better with certain components
*/

const InventorySegmentFilterGroup = props => {
  return (
    <FilterContainer
      setFilters={props.setFilters}
      orgId={props.organizationId}
      widthLimited={props.widthLimited}
      filters={props.filters}
    />
  );
};

InventorySegmentFilterGroup.propTypes = {
  setFilters: PropTypes.func,
  organizationId: PropTypes.number,
  widthLimited: PropTypes.bool
};

InventorySegmentFilterGroup.defaultProps = {
  widthLimited: false
};

export default InventorySegmentFilterGroup;
