import request from "apiClient";
export default class OpportunityService {
  static index(organizationID, queryString) {
    return request({
      url: `/api/${organizationID}/opportunities${queryString}`,
      method: "GET"
    });
  }
  static create(organizationID, data) {
    return request({
      url: `/api/${organizationID}/opportunities`,
      method: "POST",
      data
    });
  }
  static fetch(organizationId, opportunityId) {
    return request({
      url: `/api/${organizationId}/opportunities/${opportunityId}`,
      method: "GET"
    });
  }
  static update(organizationID, opportunityId, data) {
    return request({
      url: `/api/${organizationID}/opportunities/${opportunityId}`,
      method: "PATCH",
      data
    });
  }
  static simpleUpdate(organizationId, opportunityId, data) {
    return request({
      url: `/api/${organizationId}/opportunities/${opportunityId}/simple_update`,
      method: "PATCH",
      data
    });
  }
  static possibleEmployees(organizationID) {
    return request({
      url: `/api/${organizationID}/opportunities/possible_employees`,
      method: "GET"
    });
  }
  static possibleCustomer(organizationID, email) {
    return request({
      url: `/api/${organizationID}/opportunities/possible_customer?email=${email}`,
      method: "GET"
    });
  }
}
