import React from 'react';
import sortBy from 'lodash/sortBy';
import SelectService from 'adminApi/SelectService';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';
import displayError from '../../shared/components/ErrorDisplay';

export default class UserSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { assigneeOptions: [] };
  }

  componentDidMount() {
    this.fetchPeople();
  }

  handleChange = selectedOption => {
    this.props.handleSelect(selectedOption.value, selectedOption.type);
  };

  fetchPeople() {
    const data = {
      model: 'Employee',
      select: ['id', 'name']
    };
    SelectService.index(this.props.organization.id, data)
      .then(response => {
        const assigneeOptions = sortBy(response.options, ['name']).map(
          option => ({
            value: option.id,
            label: option.name,
            type: 'Employee'
          })
        );
        this.setState({ assigneeOptions });
      })
      .catch(err =>
        displayError({ message: 'Error retrieving assignees', err })
      );
  }

  render() {
    const { selectedOption } = this.state;
    return (
      <div>
        <InputSelect
          name="user"
          placeholder="Select a user to assign..."
          options={this.state.assigneeOptions}
          onChange={this.handleChange}
          value={selectedOption}
        />
      </div>
    );
  }
}
