import request from "apiClient";
function index(orgId, params = {}) {
  return request({
    url: `/api/${orgId}/inventory_items`,
    method: "GET",
    params
  });
}
function create(orgId, data) {
  return request({
    url: `/api/${orgId}/inventory_items`,
    method: "POST",
    data
  });
}
function update(orgId, itemId, data) {
  return request({
    url: `/api/${orgId}/inventory_items/${itemId}`,
    method: "PATCH",
    data
  });
}
function deleteItem(orgId, itemId) {
  return request({
    url: `/api/${orgId}/inventory_items/${itemId}`,
    method: "DELETE"
  });
}
const InventoryItemService = {
  index,
  create,
  update,
  deleteItem
};
export default InventoryItemService;
