import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Bullet = styled.div`
  height: 20px;
  width: 20px;
  background-color: black;
  margin-right: 12px;
`;

const Text = styled.h6`
  color: #404040;
`;

const ListDataItem = ({ text }) => (
  <Container>
    <Bullet />
    <Text>{text}</Text>
  </Container>
);

export default ListDataItem;
