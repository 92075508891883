import request from "apiClient";
const listVehicles = ({ orgId, params }) => {
  return request({
    url: `/api/${orgId}/vehicles`,
    method: "GET",
    params
  });
};
const createVehicle = ({ orgId, params }) => {
  return request({
    url: `/api/${orgId}/vehicles`,
    method: "POST",
    data: { vehicle: params }
  });
};
const getVehicle = ({ orgId, vehicleId }) => {
  return request({
    url: `/api/${orgId}/vehicles/${vehicleId}`,
    method: "GET"
  });
};
const getVehiclePricing = ({ orgId, vehicleId }) => {
  return request({
    url: `/api/${orgId}/vehicles/${vehicleId}/unit_pricing`,
    method: "GET"
  });
};
const getVehicleAvailability = ({ orgId, vehicleId }) => {
  return request({
    url: `/api/${orgId}/vehicles/${vehicleId}/unit_availability`,
    method: "GET"
  });
};
const updateVehicle = ({ orgId, params, vehicleId }) => {
  return request({
    url: `/api/${orgId}/vehicles/${vehicleId}`,
    method: "PATCH",
    data: { vehicle: params }
  });
};
const updateVehicleLocation = ({ orgId, vehicleId, params }) => {
  return request({
    url: `/api/${orgId}/vehicles/${vehicleId}/location`,
    method: "PATCH",
    data: { location: params }
  });
};
const updateVehiclePricing = ({ orgId, vehicleId, params }) => {
  return request({
    url: `/api/${orgId}/vehicles/${vehicleId}/pricing`,
    method: "PATCH",
    data: { pricing: params }
  });
};
const cloneVehicle = ({ orgId, params, vehicleId }) => {
  return request({
    url: `/api/${orgId}/vehicles/${vehicleId}/clone`,
    method: "POST",
    data: params
  });
};
const getVehicleContracts = ({ orgId, vehicleId }) => {
  return request({
    url: `/api/${orgId}/vehicles/${vehicleId}/contracts`,
    method: "GET"
  });
};
const handleDeposit = ({ orgId, vehicleId, depositValue }) => {
  return request({
    url: `/api/${orgId}/vehicles/${vehicleId}/handle_deposit`,
    method: "POST",
    data: { deposit_value: depositValue }
  });
};
export {
  createVehicle,
  listVehicles,
  getVehicle,
  getVehicleAvailability,
  getVehiclePricing,
  updateVehicle,
  updateVehicleLocation,
  updateVehiclePricing,
  cloneVehicle,
  getVehicleContracts,
  handleDeposit
};
