import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const LiabilitiesAndStatementsLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags,
  ...props
}) => {
  return (
    <Navbar.Link
      href="/accounting/liabilities-and-statements"
      variation="secondary"
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={
        activeRoutes.secondary === navTags.secondary.LIABILITIES_AND_STATEMENTS
      }
    >
      Liabilities & Statements
    </Navbar.Link>
  );
};

LiabilitiesAndStatementsLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object
};

export default LiabilitiesAndStatementsLink;
