import React from "react";
import PropTypes from "prop-types";
import PageStateMessage from "../../../shared/components/page-state-message";
const NoResults = ({ headline, description }) => /* @__PURE__ */ React.createElement(PageStateMessage, {
  iconProps: { name: "emptySet" },
  headline,
  description
});
NoResults.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string
};
NoResults.defaultProps = {
  headline: "No results found",
  description: "Try adjusting your search or filter to find what you're looking for"
};
export default NoResults;
