import React from 'react';
import {
  FilterOptionLi,
  Label,
  LeftContainer,
  DivWithWidthRestrictedBorder,
  Icon
} from '../styles';
const FilterOption = ({ option, search, onSelect, iconClasses }) => {
  return (
    <>
      <FilterOptionLi onClick={() => onSelect(option)}>
        <LeftContainer>
          <Icon className={iconClasses} />
          <span>{search}</span>
        </LeftContainer>
        <Label>{option.label}</Label>
      </FilterOptionLi>
      <DivWithWidthRestrictedBorder />
    </>
  );
};
export default FilterOption;
