import React from 'react';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ajaxDataWithAuthorization } from 'apiClient';
import PortalModal from 'sharedComponents/PortalModal';
import Loadable from 'sharedComponents/Loadable';
import BookingSelect from 'adminComponents/Selects/BookingSelect';
import ManualBookingForm from 'adminComponents/ManualBookingForm';

import { OrganizationContext } from 'adminContexts';

const conversionTypeOptions = [
  { label: 'New Booking', value: 'new_booking' },
  { label: 'Existing Booking', value: 'existing_booking' }
];

const initialState = {
  conversionType: null,
  quote_id: null,
  booking_id: null,
  loading: false
};

const stringFormattedDate = date => {
  let momentDate = date;

  if (typeof date === 'string') {
    momentDate = moment(date);
  }

  return momentDate.format('DD-MM-YYYY');
};

export default class OpportunityConvertedModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  onCancel = () => {
    this.props.afterComplete({});
    this.setState(initialState);
  };

  onSubmit = () => {
    if (!this.state.quote_id) {
      toast.error(
        'Please select or create a valid booking for this opportunity.'
      );
      return;
    }

    if (this.state.booking_id) {
      const { booking_id, quote_id, unit_id } = this.state;

      this.setState(initialState, () => {
        this.props.afterComplete({
          booking_id,
          quote_id,
          unit_id,
          status: 'converted'
        });
      });
      return;
    }

    if (
      !this.state.listing_id ||
      this.state.listing_id === '' ||
      !this.state.unit_id ||
      this.state.unit_id === ''
    ) {
      toast.error('Please select a valid listing.');
      return;
    }

    this.setState({ loading: true }, () => {
      $.ajax(
        ajaxDataWithAuthorization({
          type: 'POST',
          url: `/api/${this.context.organizationId}/bookings`,
          context: this,
          dataType: 'json',
          data: {
            booking_type: this.state.booking_type,
            check_in: stringFormattedDate(this.state.check_in),
            check_out: stringFormattedDate(this.state.check_out),
            confirmed: this.state.confirmed,
            customer_email: this.state.customer_email,
            customer_name: this.state.customer_name,
            customer_telephone: this.state.customer_telephone,
            guests: parseInt(this.state.guests),
            listing_id: parseInt(this.state.listing_id),
            total: parseFloat(this.state.total),
            channel_id: parseInt(this.state.channel_id),
            sendConfirmation: this.state.sendConfirmation,
            quote_id: this.state.quote_id
          }
        })
      )
        .done(data => {
          this.setState(initialState, () => {
            this.props.afterComplete({
              booking_id: data.id,
              quote_id: data.quote_id,
              unit_id: data.unit_id,
              status: 'converted'
            });
          });
        })
        .fail(jqXhr => {
          toast.error(jqXhr.responseJSON.error);
          this.onCancel();
        });
    });
  };

  renderModalContent = () => {
    if (this.state.loading) return;
    if (!this.state.conversionType) return null;
    if (this.state.conversionType === 'existing_booking') {
      return (
        <BookingSelect
          onSelect={({ id, quote_id, unit_id }) =>
            this.setState({ booking_id: id, quote_id, unit_id })
          }
          placeholder={'Search by code or customer...'}
          searchableKeys={['booking_code', 'name', 'email']}
          defaultSelectData={{
            joins: [
              'INNER JOIN customers ON bookings.customer_id = customers.id',
              'INNER JOIN unit_listings on bookings.unit_listing_id = unit_listings.id'
            ],
            select: [
              'id',
              'booking_code',
              'quote_id',
              'unit_listings.unit_id as unit_id',
              'customers.name as name',
              'customers.email as email'
            ]
          }}
        />
      );
    }

    return (
      <ManualBookingForm
        organization={this.context.organization}
        initialQuoteId={this.props.opportunity.quote_id}
        bookingType={this.props.opportunity.booking_type}
        afterQuoteUpdate={updates => this.setState(updates)}
        customer_id={this.props.opportunity.customer_id}
      />
    );
  };

  render() {
    if (!this.props.opportunity) return null;

    return (
      <PortalModal
        header={'Convert Opportunity'}
        modalStyles={{
          textAlign: 'unset',
          position: 'relative',
          width: '560px',
          height:
            this.state.conversionType === 'new_booking' ? '560px' : '344px',
          transition: 'height 1s'
        }}
        submitAction={
          <a className={'button'} onClick={this.onSubmit}>
            Submit
          </a>
        }
        cancelAction={_closePortal => (
          <a
            className={'button negative'}
            onClick={this.onCancel}
            style={{ marginRight: '8px' }}
          >
            Cancel
          </a>
        )}
        defaultOpen
        disableCloseOnOutsideClick
        hideX
        actionStyles={{
          display: 'flex',
          flexDirection: 'row-reverse',
          position: 'absolute',
          bottom: '16px',
          right: '16px'
        }}
      >
        <Loadable loading={this.state.loading}>
          <label>Conversion Type</label>
          <InputSelect
            name={'conversion_type'}
            placeholder={'Choose the conversion type...'}
            options={conversionTypeOptions}
            onChange={option =>
              this.setState({
                ...initialState,
                ...{ conversionType: option.value }
              })
            }
          />
          <div style={{ marginBottom: '16px' }} />
          {this.renderModalContent()}
          <div style={{ marginBottom: '16px' }} />
        </Loadable>
      </PortalModal>
    );
  }
}

OpportunityConvertedModal.contextType = OrganizationContext;
