import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const RvShareLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags,
  label,
  variation,
  organization
}) => {
  const [showVehicleLink, setShowVehicleLink] = useState(false);

  useEffect(() => {
    setShowVehicleLink(organization.organization_type === 'rv_fleet');
  }, []);

  return showVehicleLink ? (
    <Navbar.Link
      href="/channels/rvshare"
      variation={variation}
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={activeRoutes.secondary === navTags.secondary.RVSHARE}
    >
      {label}
    </Navbar.Link>
  ) : (
    <></>
  );
};

RvShareLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object,
  label: PropTypes.string,
  variation: PropTypes.oneOf(['primary', 'secondary', 'featured'])
};

RvShareLink.defaultProps = {
  label: 'RVshare',
  variation: 'secondary'
};

export default RvShareLink;
