import React, { useEffect, useState } from 'react';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import { useDetectMobile } from 'sharedHooks';
import PermissionComponent from 'PermissionComponent';
import {
  DISTRIBUTION_NAV_TAGS,
  MESSAGING_NAV_TAGS,
  PEOPLE_NAV_TAGS,
  WEBSITE_NAV_TAGS,
  WORKFLOWS_NAV_TAGS
} from 'sharedNav';
import { checkPermissions } from 'sharedNav/helpers';
import WebsiteEditorLink from 'adminNavbar/site-editor/links/website-editor-link';
import PeopleLinks from './link-menus/people/links-desktop';
import WorkflowsLinks from './link-menus/workflows/links-desktop';
import DistributionLinks from './link-menus/distribution/links-desktop';
import MessagingLinks from './link-menus/messaging/links-mobile';

const DefaultLeftMoreLink = props => {
  const { isSuperHD } = useDetectMobile();
  const [showPanel, setShowPanel] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const closeMenuPanel = () => {
    setShowPanel(false);
  };

  /**
   * Check all relevant permissions here to decide whether to show the MORE menu at all
   */
  useEffect(() => {
    const totalChecks = 6;
    let failedChecks = 0;

    if (!checkPermissions(props.user, 'view_distribution')) failedChecks++;
    if (!checkPermissions(props.user, 'view_workflows')) failedChecks++;
    if (!checkPermissions(props.user, 'view_people')) failedChecks++;
    if (!checkPermissions(props.user, 'site_editor_auto_emails'))
      failedChecks++;
    if (!checkPermissions(props.user, 'view_site_editor')) failedChecks++;
    if (!checkPermissions(props.user, 'ledger')) failedChecks++;

    if (failedChecks === totalChecks) setShowMenu(false);
  }, []);

  return showMenu ? (
    <Navbar.LinkMenu
      primaryLinkText={
        props.navTags.PRIMARY_EXTENDED.includes(props.activeRoutes.primary)
          ? `More: ${props.activeRoutes.primary}`
          : 'More'
      }
      primaryLinkProps={{
        isActive:
          props.activeRoutes.primary === props.navTags.PRIMARY ||
          props.navTags.PRIMARY_EXTENDED.includes(props.activeRoutes.primary),
        onClick: () => setShowPanel(!showPanel)
      }}
      onPanelClickOutside={() => setShowPanel(false)}
      showMenuPanel={showPanel}
      alignPanel={isSuperHD ? 'left' : 'center'}
    >
      <PermissionComponent user={props.user} permission="view_distribution">
        <DistributionLinks
          {...props}
          closeMenuPanel={closeMenuPanel}
          navTags={DISTRIBUTION_NAV_TAGS}
        />
      </PermissionComponent>
      <PermissionComponent user={props.user} permission="view_workflows">
        <WorkflowsLinks
          {...props}
          closeMenuPanel={closeMenuPanel}
          navTags={WORKFLOWS_NAV_TAGS}
        />
      </PermissionComponent>
      <PermissionComponent user={props.user} permission="view_people">
        <PeopleLinks
          {...props}
          closeMenuPanel={closeMenuPanel}
          navTags={PEOPLE_NAV_TAGS}
        />
      </PermissionComponent>
      {(props.user.permissions.ledger ||
        props.user.permissions.view_site_editor ||
        props.user.permissions.site_editor_auto_emails) && (
        <Navbar.LinkMenuColumn>
          <PermissionComponent
            user={props.user}
            permission="site_editor_auto_emails"
          >
            <Navbar.LinkMenuGroup groupTitle="Messaging">
              <MessagingLinks
                {...props}
                closeMenuPanel={closeMenuPanel}
                navTags={MESSAGING_NAV_TAGS}
              />
            </Navbar.LinkMenuGroup>
          </PermissionComponent>
          {(props.user.permissions.ledger ||
            props.user.permissions.view_site_editor) && (
            <Navbar.LinkMenuGroup groupTitle="Other Features">
              <PermissionComponent
                user={props.user}
                permission="view_site_editor"
              >
                <WebsiteEditorLink
                  {...props}
                  closeMenuPanel={closeMenuPanel}
                  navTags={WEBSITE_NAV_TAGS}
                />
              </PermissionComponent>
            </Navbar.LinkMenuGroup>
          )}
        </Navbar.LinkMenuColumn>
      )}
    </Navbar.LinkMenu>
  ) : (
    <></>
  );
};

export default DefaultLeftMoreLink;
