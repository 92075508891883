import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const EmailsLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags,
  label,
  variation,
  organization,
  brands
}) => {
  const [showVehicleLink, setShowVehicleLink] = useState(false);

  useEffect(() => {
    setShowVehicleLink(organization.organization_type === 'rv_fleet');
  }, []);

  return showVehicleLink ? (
    <Navbar.Link
      href={`/messaging/direct-system-generated-auto-emails`}
      variation={variation}
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={activeRoutes.secondary === navTags.secondary.SYSTEM_EMAILS}
    >
      {label}
    </Navbar.Link>
  ) : (
    <></>
  );
};

EmailsLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object,
  label: PropTypes.string,
  variation: PropTypes.oneOf(['primary', 'secondary', 'featured'])
};

EmailsLink.defaultProps = {
  label: 'RVshare',
  variation: 'secondary'
};

export default EmailsLink;
