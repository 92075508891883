import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  CallToActionButton,
  IconButton,
  IconFontAwesome,
  Modal,
  PageHeader
} from '@directsoftware/ui-kit-web-admin';
import queryString from 'query-string';
import { StripeConnectAccountService } from 'adminApi';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { ACCOUNTING_NAV_TAGS } from '../../../../../shared/nav';

const BankAccountsHome = props => {
  const dispatch = useDispatch();
  const [stripeQueryString, setStripeQueryString] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const redirectToStripeOauth = () => {
    window.location = `https://connect.stripe.com/oauth/authorize?${stripeQueryString}`;
  };

  const type =
    props.organization.organization_type === 'rv_fleet' ? 'vehicle' : 'unit';

  const bankAccountText = {
    stripe: (
      <div>
        <p>
          You can securely add your Trust Accounts (aka escrow accounts) here.
          Once added, these connected bank accounts can be tied to your
          Portfolios so that funds flowing in from reservations (for any {type}{' '}
          in the associated portfolio) are deposited into said account.
        </p>
        <p>
          The system will also withdraw funds from these accounts and deposit
          the funds into the connected bank accounts for any owner, vendor, or
          contractor when you initiate a payout.
        </p>
        <p>
          NOTE: The first bank account you add will be considered your Default
          Account. If no additional bank accounts are added or for any
          transactions not associated with a portfolio, the funds will flow
          through this account.
        </p>
        <p>
          If you have any questions, you can live chat us in the bottom right.
        </p>
      </div>
    ),
    lynnbrook: (
      <div>
        <p>
          Your trust accounts (aka escrow accounts) are listed here. Once added,
          these connected bank accounts can be tied to your Portfolios so that
          funds flowing in from reservations (for any {type} in the associated
          portfolio) are deposited into said account.
        </p>
        <p>
          If you connect your trust accounts to Checkbook.io for payouts, the
          system will also withdraw funds from these accounts and send funds to
          any owner, vendor, or contractor through a digital check when you
          initiate a payout.
        </p>
        <p>
          NOTE: The first bank account you add will be considered your Default
          Account. If no additional bank accounts are added or for any
          transactions not associated with a portfolio, the funds will flow
          through this account.
        </p>
        <p>
          If you have any questions, you can live chat us in the bottom right.
        </p>
      </div>
    )
  };

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: ACCOUNTING_NAV_TAGS.PRIMARY,
        secondary: ACCOUNTING_NAV_TAGS.secondary.BANK_ACCOUNTS
      })
    );

    StripeConnectAccountService.getConnectObject(props.organization.id).then(
      response => {
        setStripeQueryString(queryString.stringify(response.params));
      }
    );
  }, []);

  return (
    <>
      <PageHeader>
        <PageHeader.Left>
          Accounting: <span>Bank Accounts</span>
        </PageHeader.Left>
        <PageHeader.Right>
          <IconButton
            onClick={() => setOpenModal(true)}
            variation="secondary"
            appearance="ghost"
          >
            <IconFontAwesome name="questionCircle" />
          </IconButton>
          {props.organization.payment_processor === 'stripe' && (
            <CallToActionButton
              onClick={() => redirectToStripeOauth()}
              prependIcon={<IconFontAwesome name="plus" />}
            >
              Add Account
            </CallToActionButton>
          )}
        </PageHeader.Right>
      </PageHeader>
      <Modal
        reveal={openModal}
        noHeader
        closeOnClick={() => setOpenModal(false)}
      >
        <Modal.Content>
          <Box padding="s">
            {bankAccountText[props.organization.payment_processor]}
          </Box>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default BankAccountsHome;
