// Dependencies
// -----------------------------------------------
import React from 'react';
import map from 'lodash/map';
import compact from 'lodash/compact';
import indexOf from 'lodash/indexOf';

const LocationViewingField = props => {
  const buildAddress = () => {
    const addressParts = [
      'adr_street',
      'adr_unit',
      'adr_city',
      'adr_state',
      'adr_country',
      'adr_postal_code'
    ];

    return compact(
      map(addressParts, part => {
        const adr_part = props[part];
        if (!adr_part) return false;

        if (indexOf(['adr_city', 'adr_country'], part) !== -1) {
          return `<br/>${adr_part}`;
        }

        return adr_part;
      })
    ).join(', ');
  };

  const address = buildAddress();

  return (
    <div className="consolidated-item-subsection">
      <address dangerouslySetInnerHTML={{ __html: address }} />
      {address !== '' ? (
        <a
          href={`https://www.google.com/maps/?q=${encodeURIComponent(address)}`}
          target="_blank"
        >
          View on map
        </a>
      ) : (
        'Not Set'
      )}
    </div>
  );
};

export default LocationViewingField;
