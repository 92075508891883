import React, { useEffect } from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';

const PropertiesBulkEdit = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: LISTINGS_NAV_TAGS.PRIMARY,
        secondary: LISTINGS_NAV_TAGS.secondary.PROPERTIES_BULK_EDIT
      })
    );
  }, []);

  return (
    <PageHeader containerVariation="fluid" height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/properties',
            label: 'Listings: Properties'
          },
          {
            label: 'Bulk Edit'
          }
        ]}
      >
        Bulk Edit: <span>Properties</span>
      </PageHeader.Left>
    </PageHeader>
  );
};

export default PropertiesBulkEdit;
