const StatusOptions = [
  { label: "New", value: "no_action" },
  { label: "Contacted", value: "contacted" },
  { label: "Follow Up", value: "follow_up" },
  { label: "Converted", value: "converted" },
  { label: "Not Converted", value: "not_converted" }
];
const TagOptions = [
  { label: "VIP", value: "vip" },
  { label: "Blacklisted", value: "blacklisted" }
];
const BookingTypeOptions = [
  { label: "Guest Reservation", value: "guest" },
  { label: "Owner Block", value: "owner" },
  { label: "Maintence Block", value: "maintence" },
  { label: "Admin Block", value: "admin" },
  { label: "Friend of Owner", value: "friend" },
  { label: "Comped Guest", value: "comp" }
];
export { StatusOptions, TagOptions, BookingTypeOptions };
