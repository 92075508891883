import React from 'react';
import { InputSelect, Spacer, Label } from '@directsoftware/ui-kit-web-admin';
import QuoteService from 'adminApi/QuoteService';
import displayError from 'sharedComponents/ErrorDisplay';
import IntegerSelect from 'adminComponents/Selects/IntegerSelect';
import DeliveryLocationForm from '../../pages/vehicles/atoms/DeliveryLocationForm';

const centsToDollarString = cents => {
  return (cents / 100.0).toFixed(2);
};

export default class QuoteLineItemCreator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      possibleItems: [],
      selectedItem: {},
      numFees: 1
    };
  }

  componentDidMount() {
    if (this.props.lineItemType === 'fees') {
      QuoteService.possibleFeeItems(
        this.props.organizationID,
        this.props.quote.id,
        false
      )
        .then(response => {
          this.setState({ possibleItems: response.fees });
        })
        .catch(err =>
          displayError({ message: 'Error getting fee items', err })
        );
    } else if (this.props.lineItemType === 'taxes') {
      QuoteService.possibleTaxItems(
        this.props.organizationID,
        this.props.quote.id
      )
        .then(response => {
          this.setState({ possibleItems: response.taxes });
        })
        .catch(err =>
          displayError({ message: 'Error getting tax items', err })
        );
    } else if (this.props.lineItemType === 'delivery locations') {
      QuoteService.possibleFeeItems(
        this.props.organizationID,
        this.props.quote.id
      )
        .then(response => {
          this.setState({ possibleItems: response.delivery_fees });
        })
        .catch(err =>
          displayError({ message: 'Error getting delivery locations', err })
        );
    }
  }

  createLineItem = () => {
    const {
      name,
      rate,
      total_cents,
      taxable,
      item_type,
      refundable,
      optional,
      split,
      cancellation,
      additional_data,
      debit_account_id,
      credit_account_id,
      owner_split_id
    } = { ...this.state.selectedItem };

    // makes sure we send the correct amount for a quantity based fee
    let adjustedTotal = total_cents;
    let adjustedAddlData = additional_data;
    if (this.state.numFees > 1) {
      const { numFees } = this.state;
      adjustedTotal = adjustedTotal * numFees;
      adjustedAddlData = { ...additional_data, default_calculation_amount: additional_data.default_calculation_amount * numFees };
    }
    QuoteService.createLineItem(this.props.organizationID, {
      name: name,
      rate: rate,
      total_cents: adjustedTotal,
      taxable: taxable,
      item_type: item_type,
      refundable: refundable,
      optional: optional,
      split: split,
      cancellation: cancellation,
      additional_data: adjustedAddlData,
      quote_id: this.props.quote.id,
      credit_account_id: credit_account_id,
      debit_account_id: debit_account_id,
      owner_split_id
    })
      .then(response => {
        this.props.afterCreate && this.props.afterCreate(response.quote);
      })
      .catch(err =>
        displayError({ message: 'Error creating line item', err })
      )
  };

  updateSelectedItem = option => {
    this.setState({ selectedItem: option.data });
  };

  cancel = () => {
    this.props.onCancel && this.props.onCancel();
  };

  onChange = value => {
    this.setState({ numFees: value });
  };

  render() {
    const options = this.state.possibleItems.map(item => ({
      label: `${item.name} (${centsToDollarString(item.total_cents)})`,
      value: item.id,
      data: item
    }));

    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 'auto', marginRight: '8px' }}>
            {this.props.lineItemType === 'delivery locations' && <>
              <DeliveryLocationForm
                setLocationAttributes={values => {
                  const address_info = {}
                  Object.keys(values).forEach(key => { address_info[key.replace('adr', 'adr_').toLowerCase()] = values[key] })
                  const option = {
                    data: { id: -1, name: values.name, total_cents: 0, item_type: 'delivery_fees', additional_data: { default_calculation_amount: 0, frequency_at_creation: "per_stay", included_in_base_rent: false, los_ranges_at_creation: [], manually_added: true, address_info: address_info } },
                    label: values.name,
                    value: -1
                  };
                  return this.updateSelectedItem(option);
                }}
                customLabel="Custom Delivery Location"
              />
              <Spacer size="xs" />
              <Label>Preset Delivery Location</Label>
            </>
            }
            <InputSelect
              name="line_item"
              className="magnified"
              options={options}
              isClearable={false}
              isSearchable={false}
              placeholder={`Select one of these ${this.props.lineItemType}`}
              onChange={this.updateSelectedItem}
            />
          </div>
          <div
            style={{ marginLeft: '8px', marginRight: '8px', minWidth: '75px' }}
          >
            {this.props.lineItemType != 'delivery locations' && <IntegerSelect
              name={'num_fees'}
              minInt={1}
              maxInt={24}
              value={this.state.numFees}
              onChange={option => this.onChange(option.value)}
            />}
          </div>
          <a onClick={this.cancel}>Cancel</a>
        </div>
        <a
          onClick={this.createLineItem}
          className={'button'}
          style={{ margin: '16px 0' }}
        >
          Add Item
        </a>
      </div>
    );
  }
}
