import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const EmployeesLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags,
  variation
}) => {
  return (
    <Navbar.Link
      href="/employees"
      variation={variation}
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={activeRoutes.secondary === navTags.secondary.EMPLOYEES}
    >
      Users
    </Navbar.Link>
  );
};

EmployeesLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object,
  variation: PropTypes.oneOf(['primary', 'secondary', 'featured'])
};

EmployeesLink.defaultProps = {
  variation: 'primary'
};

export default EmployeesLink;
