import React from 'react';
import DirectTable from './direct-table';

export const WrappedDirectTable = CustomFilterBar => {
  const TableWithCustomFilter = props => {
    return (
      <div>
        <CustomFilterBar {...props} />
        <DirectTable
          data={props.data}
          pages={props.pages}
          loading={props.loading}
          defaultPageSize={props.defaultPageSize}
          onFetchData={props.onFetchData}
          organization={props.organization}
          columns={props.columns}
          paginateOnly={props.paginateOnly}
          {...props}
        />
      </div>
    );
  };
  return TableWithCustomFilter;
};
