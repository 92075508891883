import React from 'react';
import ReservationsListLink from 'adminNavbar/reservations/links/reservations-list-link';
import ReservationsReviewsLink from 'adminNavbar/reservations/links/reservations-reviews-link';
import ReservationsVehiclesCalendarLink from 'adminNavbar/reservations/links/reservations-vehicles-calendar-link';

const LinksMobile = props => {
  return (
    <>
      <ReservationsListLink {...props} />
      <ReservationsVehiclesCalendarLink {...props} />
      <ReservationsReviewsLink {...props} />
    </>
  );
};

export default LinksMobile;
