// Dependencies
// -----------------------------------------------
import React from 'react';
import Geosuggest from 'react-geosuggest';
import Script from 'react-load-script';

// Components
// -----------------------------------------------
import { Spinner } from 'adminComponents';
import {
  FormField,
  FlexBox,
  Box,
  Label,
  Spacer
} from '@directsoftware/ui-kit-web-admin';

export default class LocationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      adrStreet: this.props.adrStreet,
      adrUnit: this.props.adrUnit,
      adrCity: this.props.adrCity,
      adrState: this.props.adrState,
      adrCountry: this.props.adrCountry,
      adrPostalCode: this.props.adrPostalCode,
      adrCountryShort: this.props.adrCountryShort,
      mapsLoaded: false,
      mapsLoading: true,
      setGeoInputFocused: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  handleMapScriptError = () => {
    this.setState({
      mapsLoaded: false,
      mapsLoading: false
    });
  };

  handleMapScriptLoad = () => {
    this.setState({
      mapsLoaded: true,
      mapsLoading: false
    });
  };

  findAddressPart = (adrComponents, adr_part) => {
    return adrComponents.find(e => {
      return e.types[0] === adr_part;
    });
  };

  updateOnSuggestSelected = suggest => {
    try {
      const adrComponents = suggest.gmaps.address_components;
      const adrPostalCode = this.findAddressPart(adrComponents, 'postal_code');
      const adrCountry = this.findAddressPart(adrComponents, 'country');
      const adrState = this.findAddressPart(
        adrComponents,
        'administrative_area_level_1'
      );
      let adrCity = this.findAddressPart(adrComponents, 'locality');
      if (adrCity === undefined) {
        adrCity = this.findAddressPart(adrComponents, 'postal_town');
      }
      const adrStreetName = this.findAddressPart(adrComponents, 'route');
      const adrStreetNumber = this.findAddressPart(
        adrComponents,
        'street_number'
      );

      this.setState({
        adrStreet: `${adrStreetNumber ? adrStreetNumber.long_name : ''} ${
          adrStreetName ? adrStreetName.long_name : ''
        }`,
        adrUnit: '',
        adrCity: adrCity ? adrCity.long_name : '',
        adrState: adrState ? adrState.long_name : '',
        adrCountry: adrCountry ? adrCountry.long_name : '',
        adrPostalCode: adrPostalCode ? adrPostalCode.long_name : '',
        adrCountryShort: adrCountry ? adrCountry.short_name : ''
      });

      this.adrGeoSuggest.update(this.state.adrStreet);

      this.props.setLocationAttributes(this.returnValues());
    } catch (e) {
      this.setState({
        adrStreet: ''
      });
    }
  };

  updateOnChange = e => {
    const stateChange = {};
    stateChange[e.target.name] = e.target.value;
    this.setState(stateChange, () => {
      this.props.setLocationAttributes(this.returnValues());
    });
  };

  returnValues = () => {
    const values = this.state;
    delete values.mapsLoaded;
    delete values.mapsLoading;
    delete values.setLocationAttributes;

    return values;
  };

  renderGeoSuggestedAddress = () => {
    const {
      adrStreet,
      adrUnit,
      adrCity,
      adrState,
      adrCountry,
      adrPostalCode
    } = this.state;
    return (
      <div>
        {this.state.mapsLoaded ? (
          <>
            <Label markRequiredField={this.props.markRequiredFields}>
              Address Line 1
            </Label>
            <Geosuggest
              placeholder="123 Road St."
              types={['geocode']}
              suggestsHiddenClassName="hidden"
              initialValue={adrStreet}
              ref={node => {
                this.adrGeoSuggest = node;
              }}
              onSuggestSelect={this.updateOnSuggestSelected}
              className={`newOrganization__textInputWrapper${
                this.state.setGeoInputFocused ? ' focused' : ''
              }`}
              inputClassName="newOrganization__textInput"
              suggestsClassName="newOrganization__suggestsWrapper"
              suggestItemClassName="newOrganization__suggestItem"
              onFocus={() => this.setState({ setGeoInputFocused: true })}
              onBlur={() => this.setState({ setGeoInputFocused: false })}
            />
          </>
        ) : (
          <FormField
            labelText="Address Line 1"
            labelHtmlFor="adrStreet"
            labelMarkRequiredField={this.props.markRequiredFields}
            inputProps={{
              value: adrStreet,
              placeholder: '123 Road St.',
              onChange: this.updateOnChange,
              removeBottomSpacer: true,
              name: 'adrStreet'
            }}
          />
        )}
        <Spacer size="xs" />
        <Spacer size="xxs" />
        <FormField
          labelText="Address Line 2"
          labelHtmlFor="adrUnit"
          inputProps={{
            value: adrUnit,
            placeholder: 'Ste. 1000',
            onChange: this.updateOnChange,
            description: 'Optional',
            name: 'adrUnit'
          }}
        />
        <FlexBox gap="xs">
          <Box flex="1">
            <FormField
              labelText="City"
              labelHtmlFor="adrCity"
              labelMarkRequiredField={this.props.markRequiredFields}
              inputProps={{
                placeholder: 'Chicago',
                value: adrCity,
                onChange: this.updateOnChange,
                name: 'adrCity'
              }}
            />
          </Box>
          <Box>
            <FormField
              labelText="State/Province"
              labelHtmlFor="adrState"
              labelMarkRequiredField={this.props.markRequiredFields}
              inputProps={{
                placeholder: 'IL',
                value: adrState,
                onChange: this.updateOnChange,
                inputWidth: 's',
                name: 'adrState'
              }}
            />
          </Box>
          <Box>
            <FormField
              labelText="Postal Code"
              labelHtmlFor="adrPostalCode"
              labelMarkRequiredField={this.props.markRequiredFields}
              inputProps={{
                placeholder: '60622',
                value: adrPostalCode,
                onChange: this.updateOnChange,
                inputWidth: 's',
                name: 'adrPostalCode'
              }}
            />
          </Box>
        </FlexBox>
        <FormField
          labelText="Country"
          labelHtmlFor="adrCountry"
          inputProps={{
            placeholder: this.props.defaultCountry || `United States`,
            value: adrCountry,
            onChange: this.updateOnChange,
            name: 'adrCountry'
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <fieldset>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyAPRfOoJzqa0XyJWMg-oU7CeaMpNbxkOaE&libraries=places"
          onError={this.handleMapScriptError.bind(this)}
          onLoad={this.handleMapScriptLoad.bind(this)}
        />
        {this.state.mapsLoading ? (
          <Spinner />
        ) : (
          this.renderGeoSuggestedAddress()
        )}
      </fieldset>
    );
  }
}
