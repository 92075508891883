import React from 'react';
import { Provider } from 'react-redux';
import store from '../../../redux/store';
import CreateOrganization from './create-organization';

const CreateOrganizationWithReduxWrapper = props => {
  return (
    <Provider store={store}>
      <CreateOrganization {...props} />
    </Provider>
  );
};

export default CreateOrganizationWithReduxWrapper;
