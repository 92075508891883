import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const SystemEmailsLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags
}) => {
  return (
    <Navbar.Link
      href="/messaging/direct-system-generated-auto-emails"
      variation="secondary"
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={activeRoutes.secondary === navTags.secondary.SYSTEM_EMAILS}
    >
      Direct System Emails
    </Navbar.Link>
  );
};

SystemEmailsLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object
};

export default SystemEmailsLink;
