import React from 'react';
import PropTypes from 'prop-types';
import ConfirmLink from 'sharedComponents/ConfirmLink';
import { dispatchError } from '../../../components/error-alert';
import { ajaxDataWithAuthorization } from 'apiClient';

export default class ManageOrganizationDelete extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired
  };
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      can_delete: false,
      input_name: '',
      destroying: false
    };
  }

  checkNameMatch = e => {
    const value = e.target.value;
    const valueIsName =
      value.toLowerCase() === this.props.organization.name.toLowerCase();
    let stateChange = {};
    stateChange['input_name'] = value;
    stateChange['can_delete'] = valueIsName;
    this.setState(stateChange);
  };

  deleteOrganization = () => {
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'DELETE',
        url: '/api/organizations/' + this.props.organization.id,
        context: this,
        dataType: 'json'
      })
    )
      .done(function(data) {
        this.setState({destroying: true, can_delete: false})
      })
      .fail(function(jqXhr) {
        dispatchError();
        console.warn(jqXhr);
      });
  };

  render() {
    return (
      <figure id="section-delete" className={`consolidated-item-form-section`}>
        <header className="consolidated-item-header">
          <h3>Delete organization</h3>
        </header>
        <section className="consolidated-item-form-viewing">
          <p>
            Deleting your organization will also delete all information
            connected to the organization. Please validate that this is the
            action you want to take by typing this organization's name below.
            <h2 style={{ paddingTop: '5px', paddingBottom: '5px' }}>
              {this.props.organization.name}
            </h2>
          </p>
          <label>
            <span>Organization name</span>
          </label>
          <input
            className="magnified"
            onChange={this.checkNameMatch}
            type="text"
            value={this.state.input_name}
          />
          {this.state.can_delete ? (
            <ConfirmLink
              action={this.deleteOrganization}
              actionArgs={null}
              confirmMessage={`Are you sure you want to delete this organization? This cannot be undone, and all brand, listing, employee, and reservation data will be deleted.`}
              confirmText="Yes"
              cancelText="No"
            >
              <a className="button negative">Delete</a>
            </ConfirmLink>
          ) : (
            <a className="button disabled">Delete</a>
          )}
          {this.state.destroying && <div>The process of deleting has begun. Please allow 10 minutes for this process to complete. If the organization has not been removed after that, please contact an engineer. <a href={'/my-organizations'}>Click here to return to organization page.</a></div>}
        </section>
      </figure>
    );
  }
}
