import request from "apiClient";
function list(orgId, pageSize, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/teams`,
    method: "GET",
    params: {
      limit: pageSize,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.teams,
      pages: Math.ceil(response.count / pageSize)
    };
  });
}
function create(orgId, params) {
  return request({
    url: `/api/${orgId}/teams`,
    method: "POST",
    data: params
  });
}
function get(orgId, teamId) {
  return request({
    url: `/api/${orgId}/teams/${teamId}`,
    method: "GET"
  });
}
function users(orgId, teamId, pageSize, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/teams/${teamId}/users`,
    method: "GET",
    params: {
      limit: pageSize,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.users,
      pages: Math.ceil(response.count / pageSize)
    };
  });
}
function addUser(orgId, teamId, userId) {
  return request({
    url: `/api/${orgId}/teams/${teamId}/add_user`,
    method: "POST",
    data: { employee_id: userId }
  });
}
function unlinked(orgId, teamId, pageSize, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/teams/${teamId}/unlinked`,
    method: "GET",
    params: {
      limit: pageSize,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.users,
      pages: Math.ceil(response.count / pageSize)
    };
  });
}
function update(orgId, teamId, data) {
  return request({
    url: `/api/${orgId}/teams/${teamId}`,
    method: "PUT",
    data
  });
}
const TeamService = {
  get,
  list,
  update,
  create,
  addUser,
  users,
  unlinked
};
export default TeamService;
