"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOrganizationContext = void 0;
const react_1 = require("react");
const adminContexts_1 = require("adminContexts");
function useOrganizationContext() {
    const context = (0, react_1.useContext)(adminContexts_1.OrganizationContext);
    if (context === undefined) {
        throw new Error('useOrganizationContext must be used within an OrganizationProvider');
    }
    return context;
}
exports.useOrganizationContext = useOrganizationContext;
