import React from 'react';

export default class InputPercent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || '',
      symbol: this.props.symbol || '%',
      min: this.props.min || 0,
      max: this.props.max || 100
    };
  }

  sanitize = e => {
    if (!isNaN(parseFloat(e.target.value))) {
      const sanitizedValue = parseFloat(e.target.value);
      if (sanitizedValue < this.state.min) {
        this.setState({ value: this.state.min }, () => {
          this.props.setPercent(this.state.value);
        });
      } else if (sanitizedValue > this.state.max) {
        this.setState({ value: this.state.max }, () => {
          this.props.setPercent(this.state.value);
        });
      }
    }
  };

  setPercent = e => {
    e.preventDefault();
    this.setState({ value: e.target.value }, () => {
      this.props.setPercent(this.state.value);
    });
  };

  render() {
    return (
      <figure className="input-percent" style={this.props.containerStyle}>
        <div>
          <label htmlFor={this.props.name}>
            <span>{this.props.label}</span>
          </label>
          <div className="input-with-immutable-label">
            <input
              type="number"
              name={this.props.name}
              className="magnified"
              min={this.state.min}
              max={this.state.max}
              placeholder={this.props.placeholder || '0'}
              value={this.state.value}
              onChange={e => this.setPercent(e)}
              onBlur={e => this.sanitize(e)}
              style={this.props.styles}
              disabled={this.props.disabled || false}
            />
            <span style={this.props.symbolStyles || { fontSize: '14px' }}>
              {this.state.symbol}
            </span>
          </div>
        </div>
      </figure>
    );
  }
}
