// Dependencies
// -----------------------------------------------
import React from 'react';
import { OrganizationContext } from 'adminContexts';
import { InlineTip } from 'adminComponents';
import { InputDate, Label } from '@directsoftware/ui-kit-web-admin';

export default class DefaultSingleDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      date: props.initialDate
    };
  }

  onDateChange = date => {
    this.setState({ date });
  };

  onFocusChange = ({ focused }) => {
    this.setState({ focused }, () => {
      if (this.state.date && !this.state.focused) {
        this.props.onDateSet(this.state.date);
      }
    });
  };

  onDateUnset = () => {
    this.setState({ date: null }, () => {
      this.props.onDateSet(null);
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.label || this.props.tip && (
          <div
            style={
              this.props.tip && { display: 'flex', justifyContent: 'flex-start' }
            }
          >
            {this.props.label && (
              <Label markRequiredField={this.props.required}>
                {this.props.label}
              </Label>
            )}
            {this.props.tip && (
              <InlineTip tip={this.props.tip} alwaysFullscreen />
            )}
          </div>
        )}
        <InputDate
          onDateChange={this.onDateChange}
          onFocusChange={this.onFocusChange}
          anchorDirection={this.props.anchorDirection}
          placeholder={this.props.startPlaceholder}
          focused={this.state.focused}
          date={this.state.date}
          displayFormat={this.context.dateFormat()}
          id={this.props.id}
          hideKeyboardShortcutsPanel
          numberOfMonths={this.props.numberOfMonths}
          renderCalendarDay={this.props.renderCalendarDay}
          isOutsideRange={this.props.isOutsideRange}
          isDayBlocked={this.props.isDayBlocked}
          inputWidth={this.props.inputWidth}
        />
        {this.props.renderUnsetLink(this.onDateUnset)}
      </React.Fragment>
    );
  }
}

DefaultSingleDatePicker.defaultProps = {
  isOutsideRange: () => false,
  isDayBlocked: () => false,
  renderUnsetLink: func => null,
  id: 'default_single_date_picker_id',
  placedholder: 'Date',
  numberOfMonths: 1
};

DefaultSingleDatePicker.contextType = OrganizationContext;
