import React from 'react';
import styled from 'styled-components';

import BrandSelect from 'adminComponents/Selects/BrandSelect';
import UnitListingSelect from 'adminComponents/Selects/UnitListingSelect';
import { InputMoney } from 'adminComponents';
import CustomerFields from './CustomerFields';
import QuoteCreateModal from 'adminComponents/QuoteCreateModal';
import QuoteEditModal from 'adminComponents/QuoteEditModal';

import QuoteService from 'adminApi/QuoteService';
import { DirectCheckbox } from 'adminComponents';
import displayError from 'sharedComponents/ErrorDisplay';

const InputMoneyContainer = styled.figure`
  .input-money {
    float: right;
    margin-bottom: 8px;
  }
`;

export default class ManualBookingForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brand_id: null,
      booking_type: this.props.bookingType,
      confirmed: true,
      check_in: null,
      check_out: null,
      customer_email: '',
      customer_name: '',
      customer_telephone: '',
      guests: 1,
      listing_id: null,
      unit_id: null,
      total: 0,
      channel_id: null,
      sendConfirmation: true,
      quote: null,
      quote_id: null,
      currency: null,
      loading: true
    };
  }

  componentDidMount() {
    if (!this.props.initialQuoteId) {
      this.setState({ loading: false });
      return;
    }

    QuoteService.newBooking(
      this.props.organization.id,
      this.props.initialQuoteId
    )
      .then(response => {
        this.setStateWithCallback({
          loading: false,
          ...response.data
        });
      })
      .catch(err => displayError({ message: 'Error creating booking', err }));
  }

  setStateWithCallback = updates => {
    this.setState(updates, () => this.props.afterQuoteUpdate(this.state));
  };

  onChange = (name, value) => {
    this.setStateWithCallback({ [name]: value });
  };

  setBrandDefaultValue = options => {
    if (this.state.brand_id)
      return options.find(o => o.data.id === this.state.brand_id);
    if (options.length === 1) return options[0];

    return null;
  };

  updateQuoteData = quote => {
    const { id, channel_id, check_in, check_out, currency } = quote;

    this.setStateWithCallback({
      quote,
      channel_id,
      check_in,
      check_out,
      currency,
      quote_id: id,
      total: (quote.total_cents / 100.0).toFixed(2)
    });
  };

  renderQuoteButton = () => {
    if (!this.state.unit_id) return null;

    if (this.state.quote)
      return (
        <div>
          <InputMoneyContainer>
            <InputMoney
              name="total"
              label="Total reservation quote"
              currency={this.state.currency}
              setMoney={value => this.setStateWithCallback({ total: value })}
              value={this.state.total}
              disabled
            />
          </InputMoneyContainer>
          <QuoteEditModal
            quote={this.state.quote}
            openByClickOn={openPortal => (
              <div style={{ textAlign: 'right', margin: '16px 0' }}>
                <a className={'button'} onClick={openPortal}>
                  Edit Quote
                </a>
              </div>
            )}
            header={'Edit Quote'}
            organization={this.props.organization}
            onSubmit={quote => this.updateQuoteData(quote)}
            hideChargeFields
          />
        </div>
      );

    return (
      <QuoteCreateModal
        unitID={this.state.unit_id}
        listingId={this.state.listing_id}
        openByClickOn={openPortal => (
          <div style={{ textAlign: 'right', margin: '16px 0' }}>
            <a className={'button'} onClick={openPortal}>
              Create Quote
            </a>
          </div>
        )}
        header={'Create Quote'}
        channelID={this.state.channel_id}
        checkIn={this.state.check_in}
        checkOut={this.state.check_out}
        organization={this.props.organization}
        onSubmit={quote => this.updateQuoteData(quote)}
      />
    );
  };

  render() {
    if (this.state.loading) return null;

    return (
      <div>
        <div
          className={'two-col two-col-50-50'}
          style={{ marginBottom: '16px' }}
        >
          <div>
            <BrandSelect
              onSelect={({ id }) => this.setStateWithCallback({ brand_id: id })}
              defaultValue={options => this.setBrandDefaultValue(options)}
            />
          </div>
          <div>
            {this.state.brand_id && (
              <UnitListingSelect
                onSelect={({ id, unit_id }) => {
                  this.setStateWithCallback({ unit_id, listing_id: id });
                }}
                defaultValue={options =>
                  options.find(o => o.data.unit_id === this.state.unit_id)
                }
                defaultSelectData={{
                  where: [`brand_id = ${this.state.brand_id}`],
                  select: ['id', 'unit_id', 'units.name as name'],
                  order: ['name']
                }}
              />
            )}
          </div>
        </div>
        {this.state.unit_id && (
          <React.Fragment>
            <CustomerFields
              onChange={this.onChange}
              customer_name={this.state.customer_name}
              customer_email={this.state.customer_email}
              customer_telephone={this.state.customer_telephone}
              guests={this.state.guests}
            />
            <div className={'two-col two-col-50-50'}>
              <DirectCheckbox
                name="send_confirmation"
                checked={this.state.sendConfirmation}
                onChange={() =>
                  this.setStateWithCallback({
                    sendConfirmation: !this.state.sendConfirmation
                  })
                }
                labelText="Send a booking confirmation email."
              />
              {this.renderQuoteButton()}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

ManualBookingForm.defaultProps = {
  afterQuoteUpdate: _updates => {}
};
