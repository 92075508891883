import React from 'react';
import styled from 'styled-components';

const Header = styled.h1`
  font-family: din-2014, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  margin: 16px 0 8px 0;
  padding: 0 8px;
  border-left: 4px solid #ff1654;
  display: inline-block;
`;

const Message = styled.h3`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  padding-left: 12px;
`;

const OutOfStayRange = props => {
  const prop = { ...props.props };
  const totalDays = prop.check_out.diff(prop.check_in, 'days');
  if (prop.customMax && totalDays > prop.customMax) {
    return (
      <div>
        <Header>Error</Header>
        <Message>
          Sorry, the dates you have selected do not match this listing's custom
          length of stay rules. The maximum nights allowed is {prop.customMax},
          you are currently trying to book {totalDays}. Please adjust your
          dates.
        </Message>
      </div>
    );
  } else if (prop.customMin && totalDays < prop.customMin) {
    return (
      <div>
        <Header>Error</Header>
        <Message>
          Sorry, the dates you have selected do not match this listing's custom
          length of stay rules. The minimum nights allowed is {prop.customMin},
          you are currently trying to book {totalDays}. Please adjust your
          dates.
        </Message>
      </div>
    );
  } else if (totalDays < prop.minStay) {
    return (
      <div>
        <Header>Error</Header>
        <Message>
          Sorry, the dates you have selected do not match this listing's length
          of stay rules. The minimum nights allowed is {prop.minStay}, you are
          currently trying to book {totalDays}. Please adjust your dates.
        </Message>
      </div>
    );
  } else if (totalDays > prop.maxStay) {
    return (
      <div>
        <Header>Error</Header>
        <Message>
          Sorry, the dates you have selected do not match this listing's length
          of stay rules. The maximum nights allowed is {prop.maxStay}, you are
          currently trying to book {totalDays}. Please adjust your dates.
        </Message>
      </div>
    );
  } else {
    return (
      <div>
        <Header>Error</Header>
        <Message>
          Sorry, the dates you have selected do not match this listing's length
          of stay rules. Please adjust your dates.
        </Message>
      </div>
    );
  }
};
export default OutOfStayRange;
