import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const PropertiesLink = ({
  activeRoutes,
  closeMenuPanel,
  navTags,
  variation,
  closeMobileNav,
  ...props
}) => {
  return (
    <Navbar.Link
      href="/properties"
      variation={variation}
      featuredProps={{
        iconName: 'house',
        description: 'Homes, condos, etc.'
      }}
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={activeRoutes.secondary === navTags.secondary.PROPERTIES}
    >
      Properties
    </Navbar.Link>
  );
};

PropertiesLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object,
  variation: PropTypes.string
};

PropertiesLink.defaultProps = {
  variation: 'secondary'
};

export default PropertiesLink;
