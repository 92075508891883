require("@rails/ujs").start();
require("jquery");
import AdminApp from "../src/admin/admin-app";
import DirectAdminApp from "../src/directAdmin/DirectAdminApp";
import AuthenticationApp from "../src/authentication/AuthenticationApp";
import ListOrganizations from "../src/admin/pages/DashboardOrganization/organisms/list-organizations";
import ManageOrganization from "../src/admin/pages/DashboardOrganization/organisms/manage-organization";
import NewOrganization from "../src/admin/pages/DashboardOrganization/organisms/new-organization";
import ResponseStripeOrganization from "../src/admin/pages/DashboardOrganization/organisms/response-stripe-organization";
import SetupStripeOrganization from "../src/admin/pages/DashboardOrganization/organisms/setup-stripe-organization";
import CreateOrganizationWithReduxWrapper from "../src/admin/pages/DashboardOrganization/organisms/create-organization-with-redux-wrapper";
import ReactOnRails from "react-on-rails";
import { datadogRum } from "@datadog/browser-rum";
document.addEventListener("DOMContentLoaded", function() {
  var _a;
  const ddTrackSessionMeta = document.querySelector('meta[name="dd-track-session"]');
  const trackSession = ddTrackSessionMeta ? ddTrackSessionMeta.getAttribute("content") !== "false" : true;
  const monitoredEnvironments = ["versailles-production"];
  if (monitoredEnvironments.includes((_a = window.globals) == null ? void 0 : _a.HEROKU_ENV_NAME) && trackSession) {
    datadogRum.init({
      applicationId: window.globals.DATADOG_APP_ID,
      clientToken: window.globals.DATADOG_CLIENT_TOKEN,
      site: "us5.datadoghq.com",
      service: window.globals.HEROKU_ENV_NAME,
      env: "production",
      sessionSampleRate: 80,
      trackUserInteractions: true,
      sessionReplaySampleRate: 10
    });
    if (window.location.pathname.match("/dashboard")) {
      datadogRum.setGlobalContextProperty("organizationSubdomain", window.location.pathname.split("/")[2]);
    }
  }
});
ReactOnRails.register({
  AdminApp,
  DirectAdminApp,
  AuthenticationApp,
  ListOrganizations,
  ManageOrganization,
  NewOrganization,
  ResponseStripeOrganization,
  SetupStripeOrganization,
  CreateOrganizationWithReduxWrapper
});
