import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { ACCOUNTING_NAV_TAGS } from '../../../../../shared/nav';

const StatementHistory = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: ACCOUNTING_NAV_TAGS.PRIMARY,
        secondary: ACCOUNTING_NAV_TAGS.secondary.STATEMENTS
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/accounting/statements',
            label: 'Statements'
          },
          {
            href: `/accounting/statements/${props.statementId}`,
            label: `STMT-${props.statementId}`
          },
          {
            label: 'History'
          }
        ]}
      >
        {`STMT-${props.statementId}: `}
        <span>History</span>
      </PageHeader.Left>
    </PageHeader>
  );
};

export default StatementHistory;
