import React from 'react';

import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import MediaQuery from 'react-responsive';

export default class SimplifiedWysiwyg extends React.Component {
  constructor(props, _railsContext) {
    super(props);
    const editorState = this.initializeEditorState();
    this.state = {
      content: this.props.content,
      editorState
    };
  }

  initializeEditorState = () => {
    if (this.props.content) {
      const htmlCheck = new RegExp(/<[a-z][\s\S]*>/, 'i');
      let contentState;
      if (htmlCheck.test(this.props.content)) {
        const blocksFromHtml = htmlToDraft(this.props.content);
        const contentBlocks = blocksFromHtml.contentBlocks;
        contentState = ContentState.createFromBlockArray(contentBlocks);
      } else {
        contentState = ContentState.createFromText(this.props.content);
      }
      return EditorState.createWithContent(contentState);
    } else {
      return EditorState.createEmpty();
    }
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  onContentStateChange = contentState => {
    const content = draftToHtml(contentState, null, false, null);
    const stateChange = {};
    let withinLimit = true;
    if (this.props.maxLength) {
      const checkLimit = content.replace(/(<([^>]+)>)/gi, '').length;
      if (checkLimit >= this.props.maxLength) withinLimit = false;
    }
    if (withinLimit) {
      stateChange.content = content;
      this.setState(stateChange, () => {
        this.props.onContentChange(
          this.props.name,
          this.state.content,
          withinLimit
        );
      });
    }
  };

  render() {
    const { contentState, editorState } = this.state;
    const toolbar_slim = {
      inline: {
        inDropdown: true,
        options: ['bold', 'italic', 'underline']
      },
      list: {
        inDropdown: true,
        options: ['unordered', 'ordered']
      },
      options: ['inline', 'list', 'history']
    };
    const toolbar_wide = {
      inline: {
        inDropdown: false,
        options: ['bold', 'italic', 'underline']
      },
      list: {
        inDropdown: false,
        options: ['unordered', 'ordered']
      },
      options: ['inline', 'list', 'history']
    };
    return (
      <figure className="simplified-wysiwyg">
        <div>
          <MediaQuery query="(max-width: 639px)">
            <Editor
              contentState={contentState}
              editorState={editorState}
              toolbarClassName="home-toolbar"
              wrapperClassName="home-wrapper"
              editorClassName="home-editor"
              onContentStateChange={this.onContentStateChange}
              onEditorStateChange={this.onEditorStateChange}
              toolbar={toolbar_slim}
              id={this.props.id}
              name={this.props.name}
            />
          </MediaQuery>
          <MediaQuery query="(min-width: 640px)">
            <Editor
              contentState={contentState}
              editorState={editorState}
              toolbarClassName="home-toolbar"
              wrapperClassName="home-wrapper"
              editorClassName="home-editor"
              onContentStateChange={this.onContentStateChange}
              onEditorStateChange={this.onEditorStateChange}
              toolbar={toolbar_wide}
              id={this.props.id}
              name={this.props.name}
            />
          </MediaQuery>
        </div>
      </figure>
    );
  }
}
