import React from 'react';

const columnClassNames = [
  'two-col two-col-50-50',
  'three-col three-col-33-33-33'
];

export default class FormColumnSection extends React.Component {
  constructor(props) {
    super(props);
  }

  renderClassNames = () => {
    if (this.props.items[0].classNameOverride) {
      return this.props.items[0].classNameOverride;
    }
    const classNameIndex = this.props.items.length - 2;

    return columnClassNames[classNameIndex];
  };

  renderLabel = label => {
    if (label === ' ') {
      label = '\u00a0';
    }
    return <label>{label}</label>;
  };
  render() {
    return (
      <section className="consolidated-item-subsection">
        <section className={this.renderClassNames()}>
          {this.props.items.map(item => (
            <div key={item.key} style={item.style}>
              {this.renderLabel(item.label)}
              {this.props.renderItem(item)}
            </div>
          ))}
        </section>
      </section>
    );
  }
}
