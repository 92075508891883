import request from "apiClient";
export default class PartnerAccessOrganizationService {
  static list(organizationId) {
    return request({
      url: `/api/${organizationId}/partner_access_organizations`,
      method: "GET"
    });
  }
  static create(organizationId, data) {
    return request({
      url: `/api/${organizationId}/partner_access_organizations`,
      method: "POST",
      data
    });
  }
  static delete(organizationId, partnerAccessOrganizationId) {
    return request({
      url: `/api/${organizationId}/partner_access_organizations/${partnerAccessOrganizationId}`,
      method: "DELETE"
    });
  }
}
