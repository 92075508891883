import React, { useEffect, useState } from 'react';
import SelectService from 'adminApi/SelectService';
import displayError from 'sharedComponents/ErrorDisplay';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import {
  Box,
  FlexBox,
  FormField,
  FormGroupHeader,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import taskTypeOptions from '../workflows-shared-components/task_type_options';
import { symbolFromCurrencyString } from '../../shared/utils';
import {
  updateNewWorkOrder,
  updateWorkOrdersUI
} from '../redux/slices/workOrder';
import MultiUnitCodeDisplay from '../components/multi-unit-code-display';

const wo_type_options = [
  { name: 'wo_type', value: 'cleaning', label: 'Cleaning' },
  { name: 'wo_type', value: 'maintenance', label: 'Maintenance' },
  { name: 'wo_type', value: 'concierge', label: 'Concierge' },
  { name: 'wo_type', value: 'guest_services', label: 'Guest Services' },
  { name: 'wo_type', value: 'inspection', label: 'Inspection' },
  { name: 'wo_type', value: 'other', label: 'Other' }
];

const job_type_options = [
  { name: 'job_type', value: 'damage', label: 'Damage' },
  { name: 'job_type', value: 'repair', label: 'Repair' },
  { name: 'job_type', value: 'preventative', label: 'Preventative' }
];

const NewWorkOrder = props => {
  const dispatch = useDispatch();
  const newOrderUI = useSelector(state => state.workOrders.ui);
  const newWorkOrder = useSelector(state => state.workOrders.newWorkOrder);
  const [isFocused, setIsFocused] = useState(false);
  const [localDate, setLocalDate] = useState(null);
  const [bookingCodeOptions, setBookingCodeOptions] = useState([]);
  const [reqByOptions, setReqByOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  const update = value => {
    dispatch(updateNewWorkOrder(value));
  };

  const getBookingCodes = () => {
    // get booking codes
    let normalizedOpts;
    const { organization } = props;
    SelectService.index(organization.id, {
      model: 'Booking',
      select: ['booking_code', 'id']
    })
      .then(res => {
        normalizedOpts = res.options.map(opt => ({
          label: opt.booking_code,
          value: opt.id
        }));
        setBookingCodeOptions(normalizedOpts);
      })
      .catch(_err =>
        displayError({
          message: 'Could not get booking codes',
          autoClose: 5000
        })
      );
  };

  const getReqByOptions = () => {
    let normedUsers = [];
    let normedEmployees = [];
    const { organization, user } = props;
    SelectService.index(organization.id, {
      model: 'User',
      select: ['id', 'name'],
      joins: ['inner join organizations on organizations.user_id = users.id'],
      where: [`organizations.id = ${organization.id}`]
    })
      .then(res => {
        normedUsers = res.options.map(opt => ({
          label: opt.name,
          value: opt.id,
          type: 'User'
        }));
        SelectService.index(organization.id, {
          model: 'Employee',
          select: ['id', 'name']
        })
          .then(response => {
            normedEmployees = response.options.map(opt => ({
              label: opt.name,
              value: opt.id,
              type: 'Employee'
            }));
            const allRequesters = sortBy(
              normedUsers.concat(normedEmployees),
              u => u.label
            );
            const currentUser = find(
              allRequesters,
              opt => opt.value === user.id
            );
            setReqByOptions(allRequesters);
            update({ requested_by: currentUser || '' });
          })
          .catch(_err =>
            displayError({ message: 'Could not get users', autoClose: 5000 })
          );
      })
      .catch(_err =>
        displayError({ message: 'Could not get users', autoClose: 5000 })
      );
  };

  const fetchUnits = () => {
    const data = {
      model: 'Unit',
      select: [
        'id',
        'name',
        'units.unit_code as unit_unit_code',
        'properties.name as property_name',
        'properties.multi_unit as multi_unit',
        'properties.unit_code as property_unit_code'
      ],
      joins: ['inner join properties on properties.id = units.property_id']
    };
    SelectService.index(props.organization.id, data)
      .then(response => {
        const unitOptions = sortBy(response.options, ['property_name']).map(
          option => {
            if (option.multi_unit) {
              return {
                value: option.id,
                label: MultiUnitCodeDisplay(
                  option.unit_unit_code,
                  option.property_unit_code,
                  `${option.property_name} | ${option.name}`
                )
              };
            } else {
              return {
                value: option.id,
                label: MultiUnitCodeDisplay(
                  option.unit_unit_code,
                  option.property_unit_code,
                  option.name
                )
              };
            }
          }
        );
        setUnitOptions(unitOptions);
      })
      .catch(err => displayError({ message: 'Error retrieving units', err }));
  };

  const fetchPeople = () => {
    const data = {
      model: 'Employee',
      select: ['id', 'name']
    };
    SelectService.index(props.organization.id, data)
      .then(response => {
        const assigneeOptions = sortBy(response.options, ['name']).map(
          option => ({
            value: option.id,
            label: option.name,
            type: 'Employee'
          })
        );
        setUserOptions(assigneeOptions);
      })
      .catch(err =>
        displayError({ message: 'Error retrieving assignees', err })
      );
  };

  useEffect(
    () => {
      if (newOrderUI.cleanUpNewOrder) {
        setLocalDate(null);
        setIsFocused(false);
        dispatch(updateWorkOrdersUI({ cleanUpNewOrder: false }));
      }
    },
    [newOrderUI]
  );

  useEffect(() => {
    getBookingCodes();
    getReqByOptions();
    fetchUnits();
    fetchPeople();
  }, []);

  return (
    <Box paddingHorizontal="s">
      <FormGroupHeader
        title="Order Details"
        removeTopMargin
        removeTopBorder
        horizontalPadding="none"
      />
      <FormField
        labelText="Title"
        labelHtmlFor="title"
        labelMarkRequiredField
        inputType="text"
        inputProps={{
          value: newWorkOrder.title,
          onChange: e => update({ title: e.target.value })
        }}
      />
      <FormField
        labelText="Unit"
        labelHtmlFor="unit"
        labelMarkRequiredField
        inputType="select"
        inputProps={{
          options: unitOptions,
          onChange: opt => {
            update({ unit_id: opt.value });
          },
          value:
            unitOptions.filter(opt => opt.value === newWorkOrder.unit_id)[0] ||
            null
        }}
      />
      <FlexBox gap="xs" alignItems="flex-end">
        <Box flex="1">
          <FormField
            labelText="Date Due"
            labelHtmlFor="date_due"
            labelMarkRequiredField
            inputType="date"
            inputProps={{
              onFocusChange: ({ focused }) => {
                setIsFocused(focused);
              },
              onDateChange: date => {
                setLocalDate(date);
                update({ date: moment(date).format('YYYY-MM-DD') });
              },
              date: localDate,
              isFocused
            }}
          />
        </Box>
        <Box>
          <FormField
            labelText="Time Due"
            labelHtmlFor="time_due"
            inputType="time"
            inputProps={{
              value: newWorkOrder.time,
              width: 's',
              onChange: e => {
                update({ time: e.target.value });
              }
            }}
          />
        </Box>
      </FlexBox>
      <FormField
        labelText="Description"
        labelHtmlFor="description"
        labelMarkRequiredField
        inputType="textarea"
        inputProps={{
          onChange: e => update({ description: e.target.value }),
          value: newWorkOrder.description
        }}
      />
      <FormGroupHeader title="Order Configuration" horizontalPadding="none" />
      <FormField
        labelText="Work Type"
        labelHtmlFor="type"
        labelMarkRequiredField
        inputType="select"
        inputProps={{
          options: wo_type_options,
          onChange: e => update({ [e.name]: e.value }),
          inputWidth: 'm',
          value:
            wo_type_options.filter(
              opt => opt.value === newWorkOrder.wo_type
            )[0] || null
        }}
      />
      {newWorkOrder.wo_type === 'maintenance' && (
        <FormField
          labelText="Job Type"
          labelHtmlFor="job_type"
          inputType="select"
          inputProps={{
            options: job_type_options,
            onChange: e => update({ [e.name]: e.value }),
            inputWidth: 'm',
            value:
              job_type_options.filter(
                opt => opt.value === newWorkOrder.job_type
              )[0] || null
          }}
        />
      )}
      <FormField
        labelText="Amount"
        labelHtmlFor="amount"
        inputType="currency"
        inputProps={{
          value: newWorkOrder.amount,
          onValueChange: value => update({ amount: value }),
          inputWidth: 's',
          prefix: symbolFromCurrencyString(props.organization.currency)
        }}
      />
      <FormField
        labelText="Task Type"
        labelHtmlFor="task_type"
        inputType="select"
        inputProps={{
          options: taskTypeOptions,
          onChange: opt => update({ task_types: opt }),
          isMulti: true,
          value: newWorkOrder.task_types
        }}
      />
      <FormField
        labelText="Booking Code"
        labelHtmlFor="booking_code"
        inputType="select"
        inputProps={{
          options: bookingCodeOptions,
          value:
            bookingCodeOptions.filter(
              opt => opt.value === newWorkOrder.booking_id
            )[0] || null,
          onChange: opt => update({ booking_id: opt.value }),
          inputWidth: 'm'
        }}
      />
      <FormGroupHeader title="Order Personnel" horizontalPadding="none" />
      <FormField
        labelText="Assigned To"
        labelHtmlFor="assigned_to"
        inputType="select"
        inputProps={{
          options: userOptions,
          value:
            userOptions.filter(
              opt => opt.value === newWorkOrder.assignee_id
            )[0] || null,
          onChange: opt =>
            update({ assignee_id: opt.value, assignee_type: opt.type }),
          inputWidth: 'm'
        }}
      />
      <FormField
        labelText="Requested By"
        labelHtmlFor="requested_by"
        inputType="select"
        inputProps={{
          options: reqByOptions,
          value:
            reqByOptions.filter(
              opt => opt.value === newWorkOrder.requested_by?.value
            )[0] || null,
          onChange: opt => update({ requested_by: opt }),
          inputWidth: 'm'
        }}
      />
      <Spacer size="l" />
    </Box>
  );
};

export default NewWorkOrder;
