// Dependencies
// -----------------------------------------------
import React from 'react';

// -----------------------------------------------------
// COMPONENT->FEATURES-SINGLE-ASIDE-SUITABILITY-THEMES -
// -----------------------------------------------------
const FeaturesSingleAsideSuitabilityThemes = () => {
  // ---------------------------------------------
  // RETURN --------------------------------------
  // ---------------------------------------------
  return (
    <div>
      <figure>
        <small className="subtitle">Suitability</small>
        <p>
          Who, and what, is allowed in your property? Please check all that
          apply.
        </p>
      </figure>

      <figure>
        <small className="subtitle">Themes</small>
        <p>
          How would you categorize this property's vacation style? Please check
          all that apply.
        </p>
      </figure>
    </div>
  );
};

// -----------------------------------------------
// EXPORT ----------------------------------------
// -----------------------------------------------
export default FeaturesSingleAsideSuitabilityThemes;
