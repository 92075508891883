// Dependencies
// -----------------------------------------------
import React from 'react';
import styled from 'styled-components';
import remove from 'lodash/remove';

// Components
// -----------------------------------------------
import { DocumentUploader } from 'adminComponents';

// Services
// -----------------------------------------------
import { DocumentService } from 'adminApi';

// Styled Components
// -----------------------------------------------
const DocumentList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px 8px;
`;

const Document = styled.div`
  background-color: #e8e8e8;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 4px 8px;
  border-radius: 4px;

  a {
    :first-child {
      margin-right: 8px;
    }
  }
`;

export default class DocumentListWithUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: this.props.documents || []
    };
  }

  afterUpload = doc => {
    const documents = this.state.documents.slice();
    documents.push(doc);
    this.setState({ documents }, () => {
      this.props.afterUpload && this.props.afterUpload(this.state.documents);
    });
  };

  destroyUpload = doc_id => {
    const documents = this.state.documents.slice();
    remove(documents, doc => doc.id === doc_id);

    this.setState({ documents }, () => {
      DocumentService.destroy(this.props.organization.id, doc_id);
    });
  };

  renderDocumentList = () => {
    const documents = this.state.documents;

    if (documents.length === 0) return null;

    return (
      <DocumentList>
        {documents.map(doc => (
          <Document key={doc.id}>
            <a href={doc.url} target="_blank">
              {doc.name}
            </a>
            <a onClick={() => this.destroyUpload(doc.id)}>
              <i className="fas fa-times" />
            </a>
          </Document>
        ))}
      </DocumentList>
    );
  };

  renderNoDocuments = () => <p>No documents have been uploaded yet...</p>;

  render() {
    return (
      <div>
        <section className="consolidated-item-header-section remove-padding-top">
          <h3>Uploaded Documents</h3>
        </section>
        {this.renderDocumentList()}
        <DocumentUploader
          organization={this.props.organization}
          documentable_id={this.props.documentable_id}
          documentable_type={this.props.documentable_type}
          afterUpload={this.afterUpload}
        />
      </div>
    );
  }
}
