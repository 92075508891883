// Dependencies
// -----------------------------------------------
import React from 'react';

// -----------------------------------------------
// COMPONENT->OVERVIEW-SINGLE-ASIDE-INSTRUCTIONS -
// -----------------------------------------------
const OverviewSingleAsideInstructions = () => {
  // ---------------------------------------------
  // RETURN --------------------------------------
  // ---------------------------------------------
  return (
    <div>
      <figure>
        <small className="subtitle">Guest Access</small>
        <p>
          This is the method in which a guest will gain access to your property.
        </p>
      </figure>

      <figure>
        <small className="subtitle">Additional Instructions</small>
        <p>
          These are any additional instructions your guests may need to know to
          gain access to your property.
        </p>
      </figure>
    </div>
  );
};

// -----------------------------------------------
// EXPORT ----------------------------------------
// -----------------------------------------------
export default OverviewSingleAsideInstructions;
