import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const PaymentsStatementsLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags,
  ...props
}) => {
  return (
    <Navbar.Link
      href="/payments/statements"
      variation="secondary"
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={activeRoutes.secondary === navTags.secondary.STATEMENTS}
    >
      Statements
    </Navbar.Link>
  );
};

PaymentsStatementsLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object
};

export default PaymentsStatementsLink;
