import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { WORKFLOWS_NAV_TAGS } from '../../../../../shared/nav';

const WorkReportDetails = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: WORKFLOWS_NAV_TAGS.PRIMARY,
        secondary: WORKFLOWS_NAV_TAGS.secondary.WORK_REPORTS
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/workflows/work-reports',
            label: 'Work Reports'
          },
          {
            label: 'Edit Details'
          }
        ]}
      >
        Work Report: <span>{props.workReportId}</span>
      </PageHeader.Left>
    </PageHeader>
  );
};

export default WorkReportDetails;
