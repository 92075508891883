import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormField,
  Spacer,
  TextBody,
  TextTitle,
  FormGroupHeader
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyDefinitions from 'sharedUtils/currency-definitions';
import {
  organizationTypeOptions,
  companyTypeOptions,
  languageOptions,
  paymentProcessorOptions
} from 'sharedOptions';
import {
  selectNewOrganization,
  updateNewOrganization
} from 'reduxSlices/createOrganization';

const CreateOrganizationOverview = ({ user, handleDisableNextButton }) => {
  const dispatch = useDispatch();
  const organization = useSelector(selectNewOrganization);

  const sanitizeSubdomain = str => {
    return str
      .replace("'", '')
      .replace(/[^A-Z0-9]/gi, '-')
      .toLowerCase()
      .substring(0, 80);
  };

  useEffect(
    () => {
      handleDisableNextButton(
        organization.name === '' ||
          organization.companyType === '' ||
          organization.organizationType === '' ||
          organization.currency === '' ||
          organization.language === '' ||
          organization.paymentProcessor === ''
      );
    },
    [organization]
  );

  useEffect(() => {
    user.rvshare_user_id &&
      dispatch(updateNewOrganization({ organizationType: 'rv_fleet' }));
  }, []);

  return (
    <Box className="newOrganization__formWrapper" paddingBottom="l">
      <TextTitle>Let's Get Started</TextTitle>
      <Spacer />
      <TextBody>
        Setting up your Organization will be quick and easy. Start by telling us
        a few basics about your Organization.
      </TextBody>
      <Spacer size="xs" />
      <FormGroupHeader title="Organization Details" horizontalPadding="none" />
      <FormField
        labelText="Organization Name"
        labelHtmlFor="organization_name"
        inputType="text"
        inputProps={{
          value: organization.name,
          onChange: e => {
            const name = e.target.value;
            const subdomain = sanitizeSubdomain(name);
            dispatch(
              updateNewOrganization({
                name,
                subdomain,
                nameLength: name.length,
                brandName: name
              })
            );
          }
        }}
      />
      <FormField
        labelText="Organization Type"
        labelHtmlFor="organization_type"
        inputType="select"
        inputProps={{
          value: organizationTypeOptions.filter(
            option => option.value === organization.organizationType
          )[0],
          isDisabled: user.rvshare_user_id,
          searchable: false,
          clearable: false,
          options: organizationTypeOptions,
          onChange: option => {
            dispatch(updateNewOrganization({ organizationType: option.value }));
          }
        }}
      />
      <FormGroupHeader title="Business Details" horizontalPadding="none" />
      <FormField
        labelText="Company Type"
        labelHtmlFor="company_type"
        inputType="select"
        inputProps={{
          value: companyTypeOptions.filter(
            option => option.value === organization.companyType
          )[0],
          searchable: false,
          clearable: false,
          options: companyTypeOptions,
          onChange: option => {
            dispatch(updateNewOrganization({ companyType: option.value }));
          }
        }}
      />
      <FormField
        labelText="Language"
        labelHtmlFor="language"
        inputType="select"
        inputProps={{
          value: languageOptions.filter(
            option => option.value === organization.language
          )[0],
          clearable: false,
          options: languageOptions,
          onChange: option => {
            dispatch(updateNewOrganization({ language: option.value }));
          }
        }}
      />
      <FormField
        labelText="Currency"
        labelHtmlFor="currency"
        inputType="select"
        inputProps={{
          value: CurrencyDefinitions.filter(
            option => option.value === organization.currency
          )[0],
          clearable: false,
          options: CurrencyDefinitions,
          onChange: option => {
            dispatch(updateNewOrganization({ currency: option.value }));
          }
        }}
      />
      <FormField
        labelText="Payment Processor"
        labelHtmlFor="payment_processor"
        inputType="select"
        inputProps={{
          value: paymentProcessorOptions.filter(
            option => option.value === organization.paymentProcessor
          )[0],
          clearable: false,
          options: paymentProcessorOptions,
          onChange: option => {
            dispatch(updateNewOrganization({ paymentProcessor: option.value }));
          }
        }}
      />
    </Box>
  );
};

CreateOrganizationOverview.propTypes = {
  handleDisableNextButton: PropTypes.func
};

export default CreateOrganizationOverview;
