import request from "apiClient";
export default class QuoteService {
  static create(organizationID, params) {
    return request({
      url: `/api/${organizationID}/quotes`,
      method: "POST",
      data: params
    });
  }
  static update(organizationID, quoteID, data) {
    return request({
      url: `/api/${organizationID}/quotes/${quoteID}`,
      method: "PATCH",
      data
    });
  }
  static duplicate(organizationID, quoteID) {
    return request({
      url: `/api/${organizationID}/quotes/duplicates`,
      method: "POST",
      data: { quote_id: quoteID }
    });
  }
  static duplicateBookingNetDetail(organizationID, originalQuoteID, duplicateQuoteID) {
    return request({
      url: `/api/${organizationID}/quotes/booking_net_detail_duplicates`,
      method: "POST",
      data: {
        original_quote_id: originalQuoteID,
        duplicate_quote_id: duplicateQuoteID
      }
    });
  }
  static updateBookingNetDetail(organizationID, quoteID, zeroOut = false) {
    return request({
      url: `/api/${organizationID}/quotes/booking_net_details`,
      method: "PATCH",
      data: {
        quote_id: quoteID,
        zero_out: zeroOut
      }
    });
  }
  static createLineItem(organizationID, data) {
    return request({
      url: `/api/${organizationID}/quotes/line_items`,
      method: "POST",
      data
    });
  }
  static updateLineItem(organizationID, quoteID, lineItemID, totalCents, zeroOut) {
    return request({
      url: `/api/${organizationID}/quotes/line_items`,
      method: "PATCH",
      data: {
        quote_id: quoteID,
        total_cents: totalCents,
        line_item_id: lineItemID,
        zero_out: zeroOut
      }
    });
  }
  static destroyLineItem(organizationID, lineItemID) {
    return request({
      url: `/api/${organizationID}/quotes/line_items/${lineItemID}`,
      method: "DELETE"
    });
  }
  static updateBooking(organizationID, quoteID, zeroOut) {
    return request({
      url: `/api/${organizationID}/quotes/bookings`,
      method: "PATCH",
      data: {
        quote_id: quoteID,
        zero_out: zeroOut
      }
    });
  }
  static fetchCouponCodes(organizationID, listingId, listingType) {
    return request({
      url: `/api/${organizationID}/quotes/fetch_coupon_codes`,
      method: "PATCH",
      data: {
        listing_id: listingId,
        listing_type: listingType
      }
    });
  }
  static possibleFeeItems(organizationID, quoteID, getCancellationFees) {
    return request({
      url: `/api/${organizationID}/quotes/possible_fees?cancellation_fees=${getCancellationFees}&quote_id=${quoteID}`,
      method: "GET"
    });
  }
  static possibleTaxItems(organizationID, quoteID) {
    return request({
      url: `/api/${organizationID}/quotes/possible_taxes?quote_id=${quoteID}`,
      method: "GET"
    });
  }
  static newBooking(organizationID, quoteID) {
    return request({
      url: `/api/${organizationID}/quotes/bookings/new?quote_id=${quoteID}`,
      method: "GET"
    });
  }
  static getByBooking(organizationID, bookingCode) {
    return request({
      url: `/api/${organizationID}/quotes/${bookingCode}`,
      method: "GET"
    });
  }
  static availabilityCheck(organizationID, bookableId, checkIn, checkOut, vehicleBooking) {
    return request({
      url: `/api/${organizationID}/quotes/availability_check?bookable_id=${bookableId}&check_in=${checkIn}&check_out=${checkOut}&vehicle_booking=${vehicleBooking}`,
      method: "GET"
    });
  }
  static rvshareQuote(organizationID, conversationID, checkIn, checkOut, rvId, pickUp, dropOff, totalRoomRate, maxGeneratorHours, mileageIncluded, renterID, cancellationPolicy, bookingExpires, deliveryLocationID, selectedUpgrades, bookingID, securityDeposit, customGooglePlaceId = null) {
    return request({
      url: `/api/${organizationID}/quotes/rvshare_quote`,
      method: "POST",
      data: {
        check_in: checkIn,
        check_out: checkOut,
        rv_id: rvId,
        pick_up_time: pickUp,
        drop_off_time: dropOff,
        total_room_rate: totalRoomRate,
        generator_usage_included: maxGeneratorHours,
        mileage_included: mileageIncluded,
        conversation_id: conversationID,
        renter_id: renterID,
        cancellation_policy: cancellationPolicy,
        booking_expires: bookingExpires,
        delivery_location_id: deliveryLocationID,
        selected_upgrades: selectedUpgrades,
        booking_id: bookingID,
        security_deposit_amount: securityDeposit,
        custom_google_place_id: customGooglePlaceId
      }
    });
  }
  static editQuote(organizationID, conversationID, quoteID, checkIn, checkOut, rvId, pickUp, dropOff, totalRoomRate, maxGeneratorHours, mileageIncluded, renterID, cancellationPolicy, bookingExpires, deliveryLocationID, selectedUpgrades, securityDeposit, customGooglePlaceId = null) {
    return request({
      url: `/api/${organizationID}/quotes/edit_rvshare_quote`,
      method: "POST",
      data: {
        check_in: checkIn,
        check_out: checkOut,
        rv_id: rvId,
        quote_id: quoteID,
        pick_up_time: pickUp,
        drop_off_time: dropOff,
        total_room_rate: totalRoomRate,
        generator_usage_included: maxGeneratorHours,
        mileage_included: mileageIncluded,
        conversation_id: conversationID,
        renter_id: renterID,
        cancellation_policy: cancellationPolicy,
        booking_expires: bookingExpires,
        delivery_location_id: deliveryLocationID,
        selected_upgrades: selectedUpgrades,
        security_deposit_amount: securityDeposit,
        custom_google_place_id: customGooglePlaceId
      }
    });
  }
  static calculateRvshareBooking(organizationID, checkIn, checkOut, rvId, pickUp, dropOff, totalRoomRate, bookingID, maxGeneratorHours, mileageIncluded, cancellationPolicy, selectedUpgrades, securityDeposit, customGooglePlaceId = null, deliveryLocationId = null) {
    return request({
      url: `/api/${organizationID}/quotes/calculate_rvshare_booking`,
      method: "POST",
      data: {
        check_in: checkIn,
        check_out: checkOut,
        rv_id: rvId,
        pick_up_time: pickUp,
        drop_off_time: dropOff,
        total_room_rate: totalRoomRate,
        booking_id: bookingID,
        generator_usage_included: maxGeneratorHours,
        mileage_included: mileageIncluded,
        cancellation_policy: cancellationPolicy,
        selected_upgrades: selectedUpgrades,
        security_deposit_amount: securityDeposit,
        custom_google_place_id: customGooglePlaceId,
        delivery_location_external_id: deliveryLocationId
      }
    });
  }
  static calculateRvshareQuote(organizationID, conversationID, checkIn, checkOut, rvId, pickUp, dropOff, totalRoomRate, maxGeneratorHours, mileageIncluded, deliveryLocationID, selectedUpgrades, renterID, securityDeposit, customGooglePlaceId = null) {
    return request({
      url: `/api/${organizationID}/quotes/calculate_rvshare_quote`,
      method: "POST",
      data: {
        check_in: checkIn,
        check_out: checkOut,
        rv_id: rvId,
        pick_up_time: pickUp,
        drop_off_time: dropOff,
        total_room_rate: totalRoomRate,
        generator_usage_included: maxGeneratorHours,
        mileage_included: mileageIncluded,
        selected_upgrades: selectedUpgrades,
        conversation_id: conversationID,
        renter_id: renterID,
        delivery_location_id: deliveryLocationID,
        security_deposit_amount: securityDeposit,
        custom_google_place_id: customGooglePlaceId
      }
    });
  }
  static retrievePotentialRvshareUpgrades(organizationID, vehicleID) {
    return request({
      url: `/api/${organizationID}/quotes/potential_rvshare_upgrades`,
      method: "POST",
      data: {
        vehicle_id: vehicleID
      }
    });
  }
  static retrieveRequiredRvshareFees(organizationID, vehicleID) {
    return request({
      url: `/api/${organizationID}/quotes/required_rvshare_fees`,
      method: "POST",
      data: {
        vehicle_id: vehicleID
      }
    });
  }
  static retrieveAvailableVehicles(organizationID, startDate, endDate, vehicleID) {
    return request({
      url: `/api/${organizationID}/quotes/available_vehicles`,
      method: "POST",
      data: {
        start_date: startDate,
        end_date: endDate,
        vehicle_id: vehicleID
      }
    });
  }
  static sendAlteration(organizationID, checkIn, checkOut, rvId, pickUp, dropOff, totalRoomRate, bookingID, maxGeneratorHours, maxMileageIncluded, cancellationPolicy, deliveryLocation, selectedUpgrades, securityDeposit, customGooglePlaceId = null, customDeliveryLocationPrice = null) {
    return request({
      url: `/api/${organizationID}/quotes/send_alteration`,
      method: "POST",
      data: {
        check_in: checkIn,
        check_out: checkOut,
        rv_id: rvId,
        pick_up_time: pickUp,
        drop_off_time: dropOff,
        total_room_rate: totalRoomRate,
        booking_id: bookingID,
        generator_usage_included: maxGeneratorHours,
        mileage_included: maxMileageIncluded,
        cancellation_policy: cancellationPolicy,
        selected_upgrades: selectedUpgrades,
        delivery_location_id: deliveryLocation,
        security_deposit_amount: securityDeposit,
        custom_google_place_id: customGooglePlaceId,
        custom_delivery_location_price: customDeliveryLocationPrice
      }
    });
  }
  static cancelRvShareQuote(organizationID, conversationID) {
    return request({
      url: `/api/${organizationID}/quotes/cancel_rvshare_quote`,
      method: "POST",
      data: {
        conversation_id: conversationID
      }
    });
  }
  static getUsages(organizationID, vehicleID) {
    return request({
      url: `/api/${organizationID}/usages?vehicle_id=${vehicleID}`,
      method: "GET"
    });
  }
}
