import { channelDesignKitMap } from "../options";
export const getChannelLogoTag = (channelId) => {
  const searchChannelId = channelId || 0;
  const channelData = channelDesignKitMap.filter((channel) => channel.value === searchChannelId)[0];
  return channelData == null ? void 0 : channelData.design_kit_tag;
};
export const getChannelLabel = (channelId) => {
  const searchChannelId = channelId || 0;
  const channelData = channelDesignKitMap.filter((channel) => channel.value === searchChannelId)[0];
  return channelData.label;
};
export const getChannelChartColors = (channelId, matchOnTag = false) => {
  const searchChannelId = channelId || 0;
  const channelData = channelDesignKitMap.filter((channel) => {
    return matchOnTag ? channel.design_kit_tag === searchChannelId : channel.value === searchChannelId;
  })[0];
  return {
    base: channelData.chartBase,
    gradient: channelData.chartGradient
  };
};
