import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FlexBox,
  IconFontAwesome,
  Spacer,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { Waypoint } from 'react-waypoint';
import { useDispatch } from 'react-redux';
import { updateNavigation } from 'src/admin/redux/slices/navigation';
import { useDetectMobile } from 'src/shared/hooks';

const DetailsLayoutAnchor = ({
  label,
  anchorTag,
  anchorBaseUrl,
  removeTopSpacer,
  actions,
  trackSideBarTab,
  anchorOnly
}) => {
  const dispatch = useDispatch();
  const { isMobile } = useDetectMobile();
  return (
    <>
      {!removeTopSpacer && !anchorOnly && <Spacer size="xl" />}
      <Box id={anchorTag} />
      {trackSideBarTab && !isMobile && (
        <Waypoint
          topOffset={20}
          onPositionChange={({ currentPosition, waypointTop }) => {
            if (
              (currentPosition === 'inside' || currentPosition === 'above') &&
              waypointTop <= 250
            ) {
              dispatch(
                updateNavigation({
                  navigationId: anchorTag
                })
              );
            }
          }}
        />
      )}
      {!anchorOnly && (
        <>
          <FlexBox alignItems="center" justifyContent="space-between">
            <FlexBox alignItems="center" gap="xs">
              {anchorBaseUrl && (
                <Box
                  renderAs={anchorBaseUrl ? 'a' : 'div'}
                  href={anchorBaseUrl ? `${anchorBaseUrl}#${anchorTag}` : null}
                  className="details-layout-anchor"
                >
                  <IconFontAwesome name="hashtag" />
                </Box>
              )}
              <TextH2>{label}</TextH2>
            </FlexBox>
            {actions && <FlexBox>{actions}</FlexBox>}
          </FlexBox>
          <Spacer size="xs" />
        </>
      )}
    </>
  );
};

DetailsLayoutAnchor.propTypes = {
  label: PropTypes.string,
  anchorTag: PropTypes.string,
  anchorBaseUrl: PropTypes.string,
  removeTopSpacer: PropTypes.bool,
  actions: PropTypes.node,
  trackSideBarTab: PropTypes.bool,
  anchorOnly: PropTypes.bool
};

DetailsLayoutAnchor.defaultProps = {
  label: 'Anchor',
  removeTopSpacer: false
};

export default DetailsLayoutAnchor;
