import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const PaymentsEarningsSummaryLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags,
  ...props
}) => {
  return (
    <Navbar.Link
      href={`/accounting/earnings-summaries/${props.user.id}?payee_type=${
        props.user.type
      }`}
      variation="secondary"
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={activeRoutes.secondary === navTags.secondary.EARNINGS_SUMMARY}
    >
      Earnings Summary
    </Navbar.Link>
  );
};

PaymentsEarningsSummaryLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object
};

export default PaymentsEarningsSummaryLink;
