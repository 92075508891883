import React from 'react';
import moment from 'moment';

import { CloseCustomerSidebarLink } from 'adminComponents/CustomerSidebar';
import ListDataItem from './ListDataItem';
import UserAvatar from 'adminComponents/Avatars/UserAvatar';
import InlineHeaderEdit from 'adminComponents/Atlaskit/InlineHeaderEdit';
import InlineTextEdit from 'adminComponents/Atlaskit/InlineTextEdit';
import { LeadTag } from 'adminComponents';

import { CustomerInfoContainer, AvatarContainer } from './CustomerInfoStyles';
import {
  isPhoneNumberValid
} from '../../../../shared/utils/field-helpers';

const formatTelephone = phone => {
  let newPhone = phone ? phone.replace(/[^0-9]+/g, '') : '';
  newPhone = newPhone.length === 11 ? newPhone.slice(1) : newPhone;

  return phone && newPhone.length === 10
    ? `(${newPhone.slice(0, 3)}) ${newPhone.slice(3, 6)}-${newPhone.slice(6)}`
    : '';
};

const CustomerInfo = props => {
  let validateValue;

  const validatePhone = value => {
    validateValue = value;
    return new Promise(resolve => {
      if (!isPhoneNumberValid(value)) {
        resolve({ value, error: 'Enter valid phone number' });
      }

      resolve(undefined);
    }).then(validateObject => {
      if (validateObject && validateObject.value === validateValue) {
        return validateObject.error;
      }
      return undefined;
    });
  };

  const customer = props.customer;
  return (
    <CustomerInfoContainer>
      <CloseCustomerSidebarLink />
      <AvatarContainer>
        <UserAvatar user={customer} size={'large'} />
      </AvatarContainer>
      <div style={{ flex: '1 0 auto' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <InlineHeaderEdit
            defaultValue={customer.name}
            readView={value => <h3>{value}</h3>}
            onConfirm={value => props.updateCustomer({ name: value })}
          />
          <LeadTag lead={customer} />
        </div>
        <InlineTextEdit
          defaultValue={customer.email}
          readView={value => value || 'No email provided'}
          onConfirm={value => props.updateCustomer({ email: value })}
        />
        <InlineTextEdit
          defaultValue={formatTelephone(customer.telephone)}
          readView={value => value || 'No telephone number provided'}
          onConfirm={value => {
            props.updateCustomer({ telephone: value });
          }}
          validate={validatePhone}
        />
        <div style={{ padding: '16px' }}>
          <ListDataItem
            text={`Joined ${moment(customer.joined).format('MMMM YYYY')}`}
          />
          <ListDataItem
            text={`${customer.bookings} ${
              customer.bookings > 1 || customer.bookings === 0
                ? 'bookings'
                : 'booking'
            }`}
          />
        </div>
      </div>
    </CustomerInfoContainer>
  );
};

export default CustomerInfo;
