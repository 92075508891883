// Dependencies
// -----------------------------------------------
import React from 'react';

export default class TextInput extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = (name, value) => {
    this.props.onChange(name, value);
  };

  render() {
    return (
      <div>
        <label>{this.props.label}</label>
        <input
          className="magnified"
          type={this.props.type}
          name={this.props.name}
          onChange={e => this.onChange(e.target.name, e.target.value)}
          value={this.props.value}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          style={this.props.style}
          {...this.props.additionalProps}
        />
      </div>
    );
  }
}

TextInput.defaultProps = {
  type: 'text'
};
