import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

const TermsConditionsAug2023 = props => {
  return (
    <figure
      className={
        props.isContained ? 'terms-conditions' : 'new-terms-conditions'
      }
    >
      <div id={props.isContained ? 'terms' : ''}>
        <h3>
          <strong>
            Direct Software Service Agreement for Real Estate Rental Properties
          </strong>
        </h3>
        <h3>
          <strong>
            MANAGER LICENSE AND SERVICES AGREEMENT
          </strong>
        </h3>
        <br />
        <p>Last Updated: August 11, 2023</p>
        <br />
        <p>
          <span>This Manager License and Services Agreement (this “</span>
          <strong>Agreement</strong>
          <span>
            ”), effective as of the date this agreement is executed (the “
          </span>
          <strong>Effective Date</strong>
          <span>
            ”), constitutes a binding agreement between Vacation Rental
            Partners, Inc. (“
          </span>
          <strong>VRP</strong>
          <span>
            ”) and you, an entity that manages real estate property listings via
            the Service (“
          </span>
          <strong>Manager</strong>
          <span>,” “</span>
          <strong>you,</strong>
          <span>” or “</span>
          <strong>your</strong>
          <span>
            ”). This Agreement sets forth the terms and conditions upon which
            VRP grants Manager a license to use the VRP websites, GetDirect.io
            and its subdomains, DirectSoftware.com and its subdomains, including
            any versions optimized for viewing on a mobile device or any version
            that is made available on a third-party website with VRP’s
            authorization; and all other interactive features, services,
            software and communications provided by VRP (the “
          </span>
          <strong>Service</strong>
          <span>”). </span>
          <strong>
            By clicking “I Agree,” you accept all the terms and conditions of
            this Agreement. If you DO NOT agree to the terms and conditions of
            this Agreement, DO NOT click “I Agree” or use the Service.
          </strong>
        </p>
        <ol>
          <li>
            <strong> The Service.</strong>
            <span>
              {' '}
              VRP makes available a platform to manage real estate rental
              properties listed by Manager on the Service (“
            </span>
            <strong>Listed Units</strong>
            <span>
              ”), operations, distribution to online third-party marketplaces,
              and accept bookings by guests via white label websites. VRP does
              not own, operate, sell, furnish, provide, rent, manage or control
              Listed Units or Manager’s services, including real estate,
              vehicles, boats, and equipment. Manager is solely responsible for
              determining the appropriateness and suitability of any prospective
              guest or any activity booked through the Service.
            </span>
          </li>
          <li>
            <strong> License. </strong>
            <span>
              Subject to Manager’s compliance with the terms, conditions and
              limitations of this Agreement, including payment to VRP of the
              fees set forth herein, VRP grants you a limited, revocable,
              non-exclusive, non-transferable license to access and make use of
              the Service during the term of the Agreement. This license does
              not include any resale or commercial use of the Service or their
              contents; any collection and use of any images or third-party
              content on the Service; any derivative use of the Service or their
              contents; any uncompiling, disassembling, reverse engineering, or
              other such attempts at discovering the source code of any
              Services; any downloading or copying of account information for
              the benefit of another company or user; or any use of data mining,
              robots, or similar data gathering and extraction tools. The
              Service, or any portion thereof, may not be reproduced, sold,
              resold, visited or otherwise exploited for any commercial purpose
              without VRP’s express written consent. You will not access or
              attempt to access content on the Service through any interface
              except for the publicly provided websites or applications provided
              by VRP.
            </span>
          </li>
          <li>
            <strong> Intellectual Property.</strong>
          </li>
        </ol>
        <p>
          <span>
            3.1 The Service and all of the content featured or displayed on the
            Service, including without limitation text, graphics, photographs,
            images, sound, and illustrations, and excluding Manager Data,
            defined in Section 6 (“
          </span>
          <strong>Content</strong>
          <span>
            ”), is owned by VRP or its licensors, vendors, agents or its Content
            providers. All elements of the Service, including without limitation
            the general design and the Content, are protected by trade dress,
            copyright, moral rights, trademark and other laws relating to
            intellectual property rights. You agree not to challenge VRP’s
            ownership of all right, title and interest in and to the Service (or
            any code underlying the Service). The Service may only be used for
            the intended purpose for which such Service is being made available.
            You are authorized to view, play, print and download copyrighted
            documents, audio and video found on our Service for personal,
            informational, and non-commercial purposes only. You may not modify
            any of the materials and you may not copy, distribute, transmit,
            display, perform, reproduce, publish, license, create derivative
            works from, transfer or sell any information or work contained on
            the Service. The Service, its Content and all related rights shall
            remain the exclusive property of VRP or its licensors unless
            otherwise expressly agreed. You will not remove any copyright,
            trademark or other proprietary notices from material found on the
            Service.
          </span>
          <span>
            <br />
          </span>
          <span>
            <br />
          </span>
          <span>
            We will respond to notices of alleged copyright infringement that
            comply with applicable law and are properly provided to VRP. We
            reserve the right to remove any material alleged to be infringing
            without prior notice and without liability to you. When appropriate,
            we may also terminate your account. If you believe that your
            copyrighted information has been used in a way that constitutes
            copyright infringement, please send a notice that complies with the
            requirements of the Digital Millennium Copyright Act to our
            designated agent at:
          </span>
        </p>
        <p>
          <span>Vacation Rental Partners, Inc.</span>
          <span>
            <br />
          </span>
          <span>5534 Saint Joe Road</span>
          <span>
            <br />
          </span>
          <span>Fort Wayne, IN 46835</span>
          <span>
            <br />
          </span>
          <span>Email: </span>
          <a target="_blank" href="mailto:help@getdirect.io" rel="noreferrer">
            <span>help@getdirect.io</span>
          </a>
        </p>
        <p>
          <span>
            3.2 All trademarks, service marks and trade names of VRP used in the
            Service (including but not limited to: VRP’s name and logo; the
            Service’s name, design, and any logos) (collectively “
          </span>
          <strong>Marks</strong>
          <span>
            ”) are trademarks of VRP or its affiliates, partners, vendors or
            licensors. You may not use, copy, reproduce, republish, upload,
            post, transmit, distribute, or modify the Marks in any way,
            including in advertising or publicity pertaining to distribution of
            materials on the Service, without VRP’s prior written consent. VRP
            prohibits the use of the Marks as a “hot” link on or to any other
            website unless establishment of such a link is approved in advance.
            You shall not use VRP’s name or any language, pictures or symbols
            which could, in VRP’s judgment, imply VRP’s endorsement in any
            written or oral advertising, presentation, brochure, newsletter,
            book, or other written material of whatever nature, without prior
            written consent.
          </span>
        </p>
        <ol start="4">
          <li>
            <strong> Use of the Service.</strong>
          </li>
        </ol>
        <p>
          <span>
            4.1 You are prohibited from using the Service to post or send any
            unlawful, threatening, defamatory, libelous, obscene, pornographic
            or profane material or any material that could constitute or
            encourage conduct that would be considered a criminal offense or
            give rise to civil liability, or otherwise violate any law. In
            addition to any remedies that we may have at law or in equity, if we
            determine, in our sole discretion, that you have violated or are
            likely to violate the foregoing prohibitions, we may take any action
            we deem necessary to cure or prevent the violation. We may fully
            cooperate with any law enforcement authorities or court order or
            subpoena requesting or directing us to disclose the identity of
            anyone inappropriately using the Service.
          </span>
        </p>
        <p>
          <span>
            4.2 Running or displaying the Service or any information or material
            displayed on the Service in frames or through similar means on
            another website without our prior written permission is prohibited.
            From time to time, the Service may contain links to websites that
            are not owned, operated or controlled by VRP or its affiliates. All
            such links are provided solely as a convenience to you. If you use
            these links, you will leave the Service. Neither we nor any of our
            respective affiliates are responsible for any content, materials or
            other information located on or accessible from any other website.
            We do not endorse, guarantee, or make any representations or
            warranties regarding any other websites, services, or any other
            information located or accessible from any other websites or
            services. If you decide to access any other websites, you do so
            entirely at your own risk.
          </span>
        </p>
        <p>
          <span>
            4.3 You agree that you will not use any robot, spider, scraper or
            other automated means to access the Service for any purpose without
            our express written permission. Additionally, you agree that you
            will not: (i) take any action that imposes, or may impose in our
            sole discretion an unreasonable or disproportionately large load on
            our infrastructure; (ii) interfere or attempt to interfere with the
            proper working of the Service or any activities conducted on the
            Service; or (iii) bypass any measures we may use to prevent or
            restrict access to the Service. We retain the right at our sole
            discretion to deny access to anyone to this Service, at any time and
            for any reason, including, but not limited to, for violation of this
            Agreement.
          </span>
        </p>
        <p>
          <span>
            4.4 You may not post, upload, publish, submit or transmit any
            content (including but not limited to any text, graphics, images,
            music, software, audio, video, information or other material) via
            the Services that (a) infringes, misappropriates or violates a third
            party’s patent, copyright, trademark, trade secret, moral rights or
            other intellectual property rights, or rights of publicity or
            privacy; (b) violates, or encourages any conduct that would violate,
            any applicable law or regulation or would give rise to civil or
            criminal liability; (c) is fraudulent, false, misleading or
            deceptive; (d) is defamatory, obscene, pornographic, vulgar or
            offensive as determined in VRP’s sole judgment; (e) promotes
            discrimination, bigotry, racism, hatred, harassment or harm against
            any individual or group; (f) is violent or threatening or promotes
            violence or actions that are threatening to any other person; or (g)
            promotes illegal or harmful activities or substances. In addition to
            any other remedies, VRP may immediately suspend or terminate any
            Manager that violates these restrictions.
          </span>
        </p>
        <ol start="5">
          <li>
            <strong> Bookings.</strong>
          </li>
        </ol>
        <p>
          <span>
            5.1 If a guest makes a booking at any of your Listed Units, any
            agreement you enter into with such guest is between you and the
            guest, and VRP is not a party or responsible for any commitments or
            obligations contained therein.
          </span>
        </p>
        <p>
          <span>
            5.2 You acknowledge and agree that VRP does not act as an insurer or
            as a contracting agent for you as Manager. VRP recommends that you
            obtain appropriate insurance for your business. Please review any
            insurance policies that you may have carefully, and in particular
            please make sure that you are familiar with and understand any
            exclusions to, and any deductibles that may apply for, including,
            but not limited to, whether or not your insurance policy will cover
            the actions or inactions of guests (and the individuals the guest
            invites, if applicable) while renting your Listed Units.
          </span>
        </p>
        <p>
          <span>
            5.3 You acknowledge and agree that you are fully authorized,
            licensed and permitted to provide your Listed Units, including the
            authority to license or rent properties. You acknowledge and agree
            that, as between VRP and yourself, you are responsible for the acts
            and omissions for your employees, contractors, vendors, agents and
            all other parties that are involved in providing your Listed Units.
          </span>
        </p>
        <ol start="6">
          <li>
            <strong> Data Privacy; Security</strong>
            <span>. </span>
          </li>
        </ol>
        <p>
          <span>
            {ReactHtmlParser(`6.1 Data collection and use, including the collection and use of
            personal information, is governed by VRP’s
            <a target="_blank" href="https://www.directsoftware.com/legal/privacy">
              <span>Privacy Policy</span>
            </a>
            , which is incorporated into and is a part of this Agreement.`)}
          </span>
        </p>
        <p>
          <span>
            6.2 VRP shall implement and maintain commercially reasonable
            technical, physical and organizational security measures intended to
            protect and safeguard the data that is provided to VRP by or on
            behalf of you under this Agreement (“
          </span>
          <strong>Manager Data”).</strong>
          <span>
            {' '}
            In the event that VRP becomes aware of any breach of security with
            respect to Manager Data, VRP shall: (i) provide notice of the
            security breach to Manager; (ii) perform an investigation to learn
            the cause of such security breach; and (iii) take all steps
            reasonably necessary to remedy the event and to prevent such
            security breach from reoccurring.
          </span>
        </p>
        <p>
          <span>
            6.3 Manager is the sole and exclusive owner of its Manager Data.
          </span>
        </p>
        <p>
          <span>
            6.4 VRP may de-identify and/or aggregate Manager Data so that it
            does not contain personal information (“
          </span>
          <strong>De-Identified Data</strong>
          <span>”). </span>
        </p>
        <p>
          <span>
            6.5 VRP and its licensors may collect, store and process analytics
            data about the Service and Manager’s use of the Service, that does
            not contain personal information, such as machine data, technical
            data, usage data, systems data, data gathered via cookies and other
            online tracking mechanisms, and other similar types of data (“
          </span>
          <strong>Analytics Data</strong>
          <span>”). </span>
        </p>
        <p>
          <span>
            6.6 VRP is the sole and exclusive owner of all right, title and
            interest in and to Analytics Data and De-Identified Data, which VRP
            may use and share for any business purpose in compliance with
            applicable laws. Without limiting the foregoing, De-Identified Data
            and Analytics Data may be aggregated with other data by VRP and used
            for benchmarking and Service improvement purposes.
          </span>
        </p>
        <p>
          <span>
            6.7 Manager is solely responsible for all Manager Data input into
            the Service, including its legality and accuracy and the resulting
            output. Manager is responsible for backing up any Manager Data input
            into the Service.
          </span>
        </p>
        <ol start="7">
          <li>
            <strong> Right to Cancel, Error, Mistakes. </strong>
            <span>
              The Service may contain technical inaccuracies and typographical
              or other errors in connection with information displayed. VRP
              assumes no responsibility or liability for such errors,
              inaccuracies, or omissions. VRP has the right to make changes,
              corrections or cancellations to such information, at any time,
              including after confirmation of a transaction. VRP is not
              responsible for communication failures, errors, difficulties, or
              other malfunctions or lost, stolen or misdirected transactions,
              transmissions, messages or entries on or in connection with the
              Service. The Service may not be continuously available due to
              maintenance or repairs or due to computer problems, disruptions in
              Internet service or other unforeseen circumstances.
            </span>
          </li>
          <li>
            <strong>Service Plans; Payment.</strong>
          </li>
        </ol>
        <p>
          <span>8.1 </span>
          <strong>Service Plans. </strong>
          <span>
            VRP offers the following four service plans based on the number of
            Manager’s Listed Units: Tier I, Tier II, Tier III, and Tier IV. Manager shall pay a monthly
            subscription fee (“
            <strong>Subscription Fee</strong>
            ”) in each plan. The total amount of Subscription Fees billed
            to Manager is based on the number of Listed Units managed. If the number of Listed Units
            managed changes in a given month, resulting in a shift between plans, the new plan rate will be
            applied at the start of the next billing cycle. For example, if a Manager has 50 Listed Units on
            August 1, Manager will be billed for Tier 1. If Manager’s number of Listed Units increases to 60
            on August 15, then Manager will be charged for Tier 2 at the beginning of the next billing cycle.
          </span>
          <table style={{ border: 'solid', borderCollapse: 'collapse', width:'100%', textAlign:'center' }}>
            <tr>
              <th style={{ border: 'solid' }}>Service Plan</th>
              <th style={{ border: 'solid' }}>Listed Units</th>
              <th style={{ border: 'solid' }}>Monthly Subscription Fee</th>
            </tr>
            <tr>
              <td style={{ border: 'solid' }}>Tier I</td>
              <td style={{ border: 'solid' }}>1-50</td>
              <td style={{ border: 'solid' }}>$30</td>
            </tr>
            <tr>
              <td style={{ border: 'solid' }}>Tier II</td>
              <td style={{ border: 'solid' }}>51-100</td>
              <td style={{ border: 'solid' }}>$25</td>
            </tr>
            <tr>
              <td style={{ border: 'solid' }}>Tier III</td>
              <td style={{ border: 'solid' }}>101-150</td>
              <td style={{ border: 'solid' }}>$20</td>
            </tr>
            <tr>
              <td style={{ border: 'solid' }}>Tier IV</td>
              <td style={{ border: 'solid' }}>151+</td>
              <td style={{ border: 'solid' }}>$15</td>
            </tr>
          </table>
          <span>
            For 10 Listed Units or fewer, a minimum charge of $350/month applies. VRP will charge the
            appropriate Subscription Fee to Manager’s payment account at the start of each month
            beginning on the Effective Date. Separate onboarding fees will apply. At Manager’s option,
            Manager may pay amounts due via ACH or wire transfer via VRP’s third-party payment
            processor. Manager shall execute a Direct Order Form and pay a one-time implementation fee
            (“
            <strong>Implementation Fee</strong>
            ”) as detailed in Section 8.3.
          </span>
        </p>
        <p>
          <span>8.2 </span>
          <strong>Seasonal Operators.</strong>
          <span>
            Seasonal operators can opt for a hybrid pricing model, 
            combining a reduced Subscription Fee with a percentage of each transaction. Details of this 
            model are available upon request.</span>
        </p>
        <p>
          <span>8.3 </span>
          <strong>Implementation Fee.</strong>
          <span>
            Upon execution of a Direct Order Form, Manager shall pay a 
            one-time Implementation Fee. The amount of each Implementation Fee varies based on the 
            level of effort determined for each onboarding project.
          </span>
        </p>
        <ol start="9">
          <li>
            <strong>Website Development. </strong>
            <span>
              From time to time VRP may create websites, customer relationship
              management tools and other online features for use by Manager (the
              “
            </span>
            <strong>Online Assets</strong>
            <span>
              ”). Manager acknowledges and agrees that the Online Assets are the
              sole property of VRP and, notwithstanding the inclusion of
              Manager’s marks, are not a “work made for hire” under the
              Copyright Act or any other law. To the extent that the foregoing
              does not apply, Manager hereby irrevocably assigns to VRP, and its
              successors and assigns, for no additional consideration, Manager’s
              entire right, title and interest in and to the Online Assets and
              any intellectual property rights contained therein. Nothing
              contained in this Agreement shall be construed to reduce or limit
              VRP’s right, title or interest in the Online Assets. Further,
              Manager agrees to stop using and return to VRP the Online Assets
              at VRP’s request or upon the termination or expiration of this
              Agreement. This section shall not apply to any websites created by
              Manager (“
            </span>
            <strong>Manager Assets</strong>
            <span>
              ”), which shall remain the sole property of Manager unless
              otherwise agreed to by the parties.
            </span>
          </li>
          <li>
            <strong> Disclaimers.</strong>
          </li>
        </ol>
        <p>
          <span>
            10.1 YOUR USE OF THE SERVICE IS AT YOUR RISK. THE CONTENT, MATERIALS
            AND SERVICE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS
            WITHOUT ANY WARRANTIES OF ANY KIND INCLUDING WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SECURITY OR
            NON-INFRINGEMENT OF INTELLECTUAL PROPERTY. NEITHER VRP, NOR ANY OF
            ITS AFFILIATES, SUPPLIERS, SERVICE SUPPLIERS OR LICENSORS WARRANT
            THE ACCURACY OR COMPLETENESS OF THE CONTENT OR MATERIALS PROVIDED ON
            OR THROUGH THE SERVICE NOR THAT THE SERVICE WILL MEET YOUR
            REQUIREMENTS. THE SERVICE COULD INCLUDE TECHNICAL OR OTHER MISTAKES,
            INACCURACIES OR TYPOGRAPHICAL ERRORS. WE MAY MAKE CHANGES TO THE
            CONTENT AND SERVICE AT THE DIRECT WEBSITE, INCLUDING THE PRICES AND
            DESCRIPTIONS OF ANY PRODUCTS OR SERVICES LISTED HEREIN, AT ANY TIME
            WITHOUT NOTICE. THE FOREGOING EXCLUSIONS OF IMPLIED WARRANTIES DO
            NOT APPLY TO THE EXTENT PROHIBITED BY LAW. NO ADVICE OR INFORMATION,
            WHETHER ORAL OR WRITTEN, OBTAINED FROM VRP OR THROUGH THE SERVICE,
            CONTENT AND SERVICE WILL NOT CREATE ANY WARRANTY NOT EXPRESSLY MADE
            HEREIN.
          </span>
        </p>
        <p>
          <span>
            10.2 MANAGER IS AN INDEPENDENT CONTRACTOR AND NOT AN AGENT OR
            EMPLOYEE OF VRP OR ITS AFFILIATES. VRP AND ITS AFFILIATES ARE NOT
            LIABLE FOR THE ACTS, ERRORS, OMISSIONS, REPRESENTATIONS, WARRANTIES,
            BREACHES OR NEGLIGENCE OF SUPPLIER OR FOR ANY PERSONAL INJURIES,
            DEATH, PROPERTY DAMAGE, OR OTHER DAMAGES OR EXPENSES RESULTING
            THEREFROM. VRP AND ITS AFFILIATES HAVE NO LIABILITY AND WILL MAKE NO
            REFUND IN THE EVENT OF ANY DELAY, CANCELLATION, OVERBOOKING, STRIKE,
            FORCE MAJEURE OR OTHER CAUSES BEYOND THEIR DIRECT CONTROL, AND THEY
            HAVE NO RESPONSIBILITY FOR ANY ADDITIONAL EXPENSE, OMISSIONS,
            DELAYS, RE-ROUTING OR ACTS OF ANY GOVERNMENT OR AUTHORITY. VRP, ITS
            AFFILIATES AND THEIR RESPECTIVE SUPPLIERS MAKE NO GUARANTEES ABOUT
            THE AVAILABILITY OF SPECIFIC PRODUCTS AND SERVICES.
          </span>
        </p>
        <ol start="11">
          <li>
            <strong>
              {' '}
              Limitations of Liability; Limitation on Time to File Claims.
            </strong>
          </li>
        </ol>
        <p>
          <span>
            11.1 You expressly understand and agree that VRP does not assume any
            responsibility for and is not liable for any damages to your
            computer, equipment or other property caused by or arising from your
            access to, use of, or browsing the Service, or your downloading of
            any information or materials from this Service. IN NO EVENT WILL
            VRP, OR ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS,
            AFFILIATES, AGENTS, SUCCESSORS OR ASSIGNS, BE LIABLE TO YOU OR
            ANYONE ELSE FOR ANY INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL OR
            CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, THOSE
            RESULTING FROM LOST PROFITS, LOSS OF GOODWILL, LOST DATA OR BUSINESS
            INTERRUPTION) ARISING OUT OF THE USE, INABILITY TO USE, OR THE
            RESULTS OF USING THE SERVICE, ANY WEBSITES OR APPLICATIONS LINKED TO
            THE SERVICE, OR THE MATERIALS, INFORMATION OR OTHER CONTENT
            CONTAINED ON THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT
            OR ANY OTHER LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATIONS OF LIABILITY
            DO NOT APPLY TO THE EXTENT PROHIBITED BY LAW. IN JURISDICTIONS THAT
            DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, VRP’S LIABILITY IN SUCH JURISDICTIONS SHALL
            BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
          </span>
        </p>
        <p>
          <span>
            11.2 IN NO EVENT SHALL VRP’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES,
            LOSSES, AND CAUSES OF ACTION WHETHER IN CONTRACT, TORT (INCLUDING,
            BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE EXCEED THE SERVICE
            FEES YOU PAID TO VRP HEREUNDER IN THE SIX MONTH PERIOD ENDING ON THE
            DATE THAT A CLAIM OR DEMAND IS FIRST ASSERTED.
          </span>
        </p>
        <p>
          <span>
            11.3 TO THE EXTENT ALLOWED BY APPLICABLE LAW, YOU AGREE THAT YOU
            WILL BRING ANY CLAIM OR CAUSE OF ACTION ARISING FROM OR RELATING TO
            YOUR ACCESS OR USE OF THIS SERVICE WITHIN ONE (1) YEAR FROM THE DATE
            ON WHICH SUCH CLAIM OR ACTION AROSE OR ACCRUED OR SUCH CLAIM OR
            CAUSE OF ACTION WILL BE IRREVOCABLY WAIVED.
          </span>
        </p>
        <ol start="12">
          <li>
            <strong> Indemnity. </strong>
            <span>
              You agree to defend, indemnify and hold VRP and its affiliates,
              and its and their equity holders, directors, officers, employees
              and agents, harmless from any and all liabilities, costs, and
              expenses, including reasonable attorneys’ fees, related to or in
              connection with (i) the use or misuse of the Service or your
              placement or transmission of any message or information on this
              Service by you or your authorized users; (ii) your violation of
              any term of this Agreement, including without limitation, your
              breach of any of the representations and warranties above; (iii)
              your violation of any third-party right, including without
              limitation any right of privacy, publicity rights or intellectual
              property rights, including any such violation arising from
              Content, or VRP’s use of Content; (iv) your violation of any law,
              rule or regulation of the United States or any other country,
              including data protection laws; or (v) any claim or damages that
              arise as a result of any information or data that you provide to
              VRP.
            </span>
          </li>
          <li>
            <strong> Term; Termination; Survival.</strong>
          </li>
        </ol>
        <p>
          <span>
            13.1 Subject to Section 8 above, unless otherwise set forth in an
            Order Form, the Agreement will commence on the Effective Date and
            shall continue for a period of one (1) year (the “
          </span>
          <strong>Initial Term</strong>
          <span>
            ”). After the expiration of the Initial Term, the Agreement shall
            automatically renew pursuant to the then-current pricing schedule
            available at
          </span>
          <a
            target="_blank"
            href="https://www.directsoftware.com/pricing"
            rel="noreferrer"
          >
            <span>https://www.directsoftware.com/pricing</span>
          </a>
          <span>
            for successive one (1) year periods unless Manager provides VRP with
            sixty (60) days prior written notice of its intent not to renew (“
          </span>
          <strong>Notice of Termination</strong>
          <span>
            ”). In the event of termination, Manager shall be responsible for
            all payments due in connection with its then-current billing cycle,
            in addition to the two (2) billing cycles following Manager’s Notice
            of Termination.
          </span>
        </p>
        <p>
          <span>
            13.2 Manager will have a period of thirty (30) days from the date of
            the Notice of Termination or termination date to request access to
            the Manager Data maintained by VRP (“
          </span>
          <strong>Transition Period</strong>
          <span>
            ”). Upon expiration of the Transition Period, VRP shall permanently
            delete all Manager Data.
          </span>
        </p>
        <p>
          <span>
            13.3 In the event Manager fails to pay any fees required under this
            Agreement, VRP will provide notice to Manager and an opportunity to
            cure any non-payment within fifteen (15) days of such notice (“
          </span>
          <strong>Cure Period</strong>
          <span>
            ”). In the event Manager’s failure to pay continues after the Cure
            Period, VRP reserves the right to suspend Manager’s access to the
            Service until payment has been made. VRP reserves the right to
            terminate this Agreement in the event Manager continuously fails to
            pay any required fees under this Agreement (“
          </span>
          <strong>Chronic Failures</strong>
          <span>
            ”). In the event of VRP’s termination for Chronic Failures, Manager
            will not receive any prorated refunds.
          </span>
        </p>
        <p>
          <span>
            13.4 VRP reserves the right to terminate this Agreement if Manager
            is inactive on the Service or does not have at least one (1) Listed
            Unit in the Service for at least a period of one (1) year from the
            Effective Date.
          </span>
        </p>
        <p>
          <span>
            13.5 All provisions of this Agreement that by their nature are
            intended to extend beyond the termination of this Agreement for any
            reason shall survive the termination of this Agreement.
          </span>
        </p>
        <ol start="14">
          <li>
            <strong> Miscellaneous.</strong>
          </li>
        </ol>
        <p>
          <span>14.1 </span>
          <strong>Governing Law and Venue. </strong>
          <span>
            This Agreement, and any disputes arising hereunder, shall be
            governed by the laws of the state of Illinois, United States,
            without regard to its conflicts of law principles. Customer agrees
            to exclusive jurisdiction and venue in the state and federal courts
            having jurisdiction in Cook County, Illinois, U.S. and irrevocably
            consents to personal jurisdiction in such courts. The parties
            specifically exclude application of the United Nations Convention on
            Contracts for the International Sale of Goods and the Uniform
            Computer Information Transactions Act (UCITA) to this Agreement.
          </span>
        </p>
        <p>
          <span>14.2 </span>
          <strong>Compliance with Laws. </strong>
          <span>
            Each party shall comply with all applicable laws, rules and
            regulations relating to its performance under this Agreement.
            Without limiting the generality of the foregoing, Manager shall
            comply with all applicable import and export laws and regulations
            related to the Service for the country where the Service is used by
            Manager as well as with all applicable United States export laws
            (collectively, the “
          </span>
          <strong>Export Laws</strong>
          <span>
            ”). Manager shall not, and shall not permit any third party to: (i)
            export or transmit the Service, directly or indirectly, in violation
            of Export Laws, including to a country subject to a U.S. embargo;
            (ii) access or use the Service in violation of any Export Laws;
            (iii) provide access to the Service across international boundaries
            except in compliance with any Export Laws, including those of the
            originating country.
          </span>
        </p>
        <p>
          <span>14.3 </span>
          <strong>Independent Contractors. </strong>
          <span>
            Both parties shall be, and shall act as, independent contractors.
            Neither party shall have authority to create any obligation or make
            any commitments on the other party’s behalf.
          </span>
        </p>
        <p>
          <span>14.4 </span>
          <strong>Notices. </strong>
          <span>
            All notices under this Agreement will be in writing and will be
            deemed to have been duly given when received, if personally
            delivered; when receipt is electronically confirmed, if transmitted
            by facsimile or e-mail; the day after it is sent, if sent for next
            day delivery by recognized overnight delivery service; and upon
            receipt, if sent by certified or registered mail, return receipt
            requested.
          </span>
        </p>
        <p>
          <span>14.5 </span>
          <strong>Use of Names. </strong>
          <span>
            Neither party may use the names, trademarks, trade names, service
            marks, insignia, or logos (“
          </span>
          <strong>Marks</strong>
          <span>
            ”) of the other party without such other party&apos;s prior written
            consent. Notwithstanding the foregoing, VRP may publically refer to
            Manager as a customer of VRP online, verbally, and in its marketing
            materials.
          </span>
          <strong> </strong>
        </p>
        <p>
          <span>14.6 </span>
          <strong>Assignment. </strong>
          <span>
            This Agreement is not assignable by Manager except with VRP’s prior
            written consent, and any such assignment in violation hereof is
            void. VRP may transfer and assign any of its rights and obligations
            under this Agreement without consent.
          </span>
        </p>
        <p>
          <span>14.7 </span>
          <strong>Force Majeure.</strong>
          <span>
            No delay, failure, or default by VRP, will constitute a breach of
            this Agreement to the extent it results of causes beyond the
            reasonable control of VRP, including acts of war, terrorism,
            weather, acts of God or nature, strikes, labor disputes, riots,
            civil disorder, embargo, internet outage, health crisis, pandemic,
            earthquake or the like.
          </span>
        </p>
        <p>
          <span>14.8 </span>
          <strong>Waiver.</strong>
          <span>
            Neither party shall be deemed to have waived any of its rights under
            this Agreement by lapse of time or by any statement or
            representation other than by an authorized representative in an
            explicit signed, written waiver. No waiver of a breach of this
            Agreement by either party will constitute a waiver of any other
            breach of this Agreement.
          </span>
        </p>
        <p>
          <span>14.9 </span>
          <strong>Severability.</strong>
          <span>
            In the event a court of competent jurisdiction finds any provision
            of this Agreement invalid or unenforceable, such provision will be
            interpreted to fulfill its intended purpose to the maximum extent
            permitted by applicable law, and if the foregoing is not possible,
            such provision shall be severed from the Agreement. All remaining
            provisions shall continue in full force and effect.
          </span>
        </p>
        <p>
          <span>14.10 </span>
          <strong>Entire Agreement. </strong>
          <span>
            This Agreement (including any applicable Order Form and all other
            documents reference herein) constitutes the entire agreement between
            the parties with regard to the subject matter herein and supersede
            all prior or contemporaneous negotiations, discussions,
            understandings or agreements between the parties. Neither party has
            relied upon any such prior or contemporaneous communications. Any
            amendment or modification to this Agreement must be in writing and
            signed by the authorized representatives of both parties to be
            effective.
          </span>
        </p>
      </div>
    </figure>
  );
};

TermsConditionsAug2023.propTypes = {
  isContained: PropTypes.bool
};

TermsConditionsAug2023.defaultProps = {
  isContained: true
};

export default TermsConditionsAug2023;
