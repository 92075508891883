import styled from "styled-components";
export const FilterOptionLi = styled.li`
  padding: 2% 1%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: white;
  overflow: hidden;
  &:hover {
    background: #f5f5f5;
    cursor: pointer;
  }
`;
export const DivWithWidthRestrictedBorder = styled.div`
  width: 98%;
  margin 0 auto;
  border-bottom: solid lightgrey 1px;
`;
export const Label = styled.div`
  text-transform: uppercase;
`;
export const LeftContainer = styled.div`
  font-weight: bold;
`;
export const Icon = styled.i`
  margin-right: 5px;
`;
export const OptionContainer = styled.ul``;
export const MainContainer = styled.div`
  max-width: 75%;
  margin-left: 4%;
`;
