import React from 'react';
import PropTypes from 'prop-types';

const organizationStatus = ({ status }) => {
  // eslint-disable-next-line default-case
  switch (status) {
    case 'green':
      return 'Active';
    case 'yellow':
      return 'Inactive';
    default:
      return 'Incomplete';
  }
};

export default class ListOrganizationsActionCards extends React.Component {
  static propTypes = {
    organizations: PropTypes.array.isRequired
  };

  constructor(props, _railsContext) {
    super(props);
  }

  render() {
    return (
      <ul className="action-cards wrapper-s">
        {this.props.organizations.map(organization => (
          <li
            key={organization.id}
            className={`action-card ${
              organization.is_onboarded
                ? `${organization.is_active ? 'action-green' : 'action-yellow'}`
                : 'action-red'
            }`}
          >
            <a href={`/dashboard/${organization.subdomain}`}>
              <header className="action-head">
                <h3>{organization.name}</h3>
                <span
                  className={`action-status ${
                    organization.is_onboarded
                      ? `${
                          organization.is_active
                            ? 'action-green'
                            : 'action-yellow'
                        }`
                      : 'action-red'
                  }`}
                >
                  {organization.is_onboarded
                    ? `${organization.is_active ? 'Active' : 'Inactive'}`
                    : 'Incomplete'}
                </span>
              </header>
              <main className="action-body">
                <ul className="action-item-list">
                  <li>
                    <span>Brands: </span>
                    <b>{organization.total_brands || 0}</b>
                  </li>
                  <li>
                    <span>Domains: </span>
                    <b>{organization.total_domains || 0}</b>
                  </li>
                  <li>
                    <span>Users: </span>
                    <b>{organization.total_employees || 0}</b>
                  </li>
                  <li>
                    <span>Properties: </span>
                    <b>{organization.total_properties || 0}</b>
                  </li>
                  <li>
                    <span>Units: </span>
                    <b>{organization.total_units || 0}</b>
                  </li>
                  <li>
                    <span>Listings: </span>
                    <b>{organization.total_unit_listings}</b>
                  </li>
                </ul>
              </main>
            </a>
          </li>
        ))}
      </ul>
    );
  }
}
