import React from 'react';
import { InputCheckbox, Label } from '@directsoftware/ui-kit-web-admin';

export default class FeatureCheckbox extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <InputCheckbox
        name={this.props.fieldKey}
        id={this.props.fieldKey}
        value={this.props.fieldKey}
        checked={this.props.field.value}
        onChange={e => this.props.updateIndividualField(e)}
      >
        <Label htmlFor={this.props.fieldKey}>
          {this.props.field.label} {this.props.children}
        </Label>
      </InputCheckbox>
    );
  }
}
