import React from 'react';
import styled from 'styled-components';

const InputNumber = ({
  onChange,
  name,
  defaultValue,
  style,
  integerOnly,
  className,
  min
}) => {
  return (
    <InputContainer>
      <input
        name={name}
        onChange={onChange}
        min={min}
        className={className}
        onInput={i =>
          integerOnly ? (i.target.value = Math.round(i.target.value)) : {}
        }
        defaultValue={defaultValue}
        type="number"
        style={style}
      />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  max-width: 320px;
`;

InputNumber.defaultProps = {
  onChange: () => null,
  name: '',
  defaultValue: 1,
  min: 1
};

export default InputNumber;
