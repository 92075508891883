import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  CallToActionLink,
  IconFontAwesome,
  IconLink,
  PageHeader,
  VisibilityHelper
} from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { PEOPLE_NAV_TAGS } from '../../../../../shared/nav';

const EmployeesHome = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: PEOPLE_NAV_TAGS.PRIMARY,
        secondary: PEOPLE_NAV_TAGS.secondary.EMPLOYEES
      })
    );
  }, []);

  return (
    <PageHeader>
      <PageHeader.Left>
        People: <span>Users</span>
      </PageHeader.Left>
      <PageHeader.Right>
        <VisibilityHelper hideStartingFrom="tablet">
          <IconLink href="/employees/new" size="dense">
            <IconFontAwesome name="plus" />
          </IconLink>
        </VisibilityHelper>
        <VisibilityHelper showStartingFrom="tablet">
          <CallToActionLink
            href="/employees/new"
            prependIcon={<IconFontAwesome name="plus" />}
          >
            Create
          </CallToActionLink>
        </VisibilityHelper>
      </PageHeader.Right>
    </PageHeader>
  );
};

export default EmployeesHome;
