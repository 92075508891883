// Dependencies
// -----------------------------------------------
import React from 'react';

// Components
// -----------------------------------------------
import { SimplifiedWysiwyg } from 'adminComponents';

// Styled Components
// -----------------------------------------------
import { RadioOption } from './styled';

// ------------------------------------------------
// COMPONENT->OVERVIEW-SINGLE-FIELDS-INSTRUCTIONS -
// ------------------------------------------------
const OverviewSingleFieldsInstructions = props => {
  const onInstructionsChange = (name, value) => {
    let newInstructions = props.instructions;
    newInstructions[name] = value;
    props.onInstructionsChange('check_in_instructions', newInstructions);
  };

  // ---------------------------------------------
  // RETURN --------------------------------------
  // ---------------------------------------------
  return (
    <fieldset>
      <header className="fieldset-header">
        <legend>Check-ins</legend>
        <p>Tell us how guests check-in.</p>
      </header>

      <label>
        <span>How will guests gain access to the home?</span>
      </label>
      <form
        onClick={e => onInstructionsChange(e.target.name, e.target.value)}
        style={{ marginBottom: '16px' }}
      >
        <RadioOption>
          <input
            type="radio"
            name="access_code_type"
            value="last_4_digits"
            checked={props.instructions.access_code_type == 'last_4_digits'}
          />
          <label>
            Unique 4 digit lock box code provided by system (unique for each
            rental)
          </label>
        </RadioOption>
        <RadioOption>
          <input
            type="radio"
            name="access_code_type"
            value="custom_code"
            checked={props.instructions.access_code_type == 'custom_code'}
          />
          <label>Use the same code I provide for every rental</label>
        </RadioOption>
        <RadioOption>
          <input
            type="radio"
            name="access_code_type"
            value="other"
            checked={props.instructions.access_code_type == 'other'}
          />
          <label>Other (please specify below)</label>
        </RadioOption>
      </form>

      {props.instructions.access_code_type == 'custom_code' && (
        <div>
          <label htmlFor="access_code">
            Enter code here and include instructions for what to do with this
            code below
          </label>
          <input
            type="text"
            name="access_code"
            className="magnified"
            placeholder="Custom Access Code"
            value={props.instructions.access_code}
            onChange={e => onInstructionsChange(e.target.name, e.target.value)}
          />
        </div>
      )}

      <label htmlFor="additional">
        <span>
          Additional Instructions (e.g. location of the lock box, security
          system codes and instructions, garage door codes, etc.)
        </span>
      </label>
      <SimplifiedWysiwyg
        content={props.instructions.additional}
        name="additional"
        onContentChange={onInstructionsChange}
      />
    </fieldset>
  );
};

// -----------------------------------------------
// EXPORT ----------------------------------------
// -----------------------------------------------
export default OverviewSingleFieldsInstructions;
