import React, { useEffect, useState } from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import displayError from 'sharedComponents/ErrorDisplay';
import RateGroupRangeService from 'adminApi/RateGroupRangesService';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';

const RateGroupRangesDetails = props => {
  const dispatch = useDispatch();
  const [rateGroupId, setRateGroupId] = useState('');
  const [rateGroupName, setRateGroupName] = useState('');
  const [rateGroupRangeName, setRateGroupRangeName] = useState('');

  useEffect(
    () => {
      RateGroupRangeService.fetch(
        props.organization.id,
        props.rateGroupRangeId,
        ''
      )
        .then(response => {
          setRateGroupRangeName(response.name);
          setRateGroupName(response.rate_group_name);
          setRateGroupId(response.rate_group_id);
        })
        .catch(err =>
          displayError({ message: 'Error Loading Rate Group', err })
        );
    },
    [props]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: LISTINGS_NAV_TAGS.PRIMARY,
        secondary: LISTINGS_NAV_TAGS.secondary.RATE_GROUPS
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/rate-groups',
            label: 'Rate Groups'
          },
          {
            href: `/rate-groups/${rateGroupId}`,
            label: rateGroupName
          },
          {
            label: 'Details'
          }
        ]}
      >
        {rateGroupRangeName}
      </PageHeader.Left>
    </PageHeader>
  );
};

export default RateGroupRangesDetails;
