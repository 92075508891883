import React from 'react';
import PropTypes from 'prop-types';
import { IndicatorToggle } from 'adminComponents';
import { dispatchError } from '../../../components/error-alert';
import { ajaxDataWithAuthorization } from 'apiClient';

export default class ManageOrganizationLock extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired
  };
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      can_delete: false,
      input_name: '',
      locked: this.props.organization.locked
    };
  }

  checkNameMatch = e => {
    const value = e.target.value;
    const valueIsName =
      value.toLowerCase() === this.props.organization.name.toLowerCase();
    let stateChange = {};
    stateChange['input_name'] = value;
    stateChange['can_lock'] = valueIsName;
    this.setState(stateChange);
  };

  lockOrganization = () => {
    $.ajax(
      ajaxDataWithAuthorization({
        type: 'PATCH',
        url:
          '/api/organizations/' +
          this.props.organization.id +
          '?locked=' +
          !this.state.locked,
        context: this,
        dataType: 'json'
      })
    )
      .done(function(data) {
        this.setState({ locked: !this.state.locked });
      })
      .fail(function(jqXhr) {
        dispatchError();
        console.warn(jqXhr);
      });
  };

  description = locked => {
    return locked
      ? "Unlocking your organization will allow all the organization's users to log in."
      : 'Locking your organization will prevent everyone but super admins from logging in.';
  };

  render() {
    const { locked } = this.state;
    return (
      <figure id="section-delete" className={`consolidated-item-form-section`}>
        <header className="consolidated-item-header">
          <h3>{locked ? 'Unlock organization' : 'Lock Organization'}</h3>
        </header>
        <section className="consolidated-item-form-viewing">
          <p>
            {this.description(locked)} Please validate that this is the action
            you want to take by typing this organization's name below.
            <h2 style={{ paddingTop: '5px', paddingBottom: '5px' }}>
              {this.props.organization.name}
            </h2>
          </p>
          <label>
            <span>Organization name</span>
          </label>
          <input
            className="magnified"
            onChange={this.checkNameMatch}
            type="text"
            value={this.state.input_name}
          />
          {this.state.can_lock && (
            <>
              <label>
                {locked ? 'Unlock organization' : 'Lock Organization'}
              </label>
              <IndicatorToggle
                toggleAction={() => this.lockOrganization()}
                toggleStatus={this.state.locked}
                toggleFalseLabel="Unlocked"
                toggleTrueLabel="Locked"
              />
            </>
          )}
        </section>
      </figure>
    );
  }
}
