// Dependencies
// -----------------------------------------------
import React from 'react';
import deepcopy from 'deepcopy';
import find from 'lodash/find';

// Components
// -----------------------------------------------
import { FeatureCheckbox } from 'adminComponents';
import {
  CallToActionButton,
  EditableLineItem,
  InputSelect,
  Spacer
} from '@directsoftware/ui-kit-web-admin';
import { checkPermissions } from 'sharedNav/helpers';

// -----------------------------------------------
// COMPONENT->INDIVIDUAL-BATHROOM-FIELDS ---------
// -----------------------------------------------
export default class IndividualBathroomFields extends React.Component {
  state = {
    amenities: this.props.bathroom.amenities,
    amenities_cache: this.props.bathroom.amenities,
    type: this.props.bathroom.bathroom_type,
    type_cache: this.props.bathroom.bathroom_type,
    isDirty: false
  };

  toggleEditing = () => {
    this.setState({
      editing: !this.state.editing,
      isDirty: false
    });
  };

  toggleEditingAndSave = () => {
    this.props.updateBathroom({
      index: this.props.index,
      amenities: this.state.amenities,
      bathroom_type: this.state.type
    });
    this.setState({
      amenities_cache: this.state.amenities,
      type_cache: this.state.type
    });
    this.toggleEditing();
  };

  toggleEditingAndRevert = e => {
    this.setState({
      amenities: this.state.amenities_cache,
      type: this.state.type_cache
    });
    this.toggleEditing(e);
  };

  updateAmenity = amenityValue => {
    const amenities = deepcopy(this.state.amenities);
    amenities[amenityValue].value = !amenities[amenityValue].value;
    this.setState({
      amenities,
      isDirty: true
    });
  };

  updateType = value => {
    this.setState({
      type: value.value,
      isDirty: true
    });
  };

  renderEditing() {
    const bathroomTypeOptions = [
      { value: 'full', label: 'Full Bath' },
      { value: 'half', label: 'Half Bath' },
      { value: 'shower', label: 'Shower Only' }
    ];

    return (
      <div>
        <label htmlFor="bathroom_type">Type of bathroom</label>
        <InputSelect
          name="bathroom_type"
          className="magnified"
          options={bathroomTypeOptions}
          isClearable={false}
          isSearchable={false}
          simpleValue
          placeholder="Select bathroom type"
          value={find(bathroomTypeOptions, { value: this.state.type })}
          onChange={this.updateType}
        />
        <Spacer />
        {Object.keys(this.state.amenities).map(fieldKey => (
          <FeatureCheckbox
            key={fieldKey + this.props.index}
            id={this.props.index}
            fieldKey={fieldKey}
            field={this.state.amenities[fieldKey]}
            updateIndividualField={this.updateAmenity}
          />
        ))}
      </div>
    );
  }

  renderViewing() {
    return (
      <div>
        <div>
          {this.state.type == 'full' ? 'Full Bath' : null}
          {this.state.type == 'half' ? 'Half Bath' : null}
          {this.state.type == 'shower' ? 'Shower Only' : null}
        </div>
        <br />
        <div>
          {this.state.amenities.AMENITY_BIDET.value == true &&
            this.state.amenities.AMENITY_BIDET.label}
        </div>
        <div>
          {this.state.amenities.AMENITY_COMBO_TUB_SHOWER.value == true &&
            this.state.amenities.AMENITY_COMBO_TUB_SHOWER.label}
        </div>
        <div>
          {this.state.amenities.AMENITY_JETTED_TUB.value == true &&
            this.state.amenities.AMENITY_JETTED_TUB.label}
        </div>
        <div>
          {this.state.amenities.AMENITY_OUTDOOR_SHOWER.value == true &&
            this.state.amenities.AMENITY_OUTDOOR_SHOWER.label}
        </div>
        <div>
          {this.state.amenities.AMENITY_SHOWER.value == true &&
            this.state.amenities.AMENITY_SHOWER.label}
        </div>
        <div>
          {this.state.amenities.AMENITY_TOILET.value == true &&
            this.state.amenities.AMENITY_TOILET.label}
        </div>
        <div>
          {this.state.amenities.AMENITY_TUB.value == true &&
            this.state.amenities.AMENITY_TUB.label}
        </div>
      </div>
    );
  }

  renderHeaderEditing() {
    return (
      <header
        className="input-group-header"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <h3>
          Bathroom
          {this.props.index + 1}
        </h3>
        <div className="button-container">
          <CallToActionButton
            onClick={e => this.toggleEditingAndSave(e)}
            isDisabled={!this.state.isDirty}
          >
            Save
          </CallToActionButton>
          <Spacer size="xs" direction="horizontal" />
          <CallToActionButton
            onClick={e => this.toggleEditingAndRevert(e)}
            variation="secondary"
            appearance="ghost"
          >
            Cancel
          </CallToActionButton>
        </div>
      </header>
    );
  }

  renderHeaderViewing() {
    return (
      <header className="input-group-header">
        <h3>
          Bathroom
          {this.props.index + 1}
        </h3>
        <div className="button-container">
          <CallToActionButton
            appearance="outline"
            variation="secondary"
            onClick={e => this.toggleEditing(e)}
          >
            Edit
          </CallToActionButton>
        </div>
      </header>
    );
  }

  // ---------------------------------------------
  // RENDER --------------------------------------
  // ---------------------------------------------
  render() {
    return (
      <>
        <EditableLineItem
          label={`Bathroom ${this.props.index + 1}`}
          customDisplayView={this.renderViewing()}
          customEditView={this.renderEditing()}
          saveAction={() => this.toggleEditingAndSave()}
          saveIsDisabled={!this.state.isDirty}
          primaryActionLabel="Update"
          primaryActionType={
            checkPermissions(
              this.props.user,
              'view_properties',
              'properties_read_only'
            )
              ? 'button'
              : 'none'
          }
        />
      </>
    );
  }
}
