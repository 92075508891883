import React, { useEffect, useState } from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import PromotionService from 'adminApi/PromotionService';
import displayError from 'sharedComponents/ErrorDisplay';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';

const Promotions = props => {
  const dispatch = useDispatch();
  const [promotionName, setPromotionName] = useState('');

  useEffect(
    () => {
      PromotionService.get(props.organization.id, props.promotionId)
        .then(promotion => {
          setPromotionName(promotion.name);
        })
        .catch(err =>
          displayError({ message: 'Error Loading Promotion', err })
        );
    },
    [props]
  );

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: LISTINGS_NAV_TAGS.PRIMARY,
        secondary: LISTINGS_NAV_TAGS.secondary.PROMOTIONS
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: '/promotions',
            label: 'Promotions'
          },
          {
            label: 'Details'
          }
        ]}
      >
        {promotionName}
      </PageHeader.Left>
    </PageHeader>
  );
};

export default Promotions;
