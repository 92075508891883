import styled from "styled-components";
import { Link } from "react-router-dom";
const ConversationsRouter = styled.div`
  padding: 16px;
`;
const ConversationsContainer = styled.div`
  border-top: 1px solid #f1f3f6;
`;
const StyledLinkContainer = styled.div`
  background: #f1f3f6;
  height: 36px;
  width: 36px;
  border-radius: 4px;
  margin-right: 12px;
  position: relative;
  display: inline-block;
  vertical-align: bottom;

  &:hover,
  &:focus {
    cursor: pointer;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #606b6f;
    width: 16px !important;
    height: 16px !important;
  }
`;
const StyledLink = styled(Link)`
  border-bottom: 0px solid transparent !important;
`;
const PaginationContainer = styled.div`
  .consolidated-key {
    ul {
      margin-top: 16px;
    }
    li {
      margin-right: 16px;

      a {
        color: #0275d8;
        text-transform: uppercase;
      }

      &.active {
        border-bottom: 0px;

        a {
          color: #404040;
        }
      }

      &.next {
        margin-right: 0px;
      }

      &.disabled {
        a {
          color: #bbc5c7;

          &:hover {
            cursor: default;
          }
        }
      }
    }

    @media (max-width: 450px) {
      width: 100%;

      .consolidated-key {
        width: 100%;
        padding: 0 0 16px;

        .no-wrap {
          float: none;
        }

        li {
          margin: 12px 10px 0;
        }
      }
    }
  }
`;
const MessageAccordion = styled.div`
  position: relative;

  .line-separator {
    border-bottom: 1px solid #f1f3f6;
    margin: 0.5em 0 0.5em 0;
  }

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%);
    background: #f1f3f6;
    font-size: 8px;
    color: #8b999f;
    font-weight: bold;
    padding: 4px;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
    }
  }
`;
export {
  ConversationsRouter,
  PaginationContainer,
  ConversationsContainer,
  MessageAccordion,
  StyledLinkContainer,
  StyledLink
};
