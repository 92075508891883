import React from 'react';
import find from 'lodash/find';
import CurrencyDefinitions from '../utils/currency-definitions';

export const nativeSymbolFromValue = value => {

  return find(CurrencyDefinitions, def => {
    return def.value === value;
  })?.symbol_native || '';
};


export const CurrencyDisplay = props => (
  <div style={{ display: 'inline' }}>
    {nativeSymbolFromValue(props.currencyValue)}
  </div>
);
