import React from 'react';
import { ConfigContext } from 'adminContexts';

const ConfigProvider = props => {
  const config = {
    viewingVehicle: () => window.location.href.includes('vehicles')
  }

  return (
    <ConfigContext.Provider value={config}>
      {props.children}
    </ConfigContext.Provider>
  )
}

export default ConfigProvider;
