import React from 'react';
import UserAvatar from 'adminComponents/Avatars/UserAvatar';
import { Message, MessageContainer, TextBgOverride } from '../SectionsStyles';
import { OrgFormattedDate } from 'adminComponents';
import styled from 'styled-components';
import moment from 'moment';

const PreApprovalButton = styled.button`
  background: #fff;
  border: 1px solid #ff5a5f;
  border-radius: 2px;
  box-shadow: 0 3px 5px -4px rgba(26, 25, 25, 0.5);
  color: #ff5a5f;
  cursor: pointer;
  display: inline-block;
  line-height: 11.312px;
  font-family: 'Rajdhani', Helvetica, Arial, sans-serif;
  font-size: 11.312px;
  font-weight: 900;
  letter-spacing: 2px;
  padding: 11px 22px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s;
  margin-bottom: 25px;
`;

// eslint-disable-next-line react/prefer-stateless-function
export default class MessageCard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let justifyPosition = ['User', 'Employee'].includes(
      this.props.message.sender_type
    )
      ? 'flex-end'
      : 'flex-start';
    let adminSender = ['User', 'Employee'].includes(
      this.props.message.sender_type
    );
    const { message, showAirbnbPreApproval } = this.props;
    return (
      <>
        <MessageContainer key={message.id} justifyPosition={justifyPosition}>
          {!adminSender && (
            <div
              style={{
                marginRight: '10px',
                display: 'flex',
                alignItems: 'flex-end'
              }}
            >
              <UserAvatar size={'small'} user={{ name: message.sender_name }} />
            </div>
          )}
          <Message>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <div>
                  <TextBgOverride
                    dangerouslySetInnerHTML={{ __html: message.body }}
                  />
                </div>
                <div
                  style={{
                    fontSize: '14px',
                    color: '#606B6F',
                    marginTop: '15px'
                  }}
                >
                  {message.sender_name} |{' '}
                  {<OrgFormattedDate date={message.created_at} />}
                </div>
                <small>
                  {moment(message.created_at)
                    .local()
                    .format('hh:mm A')}
                </small>
              </div>
            </div>
          </Message>

          {adminSender && (
            <div
              style={{
                marginLeft: '10px',
                display: 'flex',
                alignItems: 'flex-end'
              }}
            >
              <UserAvatar size={'small'} user={{ name: message.sender_name }} />
            </div>
          )}
        </MessageContainer>
        {showAirbnbPreApproval && (
          <PreApprovalButton
            onClick={() => {
              this.props.handlePreApproveClicked(message);
            }}
          >
            Pre Approve
          </PreApprovalButton>
        )}
      </>
    );
  }
}
