import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ReportingHome from './reporting-home';
import ReportingType from './reporting-type';

const ReportingRoutes = props => {
  return (
    <Switch>
      <Route
        path="/reporting/:report_type"
        render={routeProps => (
          <ReportingType
            {...props}
            reportType={routeProps.match.params.report_type}
          />
        )}
      />
      <Route path="/reporting" render={() => <ReportingHome />} />
    </Switch>
  );
};

export default ReportingRoutes;
