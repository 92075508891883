import React from 'react';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import { ajaxDataWithAuthorization } from 'apiClient';
import PropTypes from 'prop-types';

const LogoutLink = props => {
  const masquerading = props.masquerading_as_user || props.masquerading_as_employee;
  const handleLogOut = (user, subdomain) => {
    let apiUrl;
    let redirectUrl;
    if (!masquerading) {
      apiUrl = user.type !== 'user' ? `/dashboard/${subdomain}/employees/sign_out` : '/users/sign_out';
    } else if (props.masquerading_as_user) {
      apiUrl = '/users/masquerade/back';
    } else {
      apiUrl = '/employees/masquerade/back';
    }
    if (!masquerading) {
      redirectUrl = '/sign-in';
    } else if (props.masquerading_as_user) {
      redirectUrl = '/admin/view/users';
    } else {
      redirectUrl = props.user.admin ? '/admin/view/employees' : `/dashboard/${subdomain}/employees`;
    }

    $.ajax(
      ajaxDataWithAuthorization({
        type: masquerading ? 'GET' : 'DELETE',
        url: apiUrl,
        context: this
      })
    )
      .done(() => {
        window.location = redirectUrl;
      })
      .fail(jqXhr => {
        return jqXhr;
      });
  };

  return (
    <Navbar.Link
      variation={props.variation}
      linkType="button"
      onClick={() => {
        handleLogOut(props.user, props.organization.subdomain);
      }}
    >
      {masquerading ? 'Stop Masquerading' : 'Logout'}
    </Navbar.Link>
  );
};

LogoutLink.propTypes = {
  variation: PropTypes.string
};

LogoutLink.defaultProps = {
  variation: 'secondary'
};

export default LogoutLink;
