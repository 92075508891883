const AllChannelOptions = [
  {
    label: "All Channels",
    value: ""
  },
  {
    label: "Airbnb",
    value: 3
  },
  {
    label: "Booking.com",
    value: 1
  },
  {
    label: "Direct",
    value: 0
  },
  {
    label: "Vrbo",
    value: 2
  }
];
const SingleChannelOptions = [
  {
    label: "Airbnb",
    value: 3
  },
  {
    label: "Booking.com",
    value: 1
  },
  {
    label: "Direct",
    value: 0
  },
  {
    label: "Vrbo",
    value: 2
  }
];
const channelDesignKitMap = [
  {
    value: 0,
    label: "Direct",
    design_kit_tag: "direct",
    chartBase: "#06d6a0",
    chartGradient: "#cdf7ec"
  },
  {
    value: 1,
    label: "Booking.com",
    design_kit_tag: "booking",
    chartBase: "#067bc2",
    chartGradient: "#cde5f3"
  },
  {
    value: 2,
    label: "Homeaway",
    design_kit_tag: "homeaway",
    chartBase: "#7940ff",
    chartGradient: "#e4d9ff"
  },
  {
    value: 3,
    label: "Airbnb",
    design_kit_tag: "airbnb",
    chartBase: "#ff1654",
    chartGradient: "#ffd0dd"
  },
  {
    value: 4,
    label: "TripAdvisor",
    design_kit_tag: "tripadvisor",
    chartBase: "#FF7708",
    chartGradient: "#FFE4CE"
  },
  {
    value: 5,
    label: "Expedia",
    design_kit_tag: "expedia",
    chartBase: "#ffba08",
    chartGradient: "#fff1ce"
  },
  {
    value: 6,
    label: "RVshare",
    design_kit_tag: "rvshare",
    chartBase: "#2d08ff",
    chartGradient: "#cecfff"
  },
  {
    value: 7,
    label: "Google",
    design_kit_tag: "google",
    chartBase: "#505775",
    chartGradient: "#c6c7cc"
  },
  {
    value: 8,
    label: "Outdoorsy",
    design_kit_tag: "outdoorsy",
    chartBase: "#ff0000",
    chartGradient: "#ffcccc"
  },
  {
    value: 9,
    label: "RVezy",
    design_kit_tag: "rvezy",
    chartBase: "#00ff00",
    chartGradient: "#ccffcc"
  },
  {
    value: 10,
    label: "Wallabing",
    design_kit_tag: "wallabing",
    chartBase: "#0000ff",
    chartGradient: "#ccccff"
  },
  {
    value: 11,
    label: "RVnGO",
    design_kit_tag: "rvngo",
    chartBase: "#ffff00",
    chartGradient: "#ffffcc"
  },
  {
    value: 12,
    label: "Good Sam",
    design_kit_tag: "goodsam",
    chartBase: "#ff00ff",
    chartGradient: "#ffccff"
  }
];
export { AllChannelOptions, SingleChannelOptions, channelDesignKitMap };
