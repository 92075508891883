import React from 'react';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import EmployeesLink from 'adminNavbar/people/links/employees-link';

const LinksDesktop = props => {
  return (
    <>
      <Navbar.LinkMenuColumn>
        <Navbar.LinkMenuGroup groupTitle="People">
          <EmployeesLink {...props} />
        </Navbar.LinkMenuGroup>
      </Navbar.LinkMenuColumn>
    </>
  );
};

export default LinksDesktop;
