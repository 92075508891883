import React from 'react';
import styled from 'styled-components';

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #606B6F;
  position: relative;
  cursor: default;

  h2 {
    color: #FFF;
    font-weight: bold;
  }

  ${props =>
    props.size === 'large' &&
    `
    height: 75px;
    width: 75px;
    min-height: 75px;
    min-width: 75px;
  `}

  ${props =>
    props.size === 'default' &&
    `
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;

    h2 {
      font-size: 18px;
    }
  `}

  ${props =>
    props.size === 'small' &&
    `
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;

    h2 {
      font-size: 8px;
    }
  `}
`;

const AvatarTip = styled.div`
  position: absolute;
  background-color: #404040;
  color: white;
  border-radius: 4px;
  padding: 4px 8px;
  top: -30px;
  left: 0;
  width: max-content;
  font-size: 10px;
`;

const AvatarTipArrow = styled.div`
  position: absolute;
  top: -9px;
  left: 25%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #404040 transparent transparent transparent;
`;

const Initials = name => {
  let initials = name.match(/\b\w/g) || [];

  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

const defaultAvatarStyle = avatarUrl => {
  if (!avatarUrl) return null;

  return {
    backgroundUrl: `url(${avatarUrl})`,
    backgroundCover: 'cover',
    backgroundRepeat: 'none',
    backgroundPosition: 'center'
  };
};

export default class UserAvatar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hovered: false
    };
  }

  render() {
    const { user, size } = { ...this.props };
    if (!user) return null;

    return (
      <AvatarContainer
        size={size}
        style={defaultAvatarStyle(user.avatar_url)}
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
      >
        {this.state.hovered && (
          <React.Fragment>
            <AvatarTip>{user.name}</AvatarTip>
            <AvatarTipArrow />
          </React.Fragment>
        )}
        {!user.avatar_url && <h2>{Initials(user.name || '')}</h2>}
      </AvatarContainer>
    );
  }
}

UserAvatar.defaultProps = {
  size: 'default'
};
