import React from 'react';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';

const GenericSelect = ({
  label,
  onChange,
  options,
  name,
  clearable,
  onClear,
  customStyles,
  searchable,
  value,
  isDisabled
}) => {
  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <InputSelect
        options={options}
        name={name}
        isClearable={clearable}
        isSearchable={searchable}
        onChange={onChange}
        onClear={onClear}
        value={value}
        isDisabled={isDisabled}
      />
    </>
  );
};
GenericSelect.defaultProps = {
  clearable: false,
  name: 'select',
  options: [],
  onChange: () => null,
  onClear: () => null,
  customStyles: {},
  label: 'Select: ',
  searchable: false,
  isDisabled: false
};

export default GenericSelect;
