import isNull from "lodash/isNull";
export const parseCcBrand = (brand) => {
  const amexRegex = /amex|american\sexpress/gi;
  const dinersRegex = /diners|diners\sclub/gi;
  const discoverRegex = /discover/gi;
  const jcbRegex = /jcb/gi;
  const mastercardRegex = /mastercard/gi;
  const unionpayRegex = /unionpay/gi;
  const visaRegex = /visa/gi;
  if (isNull(brand)) {
    return "";
  } else if (brand.match(amexRegex)) {
    return "amex";
  } else if (brand.match(dinersRegex)) {
    return "diners";
  } else if (brand.match(discoverRegex)) {
    return "discover";
  } else if (brand.match(jcbRegex)) {
    return "jcb";
  } else if (brand.match(mastercardRegex)) {
    return "mastercard";
  } else if (brand.match(unionpayRegex)) {
    return "unionpay";
  } else if (brand.match(visaRegex)) {
    return "visa";
  } else {
    return "";
  }
};
