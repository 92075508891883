import toLower from 'lodash/toLower';

export const normalizeString = str => {
  const specialCharsRemoved = str.replace(/\W|_/g, '');
  const extraWhitespaceRemoved = specialCharsRemoved
    .split(' ')
    .filter(s => s !== '')
    .join(' ');
  return toLower(extraWhitespaceRemoved);
};

export const getQueryParams = queryString => {
  let copyOfQueryString;
  if (queryString[0] === '?') {
    copyOfQueryString = queryString.split('?').pop();
  } else {
    copyOfQueryString = queryString;
  }
  const queryParts = copyOfQueryString.split('&').map(q => q.split('='));
  const reducer = (accumulator, currentValue) => ({
    ...accumulator,
    [currentValue[0]]: currentValue[1]
  });
  return queryParts.reduce(reducer, {});
};
