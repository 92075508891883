import React from 'react';

const MasqueradingOverlay = props => (
  (props.masquerading_as_user || props.masquerading_as_employee) && (
        <div
          style={{
            background: 'rgba(240, 160, 0, 0.45)',
            padding: '16px 8px'
          }}
        >
          {`You are masquerading as ${props.user.name}`}
        </div>
      )
)

export default MasqueradingOverlay;
