import React from 'react';
import { SearchableClearableField } from 'adminComponents';
import FilterOption from './components/FilterOption';
import { OptionContainer } from './styles';

class FilterableSearchbar extends React.Component {
  static defaultProps = {
    placeholder: 'Search...',
    filterOptions: [],
    onFilterChange: () => null,
    onClear: () => null
  };

  state = {
    search: '',
    defaultFilter: { label: 'All Results', filter: '' },
    selectedFilterOption: {},
    shouldRenderOptions: false
  };

  onSearchChange = (event, value) => {
    // when user inputs into search bar, a set of options should appear
    this.setState({
      search: value,
      shouldRenderOptions: value !== ''
    }); // ternary is to test whether search was added to or cleared; if cleared, options should dissapear
  };

  renderFilterOptions = () => {
    // filter options are passed as props
    // when the user inputs an appropriate length of search value, options should appear
    return (
      <OptionContainer>
        {this.props.filterOptions.map(opt => {
          return (
            <>
              <FilterOption
                search={this.state.search}
                option={opt}
                onSelect={selection => this.selectFilterOption(selection)}
                iconClasses="fa fa-bars fa-lg"
              />
            </>
          );
        })}
      </OptionContainer>
    );
  };

  selectFilterOption = selection => {
    // sets selectedFilterOption
    // takes the users input of a filter type, and filters the results based on this.state.value and selectedFilterOption
    this.setState(
      { selectedFilterOption: selection, shouldRenderOptions: false },
      () => {
        this.props.onFilterChange({
          filter: selection.filter,
          value: this.state.search
        });
      }
    );
  };

  render() {
    const { placeholder } = this.props;
    const { search, shouldRenderOptions } = this.state;
    return this.props.useDesignKit ? (
      <>
        <SearchableClearableField
          name="search"
          label="Search"
          placeholder={placeholder}
          onChange={e => this.onSearchChange(e, e.target.value)}
          onClear={() => {
            this.setState({ shouldRenderOptions: false, search: '' });
            this.props.onClear();
          }}
          value={search}
          useDesignKit={this.props.useDesignKit}
          removeBottomSpacer={this.props.removeBottomSpacer}
          inputWidth={this.props.inputWidth}
          isDense={this.props.isDense}
        />
        {shouldRenderOptions && this.renderFilterOptions()}
      </>
    ) : (
      <div>
        <SearchableClearableField
          name="search"
          label="Search"
          placeholder={placeholder}
          onChange={e => this.onSearchChange(e, e.target.value)}
          onClear={() => {
            this.setState({ shouldRenderOptions: false, search: '' });
            this.props.onClear();
          }}
          value={search}
        />
        {shouldRenderOptions && this.renderFilterOptions()}
      </div>
    );
  }
}

export default FilterableSearchbar;
