import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dropdown,
  IconFontAwesome,
  PageHeader,
  Tooltip
} from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { ACCOUNTING_NAV_TAGS } from '../../../../../shared/nav';
import PermissionComponent from '../../../../PermissionComponent';
import { useDetectMobile } from '../../../../../shared/hooks';
import { updateAccountingChartOfAccounts } from '../../../../redux/slices/accounting';

const ChartOfAccountsHome = props => {
  const { isMobile } = useDetectMobile();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: ACCOUNTING_NAV_TAGS.PRIMARY,
        secondary: ACCOUNTING_NAV_TAGS.secondary.CHART_OF_ACCOUNTS
      })
    );
  }, []);

  return (
    <PageHeader>
      <PageHeader.Left>
        Accounting: <span>Chart of Accounts</span>
      </PageHeader.Left>
      <PageHeader.Right>
        <PermissionComponent
          user={props.user}
          permission="chart_of_accounts_settings"
        >
          <Tooltip tipContent="Settings" theme="light">
            <a
              href={`/dashboard/${props.organization.subdomain}${
                props.match.path
              }/settings`}
              className="button-control-panel-ghost"
            >
              <IconFontAwesome name="cog" />
            </a>
          </Tooltip>
        </PermissionComponent>
        <Dropdown
          panelContent={[
            {
              label: 'Ledger Account',
              type: 'button',
              onClick: () => {
                dispatch(
                  updateAccountingChartOfAccounts({ openLedgerModal: true })
                );
              }
            },
            {
              label: 'Journal Entry',
              type: 'button',
              onClick: () => {
                dispatch(
                  updateAccountingChartOfAccounts({ openJournalModal: true })
                );
              }
            }
          ]}
          alignPanel="bottomRight"
          controlLabel={isMobile ? <IconFontAwesome name="plus" /> : 'Create'}
          controlType={isMobile ? 'icon-button' : 'call-to-action-button'}
        />
      </PageHeader.Right>
    </PageHeader>
  );
};

export default ChartOfAccountsHome;
