import React from 'react';
import PropTypes from 'prop-types';
import { ConversationService } from 'adminApi';
import { FormattedDate, Pagination } from 'adminComponents';
import striptags from 'striptags';
// import ListLeadsCommandPalette from './list-leads-command-palette';
import { OpenCustomerMessageDetail } from 'adminComponents/CustomerSidebar';

import { Link } from 'react-router-dom';
import debounce from 'lodash/';
import keys from 'lodash/keys';
import get from 'lodash/get';
import filter from 'lodash/filter';
import isNull from 'lodash/isNull';
import { CustomerContext } from 'adminContexts';
import queryString from 'query-string';
import displayError from 'sharedComponents/ErrorDisplay';
import InventorySegmentFilterGroup from 'adminComponents/InventorySegmentFilters';
import {
  Dropdown,
  Box,
  FlexBox,
  IconFontAwesome
} from '@directsoftware/ui-kit-web-admin';
import { PaginationContainer } from '../pages/DashboardConversation/organisms/styled';
import DirectTable from './Tables/direct-table';
import ListConversationsFilter from './list-conversations-filter';
import MultiUnitCodeDisplay from './multi-unit-code-display';

export default class NewMessages extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: 0,
      page: 0,
      total_count: 0,
      page_size: 50,
      loading: true,
      filters: {
        search: '',
        portfolio_id: '',
        subportfolio_id: '',
        unit_group_id: ''
      }
    };
    this.loadMessages = debounce(this.loadMessages, 300);
  }

  componentDidMount() {
    const { active_customer_id, active_conversation_id } = queryString.parse(
      get(this, 'props.location.search', {}),
      { ignoreQueryPrefix: true }
    );
    if (!active_customer_id || !active_conversation_id) return null;

    this.context.enterMessageDetailView(
      { id: active_customer_id },
      { id: active_conversation_id }
    );
  }

  loadMessages = (data = {}) => {
    data.loading = true;
    this.setState(data, () => {
      ConversationService.index(this.props.organization.id, data)
        .then(response => {
          this.setState({
            data: filter(
              response.conversations,
              c => c.message_count > 0 && c.customer
            ),
            viewer: response.current_viewer,
            total_count: response.total_count,
            isSearching: false,
            page: response.current_page,
            unread_message_count: response.unread_message_count,
            loading: false
          });
        })
        .catch(err =>
          displayError({ message: 'Error fetching conversation', err })
        );
    });
  };

  applyFilters = data => {
    const filterChange = this.state.filters;
    keys(data).map(key => {
      filterChange[key] = data[key];
    });
    this.loadMessages({ filters: filterChange });
  };

  handlePageClick = data => {
    this.loadMessages(
      {
        current_page: data.selected + 1,
        search: this.state.search
      },
      () => {
        this.search.scrollIntoView({ alignToTop: true });
      }
    );
  };

  renderUserSidebarTrigger = conversation => {
    const customer = conversation.customer;
    const message = conversation.last_message;
    const viewerIsSender =
      get(message, 'sender_name') == this.state.viewer.name;
    const highlight = !(viewerIsSender || get(message, 'read_at'));
    const weight = highlight ? 'bolder' : 'normal';

    if (!customer || this.state.viewer.id === customer.id) {
      return (
        <Link
          to={`/${conversation.id}`}
          style={{ color: 'inherit', fontWeight: weight }}
        >
          {get(customer, 'name')}
        </Link>
      );
    }

    return (
      <OpenCustomerMessageDetail
        styles={{ color: 'inherit', fontWeight: weight }}
        customer={customer}
        activeConversation={conversation}
      />
    );
  };

  renderVehicleLink = conversation => {
    return (
      <div
        onClick={() =>
          window.open(
            `${window.location.href.replace('inbox', '')}vehicles/${
              conversation.vehicle_id
            }`
          )
        }
        style={{ color: 'inherit', fontWeight: 'normal', cursor: 'pointer' }}
      >{conversation.listing_name}</div>
    );
  };

  renderUnitLink = conversation => {
    return (
      <div
        onClick={() =>
          conversation.multi_unit
            ? window.open(
                `${window.location.href.replace('inbox', '')}properties/${
                  conversation.property_id
                }/units/${conversation.unit_id}`
              )
            : window.open(
                `${window.location.href.replace('inbox', '')}properties/${
                  conversation.property_id
                }`
              )
        }
        style={{ color: 'inherit', fontWeight: 'normal', cursor: 'pointer' }}
      >
        {MultiUnitCodeDisplay(
          conversation.unit_unit_code,
          conversation.propert_unit_code,
          conversation.listing_name,
          true
        )}
      </div>
    );
  };

  renderChannelLogo = message => {
    const url = get(message, 'channel_logo');
    return (
      <div className="channel-logo" style={{ textAlign: 'center' }}>
        <img
          src={`/assets/admin/${url}`}
          width="auto"
          height="20px"
          style={{ height: 20 }}
          alt="chan-logo"
        />
      </div>
    );
  };

  renderLastMessageDate = conversation => {
    const lastMessageDate = get(conversation, 'last_message.created_at');
    return (
      <FormattedDate
        className="date"
        date={lastMessageDate}
        format={this.props.format}
      />
    );
  };

  renderCheckDates = conversation => {
    const check_in = conversation.check_in;
    const check_out = conversation.check_out;
    if (check_in) {
      return (
        <div style={{ display: 'flex' }}>
          <div>
            <FormattedDate
              className="date"
              date={check_in}
              format={this.props.format}
            />
          </div>
          {' - '}
          <div>
            <FormattedDate
              className="date"
              date={check_out}
              format={this.props.format}
            />
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  updateToRead = conversation => {
    const { data } = this.state;
    conversation.last_message = {
      ...conversation.last_message,
      read_at: new Date()
    };
    data[data.findIndex(c => c.id === conversation.id)] = conversation;
    this.setState({ data });
  };

  renderSubjectAndPreview = conversation => {
    const customer = conversation.customer;
    const subject = conversation.subject;
    const message = get(conversation, 'last_message', {});
    const body = get(message, 'body');
    const viewerIsSender =
      get(message, 'sender_name') === this.state.viewer.name;
    const highlight = !(viewerIsSender || get(message, 'read_at'));
    const weight = highlight ? 'bolder' : 'normal';

    if (!customer || this.state.viewer.id === customer.id) {
      return (
        <Link
          to={`/${conversation.id}`}
          onClick={() => this.updateToRead(conversation)}
          style={{ color: 'inherit', fontWeight: weight }}
        >
          <div>
            {isNull(conversation.channel_id) && (
              <>
                <span>{subject}</span>-{' '}
              </>
            )}
            <span>
              {striptags(body).slice(0, 50)}
              ...
            </span>
          </div>
        </Link>
      );
    }

    return (
      <OpenCustomerMessageDetail
        styles={{ color: 'inherit', fontWeight: weight }}
        customer={conversation.customer}
        activeConversation={conversation}
        updateToRead={this.updateToRead}
        displayName={
          <div>
            {isNull(conversation.channel_id) && (
              <>
                <span>{subject}</span>-{' '}
              </>
            )}
            <span>
              {striptags(body).slice(0, 50)}
              ...
            </span>
          </div>
        }
      />
    );
  };

  renderPagination = () => (
    <PaginationContainer>
      <Pagination
        pageCount={Math.ceil(this.state.total_count / 25)}
        handlePageClick={this.handlePageClick}
        currentPage={this.state.page}
      />
    </PaginationContainer>
  );

  render() {
    const { data, pages, loading, pageSize } = this.state;

    const columns = [
      {
        Header: 'Channel',
        name: 'channel_logo',
        accessor: d => this.renderChannelLogo(d)
      },
      {
        Header: 'Name',
        name: 'name',
        accessor: d => this.renderUserSidebarTrigger(d)
      },
      {
        Header: 'Listing',
        name: 'listing_name',
        accessor: d => {
          if (d.vehicle) {
            return this.renderVehicleLink(d);
          } else {
            return this.renderUnitLink(d);
          }
        }
      },
      {
        Header: 'Check In/Out',
        name: 'check_dates',
        accessor: d => this.renderCheckDates(d)
      },
      {
        Header: 'Subject',
        name: 'subject',
        accessor: d => this.renderSubjectAndPreview(d)
      },
      {
        Header: 'Date',
        name: 'last_message',
        accessor: d => this.renderLastMessageDate(d)
      }
    ];

    return (
      <section>
        <FlexBox flexWrap="wrap" gap="xs">
          <div className="properties-search-wrapper">
            <ListConversationsFilter
              filters={this.state.filters}
              applyFilters={this.applyFilters}
              organization={this.props.organization}
            />
          </div>
          <Dropdown
            controlProps={{
              variation: 'secondary',
              appearance: 'outline',
              appendIcon: <IconFontAwesome name="slidersH" />
            }}
            controlLabel="Segmentations"
            controlType="call-to-action-button"
            panelWidthVariation="s"
            panelContent={
              <Box padding="s">
                <InventorySegmentFilterGroup
                  organizationId={this.props.organization.id}
                  setFilters={selection => {
                    this.applyFilters({ [selection.filter]: selection.value });
                  }}
                  filters={this.state.filters}
                />
              </Box>
            }
          />
        </FlexBox>
        <section className="consolidated-manager">
          <DirectTable
            data={data}
            pages={pages}
            loading={loading}
            onFetchData={this.loadMessages}
            organization={this.props.organization}
            columns={columns}
          />
          {this.renderPagination()}
        </section>
      </section>
    );
  }
}

NewMessages.contextType = CustomerContext;
