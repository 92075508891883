// Dependencies
// -----------------------------------------------
import React from 'react';

// -----------------------------------------------
// COMPONENT->OVERVIEW-SINGLE-ASIDE-LODGING ------
// -----------------------------------------------
const OverviewSingleAsideLodging = () => {
  // ---------------------------------------------
  // RETURN --------------------------------------
  // ---------------------------------------------
  return (
    <div>
      <figure>
        <small className="subtitle">Description</small>
        <p>
          This is the long-form description of your lodging. While it can be as
          short or as long as you’d like, this should be a high-level
          explanation of the key features of staying in this property.
        </p>
      </figure>
    </div>
  );
};

// -----------------------------------------------
// EXPORT ----------------------------------------
// -----------------------------------------------
export default OverviewSingleAsideLodging;
