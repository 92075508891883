import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import moment from 'moment';
import Modal from 'react-modal';
import { StripeConnectButton } from 'adminComponents/Stripe';
import { StripeConnectAccountService } from 'adminApi';
import IndicatorToggle from 'adminComponents/indicator-toggle';
import displayError from 'sharedComponents/ErrorDisplay';
import DefaultSingleDatePicker from 'adminComponents/DefaultSingleDatePicker/DefaultSingleDatePicker';

export default class ManageOrganizationFieldsStripeConnect extends React.Component {
  static propTypes = {
    organization: PropTypes.object.isRequired,
    stripe_connect_account: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      specialPricingEnabled: get(
        this,
        'props.stripe_connect_account.special_pricing_enabled'
      ),
      updating: false,
      modalOpen: false,
      startDate: moment()
    };
  }

  toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen });

  onInterchangeToggle = () => {
    if (this.state.updating) return;

    if (this.state.specialPricingEnabled) {
      this.updateSpecialPricing();
    } else {
      this.toggleModal();
    }
  };

  updateSpecialPricing = () => {
    if (this.state.updating) return;
    if (this.state.modalOpen) this.toggleModal();

    this.setState({ updating: true }, () => {
      StripeConnectAccountService.updateSpecialPricing(
        this.props.organization.id,
        get(this, 'props.stripe_connect_account.id'),
        !this.state.specialPricingEnabled,
        this.state.startDate.format('DD-MM-YYYY')
      )
        .then(res => {
          this.setState({
            specialPricingEnabled: res.special_pricing_enabled,
            updating: false
          });
        })
        .catch(err => {
          displayError({ message: 'Error updating special pricing', err });
          this.setState({ updating: false });
        });
    });
  };

  renderWithStripe() {
    return (
      <section className="consolidated-item-form-viewing">
        <p>
          Your organization is connected to Stripe. You can manage your Stripe
          account via Stripe's dashboard.
        </p>
        <p>
          For reference, your organization's Stripe ID is:{' '}
          <b>{this.props.stripe_connect_account.stripe_user_id}</b>
        </p>
        <p>
          <a
            href="https://dashboard.stripe.com"
            target="_blank"
            className="button stripe-connect"
          >
            <span>Go to Stripe dashboard</span>
          </a>
        </p>
        <div style={{ marginBottom: '20px' }}>
          <p>
            If your organization has Stripe Interchange fees enabled, toggle it
            on here to enable it in our system (this will enable Interchange
            fees for all of your associated Stripe accounts)
          </p>
          <IndicatorToggle
            toggleAction={this.onInterchangeToggle}
            toggleStatus={this.state.specialPricingEnabled}
          />
        </div>
        <p>
          Please contact{' '}
          <a href="mailto:help@getdirect.io">help@getdirect.io</a> if you would
          like to remove this Stripe account from your organization.
        </p>
      </section>
    );
  }

  renderWithoutStripe() {
    return (
      <section className="consolidated-item-form-viewing">
        <p>
          You do not currently have a Stripe Connect account attached to this
          organization. You are still able to take bookings, but your payments
          will not instantly be available, and you may be subject to additional
          processing fees.
        </p>
        <StripeConnectButton
          organizationId={this.props.organization.id}
          text="Connect With Stripe"
          stripeStyling
        />
      </section>
    );
  }

  renderModalContent = () => (
    <div style={{ padding: '15px' }}>
      <p>When did you sign your Interchange pricing agreement?</p>
      <p>This will determine how far back we retroactively apply the fees</p>
      <DefaultSingleDatePicker
        initialDate={this.state.startDate}
        onDateSet={date => this.setState({ startDate: date })}
      />
      <button className="button" onClick={this.updateSpecialPricing}>
        Apply
      </button>
    </div>
  );

  render() {
    const customStyles = {
      overlay: {
        zIndex: 999999999,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'center'
      },
      content: {
        position: 'relative',
        flex: '0 1 500px',
        boxShadow: '0px 2px 3px 0px rgba(0,0,0,0.25)',
        borderRadius: 0,
        padding: 0,
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
        minHeight: '475px'
      }
    };
    return (
      <figure
        id="section-stripe-connect"
        className={`consolidated-item-form-section`}
      >
        <header className="consolidated-item-header">
          <h3>Stripe Connect Settings</h3>
        </header>
        {this.props.stripe_connect_account
          ? this.renderWithStripe()
          : this.renderWithoutStripe()}
        <Modal
          isOpen={this.state.modalOpen}
          onRequestClose={this.toggleModal}
          shouldCloseOnOverlayClick
          style={customStyles}
          ariaHideApp={false}
        >
          {this.renderModalContent()}
        </Modal>
      </figure>
    );
  }
}
