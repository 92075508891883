import request from "apiClient";
function get(orgId, role) {
  return request({
    url: `/api/${orgId}/access_role_permissions/?role=${role}`,
    method: "GET"
  });
}
const AccessRolePermissionService = {
  get
};
export default AccessRolePermissionService;
