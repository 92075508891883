const reservationStatusOptions = [
  { value: "", label: "All Types", filter: "booking_status" },
  { value: "cancelled", label: "Cancelled", filter: "booking_status" },
  { value: "confirmed", label: "Confirmed", filter: "booking_status" },
  { value: "archived", label: "Archived", filter: "booking_status" }
];
const paymentStatusOptions = [
  { value: "", label: "All Statuses", filterLabel: "All" },
  { value: "full", label: "Paid in full", filterLabel: "Full" },
  { value: "partial", label: "Partial payment", filterLabel: "Partial" },
  { value: "not_paid", label: "No payment", filterLabel: "None" }
];
const generationTypeOptions = [
  { value: "", label: "All Types" },
  { value: "instant", label: "Instant Booking" },
  { value: "request", label: "Request Booking" }
];
const reservationTypeOptions = [
  { value: "", label: "All Types" },
  { value: "guest", label: "Guest Reservation" },
  { value: "owner", label: "Owner Block" },
  { value: "maintenance", label: "Maintenance Block" },
  { value: "admin", label: "Admin Block" },
  { value: "friend", label: "Friend of Owner" },
  { value: "comp", label: "Comped Guest" }
];
export {
  reservationStatusOptions,
  paymentStatusOptions,
  generationTypeOptions,
  reservationTypeOptions
};
