const isPhoneNoValid = (value) => {
  const pattern = /^[- +()]*[0-9][- +()0-9]*/;
  if (pattern.test(value) && value.length >= 10)
    return true;
  else
    return false;
};
const isEmailValid = (value) => {
  const pattern = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
  if (pattern.test(value))
    return true;
  else
    return false;
};
const isPhoneNumberValid = (value) => {
  const pattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  return pattern.test(value);
};
export { isEmailValid, isPhoneNoValid, isPhoneNumberValid };
