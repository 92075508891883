import React from 'react';
import Loading from 'react-loading-components';
import debounce from 'lodash/debounce';

import { Pagination, SearchableClearableField } from 'adminComponents';
import { OrganizationService } from 'adminApi';
import ListOrganizationsCommandPalette from '../molecules/list-organizations-command-palette';
import ListOrganizationsActionCards from '../molecules/list-organizations-action-cards';

const limit = 20;

export default class ListOrganizations extends React.Component {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      isDirty: false,
      isSaving: false,
      isSaved: false,
      organizations: [],
      loading: true,
      page: 0,
      totalCount: 0,
      search: ''
    };

    this.onSearch = debounce(this.onSearch, 300);
  }

  componentDidMount() {
    this.fetchOrganizations();
  }

  onSearch = (search = '') => {
    this.setState({ search }, () => this.fetchOrganizations());
  };

  fetchOrganizations = (page = this.state.page) => {
    this.setState({ loading: true, page }, () => {
      // client
      //   .query({
      //     query: gql`
      //       query allOrganizations($filter: String) {
      //         organizations(filter: $filter) {
      //           id
      //           name
      //           subdomain
      //           status
      //         }
      //       }
      //     `,
      //     variables: {
      //       filter: this.state.search,
      //     },
      //   })
      //   .then(({ data }) => {
      //     this.setState({
      //       loading: false,
      //       organizations: data.organizations,
      //     })
      //   });

      OrganizationService.index(
        `search=${this.state.search}&limit=${limit}&page=${this.state.page + 1}`
      ).then(response => {
        this.setState({
          loading: false,
          organizations: response.organizations,
          totalCount: response.count
        });
      });
    });
  };

  renderLoadingOrganizations = () => (
    <div
      style={{
        height: '210px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Loading type="tail_spin" width={50} height={50} fill="#00CC99" />
    </div>
  );

  renderNoOrganizations = () => (
    <div className="action-empty-state">
      <div className="card empty-state">
        <p>No organizations to display.</p>
        <a href="/my-organizations/create" className="button">
          New organization
        </a>
      </div>
    </div>
  );

  renderOrganizationList = () => {
    if (this.state.loading) {
      return this.renderLoadingOrganizations();
    } else if (this.state.organizations.length === 0) {
      return this.renderNoOrganizations();
    }

    return (
      <div>
        <ListOrganizationsActionCards
          organizations={this.state.organizations}
        />
        <div className="action-empty-state">
          <div className="card empty-state">
            <a href="/my-organizations/create" className="button">
              New organization
            </a>
          </div>
        </div>
        <div className="action-empty-state">
          <Pagination
            pageCount={Math.ceil(this.state.totalCount / limit)}
            handlePageClick={data => this.fetchOrganizations(data.selected)}
            currentPage={this.state.page}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <section>
        <ListOrganizationsCommandPalette />
        <section className="consolidated-manager">
          <div className="action-empty-state">
            <SearchableClearableField
              name="name"
              label="Search"
              placeholder="Search by name"
              onChange={e => this.onSearch(e.target.value)}
              onClear={() => this.onSearch()}
            />
          </div>
          {this.renderOrganizationList()}
        </section>
      </section>
    );
  }
}
