import React, { useState } from 'react';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import { useDetectMobile } from 'sharedHooks';
import {
  DISTRIBUTION_NAV_TAGS,
  PEOPLE_NAV_TAGS,
  RVSHARE_CONTRACTS_NAV_TAGS,
  RVSHARE_EMAILS_NAV_TAGS
} from 'sharedNav';
import RvShareLink from 'src/admin/navigation/design-system-nav/navbar/distribution/links/rv-share-link';
import EmployeesLink from 'src/admin/navigation/design-system-nav/navbar/people/links/employees-link';
import RentalAgreementLink from 'src/admin/navigation/design-system-nav/navbar/site-editor/links/rental-agreement-link';
import { FeatureTierDisplay } from 'src/admin/components/FeatureTierDisplay';
import EmailsLink from 'src/admin/navigation/design-system-nav/navbar/workflows/links/emails-link';
import CustomEmailsLink from 'adminNavbar/messaging/links/custom-emails-link';
import WebsiteEditorLink from 'src/admin/navigation/design-system-nav/navbar/site-editor/links/website-editor-link';
import { WEBSITE_NAV_TAGS } from 'src/shared/nav';
import PermissionComponent from 'src/admin/PermissionComponent';

const RvFleetLeftMoreLink = props => {
  const { isSuperHD } = useDetectMobile();
  const [showPanel, setShowPanel] = useState(false);
  const closeMenuPanel = () => {
    setShowPanel(false);
  };

  return (
    <Navbar.LinkMenu
      primaryLinkText={
        props.navTags.PRIMARY_EXTENDED.includes(props.activeRoutes.primary)
          ? `More: ${props.activeRoutes.primary}`
          : 'More'
      }
      primaryLinkProps={{
        isActive:
          props.activeRoutes.primary === props.navTags.PRIMARY ||
          props.navTags.PRIMARY_EXTENDED.includes(props.activeRoutes.primary),
        onClick: () => setShowPanel(!showPanel)
      }}
      onPanelClickOutside={() => setShowPanel(false)}
      showMenuPanel={showPanel}
      alignPanel={isSuperHD ? 'left' : 'center'}
    >
      <Navbar.LinkMenuColumn>
        <Navbar.LinkMenuGroup groupTitle="Other Features">
          <PermissionComponent user={props.user} permission="view_distribution">
            <RvShareLink
              {...props}
              closeMenuPanel={closeMenuPanel}
              navTags={DISTRIBUTION_NAV_TAGS}
              label="Distribution"
            />
          </PermissionComponent>
          <PermissionComponent user={props.user} permission="users">
            <EmployeesLink
              {...props}
              variation="secondary"
              closeMenuPanel={closeMenuPanel}
              navTags={PEOPLE_NAV_TAGS}
            />
          </PermissionComponent>
          <PermissionComponent user={props.user} permission="view_site_editor">
            <PermissionComponent user={props.user} permission="site_editor_contracts">
              <FeatureTierDisplay allowedTiers={['freemium']}>
                <RentalAgreementLink
                  {...props}
                  navTags={RVSHARE_CONTRACTS_NAV_TAGS}
                  closeMenuPanel={closeMenuPanel}
                  label="Contracts"
                />
              </FeatureTierDisplay>
              <FeatureTierDisplay allowedTiers={['premium']}>
                <RentalAgreementLink
                  {...props}
                  isPremium
                  navTags={RVSHARE_CONTRACTS_NAV_TAGS}
                  closeMenuPanel={closeMenuPanel}
                  label="Contracts"
                />
              </FeatureTierDisplay>
            </PermissionComponent>
            <PermissionComponent user={props.user} permission="site_editor_auto_emails">
              <CustomEmailsLink
                {...props}
                navTags={RVSHARE_EMAILS_NAV_TAGS}
                closeMenuPanel={closeMenuPanel}
              />
              <EmailsLink
                {...props}
                navTags={RVSHARE_EMAILS_NAV_TAGS}
                closeMenuPanel={closeMenuPanel}
                label="Direct System Emails"
              />
            </PermissionComponent>
            <PermissionComponent user={props.user} permission='site_editor_overview'>
              <FeatureTierDisplay allowedTiers={['premium']}>
                <WebsiteEditorLink
                  {...props}
                  closeMenuPanel={closeMenuPanel}
                  navTags={WEBSITE_NAV_TAGS}
                />
              </FeatureTierDisplay>
            </PermissionComponent>
          </PermissionComponent>
        </Navbar.LinkMenuGroup>
      </Navbar.LinkMenuColumn>
    </Navbar.LinkMenu>
  );
};

export default RvFleetLeftMoreLink;
