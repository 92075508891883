import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { ACCOUNTING_NAV_TAGS } from '../../../../../shared/nav';
import { selectEarningAccount } from '../../../../redux/slices/accounting';

const EarningSummaryDetails = props => {
  const dispatch = useDispatch();
  const { name } = useSelector(selectEarningAccount); 

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: ACCOUNTING_NAV_TAGS.PRIMARY,
        secondary: ACCOUNTING_NAV_TAGS.secondary.EARNINGS_SUMMARY
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      {props.user.role >= 4 ? (
        <PageHeader.Left
          breadcrumbs={[
            {
              href: '/accounting/earnings-summaries',
              label: 'Earnings Summary'
            },
            {
              label: 'Details'
            }
          ]}
        >
          Earnings Summary: <span>{`Payee ${name || props.payeeId}`}</span>
        </PageHeader.Left>
      ) : (
        <PageHeader.Left>
          Earnings Summary: <span>{`Payee ${name || props.payeeId}`}</span>
        </PageHeader.Left>
      )}
    </PageHeader>
  );
};

export default EarningSummaryDetails;
