import React from 'react';
import find from 'lodash/find';

import { format } from 'money-formatter';
import CurrencyDefinitions from 'sharedUtils/currency-definitions';
import { InlineTip } from 'adminComponents';

export default class InputMoney extends React.Component {
  constructor(props) {
    super(props);
    const currencyInfo = this.getCurrencyInfo();
    this.state = {
      value: this.props.value,
      currency: this.props.currency,
      symbol: currencyInfo.symbol,
      decimalDigits: currencyInfo.decimal_digits
    };
  }

  componentDidMount() {
    this.sanitize(true);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value === this.state.value) {
      return null;
    }

    this.setState({ value: nextProps.value }, () => this.sanitize(true));
  }

  calculateFormatRate = () => {
    if (!isNaN(parseFloat(this.state.value))) {
      if (this.props.rateCalculator) {
        return (
          format(
            this.props.currency,
            this.props.rateCalculator(this.state.value)
          ) + (this.props.rateLabel || '')
        );
      }
      return (
        format(this.props.currency, this.state.value) +
        (this.props.rateLabel || '')
      );
    }
  };

  setMoney = e => {
    if (this.props.disabled) return null;

    this.setState({ value: e.target.value }, () => {
      this.sanitize(false);
      this.props.setMoney(this.state.value);
    });
  };

  sanitize = withFormatting => {
    let sanitizedVal = parseFloat(this.state.value);
    const max = parseFloat(this.props.max);

    if (!isNaN(sanitizedVal)) {
      if (sanitizedVal < 0) {
        sanitizedVal = 0;
      } else if (max && sanitizedVal > max) {
        sanitizedVal = max;
      }
    }

    if (withFormatting) {
      sanitizedVal = sanitizedVal.toFixed(this.state.decimalDigits);
    }

    this.setState({ value: sanitizedVal });
  };

  getCurrencyInfo() {
    return find(CurrencyDefinitions, def => {
      return def.value === this.props.currency;
    });
  }

  render() {
    const calculatedFormattedRate = this.calculateFormatRate();
    const width = this.props.extraClasses || 'input-money-width';
    return (
      <figure
        className="input-money"
        style={{ ...this.props.containerStyles, ...{ position: 'relative' } }}
      >
        <div style={{ width: '100%' }}>
          <div
            style={
              this.props.tip && {
                display: 'flex',
                justifyContent: 'flex-start'
              }
            }
          >
            <label
              htmlFor={this.props.name}
              className={this.props.required && 'required'}
            >
              <span>{this.props.label}</span>
            </label>
            {this.props.tip && (
              <InlineTip tip={this.props.tip} alwaysFullscreen />
            )}
          </div>
          <div className="input-with-immutable-label">
            <span style={this.props.symbolStyles || { fontSize: '14px' }}>
              {this.state.symbol}
            </span>
            <input
              type="number"
              name={this.props.name}
              className={`magnified ${width}`}
              min="0"
              max={this.props.max}
              placeholder={this.props.placeholder}
              value={this.state.value}
              onChange={e => this.setMoney(e)}
              onBlur={e => this.sanitize(e)}
              disabled={this.props.disabled}
              style={this.props.inputStyles}
            />
          </div>
        </div>
        {this.props.showCalculated && (
          <span className="calculated-money">{calculatedFormattedRate}</span>
        )}
      </figure>
    );
  }
}

InputMoney.defaultProps = {
  max: '0',
  placeholder: '0.00',
  disabled: false,
  currency: 'usd',
  value: ''
};
