import { createSelector, createSlice } from "@reduxjs/toolkit";
const initialState = {
  filters: {
    portfolios: []
  },
  statements: {
    pending: [],
    approved: [],
    published: [],
    selectedBulkIds: [],
    fakeBulkPaymentProgress: false,
    fakeDeferPaymentProgress: false,
    payeeName: ""
  }
};
const liabilitiesAndStatementsSlice = createSlice({
  name: "liabilitiesAndStatements",
  initialState,
  reducers: {
    updateLiabilitiesAndStatements: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetLiabilitiesAndStatements: (state) => {
      return { ...state, ...initialState };
    },
    updateLiabilitiesAndStatementsFilters: (state, action) => {
      return {
        ...state,
        filters: { ...state.filters, ...action.payload }
      };
    },
    resetLiabilitiesAndStatementsFilters: (state) => {
      return { ...state, filters: initialState.filters };
    },
    updateStatements: (state, action) => {
      return {
        ...state,
        statements: {
          ...state.statements,
          ...action.payload
        }
      };
    },
    resetStatements: (state) => {
      return {
        ...state,
        statements: initialState.statements
      };
    },
    updateStatementsPending: (state, action) => {
      return {
        ...state,
        statements: {
          ...state.statements,
          pending: action.payload
        }
      };
    },
    resetStatementsPending: (state) => {
      return {
        ...state,
        statements: {
          ...state.statements,
          pending: initialState.statements.pending
        }
      };
    },
    updateStatementsApproved: (state, action) => {
      return {
        ...state,
        statements: {
          ...state.statements,
          approved: action.payload
        }
      };
    },
    resetStatementsApproved: (state) => {
      return {
        ...state,
        statements: {
          ...state.statements,
          approved: initialState.statements.approved
        }
      };
    },
    updateStatementsPublished: (state, action) => {
      return {
        ...state,
        statements: {
          ...state.statements,
          published: action.payload
        }
      };
    },
    resetStatementsPublished: (state) => {
      return {
        ...state,
        statements: {
          ...state.statements,
          published: initialState.statements.published
        }
      };
    },
    updateStatementsBulkIds: (state, action) => {
      return {
        ...state,
        statements: {
          ...state.statements,
          selectedBulkIds: action.payload
        }
      };
    }
  }
});
export const {
  updateLiabilitiesAndStatements,
  resetLiabilitiesAndStatements,
  updateLiabilitiesAndStatementsFilters,
  resetLiabilitiesAndStatementsFilters,
  updateStatements,
  resetStatements,
  updateStatementsPending,
  resetStatementsPending,
  updateStatementsApproved,
  resetStatementsApproved,
  updateStatementsPublished,
  resetStatementsPublished,
  updateStatementsBulkIds
} = liabilitiesAndStatementsSlice.actions;
export default liabilitiesAndStatementsSlice.reducer;
export const selectLiabilitiesAndStatementsState = createSelector((state) => state.liabilitiesAndStatements, (liabilitiesAndStatements) => liabilitiesAndStatements);
export const selectLiabilitiesAndStatementsFilters = createSelector((state) => state.liabilitiesAndStatements, (liabilitiesAndStatements) => liabilitiesAndStatements.filters);
export const selectStatementsState = createSelector((state) => state.liabilitiesAndStatements, (liabilitiesAndStatements) => liabilitiesAndStatements.statements);
