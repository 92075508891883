// Dependencies
// -----------------------------------------------
import React from 'react';
import { DeleteButton, Spinner } from 'adminComponents';
import displayError from 'sharedComponents/ErrorDisplay';

// Services
// -----------------------------------------------
import { StripeBankAccountService } from 'adminApi';

// -----------------------------------------------
// COMPONENT->STRIPE-ACCOUNT-REMOVE-BUTTON -------
// -----------------------------------------------
export default class StripeAccountRemoveButton extends React.Component {
  // ---------------------------------------------
  // CONSTRUCTOR ---------------------------------
  // ---------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      deleting: false
    };
  }

  removeBankAccount = () => {
    this.setState({ deleting: true }, () => {
      StripeBankAccountService.destroy(
        this.props.organization.id,
        this.props.stripe_bank_account.id
      )
        .then(response => {
          this.props.afterBankAccountRemoved(response);
        })
        .catch(err => {
          const message = `Error deleting account: ${
            err.data && err.data.error ? err.data.error : ''
          }`;
          displayError({ message });
        });
    });
  };

  // ---------------------------------------------
  // RENDER --------------------------------------
  // ---------------------------------------------
  render() {
    return (
      <DeleteButton
        action={this.removeBankAccount}
        confirmMessage={
          'Are you sure you want to remove this bank account from this group?'
        }
        confirmText="Remove"
        cancelText="Cancel"
        can_delete={true}
      >
        {this.state.deleting ? (
          <Spinner />
        ) : (
          <a href="#" className="button">
            <span>{this.props.text || 'Remove Bank Account'}</span>
          </a>
        )}
      </DeleteButton>
    );
  }
}
