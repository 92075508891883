import React, { useEffect } from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { LISTINGS_NAV_TAGS } from '../../../../../shared/nav';

const SubPortfolios = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: LISTINGS_NAV_TAGS.PRIMARY,
        secondary: LISTINGS_NAV_TAGS.secondary.SUB_PORTFOLIOS
      })
    );
  }, []);

  return (
    <PageHeader>
      <PageHeader.Left>
        Listings: <span>Sub Portfolios</span>
      </PageHeader.Left>
    </PageHeader>
  );
};

export default SubPortfolios;
