import request from "apiClient";
function list(orgId, pageSize, page, sorted, filtered) {
  return request({
    url: `/api/${orgId}/invoices`,
    method: "GET",
    params: {
      limit: pageSize,
      page,
      sorted,
      filtered
    }
  }).then((response) => {
    return {
      rows: response.invoices,
      pages: Math.ceil(response.count / pageSize)
    };
  });
}
function get(orgId, invoiceId) {
  return request({
    url: `/api/${orgId}/invoices/${invoiceId}`,
    method: "GET"
  });
}
function create(orgId, formData) {
  return request({
    url: `/api/${orgId}/invoices/`,
    method: "POST",
    data: formData
  });
}
const WorkReportService = {
  get,
  list,
  create
};
export default WorkReportService;
