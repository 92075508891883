import request from "apiClient";
export default class PaymentMethodService {
  static listCustomerPaymentMethods(organizationId, customerId, bookingCode) {
    const data = {
      booking_code: bookingCode,
      customer_id: customerId
    };
    const queryString = new URLSearchParams(data).toString();
    return request({
      url: `/api/${organizationId}/payment_methods?${queryString}`,
      method: "GET"
    });
  }
  static setDefaultPaymentMethod(organizationId, data) {
    return request({
      url: `/api/${organizationId}/payment_methods/${data.payment_method_id}`,
      method: "PATCH",
      data
    });
  }
  static destroyPaymentMethod(organizationId, data) {
    const queryString = new URLSearchParams(data).toString();
    return request({
      url: `/api/${organizationId}/payment_methods/${data.payment_method_id}?${queryString}`,
      method: "DELETE"
    });
  }
}
