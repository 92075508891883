import React, { useState } from 'react';
import find from 'lodash/find';
import DefaultRangePicker from 'sharedComponents/DefaultRangePicker';
import {
  Box,
  InputButtonSelect,
  InputDateRange,
  InputSelect
} from '@directsoftware/ui-kit-web-admin';
import { useDetectMobile } from '../../../../shared/hooks';

const BookingDatesFilter = props => {
  const { isMobile } = useDetectMobile();
  const [focusedInput, setFocusedInput] = useState(null);
  let options = [];
  switch (props.reportType) {
    case 'business_payables':
      options = [{ value: 'check_in', label: 'Post Date' }];
      break;
    case 'folio_balance':
      options = [
        { value: 'check_in', label: 'Check In' },
        { value: 'check_out', label: 'Check Out' }
      ];
      break;
    case 'occupancy':
      options = [{ value: 'check_in', label: 'Date Range' }];
      break;
    default:
      options = props.showStayDates
        ? [
            { value: 'check_in', label: 'Check In' },
            { value: 'check_out', label: 'Check Out' },
            { value: 'created_at', label: 'Created At' },
            { value: 'stay_dates', label: 'Traveled Over' }
          ]
        : [
            { value: 'check_in', label: 'Check In' },
            { value: 'check_out', label: 'Check Out' },
            { value: 'created_at', label: 'Created At' }
          ];
      break;
  }

  const { label } = find(options, { value: props.date_filter || 'check_in' });
  const value = find(options, { value: props.date_filter || 'check_in' });
  const updateDates = dateParams =>
    props.updateDates && props.updateDates(dateParams);

  return props.controlPanelView ? (
    <>
      <Box>
        <InputButtonSelect removeBottomSpacer inputWidth="inline-flex">
          {options.map((button, idx) => (
            <InputButtonSelect.Button
              key={`button-group-${idx}`}
              isActive={value.value === button.value}
              onClick={() => {
                updateDates({
                  date_filter: button.value,
                  start_date: props.start_date,
                  end_date: props.end_date
                });
              }}
            >
              {button.label}
            </InputButtonSelect.Button>
          ))}
        </InputButtonSelect>
      </Box>
      <Box>
        <InputDateRange
          onDatesChange={({ startDate, endDate }) =>
            updateDates({
              date_filter: props.date_filter,
              start_date: startDate,
              end_date: endDate
            })
          }
          onFocusChange={focus => setFocusedInput(focus)}
          startDate={props.start_date}
          endDate={props.end_date}
          inputWidth={isMobile ? 'auto' : 'l'}
          focusedInput={focusedInput}
          removeBottomSpacer
        />
      </Box>
    </>
  ) : (
    <section>
      <label htmlFor="date_filter">Filter By:</label>
      <InputSelect
        name="date_filter"
        options={options}
        clearable={false}
        searchable={false}
        placeholder="Filter by"
        value={value}
        onChange={option =>
          updateDates({
            date_filter: option.value,
            start_date: props.start_date,
            end_date: props.end_date
          })
        }
        inputWidth="s"
      />
      <DefaultRangePicker
        minimumNightsOverride={props.minimumNights}
        label={`${label} Filter`}
        containerClass="date-picker-container"
        initialStartDate={props.start_date}
        initialEndDate={props.end_date}
        onDatesSet={({ startDate, endDate }) =>
          updateDates({
            date_filter: props.date_filter,
            start_date: startDate,
            end_date: endDate
          })
        }
      />
    </section>
  );
};

export default BookingDatesFilter;
