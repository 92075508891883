import request from "apiClient";
export default class BookableService {
  static listUnitListings(organizationID, queryParams) {
    return request({
      url: `/api/${organizationID}/bookable/unit_listings${queryParams}`,
      method: "GET"
    });
  }
  static listUnits(organizationID, queryParams) {
    return request({
      url: `/api/${organizationID}/bookable/units${queryParams}`,
      method: "GET"
    });
  }
}
