import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const BulkSettingsLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags,
  variation,
  ...props
}) => {
  return (
    <Navbar.Link
      href="/accounting/bulk-settings"
      variation={variation}
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={activeRoutes.secondary === navTags.secondary.FEE_ACCOUNTS}
    >
      Fee Account Manager
    </Navbar.Link>
  );
};

BulkSettingsLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object,
  variation: PropTypes.string
};

BulkSettingsLink.defaultProps = {
  variation: 'secondary'
};

export default BulkSettingsLink;
