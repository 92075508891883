import React from 'react';
import CustomEmailsLink from 'adminNavbar/messaging/links/custom-emails-link';
import SystemEmailsLink from 'adminNavbar/messaging/links/system-emails-link';
import VrboAutoEmailsLink from 'adminNavbar/messaging/links/vrbo-auto-emails-link';
import AirbnbAutoEmailsLink from 'adminNavbar/messaging/links/airbnb-auto-emails-link';

const LinksMobile = props => {
  return (
    <>
      <CustomEmailsLink {...props} />
      <SystemEmailsLink {...props} />
      <VrboAutoEmailsLink {...props} />
      <AirbnbAutoEmailsLink {...props} />
    </>
  );
};

export default LinksMobile;
