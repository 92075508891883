import React from 'react';

import MediaQuery from 'react-responsive';
import queryString from 'query-string';

export default class SetupStripeOrganization extends React.Component {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      hintsVisible: false
    };
  }

  toggleHintsVisiblity = e => {
    e.preventDefault();
    const hintsVisible = this.state.hintsVisible;
    this.setState({
      hintsVisible: !hintsVisible
    });
  };

  buildStripeUrlParams = () => {
    let stripeUrlParamObject = {};
    stripeUrlParamObject['response_type'] = 'code';
    stripeUrlParamObject['client_id'] = this.props.stripe_connect_key;
    stripeUrlParamObject['scope'] = 'read_write';
    stripeUrlParamObject['state'] = this.props.organization.subdomain;
    stripeUrlParamObject['stripe_user[email]'] = this.props.user.email;
    stripeUrlParamObject[
      'stripe_user[business_name]'
    ] = this.props.organization.name;
    stripeUrlParamObject[
      'stripe_user[business_type]'
    ] = this.props.organization.company_type;
    stripeUrlParamObject[
      'stripe_user[phone_number]'
    ] = this.props.user.telephone;
    stripeUrlParamObject['stripe_user[first_name]'] = this.props.first_name;
    stripeUrlParamObject['stripe_user[last_name]'] = this.props.last_name;
    stripeUrlParamObject['stripe_user[street_address]'] =
      this.props.location.adr_street + ' ' + this.props.location.adr_unit;
    stripeUrlParamObject['stripe_user[city]'] = this.props.location.adr_city;
    stripeUrlParamObject['stripe_user[state]'] = this.props.location.adr_state;
    stripeUrlParamObject['stripe_user[country]'] = this.props.country_iso_2;
    stripeUrlParamObject[
      'stripe_user[zip]'
    ] = this.props.location.adr_postal_code;
    stripeUrlParamObject['stripe_user[physical_product]'] = false;
    stripeUrlParamObject['stripe_user[product_category]'] =
      'tourism_and_travel';
    stripeUrlParamObject[
      'stripe_user[currency]'
    ] = this.props.organization.currency;
    const stringifiedQueryString = queryString.stringify(stripeUrlParamObject);
    return stringifiedQueryString;
  };

  redirectToStripeOauth = e => {
    e.preventDefault();
    window.location =
      'https://connect.stripe.com/oauth/authorize?' +
      this.buildStripeUrlParams();
  };

  render() {
    return (
      <section>
        <figure className="split">
          <main>
            <div className="wrapper">
              <section>
                <h2>Congrats, you did it!</h2>
                <p>
                  <b>Your new organization is all set up!</b>
                </p>
                <p>
                  Now, simply connect your account with Stripe, or skip this
                  step to access your dashboard. From there, you can build your
                  new site, list properties, and more.
                </p>
                <p>
                  <a
                    href="#"
                    onClick={e => this.redirectToStripeOauth(e)}
                    className="button stripe-connect"
                  >
                    <span>Connect with Stripe</span>
                  </a>
                </p>
              </section>
            </div>
            <section className="stepper-button-container single">
              <a
                className="button positive"
                href={`/dashboard/${this.props.organization.subdomain}`}
              >
                Skip this step →
              </a>
            </section>
          </main>
          <MediaQuery query="(max-width: 799px)">
            {this.state.hintsVisible ? (
              <aside>
                <figure className="card">
                  <figure>
                    <small className="subtitle">Stripe</small>
                    <p>
                      Stripe is the online payment system we use. If you do not
                      already have an account, please set one up! You’ll be able
                      to accept payments instantly or via secure ACH. Oh, and it
                      offers fraud protection, too!
                    </p>
                  </figure>
                  <figure>
                    <small className="subtitle">Note</small>
                    <p>
                      If you skip this step, you can always set Stripe up later
                      by visiting your Organization Settings.
                    </p>
                    <p>
                      You can still take bookings without setting up Stripe, but
                      your payments will not instantly be available, and you may
                      be subject to additional processing fees.
                    </p>
                  </figure>
                </figure>
              </aside>
            ) : null}
            <a
              href="#"
              className="hints-trigger"
              onClick={e => this.toggleHintsVisiblity(e)}
            >
              ?
            </a>
          </MediaQuery>
          <MediaQuery query="(min-width: 800px)">
            <aside>
              <div>
                <span href="#" className="hints-icon">
                  ?
                </span>
                <figure className="card">
                  <figure>
                    <small className="subtitle">Stripe</small>
                    <p>
                      Stripe is the online payment system we use. If you do not
                      already have an account, please set one up! You’ll be able
                      to accept payments instantly or via secure ACH. Oh, and it
                      offers fraud protection, too!
                    </p>
                  </figure>
                  <figure>
                    <small className="subtitle">Note</small>
                    <p>
                      If you skip this step, you can always set Stripe up later
                      by visiting your Organization Settings.
                    </p>
                    <p>
                      You can still take bookings without setting up Stripe, but
                      your payments will not instantly be available, and you may
                      be subject to additional processing fees.
                    </p>
                  </figure>
                </figure>
              </div>
            </aside>
          </MediaQuery>
        </figure>
      </section>
    );
  }
}
