import React from 'react';
import find from 'lodash/find';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';

const AllChannelOptions = [
  {
    label: 'All Channels',
    value: ''
  },
  {
    label: 'Airbnb',
    value: 3
  },
  {
    label: 'Booking.com',
    value: 1
  },
  {
    label: 'Direct',
    value: 0
  },
  {
    label: 'Vrbo',
    value: 2
  }
];

const SingleChannelOptions = [
  {
    label: 'Airbnb',
    value: 3
  },
  {
    label: 'Booking.com',
    value: 1
  },
  {
    label: 'Direct',
    value: 0
  },
  {
    label: 'Vrbo',
    value: 2
  }
];

const ChannelSelect = props => {
  const ChannelOptions = props.singleChannelOnly
    ? SingleChannelOptions
    : AllChannelOptions;

  return (
    <figure style={props.styles}>
      <label htmlFor="channel">Channel</label>
      <InputSelect
        name="channel"
        className="magnified"
        options={ChannelOptions}
        isClearable={false}
        isSearchable={false}
        placeholder={props.placeholder || 'Select channel'}
        onChange={option => props.onChannelChange(option.value)}
        value={find(ChannelOptions, { value: props.channelID })}
      />
    </figure>
  );
};

export default ChannelSelect;
