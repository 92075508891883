import React from 'react';
import {
  ConsolidatedItemEditToggleHeader,
  DirectCheckbox
} from 'adminComponents';
import { toast } from 'react-toastify';

import { UnitService } from 'adminApi';
import { FormField } from '@directsoftware/ui-kit-web-admin';

class ManageSingleFieldsGuestControls extends React.Component {
  state = {
    // form state
    isEditing: false,
    isDirty: false,
    isSaving: false,
    isSaved: false,
    descriptionRequired: !this.props.guest_controls.children.value,
    // form values
    guestControls: this.props.guest_controls,
    guestControlsCache: this.props.guest_controls,
    childrenNotAllowedDescription: this.props.description,
    childrenNotAllowedDescriptionCache: this.props.description
  };

  toggleEditing = () => this.setState({ isEditing: true });

  finishEditing = shouldSave => {
    const changeState = {
      isDirty: false,
      isEditing: false,
      isSaving: false,
      isSaved: shouldSave
    };

    if (shouldSave) {
      changeState.guestControls = this.state.guestControlsCache;
    } else {
      changeState.guestControlsCache = this.state.guestControls;
    }

    this.setState(changeState);
  };

  attemptSave = () => {
    if (
      this.state.descriptionRequired &&
      this.state.childrenNotAllowedDescription === ''
    ) {
      toast.warn(
        'If you cannot accomodate children, you must provide a reason',
        { autoClose: 5000 }
      );
    } else {
      this.setState({ isSaving: true, isSaved: false }, () => {
        UnitService.update(this.props.organization.id, this.props.unit.id, {
          guest_controls: {
            description: this.state.childrenNotAllowedDescription,
            ...this.state.guestControls
          }
        })
          .then(_res => {
            this.finishEditing(true);
          })
          .catch(_err => {
            this.finishEditing(false);
          });
      });
    }
  };

  onChange = change => {
    if (change.field === 'description') {
      this.setState({
        childrenNotAllowedDescription: change.value,
        isDirty: true
      });
    } else {
      const guest_controls_copy = this.state.guestControls;
      guest_controls_copy[change.field].value = !change.value;
      if (change.field === 'children') {
        this.setState({
          guestControls: guest_controls_copy,
          isDirty: true,
          descriptionRequired: change.value
        });
      } else {
        this.setState({ guestControls: guest_controls_copy, isDirty: true });
      }
    }
  };

  renderEditing = () => {
    const { guestControls } = this.state;
    return (
      <section className="consolidated-item-form-editing">
        {Object.keys(guestControls).map(k => {
          return (
            <div className="consolidated-item-subsection">
              <DirectCheckbox
                name={guestControls[k].editingLabel}
                labelText={guestControls[k].editingLabel}
                onChange={() =>
                  this.onChange({ field: k, value: guestControls[k].value })
                }
                checked={guestControls[k].value}
              />
            </div>
          );
        })}
        <FormField
          inputType="text"
          labelText="You must specify a reason if you choose not to allow children or infants"
          labelHtmlFor="no_children_description"
          inputProps={{
            id: 'no_children_description',
            value: this.state.childrenNotAllowedDescription,
            onChange: e =>
              this.onChange({ field: 'description', value: e.target.value })
          }}
        />
      </section>
    );
  };

  renderViewing = () => {
    const { guestControls, childrenNotAllowedDescription } = this.state;
    return (
      <section className="consolidated-item-form-viewing">
        {Object.keys(guestControls).map(k => {
          return (
            <div className="consolidated-item-subsection">
              <h4>{this.renderLabel(guestControls[k])}</h4>
            </div>
          );
        })}
        <div className="consolidated-item-subsection">
          <h4>Description:</h4>
          {childrenNotAllowedDescription}
        </div>
      </section>
    );
  };

  renderLabel = opt => {
    switch (opt.value) {
      case 'not_set':
        return opt.viewingLabel;
      case true:
        return opt.trueLabel;
      case false:
        return opt.falseLabel;
    }
  };

  render() {
    const {
      state: { isDirty, isEditing, isSaving },
      props: { blockEdit }
    } = this;

    return (
      <figure
        className={`consolidated-item-form-section ${
          isEditing ? 'form-editing' : ''
        }`}
      >
        <ConsolidatedItemEditToggleHeader
          toggleEditing={this.toggleEditing}
          finishEditingAndRevert={() => this.finishEditing(false)}
          finishEditingAndUpdate={() => this.finishEditing(true)}
          attemptSave={this.attemptSave}
          isDirty={isDirty}
          isEditing={isEditing}
          isSaving={isSaving}
          title="Guest Controls"
          blockEdit={blockEdit}
        />
        {isEditing ? this.renderEditing() : this.renderViewing()}
      </figure>
    );
  }
}
ManageSingleFieldsGuestControls.defaultProps = {
  guest_controls: {
    children: {
      viewingLabel: 'Suitable for Children',
      trueLabel: 'Children welcome',
      falseLabel: 'Not suitable for children',
      editingLabel: 'Is listing suitable for children (2-12 years)?',
      value: false
    },
    infants: {
      viewingLabel: 'Suitable for Infants',
      trueLabel: 'Infants welcome',
      falseLabel: 'Not suitable for infants',
      editingLabel: 'Is listing suitable for infants (under 2 years)?',
      value: false
    },
    smoking: {
      viewingLabel: 'Smoking Allowed',
      trueLabel: 'Smoking allowed',
      falseLabel: 'Smoking not allowed',
      editingLabel: 'Is smoking allowed?',
      value: false
    },
    pets: {
      viewingLabel: 'Pets Allowed',
      trueLabel: 'Pets allowed',
      falseLabel: 'Pets not allowed',
      editingLabel: 'Are pets allowed?',
      value: false
    },
    events: {
      viewingLabel: 'Events Allowed',
      trueLabel: 'Events allowed',
      falseLabel: 'Events not allowed',
      editingLabel: 'Are parties or events allowed?',
      value: false
    }
  },
  description: ''
};
export default ManageSingleFieldsGuestControls;
