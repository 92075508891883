import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';
import PermissionComponent from 'PermissionComponent';

const TemplatesLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags,
  ...props
}) => {
  return (
    <PermissionComponent user={props.user} permission="templates">
      <Navbar.Link
        href="/workflows"
        variation="secondary"
        onClick={() => {
          closeMenuPanel();
          if (closeMobileNav)
            // desktop links wont have this
            closeMobileNav();
        }}
        isActive={activeRoutes.secondary === navTags.secondary.TEMPLATES}
      >
        Templates
      </Navbar.Link>
    </PermissionComponent>
  );
};

TemplatesLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object
};

export default TemplatesLink;
