import React from 'react';
import { Box, Divider, Navbar } from '@directsoftware/ui-kit-web-admin';
import PermissionComponent from 'PermissionComponent';
import BankAccountsLink from 'adminNavbar/accounting/links/bank-accounts-link';
import ReportingLink from 'adminNavbar/accounting/links/reporting-link';

const LinksDesktop = props => {
  return (
    <Navbar.LinkMenuColumn>
      <PermissionComponent user={props.user} permission="bank_accounts">
        {props.organization.payment_processor !== 'unspecified' &&
          props.user.role_name === 'organization_owner' && (
            <BankAccountsLink {...props} />
          )}
      </PermissionComponent>
      <PermissionComponent user={props.user} permission="view_reporting">
        <Box className="nav-v3-column-divider">
          <Divider />
        </Box>
        <ReportingLink {...props} />
      </PermissionComponent>
    </Navbar.LinkMenuColumn>
  );
};

export default LinksDesktop;
