import styled from 'styled-components';

const NavContainer = styled.div`
  display: flex;
  width: 100%;
  border-top: 2px solid #fafafa;
`;

const NavTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 50px;
  flex: 1 0 auto;
  cursor: pointer;

  img {
    height: 50%;
    width: auto;
  }
`;

export { NavContainer, NavTab };
