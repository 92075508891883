// Dependencies
// -----------------------------------------------
import React from 'react';
import find from 'lodash/find';
import styled from 'styled-components';

// Components
// -----------------------------------------------
import { BookingDatesFilter } from 'adminComponents/FilterSection/Filters';
import { OrganizationContext } from 'adminContexts';
import DefaultRangePicker from 'sharedComponents/DefaultRangePicker';
import InventorySegmentFilterGroup from 'adminComponents/InventorySegmentFilters';
import IndicatorToggle from 'adminComponents/indicator-toggle';
import {
  Divider,
  IconFontAwesome,
  InputSelect,
  InputText
} from '@directsoftware/ui-kit-web-admin';

const customSelectStyles = {
  menu: provided => ({ ...provided, zIndex: 9999999 })
};

const FormHalf = styled.div`
  display: flex;
  flex-direction: row;
`;

// -----------------------------------------------
// COMPONENT->LIST-RESERVATIONS-FILTER -----------
// -----------------------------------------------
export default class ListReservationsFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: this.props.filters.search,
      time_period: this.props.filters.time_period,
      channel_id: this.props.filters.channel_id,
      payment_status: this.props.filters.payment_status,
      reservation_type: this.props.filters.reservation_type,
      generation_type: this.props.filters.generation_type,
      guest_count: this.props.filters.guest_count,
      bedroom_count: this.props.filters.bedroom_count,
      bathroom_count: this.props.filters.bathroom_count,
      user: this.props.user || {},
      start_date: null,
      end_date: null,
      date_filter: 'check_in',
      portfolio_id: this.props.filters.portfolio_id,
      portfolio_options: [],
      availability_start_date: null,
      availability_end_date: null,
      viewport: {
        height: document.documentElement.clientHeight,
        width: document.documentElement.clientWidth
      }
    };
  }

  updateFilters = data => {
    const newFilter = {};
    newFilter[data.filter] = data.value;
    this.setState(newFilter, () => {
      this.props.applyFilters(newFilter);
    });
  };

  updateDates = newDates => {
    this.setState(newDates, () => {
      this.props.applyFilters(newDates);
    });
  };

  updateAvailabilityDates = newDates => {
    this.setState(newDates, () => {
      const { availability_start_date, availability_end_date } = newDates;
      if (availability_start_date && availability_end_date) {
        this.props.applyFilters(newDates);
      }
    });
  };

  onSearchChange = (e, value) => {
    e.preventDefault();
    this.updateFilters({
      filter: 'search',
      value
    });
  };

  currentHostname = () => window.location.host;

  showRestrictedChannelOptions = () =>
    ['app.getdirect.io', 'demo.getdirect.io'].includes(this.currentHostname());

  // -----------------------------------------------
  // RENDER ----------------------------------------
  // -----------------------------------------------
  render() {
    const timePeriodOptions = [
      { value: '0', label: 'Today', filter: 'time_period' },
      { value: '1', label: 'Tomorrow', filter: 'time_period' },
      { value: '6', label: 'Next 7 Days', filter: 'time_period' },
      { value: '29', label: 'Next 30 Days', filter: 'time_period' },
      { value: '-1', label: 'Yesterday', filter: 'time_period' },
      { value: '-6', label: 'Past 7 Days', filter: 'time_period' },
      { value: '-29', label: 'Past 30 Days', filter: 'time_period' },
      { value: '', label: 'All Time', filter: 'time_period' }
    ];

    const channelOptions = this.showRestrictedChannelOptions()
      ? [
          { value: '', label: 'All Channels', filter: 'channel_id' },
          { value: 3, label: 'AirBnb', filter: 'channel_id' },
          { value: 1, label: 'Booking.com', filter: 'channel_id' },
          { value: 0, label: 'Direct', filter: 'channel_id' },
          { value: 2, label: 'Vrbo', filter: 'channel_id' }
        ]
      : [
          { value: '', label: 'All Channels', filter: 'channel_id' },
          { value: 3, label: 'AirBnb', filter: 'channel_id' },
          { value: 1, label: 'Booking.com', filter: 'channel_id' },
          { value: 0, label: 'Direct', filter: 'channel_id' },
          { value: 2, label: 'Vrbo', filter: 'channel_id' }
        ];

    const vehicleChannelOptions = [
      { value: '', label: 'All Channels', filter: 'channel_id' },
      { value: 0, label: 'Direct', filter: 'channel_id' },
      { value: 12, label: 'Good Sam', filter: 'channel_id' },
      { value: 8, label: 'Outdoorsy', filter: 'channel_id' },
      { value: 9, label: 'RVezy', filter: 'channel_id' },
      { value: 11, label: 'RVnGO', filter: 'channel_id' },
      { value: 6, label: 'RVshare', filter: 'channel_id' },
      { value: 10, label: 'Wallabing', filter: 'channel_id' }
    ];

    const paymentStatusOptions = [
      { value: '', label: 'All Types', filter: 'payment_status' },
      { value: 'full', label: 'Paid in full', filter: 'payment_status' },
      { value: 'partial', label: 'Partial payment', filter: 'payment_status' },
      { value: 'not_paid', label: 'No payment', filter: 'payment_status' }
    ];

    const reservationStatusOptions = [
      { value: '', label: 'All Types', filter: 'booking_status' },
      { value: 'cancelled', label: 'Cancelled', filter: 'booking_status' },
      { value: 'confirmed', label: 'Confirmed', filter: 'booking_status' },
      { value: 'archived', label: 'Archived', filter: 'booking_status' }
    ];

    const reservationTypeOptions = [
      { value: '', label: 'All Types', filter: 'reservation_type' },
      {
        value: 'guest',
        label: 'Guest Reservation',
        filter: 'reservation_type'
      },
      { value: 'owner', label: 'Owner Block', filter: 'reservation_type' },
      {
        value: 'maintenance',
        label: 'Maintenance Block',
        filter: 'reservation_type'
      },
      { value: 'admin', label: 'Admin Block', filter: 'reservation_type' },
      { value: 'friend', label: 'Friend of Owner', filter: 'reservation_type' },
      { value: 'comp', label: 'Comped Guest', filter: 'reservation_type' }
    ];

    const guestCountOptions = [
      { value: '', label: 'Any', filter: 'guest_count' }
    ];

    for (let i = 1; i <= 20; i += 1) {
      guestCountOptions.push({
        value: `${i}`,
        label: `${i}+`,
        filter: 'guest_count'
      });
    }

    const bedroomCountOptions = [
      { value: '', label: 'Any', filter: 'bedroom_count' }
    ];

    for (let i = 1; i <= 10; i += 1) {
      bedroomCountOptions.push({
        value: `${i}`,
        label: `${i}+`,
        filter: 'bedroom_count'
      });
    }

    const bathroomCountOptions = [
      { value: '', label: 'Any', filter: 'bathroom_count' }
    ];

    for (let i = 1; i <= 10; i += 1) {
      bathroomCountOptions.push({
        value: `${i}`,
        label: `${i}+`,
        filter: 'bathroom_count'
      });
    }

    const creationMethodOptions = [
      { value: '', label: 'All Types', filter: 'creation_method' },
      { value: 'automatically', label: 'Automatic', filter: 'creation_method' },
      { value: 'manually', label: 'Manual', filter: 'creation_method' },
      { value: 'import', label: 'Import', filter: 'creation_method' }
    ];

    const filterStyle = {
      display: 'block'
    };

    let inputStyle;
    if (this.state.viewport.width > 800) {
      inputStyle = {
        flex: '1',
        marginRight: '5px'
      };
    } else {
      inputStyle = {
        display: 'block',
        marginRight: 0,
        width: '100%'
      };
    }

    return (
      <div style={{ zIndex: 2 }}>
        {this.state.viewport.width < 800 && (
          <>
            <InputText
              onChange={e => this.onSearchChange(e, e.target.value)}
              name="search"
              prependIcon={<IconFontAwesome name="search" />}
              onClear={e => this.onSearchChange(e, '')}
              placeholder="Property, person, or booking ID"
              value={this.state.search}
            />
            <Divider padding="s" />
          </>
        )}
        {this.props.user.role >= 4 && (
          <InventorySegmentFilterGroup
            organizationId={this.props.organization.id}
            setFilters={this.props.setFilters}
            filters={this.props.filters}
            widthLimited
          />
        )}
        {this.props.view === 'calendar' && this.state.viewport.width > 800 && (
          <section className="consolidated-filters" style={filterStyle}>
            <figure style={inputStyle}>
              <label htmlFor="source">Availability</label>
              <DefaultRangePicker
                disabled={false}
                initialStartDate={this.props.unset_availability_start_date}
                initialEndDate={this.props.unset_availability_end_date}
                renderUnsetLink={onDatesUnset => (
                  <a
                    style={{
                      fontSize: '14px',
                      marginTop: 8,
                      display: 'block',
                      position: 'relative',
                      textTransform: 'capitalize'
                    }}
                    onClick={x => {
                      this.updateDates({
                        availability_start_date: '',
                        availability_end_date: '',
                        start_date: '',
                        end_date: ''
                      });
                    }}
                  >
                    Clear Dates
                  </a>
                )}
                onDatesSet={({ startDate, endDate }) => {
                  this.updateAvailabilityDates({
                    availability_start_date: startDate.format('DD-MM-YYYY'),
                    availability_end_date: endDate.format('DD-MM-YYYY')
                  });
                }}
              />
            </figure>

            <figure style={inputStyle}>
              <label htmlFor="bedroom_count">Bedrooms</label>
              <InputSelect
                name="bedroom_count"
                options={bedroomCountOptions}
                isClearable
                isSearchable={false}
                placeholder="Any"
                value={find(bedroomCountOptions, {
                  value: this.props.filters.bedroom_count
                })}
                onChange={this.updateFilters}
              />
            </figure>

            <figure style={inputStyle}>
              <label htmlFor="bathroom_count">Bathrooms</label>
              <InputSelect
                name="bathroom_count"
                options={bathroomCountOptions}
                isClearable
                isSearchable={false}
                placeholder="Any"
                value={find(bathroomCountOptions, {
                  value: this.props.filters.bathroom_count
                })}
                onChange={this.updateFilters}
              />
            </figure>

            <figure style={inputStyle}>
              <label htmlFor="guest_count">Guests</label>
              <InputSelect
                name="guest_count"
                options={guestCountOptions}
                isClearable
                isSearchable={false}
                placeholder="Any"
                value={find(guestCountOptions, {
                  value: this.props.filters.guest_count
                })}
                onChange={this.updateFilters}
              />
            </figure>
          </section>
        )}
        <section className="consolidated-filters" style={filterStyle}>
          <FormHalf>
            <figure style={inputStyle}>
              <label htmlFor="paymentStatus">Payment status</label>
              <InputSelect
                name="payment_status"
                options={paymentStatusOptions}
                isClearable={false}
                isSearchable={false}
                placeholder="All Types"
                value={find(paymentStatusOptions, {
                  value: this.props.filters.payment_status
                })}
                onChange={this.updateFilters}
              />
            </figure>
            <figure style={inputStyle}>
              <label htmlFor="source">Channel</label>
              <InputSelect
                name="channel_id"
                options={
                  this.props.organization.organization_type === 'rv_fleet'
                    ? vehicleChannelOptions
                    : channelOptions
                }
                isClearable={false}
                isSearchable={false}
                placeholder="All Channels"
                value={find(channelOptions, {
                  value: this.props.filters.channel_id
                })}
                onChange={this.updateFilters}
              />
            </figure>
          </FormHalf>
          <FormHalf>
            <figure style={inputStyle}>
              <label htmlFor="reservationType">Reservation type</label>
              <InputSelect
                name="reservation_type"
                options={reservationTypeOptions}
                isClearable={false}
                isSearchable={false}
                placeholder="All Types"
                value={find(reservationTypeOptions, {
                  value: this.props.filters.reservation_type
                })}
                onChange={this.updateFilters}
              />
            </figure>
            <figure style={inputStyle}>
              <label htmlFor="generationType">Generated By</label>
              <InputSelect
                name="generation_type"
                options={creationMethodOptions}
                isClearable={false}
                isSearchable={false}
                placeholder="All Types"
                value={find(creationMethodOptions, {
                  value: this.props.filters.creation_method
                })}
                onChange={this.updateFilters}
              />
            </figure>
          </FormHalf>
        </section>
        <BookingDatesFilter
          minimumNights={0}
          updateDates={this.updateDates}
          start_date={this.state.start_date}
          end_date={this.state.end_date}
          date_filter={this.state.date_filter}
        />
        {this.props.view === 'calendar' && (
          <IndicatorToggle
            style={{ margin: '10px' }}
            toggleAction={() =>
              this.updateFilters({
                filter: 'onlyActive',
                value: !this.props.filters.onlyActive
              })
            }
            toggleStatus={this.props.filters.onlyActive}
            toggleFalseLabel="Only Show Active Properties"
            toggleTrueLabel="Only Show Active Properties"
          />
        )}
      </div>
    );
  }
}

ListReservationsFilter.contextType = OrganizationContext;
