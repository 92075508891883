import React from 'react';
import some from 'lodash/some';
import get from 'lodash/get';

import { OrganizationContext } from 'adminContexts';
import SelectService from 'adminApi/SelectService';
import { Label, InputSelect } from '@directsoftware/ui-kit-web-admin';

// This component should be used for when data options
// have the possibility of being rather large (> 100), or
// if you simply want to be able to search options
export default class SearchableSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      loading: true
    };
  }

  componentDidMount() {
    this.fetchOptions();
  }

  fetchOptions = () => {
    SelectService.index(
      this.context.organizationId,
      this.props.selectData
    ).then(response => {
      const options = response.options.map(data => {
        return {
          label: data[this.props.optionLabelKey],
          value: data[this.props.optionValueKey],
          data
        };
      });

      this.setState({ options, loading: false });
    });
  };

  loadOptions = search => {
    return new Promise(resolve => {
      resolve(this.filterData(search));
    });
  };

  filterData = search => {
    const lowerSearch = search.toLowerCase();

    return this.state.options
      .filter(option => {
        return this.isValidOption(option, lowerSearch);
      })
      .slice(0, 25); // keeping options to 25 to keep client side rendering performant
  };

  isValidOption = (option, lowerSearch) => {
    return some(this.props.searchableKeys, key => {
      return option.data[key].toLowerCase().includes(lowerSearch);
    });
  };

  setDefaultValue = () => {
    const { options } = this.state;
    return this.props.defaultValue(options);
  };

  render() {
    if (this.state.loading) return null;

    const { label, name, placeholder, onSelect, useDesignKit } = this.props;

    return (
      <React.Fragment>
        {useDesignKit ? <Label>{label}</Label> : <label>{label}</label>}
        <InputSelect
          componentType="async"
          loadOptions={this.loadOptions}
          name={name}
          placeholder={placeholder}
          isClearable
          defaultOptions
          onChange={option => onSelect(get(option, 'data', {}))}
          defaultValue={this.setDefaultValue()}
          styles={{
            menuList: (provided, _state) => ({
              ...provided,
              maxHeight: '128px'
            })
          }}
        />
      </React.Fragment>
    );
  }
}

SearchableSelect.defaultProps = {
  label: 'Search',
  optionLabelKey: 'name',
  optionValueKey: 'id',
  placeholder: 'Type to search...',
  name: 'searchable_select',
  searchableKeys: ['name'],
  selectData: {},
  defaultValue: _options => null,
  onSelect: _data => {}
};

SearchableSelect.contextType = OrganizationContext;
