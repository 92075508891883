import React, { useEffect } from 'react';
import { PageHeader } from '@directsoftware/ui-kit-web-admin';
import { useDispatch } from 'react-redux';
import { updateActiveRoutes } from '../../../../redux/slices/navigation';
import { ACCOUNTING_NAV_TAGS } from '../../../../../shared/nav';

const ChartOfAccountsJournalEntryDetails = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateActiveRoutes({
        primary: ACCOUNTING_NAV_TAGS.PRIMARY,
        secondary: ACCOUNTING_NAV_TAGS.secondary.GENERAL_LEDGER
      })
    );
  }, []);

  return (
    <PageHeader height="l">
      <PageHeader.Left
        breadcrumbs={[
          {
            href: `/accounting/chart-of-accounts/general-ledger/${
              props.subscription.id
            }`,
            label: 'General Ledger'
          },
          {
            label: 'Journal Entry'
          }
        ]}
      >
        Journal Entry: <span>{props.entryId}</span>
      </PageHeader.Left>
    </PageHeader>
  );
};

export default ChartOfAccountsJournalEntryDetails;
