import React from 'react';
import SelectService from 'adminApi/SelectService';
import {
  CallToActionButton,
  Spacer,
  FormField
} from '@directsoftware/ui-kit-web-admin';

export default class FilterContainer extends React.Component {
  state = {
    filter_portfolio_id: '',
    portfolio_options: [],
    filter_subportfolio_id: '',
    subportfolio_options: [],
    filter_unit_group_id: '',
    unitgroup_options: [],
    subportfolio_disabled: true,
    unitgroup_disabled: true
  };

  componentDidMount() {
    this.loadOptions('Portfolio', 'portfolio_options', 'All Portfolios');
    if (this.props.filters) {
      const {
        portfolio_id,
        subportfolio_id,
        unit_group_id
      } = this.props.filters;
      this.setState({
        filter_portfolio_id: portfolio_id,
        filter_subportfolio_id: subportfolio_id,
        filter_unit_group_id: unit_group_id
      });
      if (subportfolio_id) {
        this.loadOptions(
          'Subportfolio',
          'subportfolio_options',
          'All Subportfolios',
          portfolio_id,
          'subportfolio_id',
          'portfolio_id',
          'subportfolio_disabled'
        );
        if (unit_group_id) {
          this.loadOptions(
            'UnitGroup',
            'unitgroup_options',
            'All Unit Groups',
            subportfolio_id,
            'unit_group_id',
            'subportfolio_id',
            'unitgroup_disabled'
          );
        }
      }
    }
  }

  onPortfolioFilterChange = (selection, actions) => {
    if (actions.action !== 'clear') {
      if (selection.value !== '') {
        // must not enable the next dropdown if the default value was selected
        this.loadOptions(
          'Subportfolio',
          'subportfolio_options',
          'All Subportfolios',
          selection.value,
          'subportfolio_id',
          'portfolio_id',
          'subportfolio_disabled'
        );
        this.setState({ filter_portfolio_id: selection.value }); // also needs to handle resetting selects
        this.props.setFilters(selection);
      } else {
        // needs to clear other filters and reset itself, if 'All Portfolios' was chosen
        this.resetFilters();
      }
    } else {
      this.props.setFilters({ filter: 'portfolio_id', value: '' });
      this.resetSubportfolio();
      this.resetUnitGroup();
    }
  };

  onSubportfolioFilterChange = (selection, actions) => {
    if (actions.action !== 'clear') {
      if (selection.value !== '') {
        this.loadOptions(
          'UnitGroup',
          'unitgroup_options',
          'All Unit Groups',
          selection.value,
          'unit_group_id',
          'subportfolio_id',
          'unitgroup_disabled'
        );
        this.setState({ filter_subportfolio_id: selection.value });
        this.props.setFilters(selection);
      } else {
        // needs to clear unitgroup filter and unset subportfolio filter
        this.resetUnitGroup();
        this.props.setFilters({ filter: 'subportfolio_id', value: '' });
      }
    } else {
      this.props.setFilters({ filter: 'subportfolio_id', value: '' });
      this.resetUnitGroup();
    }
  };

  onUnitGroupFilterChange = (selection, actions) => {
    if (actions.action !== 'clear') {
      this.setState({ filter_unit_group_id: selection.value });
      this.props.setFilters(selection);
    } else {
      this.setState({ filter_unit_group_id: '' });
      this.props.setFilters({ filter: 'unit_group_id', value: '' });
    }
  };

  resetFilters = () => {
    const {
      filter_portfolio_id,
      filter_subportfolio_id,
      filter_unit_group_id
    } = this.state;
    const { setFilters } = this.props;
    this.setState(
      {
        filter_portfolio_id: '',
        subportfolio_disabled: true,
        subportfolio_options: [],
        filter_subportfolio_id: '',
        unitgroup_disabled: true,
        unitgroup_options: [],
        filter_unit_group_id: ''
      },
      () => {
        filter_portfolio_id !== '' &&
          setFilters({ filter: 'portfolio_id', value: '' });
        filter_subportfolio_id !== '' &&
          setFilters({ filter: 'subportfolio_id', value: '' });
        filter_unit_group_id !== '' &&
          setFilters({ filter: 'unit_group_id', value: '' });
      }
    );
  };

  resetUnitGroup = () => {
    this.setState({
      filter_subportfolio_id: '',
      unitgroup_disabled: true,
      unitgroup_options: [],
      filter_unit_group_id: ''
    });
  };

  resetSubportfolio = () => {
    this.setState({
      filter_portfolio_id: '',
      subportfolio_disabled: true,
      subportfolio_options: [],
      filter_subportfolio_id: ''
    });
  };

  loadOptions = (
    model,
    optionsArr,
    label,
    id = false,
    filter = '',
    condition = '',
    disabled = ''
  ) => {
    if (id) {
      // is called after portfolio selection
      SelectService.index(this.props.orgId, {
        model,
        select: ['id', 'name'],
        order: 'name',
        where: [`${condition} = ?`, id]
      }).then(res => {
        if (res.options.length > 0) {
          // next select should not be enabled if SelectService returns no options
          const options = [
            {
              value: '',
              label,
              filter
            }
          ];
          res.options.forEach(opt => {
            options.push({
              value: opt.id,
              label: opt.name,
              filter
            });
          });
          this.setState({ [optionsArr]: options, [disabled]: false });
        } else {
          this.setState({ [disabled]: true });
        }
      });
    } else {
      SelectService.index(this.props.orgId, {
        model,
        select: ['id', 'name'],
        order: 'name'
      }) // is called to load portfolio options
        .then(res => {
          const options = [
            {
              value: '',
              label,
              filter: 'portfolio_id'
            }
          ];
          res.options.forEach(opt => {
            options.push({
              value: opt.id,
              label: opt.name,
              filter: 'portfolio_id'
            });
          });
          this.setState({ [optionsArr]: options });
        });
    }
  };

  render() {
    const {
      portfolio_options,
      filter_portfolio_id,
      subportfolio_disabled,
      subportfolio_options,
      filter_subportfolio_id,
      unitgroup_disabled,
      unitgroup_options,
      filter_unit_group_id
    } = this.state;
    return (
      <>
        <FormField
          labelText="Portfolio"
          inputType="select"
          placeholder="All Portfolios"
          labelHtmlFor="portfolio_id"
          isClearable
          inputProps={{
            name: 'portfolio_id',
            onChange: this.onPortfolioFilterChange,
            value: portfolio_options.filter(
              option => option.value === filter_portfolio_id
            ),
            options: portfolio_options
          }}
        />
        <FormField
          labelText="Subportfolio"
          inputType="select"
          placeholder="All Subportfolios"
          labelHtmlFor="subportfolio_id"
          isClearable
          inputProps={{
            name: 'subportfolio_id',
            onChange: this.onSubportfolioFilterChange,
            value: subportfolio_options.filter(
              option => option.value === filter_subportfolio_id
            ),
            options: subportfolio_options,
            isDisabled: subportfolio_disabled
          }}
        />
        <FormField
          labelText="Unit Group"
          inputType="select"
          placeholder="All Unit Groups"
          labelHtmlFor="unit_group_id"
          inputProps={{
            name: 'unit_group_id',
            onChange: this.onUnitGroupFilterChange,
            value: unitgroup_options.filter(
              option => option.value === filter_unit_group_id
            ),
            options: unitgroup_options,
            isDisabled: unitgroup_disabled
          }}
        />
        <Spacer size="xs" />
        <CallToActionButton
          appearance="ghost"
          variation="secondary"
          onClick={this.resetFilters}
        >
          Reset Filters
        </CallToActionButton>
      </>
    );
  }
}
