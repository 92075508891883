import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SettingsHome from './settings-home';
import IntegrationsHome from './integrations-home';
import BillingHome from './billing-home';
import AccountHome from "./account-home";

const SettingsRoutes = props => {
  return (
    <Switch>
      <Route
        path="/account"
        render={routeProps => <AccountHome {...props} {...routeProps} />}
      />
      <Route
        path="/settings/integrations"
        render={routeProps => <IntegrationsHome {...props} {...routeProps} />}
      />
      <Route
        path="/settings/billing"
        render={routeProps => <BillingHome {...props} {...routeProps} />}
      />
      <Route
        path="/settings"
        render={routeProps => <SettingsHome {...props} {...routeProps} />}
      />
    </Switch>
  );
};

export default SettingsRoutes;
