import request from "apiClient";
export default class ConversationService {
  static index(organizationId, data = {}) {
    return request({
      url: `/api/${organizationId}/conversations`,
      method: "POST",
      data
    });
  }
  static fetch(organizationId, conversationId, params = "") {
    return request({
      url: `/api/${organizationId}/conversations/${conversationId}${params}`,
      method: "GET"
    });
  }
  static update(organizationId, conversationId, data) {
    return request({
      url: `/api/${organizationId}/conversations/${conversationId}`,
      method: "PATCH",
      data
    });
  }
  static archivedUpdateMany(organizationId, data) {
    return request({
      url: `/api/${organizationId}/conversations/archived_update_many`,
      method: "PUT",
      data
    });
  }
  static archivedUpdateAll(organizationId, data) {
    return request({
      url: `/api/${organizationId}/conversations/archived_update_all`,
      method: "PUT",
      data
    });
  }
  static delete(organizationId, conversationId) {
    return request({
      url: `/api/${organizationId}/conversations/${conversationId}`,
      method: "DELETE"
    });
  }
}
