import React from 'react';

import SearchableSelect from './SearchableSelect';

const defaultSelectData = {
  model: 'UnitListing',
  joins: ['LEFT JOIN units ON unit_listings.unit_id = units.id'],
  select: ['id', 'units.name as name']
};

const UnitListingSelect = props => (
  <SearchableSelect
    selectData={{ ...defaultSelectData, ...{ ...props.defaultSelectData } }}
    label={'Listing'}
    placeholder={'Search by listing name...'}
    name={'unit_listing_select'}
    {...props}
  />
);

export default UnitListingSelect;
