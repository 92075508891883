const options = {
  type: [
    { value: "flat", label: "Fixed rate" },
    { value: "percent", label: "Percentage" }
  ],
  tax: [
    { value: false, label: "Non-taxable" },
    { value: true, label: "Taxable" }
  ],
  authorization: [
    {
      value: "check_in",
      label: "Check-in date"
    },
    {
      value: "day3",
      label: "3 days prior to check-in"
    }
  ],
  refund: [
    { value: false, label: "Non-refundable" },
    { value: true, label: "Refundable" }
  ],
  autoProcessPayments: [
    { value: true, label: "Automatic Processing" },
    { value: false, label: "Manual Processing" }
  ],
  dueDate: [
    { value: "day90", label: "90 days prior to check-in" },
    { value: "day60", label: "60 days prior to check-in" },
    { value: "day30", label: "30 days prior to check-in" },
    { value: "day15", label: "15 days prior to check-in" },
    { value: "day7", label: "7 days prior to check-in" },
    { value: "check_in_date", label: "Check-in date" },
    { value: "manual", label: "Manual" }
  ]
};
export default options;
