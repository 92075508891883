import React from 'react';

import TermsConditions from '../../../components/terms-conditions';

export default class FieldsOrganizationTerms extends React.Component {
  constructor(props, _railsContext) {
    super(props);

    this.state = {
      checked: false,
      disabled: 'disabled'
    };
  }

  disableTermsCheckbox = () => {
    this.setState(
      {
        checked: false,
        disabled: 'disabled'
      },
      () => {
        this.props.setTermsAccepted(this.state.checked);
      }
    );
  };

  enableTermsCheckbox = () => {
    this.setState(
      {
        checked: false,
        disabled: ''
      },
      () => {
        this.props.setTermsAccepted(this.state.checked);
      }
    );
  };

  updateTermsCheckbox = e => {
    this.setState({ checked: !this.state.checked }, () => {
      this.props.setTermsAccepted(this.state.checked);
    });
  };

  render() {
    return (
      <fieldset>
        <header className="fieldset-header">
          <legend>Manager License Agreement and Terms of Service</legend>
        </header>
        <div>
          <TermsConditions
            handleEnter={this.enableTermsCheckbox}
            handleLeave={this.disableTermsCheckbox}
          />
        </div>
        <figure className="checkbox">
          {this.state.disabled ? (
            <p>
              <b>
                Please scroll to the bottom of the Manager License Agreement and
                Terms of Service to indicate that you have read and understood
                the document.
              </b>
            </p>
          ) : null}
          <p>
            <input
              type="checkbox"
              name="terms-agreed"
              id="terms-agreed"
              disabled={this.state.disabled}
              onChange={e => this.updateTermsCheckbox(e)}
            />
            <label htmlFor="terms-agreed">
              <span>
                I have read and agree to the Manager License Agreement and{' '}
                <a
                  href="https://directsoftware.com/legal/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>{' '}
                and{' '}
                <a
                  href="https://www.directsoftware.com/legal/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{' '}
                for using DIRECT®
              </span>
            </label>
          </p>
        </figure>
      </fieldset>
    );
  }
}
