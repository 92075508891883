import React from 'react';

// PROPS THAT CAN BE PASSED TO THIS COMPONENT
// user = current user
// permission = people who are allowed to see the component within <PermissionComponent>
// NOTE: permission prop is mandatory, if nothing is included nobody will see it. If you want nobody to see it, pass 'permission={[]}' (an empty array)

// restriction = people who are specifically not allowed to see the component within <PermissionComponent> (this one is not required to be passed unless there is a specific user class you want excluded)

const PermissionComponent = props => {
  if (!props.user) return null;
  if (props.restriction && props.restriction instanceof Array) {
    const anyRestrictionTrue = props.restriction.some(r => props.user.permissions[r]);
    if (anyRestrictionTrue)
      return null;
  } else if (props.restriction && props.user.permissions[props.restriction]) {
    return null;
  }

  const anyPermissionsTrue = props.permission instanceof Array ?
    props.permission.some(p => props.user.permissions[p]) :
    props.user.permissions[props.permission];
  if (props.children.length > 1) {
    return <>{anyPermissionsTrue && props.children}</>;
  } else {
    return anyPermissionsTrue && props.children
      ? props.children
      : null;
  }

  return null;
};

export default PermissionComponent;
