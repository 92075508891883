import React from 'react';
import { CalendarDay } from 'react-dates';

import DefaultRangePicker from 'sharedComponents/DefaultRangePicker';
import { WidgetCalendarDay } from './WidgetDatePickerStyles';


const CustomCalendarDay = (props, calendarDayStatus) => {
  if (!props || !props.day) return <CalendarDay {...props} />;

  const modifiers = props.modifiers;

  let status = calendarDayStatus(props.day);
  if (modifiers && modifiers.has('selected-start') && status === 'check_out') {
    status = 'check_in_with_previous_check_out';
  } else if (
    modifiers &&
    modifiers.has('selected-end') &&
    status === 'check_in'
  ) {
    status = 'check_out_with_previous_check_in';
  }

  if (status === 'unavailable') status = 'blocked';
  return (
    <WidgetCalendarDay status={status}>
      <CalendarDay {...props} />
    </WidgetCalendarDay>
  );
};

const WidgetDatePickerContainer = props => {
  const { calendarDayStatus } = props;
  return (
    <DefaultRangePicker
      label={props.label}
      startPlaceholder="Check in"
      endPlaceholder="Check out"
      onDatesSet={props.onDatesChange}
      initialStartDate={props.startDate}
      initialEndDate={props.endDate}
      isOutsideRange={props.isOutsideRange}
      renderCalendarDay={props => CustomCalendarDay(props, calendarDayStatus)}
      disabled={props.disabled}
      displayFormat={props.displayFormat}
      readOnly={props.readOnly}
      removeOldStyling={props.removeOldStyling}
      openDirection={props.openDirection}
      numberOfMonths={props.numberOfMonths}
      orientation={props.orientation}
      withFullScreenPortal={props.withFullScreenPortal}
      onPickerFocused={props.onPickerFocused}
      navPrev={props.navPrev}
      navNext={props.navNext}
    />
  );
};

export default WidgetDatePickerContainer;
