import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import find from 'lodash/find';

import OpportunityDisplayValue from 'adminComponents/OpportunityDisplayValue';
import CurrencyDefinitions from 'sharedUtils/currency-definitions';
import OpportunityStatusLabel from './OpportunityStatusLabel';

const displayValue = (value, currency) => {
  if (!value || !currency) return '0.00';

  const currencyDefinition = find(
    CurrencyDefinitions,
    definition => definition.value === currency
  );
  return (
    currencyDefinition.symbol +
    (value / 100.0).toFixed(currencyDefinition.decimal_digits)
  );
};

const dates = (check_in, check_out) => {
  if (!check_in || !check_out) return null;

  return (
    <div style={{ fontSize: '12px', color: '#606B6F', marginBottom: '4px' }}>
      {moment(check_in).format('MMMM DD')}-
      {moment(check_out).format('DD, YYYY')}
    </div>
  );
};

const propertyLink = unit => {
  if (!unit) return 'No Listing';

  return (
    <Link
      to={`/properties/${unit.property_id}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ fontSize: '20px' }}
    >
      {unit.display_name}
    </Link>
  );
};

const SingleDetailsHeader = props => {
  const opportunity = props.opportunity;
  const { check_in, check_out, unit, value, currency } = opportunity;

  return (
    <div>
      <div
        style={{ marginBottom: '8px', fontSize: '12px', cursor: 'pointer' }}
        onClick={props.loadOpportunities}
      >
        <img
          style={{ height: '10px', width: 'auto', paddingRight: '8px' }}
          alt="arrow-left"
          src="/assets/brand/arrow-left.svg"
        />
        ALL OPPORTUNITIES
      </div>
      <div
        key={opportunity.id}
        style={{
          width: '100%',
          backgroundColor: 'white',
          boxShadow: '2px 2px 5px -2px rgba(194,194,194,1)',
          padding: '25px',
          marginBottom: '15px'
        }}
      >
        <OpportunityStatusLabel opportunity={opportunity} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0 20px'
              }}
            >
              {dates(check_in, check_out)}
              <div style={{ fontWeight: '500' }}>{propertyLink(unit)}</div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div className="opportunity-value">
              {displayValue(value, currency)}
            </div>
            <OpportunityDisplayValue currency={currency} value={value} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleDetailsHeader;
