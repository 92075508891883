const taskTypeOptions = [
  {
    name: "task_type",
    label: "Back to back clean",
    value: "back_to_back_clean"
  },
  {
    name: "task_type",
    label: "Back to back inspection",
    value: "back_to_back_inspection"
  },
  { name: "task_type", label: "Call back clean", value: "call_back_clean" },
  {
    name: "task_type",
    label: "Construction clean",
    value: "construction_clean"
  },
  {
    name: "task_type",
    label: "Daily maid service",
    value: "daily_maid_service"
  },
  { name: "task_type", label: "Deep clean", value: "deep_clean" },
  {
    name: "task_type",
    label: "Deep clean inspection",
    value: "deep_clean_inspection"
  },
  { name: "task_type", label: "Departure clean", value: "departure_clean" },
  {
    name: "task_type",
    label: "Departure inspection",
    value: "departure_inspection"
  },
  {
    name: "task_type",
    label: "Guest cleaning complaint",
    value: "guest_cleaning_complaint"
  },
  { name: "task_type", label: "Linen exchange", value: "linen_exchange" },
  { name: "task_type", label: "Linen set up only", value: "linen_set_up_only" },
  {
    name: "task_type",
    label: "Mid stay tidy clean ",
    value: "mid_stay_tidy_clean_"
  },
  {
    name: "task_type",
    label: "Mid stay tidy clean (no linen)",
    value: "mid_stay_tidy_clean_(no_linen)"
  },
  { name: "task_type", label: "Owner tidy clean", value: "owner_tidy_clean" },
  {
    name: "task_type",
    label: "Standard inspection",
    value: "standard_inspection"
  },
  { name: "task_type", label: "Tidy clean", value: "tidy_clean" },
  { name: "task_type", label: "Touch up clean", value: "touch_up_clean" },
  {
    name: "task_type",
    label: "Guest Request Clean ",
    value: "guest_request_clean_"
  },
  {
    name: "task_type",
    label: "RGH Gear Rental Issue ",
    value: "rgh_gear_rental_issue_"
  },
  {
    name: "task_type",
    label: "RGH Golf Cart Issue ",
    value: "rgh_golf_cart_issue_"
  },
  {
    name: "task_type",
    label: "RGH House Bike Issue",
    value: "rgh_house_bike_issue"
  },
  { name: "task_type", label: "Guest Charge", value: "guest_charge" },
  { name: "task_type", label: "Guest Contact", value: "guest_contact" },
  {
    name: "task_type",
    label: "Guest Late Departure",
    value: "guest_late_departure"
  },
  {
    name: "task_type",
    label: "Guest Move - PM request ",
    value: "guest_move_-_pm_request_"
  },
  {
    name: "task_type",
    label: "Guest Move/Cancel - Guest requested",
    value: "guest_move/cancel_-_guest_requested"
  },
  { name: "task_type", label: "VIP Guest", value: "vip_guest" },
  {
    name: "task_type",
    label: "Amenity cards (Missing or not working)",
    value: "amenity_cards_(missing_or_not_working)"
  },
  {
    name: "task_type",
    label: "Appliance - Dishwasher",
    value: "appliance_-_dishwasher"
  },
  { name: "task_type", label: "Appliance - Dryer", value: "appliance_-_dryer" },
  {
    name: "task_type",
    label: "Appliance - Garbage Disposal",
    value: "appliance_-_garbage_disposal"
  },
  {
    name: "task_type",
    label: "Appliance - Ice Maker",
    value: "appliance_-_ice_maker"
  },
  {
    name: "task_type",
    label: "Appliance - Microwave",
    value: "appliance_-_microwave"
  },
  {
    name: "task_type",
    label: "Appliance - Refrigerator/ Freezer",
    value: "appliance_-_refrigerator/_freezer"
  },
  {
    name: "task_type",
    label: "Appliance - Stove/ Oven",
    value: "appliance_-_stove/_oven"
  },
  {
    name: "task_type",
    label: "Appliance - Washing Machine",
    value: "appliance_-_washing_machine"
  },
  { name: "task_type", label: "AV/TV", value: "av/tv" },
  {
    name: "task_type",
    label: "Bedding Issue - Missing",
    value: "bedding_issue_-_missing"
  },
  {
    name: "task_type",
    label: "Bedding Issue - Ripped/Damaged",
    value: "bedding_issue_-_ripped/damaged"
  },
  {
    name: "task_type",
    label: "Bedding Issue - Stained",
    value: "bedding_issue_-_stained"
  },
  {
    name: "task_type",
    label: "Bedding Issue - Wrong Size",
    value: "bedding_issue_-_wrong_size"
  },
  {
    name: "task_type",
    label: "Behavior Complaint",
    value: "behavior_complaint"
  },
  { name: "task_type", label: "Deep Clean", value: "deep_clean" },
  {
    name: "task_type",
    label: "Door lock issue (not Kaba)",
    value: "door_lock_issue_(not_kaba)"
  },
  {
    name: "task_type",
    label: "Doors/ Windows (not door locks)",
    value: "doors/_windows_(not_door_locks)"
  },
  { name: "task_type", label: "Electrical", value: "electrical" },
  { name: "task_type", label: "Elevator", value: "elevator" },
  { name: "task_type", label: "Filters", value: "filters" },
  { name: "task_type", label: "Fireplace", value: "fireplace" },
  {
    name: "task_type",
    label: "Furniture/Hardware",
    value: "furniture/hardware"
  },
  { name: "task_type", label: "Gas Leak", value: "gas_leak" },
  { name: "task_type", label: "Golf Cart Check", value: "golf_cart_check" },
  { name: "task_type", label: "Grills/Propane", value: "grills/propane" },
  { name: "task_type", label: "Guest Arrival", value: "guest_arrival" },
  { name: "task_type", label: "Guest Request", value: "guest_request" },
  {
    name: "task_type",
    label: "HK Issue - Amenities Missing",
    value: "hk_issue_-_amenities_missing"
  },
  {
    name: "task_type",
    label: "HK Issue - Bedding Missing",
    value: "hk_issue_-_bedding_missing"
  },
  {
    name: "task_type",
    label: "HK Issue - Offboarding Pick Up",
    value: "hk_issue_-_offboarding_pick_up"
  },
  {
    name: "task_type",
    label: "HK Issue - Towels Missing",
    value: "hk_issue_-_towels_missing"
  },
  { name: "task_type", label: "House Golf Cart", value: "house_golf_cart" },
  { name: "task_type", label: "Housewares issue", value: "housewares_issue" },
  {
    name: "task_type",
    label: "Internal Company Request",
    value: "internal_company_request"
  },
  {
    name: "task_type",
    label: "Internet - Not Functioning",
    value: "internet_-_not_functioning"
  },
  { name: "task_type", label: "Landscaping", value: "landscaping" },
  { name: "task_type", label: "Lightbulbs", value: "lightbulbs" },
  { name: "task_type", label: "Locks", value: "locks" },
  { name: "task_type", label: "Mold", value: "mold" },
  {
    name: "task_type",
    label: "New Property Walkthrough",
    value: "new_property_walkthrough"
  },
  {
    name: "task_type",
    label: "Owner Annual Inspection",
    value: "owner_annual_inspection"
  },
  { name: "task_type", label: "Owner Arrival", value: "owner_arrival" },
  { name: "task_type", label: "Owner Punch List", value: "owner_punch_list" },
  { name: "task_type", label: "Owner Request", value: "owner_request" },
  { name: "task_type", label: "Package Delivery", value: "package_delivery" },
  { name: "task_type", label: "Parking Passes", value: "parking_passes" },
  { name: "task_type", label: "Pest Control", value: "pest_control" },
  { name: "task_type", label: "Plumbing - Clog", value: "plumbing_-_clog" },
  {
    name: "task_type",
    label: "Plumbing - Hot Water Issue",
    value: "plumbing_-_hot_water_issue"
  },
  { name: "task_type", label: "Plumbing - Leak", value: "plumbing_-_leak" },
  { name: "task_type", label: "PM Request", value: "pm_request" },
  { name: "task_type", label: "Pool - Alarms", value: "pool_-_alarms" },
  { name: "task_type", label: "Pool - Cleaning", value: "pool_-_cleaning" },
  { name: "task_type", label: "Pool - Heating", value: "pool_-_heating" },
  { name: "task_type", label: "Pool - Other", value: "pool_-_other" },
  { name: "task_type", label: "QA Findings", value: "qa_findings" },
  { name: "task_type", label: "Roof Leak", value: "roof_leak" },
  {
    name: "task_type",
    label: "Smoke Detectors/House Alarms",
    value: "smoke_detectors/house_alarms"
  },
  { name: "task_type", label: "Trash Removal", value: "trash_removal" },
  { name: "task_type", label: "VIP Guest", value: "vip_guest" }
];
export default taskTypeOptions;
