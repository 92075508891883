import React from 'react';

const backgroundImageUrl = imageUrl => {
  const url = imageUrl || '/assets/fallback/property_default.png';

  return `url(${url})`;
};

const styles = {
  borderRadius: '50%',
  backgroundSize: 'cover',
  height: '50px',
  width: '50px',
  backgroundPosition: 'center'
};

const UnitAvatar = ({ imageUrl }) => {
  return (
    <div
      style={{
        ...styles,
        ...{ backgroundImage: backgroundImageUrl(imageUrl) }
      }}
    />
  );
};

export default UnitAvatar;
