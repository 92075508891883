const DASHBOARD_NAV_TAGS = Object.freeze({
  PRIMARY: "Dashboard"
});
const LISTINGS_NAV_TAGS = Object.freeze({
  PRIMARY: "listings",
  secondary: Object.freeze({
    PROPERTIES: "Properties",
    VEHICLES: "Vehicles",
    PORTFOLIOS: "Portfolios",
    SUB_PORTFOLIOS: "Sub Portfolios",
    UNIT_GROUPS: "Unit Groups",
    RATE_GROUPS: "Rate Groups",
    PROMOTIONS: "Promotions",
    ROOM_TYPES: "Room Types",
    AUDIT_PROPERTIES: "Audit Properties",
    PROPERTIES_BULK_EDIT: "Properties Bulk Edit",
    VEHICLES_BULK_EDIT: "Vehicles Bulk Edit",
    FEE_ACCOUNTS: "Fee Accounts"
  }),
  tertiary: Object.freeze({
    OVERVIEW: "Overview",
    IMAGES: "Images",
    POLICIES_AND_RULES: "Policies & Rules",
    BEDS_AND_BATHS: "Beds & Baths",
    AMENITIES: "Amenities",
    AVAILABILITY: "Availability",
    PRICING: "Pricing",
    CALENDAR: "Calendar",
    PEOPLE_AND_ADMIN: "People & Admin",
    RESERVATIONS: "Reservations",
    CHANGE_LOG: "Change Log",
    BEDROOMS: "Bedrooms",
    BATHROOMS: "Bathrooms",
    MANAGE_PROPERTY_UNITS: "Manage Property Units"
  })
});
const RESERVATIONS_NAV_TAGS = Object.freeze({
  PRIMARY: "Reservations",
  secondary: Object.freeze({
    LIST_VIEW: "Rez List",
    CALENDAR_VIEW: "Rez Calendar",
    VEHICLES_CALENDAR_VIEW: "Rez Vehicles Calendar",
    REVIEWS: "Reviews"
  }),
  tertiary: Object.freeze({
    OVERVIEW: "Reservations Overview",
    FOLIO: "Folio",
    TRANSACTIONS: "Transactions",
    CHANGE_LOG: "Reservations Change Log",
    EMAILS: "Reservations Emails"
  })
});
const INBOX_NAV_TAGS = Object.freeze({
  PRIMARY: "Unified Inbox"
});
const UNIFIED_INBOX_NAV_TAGS = Object.freeze({
  PRIMARY: "Unified Inbox"
});
const ACCOUNTING_NAV_TAGS = Object.freeze({
  PRIMARY: "Accounting",
  secondary: Object.freeze({
    LIABILITIES_AND_STATEMENTS: "Liabilities & Statements",
    PAYOUTS_BALANCES: "Payouts & Balances",
    INVOICES: "Invoices",
    RECONCILE: "Reservation Liability Report",
    CHART_OF_ACCOUNTS: "Chart of Accounts",
    GENERAL_LEDGER: "General Ledger",
    STATEMENTS: "Statements",
    BOOKINGS_SUMMARY: "Bookings Summary",
    EARNINGS_SUMMARY: "Earnings Summary",
    BANK_ACCOUNTS: "Bank Accounts",
    REPORTING: "Reporting",
    PAYMENTS: "Payments"
  })
});
const PAYMENTS_NAV_TAGS = Object.freeze({
  PRIMARY: "Accounting - Payments",
  secondary: Object.freeze({
    EARNINGS_SUMMARY: "All Accounting - Payments Earnings Summary",
    STATEMENTS: "Accounting - Payments Statements",
    INVOICES: "Accounting - Payments Invoices",
    BANK_ACCOUNTS: "Accounting - Payments Bank Accounts"
  })
});
const DISTRIBUTION_NAV_TAGS = Object.freeze({
  PRIMARY: "Distribution",
  secondary: Object.freeze({
    ALL_CHANNELS: "All Channels",
    AIRBNB: "Airbnb",
    BOOKING_COM: "Booking.com",
    HOMEAWAY: "HomeAway",
    EXPEDIA: "Expedia",
    RVSHARE: "RVshare",
    GOOGLE: "Google"
  })
});
const WORKFLOWS_NAV_TAGS = Object.freeze({
  PRIMARY: "Workflows",
  secondary: Object.freeze({
    WORK_ORDERS: "Work Orders",
    SCHEDULING: "Scheduling",
    WORK_REPORTS: "Work Reports",
    TEMPLATES: "Templates"
  }),
  tertiary: Object.freeze({
    OVERVIEW: "Workflows Overview",
    NOTES: "Workflows Notes",
    DOCUMENTS: "Workflows Documents"
  })
});
const PEOPLE_NAV_TAGS = Object.freeze({
  PRIMARY: "People",
  secondary: Object.freeze({
    EMPLOYEES: "Employees",
    USER_GROUPS: "User Groups",
    LEADS_LIST: "Leads List",
    CUSTOMERS_LIST: "Customers List",
    CRM: "CRM"
  }),
  tertiary: Object.freeze({
    OVERVIEW: "Employee Overview",
    UNITS: "Employee Units",
    DOCUMENTS: "Employee Documents",
    NOTES: "Employee Notes",
    BANK_INFO: "Employee Bank Info",
    PERMISSIONS: "Employee Permissions",
    GROUP_BANK_INFO: "User Group Bank Info"
  })
});
const WEBSITE_NAV_TAGS = Object.freeze({
  PRIMARY: "Website Editor",
  secondary: Object.freeze({
    SITE_EDITOR: "Site Editor"
  }),
  tertiary: Object.freeze({
    OVERVIEW: "Site Overview",
    CMS: "Site CMS",
    PAGES: "Site Pages",
    CONTRACTS: "Contracts",
    RENTAL_AGREEMENT: "Site Rental Agreement",
    ID_VERIFICATION: "Site ID Verify",
    EXTERNAL_CONTRACTS: "Site External Contracts",
    ANALYTICS: "Site Analytics",
    ADD_LISTINGS: "Add Listings",
    CONTACT_INFO: "Site Contact Info",
    NAVIGATION: "Site Navigation",
    URL_REDIRECTS: "Site Url Redirects"
  })
});
const MESSAGING_NAV_TAGS = Object.freeze({
  PRIMARY: "Messaging",
  secondary: Object.freeze({
    CUSTOM_EMAILS: "Custom Emails",
    SYSTEM_EMAILS: "System Emails",
    VRBO_AUTO_MESSAGES: "VRBO Auto Messages",
    AIRBNB_AUTO_MESSAGES: "Airbnb Auto Messages"
  })
});
const MY_ACCOUNT_NAV_TAGS = Object.freeze({
  PRIMARY: "My Account",
  secondary: Object.freeze({
    MY_ACCOUNT: "My Account",
    MY_ORGANIZATIONS: "My Organizations",
    MY_SETTINGS: "My Settings",
    MY_BILLING: "My Billing",
    MY_INTEGRATIONS: "My Integrations",
    MY_SETTINGS_NON_ADMIN: "My Settings Non Admin"
  })
});
const GLOBAL_SEARCH_NAV_TAGS = Object.freeze({
  PRIMARY: "Global Search"
});
const MORE_NAV_TAGS = Object.freeze({
  PRIMARY: "More",
  PRIMARY_EXTENDED: [
    DISTRIBUTION_NAV_TAGS.PRIMARY,
    WORKFLOWS_NAV_TAGS.PRIMARY,
    PEOPLE_NAV_TAGS.PRIMARY,
    WEBSITE_NAV_TAGS.PRIMARY,
    MESSAGING_NAV_TAGS.PRIMARY
  ]
});
const RVSHARE_CONTRACTS_NAV_TAGS = Object.freeze({
  PRIMARY: "Contracts"
});
const RVSHARE_EMAILS_NAV_TAGS = Object.freeze({
  PRIMARY: "Emails",
  secondary: Object.freeze({
    CUSTOM_EMAILS: "Custom Emails",
    SYSTEM_EMAILS: "System Emails",
    VRBO_AUTO_MESSAGES: "VRBO Auto Messages",
    AIRBNB_AUTO_MESSAGES: "Airbnb Auto Messages"
  })
});
const MORE_NAV_TAGS_RV_FLEET = Object.freeze({
  PRIMARY: "More",
  PRIMARY_EXTENDED: [
    DISTRIBUTION_NAV_TAGS.PRIMARY,
    PEOPLE_NAV_TAGS.PRIMARY,
    RVSHARE_CONTRACTS_NAV_TAGS.PRIMARY,
    MESSAGING_NAV_TAGS.PRIMARY,
    WEBSITE_NAV_TAGS.PRIMARY,
    RVSHARE_EMAILS_NAV_TAGS.PRIMARY
  ]
});
export {
  DASHBOARD_NAV_TAGS,
  LISTINGS_NAV_TAGS,
  RESERVATIONS_NAV_TAGS,
  INBOX_NAV_TAGS,
  UNIFIED_INBOX_NAV_TAGS,
  ACCOUNTING_NAV_TAGS,
  DISTRIBUTION_NAV_TAGS,
  WORKFLOWS_NAV_TAGS,
  PEOPLE_NAV_TAGS,
  WEBSITE_NAV_TAGS,
  MESSAGING_NAV_TAGS,
  MORE_NAV_TAGS,
  MY_ACCOUNT_NAV_TAGS,
  PAYMENTS_NAV_TAGS,
  GLOBAL_SEARCH_NAV_TAGS,
  MORE_NAV_TAGS_RV_FLEET,
  RVSHARE_CONTRACTS_NAV_TAGS,
  RVSHARE_EMAILS_NAV_TAGS
};
