import React from 'react';

import PortalModal from 'sharedComponents/PortalModal';
import QuoteEditingForm from 'adminComponents/QuoteEditingForm';

import { QuoteService } from 'adminApi';
import displayError from 'sharedComponents/ErrorDisplay';
import {
  Box,
  FlexBox,
  Spacer,
  TextDense
} from '@directsoftware/ui-kit-web-admin';
import { bookingStatusPill } from 'src/shared/utils/reservation-helpers';

export default class QuoteEditModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newChargeAmount: null,
      applyCharge: this.props.applyCharge,
      newQuoteId: null,
      newQuote: null,
      editedBookingNetDetail: null
    };
  }

  cancelAction = closePortal => {
    if (!this.state.newQuoteId) return null;

    return (
      <a
        onClick={() => this.setState({ newQuoteId: null }, () => closePortal())}
        className="button negative"
      >
        Cancel
      </a>
    );
  };

  submitAction = closePortal => {
    if (!this.state.newQuoteId) return null;

    return (
      <a
        onClick={() => this.onApply(closePortal)}
        className="button"
        style={{ marginLeft: '8px' }}
      >
        Apply
      </a>
    );
  };

  onApply = closePortal => {
    if (!this.state.newQuoteId) return null;
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.newQuote);
      closePortal();
      return;
    }

    const bookingCode = this.props.quote.booking_code;
    const subdomain = this.props.organization.subdomain;
    const newChargeAmount = this.state.newChargeAmount;

    let redirectUrl = `/dashboard/${subdomain}/reservations/${bookingCode}`;

    if (this.state.applyCharge && newChargeAmount) {
      redirectUrl += `?applyCharge=${newChargeAmount}`;
    }
    QuoteService.updateBookingNetDetail(
      this.props.organization.id,
      this.state.newQuoteId
    )
      .then(response => {
        this.setState({ editedBookingNetDetail: response.booking_net_detail });
      })
      .catch(err => displayError({ message: 'Error updating booking', err }));

    QuoteService.updateBooking(
      this.props.organization.id,
      this.state.newQuoteId,
      false
    )
      .then(() => {
        window.location = redirectUrl;
      })
      .catch(err => displayError({ message: 'Error updating booking', err }));
  };

  afterEdit = (newQuote, newChargeAmount) => {
    this.setState({ newQuoteId: newQuote.id, newQuote, newChargeAmount });
  };

  afterDelete = newQuote => {
    this.setState({ newQuoteId: newQuote.id, newQuote, newChargeAmount: 0 });
  };

  updateApplyCharge = applyCharge => {
    this.setState({ applyCharge });
  };

  afterLineItemCreated = quote => {
    this.setState({ newQuoteId: quote.id, newQuote: quote });
  };

  // Redundent to above, but don't want to go down loop to actually
  // update all other places that is used. Possbile refactor later
  afterNumGuestsCreated = quote => {
    this.setState({ newQuoteId: quote.id, newQuote: quote });
  };

  render() {
    return (
      <PortalModal
        openByClickOn={openPortal => this.props.openByClickOn(openPortal)}
        header={this.props.header}
        modalStyles={{ textAlign: 'unset', width: '640px' }}
        submitAction={this.submitAction}
        cancelAction={this.cancelAction}
        closeOnSubmit
        actionStyles={{ display: 'flex', flexDirection: 'row-reverse' }}
        disableCloseOnOutsideClick
      >
        {this.props.quoteBookingStatusInfo && (
          <>
            <FlexBox gap="xs" alignItems="center">
              <Box>
                <TextDense textColor="dark-gray" weight="semibold">
                  Booking Status:
                </TextDense>
              </Box>
              {bookingStatusPill(this.props.quoteBookingStatusInfo)}
            </FlexBox>
            <Spacer />
          </>
        )}
        <QuoteEditingForm
          quote={this.props.quote}
          organizationID={this.props.organization.id}
          afterEdit={this.afterEdit}
          afterLineItemCreated={this.afterLineItemCreated}
          afterNumGuestsCreated={this.afterNumGuestsCreated}
          updateApplyCharge={this.updateApplyCharge}
          hideChargeFields={this.props.hideChargeFields}
          applyCharge={this.state.applyCharge}
          initialLineItem={this.props.initialLineItem}
          updatingNumGuests={this.props.updatingNumGuests}
          afterDelete={this.afterDelete}
          ownerOrFriendBooking={this.props.ownerOrFriendBooking}
          nonGuestBooking={this.props.nonGuestBooking}
          disableDateEditing={
            this.props.quote.channel_id === 1 && this.props.channelUpdateable
          } // BDC bookings can be edited, but date range cannot change
          bookingCode={this.props.quote.booking_code}
          vehicleBooking={this.props.vehicleBooking}
          vehicleId={this.props.vehicle?.id}
          unitID={this.props.quote?.unit_id}
          quoteBookingStatusInfo={this.props.quote_booking_status_info}
        />
      </PortalModal>
    );
  }
}
