import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const VehicleListReportLink = ({
  closeMobileNav,
  closeMenuPanel,
  variation,
}) => {
  return (
    <Navbar.Link
      href="/reporting/vehicle_list"
      variation={variation}
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
    >
      Details Report
    </Navbar.Link>
  );
};

VehicleListReportLink.propTypes = {
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  variation: PropTypes.string
};

VehicleListReportLink.defaultProps = {
  variation: 'secondary'
};

export default VehicleListReportLink;
