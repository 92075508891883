import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@directsoftware/ui-kit-web-admin';

const UnitGroupsLink = ({
  activeRoutes,
  closeMobileNav,
  closeMenuPanel,
  navTags,
  ...props
}) => {
  return (
    <Navbar.Link
      href="/unit-groups"
      variation="secondary"
      onClick={() => {
        closeMenuPanel();
        if (closeMobileNav)
          // desktop links wont have this
          closeMobileNav();
      }}
      isActive={activeRoutes.secondary === navTags.secondary.UNIT_GROUPS}
    >
      Unit Groups
    </Navbar.Link>
  );
};

UnitGroupsLink.propTypes = {
  activeRoutes: PropTypes.object,
  closeMobileNav: PropTypes.func,
  closeMenuPanel: PropTypes.func,
  navTags: PropTypes.object
};

export default UnitGroupsLink;
