import request from "apiClient";
export default class SubscriptionService {
  static update(organizationID, subscriptionID, data) {
    return request({
      url: `/api/${organizationID}/subscriptions/${subscriptionID}`,
      method: "PATCH",
      data
    });
  }
  static create(organizationID, data) {
    return request({
      url: `/api/${organizationID}/subscriptions`,
      method: "POST",
      data
    });
  }
}
