import React from 'react';
import snakeCase from 'lodash/snakeCase';
import { ConsolidatedItemEditToggleHeader } from 'adminComponents';
import EditingForm from './editing-form';
import ViewingForm from './viewing-form';

export default class EditableViewingForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      isSaving: false,
      isSaved: false,
      isDirty: false
    };
  }

  componentDidMount() {
    const items = {};

    this.props.items.forEach(item => {
      if (toString.call(item) === '[object Array]') {
        item.forEach(subItem => {
          items[subItem.key] = subItem.value;
          items[`${subItem.key}Cache`] = subItem.value;
        });
      } else {
        items[item.key] = item.value;
        items[`${item.key}Cache`] = item.value;
      }
    });

    this.setState(items);
  }

  attemptSave = () => {
    const data = {};
    this.props.items.forEach(item => {
      // Convert CamelCaseKey to snake_case_key
      if (toString.call(item) === '[object Array]') {
        item.forEach(subItem => {
          data[snakeCase(subItem.key)] = this.state[subItem.key];
        });
      } else {
        data[snakeCase(item.key)] = this.state[item.key];
      }
    });

    this.setState(
      {
        isSaving: true,
        isSaved: false
      },
      () =>
        this.props.attemptSave(data, success => {
          this.setState(
            {
              isEditing: !success,
              isSaving: false,
              isSaved: success,
              isDirty: !success
            },
            () => this.finishedEditing(success)
          );
        })
    );
  };

  finishedEditing = success => {
    if (success) {
      this.props.finishEditingAndUpdate && this.props.finishEditingAndUpdate();
    } else {
      this.props.finishEditingAndRevert && this.props.finishEditingAndRevert();
    }
  };

  finishEditingAndRevert = () => {
    const stateChange = {
      isEditing: false,
      isSaving: false,
      isSaved: false,
      isDirty: false
    };

    this.props.items.forEach(item => {
      if (toString.call(item) === '[object Array]') {
        item.forEach(subItem => {
          stateChange[subItem.key] = this.state[`${subItem.key}Cache`];
        });
      } else {
        stateChange[item.key] = this.state[`${item.key}Cache`];
      }
    });

    this.setState(stateChange, () => this.finishedEditing(false));
  };

  finishEditingAndUpdate = () => {
    const stateChange = {
      isEditing: false,
      isSaving: false,
      isSaved: true,
      isDirty: false
    };

    this.props.items.forEach(item => {
      if (toString.call(item) === '[object Array]') {
        item.forEach(subItem => {
          stateChange[`${subItem.key}Cache`] = this.state[subItem.key];
        });
      } else {
        stateChange[`${item.key}Cache`] = this.state[item.key];
      }
    });

    this.setState(stateChange, () => this.finishedEditing(true));
  };

  toggleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  onChange = (key, value) => {
    this.setState(
      {
        [key]: value,
        isDirty: true
      },
      () => {
        if (this.state.minimumAgeToRent < 25) {
          this.setState({ isDirty: false });
        }
        this.props.onChange && this.props.onChange(key, value);
      }
    );
  };

  renderViewing = () => (
    <ViewingForm items={this.props.items} formState={this.state} />
  );

  renderEditing = () => (
    <EditingForm
      items={this.props.items}
      formState={this.state}
      onChange={this.onChange}
      organization={this.props.organization}
      attemptSave={this.attemptSave}
    />
  );

  render() {
    if (this.props.viewOnly) {
      return this.renderViewing();
    }

    return (
      <figure
        className={`consolidated-item-form-section ${
          this.state.isEditing ? 'form-editing' : ''
        }`}
        style={{ width: '100%', overflow: 'auto', ...this.props.style }}
      >
        <ConsolidatedItemEditToggleHeader
          finishEditingAndRevert={this.finishEditingAndRevert}
          finishEditingAndUpdate={this.finishEditingAndUpdate}
          attemptSave={this.attemptSave}
          toggleEditing={this.toggleEditing}
          isEditing={this.state.isEditing}
          isSaving={this.state.isSaving}
          isSaved={this.state.isSaved}
          isDirty={this.state.isDirty}
          title={this.props.title}
          blockEdit={this.props.blockEdit}
        />
        {this.state.isEditing ? this.renderEditing() : this.renderViewing()}
      </figure>
    );
  }
}

EditableViewingForm.defaultProps = {
  style: {}
};
