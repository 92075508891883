import request from "apiClient";
export default class SingleUnitPropertyService {
  static update(organizationId, propertyID, unitID, params = {}) {
    return request({
      url: `/api/${organizationId}/single/${propertyID}/${unitID}`,
      method: "PATCH",
      data: params
    });
  }
}
