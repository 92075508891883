import request from "apiClient";
export default class EventService {
  static create(organizationId, params = {}) {
    return request({
      url: `/api/${organizationId}/events`,
      method: "POST",
      data: params
    });
  }
}
