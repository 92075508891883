import React from 'react';
import Loading from 'react-loading-components';

import PortalModal from 'sharedComponents/PortalModal';
import QuoteEditingForm from 'adminComponents/QuoteEditingForm';
import BookingWidget from 'adminComponents/BookingWidget';

export default class QuoteCreateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quote: null,
      newQuote: null,
      edited: false,
      loading: false
    };
  }

  cancelAction = closePortal => {
    if (!this.state.quote) return null;

    return (
      <a
        onClick={() => this.setState({ quote: null }, () => closePortal())}
        className={'button negative'}
      >
        Cancel
      </a>
    );
  };

  submitAction = closePortal => {
    if (!this.state.quote) return null;

    return (
      <a
        onClick={() => this.onSubmit(closePortal)}
        className={'button'}
        style={{ marginLeft: '8px' }}
      >
        Save Quote
      </a>
    );
  };

  onSubmit = closePortal => {
    if (!this.state.quote) return null;

    this.props.onSubmit && this.props.onSubmit(this.state.quote);
    closePortal();
  };

  afterEdit = (newQuote, _newChargeAmount) => {
    this.setState({ newQuote, edited: true });
  };

  afterDelete = newQuote => {
    this.refreshQuote(newQuote);
  };

  afterQuoteCreated = quote => {
    this.refreshQuote(quote);
  };

  onDatesChange = ({ startDate, endDate }) => {
    this.props.onDatesChange &&
      this.props.onDatesChange({ startDate, endDate });
  };

  refreshQuote = newQuote => {
    this.setState({ loading: true }, () => {
      this.setState({
        loading: false,
        quote: newQuote,
        newQuote: null,
        edited: false
      });
    });
  };

  afterLineItemCreated = quote => {
    this.setState({ quote, newQuote: null });
  };

  renderForm = () => {
    if (this.state.loading) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '300px',
            width: '100%'
          }}
        >
          <Loading type="tail_spin" width={50} height={50} fill="#00CC99" />
        </div>
      );
    }

    return (
      <div>
        {this.state.quote && (
          <QuoteEditingForm
            quote={this.state.quote}
            unitID={this.props.unitID}
            organizationID={this.props.organization.id}
            afterEdit={this.afterEdit}
            afterLineItemCreated={this.afterLineItemCreated}
            disableNumGuestsEditing
            disableDateEditing
            refreshQuote={this.refreshQuote}
            afterDelete={this.afterDelete}
          />
        )}
        {this.state.edited && (
          <a
            onClick={() => this.refreshQuote(this.state.newQuote)}
            className={'button'}
            style={{ margin: '16px 0' }}
          >
            Apply Change
          </a>
        )}
      </div>
    );
  };

  render() {
    return (
      <PortalModal
        openByClickOn={openPortal => this.props.openByClickOn(openPortal)}
        header={this.props.header}
        modalStyles={{ textAlign: 'unset', width: '640px' }}
        submitAction={this.submitAction}
        cancelAction={this.cancelAction}
        closeOnSubmit
        actionStyles={{ display: 'flex', flexDirection: 'row-reverse' }}
        disableCloseOnOutsideClick
      >
        <BookingWidget
          unitID={this.props.unitID}
          listingId={this.props.listingId}
          organizationID={this.props.organization.id}
          afterQuoteCreated={this.afterQuoteCreated}
          onDatesChange={this.onDatesChange}
          channelID={this.props.channelID}
          checkIn={this.props.checkIn}
          checkOut={this.props.checkOut}
          numGuests={this.props.numGuests}
          hideContainer
        />
        {this.renderForm()}
      </PortalModal>
    );
  }
}
