import React from 'react';
import find from 'lodash/find';
import { InputSelect } from '@directsoftware/ui-kit-web-admin';

const options = [
  { value: '', label: 'All Types' },
  { value: 'instant', label: 'Instant Booking' },
  { value: 'request', label: 'Request Booking' }
];

const GenerationTypeFilter = props => (
  <figure style={{ flex: `1 1 auto`, marginRight: '5px' }}>
    <label htmlFor="generation_type">Generation Type</label>
    <InputSelect
      name="generation_type"
      className="magnified"
      options={options}
      isClearable={false}
      isSearchable={false}
      placeholder="All Types"
      onChange={option => props.updateFilter('generation_type', option.value)}
      value={find(options, { value: props.generation_type })}
    />
  </figure>
);

export default GenerationTypeFilter;
