import React from 'react';
import { IconFontAwesome, InputText } from '@directsoftware/ui-kit-web-admin';

export default class ListFilter extends React.Component {
  constructor(props) {
    super(props);
  }

  onChange = e => {
    this.props.onFilterChange(e.target.value);
  };

  onClear = e => {
    e.preventDefault();
    this.props.onFilterChange('');
  };

  render() {
    return this.props.isControlPanel ? (
      <InputText
        name={this.props.name}
        placeholder={this.props.placeholder}
        onChange={this.onChange}
        value={this.props.filter}
        prependIcon={<IconFontAwesome name="search" />}
        inputWidth="auto"
        removeBottomSpacer
      />
    ) : (
      <div className="properties-search-wrapper">
        <InputText
          name={this.props.name}
          placeholder={this.props.placeholder}
          onChange={this.onChange}
          value={this.props.filter}
          prependIcon={<IconFontAwesome name="search" />}
        />
      </div>
    );
  }
}
