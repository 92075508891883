import request from "apiClient";
export default class NotificationService {
  static index(organizationID, channelType) {
    return request({
      url: `/api/${organizationID}/notifications?channel_type=${channelType}`,
      method: "GET"
    });
  }
  static get(organizationID, notificationID) {
    return request({
      url: `/api/${organizationID}/notifications/${notificationID}`,
      method: "GET"
    });
  }
  static update(organizationID, notificationID, data) {
    return request({
      url: `/api/${organizationID}/notifications/${notificationID}`,
      method: "PUT",
      data
    });
  }
}
