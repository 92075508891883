import React from 'react';

import {
  dispatchError,
  ConsolidatedItemEditToggleHeader,
  LocationForm,
  LocationViewingField
} from 'adminComponents';

import { ajaxDataWithAuthorization } from 'apiClient';

export default class ManageOrganizationFieldsLocation extends React.Component {
  constructor(props, _railsContext) {
    super(props);

    this.state = {
      adr_street: this.props.location.adr_street,
      adr_street_cache: this.props.location.adr_street,
      adr_unit: this.props.location.adr_unit,
      adr_unit_cache: this.props.location.adr_unit,
      adr_city: this.props.location.adr_city,
      adr_city_cache: this.props.location.adr_city,
      adr_state: this.props.location.adr_state,
      adr_state_cache: this.props.location.adr_state,
      adr_country: this.props.location.adr_country,
      adr_country_cache: this.props.location.adr_country,
      adr_postal_code: this.props.location.adr_postal_code,
      adr_postal_code_cache: this.props.location.adr_postal_code,
      isDirty: false,
      isEditing: false,
      isSaving: false,
      isSaved: false,
      mapsLoaded: false,
      mapsLoading: true
    };
  }

  onChange = e => {
    e.preventDefault();
    let stateChange = {};
    stateChange[e.target.name] = e.target.value;
    stateChange['isDirty'] = true;
    this.setState(stateChange);
  };

  handleMapScriptError = () => {
    this.setState({
      mapsLoaded: false,
      mapsLoading: false
    });
  };

  handleMapScriptLoad = () => {
    this.setState({
      mapsLoaded: true,
      mapsLoading: false
    });
  };

  toggleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  finishEditingAndRevert = () => {
    let stateChange = {};
    (stateChange['adr_street'] = this.state.adr_street_cache),
      (stateChange['adr_unit'] = this.state.adr_unit_cache),
      (stateChange['adr_city'] = this.state.adr_city_cache),
      (stateChange['adr_state'] = this.state.adr_state_cache),
      (stateChange['adr_country'] = this.state.adr_country_cache),
      (stateChange['adr_postal_code'] = this.state.adr_postal_code_cache),
      (stateChange['isDirty'] = false);
    stateChange['isEditing'] = false;
    stateChange['isSaving'] = false;
    stateChange['isSaved'] = false;
    this.setState(stateChange);
  };

  finishEditingAndUpdate = () => {
    let stateChange = {};
    (stateChange['adr_street_cache'] = this.state.adr_street),
      (stateChange['adr_unit_cache'] = this.state.adr_unit),
      (stateChange['adr_city_cache'] = this.state.adr_city),
      (stateChange['adr_state_cache'] = this.state.adr_state),
      (stateChange['adr_country_cache'] = this.state.adr_country),
      (stateChange['adr_postal_code_cache'] = this.state.adr_postal_code),
      (stateChange['isDirty'] = false);
    stateChange['isEditing'] = false;
    stateChange['isSaving'] = false;
    stateChange['isSaved'] = true;
    this.setState(stateChange);
  };

  attemptSave = () => {
    this.setState(
      {
        isSaving: true,
        isSaved: false
      },
      () => {
        $.ajax(
          ajaxDataWithAuthorization({
            type: 'PATCH',
            url: `/api/${this.props.organization.id}/locations/${
              this.props.location.id
            }`,
            context: this,
            dataType: 'json',
            data: {
              adr_street: this.state.adr_street,
              adr_unit: this.state.adr_unit,
              adr_city: this.state.adr_city,
              adr_state: this.state.adr_state,
              adr_country: this.state.adr_country,
              adr_postal_code: this.state.adr_postal_code
            }
          })
        )
          .done(function(data) {
            this.finishEditingAndUpdate();
          })
          .fail(function(jqXhr) {
            dispatchError();
            console.warn(jqXhr);
            this.finishEditingAndRevert();
          });
      }
    );
  };

  setLocationAttributes = values => {
    const {
      adrStreet,
      adrUnit,
      adrCity,
      adrState,
      adrCountry,
      adrPostalCode
    } = values;
    this.setState({
      adr_street: adrStreet,
      adr_unit: adrUnit,
      adr_city: adrCity,
      adr_state: adrState,
      adr_country: adrCountry,
      adr_postal_code: adrPostalCode,
      isDirty: true
    });
  };

  renderEditing = () => (
    <section className="consolidated-item-form-editing">
      <LocationForm
        adrStreet={this.state.adr_street}
        adrUnit={this.state.adr_unit}
        adrCity={this.state.adr_city}
        adrState={this.state.adr_state}
        adrCountry={this.state.adr_country}
        adrPostalCode={this.state.adr_postal_code}
        setLocationAttributes={this.setLocationAttributes}
      />
    </section>
  );

  renderViewing = () => (
    <section className="consolidated-item-form-viewing">
      <LocationViewingField
        adr_street={this.state.adr_street}
        adr_unit={this.state.adr_unit}
        adr_city={this.state.adr_city}
        adr_state={this.state.adr_state}
        adr_postal_code={this.state.adr_postal_code}
        adr_country={this.state.adr_country}
      />
    </section>
  );

  render() {
    return (
      <figure
        className={`consolidated-item-form-section ${
          this.state.isEditing ? 'form-editing' : ''
        }`}
      >
        <ConsolidatedItemEditToggleHeader
          toggleEditing={this.toggleEditing}
          finishEditingAndRevert={this.finishEditingAndRevert}
          finishEditingAndUpdate={this.finishEditingAndUpdate}
          attemptSave={this.attemptSave}
          isDirty={this.state.isDirty}
          isEditing={this.state.isEditing}
          isSaving={this.state.isSaving}
          title="Business Address"
        />
        {this.state.isEditing ? this.renderEditing() : this.renderViewing()}
      </figure>
    );
  }
}
